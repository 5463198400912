import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput, maxLength, required
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';


const useStyles = makeStyles((theme) => ({
    adjudicacion_criterio: {display: 'inline-block', width: 200, marginLeft: 8},
    adjudicacion_criterio_d: {display: 'inline-block', width: 600, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_adjudicacion_criterio = values.adjudicacion_criterio

    if (w_adjudicacion_criterio===undefined) {
        errors.adjudicacion_criterio = ['El campo Código es requerido'];
    }else {
        if (w_adjudicacion_criterio === null) {
            errors.adjudicacion_criterio = ['El campo Código es requerido'];
        } else {
            w_adjudicacion_criterio = w_adjudicacion_criterio.trim()
            if (w_adjudicacion_criterio === '') {
                errors.adjudicacion_criterio = ['El campo Código es requerido'];
            }
        }
    }


    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/AdjudicacionCriterio`;

const validateadjudicacion_criterio = [required(), maxLength(10)];
const validateadjudicacion_criterio_d = [required(), maxLength(60)];

const AdjudicacionCriterioCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Adjudicacion Criterio</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS ADJUDICACION CRITERIO">
                    <TextInput
                        label="Código Adjudicacion Criterio"
                        source="adjudicacion_criterio"
                        formClassName={classes.adjudicacion_criterio}
                        placeholder="Ingrese Código Adjudicacion Criterio"
                        helperText="Código Adjudicacion Criterio"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validateadjudicacion_criterio}
                    />

                    <br/>

                    <TextInput
                        label="Descripción Adjudicacion Criterio"
                        source="adjudicacion_criterio_d"
                        formClassName={classes.adjudicacion_criterio_d}
                        placeholder="Ingrese Descripción Adjudicacion Criterio"
                        helperText="Descripción Adjudicacion Criterio"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validateadjudicacion_criterio_d}
                    />

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default AdjudicacionCriterioCreate;


