import React from "react";
import * as XLSX from "xlsx";
import {
    useDataProvider,
    useListContext,
    Button,
    Filter,
    DateField,
    TextField,
    NumberField,
    Datagrid
} from 'react-admin';
import DownloadIcon from '@mui/icons-material/Download';

const ExportExcelButton = ({total, fileName, sheetName}) => {
    const {filterValues} = useListContext();
    const dataProvider = useDataProvider();

    let w_fileName, w_sheetName;
    fileName ? w_fileName = fileName + ".xlsx" : w_fileName = 'planilla.xlsx';
    sheetName ? w_sheetName = sheetName : w_sheetName = 'Hoja 1';

    const handleClick = () => {
        // Data para exportar
        dataProvider.getList(
            'ContratacionEncuadreVisita',
            {
                filter: {
                    id_unidad_negocio: filterValues.id_unidad_negocio,
                    id_unidad_area: filterValues.id_unidad_area,
                    id_contratacion_tipo: filterValues.id_contratacion_tipo,
                    id_estado_encuadre: filterValues.id_estado_encuadre,
                    contratacion_ano: filterValues.contratacion_ano,
                    visitas: filterValues.visitas,
                    q: filterValues.q
                },
                sort: {field: 'id', order: 'ASC'},
                pagination: {page: 1, perPage: 9999999},
            },)
            .then(({data}) => {
                downloadExcel(data);
            })
            .catch(error => {
                console.log(error)
            });
    };

    // JN Creo un nuevo JSON sólo con los campos que quiero mostrar en el excel

    // Ajusta el ancho de las columnas automaticamente segun el contenido
    const fitToColumn = data_export => {
        const columnWidths = [];
        for (const property in data_export[0]) {
            columnWidths.push({wch: Math.max(property ? property.toString().length : 0, ...data_export.map(obj => obj[property] ? obj[property].toString().length : 0))});
        }
        return columnWidths;
    };

    const downloadExcel = (data) => {
        let data_export = data.map(({
                                        id,
                                        fecha_encuadre_corta,
                                        expediente_referencia_gde,
                                        contratacion_tipo_encuadre,
                                        contratacion_ano,
                                        unidad_negocio_requiere,
                                        unidad_area_d,
                                        moneda,
                                        importe_presupuesto,
                                        importe_presupuesto_pesos,
                                        estado_encuadre,
                                        estado_encuadre_d,
                                        estado_encuadre_fh_ultima_actualizacion,
                                        contratacion_tipo,
                                        contratacion_d,
                                        concepto_descripcion,
                                    }) => ({
            id,
            fecha_encuadre_corta,
            expediente_referencia_gde,
            contratacion_tipo_encuadre,
            contratacion_ano,
            unidad_negocio_requiere,
            unidad_area_d,
            moneda,
            importe_presupuesto,
            importe_presupuesto_pesos,
            estado_encuadre,
            estado_encuadre_d,
            estado_encuadre_fh_ultima_actualizacion,
            contratacion_tipo,
            contratacion_d,
            concepto_descripcion,


        }));

        const worksheet = XLSX.utils.json_to_sheet(data_export);
        worksheet['!cols'] = fitToColumn(data_export);

        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, w_sheetName);
        XLSX.utils.sheet_add_aoa(worksheet, [["ID", "Fecha de Encuadre", "Expediente", "Encuadre", "Año", "Unidad Negocio", "Unidad Área", "Moneda",
            "Importe", "Importe $", "Estado Actual", "Estado Descripcion", "Última Actualización", "Contratacion Tipo ", "Contratacion Desc.", "Concepto"]], {origin: "A1"});

        XLSX.writeFile(workbook, w_fileName);
    };
    return (
        <Button
            onClick={() => handleClick()}
            label="excel"
        >
            <DownloadIcon/>
        </Button>
    )
};

export default ExportExcelButton;
