import {SelectInput, ReferenceInput, FormDataConsumer} from 'react-admin';
import React, {useCallback} from "react";
import {useForm} from "react-final-form";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    persona_forma: {marginLeft: 32},
}));

// JN Al seleccionar valor en Combo persona Tipo, filtra el valor de Persona Forma.
const PersonaTipoPersonaForma = ({setPersonaTipo, isReadonly}) => {
    const form = useForm();
    const classes = useStyles();

    /// Seteo el estado del Parent con el valor letra del id seleccionado
    const handleClick = useCallback((event) => {
        let valor = event.target.value

        if (valor == 1) {
            setPersonaTipo('PF');
        } else if (valor == 2) {
            setPersonaTipo('PJ');
        } else if (valor == 3) {
            setPersonaTipo('PE');
        } else if (valor == '') {
            setPersonaTipo('');
        }
        // si cambio el Tipo de Persona, reseteo la Forma
        form.change('id_persona_forma', undefined);
    }, [form]);

    return (
        <>
            <FormDataConsumer>
                {({formData, ...rest}) => (
                    <div style={{display: 'inline-flex', width: '100%'}}>
                        <ReferenceInput label="Persona Tipo" source="id_persona_tipo" reference="personaTipo"
                                        variant="outlined"
                                        onChange={(event) => {
                                            handleClick(event);
                                        }
                                        }
                        >
                            <SelectInput InputLabelProps={{shrink: true}} optionText="persona_tipo_d"
                                         inputProps={{readOnly: isReadonly}}
                            />
                        </ReferenceInput>
                        <br/>
                        {/*// Si es Persona Jurídica (ID = 1) no se completa persona forma*/}
                        {formData.id_persona_tipo > 1 ? (
                            <ReferenceInput label="Persona Forma" source="id_persona_forma" reference="PersonaForma"
                                            variant="outlined" allowEmpty className={classes.persona_forma}
                                            filter={{id_persona_tipo: formData.id_persona_tipo}}
                            >
                                <SelectInput InputLabelProps={{shrink: true}} optionText="persona_forma_d"
                                             inputProps={{readOnly: isReadonly}}
                                />
                            </ReferenceInput>
                        ) : (

                            <SelectInput label="Persona Forma" InputLabelProps={{shrink: true}}
                                         optionText="persona_forma_d" className={classes.persona_forma}
                                         inputProps={{disabled: true}}/>
                        )
                        }

                    </div>
                )}
            </FormDataConsumer>
        </>
    );

};

export default PersonaTipoPersonaForma;
