import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';


const useStyles = makeStyles((theme) => ({
    ciudad: {display: 'inline-block', width: 200, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_ciudad = values.ciudad

    if (w_ciudad===undefined) {
        errors.ciudad = ['El campo Código es requerido'];
    }else {
        if (w_ciudad === null) {
            errors.ciudad = ['El campo Código es requerido'];
        } else {
            w_ciudad = w_ciudad.trim()
            if (w_ciudad === '') {
                errors.ciudad = ['El campo Código es requerido'];
            }
        }
    }


    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/Ciudad`;

const CiudadCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Ciudad</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS CIUDAD">
                    <TextInput
                        label="Ciudad"
                        source="ciudad"
                        formClassName={classes.ciudad}
                        placeholder="Ingrese Ciudad"
                        helperText="Ciudad"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br/>

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default CiudadCreate;


