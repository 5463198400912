import React, {useEffect, Fragment, useState, useCallback} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField, DateInput
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RequerimientoConceptoBusquedaBtn from "../RequerimientoConcepto/RequerimientoConceptoBusquedaBtn";
import UniMedBusquedaBtn from "../UniMed/UniMedBusquedaBtn";
import PropTypes from "prop-types";

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if (w_apiurlserver == null) {
    w_apiurlserver = ''
}
w_apiurlserver = w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
    item: {display: 'inline-block', width: 80},
    requerimiento_concepto: {display: 'inline-block', width: 250},
    clase: {display: 'inline-block', width: 250, marginLeft: 32},
    renglon: {display: 'inline-block', width: 80},
    renglon_d: {display: 'inline-block', width: 800, marginLeft: 32},
    fec_entrega: {display: 'inline-block', width: 150, marginLeft: 60},
    uni_med: {display: 'inline-block', width: 120},
    cantidad: {display: 'inline-block', width: 80, marginLeft: 32},
    iva: {display: 'inline-block', width: 110, marginLeft: 22},
    btnBusqueda: {
        marginRight: '2rem',
        // width: 40,
        display: 'inline-block',
    },
    moneda: {display: 'inline-block', width: 150, marginLeft: 15},
    cotizacion: {display: 'inline-block', width: 150, marginLeft: 32},
    fecha_cotizacion: {display: 'inline-block', width: 150, marginLeft: 32},
    importe: {display: 'inline-block', width: 150, marginLeft: 32},
    importe2: {display: 'inline-block', width: 150, marginLeft: 16},
});

const OrdenCompraDetalleButtonShow = ({record}) => {
    var wr_id = record.id

    var wr_orden_compra_nro = record.orden_compra_nro

    if (wr_orden_compra_nro == null) {
        wr_orden_compra_nro = 0
    }
    // wr_orden_compra_nro=wr_orden_compra_nro.trim()

    var wr_orden_compra_ampliacion_nro = record.orden_compra_ampliacion_nro

    if (wr_orden_compra_ampliacion_nro == null) {
        wr_orden_compra_ampliacion_nro = 0
    }
    // wr_orden_compra_ampliacion_nro_tiene=wr_orden_compra_ampliacion_nro_tiene.trim()
    const dataProviderTableroControl = useDataProvider();
    const [Estadoid, setEstadoid] = useState(0)
    const [Estadoid_orden_compra_cabecera, setEstadoid_orden_compra_cabecera] = useState(0)
    const [Estadoorden_compra_nro, setEstadoorden_compra_nro] = useState(0)
    const [Estadoorden_compra_ampliacion_nro, setEstadoorden_compra_ampliacion_nro] = useState(0)
    const [Estadoitem, setEstadoitem] = useState('')
    const [Estadoconcepto_requerimiento, setEstadoconcepto_requerimiento] = useState('')
    const [Estadoclase, setEstadoclase] = useState('')
    const [Estadorenglon_nro, setEstadorenglon_nro] = useState('')
    const [Estadorenglon_descripcion, setEstadorenglon_descripcion] = useState('')
    const [Estadounidad_medida, setEstadounidad_medida] = useState('')
    const [Estadounidad_medida_abre, setEstadounidad_medida_abre] = useState('')
    const [Estadocantidad, setEstadocantidad] = useState('')
    const [Estadofecha_entrega, setEstadofecha_entrega] = useState('')
    const [Estadomoneda, setEstadomoneda] = useState('')
    const [Estadomoneda_abre, setEstadomoneda_abre] = useState('')
    const [EstadoIdMoneda, setEstadoIdMoneda] = useState(0)
    const [Estadocotizacion, setEstadocotizacion] = useState(record.cotizacion)
    const [EstadocotizacionFecha, setEstadocotizacionFecha] = useState(record.cotizacion_fecha)
    const [Estadoprecio_unitario_sin_iva, setEstadoprecio_unitario_sin_iva] = useState('')
    const [Estadodescuento_porcentaje, setEstadodescuento_porcentaje] = useState('')
    const [Estadoprecio_unitario_sin_iva_con_dto, setEstadoprecio_unitario_sin_iva_con_dto] = useState('')
    const [Estadoiva_porcentaje, setEstadoiva_porcentaje] = useState('')
    const [Estadoiva_importe, setEstadoiva_importe] = useState('')
    const [Estadototal_item, setEstadototal_item] = useState('')
    const [Estadototal_importe_iva_pesos, setEstadototal_importe_iva_pesos] = useState('')
    const [Estadototal_item_pesos, setEstadototal_item_pesos] = useState('')
    const [Estadofecha_necesidad, setEstadofecha_necesidad] = useState('')
    const [ComboMoneda, setComboMoneda] = useState([]);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        console.log("record detalle", record)
        var wr_id = record.id;
        var wr_id_orden_compra_cabecera = record.id_orden_compra_cabecera;
        var wr_orden_compra_nro = record.orden_compra_nro;
        var wr_orden_compra_ampliacion_nro = record.orden_compra_ampliacion_nro;
        var wr_item = record.item;
        var wr_concepto_requerimiento = record.concepto_requerimiento;
        var wr_clase = record.clase;
        var wr_renglon_nro = record.renglon_nro;
        var wr_renglon_descripcion = record.renglon_descripcion;
        var wr_unidad_medida = record.unidad_medida;
        var wr_unidad_medida_abre = record.unidad_medida_abre;
        var wr_cantidad = record.cantidad;
        var wr_fecha_entrega = record.fecha_entrega;
        var wr_moneda = record.moneda;
        var wr_id_moneda = record.id_moneda;
        var wr_cotizacion = record.cotizacion;
        var wr_cotizacion_fecha = record.cotizacion_fecha;
        var wr_precio_unitario_sin_iva = record.precio_unitario_sin_iva;
        var wr_descuento_porcentaje = record.descuento_porcentaje;
        var wr_precio_unitario_sin_iva_con_dto = record.precio_unitario_sin_iva_con_dto;
        var wr_iva_porcentaje = record.iva_porcentaje;
        var wr_iva_importe = record.iva_importe;
        var wr_total_item = record.total_item;
        var wr_total_importe_iva_pesos = record.total_importe_iva_pesos;
        var wr_total_item_pesos = record.total_item_pesos;
        var wr_fecha_necesidad = record.fecha_necesidad;


        setEstadoid(wr_id);
        setEstadoid_orden_compra_cabecera(wr_id_orden_compra_cabecera);
        setEstadoorden_compra_nro(wr_orden_compra_nro);
        setEstadoorden_compra_ampliacion_nro(wr_orden_compra_ampliacion_nro);
        setEstadoitem(wr_item);
        setEstadoconcepto_requerimiento(wr_concepto_requerimiento);
        setEstadoclase(wr_clase);
        setEstadorenglon_nro(wr_renglon_nro);
        setEstadorenglon_descripcion(wr_renglon_descripcion);
        setEstadounidad_medida(wr_unidad_medida);
        setEstadounidad_medida_abre(wr_unidad_medida_abre);
        setEstadocantidad(wr_cantidad);
        setEstadofecha_entrega(wr_fecha_entrega);
        setEstadomoneda(wr_moneda);
        setEstadoIdMoneda(wr_id_moneda);
        setEstadocotizacion(wr_cotizacion);
        setEstadocotizacionFecha(wr_cotizacion_fecha);
        setEstadoprecio_unitario_sin_iva(wr_precio_unitario_sin_iva);
        setEstadodescuento_porcentaje(wr_descuento_porcentaje);
        setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto);
        setEstadoiva_porcentaje(wr_iva_porcentaje);
        setEstadoiva_importe(wr_iva_importe);
        setEstadototal_item(wr_total_item);
        setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos);
        setEstadototal_item_pesos(wr_total_item_pesos);
        setEstadofecha_necesidad(wr_fecha_necesidad);

        dataProviderTableroControl.getList(
            'MonedaActivo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'S'},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboMoneda(data);
                setEstadomoneda(data[0].moneda_d);
            })
            .catch(error => {
                setOpen(false);
            })

        setOpen(true);
    };

    const handlecantidad = (event) => {
        const Cambiacantidad1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadocantidad(wp_value)

            var wh_cantidad = wp_value
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva
            var wh_descuento_porcentaje = Estadodescuento_porcentaje
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * wh_descuento_porcentaje) / 100)
                wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100
            }

            wr_precio_unitario_sin_iva_con_dto = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad)

            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = wr_precio_unitario_sin_iva_con_dto + wr_iva_importe

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiacantidad1(w_value)

    };

    const handlefecha_entrega = (event) => {
        const Cambiafecha_entrega1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadofecha_entrega(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiafecha_entrega1(w_value)

    };

    const handlecotizacion = (event) => {
        const Cambiacotizacion1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadocotizacion(wp_value)

            var wh_cantidad = Estadocantidad
            var wh_cotizacion = wp_value
            var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva
            var wh_descuento_porcentaje = Estadodescuento_porcentaje
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * wh_descuento_porcentaje) / 100)
                wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100
            }

            wr_precio_unitario_sin_iva_con_dto = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad)

            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = wr_precio_unitario_sin_iva_con_dto + wr_iva_importe

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiacotizacion1(w_value)

    };

    const handleprecio_unitario_sin_iva = (event) => {
        const Cambiaprecio_unitario_sin_iva1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoprecio_unitario_sin_iva(wp_value)

            var wh_cantidad = Estadocantidad
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = wp_value
            var wh_descuento_porcentaje = Estadodescuento_porcentaje
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * wh_descuento_porcentaje) / 100)
                wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100
            }

            wr_precio_unitario_sin_iva_con_dto = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad)

            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = wr_precio_unitario_sin_iva_con_dto + wr_iva_importe

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiaprecio_unitario_sin_iva1(w_value)

    };

    const handleiva_porcentaje = (event) => {
        const Cambiaiva_porcentaje1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoiva_porcentaje(wp_value)

            var wh_cantidad = Estadocantidad
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva
            var wh_descuento_porcentaje = Estadodescuento_porcentaje
            var wh_iva_porcentaje = wp_value


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * wh_descuento_porcentaje) / 100)
                wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100
            }

            wr_precio_unitario_sin_iva_con_dto = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad)

            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = wr_precio_unitario_sin_iva_con_dto + wr_iva_importe

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiaiva_porcentaje1(w_value)

    };

    const handledescuento_porcentaje = (event) => {
        const Cambiadescuento_porcentaje1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadodescuento_porcentaje(wp_value)

            var wh_cantidad = Estadocantidad
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva
            var wh_descuento_porcentaje = wp_value
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * wh_descuento_porcentaje) / 100)
                wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100
            }

            wr_precio_unitario_sin_iva_con_dto = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad)

            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = wr_precio_unitario_sin_iva_con_dto + wr_iva_importe

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiadescuento_porcentaje1(w_value)

    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleconcepto_requerimiento = useCallback(async (who) => {
        setEstadoconcepto_requerimiento(who.requerimiento_concepto);
        setEstadoclase(who.clase);
    });

    const formatEstadoconcepto_requerimiento = v => {
        return Estadoconcepto_requerimiento
    };

    const formatEstadoclase = v => {
        return Estadoclase
    };

    const handleclase = (event) => {
        const Cambiaclase1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoclase(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiaclase1(w_value)

    };

    const handlerenglon_nro = (event) => {
        const Cambiarenglon_nro1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadorenglon_nro(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiarenglon_nro1(w_value)

    };

    const handlerenglon_descripcion = (event) => {
        const Cambiarenglon_descripcion1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadorenglon_descripcion(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiarenglon_descripcion1(w_value)

    };

    const handleunidad_medida = useCallback(async (who) => {
        setEstadounidad_medida(who.unidad_medida);
        setEstadounidad_medida_abre(who.unidad_medida_abre);
    });

    const formatEstadounidad_medida_abre = v => {
        return Estadounidad_medida_abre;
    };

    const handlemoneda = (event) => {
        const Cambiamoneda1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadomoneda(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiamoneda1(w_value)

    };

    function NumberFormatCustom(props) {
        const {inputRef, onChange, ...other} = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={'.'}
                decimalSeparator={','}
                isNumericString
                prefix=""
            />
        );
    }

    NumberFormatCustom.propTypes = {
        inputRef: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,

    };


    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <VisibilityIcon style={{color: '#004187'}}/>
                    Mostrar
                </div>
            </Button>

            <Dialog open={open}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                // fullScreen={true}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Detalle de Productos/Servicios</DialogTitle>
                <DialogContent>
                    <form>

                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput source="item" style={{textAlign: 'right', margin: '1em'}}
                                       label={"Item"}
                                       fullWidth={true}
                                       className={classes.item}
                                       inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                                       value={Estadoitem}
                                       variant="outlined"
                                // format={formatItem}
                            />

                            <TextInput source="requerimiento_concepto" style={{textAlign: 'right', margin: '1em'}}
                                       label={"Concepto Requerimiento"}
                                       fullWidth={true}
                                       className={classes.requerimiento_concepto}
                                       inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                                       value={Estadoconcepto_requerimiento}
                                       format={formatEstadoconcepto_requerimiento}
                                       variant="outlined"
                            />

                            <TextInput source="clase" label={"Clase"}
                                       fullWidth={true}
                                       className={classes.clase}
                                       inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                                       value={Estadoclase}
                                       format={formatEstadoclase}
                                       variant="outlined"
                            />

                            {/*<RequerimientoConceptoBusquedaBtn fullWidth={true}*/}
                            {/*                                  callbackRequerimientoConcepto={handleconcepto_requerimiento}*/}
                            {/*                                  className={classes.btnBusqueda}/>*/}

                        </div>

                        <TextField label="Renglon" value={Estadorenglon_nro}
                                   onChange={handlerenglon_nro} style={{textAlign: 'right', margin: '1em'}}
                                   SelectProps={{native: true,}} inputProps={{readOnly: true,}}
                                   className={classes.renglon}

                        />

                        <TextField
                            label="Renglon Descripción"
                            value={Estadorenglon_descripcion}
                            onChange={handlerenglon_descripcion}
                            multiline style={{textAlign: 'right', margin: '1em'}}
                            fullWidth inputProps={{readOnly: true,}}
                            SelectProps={{native: true,}}
                            className={classes.renglon_d}

                        />
                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput label="Uni.Med."
                                       variant="outlined"
                                       value={Estadounidad_medida_abre}
                                       format={formatEstadounidad_medida_abre}
                                       style={{textAlign: 'right', margin: '1em'}}
                                       className={classes.uni_med}
                                       inputProps={{readOnly: true,}}
                                       SelectProps={{
                                           native: true,
                                       }}/>
                            {/*
                            <UniMedBusquedaBtn
                                callbackUniMed={handleunidad_medida}
                                className={classes.btnBusqueda}/>*/}

                            <NumberFormat
                                label="Cantidad" inputProps={{readOnly: true,}}
                                customInput={TextField} value={Estadocantidad}
                                onChange={handlecantidad} className={classes.cantidad}
                                style={{textAlign: 'right', margin: '1em'}}
                                options={{minimumFractionDigits: 2}}
                            />

                            <DateInput
                                label="Fecha de Entrega"
                                source={"fecha_entrega"}
                                className={classes.fec_entrega} fullWidth
                                defaultValue={Estadofecha_entrega}
                                onChange={handlefecha_entrega} style={{textAlign: 'right', margin: '1em'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                inputProps={{readOnly: true,}}
                            />

                        </div>

                        <TextField
                            label="Moneda"
                            value={Estadomoneda}
                            inputProps={{readOnly: true}}
                            // variant="filled"
                            className={classes.moneda}
                        />

                        <TextField
                            label="Cotización"
                            value={Estadocotizacion}
                            inputProps={{readOnly: true}}
                            // variant="filled"
                            className={classes.cotizacion}
                        />

                        <DateInput
                            label="Fecha Cotización"
                            source={"cotizacion_fecha"}
                            className={classes.fec_entrega} fullWidth
                            defaultValue={EstadocotizacionFecha}
                            value={Estadofecha_entrega}
                            inputProps={{readOnly: true}}
                            style={{textAlign: 'right', margin: '1em'}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                        />

                        <br/>

                        <TextField
                            customInput={TextField} label="Precio Unit. Sin IVA"
                            value={Estadoprecio_unitario_sin_iva}
                            onChange={handleprecio_unitario_sin_iva} inputProps={{readOnly: true,}}
                            style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                            className={classes.importe}
                            options={{minimumFractionDigits: 2}}
                        />

                        <TextField
                            customInput={TextField} label="Porc. Descuento"
                            value={Estadodescuento_porcentaje} inputProps={{readOnly: true,}}
                            onChange={handledescuento_porcentaje}
                            style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                            className={classes.importe}
                        />

                        <TextField
                            customInput={TextField} label="Precio Unitario con Descuento"
                            value={Estadoprecio_unitario_sin_iva_con_dto}
                            inputProps={{readOnly: true}}
                            className={classes.importe2}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                            style={{textAlign: 'right', margin: '1em'}}
                        />
                        <br/>

                        <TextField
                            customInput={TextField} label="IVA %" value={Estadoiva_porcentaje}
                            onChange={handleiva_porcentaje}
                            className={classes.importe}
                            inputProps={{readOnly: true}}
                            style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                        />

                        <TextField
                            customInput={TextField} label="IVA Importe" value={Estadoiva_importe}
                            inputProps={{readOnly: true}}
                            className={classes.importe}
                            style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                        />

                        <TextField
                            customInput={TextField} label="Total Ítem" value={Estadototal_item}
                            inputProps={{readOnly: true}}
                            className={classes.importe}
                            style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                        />

                        <br/>
                        <TextField
                            customInput={TextField} label="Total IVA $"
                            value={Estadototal_importe_iva_pesos}
                            inputProps={{readOnly: true}}
                            className={classes.importe}
                            style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                        />

                        <TextField
                            customInput={TextField} label="Total Ítem $"
                            value={Estadototal_item_pesos}
                            inputProps={{readOnly: true}}
                            style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                            className={classes.importe}
                        />

                    </form>
                </DialogContent>
                <DialogActions>
                    {/*<BotonActualizarDetelle/>*/}
                    <Button variant="outlined" color="primary" onClick={handleClose}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <CancelPresentationIcon style={{color: '#004187'}}/>
                            Cerrar
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

};

OrdenCompraDetalleButtonShow.defaultProps = {
    source: '',
    addLabel: true,
};

export default OrdenCompraDetalleButtonShow;