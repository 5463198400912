import React, {useState} from 'react';
import ProveedorBusquedaDialogDataGrid from "./ProveedorBusquedaDialogDataGrid"
import {Drawer} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import IconCancelar from "@material-ui/icons/Close";

export const ProveedorBusquedaDialogBtn = (props) => {
    const useStyles = makeStyles({
        button: {
            justifyContent: 'left',
            display: 'inline-flex',
            fontWeight: 'bold',
            margin: 0,
            marginBottom: 0
        },
    });
    const classes = useStyles();

    const [openProveedorBusqueda, setOpenProveedorBusqueda] = useState(false);
    const [fullWidthProveedorBusqued, setFullWidthProveedorBusqued] = React.useState(true);
    const [maxWidthProveedorBusqued, setMaxWidthProveedorBusqued] = React.useState('lg');

    const handleDialogOpenProveedorBusqueda = () => {
        setOpenProveedorBusqueda(false);
    };

    return (
        <div>

            <IconButton style={{color: '#123B80'}} onClick={() => setOpenProveedorBusqueda(!openProveedorBusqueda)}>
                <Icon />
            </IconButton>

            <Drawer
                variant="persistent"
                anchor="right"
                open={openProveedorBusqueda}
            >
                <ProveedorBusquedaDialogDataGrid
                    callbackProveedor={props.callbackProveedor}
                    proveedor={props.proveedor}
                    callbackCloseDialog={handleDialogOpenProveedorBusqueda}
                />

            </Drawer>


            {/*<Button variant="outlined" color="primary" onClick={() => setOpenProveedorBusqueda(!openProveedorBusqueda)}>*/}
            {/*    <div title={''}*/}
            {/*         style={{*/}
            {/*             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
            {/*        <Icon/>*/}
            {/*    </div>*/}
            {/*</Button>*/}
            {/*<IconButton color="primary" onClick={() => setOpenProveedorBusqueda(!openProveedorBusqueda)}>*/}
            {/*    <Icon/>*/}
            {/*</IconButton>*/}

            {/*<Dialog open={openProveedorBusqueda}*/}
            {/*        fullWidth={fullWidthProveedorBusqued}*/}
            {/*        maxWidth={maxWidthProveedorBusqued}*/}
            {/*        aria-labelledby="form-dialog-title">*/}
            {/*    /!*<DialogTitle id="form-dialog-title">Proveedor</DialogTitle>*!/*/}
            {/*    <DialogContent>*/}
            {/*        <ProveedorBusquedaDialogDataGrid*/}
            {/*            callbackProveedor={props.callbackProveedor}*/}
            {/*            proveedor={props.proveedor}*/}
            {/*            callbackCloseDialog={handleDialogOpenProveedorBusqueda}*/}
            {/*        />*/}
            {/*    </DialogContent>*/}
            {/*    <DialogActions>*/}
            {/*        /!*<Button variant="outlined" color="primary" onClick={handleDialogOpenProveedorBusqueda}>*!/*/}
            {/*        /!*    <div title={''}*!/*/}
            {/*        /!*         style={{*!/*/}
            {/*        /!*             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*!/*/}
            {/*        /!*        <CancelPresentationIcon style={{color: '#004187'}}/>*!/*/}
            {/*        /!*        Volver*!/*/}
            {/*        /!*    </div>*!/*/}
            {/*        /!*</Button>*!/*/}
            {/*        <Button variant="contained" size={"small"} color="primary" onClick={handleDialogOpenProveedorBusqueda}>*/}
            {/*            <IconCancelar/>*/}
            {/*            Volver*/}
            {/*        </Button>*/}
            {/*    </DialogActions>*/}
            {/*</Dialog>*/}
        </div>
    )
};
export default ProveedorBusquedaDialogBtn;