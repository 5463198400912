import React, {useCallback, useState} from 'react';
import {
    useNotify,
    useRefresh,
    useMutation,
    ReferenceInput,
    SelectInput,
    FileField,
    FileInput,
    useDelete, useUpdate, useCreate
} from 'react-admin';
import {
    TextInput,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import IconCancelar from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {Drawer, makeStyles} from "@material-ui/core";
import DocumentacionTipoBusquedaBtn from "../DocumentacionTipo/DocumentacionTipoBusquedaBtn";
import Icon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import ProveedorDocumentacionAdjuntoSaveButton from "./ProveedorDocumentacionAdjuntoSaveButton"
import IconEliminar from "@material-ui/icons/Delete";
import axios from 'axios';
import FilePondUpload from "./FilePondUpload"
import Compressor from 'compressorjs';
import RefreshIcon from '@material-ui/icons/Refresh';
import ApiUrlServer from '../layout/ApiUrlServer'
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// Importar plugin
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AttachFileIcon from '@material-ui/icons/AttachFile';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview,FilePondPluginImageResize);


var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()


const useStyles = makeStyles((theme) => ({

    documentacion_tipo: {display: 'inline-block', width: '100%'},
    documentacion_tipo_d: {display: 'inline-block', width: '100%'},
    documentacion_ingresar_vencimiento: {display: 'inline-block', width: '100%'},
    documentacion_adjuntar_archivo: {display: 'inline-block', width: '100%'},
    fecha_ingreso: {display: 'inline-block', width: '100%'},
    fecha_vencimiento: {display: 'inline-block', width: '100%'},
    id_documentacion_tipo: {display: 'inline-block', width: '100%'},
    root: {
        flexGrow: 1,
    },
}));


function ProveedorDocumentacionAdjuntoNuevo({record, refetch}) {
    // const [page, setPage] = useState(1);
    // const [perPage, setPerPage] = useState(25);
    // const [sort, setSort] = useState({field: 'id', order: 'ASC'});
    const classes = useStyles();

    var dateFechaEncuadre = new Date();
    var duedateFechaEncuadre = new Date(dateFechaEncuadre);
    duedateFechaEncuadre.setDate(dateFechaEncuadre.getDate());
    var dFechaEncuadre = new Date(duedateFechaEncuadre || Date.now()),
        monthFechaEncuadre = '' + (dFechaEncuadre.getMonth() + 1),
        dayFechaEncuadre = '' + (dFechaEncuadre.getDate()),
        yearFechaEncuadre = dFechaEncuadre.getFullYear();

    //console.log(month)

    if (monthFechaEncuadre.length < 2) monthFechaEncuadre = '0' + monthFechaEncuadre;
    if (dayFechaEncuadre.length < 2) dayFechaEncuadre = '0' + dayFechaEncuadre;

    // var w_f_carga1FechaEncuadre = [dayFechaEncuadre, monthFechaEncuadre, yearFechaEncuadre].join('/');
    var w_f_carga2FechaEncuadre = [yearFechaEncuadre, monthFechaEncuadre, dayFechaEncuadre].join('-');

    const [openAgregar, setOpenAgregar] = useState(false);

    const [documentacion_ingresar_vencimientoAgregar, setdocumentacion_ingresar_vencimientoAgregar] = useState(record.documentacion_ingresar_vencimiento);
    const [documentacion_adjuntar_archivoAgregar, setdocumentacion_adjuntar_archivoAgregar] = useState(record.documentacion_adjuntar_archivo);

    const [EstadoArchivosAdjuntos, setEstadoArchivosAdjuntos] = useState('')
    const [Estadofecha_vencimiento, setEstadofecha_vencimiento] = useState(w_f_carga2FechaEncuadre)
    const [errorfecha_vencimiento, setErrorfecha_vencimiento] = useState(false);
    const [helperTextfecha_vencimiento, setHelperTextfecha_vencimiento] = useState('');

    const handleDrawerOpenAgregar = () => {
        setOpenAgregar(false);
    };

    const cambiaArchivosAdjuntos = (event) => {
        const CambiaArchivosAdjuntos = (wp_value) => {
            console.log('wp_value----------------------CambiaArchivosAdjuntos')
            console.log(wp_value)
            setEstadoArchivosAdjuntos(wp_value)
            console.log('EstadoArchivosAdjuntos')
            console.log(EstadoArchivosAdjuntos)
        };

        console.log('event----------------------cambiaArchivosAdjuntos')
        console.log(event)

        var w_value = event
        CambiaArchivosAdjuntos(w_value)
    }

    const cambiafecha_vencimiento = (event) => {
        const Cambiafecha_vencimiento = (wp_value) => {
            // console.log('wp_value----------------------Cambiafecha_vencimiento')
            // console.log(wp_value)
            setEstadofecha_vencimiento(wp_value)
            // console.log('Estadofecha_vencimiento')
            // console.log(Estadofecha_vencimiento)
        };
        const Cambiafecha_vencimientoError = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadofecha_vencimiento(wp_value)
            // console.log('Estadofecha_vencimiento')
            // console.log(Estadofecha_vencimiento)
            setErrorfecha_vencimiento(true);

        };
        setHelperTextfecha_vencimiento('')
        setErrorfecha_vencimiento(false);
        var w_value = event.target.value
        // console.log('w_value----------------cambiafecha_vencimiento')
        // console.log(w_value)
        if(w_value){
            if(w_value===null){
                w_value=''
            }
            w_value=w_value.trim()
            var w_value_length = w_value.length
            // console.log('w_value_length')
            // console.log(w_value_length)
            if(w_value_length===10) {
                var wh_substring = w_value.substring(0, 4)
                // console.log('wh_substring')
                // console.log(wh_substring)
                var wh_ano = parseInt(wh_substring)
                // console.log('wh_ano')
                // console.log(wh_ano)
                if (wh_ano >= 2000) {
                    Cambiafecha_vencimiento(w_value)
                }else{
                    setHelperTextfecha_vencimiento('El año de la Fecha tiene que ser mayor o igual a 2000')
                    Cambiafecha_vencimientoError(w_value)
                }
            }else{
                setHelperTextfecha_vencimiento('Error en el Formato de Fecha')
                Cambiafecha_vencimientoError(w_value)
            }
        }else{
            setHelperTextfecha_vencimiento('Error en el Formato de Fecha')
            Cambiafecha_vencimientoError(w_value)
        }

    }

    const [files, setFiles] = useState([]);
    const [IDArchivoAdjunto, setIDArchivoAdjunto] = useState(0);

    const [{
        proveedor,
        documentacion_tipo,
        fecha_vencimiento,
        id_proveedor_documentacion,
        ArchivosAdjuntos,
        src
    }, setState] = useState({
        proveedor: record.pro,
        documentacion_tipo: record.documentacion_tipo,
        fecha_vencimiento: null,
        id_proveedor_documentacion: record.id,
        ArchivosAdjuntos: null,
        src: null
    });

    /// JN Mascara para el input cuit-cuil
    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    function handleTextInputChange({target: {name, value}}) {
        setState(prevState => ({...prevState, [name]: value}));
    }

    const BtnCancelar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
            handleDrawerOpenAgregar(false)
            setFiles([])
            setIDArchivoAdjunto(0)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const BotonProveedorDocumentacionAdjuntoSave = (record) => {
        const notify = useNotify();
        const [update] = useCreate('ProveedorDocumentacionAdjunto');
        const handleClick = () => {
            console.log('files')
            console.log(files)
            update(
                {
                    payload: {
                        data: {
                            proveedor,
                            documentacion_tipo,
                            Estadofecha_vencimiento,
                            id_proveedor_documentacion,
                            files,
                            IDArchivoAdjunto
                        }
                    }
                },
                {
                    onSuccess: ({data: newRecord}) => {
                        notify('Listo el Adjunto de la Documentación Solicitada', 'info', {
                            smart_count: 1,
                        });
                        refetch();
                    },
                }
            );
        };
        return  (
            <div>
                {IDArchivoAdjunto === 0 ?
                    <Button  style={{color: '#9b9b9b', backgroundColor: '#eaeaea'}}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <SaveIcon/>
                            Guardar
                        </div>
                    </Button>
                    :
                    <Button variant="outlined" color="primary" onClick={handleClick}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <SaveIcon style={{color: '#004187'}}/>
                            Guardar
                        </div>
                    </Button>
                }
            </div>
        );

    };

    const BotonProveedorDocumentacionAdjuntoRefresh = (record) => {
        const handleClick = () => {
            refetch();
        };
        return  (
            <div>
                {IDArchivoAdjunto === 0 ?
                    <Button  style={{color: '#9b9b9b', backgroundColor: '#eaeaea', marginLeft: 24}}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <RefreshIcon/>
                        </div>
                    </Button>
                    :
                    <Button style={{marginLeft: 24}}
                            variant="outlined" color="primary" onClick={handleClick}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <RefreshIcon style={{color: '#004187'}}/>
                        </div>
                    </Button>
                }
            </div>
        );

    };

    const handleFileChange = (files) => {
        setFiles(files)
    };

    let tokenStr = localStorage.getItem('token');

    var wa_location_hostname = window.location.hostname

    console.log('wa_location_hostname')
    console.log(wa_location_hostname)

    var wa_apiurlserver

    switch (wa_location_hostname) {
        case 'opi.radionacional.ar':
            wa_apiurlserver = 'https://opi.radionacional.ar:4443/'
            break
        case 'proveedores.radionacional.ar':
            wa_apiurlserver = 'https://archivprov.radionacional.ar/'
            break
        default:
            wa_apiurlserver = 'http://'+wa_location_hostname+':4620'
            break
    }


    const handleRevertFile = (error, file) => {
        if (!error) {
            const updatedFiles = files.filter((f) => f !== file);
            setFiles(updatedFiles);
        }
    };

    const handleProcessFile = (error, file) => {
        if (!error) {
            setFiles([...files, file]);
        }
    };

    return (
        <div className={classes.root}>
            {/*<h2 className="mb-3">Agregar Archivo Documentacion</h2>*/}
            <Grid container spacing={12}>
                <Grid item xs={4}>
                    <FilePond
                        allowMultiple={false}
                        files={files}
                        name={'archivo'}
                        allowReorder={true}
                        instantUpload={true}
                        storeAsFile={true}
                        allowRevert={false}
                        imageResizeTargetWidth={1920}
                        imageResizeTargetHeight={1080}
                        imageResizeQuality={0.5}
                        onupdatefiles={(fileItems) => handleFileChange(fileItems)}
                        // server='http://localhost:4620/ProveedorDocumentacionAdjunto' // File upload api goes here}*/}
                        server={{
                            // url: 'http://localhost:4620/ArchivoAdjunto',
                            url: wa_apiurlserver+'/ArchivoAdjunto',
                            process: {
                                method: 'POST',
                                headers: {
                                    'X-CSRF-TOKEN': 'TOKEN',
                                    "x-auth-token": `${tokenStr}`
                                },
                                onload: (response) => {
                                    console.log(response);

                                    const json = response;
                                    const data = JSON.parse(json);

                                    console.log(data.fieldname); // salida: "archivo"
                                    console.log(data.id); // salida: 17

                                    setIDArchivoAdjunto(data.id)
                                },
                                onerror: (response) => {
                                    console.log(response);
                                }
                            }
                        }}
                        onprocessfile={handleProcessFile}
                        onremovefile={(error, file) => {
                            handleRevertFile(error, file);
                        }}
                        labelFileProcessingError={'Error al procesar el archivo'}
                        labelFileTypeNotAllowed={'Tipo de archivo no permitido'}
                        labelMaxFileSizeExceeded={'Tamaño máximo de archivo excedido'}
                        labelMaxFileSize={'Tamaño máximo de archivo'}
                        labelMaxFiles={'Número máximo de archivos'}
                        labelIdle='Arrastre el archivo para subir o <span class="filepond--label-action">haga clic para seleccionarlo</span>'
                        credits={''}
                        labelFileProcessingComplete={'Carga completa'}
                        labelFileProcessing={'Cargando...'}
                        labelTapToCancel={'Toque para Cancelar'}
                        labelTapToRetry={'Toca para Reintentar'}
                        labelTapToUndo={'Toque para Deshacer'}
                    />
                </Grid>
                <Grid item xs={4}>
                    {documentacion_ingresar_vencimientoAgregar === 'S' ?
                        <TextField
                            error={errorfecha_vencimiento}
                            id="fecha_vencimiento"
                            source="fecha_vencimiento"
                            label="Fecha Vencimiento"
                            type="date"
                            formClassName={classes.fecha_vencimiento}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={Estadofecha_vencimiento}
                            onChange={cambiafecha_vencimiento}
                            helperText={helperTextfecha_vencimiento}
                            style={{display: 'inline-block', width: '100%', marginLeft: 64}}
                        />
                        :
                        ''
                    }
                </Grid>
                <Grid item xs={2}>
                    <BotonProveedorDocumentacionAdjuntoSave
                        style={{display: 'inline-block', width: '100%', marginLeft: 32}}
                    />
                </Grid>
                <Grid item xs={2}>
                    <BotonProveedorDocumentacionAdjuntoRefresh
                        style={{display: 'inline-block', width: '100%', marginLeft: 32}}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default ProveedorDocumentacionAdjuntoNuevo;