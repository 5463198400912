import UserUsuComCreate from './UserUsuComCreate';
import UserUsuComEdit from './UserUsuComEdit';
// import UserUsuComShow from './UserUsuComShow';
import UserUsuComList from './UserUsuComList'



export default {
    create: UserUsuComCreate,
    edit: UserUsuComEdit,
    list: UserUsuComList,
    // show: UserUsuComShow

};
