import React, {useState} from 'react';
import {Drawer} from "@material-ui/core";
import Button from '@mui/material/Button';
import IconEdit from '@material-ui/icons/Edit';
import IconShow from '@material-ui/icons/Visibility';
import {makeStyles} from '@material-ui/core/styles';
import OCDetalleForm from "./OCDetalleForm";

export const OCDetalleBtnAccionGrid = (props) => {
    const useStyles = makeStyles({
        paper: {
            width: '50%'  /// Lo piden asi ancho. Antes estaba en 40
        },
        button: {
            justifyContent: 'left',
            display: 'inline-flex',
            fontWeight: 'bold',
            margin: 0,
            marginBottom: 0
        }
    });

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(false);
    };
    // console.log("record Brn Acceion", props.record)

    return (
        <div>

            {/*Si es readonly = true, significa que entra por SHOW. Sino, es EDIT*/}
            {props.accion == 'SHOW' &&
            <Button style={{color: '#004187'}} endIcon={<IconShow/>}
                    onClick={() => setOpen(!open)}
                    title={"Ver"}
            />
            }

            {props.accion == 'EDIT' &&
            <Button style={{color: '#004187'}} endIcon={<IconEdit/>}
                    onClick={() => setOpen(!open)}
                    title={"Editar"}
            />
            }

            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={open}
            >


                {open &&
                <>
                    <OCDetalleForm
                        callbackHayCambios={props.callbackHayCambios}
                        callbackCloseDrawer={handleDrawerOpen}
                        Data={props.record}
                        isReadOnly={props.accion === 'SHOW' ? true : false} /// si es show, algunos campos son read only
                    />
                </>
                }
            </Drawer>
        </div>
    )
};
export default OCDetalleBtnAccionGrid;