import {DateInput, FormDataConsumer, FormTab, NumberInput, SelectInput, TextInput, useDataProvider} from 'react-admin';
import React, {useCallback, useState} from "react";
import {useForm} from "react-final-form";
import {makeStyles} from "@material-ui/core";
import OrdenCompraBusquedaBtn from "./OrdenCompraBusquedaBtn";
import ContratacionEncuadreBusquedaBtn from "../ContratacionEncuadre/ContratacionEncuadreBusquedaBtn";
import UnidadEmisoraBusquedaBtn from "../UnidadEmisora/UnidadEmisoraBusquedaBtn";
import UnidadGerenciaBusquedaBtn from "../UnidadGerencia/UnidadGerenciaBusquedaBtn";
import UnidadSectorBusquedaBtn from "../UnidadSector/UnidadSectorBusquedaBtn";
import UnidadProgramaBusquedaBtn from "../UnidadPrograma/UnidadProgramaBusquedaBtn";

const useStyles = makeStyles((theme) => ({

    es_ampliacion: {display: 'inline-block', width: 150, marginLeft: 32},


    orden_compra_nro: {display: 'inline-block', width: 180},
    orden_compra_ampliacion_nro: {display: 'inline-block', width: 120, marginLeft: 32},
    orden_compra_fecha: {display: 'inline-block', width: 180, marginLeft: 32},
    orden_compra_fecha_origen: {display: 'inline-block', width: 180, marginLeft: 32},
    orden_compra_ano: {display: 'inline-block', width: 120, marginLeft: 32},
    orden_compra_origen_nro: {display: 'inline-block', width: 180},
    orden_compra_origen_ampliacion_nro: {display: 'inline-block', width: 120, marginLeft: 32},

    contratacion_tipo: {display: 'inline-block', width: 140},
    contratacion_tipo_d: {display: 'inline-block', width: 600, marginLeft: 32},
    contratacion_ano: {display: 'inline-block', width: 140},
    contratacion_nro: {display: 'inline-block', width: 140},
    unidad_area_requiere_cod: {display: 'inline-block', width: 100, marginLeft: 32},
    unidad_negocio_requiere: {display: 'inline-block', width: 140, marginLeft: 32},
    unidad_area_requiere: {display: 'inline-block', width: 380, marginLeft: 32},

    descripcion: {display: 'inline-block', width: 600, marginLeft: 32},
    programa: {display: 'inline-block', width: 100},
    sector: {display: 'inline-block', width: 100},
    gerencia: {display: 'inline-block', width: 100},
    emisora: {display: 'inline-block', width: 100},

    informe_adjudicacion_referencia_gde: {display: 'inline-block', width: 600},
    procedimiento: {display: 'inline-block', width: 600},
    expediente: {display: 'inline-block', width: 600},
    informe_adjudicacion: {display: 'inline-block', width: 600},
}));

const OrdenDeCompraContratacion = ({setPersonaTipo, isReadonly}) => {
        const {form, change} = useForm();
        const classes = useStyles();
        const dataProvider = useDataProvider();

        const updateOrdenDeCompra = useCallback(async (who) => {
            console.log("OC", who)

            change("id", who.id);

            change("orden_compra_origen_nro", who.orden_compra_nro);
            change("orden_compra_origen_ampliacion_nro", who.orden_compra_ampliacion_nro);

            // change("orden_compra_nro", who.orden_compra_nro);
            // change("orden_compra_ampliacion_nro", who.orden_compra_ampliacion_nro);
            change("orden_compra_fecha_corta", who.orden_compra_fecha_corta);
            change("orden_compra_ano", who.orden_compra_ano);
            change("procedimeinto_referencia_gde", who.procedimeinto_referencia_gde);
            change("id_contratacion_tipo", who.id_contratacion_tipo);

            change("Area.area_d", who.area_d.trim());
            change("Sector.sector_d", who.sector_d.trim());
            change("Programa.programa_d", who.programa_d.trim());
            change("Gerencia.gerencia_d", who.gerencia_d.trim());
            change("Emisora.emisora_d", who.emisora_d.trim());

            change("area", who.area ? who.area : '');
            change("sector", who.sector ? who.sector : '');
            change("programa", who.programa ? who.programa : '');
            change("gerencia", who.gerencia ? who.gerencia : '');
            change("emisora", who.emisora ? who.emisora : '');

            change("id_unidad_area", who.id_unidad_area ? who.id_unidad_area : '');
            change("id_unidad_sector", who.id_unidad_sector ? who.id_unidad_sector : '');
            change("id_unidad_programa", who.id_unidad_programa ? who.id_unidad_programa : '');
            change("id_unidad_gerencia", who.id_unidad_gerencia ? who.id_unidad_gerencia : '');
            change("id_unidad_emisora", who.id_unidad_emisora ? who.id_unidad_emisora : '');

            if (who.id_contratacion_tipo > 0) {
                dataProvider.getList(
                    'ContratacionEncuadre',
                    {
                        filter: {id: who.id_contratacion_tipo},
                        sort: {field: 'id', order: 'DESC'},
                        pagination: {page: 1, perPage: 100},
                    },)
                    .then(({data}) => {
                        console.log("updateOrdenDeCompra: ", data[0])

                        change("id_contratacion_tipo", data[0].id_contratacion_tipo);
                        change("ContratacionTipo.contratacion_d", data[0].contratacion_d);
                        change("contratacion_tipo", data[0].contratacion_tipo_encuadre);
                        change("unidad_negocio", data[0].unidad_negocio_requiere.trim());
                        change("area", data[0].unidad_area_d.trim());
                        change("contratacion_ano", data[0].contratacion_ano);
                        change("contratacion_nro", data[0].id);
                        change("id_unidad_area", data[0].id_unidad_area);
                        change("id_unidad_negocio", data[0].id_unidad_negocio);

                    })
                    .catch(error => {

                    })
            }
        }, [form]);

        const updateContratacionEncuadre = useCallback(async (who) => {
            console.log("updateContratacionEncuadre: ", who)
            change("id_contratacion_tipo", who.id_contratacion_tipo);
            change("ContratacionTipo.contratacion_d", who.contratacion_tipo_encuadre);
            change("unidad_negocio", who.unidad_negocio_requiere.trim());

            change("Area.area_d", who.unidad_area_d.trim());
            change("area", who.unidad_area_requiere.trim());
            change("contratacion_ano", who.contratacion_ano);
            change("contratacion_nro", who.id);

            change("id_unidad_area", who.id_unidad_area);
            change("id_unidad_negocio", who.id_unidad_negocio);

            if (who.id_contratacion_tipo > 0) {
                dataProvider.getList(
                    'ContratacionTipo',
                    {
                        filter: {id: who.id_contratacion_tipo},
                        sort: {field: 'id', order: 'DESC'},
                        pagination: {page: 1, perPage: 100},
                    },)
                    .then(({data}) => {
                        change("ContratacionTipo.contratacion_d", data[0].contratacion_d);

                    })
                    .catch(error => {

                    })
            }
        }, [form]);

        const updateUEmisora = useCallback(async (who) => {
            change("emisora", who.emisora.trim());
            change("Emisora.emisora_d", who.emisora_d.trim());
            change("id_unidad_emisora", who.id);

        }, [form]);

        const updateUGerencia = useCallback(async (who) => {
            change("id_unidad_gerencia", who.id);
            change("gerencia", who.gerencia.trim());
            change("Gerencia.gerencia_d", who.gerencia_d.trim());
        }, [form]);

        const updateUPrograma = useCallback(async (who) => {
            change("id_unidad_programa", who.id);
            change("programa", who.programa.trim());
            change("Programa.programa_d", who.programa_d.trim());

        }, [form]);

        const updateUSector = useCallback(async (who) => {
            change("id_unidad_sector", who.id);
            change("sector", who.sector.trim());
            change("Sector.sector_d", who.sector_d.trim());

        }, [form]);

        return (
            <>
                <FormDataConsumer>
                    {({formData, ...rest}) => (
                        <>

                            <TextInput source="orden_compra_nro" label="Nro. OC"
                                       className={classes.orden_compra_nro}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       fullWidth
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="orden_compra_ampliacion_nro"
                                       label="Nro. ampliación"
                                       className={classes.orden_compra_ampliacion_nro}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                            />

                            <SelectInput source="es_ampliacion" className={classes.es_ampliacion} fullWidth={true}
                                         variant="filled" InputLabelProps={{shrink: true}} label={"¿Es una ampliación?"}
                                         inputProps={{readOnly: true,}}
                                         choices={[
                                             {id: 'S', name: 'Si'},
                                             {id: 'N', name: 'No'},
                                         ]}
                            />

                            {formData.es_ampliacion === 'S' &&
                            <>
                                <div style={{display: 'inline-flex', width: '100%'}}>
                                    <h4> Seleccionar Orden de Compra de Origen </h4>

                                    <OrdenCompraBusquedaBtn callbackOrdenCompra={updateOrdenDeCompra}
                                                            className={classes.btnBusqueda} fullWidth={true}
                                    />

                                </div>

                                <br/>
                                <div style={{display: 'inline-flex', width: '100%'}}>
                                    <TextInput source="orden_compra_origen_nro" label="Nro. OC Origen"
                                               className={classes.orden_compra_origen_nro}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               fullWidth
                                               InputLabelProps={{shrink: true}}
                                    />

                                    <TextInput source="orden_compra_origen_ampliacion_nro"
                                               label="Nro. ampliación"
                                               className={classes.orden_compra_origen_ampliacion_nro}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               fullWidth
                                               InputLabelProps={{shrink: true}}
                                    />

                                    <OrdenCompraBusquedaBtn className={classes.btnBusqueda} fullWidth={true}
                                                            oc={formData.orden_compra_origen_nro}
                                                            nroAmpliacion={formData.orden_compra_origen_ampliacion_nro}
                                    />
                                </div>

                                <hr align='left' style={{width: '45%'}}/>
                                <br/>


                                <TextInput source="contratacion_nro" label={"Contratación ID"}
                                           className={classes.contratacion_nro}
                                           variant="filled" inputProps={{readOnly: true}}
                                           vfullWidth
                                           InputLabelProps={{shrink: true}}
                                />
                                <br/>
                            </>
                            }

                            {formData.es_ampliacion === 'N' &&
                            <>
                                <div style={{display: 'inline-flex', width: '100%'}}>
                                    <TextInput source="contratacion_nro" label={"Contratación Nro."}
                                               className={classes.contratacion_nro}
                                               variant="outlined" inputProps={{readOnly: true}}
                                               fullWidth
                                               InputLabelProps={{shrink: true}}
                                    />

                                    <ContratacionEncuadreBusquedaBtn
                                        callbackContratacionEncuadre={updateContratacionEncuadre}
                                        className={classes.btnBusqueda} fullWidth={true}/>
                                </div>
                                <br/>
                            </>
                            }

                            {formData.es_ampliacion &&
                            <>
                                <TextInput source="contratacion_tipo" label={"Tipo Contratación"}
                                           className={classes.contratacion_tipo}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                />

                                <TextInput source="ContratacionTipo.contratacion_d" label={"Descripción"}
                                           className={classes.contratacion_tipo_d}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                />


                                <br/>

                                <TextInput source="contratacion_ano" label={"Año Contratación"}
                                           className={classes.contratacion_ano}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                />

                                <TextInput source="unidad_negocio" label={"Unidad de Negocio"}
                                           className={classes.unidad_negocio_requiere}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                />
                                {/*
                                <TextInput source="ContratacionEncuadre.unidad_area" label={"Unidad Area"}
                                           className={classes.unidad_area_requiere}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                />*/}

                                <TextInput source="area" label={"Cod. Area"}
                                           className={classes.unidad_area_requiere_cod}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                />

                                <TextInput source="Area.area_d" label={"Unidad Area"}
                                           className={classes.unidad_area_requiere}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                />
                                <br/>

                                <div style={{display: 'inline-flex', width: '100%'}}>
                                    <TextInput source="emisora" label={"Emisora"}
                                               className={classes.emisora}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               fullWidth
                                               InputLabelProps={{shrink: true}}
                                    />

                                    <TextInput source="Emisora.emisora_d" label={"Descripción"}
                                               className={classes.descripcion}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               fullWidth
                                               InputLabelProps={{shrink: true}}
                                    />

                                    <UnidadEmisoraBusquedaBtn
                                        callbackUnidadEmisora={updateUEmisora} unidadNegocio={formData.unidad_negocio}
                                        className={classes.btnBusqueda} fullWidth={true}/>
                                </div>
                                <br/>

                                <div style={{display: 'inline-flex', width: '100%'}}>
                                    <TextInput source="gerencia" label={"Gerencia"}
                                               className={classes.gerencia}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               fullWidth
                                               InputLabelProps={{shrink: true}}
                                    />
                                    <TextInput source="Gerencia.gerencia_d" label={"Descripción"}
                                               className={classes.descripcion}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               fullWidth
                                               InputLabelProps={{shrink: true}}
                                    />

                                    <UnidadGerenciaBusquedaBtn callbackUnidadGerencia={updateUGerencia}
                                                               unidadNegocio={formData.unidad_negocio}
                                                               className={classes.btnBusqueda} fullWidth={true}/>
                                </div>
                                <br/>
                                <div style={{display: 'inline-flex', width: '100%'}}>
                                    <TextInput source="sector" label={"Sector"}
                                               className={classes.sector}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               fullWidth
                                               InputLabelProps={{shrink: true}}
                                    />
                                    <TextInput source="Sector.sector_d" label={"Descripción"}
                                               className={classes.descripcion}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               fullWidth
                                               InputLabelProps={{shrink: true}}
                                    />
                                    <UnidadSectorBusquedaBtn callbackUnidadSector={updateUSector}
                                                             unidadNegocio={formData.unidad_negocio}
                                                             className={classes.btnBusqueda} fullWidth={true}/>
                                </div>
                                <br/>
                                <div style={{display: 'inline-flex', width: '100%'}}>
                                    <TextInput source="programa" label={"Programa"}
                                               className={classes.programa}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               fullWidth
                                               InputLabelProps={{shrink: true}}
                                    />
                                    <TextInput source="Programa.programa_d" label={"Descripción"}
                                               className={classes.descripcion}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               fullWidth
                                               InputLabelProps={{shrink: true}}
                                    />
                                    <UnidadProgramaBusquedaBtn callbackUnidadPrograma={updateUPrograma}
                                                               unidadNegocio={formData.unidad_negocio}
                                                               className={classes.btnBusqueda} fullWidth={true}/>
                                </div>
                            </>
                            }

                            <TextInput source="procedimiento"
                                       label={"Procedimiento"} variant="outlined"
                                       InputLabelProps={{shrink: true}}
                                       className={classes.procedimiento} fullWidth={true}/>
                            <br/>

                            <TextInput label="Expediente" source="procedimeinto_referencia_gde"
                                       className={classes.expediente}
                                       fullWidth
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       variant="filled" inputProps={{readOnly: true,}}
                            />
                            <br/>
                            <TextInput source="informe_adjudicacion"
                                       label={"Informe Adjudicación"} variant="outlined"
                                       InputLabelProps={{shrink: true}}
                                       className={classes.informe_adjudicacion} fullWidth={true}/>
                            <br/>
                            <TextInput source="informe_adjudicacion_referencia_gde"
                                       label={"Referencia Adjudicación GDE"} variant="outlined"
                                       InputLabelProps={{shrink: true}}
                                       className={classes.informe_adjudicacion_referencia_gde} fullWidth={true}/>
                        </>
                    )}

                </FormDataConsumer>
            </>
        );
    }
;

export default OrdenDeCompraContratacion;
