import React from 'react'
import {
    Datagrid,
    List,
    TextField,
} from 'react-admin';

export const EstadoProveedor = (props) => (
    <List {...props} title="Listado de Estados del Prove."
          bulkActionButtons={false}
          perPage={25}
    >

        <Datagrid>
            <TextField label="Estado" source="estado_proveedor" sortable={false}/>
            <TextField label="Descripción" source="estado_proveedor_d" sortable={false}/>
            <TextField label="Abrev." source="estado_proveedor_abre" sortable={false}/>
            <TextField label="Activo a operar S/N" source="activo_para_operar" sortable={false}/>
        </Datagrid>
    </List>
);


export default EstadoProveedor;
