import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    SelectInput,
    TextInput,
    Filter,
    Responsive,
    CardActions, DateField, NumberField, Link, Button
} from 'react-admin';
import LinkConsultarButton from './LinkConsultarButton'
import {UserUsuComEdit} from "./UserUsuComEdit";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import PersonIcon from '@material-ui/icons/Person';
import ApartmentIcon from '@material-ui/icons/Apartment';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import VisibilityIcon from "@material-ui/icons/Visibility";
import NotificationsIcon from "@material-ui/icons/Notifications";
import TodayIcon from "@material-ui/icons/Today";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import EmailIcon from '@material-ui/icons/Email';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DialpadIcon from '@material-ui/icons/Dialpad';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BlockIcon from '@material-ui/icons/Block';
import GavelIcon from '@material-ui/icons/Gavel';
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PasswordButtonEdit from "./PasswordButtonEdit";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';

const FiltroUserUsuCom = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="q" alwaysOn />
        {/*<TextInput label="Proveedor" source="pro"/>*/}
        {/*<TextInput label="Razón Social"  source="raz"/>*/}
        {/*<SelectInput*/}
        {/*    source="hay_correo_pro"*/}
        {/*    label="Correo Inf. Pago"*/}
        {/*    choices={[*/}
        {/*        {id: 'SI', name: 'C/Correo Inf. Pago'},*/}
        {/*        {id: 'NI', name: 'S/Correo Inf. Pago'},*/}
        {/*        {id: 'NT', name: 'Sin contacto'}*/}
        {/*    ]}*/}
        {/*/>*/}

        {/*<SelectInput*/}
        {/*    source="usa_role_user"*/}
        {/*    // label="ROLE_MLADOS"*/}
        {/*    label={ <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
        {/*        <ViewModuleIcon style={{ color: '#35c81d' }}/>{'Funcionamiento de Modulo'}*/}
        {/*    </div>}*/}
        {/*    choices={[*/}
        {/*        {id: 'S', name: 'SI'},*/}
        {/*        {id: 'N', name: 'NO'},*/}
        {/*    ]} alwaysOn*/}
        {/*/>*/}

        <SelectInput
            source="enabled_u"
            // label="ROLE_MLADOS"
            label={ <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                <AccessibilityIcon style={{color: '#008000'}}/>{'Usuario Habilitado'}
            </div>}
            choices={[
                {id: 'S', name: 'SI'},
                {id: 'N', name: 'NO'},
            ]} alwaysOn
        />


        <SelectInput
            source="usa_role_secu"
            // label="ROLE_MLADOS_SECU"
            label={ <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                <AssignmentIndIcon style={{color: '#dcaadc'}}/>{'Usuarios del Sistema'}
            </div>}
            choices={[
                {id: 'S', name: 'SI'},
                {id: 'N', name: 'NO'},
            ]} alwaysOn
        />
    </Filter>
);

const PostActions = ({ resource, filters, displayedFilters, filterValues, basePath, showFilter }) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <Link to={`/UserUsuCom/create`}>
            <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                <Button
                    label="Crear Usuario"
                >
                    <AddIcon/>
                </Button>
            </div>
        </Link>
        <Link to={`/UserCopy/create`}>
            <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                <Button
                    label="Copiar de Otro Usuario"
                >
                    <FileCopyIcon/>
                </Button>
            </div>
        </Link>
        {/*<Link to={`/UsuComArea/`}>*/}
        {/*    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
        {/*        <Button*/}
        {/*            label="Lista Usuario Area"*/}
        {/*        >*/}
        {/*            <div>*/}
        {/*                <ListIcon/>*/}
        {/*                <FontDownloadIcon/>*/}
        {/*            </div>*/}
        {/*        </Button>*/}
        {/*    </div>*/}
        {/*</Link>*/}
        {/*<Link to={`/UsuComArea/create/`}>*/}
        {/*    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
        {/*        <Button*/}
        {/*            label="Crear Usuario Area"*/}
        {/*        >*/}
        {/*            <div>*/}
        {/*                <AddIcon/>*/}
        {/*                <FontDownloadIcon/>*/}
        {/*            </div>*/}
        {/*        </Button>*/}
        {/*    </div>*/}
        {/*</Link>*/}
        {/*<div>*/}
        {/*    <LineWeightIcon titleAccess={'Sin contacto'} style={{color: '#c0c0c0'}} ></LineWeightIcon>{'Sin contacto'}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*    <LineWeightIcon titleAccess={'C/Correo Inf. Pago'} style={{color: '#8080ff'}} ></LineWeightIcon>{'C/Correo Inf. Pago'}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*    <LineWeightIcon titleAccess={'S/Correo Inf. Pago'} style={{color: '#ffaaaa'}} ></LineWeightIcon>{'S/Correo Inf. Pago'}*/}
        {/*</div>*/}
    </CardActions>
);

// expand={<UserUsuComInfo/>}
//xsmall={<MobileUserUsuComGrid />}

// Grilla general
export const UserUsuComList = (props) =>  {
    return (
        <div>
            <div style={{ margin: '1em' }}>
                <List {...props} title="Listado de Usuarios del Sistema" actions={<PostActions/>} perPage={25}
                      bulkActionButtons={false} exporter={false}
                      filters={<FiltroUserUsuCom />}>
                    <Responsive
                        xsmall={
                            <Datagrid rowClick="expand" rowStyle={RowStyle}
                                      // expand={<UserUsuComEdit/>}
                            >
                                <TextField label="Usuario"  source="username"/>
                                {/*<LinkConsultarButton/>*/}
                            </Datagrid>
                        }
                        medium={
                            <Datagrid rowClick="expand" rowStyle={RowStyle}
                                      // expand={<UserUsuComEdit/>}
                            >
                                <TextField label={
                                        <div title={'Nombre de Usuario'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <PersonIcon style={{color: '#0064c8'}} />
                                        </div>
                                    }
                                           source="username" sortable={false}
                                />
                                <PasswordButtonEdit
                                    label={
                                        <div title={'Cambiar Contraseña'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <EditIcon style={{color: '#0064c8'}} />
                                            <VpnKeyIcon style={{color: '#0064c8'}} />
                                        </div>
                                    }
                                />
                                {/*<TextField label="Version"  source="version"/>*/}
                                <TextField
                                    label={
                                        <div title={'Cuenta Vencida'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <ReceiptIcon style={{color: '#808080'}}/>
                                            <GavelIcon style={{color: '#808080'}}/>
                                        </div>
                                    }
                                    // label="Cuenta Vencida"
                                           source="account_expired_u" sortable={false}
                                           style={{textAlign: 'center', margin: '1em'}}
                                />
                                <TextField
                                    label={
                                        <div title={'Cuenta Bloqueada'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <ReceiptIcon style={{color: '#ff0000'}}/>
                                            <BlockIcon style={{color: '#ff0000'}}/>
                                        </div>
                                    }
                                    // label="Cuenta Bloqueada"
                                           source="account_locked_u" sortable={false}
                                           style={{textAlign: 'center', margin: '1em'}}
                                />
                                <TextField
                                    label={
                                        <div title={'Usuario Habilitado'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <AccessibilityIcon style={{color: '#008000'}}/>
                                        </div>
                                    }
                                    // label="Usuario Habilitado"
                                           source="enabled_u" sortable={false}
                                           // style={{textAlign: 'center', margin: '1em'}}
                                />
                                <TextField
                                    label={
                                        <div title={'Password Vencida'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <VisibilityOffIcon style={{color: '#ff0000'}}/>
                                        </div>
                                    }
                                    // label="Password Vencida"
                                           source="password_expired_u" sortable={false}
                                           // style={{textAlign: 'center', margin: '1em'}}
                                />

                                <TextField label={
                                    <div title={'Es Administrador'}
                                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                        <SupervisorAccountIcon/>
                                    </div>
                                }
                                           source="es_administrador" sortable={false}
                                           // style={{textAlign: 'center', margin: '1em'}}
                                />
                                <TextField label={
                                        <div    title={'Numero de Whatsapp'}
                                                style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <DialpadIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                            <WhatsAppIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                            {'Numero de Whatsapp'}
                                        </div>
                                    }
                                           source="whatsapp_nro" sortable={false}
                                />
                                <TextField label={
                                    <div title={'Enviar Whatsapp'}
                                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                        <AddToHomeScreenIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                        <WhatsAppIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                        {'Enviar Whatsapp'}
                                    </div>
                                }
                                           source="enviar_whatsapp" sortable={false}
                                           style={{textAlign: 'center', margin: '1em'}}
                                />
                                <TextField label={
                                    <div    title={'E-Mail (Correo)'}
                                            style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                        <ContactMailIcon/>
                                        {'E-Mail (Correo)'}
                                    </div>
                                }
                                           source="email" sortable={false}
                                />
                                <TextField label={
                                        <div title={'Enviar Correo'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <AddToHomeScreenIcon/>
                                            <EmailIcon/>
                                            {'Enviar Correo'}
                                        </div>
                                    }
                                        source="enviar_correo" sortable={false}
                                        style={{textAlign: 'center', margin: '1em'}}
                                />

                                <TextField label={
                                        <div title={'Enviar Notificacion'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <AddToHomeScreenIcon style={{color: '#ff0000'}}/>
                                            <NotificationsIcon style={{color: '#ff0000'}}/>
                                            {'Enviar Notificacion'}
                                        </div>
                                    }
                                            source="enviar_notificacion" sortable={false}
                                            style={{textAlign: 'center', margin: '1em'}}
                                />


                                <LinkConsultarButton
                                    label={
                                        <div title={'Mostrar y Editar Usuario'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <RemoveRedEye style={{fontSize: 30, color: '#0064c8'}}/>
                                        </div>
                                    }
                                />
                            </Datagrid>
                        }
                    />
                </List>
            </div>
        </div>
    )
};

const RowStyle = (record, index) => ({
    backgroundColor:  record.usa_role_secu === undefined
        || record.usa_role_user === undefined
    || record.account_locked_u === undefined
    || record.enabled_u === undefined? '' :
            record.usa_role_secu.trim() === 'S'
            ? '#dcaadc'
            :  record.usa_role_user.trim() === 'S'
            ? '#35c81d'
            : record.enabled_u.trim() === 'NO'
            ? '#de0000' :
            record.account_locked_u.trim() === 'SI'
            ? '#c0c0c0' :
            record.enabled_u.trim() === 'SI'
            ? '#008000' :
            '',
});

export default UserUsuComList;



