import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";


const useStyles = makeStyles((theme) => ({
    pais: {display: 'inline-block', width: 400, marginLeft: 8},
    pais_codigo_afip: {display: 'inline-block', width: 200, marginLeft: 8},
    pais_codigo_gestion: {display: 'inline-block', width: 200, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_pais = values.pais

    if (w_pais===undefined) {
        errors.pais = ['El campo Código es requerido'];
    }else {
        if (w_pais === null) {
            errors.pais = ['El campo Código es requerido'];
        } else {
            w_pais = w_pais.trim()
            if (w_pais === '') {
                errors.pais = ['El campo Código es requerido'];
            }
        }
    }


    var w_pais_codigo_afip = values.pais_codigo_afip

    if (w_pais_codigo_afip===undefined) {
        errors.pais_codigo_afip = ['El campo Código AFIP es requerido'];
    }else {
        if (w_pais_codigo_afip === null) {
            errors.pais_codigo_afip = ['El campo Código AFIP es requerido'];
        } else {
            w_pais_codigo_afip = w_pais_codigo_afip.trim()
            if (w_pais_codigo_afip === '') {
                errors.pais_codigo_afip = ['El campo Código AFIP es requerido'];
            }
        }
    }

    var w_pais_codigo_gestion = values.pais_codigo_gestion

    if (w_pais_codigo_gestion===undefined) {
        errors.pais_codigo_gestion = ['El campo Código Gestion es requerido'];
    }else {
        if (w_pais_codigo_gestion === null) {
            errors.pais_codigo_gestion = ['El campo Código Gestion es requerido'];
        } else {
            w_pais_codigo_gestion = w_pais_codigo_gestion.trim()
            if (w_pais_codigo_gestion === '') {
                errors.pais_codigo_gestion = ['El campo Código Gestion es requerido'];
            }
        }
    }


    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/UnidadEmisora`;

const PaisCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Pais</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS PAIS">

                    <NumberInput
                        id="standard-full-width"
                        label="Descripción Código AFIP"
                        source="pais_codigo_afip"
                        formClassName={classes.pais_codigo_afip}
                        placeholder="Ingrese Código AFIP"
                        helperText="Código AFIP"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    <NumberInput
                        id="standard-full-width"
                        label="Descripción Código Gestion"
                        source="pais_codigo_gestion"
                        formClassName={classes.pais_codigo_gestion}
                        placeholder="Ingrese Código Gestion"
                        helperText="Código Gestion"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    <TextInput
                        label="Pais"
                        source="pais"
                        formClassName={classes.pais}
                        placeholder="Ingrese Pais"
                        helperText="Pais"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default PaisCreate;


