import React from 'react';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import Button from "@material-ui/core/Button";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextFieldColorElegir from "./TextFieldColorElegir"

const TextFieldColor = ({record}) => {
    var w_color = record.color
    if(w_color==null){
        w_color=''
    }
    w_color=w_color.trim()

    var wr_id = record.id

    var wr_tabla = record.tabla
    if(wr_tabla==null){
        wr_tabla=''
    }
    wr_tabla=wr_tabla.trim()

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <div title={'Color de la grilla'}
                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <LineWeightIcon style={{fontSize: 40, color: w_color}}/>
                    Editar Color
                </div>
            </Button>
            <Dialog open={open} fullScreen={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Editar Color</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Salir
                    </Button>
                </DialogActions>
                <DialogContent>
                    <div title={'Color de la grilla Actualmente'}
                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        Color Actualmente
                        <LineWeightIcon style={{fontSize: 40, color: w_color}}/>
                    </div>
                    <TextFieldColorElegir id={wr_id} color={w_color} tabla={wr_tabla}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Salir
                    </Button>
                </DialogActions>
            </Dialog>
        </div>


    );

}


TextFieldColor.defaultProps = {
    source: '',
    addLabel: true,
};

export default TextFieldColor;