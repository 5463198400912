import React, {useState} from 'react';
import {
    useQuery,
    Datagrid,
    TextField,
    Pagination,
    Loading, ListContextProvider
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import IconBuscar from '@material-ui/icons/Search';
import IconSeleccionar from '@material-ui/icons/Done';
import {makeStyles} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {TextField as TextFieldMUI} from '@material-ui/core';
import IconCancelar from '@material-ui/icons/Close';

import YoutubeSearchedForIcon from "@material-ui/icons/YoutubeSearchedFor";

export const UnidadAreaBusquedaDatagrid = (props) => {

    // console.log(props)

    var wr_props_area_ing = props.area
    var wr_unidad_negocio_ing = props.unidad_negocio
    var wr_usar_todos_ing = props.usar_todos

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(12);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})
    const [filter, setFilter] = useState('');
    const [busqueda, setBusqueda] = useState('');

    const useStyles = makeStyles({
        button: {},
    });
    const classes = useStyles();

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'UnidadArea',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: {
                q: filter,
                area_ing: wr_props_area_ing,
                unidad_negocio: wr_unidad_negocio_ing, usar_todos: wr_usar_todos_ing
            },
        }
    });

    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    /*Botón seleccionar registro*/
    const BtnConfirmar = ({record}) => (
        <IconButton style={{fontSize: 15, padding: 2}}
                    onClick={() => {
                        props.callbackUnidadArea(record);
                        props.callbackCloseDrawer(false)
                    }}
        >

            <IconSeleccionar style={{fontSize: 20}}/>
        </IconButton>
    );

    /*Botón de búsqueda*/
    const BtnBuscar = ({event}) => (
        <Button
            onClick={() => {
                setFilter(busqueda);
                setPage(1);
                setPerPage(10);
            }}
            style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
            size={'small'}
            startIcon={<IconBuscar/>}
        >
            Buscar
        </Button>
    );

    /*Botón de borrar*/
    const BtnBorrar = ({event}) => (
        <Button
            onClick={() => {
                setFilter('');
                setBusqueda('');
                setPage(1);
                setPerPage(10);
            }}
            style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
            size={'small'}
            startIcon={<YoutubeSearchedForIcon/>}
        >
            Borrar
        </Button>
    );

    const BtnVolver = ({record}) => (
        <Button variant="contained" size={"small"} color="primary"
                onClick={() => {
                    props.callbackCloseDrawer(false)
                }}
                startIcon={<IconCancelar/>}
                style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
        >
            Volver
        </Button>
    );

    const Btntitulo = ({event}) => (
        <h4>{'Unidad Area'}</h4>
    );


    return (
        <ListContextProvider value={{data, total, page, perPage, setPage, filter, setFilter, sort}}>
            <div style={{margin: '3em'}}>
                <Btntitulo/>
                <TextFieldMUI label="Buscar"
                              onChange={(v) => setBusqueda(v.target.value)}
                              defaultValue={filter}
                              style={{marginLeft: '2px', width: '200px', height: '50px'}}
                />

                <BtnBuscar/>

                {filter === '' ? '' :
                    <BtnBorrar/>
                }

                <Datagrid rowStyle={RowStyle}
                          data={keyBy(data, 'id')}
                          ids={data.map(({id}) => id)}
                          currentSort={sort}
                          setSort={(field, order) => setSort({field, order})}
                >
                    <BtnConfirmar/>
                    <TextField label="Código Unidad Area" source="area"/>
                    <TextField label="Descripción Unidad Area" source="area_d"/>
                </Datagrid>
                <Pagination
                    rowsPerPageOptions={[]}
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    total={total}
                />
                <BtnVolver/>
            </div>
        </ListContextProvider>
    )
};

const RowStyle = (record, index) => ({
    backgroundColor: record.area === undefined || record.area_ing === undefined ? '' :
        record.area.trim() === record.area_ing.trim() ? '#64c8ff' : '',
});

export default UnidadAreaBusquedaDatagrid;