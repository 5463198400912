import React, {useState} from 'react';
import {
    useNotify, useRefresh, useMutation, ReferenceInput, SelectInput, useDataProvider
} from 'react-admin';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import {Drawer} from "@material-ui/core";
import IconCancelar from '@material-ui/icons/Close';
import IconEdit from '@material-ui/icons/Edit';

const useStyles = makeStyles({
    socio_nro_ingreso: {display: 'inline-block', width: 100},


    tipoDocConyuge: {display: 'inline-block', width: 150,},
    nombre: {display: 'inline-block', width: 150,},
    apellido: {display: 'inline-block', width: 150, marginLeft: 28},

    documento_tipo: {display: 'inline-block', width: 80},
    documento_nro: {display: 'inline-block', width: 120, marginLeft: 28},
    cuit_cuil: {display: 'inline-block', width: 150, marginLeft: 28},

    correo_electronico: {display: 'inline-block', width: 350},

    estado_civil: {display: 'inline-block', width: 120},
    socio_activo: {display: 'inline-block', width: 80},
});

const ProveedorSocioEditar = ({record}) => {
    const classes = useStyles();
    const dataProviderTableroControl = useDataProvider();
    const [open, setOpen] = React.useState(false);
    const [idSocio, setIdSocio] = useState(record.id);
    const [proveedor, setEstadoproveedor] = useState('');
    const [nombre_socio, SetStateNombre_socio] = useState('');
    const [apellido_socio, SetStateApellido_socio] = useState(record.apellido_socio);
    const [cuit_cuil_socio, SetStateCuit_cuil_socio] = useState('');
    const [id_documento_tipo_socio, SetStateId_documento_tipo_socio] = useState('');
    const [documento_nro_socio, SetStateDocumento_nro_socio] = useState('');
    const [correo_electronico_socio, SetStateCorreo_electronico_socio] = useState('');
    const [correo_electronico_alternativo_socio, SetStateCorreo_electronico_alternativo_socio] = useState('');
    const [id_estado_civil, SetStateId_estado_civil] = useState('');
    const [conyuge_nombre, SetStateConyuge_nombre] = useState('');
    const [conyuge_apellido, SetStateConyuge_apellido] = useState('');
    const [conyuge_cuit_cuil, SetStateConyuge_cuit_cuil] = useState('');
    const [id_conyuge_documento_tipo, Set_id_conyuge_documento_tipo] = useState('');
    const [conyuge_documento_nro, SetStateConyuge_documento_nro] = useState('');
    const [socio_activo, SetStateSocio_activo] = useState('');
    const [ComboDocumentoTipo, setComboDocumentoTipo] = useState([]);

    const BtnCancelar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
            setOpen(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const BotonCrearDetelle = (props) => {
        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'update',
                resource: 'ProveedorSocio',
                payload: {
                    id: idSocio,
                    data: {
                        proveedor,
                        nombre_socio,
                        apellido_socio,
                        cuit_cuil_socio,
                        id_documento_tipo_socio,
                        documento_nro_socio,
                        correo_electronico_socio,
                        correo_electronico_alternativo_socio,
                        id_estado_civil,
                        conyuge_nombre,
                        conyuge_apellido,
                        conyuge_cuit_cuil,
                        id_conyuge_documento_tipo,
                        conyuge_documento_nro,
                        socio_activo
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle}
                    disabled={props.pristine}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <SaveIcon style={{color: '#004187'}}/>
                    Guardar
                </div>
            </Button>
        );

    };

    /// JN Mascara para el input cuit-cuil
    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    const handleClickOpen = () => {
        setIdSocio(record.id);
        setEstadoproveedor(record.proveedor);
        SetStateNombre_socio(record.nombre);
        SetStateApellido_socio(record.apellido);
        SetStateCuit_cuil_socio(record.cuit_cuil);
        SetStateId_documento_tipo_socio(record.id_documento_tipo);
        SetStateDocumento_nro_socio(record.documento_nro);
        SetStateCorreo_electronico_socio(record.correo_electronico);
        SetStateCorreo_electronico_alternativo_socio(record.correo_electronico_alternativo);
        SetStateId_estado_civil(record.estado_civil_casado);
        SetStateConyuge_nombre(record.conyuge_nombre);
        SetStateConyuge_apellido(record.conyuge_apellido);
        SetStateConyuge_cuit_cuil(record.conyuge_cuit_cuil);
        Set_id_conyuge_documento_tipo(record.id_conyuge_documento_tipo);
        SetStateConyuge_documento_nro(record.conyuge_documento_nro);
        SetStateSocio_activo(record.socio_activo);

        dataProviderTableroControl.getList(
            'DocumentoTipo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'S'},
                sort: {field: 'orden', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboDocumentoTipo(data);
            })
            .catch(error => {
                setOpen(false);
            })

        setOpen(true);
    };
    const formatIdTipoDocSocio = v => {
        return id_documento_tipo_socio
    };
    const formatIdTipoDocSocioConyuge = v => {
        return id_conyuge_documento_tipo
    };
    const formatSocioActivo = v => {
        return socio_activo
    };
    const formatEstadioCivil = v => {
        return id_estado_civil
    };

    return (
        <div>
            <Button variant="text" style={{color: '#123B80'}} size={"small"} onClick={handleClickOpen}>
                <IconEdit/>
                Editar
            </Button>

            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <div>
                    <h3>Editar Socio</h3>
                </div>
                <br/>
                <div style={{paddingLeft: "10px"}}>

                    <TextField source={"nombre_socio"} label={"Nombre"} variant={"outlined"}
                               InputLabelProps={{shrink: true}}
                               value={nombre_socio} onChange={(event) => SetStateNombre_socio(event.target.value)}
                               className={classes.nombre} fullWidth/>

                    <TextField source={"apellido_socio"} label={"Apellido"} variant={"outlined"}
                               value={apellido_socio} onChange={(event) => SetStateApellido_socio(event.target.value)}
                               InputLabelProps={{shrink: true}}
                               className={classes.apellido} fullWidth/><br/> <br/>

                    <ReferenceInput reference="DocumentoTipo" variant="outlined"
                                    label="Tipo Doc." source="id_documento_tipo_socio"
                                    className={classes.documento_tipo} sort={{field: 'orden', order: 'DESC'}}
                                    inputProps={{
                                        onChange: (e) => {
                                            SetStateId_documento_tipo_socio(e.target.value);
                                        }
                                    }}
                                    format={formatIdTipoDocSocio}
                    >
                        <SelectInput InputLabelProps={{shrink: true}} optionText="documento_tipo_d" fullWidth={true}
                                     className={classes.documento_tipo_d}
                        />
                    </ReferenceInput>

                    <TextField source="documento_nro_socio" label={"Nro. Doc."} variant="outlined"
                               value={documento_nro_socio}
                               InputLabelProps={{shrink: true}}
                               onChange={(event) => SetStateDocumento_nro_socio(event.target.value)}
                               className={classes.documento_nro} fullWidth={true}/>

                    <TextField source="cuit_cuil_socio" label={"CUIT/CUIL"} variant="outlined"
                               InputLabelProps={{shrink: true}} value={cuit_cuil_socio}
                               className={classes.cuit_cuil} fullWidth={true}
                               format={cuitCuilFormat}
                               onChange={(event) => SetStateCuit_cuil_socio(event.target.value)}
                    /><br/>

                    <TextField source={"correo_electronico_socio"} label={"correo_electronico"} variant={"outlined"}
                               InputLabelProps={{shrink: true}} className={classes.correo_electronico}
                               value={correo_electronico_socio}
                               onChange={(event) => SetStateCorreo_electronico_socio(event.target.value)}
                               fullWidth/>
                    <br/> <br/>

                    <TextField source={"correo_electronico_alternativo_socio"} label={"correo_electronico_alternativo"}
                               variant={"outlined"} InputLabelProps={{shrink: true}}
                               value={correo_electronico_alternativo_socio}
                               className={classes.correo_electronico}
                               onChange={(event) => SetStateCorreo_electronico_alternativo_socio(event.target.value)}
                               fullWidth/><br/>
                    <br/>

                    <SelectInput source="id_estado_civil"
                                 label="¿Es casado?" InputLabelProps={{shrink: true}}
                                 className={classes.socio_activo}
                                 fullWidth={true} variant="outlined"
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}
                                 inputProps={{
                                     onChange: (e) => {
                                         SetStateId_estado_civil(e.target.value);
                                     }
                                 }}
                                 format={formatEstadioCivil}
                    />

                    {id_estado_civil === 'S' &&
                    <>
                        <h3>Datos Cónyuge</h3>
                        <TextField source={"conyuge_nombre"} label={"nombre"} variant={"outlined"}
                                   InputLabelProps={{shrink: true}} className={classes.nombre}
                                   onChange={(event) => SetStateConyuge_nombre(event.target.value)}
                                   value={conyuge_nombre}
                                   fullWidth/>

                        <TextField source={"conyuge_apellido"} label={"apellido"} variant={"outlined"}
                                   InputLabelProps={{shrink: true}} className={classes.apellido}
                                   onChange={(event) => SetStateConyuge_apellido(event.target.value)}
                                   value={conyuge_apellido}
                                   fullWidth/><br/><br/>

                        <TextField
                            id="id_conyuge_documento_tipo_ing"
                            select
                            className={classes.tipoDocConyuge}
                            label="Tipo Doc."
                            size="medium"
                            variant={"outlined"}
                            value={id_conyuge_documento_tipo}
                            onChange={(event) => Set_id_conyuge_documento_tipo(event.target.value)}
                            SelectProps={{
                                native: true,
                            }}
                            InputLabelProps={{shrink: true}}
                        >
                            {ComboDocumentoTipo.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.documento_tipo_d}
                                </option>
                            ))}
                        </TextField>


                        <TextField source="conyuge_documento_nro" label={"Nro. Doc."} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   onChange={(event) => SetStateConyuge_documento_nro(event.target.value)}
                                   className={classes.documento_nro} fullWidth={true} value={conyuge_documento_nro}
                        />

                        <TextField source="conyuge_cuit_cuil" label={"CUIT/CUIL"} variant="outlined"
                                   InputLabelProps={{shrink: true}} value={conyuge_cuit_cuil}
                                   className={classes.cuit_cuil} fullWidth={true}
                                   format={cuitCuilFormat}
                                   onChange={(event) => SetStateConyuge_cuit_cuil(event.target.value)}
                        />
                    </>
                    }
                    <br/>

                    <SelectInput source="socio_activo" value={socio_activo}
                                 label="Activo" InputLabelProps={{shrink: true}}
                                 className={classes.socio_activo}
                                 inputProps={{
                                     onChange: (e) => {
                                         SetStateSocio_activo(e.target.value);
                                     }
                                 }}
                                 format={formatSocioActivo}
                                 fullWidth={true}
                                 variant="outlined"
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}
                    />

                    <div>

                        {/* {selectedClases.length > 0 &&
                    <BtnProcesasClases pro={props.pro} data={selectedClases}/>
                    }
                    {' '}*/}
                        <BotonCrearDetelle/>

                        <BtnCancelar/>


                    </div>
                </div>
            </Drawer>
        </div>
    )
        ;

}

ProveedorSocioEditar.defaultProps = {
    source: '',
    addLabel: true,
};

export default ProveedorSocioEditar;