import React, {useState} from 'react'
import {
    Loading, useQuery, Datagrid, TextField, Pagination, Button, useDelete, useRefresh, ListContextProvider
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import IconEliminar from '@material-ui/icons/Delete';
import ProveedorApoderadoEditar from "./ProveedorApoderadoEdit";

export const ProveedorApoderadoList = (props) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'ProveedorApoderado',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: props.pro,
        }
    });

    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    // Eliminar Fila
    const BtnEliminar = ({record}) => {
        const refresh = useRefresh();
        const [deleteOne, {isLoading, error}] = useDelete(
            'proveedorApoderado',
            record.id
        );
        const handleClick = () => {
            deleteOne();
            refresh();
        };
        if (error) {
            return <p>ERROR</p>;
        }
        return <Button variant="text" size={"small"} color="primary"
                       onClick={handleClick}
                       disabled={loading}
                       label={'Eliminar'}
        >
            <IconEliminar style={{color: 'red'}}/>
        </Button>


    };
    return (
        <ListContextProvider value={{data, total, page, perPage, setPage, sort}}>
            <Datagrid
                data={keyBy(data, 'id')}
                ids={data.map(({id}) => id)}
                currentSort={sort}
                setSort={(field, order) => setSort({field, order})}
            >
                <TextField source="apoderado_nro_ingreso" label={"Nro Ingreso"}/>

                <TextField source="nombre" label={"Nombre"}/>
                <TextField source="apellido" label={"Apellido"}/>
                <TextField source="cuit_cuil" label={"Cuit-Cuil"}/>
                <TextField source="documento_tipo_d" label={"Tipo Doc."}/>
                <TextField source="documento_nro" label={"Nro. Doc."}/>
                <TextField source="correo_electronico" label={"Email"}/>
                <TextField source="correo_electronico_alternativo" label={"Email alt."}/>
                <TextField source="limite_para_oferta_electronica" label={"Límite Oferta Electr."}/>
                <TextField source="limite_para_oferta_electronica_pesos" label={"Límite en pesos"}/>
                <TextField source="es_administrador_legitimado" label={"Es Administrador Legitimado"}/>
                <TextField source="apoderado_activo" label={"Activo S/N"}/>
                <ProveedorApoderadoEditar/>
                <BtnEliminar/>

            </Datagrid>
            <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                total={total}
            />
        </ListContextProvider>
    );
};

export default ProveedorApoderadoList;



