import React from 'react'
import {
    Datagrid,
    EditButton,
    List,
    TextField,
    TextInput,
    Filter,
    DateField,
    NumberInput,
    DateInput,
    NumberField,
    ReferenceInput, SelectInput, ShowButton,
    CardActions, WithPermissions, TopToolbar,
} from 'react-admin';
import AuditoriaButton from "../componentes/AuditoriaButton";
import ContratacionEncuadreInfo from "../ContratacionEncuadre/ContratacionEncuadreInfo";
import Vertitle from "./Vertitle";
import withStyles from "@material-ui/core/styles/withStyles";
import ProveedorAdjudicadoButton from "../TableroControl/ProveedorAdjudicadoButton";
import ContratacionObservacionesButton from "../TableroControl/ContratacionObservacionesButton";
import SeguimientoCompletoButton from "../TableroControlContratacion/SeguimientoCompletoButton";
import LinearProgresoCumplidoDiasPorcentajeCompleto
    from "../TableroControlContratacion/LinearProgresoCumplidoDiasPorcentajeCompleto";
import ExportExcelButton from "./ExportExcelButton";


const Filtros = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" variant="outlined" source="q" alwaysOn/>
        <ReferenceInput label='Unidad'
                        source="id_unidad_negocio"
                        reference="UnidadNegocio"
                        onChange={(event, username, record) => console.log(`New deviceId `)}
                        alwaysOn
                        perPage={100000}
        >
            <SelectInput
                optionText="unidad_negocio_d"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <ReferenceInput label='Area'
                        source="id_unidad_area"
                        reference="UnidadArea"
                        onChange={(event, username, record) => console.log(`New deviceId `)}
                        perPage={100000}
                        alwaysOn
        >
            <SelectInput
                optionText="area_cd"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <ReferenceInput label='Tipo'
                        source="id_contratacion_tipo"
                        reference="ContratacionTipo"
                        onChange={(event, username, record) => console.log(`New deviceId `)}
                        alwaysOn
                        perPage={100000}
        >
            <SelectInput
                optionText="contratacion_d"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <ReferenceInput label='Estado'
                        source="id_estado_encuadre"
                        reference="ContratacionEstado"
                        onChange={(event, username, record) => console.log(`New deviceId `)}
                        alwaysOn
                        perPage={100000}
        >
            <SelectInput
                optionText="estado_encuadre"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <TextInput label="Año" source="contratacion_ano_ing" alwaysOn/>

        <NumberInput label="ID" source="id" alwaysOn/>
            {/*<SelectInput*/}
            {/*    source="visitas"*/}
            {/*    label="Visitas"*/}
            {/*    choices={[*/}
            {/*        {id: 'HOY', name: 'HOY'},*/}
            {/*        {id: 'AYER', name: 'AYER'},*/}
            {/*        {id: 'ENLASEMANA', name: 'EN LA SEMANA'},*/}
            {/*        {id: 'ANTERIORMENTE', name: 'ANTERIORMENTE'},*/}
            {/*        {id: 'SINVISITAS', name: 'SIN VISITAS'}*/}
            {/*    ]}*/}
            {/*    alwaysOn*/}
            {/*/>*/}

            {/*<TextInput label="Código Contratacion Estado" source="estado_encuadre" alwaysOn />*/}
            {/*<TextInput label="Descripción Contratacion Estado" source="estado_encuadre_d" alwaysOn />*/}


    </Filter>
);

const role_exportar_xls = ({permissions}) => {
    var w_role_control = '';
    var w_permissions_control = permissions;
    if (w_permissions_control == null) {
    } else {
        var w_role_exportar_xls = w_permissions_control.indexOf('ROLE_EXPORTAR_XLS');
        if (w_role_exportar_xls === -1) {
        } else {
            w_role_control = 'ROLE_EXPORTAR_XLS'
        }
    }
    return w_role_control
};

const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <TopToolbar>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}

        <ExportExcelButton total={999999} fileName={"Contrataciones Encuadre Gerente"} sheetName={"Contrataciones"}/>
      {/*  <WithPermissions
            render={({permissions}) => (
                role_exportar_xls({permissions}) === 'ROLE_EXPORTAR_XLS'
                    ?
                    <ExportExcelButton total={999999} fileName={"Contrataciones Encuadre Gerente"} sheetName={"Contrataciones"}/>
                    : null

            )}
        />*/}

        {/*<CreateButton/>*/}
    </TopToolbar>
);

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};

const role_contratacion_consulta_mostrar_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
} else {
    var w_role_contratacion_consulta_mostrar = w_permissions_control.indexOf('ROLE_CONTRATACION_CONSULTA_MOSTRAR')
    if (w_role_contratacion_consulta_mostrar === -1) {
} else {
    w_role_control = 'ROLE_CONTRATACION_CONSULTA_MOSTRAR'
}
}

    return w_role_control
}

const role_auditoria_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
} else {
    var w_role_auditoria = w_permissions_control.indexOf('ROLE_AUDITORIA')
    if (w_role_auditoria === -1) {
} else {
    w_role_control = 'ROLE_AUDITORIA'
}
}

    return w_role_control
}

const role_contratacion_gerente_inicio = ({permissions}) => {
    var w_role_contratacion_gerente_inicio = ''
    var w_permissions_contratacion_gerente_menu = permissions
    if (w_permissions_contratacion_gerente_menu == null) {
} else {
    var w_role_contratacion_gerente_inicio_aux = w_permissions_contratacion_gerente_menu.indexOf('ROLE_CONTRATACION_GERENTE_INICIO')
    if (w_role_contratacion_gerente_inicio_aux === -1) {
} else {
    w_role_contratacion_gerente_inicio = 'ROLE_CONTRATACION_GERENTE_INICIO'
}
}
    return w_role_contratacion_gerente_inicio
}


export const ContratacionEncuadreGerenteList = withStyles(styles)(({classes, permissions, ...props}) => {
    return (
    <div>
    <div>
    <Vertitle/>
    </div>
    <div style={{margin: '1em'}}>
    <List {...props} title="Listado de Consulta Contrataciones"
    bulkActionButtons={false}
    actions={<PostActions/>}
    perPage={10}
    empty={false}
    filters={<Filtros/>}
    sort={{field: 'fecha_encuadre', order: 'DESC'}}
    >

    <WithPermissions
    render={({permissions}) => (
        role_contratacion_gerente_inicio({permissions}) === 'ROLE_CONTRATACION_GERENTE_INICIO'
            ?
            <Datagrid rowStyle={RowStyle} expand={false}>
                <TextField label="ID" source="id" sortable={false}/>
                <TextField label="Expediente" source="expediente_referencia_gde" sortable={false}/>
                {/*<TextField label="Tipo" source="contratacion_tipo_encuadre"  sortable={false}/>*/}
                <TextField label="Tipo" source="contratacion_d" sortable={false}/>
                <TextField label="Concepto" source="concepto_descripcion" sortable={false}/>
                <TextField label="Unidad Negocio" source="unidad_negocio"/>
                <TextField label="Unidad Area" source="unidad_area_d"/>
                <NumberField label="Contratación $" source="importe_contratacion_pesos"
                             style={{textAlign: 'right', margin: '1em'}}
                             options={{minimumFractionDigits: 2}} sortable={false}/>
                <TextField label="Estado Actual" source="estado_encuadre" sortable={false}/>
                <SeguimientoCompletoButton label="Seguimiento"/>
                <ProveedorAdjudicadoButton label="Proveedor Adjudicado"/>
                <ContratacionObservacionesButton label="Observacion"/>
                <LinearProgresoCumplidoDiasPorcentajeCompleto label="Progreso Días"/>
                <TextField label="Etapas Cumplidas" source="cumplido_total_pasos"
                           sortable={false}/>
            </Datagrid>
            :
            <Datagrid rowStyle={RowStyle} expand={<ContratacionEncuadreInfo/>}>
                <TextField label="ID" source="id" sortable={false}/>
                <DateField label="Fecha Alta" source="fecha_encuadre"/>
                <TextField label="Expediente" source="expediente_referencia_gde"/>
                <TextField label="Encuadre" source="contratacion_tipo_encuadre"/>

                <TextField label="Año" source="contratacion_ano"/>
                <TextField label="Unidad Negocio" source="unidad_negocio_requiere"/>
                {/*<TextField label="Area" source="unidad_area_requiere" />*/}
                <TextField label="Unidad Area" source="unidad_area_d"/>
                <TextField label="Moneda" source="moneda"/>
                {/*<TextField label="Cotización" source="moneda_cotizacion" />*/}
                {/*<NumberField label="Cotización" source="moneda_cotizacion"*/}
                {/*             style={{textAlign: 'right', margin: '1em' }}*/}
                {/*             options={{ minimumFractionDigits: 5 }}  />*/}
                {/*<DateField label="Fecha Ctz." source="moneda_cotizacion_fecha"/>*/}


                <NumberField label="Importe Presupuesto" source="importe_presupuesto"
                             style={{textAlign: 'right', margin: '1em'}}
                             options={{minimumFractionDigits: 2}}/>


                <NumberField label="Importe Presupuesto $" source="importe_presupuesto_pesos"
                             style={{textAlign: 'right', margin: '1em'}}
                             options={{minimumFractionDigits: 2}}/>

                {/*<NumberField label="Importe Adjudicado Pesos" source="importe_adjudicado_pesos"*/}
                {/*             style={{textAlign: 'right', margin: '1em' }}*/}
                {/*             options={{ minimumFractionDigits: 2 }}  />*/}

                <TextField label="Estado Actual" source="estado_encuadre"/>
                <TextField label="Última Actualización"
                           source="estado_encuadre_fh_ultima_actualizacion"/>

                {role_contratacion_consulta_mostrar_permissions({permissions}) === 'ROLE_CONTRATACION_CONSULTA_MOSTRAR' &&
                <ShowButton label="Mostrar"/>
                }
                {/*<EditButton label="Editar"/>*/}
                {role_auditoria_permissions({permissions}) === 'ROLE_AUDITORIA' &&
                <AuditoriaButton label="Auditoria"/>
                }
            </Datagrid>
    )}
    />
    </List>
    </div>
    </div>
    )
});


const RowStyle = (record, index) => ({
    backgroundColor: record.estado_encuadre === undefined || record.estado_color === undefined ? '' :
    record.estado_color.trim(),
});


export default ContratacionEncuadreGerenteList;
