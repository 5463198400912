import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";


const useStyles = makeStyles((theme) => ({
    programa: {display: 'inline-block', width: 200, marginLeft: 8},
    programa_d: {display: 'inline-block', width: 400, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_programa = values.programa

    if (w_programa===undefined) {
        errors.programa = ['El campo Código es requerido'];
    }else {
        if (w_programa === null) {
            errors.programa = ['El campo Código es requerido'];
        } else {
            w_programa = w_programa.trim()
            if (w_programa === '') {
                errors.programa = ['El campo Código es requerido'];
            }
        }
    }


    var w_programa_d = values.programa_d

    if (w_programa_d===undefined) {
        errors.programa_d = ['El campo Descripcion es requerido'];
    }else {
        if (w_programa_d === null) {
            errors.programa_d = ['El campo Descripcion es requerido'];
        } else {
            w_programa_d = w_programa_d.trim()
            if (w_programa_d === '') {
                errors.programa_d = ['El campo Descripcion es requerido'];
            }
        }
    }


    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/UnidadPrograma`;

const UnidadProgramaCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Unidad Programa</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS UNIDAD PROGRAMA">

                    <ComboUnidadNegocio readOnly={false}/>

                    <TextInput
                        label="Código Unidad Programa"
                        source="programa"
                        formClassName={classes.programa}
                        placeholder="Ingrese Código Unidad Programa"
                        helperText="Código"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Descripción Unidad Programa"
                        source="programa_d"
                        formClassName={classes.programa_d}
                        multiline
                        placeholder="Ingrese Descripcion Unidad Programa"
                        helperText="Descripcion"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default UnidadProgramaCreate;


