import React from 'react';
import {
    Edit,
    TextField,
    NumberField,
    Toolbar,
    SaveButton,
    SimpleForm,
    SelectInput, TextInput, required, FormTab, FileField, FileInput, DeleteButton
} from 'react-admin';

import { withStyles } from '@material-ui/core/styles';
import ProveedorDocumentacionAdjuntoSaveButton from "./ProveedorDocumentacionAdjuntoSaveButton";
import ButtonSave from "./ButtonSave"

const styles = {
    inlineBlock: {display: 'inline-flex', marginRight: '3rem'},
    inlineBlock2: {display: 'inline-flex', marginRight: '3rem'},
};

const ProveedorDocumentacionAdjuntoTitle = ({ record }) => {
    // return <span>{record ? `Detalle de Orden de Pago ` : ''}</span>;
    return <span>{record ? `` : ''}</span>;
};


const ProveedorDocumentacionAdjuntoToolbar = props => (
    <Toolbar {...props} >
        {/*<ProveedorDocumentacionAdjuntoSaveButton*/}
        {/*    label="Subir Archivo/s"*/}
        {/*    submitOnEnter={true}*/}
        {/*/>*/}
        <ButtonSave
            label="Subir Archivo/s"
            submitOnEnter={true}
        />
    </Toolbar>
);


export const ProveedorDocumentacionAdjuntoEdit = withStyles(styles)(({ classes, permissions, ...props}) => (
    <Edit title={<ProveedorDocumentacionAdjuntoTitle />} {...props} >
        <SimpleForm toolbar={<ProveedorDocumentacionAdjuntoToolbar/>}>
            <FileInput source="ArchivosAdjuntos" label="Adjuntar Archivos Maximo 3MB"  multiple
                       minSize={1000} maxSize={3145728}
                       placeholder={<p>Arrastre algunos archivos para subir o haga clic para seleccionarlos.</p>}>
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Edit>
));

export default withStyles(styles) (ProveedorDocumentacionAdjuntoEdit)


