import React, {useState} from 'react';
import {Drawer, makeStyles} from "@material-ui/core";
import Icon from '@material-ui/icons/Add';
import Button from '@mui/material/Button';
import ProveedorDocumentacionAdjuntoShowList from './ProveedorDocumentacionAdjuntoShowList'
import IconCancelar from "@material-ui/icons/Close";
import ProveedorDocumentacionAdjuntoAgregar from "./ProveedorDocumentacionAdjuntoAgregar";
import {useRefresh} from "react-admin";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '48%'
    }
}));

export const BtnProveedorDocumentacionAdjuntoShow = ({record}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();

    var w_documentacion_adjuntar_archivo = record.documentacion_adjuntar_archivo
    if(w_documentacion_adjuntar_archivo==null){
        w_documentacion_adjuntar_archivo=''
    }
    w_documentacion_adjuntar_archivo=w_documentacion_adjuntar_archivo.trim()

    var w_sort_field = 'fecha_ingreso'

    var w_documentacion_ingresar_vencimiento = record.documentacion_ingresar_vencimiento;
    if(w_documentacion_ingresar_vencimiento==null){
        w_documentacion_ingresar_vencimiento=''
    }
    w_documentacion_ingresar_vencimiento=w_documentacion_ingresar_vencimiento.trim()

    if(w_documentacion_ingresar_vencimiento==='S'){
        w_sort_field = 'fecha_vencimiento'
    }

    var w_hay_adjunto = record.hay_adjunto
    if(w_hay_adjunto==null){
        w_hay_adjunto=''
    }
    w_hay_adjunto=w_hay_adjunto.trim()

    var w_hay_documentacion_vencida = record.hay_documentacion_vencida
    if(w_hay_documentacion_vencida==null){
        w_hay_documentacion_vencida=''
    }
    w_hay_documentacion_vencida=w_hay_documentacion_vencida.trim()

    if(w_documentacion_adjuntar_archivo==='S') {

        const handleDrawerOpen = () => {
            setOpen(false);
        };

        const handleDrawerRefresh = () => {
            setOpen(false);
            refresh();
        };

        const BtnCancelar = ({record}) => (
            <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
                handleDrawerRefresh(false)
            }}
            >
                <IconCancelar/>
                Volver
            </Button>
        );

        return (
            <div>
                <div style={{display: 'inline-block', width: 240, marginLeft: 8}}>
                    {/*<Button variant="contained" size={"small"}*/}
                    {/*        style={{color:w_hay_adjunto===undefined ? '' :*/}
                    {/*                w_hay_adjunto ==='S' ? '#0fc000' :'#ffffff',*/}
                    {/*            backgroundColor: '#123B80'}}*/}
                    {/*        onClick={() => setOpen(!open)}>*/}
                    {/*    <AttachFileIcon/>*/}
                    {/*    Adjuntar Archivo Documentacion*/}
                    {/*</Button>*/}
                    <Button title={w_hay_adjunto===undefined ||
                    w_hay_adjunto===null ? '' :
                        w_hay_adjunto.trim()==='V' ? 'Presento Documentación' :
                            w_hay_adjunto.trim()==='A' ? 'Debe Documentación' : 'Sin Documentación'}
                            variant="contained" size={"small"}
                            style={{color: w_hay_adjunto===undefined ||
                                w_hay_adjunto===null ? '' :
                                    w_hay_adjunto.trim()==='V' ? '#0fc000' :
                                        w_hay_adjunto.trim()==='A' ? '#ffc000' : '#ffffff',
                                backgroundColor: '#123B80'}}
                            onClick={() => setOpen(!open)}>
                        <AttachFileIcon titleAccess={w_hay_documentacion_vencida===undefined ||
                        w_hay_documentacion_vencida===null ? '' :
                            w_hay_documentacion_vencida.trim()==='V' ? 'Documentación Vigente' :
                                w_hay_documentacion_vencida.trim()==='R' ? 'Documentación Vencida' :
                                    ''} style={{color: w_hay_documentacion_vencida===undefined ||
                            w_hay_documentacion_vencida===null ? '' :
                                w_hay_documentacion_vencida.trim()==='V' ? '#0fc000' :
                                    w_hay_documentacion_vencida.trim()==='R' ? '#c00000' :
                                        '#ffffff',
                            backgroundColor: '#123B80'}}/>
                        Adjuntar Archivo Documentacion
                    </Button>
                    <Drawer
                        classes={{paper: classes.paper}}
                        variant="persistent"
                        anchor="right"
                        open={open}
                    >
                        <div>
                            <h3>Adjuntar Archivo Documentacion</h3>
                        </div>
                        {open===true ?
                            <div>
                                <div>
                                    <div style={{display: 'inline-block', width: 400, marginLeft: 8}}>
                                        {/*<ProveedorDocumentacionAdjuntoAgregar*/}
                                        {/*    record={record}*/}
                                        {/*    callbackCloseDrawer={handleDrawerOpen}*/}
                                        {/*    // callback={props.callbackArt}*/}
                                        {/*/>*/}
                                    </div>
                                    <Table aria-label="custom pagination table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    {/*<div style={{display: 'inline-block', width: 400, marginLeft: 8}}>*/}
                                                    {/*    <div style={{display: 'inline-block', width: 400, marginLeft: 8}}>*/}
                                                    <BtnCancelar/>
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField disabled label="Detalle"
                                                               value={record.documentacion_tipo_d}
                                                               SelectProps={{native: true,}}
                                                               multiline
                                                               rows={2}
                                                               variant="outlined"
                                                               style={{ width: 600 }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                                <div>
                                    <Table  size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <TextField disabled
                                                               label="Nro Ingreso"
                                                               value={record.documentacion_nro_ingreso}
                                                               SelectProps={{native: true,}}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField disabled
                                                               label="Tipo"
                                                               value={record.documentacion_tipo}
                                                               SelectProps={{native: true,}}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <TextField disabled
                                                               label="Vencimiento"
                                                               value={record.documentacion_ingresar_vencimiento}
                                                               SelectProps={{native: true,}}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                                <div>
                                    <br />
                                    <br />
                                </div>
                                <ProveedorDocumentacionAdjuntoShowList
                                    record={record}
                                    callbackCloseDrawer={handleDrawerOpen}
                                    sort_field={w_sort_field}
                                    // callback={props.callbackArt}
                                />
                                <div style={{display: 'inline-block', width: 400, marginLeft: 8}}>
                                    <div style={{display: 'inline-block', width: 400, marginLeft: 8}}>
                                        <BtnCancelar/>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                        }
                    </Drawer>
                </div>
            </div>
        )

    }else{
        return (
            <div>
            </div>
        )
    }
};
export default BtnProveedorDocumentacionAdjuntoShow;