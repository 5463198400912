import React, {useState, useEffect} from 'react';
import {
    useDataProvider,
    Responsive,
    Loading,
    Error
} from 'react-admin';
import UnidadDeNEgociosContratacionEncuadreTableroControlCant from "./UnidadDeNEgociosContratacionEncuadreTableroControlCant"
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Modal from "@material-ui/core/Modal";
import cartuchonewlargo from "./cartuchonewlargo.png";
import PantallaMensaje from "../componentes/PantallaMensaje";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};


const UnidadDeNEgociosContratacionEncuadreTableroControl = () => {

    // const [state, setState] = useState({});
    // const version = useVersion();
    const dataProvider = useDataProvider();
    // const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    // const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [EstadoDetalle, setEstadoDetalle] = useState(1);
    const [untablero, setUNTablero] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const [ActualizarUnidadDeNegocios, setActualizarUnidadDeNegocios] = useState(false);

    const handleClickActualizarUnidadDeNegocios = () => {
        setActualizarUnidadDeNegocios(true);
        CambiaEstado()
    };

    const handleDrawerCloseActualizarUnidadDeNegocios = () => {
        if(ActualizarUnidadDeNegocios===true) {
            setActualizarUnidadDeNegocios(false);
        }else{
            setActualizarUnidadDeNegocios(true);
        }
    };

    const CambiaEstado = () => {
        setEstadoDetalle(EstadoDetalle + 1);
    };

    setTimeout(() => {
        CambiaEstado()
    },60 * 2 * 1000);//Digamos que quieres esperar al menos 2 minutos


    useEffect(() => {

        // fetchOrders_pesos_uni_neg_TableroControl()

        dataProvider.getList(
            'TableroControlUnidadDeNEgocios',
            {
                filter: {filtros: 'N', ingreso_por: 'C'},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setUNTablero(data);
                setLoading(false);
                setActualizarUnidadDeNegocios(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
                setActualizarUnidadDeNegocios(false);

            })

    }, [EstadoDetalle]);


    if (loading) return <Loading/>;                // no cargo todavia

    if (error) {
        console.log('error')
        console.log(error)
        return <Error/>;                           // error
    }
    ;

    //----------- si ya tiene los datos cargados en forma asincrónica actualiza el DOM --------------

    if (untablero.length > 0) {
        var untableroList = untablero

        const aggregations = untableroList
            .reduce(
                (stats, order) => {
                    stats.fecha_hora = order.fecha_hora
                    stats.usa_todas = order.usa_todas
                    stats.usa_tvp = order.usa_tvp
                    stats.titulo_tvp = order.titulo_tvp
                    stats.total_tvp = order.total_tvp
                    stats.cant_pro_tvp = order.cant_pro_tvp
                    stats.usa_rna = order.usa_rna
                    stats.titulo_rna = order.titulo_rna
                    stats.total_rna = order.total_rna
                    stats.usa_cdoce = order.usa_cdoce
                    stats.titulo_cdoce = order.titulo_cdoce
                    stats.total_cdoce = order.total_cdoce
                    stats.cant_pro_cdoce = order.cant_pro_cdoce
                    stats.cant_pro_rna = order.cant_pro_rna
                    stats.total_gral = order.total_gral
                    stats.cant_pro_gral = order.cant_pro_gral
                    stats.titulo_todo_gral = order.titulo_todo_gral
                    // console.log(order)
                    return stats;
                },
                {
                    fecha_hora: '',
                    usa_todas: '',
                    usa_tvp: '',
                    titulo_tvp: '',
                    total_tvp: 0,
                    cant_pro_tvp: 0,
                    usa_rna: '',
                    titulo_rna: '',
                    total_rna: 0,
                    cant_pro_rna: 0,
                    usa_cdoce: '',
                    titulo_cdoce: '',
                    total_cdoce: 0,
                    cant_pro_cdoce: 0,
                    total_gral: 0,
                    cant_pro_gral: 0,
                    titulo_todo_gral: '',
                }
            );


        var nbrecentOrdersList = ''
        var fecha_hora = aggregations.fecha_hora
        var usa_todas = aggregations.usa_todas
        var usa_tvp = aggregations.usa_tvp
        var titulo_tvp = aggregations.titulo_tvp
        var total_tvp = aggregations.total_tvp
        var cant_pro_tvp = aggregations.cant_pro_tvp
        var usa_rna = aggregations.usa_rna
        var titulo_rna = aggregations.titulo_rna
        var total_rna = aggregations.total_rna
        var cant_pro_rna = aggregations.cant_pro_rna
        var usa_cdoce = aggregations.usa_cdoce
        var titulo_cdoce = aggregations.titulo_cdoce
        var total_cdoce = aggregations.total_cdoce
        var cant_pro_cdoce = aggregations.cant_pro_cdoce
        var total_gral = aggregations.total_gral
        var cant_pro_gral = aggregations.cant_pro_gral
        var titulo_todo_gral = aggregations.titulo_todo_gral


        return (
            <div>
                <div>
                    <Responsive
                        xsmall={<UnidadDeNEgociosContratacionEncuadreTableroControlCant
                            value={nbrecentOrdersList}
                            value1={usa_todas}
                            value2={usa_tvp}
                            value3={titulo_tvp}
                            value4={total_tvp}
                            value5={usa_rna}
                            value6={titulo_rna}
                            value7={total_rna}
                            value8={total_gral}
                            value9={cant_pro_tvp}
                            value10={cant_pro_rna}
                            value11={cant_pro_gral}
                            value12={titulo_todo_gral}
                            fecha_hora={fecha_hora}
                            value13={usa_cdoce}
                            value14={titulo_cdoce}
                            value15={total_cdoce}
                            value16={cant_pro_cdoce}
                            handleClickActualizarUnidadDeNegocios={handleClickActualizarUnidadDeNegocios}
                        />

                        }


                        small={

                            <UnidadDeNEgociosContratacionEncuadreTableroControlCant
                                value={nbrecentOrdersList}
                                value1={usa_todas}
                                value2={usa_tvp}
                                value3={titulo_tvp}
                                value4={total_tvp}
                                value5={usa_rna}
                                value6={titulo_rna}
                                value7={total_rna}
                                value8={total_gral}
                                value9={cant_pro_tvp}
                                value10={cant_pro_rna}
                                value11={cant_pro_gral}
                                value12={titulo_todo_gral}
                                fecha_hora={fecha_hora}
                                value13={usa_cdoce}
                                value14={titulo_cdoce}
                                value15={total_cdoce}
                                value16={cant_pro_cdoce}
                                handleClickActualizarUnidadDeNegocios={handleClickActualizarUnidadDeNegocios}
                            />


                        }

                        medium={

                            <UnidadDeNEgociosContratacionEncuadreTableroControlCant
                                value={nbrecentOrdersList}
                                value1={usa_todas}
                                value2={usa_tvp}
                                value3={titulo_tvp}
                                value4={total_tvp}
                                value5={usa_rna}
                                value6={titulo_rna}
                                value7={total_rna}
                                value8={total_gral}
                                value9={cant_pro_tvp}
                                value10={cant_pro_rna}
                                value11={cant_pro_gral}
                                value12={titulo_todo_gral}
                                fecha_hora={fecha_hora}
                                value13={usa_cdoce}
                                value14={titulo_cdoce}
                                value15={total_cdoce}
                                value16={cant_pro_cdoce}
                                handleClickActualizarUnidadDeNegocios={handleClickActualizarUnidadDeNegocios}
                            />
                        }
                    />
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={styles.modal}
                    open={ActualizarUnidadDeNegocios}
                    onClose={handleDrawerCloseActualizarUnidadDeNegocios}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={ActualizarUnidadDeNegocios}>
                        <div>
                            <Table style={{ background: `url(${cartuchonewlargo}) no-repeat`,
                                color: '#ffffff',
                                backgroundColor: '#ffffff'}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell size="small">
                                            <Typography variant="headline" component="h2">
                                                {'Actualizando Contrataciones...'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <PantallaMensaje
                                p_imagen={'CircularProgressModalabajo'}
                                p_mensajePrimario={'Cargando'}
                                p_mensajeSecondario={'La página se está cargando, espere un momento por favor.'}
                            />
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    }else{
        return (
            <div>
                <Typography>
                    {'Sin Datos'}
                </Typography>
            </div>
        );
    }
}
export default UnidadDeNEgociosContratacionEncuadreTableroControl;



