import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    Responsive,
    CardActions, Link, Button, WithPermissions, ExportButton
} from 'react-admin';

import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import UsuComNotificacionTipoButtonDelete from "./UsuComNotificacionTipoButtonDelete"
import Vertitle from "./Vertitle";
import withStyles from "@material-ui/core/styles/withStyles";

const FiltroUserUsuCom = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="q" alwaysOn/>
        {/*<TextInput label="Proveedor" source="pro"/>*/}
        {/*<TextInput label="Razón Social"  source="raz"/>*/}
        {/*<SelectInput*/}
        {/*    source="hay_correo_pro"*/}
        {/*    label="Correo Inf. Pago"*/}
        {/*    choices={[*/}
        {/*        {id: 'SI', name: 'C/Correo Inf. Pago'},*/}
        {/*        {id: 'NI', name: 'S/Correo Inf. Pago'},*/}
        {/*        {id: 'NT', name: 'Sin contacto'}*/}
        {/*    ]}*/}
        {/*/>*/}

        {/*<SelectInput*/}
        {/*    source="usa_role_user"*/}
        {/*    // label="ROLE_MLADOS"*/}
        {/*    label={ <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
        {/*        <ViewModuleIcon style={{ color: '#35c81d' }}/>{'Funcionamiento de Modulo'}*/}
        {/*    </div>}*/}
        {/*    choices={[*/}
        {/*        {id: 'S', name: 'SI'},*/}
        {/*        {id: 'N', name: 'NO'},*/}
        {/*    ]} alwaysOn*/}
        {/*/>*/}
        {/*<SelectInput*/}
        {/*    source="usa_role_secu"*/}
        {/*    // label="ROLE_MLADOS_SECU"*/}
        {/*    label={ <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
        {/*        <AssignmentIndIcon style={{color: '#dcaadc'}}/>{'Usuarios del Sistema'}*/}
        {/*    </div>}*/}
        {/*    choices={[*/}
        {/*        {id: 'S', name: 'SI'},*/}
        {/*        {id: 'N', name: 'NO'},*/}
        {/*    ]} alwaysOn*/}
        {/*/>*/}
    </Filter>
);

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};

const role_auxiliar_editar_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_auxiliar_editar = w_permissions_control.indexOf('ROLE_AUXILIAR_EDITAR')
        if (w_role_auxiliar_editar === -1) {
        } else {
            w_role_control = 'ROLE_AUXILIAR_EDITAR'
        }
    }

    return w_role_control
}

const role_auxiliar_crear_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_auxiliar_crear = w_permissions_control.indexOf('ROLE_AUXILIAR_CREAR')
        if (w_role_auxiliar_crear === -1) {
        } else {
            w_role_control = 'ROLE_AUXILIAR_CREAR'
        }
    }

    return w_role_control
}

const role_exportar_csv = ({permissions}) => {
    var w_role_control = '';
    var w_permissions_control = permissions;
    if (w_permissions_control == null) {
    } else {
        var w_role_exportar_csv = w_permissions_control.indexOf('ROLE_EXPORTAR_CSV');
        if (w_role_exportar_csv === -1) {
        } else {
            w_role_control = 'ROLE_EXPORTAR_CSV'
        }
    }
    return w_role_control
};

const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <WithPermissions
            render={({permissions}) => (
                role_auxiliar_crear_permissions({permissions}) === 'ROLE_AUXILIAR_CREAR'
                    ?
                    <Link to={`/UsuComNotificacionTipo/create`}>
                        <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <Button
                                label="Crear Usuario de Notificacion"
                            >
                                <AddIcon/>
                            </Button>
                        </div>
                    </Link>
                    : null
            )}
        />

        <WithPermissions
            render={({permissions}) => (
                role_exportar_csv({permissions}) === 'ROLE_EXPORTAR_CSV'
                    ?
                    <ExportButton/>
                    : null
            )}
        />
        {/*<div>*/}
        {/*    <LineWeightIcon titleAccess={'Sin contacto'} style={{color: '#c0c0c0'}} ></LineWeightIcon>{'Sin contacto'}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*    <LineWeightIcon titleAccess={'C/Correo Inf. Pago'} style={{color: '#8080ff'}} ></LineWeightIcon>{'C/Correo Inf. Pago'}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*    <LineWeightIcon titleAccess={'S/Correo Inf. Pago'} style={{color: '#ffaaaa'}} ></LineWeightIcon>{'S/Correo Inf. Pago'}*/}
        {/*</div>*/}
    </CardActions>
);

// expand={<UserUsuComInfo/>}
//xsmall={<MobileUserUsuComGrid />}

// Grilla general
export const UsuComNotificacionTipoList = withStyles(styles)(({classes, permissions, ...props}) => {
    return (
        <div>
            <div>
                <Vertitle/>
            </div>
            <div style={{margin: '1em'}}>
                <List {...props} title="Listado de Usuario de Notificacion" actions={<PostActions/>} perPage={25}
                      bulkActionButtons={false} exporter={false}
                      filters={<FiltroUserUsuCom/>}>
                    <Responsive
                        xsmall={
                            <Datagrid rowClick="expand" rowStyle={RowStyle}
                                // expand={<UserUsuComEdit/>}
                            >
                                <TextField label="Usuario" source="username"/>
                                <TextField label="Notificacion" source="notificacion_codigo_d"/>
                            </Datagrid>
                        }
                        medium={
                            <Datagrid rowClick="expand" rowStyle={RowStyle}
                                // expand={<UserUsuComEdit/>}
                            >
                                <TextField label={
                                    <div title={'Nombre de Usuario'}
                                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                        <PersonIcon style={{color: '#0064c8'}}/>
                                        {'Usuario'}
                                    </div>
                                }
                                           source="username"
                                />
                                <TextField
                                    label={
                                        <div title={'Notificacion Tipo'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <AddAlertIcon style={{color: '#ff0000'}}/>
                                            {'Código'}
                                        </div>
                                    }
                                    source="notificacion_codigo"
                                />
                                <TextField
                                    label={
                                        <div title={'Notificacion Descripción'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <AddAlertIcon style={{color: '#ff0000'}}/>
                                            {'Descripción'}
                                        </div>
                                    }
                                    source="notificacion_d"
                                />
                                {role_auxiliar_editar_permissions({permissions}) === 'ROLE_AUXILIAR_EDITAR' &&
                                <UsuComNotificacionTipoButtonDelete/>
                                }

                                {/*<LinkConsultarButton/>*/}
                            </Datagrid>
                        }
                    />
                </List>
            </div>
        </div>
    )
});

const RowStyle = (record, index) => ({
    backgroundColor: record.notificacion_codigo === undefined || record.estado_color === undefined ? '' :
        record.estado_color.trim(),
});

export default UsuComNotificacionTipoList;



