import React from 'react'
import {
    Datagrid,
    List,
    TextField,
    Filter, TextInput
} from 'react-admin';
import Vertitle from "./Vertitle"
import withStyles from "@material-ui/core/styles/withStyles";


const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const Filtros = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" variant="outlined" source="q" alwaysOn/>
    </Filter>
);

// Grilla general
export const ParametroSistemaList = withStyles(styles)(({classes, permissions,...props}) =>  {
    return (
    <div>
        <div>
            <Vertitle/>
        </div>
        <div style={{ margin: '1em' }}>
            <List {...props} title="Listado de Parametros del Sistema"
                  bulkActionButtons={false}
                  actions={false}
                  perPage={25}
                  filters={<Filtros/>}
                  empty={false}
            >
                <Datagrid
                >
                    <TextField label="Parametro Código" source="parametro_codigo" />
                    <TextField label="Parametro Valor" source="parametro_valor" />
                    <TextField label="Parametro Descripción" source="parametro_descripcion" />
                    <TextField label="Usuario Ult. Act." source="usuario_ult_act" />
                    <TextField label="Fecha Hora Ult. Act." source="fh_ult_act" />
                </Datagrid>
            </List>
        </div>
    </div>
    )
});

export default ParametroSistemaList;
