import React, {Fragment, useEffect, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    useRedirect,
    useNotify,
    useRefresh,
    useMutation,
    Button,
    Link
} from 'react-admin';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import RefreshIcon from '@material-ui/icons/Refresh';
import Battery20Icon from '@material-ui/icons/Battery20';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery90Icon from '@material-ui/icons/Battery90';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import UpdateIcon from "@material-ui/icons/Update";
import CircularProgress from "@material-ui/core/CircularProgress";

const drawerheight = 150;
const drawerWidth = 250;

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
    drawerPaper: {
        height: drawerheight,
        width: drawerWidth,
    },
});



//--------------------  recibe como parametro la fecha de proceso ------------------

const Porcentaje = ({ porcentaje, tiempo, espar, cantidad, id_ml_sel }) => {

    // console.log('Porcentaje')

    var w_porcentaje = porcentaje
    // console.log('Porcentaje --w_porcentaje')
    // console.log(w_porcentaje)

    var w_tiempo = tiempo


    if(w_porcentaje===0){
        return (
            <div>
            </div>
        )
    }else {
        var w_espar = espar
        if(w_espar==null){
            w_espar=''
        }
        w_espar=w_espar.trim()
        var w_cantidad = cantidad
        if(w_cantidad==null){
            w_cantidad=''
        }
        w_cantidad=w_cantidad.trim()
        var w_id_ml_sel = id_ml_sel
        if(w_id_ml_sel==null){
            w_id_ml_sel=''
        }
        w_id_ml_sel=w_id_ml_sel.trim()
        // alert(w_porcentaje)
        return (
            <div>
                <div>
                    <CardContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell size="small">
                                        <Typography variant="headline" component="h4">
                                            {w_porcentaje === 0 ? <BatteryUnknownIcon/> :
                                                w_porcentaje <= 20 ? <Battery20Icon/> :
                                                    w_porcentaje <= 30 ? <Battery30Icon/> :
                                                        w_porcentaje <= 50 ? <Battery50Icon/> :
                                                            w_porcentaje <= 60 ? <Battery60Icon/> :
                                                                w_porcentaje <= 80 ? <Battery80Icon/> :
                                                                    w_porcentaje <= 90 ? <Battery90Icon/> :
                                                                        w_porcentaje === 100 ? <BatteryFullIcon/> :
                                                                            <BatteryFullIcon/>
                                            }
                                            {w_porcentaje === undefined ? '0,00' :
                                                w_porcentaje.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 16,
                                                })}
                                            {'%'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell size="small">
                                        <Typography variant="headline" component="h4">
                                            {w_cantidad}
                                            {w_espar === undefined ? '' :
                                                w_espar === 'N' ? <DoubleArrowIcon/> :
                                                    <DoubleArrowIcon style={{color: '#c8c8c8'}} />}
                                        </Typography>
                                    </TableCell>
                                    <TableCell size="small">
                                        <Typography variant="headline" component="h4">
                                            {w_tiempo}
                                            {w_espar === undefined ? '' :
                                                w_espar === 'S' ? <DoubleArrowIcon/> :
                                                    <DoubleArrowIcon style={{color: '#c8c8c8'}} />}
                                        </Typography>
                                    </TableCell>
                                    <TableCell size="small">
                                        <Typography variant="headline" component="h4">
                                            {w_id_ml_sel}
                                            {w_espar === undefined ? '' :
                                                w_espar === 'N' ? <DoubleArrowIcon/> :
                                                    <DoubleArrowIcon style={{color: '#c8c8c8'}} />}
                                        </Typography>
                                    </TableCell>
                                    <TableCell size="small">
                                        <Typography variant="headline" component="h4">
                                            <div>
                                                <CircularProgress/>
                                                {' Procesando...'}
                                            </div>
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </CardContent>
                </div>
            </div>

        )
    }


}


export default Porcentaje;