import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField, useNotify, useRefresh, useMutation, FormTab
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
import LineWeightIcon from "@material-ui/icons/LineWeight";
import SaveIcon from '@material-ui/icons/Save';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const EstadoContratacionButtonEdit = ({record}) => {
    var wr_id = record.id

    const dataProviderTableroControl = useDataProvider();
    const [Estadoid, setEstadoid] = useState(0)
    const [Estadoexpediente_referencia_gde, setEstadoexpediente_referencia_gde] = useState('')
    const [Estadocontratacion_tipo_encuadre, setEstadocontratacion_tipo_encuadre] = useState('')
    const [Estadoen, setEstadoen] = useState('')
    const [Estadoen_motivo, setEstadoen_motivo] = useState('')
    const [Estadoen_progreso, setEstadoen_progreso] = useState('')
    const [Estadoen_progreso_motivo, setEstadoen_progreso_motivo] = useState('')
    const [Estadoen_impugnacion, setEstadoen_impugnacion] = useState('')
    const [Estadoen_impugnacion_motivo, setEstadoen_impugnacion_motivo] = useState('')
    const [Estadoen_suspenso, setEstadoen_suspenso] = useState('')
    const [Estadoen_suspenso_motivo, setEstadoen_suspenso_motivo] = useState('')
    const [Estadoen_revision, setEstadoen_revision] = useState('')
    const [Estadoen_revision_motivo, setEstadoen_revision_motivo] = useState('')
    const [Estadoen_anulado, setEstadoen_anulado] = useState('')
    const [Estadoen_anulado_motivo, setEstadoen_anulado_motivo] = useState('')
    const [Estadoen_finalizado, setEstadoen_finalizado] = useState('')
    const [Estadoen_finalizado_motivo, setEstadoen_finalizado_motivo] = useState('')



    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        var wr_id = record.id
        var wr_expediente_referencia_gde = record.expediente_referencia_gde
        var wr_contratacion_tipo_encuadre = record.contratacion_tipo_encuadre
        var wr_en_progreso = record.en_progreso
        if (wr_en_progreso == null) {
            wr_en_progreso = ''
        }
        wr_en_progreso = wr_en_progreso.trim()
        var wr_en_progreso_motivo = record.en_progreso_motivo
        if(wr_en_progreso_motivo==null){
            wr_en_progreso_motivo=''
        }
        wr_en_progreso_motivo=wr_en_progreso_motivo.trim()
        var wr_en_impugnacion = record.en_impugnacion
        if (wr_en_impugnacion == null) {
            wr_en_impugnacion = ''
        }
        wr_en_impugnacion = wr_en_impugnacion.trim()
        var wr_en_impugnacion_motivo = record.en_impugnacion_motivo
        if(wr_en_impugnacion_motivo==null){
            wr_en_impugnacion_motivo=''
        }
        wr_en_impugnacion_motivo=wr_en_impugnacion_motivo.trim()
        var wr_en_suspenso = record.en_suspenso
        if (wr_en_suspenso == null) {
            wr_en_suspenso = ''
        }
        wr_en_suspenso = wr_en_suspenso.trim()
        var wr_en_suspenso_motivo = record.en_suspenso_motivo
        if(wr_en_suspenso_motivo==null){
            wr_en_suspenso_motivo=''
        }
        wr_en_suspenso_motivo=wr_en_suspenso_motivo.trim()
        var wr_en_revision = record.en_revision
        if (wr_en_revision == null) {
            wr_en_revision = ''
        }
        wr_en_revision = wr_en_revision.trim()
        var wr_en_revision_motivo = record.en_revision_motivo
        if(wr_en_revision_motivo==null){
            wr_en_revision_motivo=''
        }
        wr_en_revision_motivo=wr_en_revision_motivo.trim()
        var wr_en_anulado = record.en_anulado
        if (wr_en_anulado == null) {
            wr_en_anulado = ''
        }
        wr_en_anulado = wr_en_anulado.trim()
        var wr_en_anulado_motivo = record.en_anulado_motivo
        if(wr_en_anulado_motivo==null){
            wr_en_anulado_motivo=''
        }
        wr_en_anulado_motivo=wr_en_anulado_motivo.trim()
        var wr_en_finalizado = record.en_finalizado
        if (wr_en_finalizado == null) {
            wr_en_finalizado = ''
        }
        wr_en_finalizado = wr_en_finalizado.trim()
        var wr_en_finalizado_motivo = record.en_finalizado_motivo
        if(wr_en_finalizado_motivo==null){
            wr_en_finalizado_motivo=''
        }
        wr_en_finalizado_motivo=wr_en_finalizado_motivo.trim()


        setEstadoid(wr_id)
        setEstadoexpediente_referencia_gde(wr_expediente_referencia_gde)
        setEstadocontratacion_tipo_encuadre(wr_contratacion_tipo_encuadre)
        setEstadoen_progreso(wr_en_progreso)
        setEstadoen_progreso_motivo(wr_en_progreso_motivo)
        setEstadoen_impugnacion(wr_en_impugnacion)
        setEstadoen_impugnacion_motivo(wr_en_impugnacion_motivo)
        setEstadoen_suspenso(wr_en_suspenso)
        setEstadoen_suspenso_motivo(wr_en_suspenso_motivo)
        setEstadoen_revision(wr_en_revision)
        setEstadoen_revision_motivo(wr_en_revision_motivo)
        setEstadoen_anulado(wr_en_anulado)
        setEstadoen_anulado_motivo(wr_en_anulado_motivo)
        setEstadoen_finalizado(wr_en_finalizado)
        setEstadoen_finalizado_motivo(wr_en_finalizado_motivo)

        if (wr_en_progreso === 'S') {
            setEstadoen('P')
            setEstadoen_motivo(wr_en_progreso_motivo)
        }
        if (wr_en_impugnacion === 'S') {
            setEstadoen('I')
            setEstadoen_motivo(wr_en_impugnacion_motivo)
        }
        if (wr_en_suspenso === 'S') {
            setEstadoen('S')
            setEstadoen_motivo(wr_en_suspenso_motivo)
        }
        if (wr_en_revision === 'S') {
            setEstadoen('R')
            setEstadoen_motivo(wr_en_revision_motivo)
        }
        if (wr_en_anulado === 'S') {
            setEstadoen('A')
            setEstadoen_motivo(wr_en_anulado_motivo)
        }
        if (wr_en_finalizado === 'S') {
            setEstadoen('F')
            setEstadoen_motivo(wr_en_finalizado_motivo)
        }

        setOpen(true);
    };



    const handleClose = () => {
        setOpen(false);
    };


    const BotonCrearDetelle = (record) => {

        var w_mensaje = ' '

        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'update',
                resource: 'ContratacionEncuadreVisita',
                payload: {
                    id: Estadoid, data: {
                        usar_contratacion_tipo: 'N',
                        estado_en: Estadoen,
                        estado_en_motivo: Estadoen_motivo,
                        expediente_referencia_gde: Estadoexpediente_referencia_gde,
                        contratacion_tipo_encuadre: Estadocontratacion_tipo_encuadre
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                    // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------

                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <SaveIcon style={{color: '#004187'}}/>
                    Guardar
                </div>
            </Button>
            // <Button label={w_mensaje}
            //         title={w_mensaje}
            //         onClick={generardetelle} disabled={loading}
            //         color="primary"
            // >
            //     <div>
            //         <LineWeightIcon style={{fontSize: 40, backgroundColor: '#ffffff', color: '#004187'}}/>
            //     </div>
            // </Button>
        );

    };

    const handleen_motivo = (event) => {
        const Cambiaen_motivo1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoen_motivo(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiaen_motivo1(w_value)

    };

    const handleChangeen = (event) => {
        var w_value = event.target.value
        console.log(w_value)
        setEstadoen(w_value);

        if (w_value === 'P') {
            setEstadoen_motivo(Estadoen_progreso_motivo)
        }
        if (w_value === 'I') {
            setEstadoen_motivo(Estadoen_impugnacion_motivo)
        }
        if (w_value === 'S') {
            setEstadoen_motivo(Estadoen_suspenso_motivo)
        }
        if (w_value === 'R') {
            setEstadoen_motivo(Estadoen_revision_motivo)
        }
        if (w_value === 'A') {
            setEstadoen_motivo(Estadoen_anulado_motivo)
        }
        if (w_value === 'F') {
            setEstadoen_motivo(Estadoen_finalizado_motivo)
        }
    };


    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <EditIcon style={{color: '#004187'}}/>
                    Editar Estado
                </div>
            </Button>
            <Dialog open={open} maxWidth={'xl'} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Estados</DialogTitle>
                <DialogContent>
                    <form>
                        <label>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>

                                        </TableCell>
                                        <TableCell>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Estado</FormLabel>
                                                <RadioGroup aria-label="gender" name="gender1" value={Estadoen} onChange={handleChangeen}>
                                                    <FormControlLabel value="P" control={<Radio />} label="En Progreso" />
                                                    <FormControlLabel value="I" control={<Radio />} label="En Impugnación" />
                                                    <FormControlLabel value="S" control={<Radio />} label="En Suspenso" />
                                                    <FormControlLabel value="R" control={<Radio />} label="En Revisión" />
                                                    <FormControlLabel value="A" control={<Radio />} label="Anulado" />
                                                    <FormControlLabel value="F" control={<Radio />} label="Finalizado" />
                                                    {/*<FormControlLabel value="other" control={<Radio />} label="Other" />*/}
                                                    {/*<FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" />*/}
                                                </RadioGroup>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">Motivo</Typography>
                                            <TextField
                                                label="Motivo"
                                                value={Estadoen_motivo}
                                                multiline
                                                fullWidth
                                                onChange={handleen_motivo}
                                                SelectProps={{native: true,}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </label>
                    </form>
                </DialogContent>
                <DialogActions>
                    <BotonCrearDetelle/>
                    <Button variant="outlined" color="primary" onClick={handleClose}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <CancelPresentationIcon style={{color: '#004187'}}/>
                            Cancelar
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}


export default EstadoContratacionButtonEdit;