import React, {useEffect, useState, cloneElement} from 'react';
import {
    List,
    Datagrid,
    TextField,
    Responsive,
    CardActions,
    Filter,
    TextInput,
    NumberField,
    useDataProvider,
    useListContext,
    DateInput,
    SimpleForm,
    TopToolbar,
    sanitizeListRestProps,
    useMutation,
    SelectInput, ReferenceInput, DateField
} from 'react-admin';
import withStyles from "@material-ui/core/styles/withStyles";
import TableroControlContratacionGraficos from "./TableroControlContratacionGraficos";
import Divider from "@material-ui/core/Divider";
import Vertitle from "./Vertitle";
import ContratacionEncuadreInfo from "../ContratacionEncuadre/ContratacionEncuadreInfo";
import AuditoriaButton from "../componentes/AuditoriaButton";
// import DescargarExcel from "../Componentes/DescargarExcel";
import LinkMostrarButton from "../ContratacionEncuadre/LinkMostrarButton"
import CircularCumplidoDiasPorcentaje from "./CircularCumplidoDiasPorcentaje"
import LinearProgresoCumplidoDiasPorcentaje from "./LinearProgresoCumplidoDiasPorcentaje"
import SeguimientoButton from "./SeguimientoButton"

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};

const MFiltro = (props, data, ids, baspath) => (
    <Filter {...props}>
        {/*<VerTotales label="Totales" props={props} alwaysOn/>*/}
        {/*<TextInput label="Buscar" source="q" variant="outlined" alwaysOn/>*/}
        <ReferenceInput label='Unidad'
                        source="id_unidad_negocio"
                        reference="UnidadNegocio"
                        onChange={(event,username,record) => console.log(`New deviceId `)}
                        alwaysOn
                        disabled={true}
        >
            <SelectInput
                optionText= "unidad_negocio_d"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <ReferenceInput label='Area'
                        source="id_unidad_area"
                        reference="UnidadArea"
                        onChange={(event,username,record) => console.log(`New deviceId `)}
                        alwaysOn
                        disabled={true}
        >
            <SelectInput
                optionText= "area_cd"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <ReferenceInput label='Encuadre'
                        source="id_contratacion_tipo"
                        reference="ContratacionTipo"
                        onChange={(event,username,record) => console.log(`New deviceId `)}
                        alwaysOn
                        disabled={true}
        >
            <SelectInput
                optionText= "contratacion_tipo"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <ReferenceInput label='Estado'
                        source="id_estado_encuadre"
                        reference="ContratacionEstado"
                        onChange={(event,username,record) => console.log(`New deviceId `)}
                        alwaysOn
                        disabled={true}
        >
            <SelectInput
                optionText= "estado_encuadre"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <TextInput disabled={true} label="Año" source="contratacion_ano"   alwaysOn/>


    </Filter>
);

const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter, selectedIds}) => {
    return (
        <CardActions>
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        </CardActions>
    );
}

const VerTotales = ({props}) => {
}

const ListActions = ({
                         currentSort,
                         className,
                         resource,
                         filters,
                         displayedFilters,
                         exporter,
                         filterValues,
                         permanentFilter,
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showFilter,
                         maxResults,
                         total,
                         ...rest
                     }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/*<CreateButton basePath={basePath} />*/}


        {/*JN Llamo a componente Descargar Excel*/}
        {/*<DescargarExcel w_controlador={'/TableroControlContratacion'}*/}
        {/*                w_filtros={filterValues}*/}
        {/*                w_currentSort={currentSort}*/}
        {/*/>*/}

    </TopToolbar>
);

const role_contratacion_tablero_mostrar_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if(w_permissions_control==null){}else{
        var w_role_contratacion_tablero_mostrar = w_permissions_control.indexOf('ROLE_CONTRATACION_TABLERO_MOSTRAR')
        if(w_role_contratacion_tablero_mostrar===-1){}else{
            w_role_control = 'ROLE_CONTRATACION_TABLERO_MOSTRAR'
        }
    }

    return w_role_control
}

const role_auditoria_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if(w_permissions_control==null){}else{
        var w_role_auditoria = w_permissions_control.indexOf('ROLE_AUDITORIA')
        if(w_role_auditoria===-1){}else{
            w_role_control = 'ROLE_AUDITORIA'
        }
    }

    return w_role_control
}


// Grilla general
export const TableroControlContratacionList = withStyles(styles)(({classes, permissions, ...props}) => {

    const [EstadoGrafico, setEstadoGrafico] = useState(props.location.search);
    const [FiltroFecha, setFiltroFecha] = useState('');
    const [FechaDesde, setFechaDesde] = useState(0);
    const [FiltroUN, setFiltroUN] = useState('');

    /* const [handleDate] = useMutation(
         {
             type: 'update',
             resource: 'TableroControlContratacion',
             payload: {id: 'estado_anterior'}
         },
         {
             undoable: true,
             onSuccess: ({data}) => {

             }
         })
 */

    const handleDate = (value) => {
        console.log("Div is clicked")
        console.log(value);
        setFechaDesde(value);
    }


    return (
        <div>
            {/*<div>*/}
            {/*    <Vertitle/>*/}
            {/*</div>*/}
            <TableroControlContratacionGraficos
                estado={EstadoGrafico}
                handleDate={() => handleDate()}
                fechaDesde={FechaDesde}
                FiltroUN={FiltroUN}
                setFiltroUN={setFiltroUN}
                callback={handleDate}
                // updateText={this.updateText}
                // handleToUpdate = {handleToUpdate.bind(this)}
                // onSelectLanguage={this.handleLanguage}
            />
            <br/>
            <List {...props}
                  title=" "
                  // actions={<PostActions/>}
                  bulkActionButtons={false}
                  // exporter={false}
                  // filters={<MFiltro/>}
                  actions={<PostActions/>}
                  perPage={10}
                  empty={false}
                  sort={{ field: 'fecha_encuadre', order: 'DESC' }}
                  // filterDefaultValues={{ fecha_desde: FechaDesde, unidad_negocio_ing: FiltroUN}}
                  // actions={<ListActions maxResults={50000}/>}
            >
                <Datagrid rowStyle={RowStyle}  expand={<ContratacionEncuadreInfo/>}>
                    <TextField label="ID" source="id"/>
                    {/*<CircularCumplidoDiasPorcentaje label="Progreso Dias"/>}/>*/}
                    <LinearProgresoCumplidoDiasPorcentaje label="Progreso Dias"/>
                    <DateField label="Fecha" source="fecha_encuadre"/>
                    <TextField label="Expediente" source="expediente_referencia_gde" />
                    <TextField label="Encuadre" source="contratacion_tipo_encuadre" />

                    <TextField label="Año" source="contratacion_ano"/>

                    {/*<TextField label="Unidad Negocio" source="unidad_negocio"/>*/}
                    <TextField label="Unidad Negocio" source="unidad_negocio_d"/>
                    {/*<TextField label="Unidad Area" source="unidad_area"/>*/}
                    <TextField label="Unidad Area" source="unidad_area_d"/>
                    {/*<TextField label="Contratacion" source="contratacion_tipo"/>*/}

                    <NumberField label="Contratación $" source="importe_contratacion_pesos"
                                 style={{textAlign: 'right', margin: '1em'}}
                                 options={{minimumFractionDigits: 2}}/>
                    <TextField label="Estado Actual" source="estado"/>
                    {role_contratacion_tablero_mostrar_permissions({permissions}) === 'ROLE_CONTRATACION_TABLERO_MOSTRAR' &&
                    <LinkMostrarButton label="Mostrar"/>}
                    <SeguimientoButton label="Seguimiento"/>
                    {/*{role_auditoria_permissions({permissions}) === 'ROLE_AUDITORIA' &&*/}
                    {/*    <AuditoriaButton label="Auditoria" />*/}
                    {/*}*/}
                </Datagrid>
            </List>
        </div>
    )
});

const RowStyle = (record, index) => ({
    backgroundColor: record.estado  === undefined || record.estado_color  === undefined ? '' :
        record.estado_color.trim(),
});


export default TableroControlContratacionList;