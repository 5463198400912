import React, {useState} from "react";
import {
    FormTab,
    Show,
    TextInput,
    TabbedForm,
    TopToolbar,
    NumberInput,
    EditButton,
    DateInput,
    ReferenceManyField, Datagrid, TextField, DateField, NumberField, SelectInput, WithPermissions, usePermissions
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import ComboContratacionTipo from "../componentes/ComboContratacionTipo";
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";
import ComboArea from "../componentes/ComboArea";
import ComboActivoMoneda from "../componentes/ComboMonedaActivo";
import ComboContratacionEstado from "../componentes/ComboContratacionEstado";
import RichTextInput from "ra-input-rich-text";
import Typography from "@material-ui/core/Typography";
import ContratacionEncuadreSecuenciaInfo from "./ContratacionEncuadreSecuenciaInfo"

const useStyles = makeStyles((theme) => ({
    combo: {display: 'inline-block', width: 250, marginLeft: 8},
    combo_contratacion: {display: 'inline-block', width: 250, marginLeft: 58},
    id: {display: 'inline-block', width: 200, marginLeft: 8},
    fecha_encuadre: {display: 'inline-block', width: 200, marginLeft: 8},
    expediente_referencia_gde: {display: 'inline-block', width: 800, marginLeft: 8},
    expediente_referencia_gde2: {display: 'inline-block', width: 400, marginLeft: 58},
    contratacion_ano: {display: 'inline-block', width: 200, marginLeft: 108},
    moneda_cotizacion: {display: 'inline-block', width: 200, marginLeft: 8},
    moneda_cotizacion_fecha: {display: 'inline-block', width: 200, marginLeft: 108},
    importe_presupuesto: {display: 'inline-block', width: 200, marginLeft: 8},
    importe_presupuesto_pesos: {display: 'inline-block', width: 200, marginLeft: 108},
    importe_adjudicado_pesos: {display: 'inline-block', width: 200, marginLeft: 108},
    estado_encuadre: {display: 'inline-block', width: 200, marginLeft: 108},
    estado_encuadre2: {display: 'inline-block', width: 200, marginLeft: 8},
}));


const role_contratacion_seguimiento_completo = ({permissions}) => {
    var w_role_contratacion_seguimiento_completo = ''
    var w_permissions_admuno_contratacion_seguimiento_completo = permissions
    if(w_permissions_admuno_contratacion_seguimiento_completo==null){}else{
        var wa_role_contratacion_seguimiento_completo = w_permissions_admuno_contratacion_seguimiento_completo.indexOf('ROLE_CONTRATACION_SEGUIMIENTO_COMPLETO')
        if(wa_role_contratacion_seguimiento_completo===-1){}else{
            w_role_contratacion_seguimiento_completo = 'ROLE_CONTRATACION_SEGUIMIENTO_COMPLETO'
        }
    }
    return w_role_contratacion_seguimiento_completo
}

const role_contratacion_seguimiento_limitado = ({permissions}) => {
    var w_role_contratacion_seguimiento_limitado = ''
    var w_permissions_admuno_contratacion_seguimiento_limitado = permissions
    if(w_permissions_admuno_contratacion_seguimiento_limitado==null){}else{
        var wa_role_contratacion_seguimiento_limitado = w_permissions_admuno_contratacion_seguimiento_limitado.indexOf('ROLE_CONTRATACION_SEGUIMIENTO_LIMITADO')
        if(wa_role_contratacion_seguimiento_limitado===-1){}else{
            w_role_contratacion_seguimiento_limitado = 'ROLE_CONTRATACION_SEGUIMIENTO_LIMITADO'
        }
    }
    return w_role_contratacion_seguimiento_limitado
}

const ContratacionEncuadreShow = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    var w_role_contratacion_estado = ''
    var w_permissions_admuno_contratacion_estado = permissions
    if(w_permissions_admuno_contratacion_estado==null){}else{
        var wa_role_contratacion_estado = w_permissions_admuno_contratacion_estado.indexOf('ROLE_CONTRATACION_ESTADO')
        if(wa_role_contratacion_estado===-1){}else{
            w_role_contratacion_estado = 'ROLE_CONTRATACION_ESTADO'
        }
    }

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            {/*<EditButton basePath={basePath} record={data}/>*/}
        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        return <span>Encuadre Contrataciones: {record ? `${record.expediente_referencia_gde}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>
                {/*Datos*/}
                <FormTab label="DATOS CONTRATACION">
                    <NumberInput label="ID Contratacion" source="id"
                                 inputProps={{readOnly: true}}
                                 variant="filled"
                                 formClassName={classes.id}
                                 options={{ minimumFractionDigits: 0 }} />

                    <TextInput source="expediente_referencia_gde" label={"Expediente"}
                               variant="filled"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.expediente_referencia_gde2}
                               fullWidth={true}
                               inputProps={{readOnly: true}}
                    />
                    <TextInput
                        label="Estado Actual"
                        source="estado_encuadre"
                        formClassName={classes.estado_encuadre2}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        inputProps={{readOnly: true}}
                    />
                    <ComboContratacionTipo label={"Encuadre"} readOnly={true} variant="filled" formClassName={classes.combo_contratacion}/>
                    <br/>
                    <DateInput  source="fecha_encuadre" label={"Fecha"}
                                variant="filled"
                                inputProps={{readOnly: true}}
                        // InputLabelProps={{shrink: false}}
                                formClassName={classes.fecha_encuadre}
                    />
                    <NumberInput
                        id="standard-full-width"
                        label="Año"
                        source="contratacion_ano"
                        formClassName={classes.contratacion_ano}
                        placeholder="Ingrese Año"
                        // helperText="Año"
                        // margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        inputProps={{readOnly: true}}
                    />

                    <br/>
                    <ComboUnidadNegocio readOnly={true} variant="filled" formClassName={classes.combo}/>
                    <br/>
                    <ComboArea readOnly={true} variant="filled" formClassName={classes.combo}/>
                    <br/>
                    <ComboActivoMoneda readOnly={true} variant="filled" formClassName={classes.combo}/>

                    <NumberInput
                        id="standard-full-width"
                        label="Cotización"
                        source="moneda_cotizacion"
                        formClassName={classes.moneda_cotizacion}
                        placeholder="Ingrese Cotización"
                        // helperText="Cotización"
                        // margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        inputProps={{readOnly: true}}
                    />


                    <DateInput source="moneda_cotizacion_fecha" label={"Fecha Ctz."}
                        // variant="filled"
                               inputProps={{readOnly: true}}
                        // InputLabelProps={{shrink: false}}
                               formClassName={classes.moneda_cotizacion_fecha}
                    />
                    <br/>

                    <NumberInput
                        id="standard-full-width"
                        label="Importe Presupuesto"
                        source="importe_presupuesto"
                        formClassName={classes.importe_presupuesto}
                        placeholder="Ingrese Importe Presupuesto"
                        helperText="Importe Presupuesto"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        inputProps={{readOnly: true}}
                    />


                    <NumberInput
                        id="standard-full-width"
                        label="Importe Presupuesto $"
                        source="importe_presupuesto_pesos"
                        formClassName={classes.importe_presupuesto_pesos}
                        // placeholder="Ingrese Importe Presupuesto $"
                        helperText="Importe Presupuesto $"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        inputProps={{readOnly: true}}
                    />


                    <NumberInput
                        id="standard-full-width"
                        label="Importe Adjudicado Pesos"
                        source="importe_adjudicado_pesos"
                        formClassName={classes.importe_adjudicado_pesos}
                        // placeholder="Ingrese Importe Adjudicado Pesos"
                        helperText="Importe Adjudicado Pesos"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        inputProps={{readOnly: true}}
                    />
                    <br/>
                    <TextInput source="concepto_descripcion" label={"Concepto"}
                               variant="filled"
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.concepto_descripcion} fullWidth={true}/>
                </FormTab>

                {/* Secuencia de Cumplimiento*/}
                <FormTab label="SECUENCIA DE CUMPLIMIENTO">

                    <NumberInput label="ID Contratacion" source="id"
                                 inputProps={{readOnly: true}}
                                 variant="filled"
                                 formClassName={classes.id}
                                 options={{ minimumFractionDigits: 0 }} />

                    <TextInput source="expediente_referencia_gde" label={"Expediente"}
                               variant="filled"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.expediente_referencia_gde2}
                               fullWidth={true}
                               inputProps={{readOnly: true}}
                    />
                    <TextInput
                        label="Estado Actual"
                        source="estado_encuadre"
                        formClassName={classes.estado_encuadre2}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        inputProps={{readOnly: true}}
                    />
                    <ComboContratacionTipo label={"Encuadre"} readOnly={true} variant="filled" formClassName={classes.combo_contratacion}/>
                    <br/>

                    <WithPermissions
                        render={({ permissions }) => (
                            role_contratacion_seguimiento_completo({permissions }) === 'ROLE_CONTRATACION_SEGUIMIENTO_COMPLETO'
                                ?
                                    <ReferenceManyField
                                        sort={{ field: 'secuencia_item', order: 'ASC' }}
                                        perPage={500}
                                        reference="ContratacionEncuadreSecuencia"
                                        target="id"
                                        addLabel={false}
                                    >
                                        <Datagrid
                                            rowStyle={RowStyle}
                                            expand={<ContratacionEncuadreSecuenciaInfo/>}
                                        >
                                            <NumberField label="Item" source="secuencia_item"
                                                         style={{textAlign: 'right', margin: '1em' }}
                                                         options={{ minimumFractionDigits: 0 }} sortable={false}  />
                                            {/*<TextField   label="Tipo" source="secuencia_tipo" />*/}
                                            <NumberField label="Dias" source="secuencia_dias"
                                                         style={{textAlign: 'right', margin: '1em' }}
                                                         options={{ minimumFractionDigits: 0 }}  sortable={false} />
                                            <TextField label="Descripción" source="secuencia_d" sortable={false} />
                                            <DateField label="Fecha Tope" source="secuencia_fecha_tope"  sortable={false}/>
                                            <DateField label="Fecha Cumplido" source="secuencia_fecha_cumplido" sortable={false} />
                                            <TextField label="Cumplido" source="secuencia_cumplido"  sortable={false}/>
                                            <TextField label="Cumplido Estado" source="secuencia_cumplido_estado"  sortable={false}/>
                                            <DateField label="Fecha GDE" source="secuencia_fecha_gde" sortable={false} />
                                            <TextField label="Usuario GDE" source="secuencia_usuario_gde" sortable={false} />
                                            {/*<TextField label="Observacion" source="secuencia_observacion"  sortable={false}/>*/}


                                        </Datagrid>
                                    </ReferenceManyField>
                                : null
                        )}
                    />
                    <WithPermissions
                        render={({ permissions }) => (
                            role_contratacion_seguimiento_limitado({permissions }) === 'ROLE_CONTRATACION_SEGUIMIENTO_LIMITADO'
                                ?
                                <ReferenceManyField
                                    sort={{ field: 'secuencia_item', order: 'ASC' }}
                                    perPage={500}
                                    reference="ContratacionEncuadreSecuencia"
                                    target="id"
                                    addLabel={false}
                                >
                                    <Datagrid
                                        rowStyle={RowStyle}
                                        // expand={<ContratacionEncuadreSecuenciaInfo/>}
                                    >
                                        {/*<NumberField label="Item" source="secuencia_item"*/}
                                        {/*             style={{textAlign: 'right', margin: '1em' }}*/}
                                        {/*             options={{ minimumFractionDigits: 0 }} sortable={false}  />*/}
                                        {/*<TextField   label="Tipo" source="secuencia_tipo" />*/}
                                        {/*<NumberField label="Dias" source="secuencia_dias"*/}
                                        {/*             style={{textAlign: 'right', margin: '1em' }}*/}
                                        {/*             options={{ minimumFractionDigits: 0 }}  sortable={false} />*/}
                                        <TextField label="Descripción" source="secuencia_d" sortable={false} />
                                        {/*<DateField label="Fecha Tope" source="secuencia_fecha_tope"  sortable={false}/>*/}
                                        <DateField label="Fecha Cumplido" source="secuencia_fecha_cumplido" sortable={false} />
                                        <TextField label="Cumplido" source="secuencia_cumplido"  sortable={false}/>
                                        <TextField label="Cumplido Estado" source="secuencia_cumplido_estado"  sortable={false}/>
                                        {/*<DateField label="Fecha GDE" source="secuencia_fecha_gde" sortable={false} />*/}
                                        {/*<TextField label="Usuario GDE" source="secuencia_usuario_gde" sortable={false} />*/}
                                        {/*<TextField label="Observacion" source="secuencia_observacion"  sortable={false}/>*/}


                                    </Datagrid>
                                </ReferenceManyField>
                                : null
                        )}
                    />
                </FormTab>

                {/* Ordenes de Compra*/}
                <FormTab label="ORDENES DE COMPRA">

                    <NumberInput label="ID Contratacion" source="id"
                                 inputProps={{readOnly: true}}
                                 variant="filled"
                                 formClassName={classes.id}
                                 options={{ minimumFractionDigits: 0 }} />

                    <TextInput source="expediente_referencia_gde" label={"Expediente"}
                               variant="filled"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.expediente_referencia_gde2}
                               fullWidth={true}
                               inputProps={{readOnly: true}}
                    />
                    <TextInput
                        label="Estado Actual"
                        source="estado_encuadre"
                        formClassName={classes.estado_encuadre2}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        inputProps={{readOnly: true}}
                    />
                    <ComboContratacionTipo label={"Encuadre"} readOnly={true} variant="filled" formClassName={classes.combo_contratacion}/>
                    <br/>
                    <NumberInput
                        id="standard-full-width"
                        label="Importe Adjudicado Pesos"
                        source="importe_adjudicado_pesos"
                        formClassName={classes.importe_adjudicado_pesos}
                        // placeholder="Ingrese Importe Adjudicado Pesos"
                        helperText="Importe Adjudicado Pesos"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        inputProps={{readOnly: true}}
                    />
                    <br/>
                    <ReferenceManyField
                        sort={{ field: 'id', order: 'ASC' }}
                        perPage={500}
                        reference="ContratacionEncuadreAdjudicacion"
                        target="id"
                        addLabel={false}
                    >
                        <Datagrid
                            // expand={<OrdenCompraDetalleInfo/>}
                        >
                            <TextField label="Renglon" source="renglon_nro" />
                            <TextField label="Renglon Descripción" source="renglon_descripcion" cellClassName={classes.title}/>
                            <NumberField label="Importe Adjudicado Pesos" source="importe_adjudicado_pesos"
                                         style={{textAlign: 'right', margin: '1em' }}
                                         options={{ minimumFractionDigits: 2 }} />

                            <TextField label="Orden Compra Nro" source="orden_compra_nro"
                                         style={{textAlign: 'right', margin: '1em' }} />

                            <TextField label="Orden Compra Ampliacion Nro" source="orden_compra_ampliacion_nro"
                                         style={{textAlign: 'right', margin: '1em' }}/>

                            <TextField label="Proveedor RTA" source="proveedor_rta" />
                            <TextField label="Razon Social" source="razon_social" />
                            <TextField label="Codigo Pro TvPublica" source="codigo_pro_tvpublica" />
                            <TextField label="Codigo Pro RNA" source="codigo_pro_rna" />

                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>
                {w_role_contratacion_estado === 'ROLE_CONTRATACION_ESTADO' &&
                <FormTab label="ESTADOS CONTRATACION">
                    <NumberInput label="ID Contratacion" source="id"
                                 inputProps={{readOnly: true}}
                                 variant="filled"
                                 formClassName={classes.id}
                                 options={{minimumFractionDigits: 0}}/>

                    <TextInput source="expediente_referencia_gde" label={"Expediente"}
                               variant="filled"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.expediente_referencia_gde2}
                               fullWidth={true}
                               inputProps={{readOnly: true}}
                    />
                    <TextInput
                        label="Estado Actual"
                        source="estado_encuadre"
                        formClassName={classes.estado_encuadre2}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        inputProps={{readOnly: true}}
                    />
                    <ComboContratacionTipo label={"Encuadre"} readOnly={true} variant="filled"
                                           formClassName={classes.combo_contratacion}/>
                    <br/>
                    <ComboContratacionEstado label={"Estado Actual"} readOnly={true} variant="filled"
                                             formClassName={classes.combo}/>
                    <TextInput source="concepto_descripcion" label={"Concepto"}
                               variant="filled"
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.concepto_descripcion} fullWidth={true}/>
                    <Typography variant="h6">Estados</Typography>
                    <SelectInput source="en_progreso" label={"En Progreso"}
                                 inputProps={{readOnly: true}}
                                 choices={[
                                     {id: 'S', name: 'S'},
                                     {id: 'N', name: 'N'},
                                 ]}/>

                    <TextInput source="en_progreso_motivo" label={"En Progreso Motivo"}
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.en_progreso_motivo} fullWidth={true}
                    />

                    <SelectInput source="en_impugnacion" label={"En Impugnacion"}
                                 inputProps={{readOnly: true}}
                                 choices={[
                                     {id: 'S', name: 'S'},
                                     {id: 'N', name: 'N'},
                                 ]}/>

                    <TextInput source="en_impugnacion_motivo" label={"En Impugnacion Motivo"}
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.en_impugnacion_motivo} fullWidth={true}
                    />

                    <SelectInput source="en_suspenso" label={"En Suspenso"}
                                 inputProps={{readOnly: true}}
                                 choices={[
                                     {id: 'S', name: 'S'},
                                     {id: 'N', name: 'N'},
                                 ]}/>

                    <TextInput source="en_suspenso_motivo" label={"En Suspenso Motivo"}
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.en_suspenso_motivo} fullWidth={true}
                    />

                    <SelectInput source="en_revision" label={"En Revision"}
                                 inputProps={{readOnly: true}}
                                 choices={[
                                     {id: 'S', name: 'S'},
                                     {id: 'N', name: 'N'},
                                 ]}/>

                    <TextInput source="en_revision_motivo" label={"En Revision Motivo"}
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.en_revision_motivo} fullWidth={true}
                    />

                    <SelectInput source="en_anulado" label={"En Anulado"}
                                 inputProps={{readOnly: true}}
                                 choices={[
                                     {id: 'S', name: 'S'},
                                     {id: 'N', name: 'N'},
                                 ]}/>

                    <TextInput source="en_anulado_motivo" label={"En Anulado Motivo"}
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.en_anulado_motivo} fullWidth={true}
                    />

                    <SelectInput source="en_finalizado" label={"En Finalizado"}
                                 inputProps={{readOnly: true}}
                                 hoices={[
                                     {id: 'S', name: 'S'},
                                     {id: 'N', name: 'N'},
                                 ]}/>

                    <TextInput source="en_finalizado_motivo" label={"En Finalizado Motivo"}
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.en_finalizado_motivo} fullWidth={true}
                    />

                </FormTab>
                }
            </TabbedForm>
        </Show>
    )
};

const RowStyle = (record, index) => ({
    backgroundColor: record.secuencia_tipo  === undefined || record.estado_color  === undefined ? '' :
        record.estado_color.trim(),
});


export default ContratacionEncuadreShow;


