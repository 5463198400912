import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CustomerIcon from '@material-ui/icons/Assignment';
import WebIcon from '@material-ui/icons/Web';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Button, translate} from 'react-admin';
import Divider from '@material-ui/core/Divider';
import CardIcon from './CardIcon';
import Avatar from '@material-ui/core/Avatar';
import Imagetvp from '../layout/tvpublica_logo_2021.bmp'; // Import using relative path
import Imagerna from '../layout/Radio_Nacional_Logo_2021.jpg'; // Import using relative path
import Imagecdoce from '../layout/canal12tl.jpeg'
import Imagerta from '../layout/headerRTA.jpg'; // Import using relative path
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import cartuchonewlargo from "./cartuchonewlargo.png";
import BusinessIcon from "@material-ui/icons/Business";
import RefreshIcon from "@material-ui/icons/Refresh";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import AvatarImage from '../componentes/Avatar/AvatarImage'

const styles = {
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    cardleft: {
        overflow: 'inherit',
        textAlign: 'left',
        padding: 16,
        minHeight: 52,
    },
    button1: {
        fontWeight: 'bold',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange', backgroundColor: 'red'  }
    },
    textright:{
        textAlign: 'right',
    },
    textleft:{
        textAlign: 'left',
    },
    textcenter:{
        textAlign: 'center',
    },

};

const UnidadDeNEgociosTableroControlCant = ({ value, value1, value2, value3, value4, value5, value6, value7, value8,
                                    value9, value10, value11, value12, value13, value14, value15, value16,
                                    fecha_hora, handleClickActualizarUnidadDeNegocios, translate, classes }) => {
    if (value1 === 'S') {
        return (
            <div className={classes.main}>
                <CardIcon Icon={WebIcon} bgColor="#4caf50" />
                <Card className={classes.card}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.textcenter}>
                                    <Typography variant="headline" component="h2">
                                        <AvatarImage medio={'RTA'} />
                                        <Typography variant="headline" component="h2" style={{textAlign: 'left'}}>
                                            {value12}
                                        </Typography>
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.textright}>
                                    <Typography variant="headline" component="h2">
                                        {'Ordenes de Compras: '+value11}
                                    </Typography>
                                    <Typography variant="headline" component="h2">
                                        {'$: '}{value8 === undefined ? '0,0' : value8.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 16,
                                    })}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <Divider />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.textcenter}>
                                    <Typography variant="headline" component="h3">
                                        <AvatarImage medio={'Canal7'} />
                                        <Typography variant="headline" component="h2" style={{textAlign: 'left'}}>
                                            {value3}
                                        </Typography>
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.textright}>
                                    <Typography variant="headline" component="h2">
                                        {'Ordenes de Compras: '+value9}
                                    </Typography>
                                    <Typography variant="headline" component="h2">
                                        {'$: '}{value4 === undefined ? '0,0' : value4.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 16,
                                    })}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <Divider />
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.textcenter}>
                                    <Typography variant="headline" component="h3">
                                        <AvatarImage medio={'RNA'} />
                                        <Typography variant="headline" component="h2" style={{textAlign: 'left'}}>
                                            {value6}
                                        </Typography>
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.textright}>
                                    <Typography variant="headline" component="h2">
                                        {'Ordenes de Compras: '+value10}
                                    </Typography>
                                    <Typography variant="headline" component="h2">
                                        {'$: '}{value7 === undefined ? '0,0' : value7.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 16,
                                    })}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <Divider />
                    {/*<Table>*/}
                    {/*    <TableHead>*/}
                    {/*        <TableRow>*/}
                    {/*            <TableCell className={classes.textcenter}>*/}
                    {/*                <Typography variant="headline" component="h2">*/}
                    {/*                    {<Avatar*/}
                    {/*                        src={`${Imagecdoce}?size=${80}x${80}`}*/}
                    {/*                        size={80}*/}
                    {/*                        style={{ width: 80, height: 80 }}*/}
                    {/*                    />}*/}
                    {/*                    <Typography variant="headline" component="h2" style={{textAlign: 'left'}}>*/}
                    {/*                        {value14}*/}
                    {/*                    </Typography>*/}
                    {/*                </Typography>*/}
                    {/*            </TableCell>*/}
                    {/*            <TableCell className={classes.textright}>*/}
                    {/*                <Typography variant="headline" component="h2">*/}
                    {/*                    {'Ordenes de Compras: '+value16}*/}
                    {/*                </Typography>*/}
                    {/*                <Typography variant="headline" component="h2">*/}
                    {/*                    {'$: '}{value15 === undefined ? '0,0' : value15.toLocaleString(undefined, {*/}
                    {/*                    minimumFractionDigits: 2,*/}
                    {/*                    maximumFractionDigits: 16,*/}
                    {/*                })}*/}
                    {/*                </Typography>*/}
                    {/*            </TableCell>*/}
                    {/*        </TableRow>*/}
                    {/*    </TableHead>*/}
                    {/*</Table>*/}
                    <Divider />
                    <Table style={{ background: `url(${cartuchonewlargo}) no-repeat`}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <AlarmOnIcon/>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="headline" component="h2">
                                        <div>{`Ordenes de Compras (Importe Total Pesos)`}</div>
                                    </Typography>
                                    <div>{`Última Actualización:`}</div>
                                    <div>{`${fecha_hora}.`}</div>
                                </TableCell>
                                <TableCell>
                                    <Button label={'Actualizar'}
                                            title={'Actualizar'}
                                            onClick={handleClickActualizarUnidadDeNegocios}
                                            style={{color: '#123B80'}}
                                    >
                                        <RefreshIcon/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>

                </Card>
            </div>
        );
    }else{
        if (value2 === 'S') {
            return (
                <div className={classes.main}>
                    <CardIcon Icon={WebIcon} bgColor="#4caf50" />
                    <Card className={classes.card}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.textcenter}>
                                        <Typography variant="headline" component="h3">
                                            <AvatarImage medio={'Canal7'} />
                                            <Typography variant="headline" component="h2" style={{textAlign: 'left'}}>
                                                {value3}
                                            </Typography>
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.textright}>
                                        <Typography variant="headline" component="h2">
                                            {'Ordenes de Compras: '+value9}
                                        </Typography>
                                        <Typography variant="headline" component="h2">
                                            {'$: '}{value4 === undefined ? '0,0' : value4.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 16,
                                        })}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <Divider />
                        <Table style={{ background: `url(${cartuchonewlargo}) no-repeat`}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <AlarmOnIcon/>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="headline" component="h2">
                                            <div>{`Ordenes de Compras (Importe Total Pesos)`}</div>
                                        </Typography>
                                        <div>{`Última Actualización:`}</div>
                                        <div>{`${fecha_hora}.`}</div>
                                    </TableCell>
                                    <TableCell>
                                        <Button label={'Actualizar'}
                                                title={'Actualizar'}
                                                onClick={handleClickActualizarUnidadDeNegocios}
                                                style={{color: '#123B80'}}
                                        >
                                            <RefreshIcon/>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </Card>
                </div>
            );
        }else{
            if (value5 === 'S') {
                return (
                    <div className={classes.main}>
                        <CardIcon Icon={WebIcon} bgColor="#4caf50" />
                        <Card className={classes.card}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.textcenter}>
                                            <Typography variant="headline" component="h3">
                                                <AvatarImage medio={'RNA'} />
                                                <Typography variant="headline" component="h2" style={{textAlign: 'left'}}>
                                                    {value6}
                                                </Typography>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textright}>
                                            <Typography variant="headline" component="h2">
                                                {'Ordenes de Compras: '+value10}
                                            </Typography>
                                            <Typography variant="headline" component="h2">
                                                {'$: '}{value7 === undefined ? '0,0' : value7.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 16,
                                            })}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Divider />
                            <Table style={{ background: `url(${cartuchonewlargo}) no-repeat`}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <AlarmOnIcon/>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="headline" component="h2">
                                                <div>{`Ordenes de Compras (Importe Total Pesos)`}</div>
                                            </Typography>
                                            <div>{`Última Actualización:`}</div>
                                            <div>{`${fecha_hora}.`}</div>
                                        </TableCell>
                                        <TableCell>
                                            <Button label={'Actualizar'}
                                                    title={'Actualizar'}
                                                    onClick={handleClickActualizarUnidadDeNegocios}
                                                    style={{color: '#123B80'}}
                                            >
                                                <RefreshIcon/>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Card>
                    </div>
                );
            }else{
                if (value13 === 'S') {
                    return (
                        <div className={classes.main}>
                            <CardIcon Icon={WebIcon} bgColor="#4caf50" />
                            <Card className={classes.card}>
                                {/*<Table>*/}
                                {/*    <TableHead>*/}
                                {/*        <TableRow>*/}
                                {/*            <TableCell className={classes.textcenter}>*/}
                                {/*                <Typography variant="headline" component="h2">*/}
                                {/*                    {<Avatar*/}
                                {/*                        src={`${Imagecdoce}?size=${80}x${80}`}*/}
                                {/*                        size={80}*/}
                                {/*                        style={{ width: 80, height: 80 }}*/}
                                {/*                    />}*/}
                                {/*                    <Typography variant="headline" component="h2" style={{textAlign: 'left'}}>*/}
                                {/*                        {value14}*/}
                                {/*                    </Typography>*/}
                                {/*                </Typography>*/}
                                {/*            </TableCell>*/}
                                {/*            <TableCell className={classes.textright}>*/}
                                {/*                <Typography variant="headline" component="h2">*/}
                                {/*                    {'Ordenes de Compras: '+value16}*/}
                                {/*                </Typography>*/}
                                {/*                <Typography variant="headline" component="h2">*/}
                                {/*                    {'$: '}{value15 === undefined ? '0,0' : value15.toLocaleString(undefined, {*/}
                                {/*                    minimumFractionDigits: 2,*/}
                                {/*                    maximumFractionDigits: 16,*/}
                                {/*                })}*/}
                                {/*                </Typography>*/}
                                {/*            </TableCell>*/}
                                {/*        </TableRow>*/}
                                {/*    </TableHead>*/}
                                {/*</Table>*/}
                                <Divider />
                                <Table style={{ background: `url(${cartuchonewlargo}) no-repeat`}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <AlarmOnIcon/>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="headline" component="h2">
                                                    <div>{`Ordenes de Compras (Importe Total Pesos)`}</div>
                                                </Typography>
                                                <div>{`Última Actualización:`}</div>
                                                <div>{`${fecha_hora}.`}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Button label={'Actualizar'}
                                                        title={'Actualizar'}
                                                        onClick={handleClickActualizarUnidadDeNegocios}
                                                        style={{color: '#123B80'}}
                                                >
                                                    <RefreshIcon/>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            </Card>
                        </div>
                    );
                }else{
                    return (
                        <div className={classes.main}>
                            <CardIcon Icon={WebIcon} bgColor="#4caf50" />
                            <Card className={classes.card}>
                                <Typography variant="headline" component="h2">
                                    {''}
                                </Typography>
                            </Card>

                        </div>
                    );
                }
            }
        }
    }
};

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(UnidadDeNEgociosTableroControlCant);
