import React, {useState, useCallback} from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Create,
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    AutocompleteInput,
    SelectInput,
    DateInput,
    TopToolbar,
    ReferenceInput,
    Toolbar, SaveButton, DeleteButton,
    useGetOne, Loading, NumberInput, TextField, ShowButton
} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    pais: {display: 'inline-block', width: 400, marginLeft: 8},
    pais_codigo_afip: {display: 'inline-block', width: 200, marginLeft: 8},
    pais_codigo_gestion: {display: 'inline-block', width: 200, marginLeft: 8},
}));

const PaisEdit = props => {
    const classes = useStyles();


    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );

    const redirect = (basePath, id, data) => `/Pais`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            <DeleteButton undoable={false} redirect={redirect}/>
        </Toolbar>
    );
    const transform = data => {
        return {
            ...data
        }
    };

    const PostTitle = ({record}) => {
        return <span>Pais: {record ? `${record.pais}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={<CustomToolbar/>}
            >
                {/*Datos*/}
                <FormTab label="DATOS PAIS">

                    <NumberInput
                        id="standard-full-width"
                        label="Descripción Código AFIP"
                        source="pais_codigo_afip"
                        formClassName={classes.pais_codigo_afip}
                        placeholder="Ingrese Código AFIP Código AFIP"
                        helperText="Código AFIP"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    <NumberInput
                        id="standard-full-width"
                        label="Descripción Código Gestion"
                        source="pais_codigo_gestion"
                        formClassName={classes.pais_codigo_gestion}
                        placeholder="Ingrese Código Gestion Código Gestion"
                        helperText="Código Gestion"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    <TextInput
                        label="Pais"
                        source="pais"
                        formClassName={classes.pais}
                        placeholder="Ingrese Pais"
                        helperText="Pais"
                        multiline
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                </FormTab>
            </TabbedForm>
        </Edit>
    )
};
export default PaisEdit;


