import {FormDataConsumer, TextInput} from 'react-admin';
import React, {useCallback} from "react";
import {useForm} from "react-final-form";
import {makeStyles} from "@material-ui/core";
import ProveedorBusquedaBtn from "../proveedor/ProveedorBusquedaBtn";
import Typography from "@material-ui/core/Typography";
import PaisComercialBusquedaBtn from "../pais/PaisBusquedaBtn";
import ProvinciaLegalBusquedaBtn from "../Provincia/ProvinciaBusquedaBtn";
import CiudadLegalBusquedaBtn from "../Ciudad/CiudadBusquedaBtn";
import ComboIvaResponsable from "../componentes/ComboIvaResponsable";

const useStyles = makeStyles((theme) => ({
    persona_forma: {marginLeft: 32},

    proveedor: {display: 'inline-block', width: 120},
    razon_social: {display: 'inline-block', width: 400},
    razon_social_fantasia: {display: 'inline-block', width: 400, marginLeft: 32},
    documento_tipo: {display: 'inline-block', width: 200},
    documento_nro: {display: 'inline-block', width: 138, marginLeft: 32},
    cuit_cuil: {display: 'inline-block', width: 200, marginLeft: 32},
    email: {display: 'inline-block', width: 280},
    emailAlt: {display: 'inline-block', width: 280, marginLeft: 32},
    area: {display: 'inline-block', width: 80},
    nro: {display: 'inline-block', marginLeft: 32, width: 168,},
    area2: {display: 'inline-block', width: 80, marginLeft: 32},
    pais: {display: 'inline-block', width: 250, marginLeft: 32},
    codPais: {display: 'inline-block', width: 80},
    provincia: {display: 'inline-block', width: 250},
    provinciaxl: {display: 'inline-block', width: 350},
    partido: {display: 'inline-block', width: 350, marginLeft: 32},
    ciudad: {display: 'inline-block', width: 350, marginLeft: 32},

    calle: {display: 'inline-block', width: 410},
    calle_nro: {display: 'inline-block', width: 90, marginLeft: 32},
    piso: {display: 'inline-block', width: 50, marginLeft: 32},
    departamento: {display: 'inline-block', width: 50, marginLeft: 20},
    codigo_postal: {display: 'inline-block', width: 110, marginLeft: 20},
    d_iva: {display: 'inline-block', width: 370},

    codigo_pro_tvpublica: {display: 'inline-block', width: 150},
    codigo_pro_rna: {display: 'inline-block', width: 150, marginLeft: 32},
}));

const OrdenDeCompraProveedor = ({setPersonaTipo, isReadonly}) => {
    const {form, change} = useForm();
    const classes = useStyles();

    const updateProveedor = useCallback(async (who) => {
        console.log("proveedor: ", who);
        change("id_proveedor", who.id);
        change("proveedor_rta", who.pro);
        change('razon_social', who.razon_social);
        change('razon_social_fantasia', who.razon_social_fantasia);
        change("documento_tipo", who.documento_tipo_d);
        change("documento_nro", who.documento_nro);
        change("cuit_cuil", who.cuit_cuil);

        change("id_iva", who.id_iva);
        change("d_iva", who.d_iva);

        change("domicilio_pais", who.comercial_pais);
        change("Pais.pais", who.d_pais_comercial ? who.d_pais_comercial.trim() : null);
        // setPais(who.who.d_pais_comercial.trim())

        change("domicilio_provincia", who.comercial_provincia);
        change("domicilio_partido", who.comercial_partido);
        change("domicilio_ciudad", who.comercial_ciudad);


        change("provincia_comercial_d", who.provincia_comercial_d);
        change("ciudad_comercial_d", who.ciudad_comercial_d);
        change("domicilio_partido", who.comercial_partido);
        change("id_comercial_ciudad", who.id_comercial_ciudad);

        change("domicilio_calle", who.comercial_calle);
        change("domicilio_calle_nro", who.comercial_calle_nro);
        change("domicilio_piso", who.comercial_piso);
        change("domicilio_departamento", who.comercial_departamento);
        change("domicilio_codigo_postal", who.comercial_codigo_postal);
        change("contacto_correo_electronico", who.correo_electronico);
        change("contacto_correo_electronico_alternativo", who.correo_electronico_alternativo);
        change("contacto_telefono_area", who.telefono_area);
        change("contacto_telefono_nro", who.telefono_nro);
        change("contacto_telefono_alternativo_area", who.telefono_alternativo_area);
        change("contacto_telefono_alternativo_nro", who.telefono_alternativo_nro);
        change("contacto_celular_area", who.celular_area);
        change("contacto_celular_nro", who.celular_nro);
        change("contacto_fax_area", who.fax_area);
        change("contacto_fax_nro", who.fax_nro);
        change("codigo_pro_tvpublica", who.codigo_pro_tvpublica);
        change("codigo_pro_rna", who.codigo_pro_rna);


    }, [form]);

    const updateComercialPais = useCallback(async (who) => {
        change("Pais.pais", who.pais.trim());
        change("domicilio_pais", who.pais_codigo_afip);
        change("domicilio_provincia", '');
        change("domicilio_ciudad", '');
        change("domicilio_partido", '');
    });

    const updateProvinciaComercial = useCallback(async (who) => {
        change("domicilio_provincia", who.provincia.trim());
    });

    const updateCiudadComercial = useCallback(async (who) => {
        change("domicilio_ciudad", who.ciudad.trim());
    });

    return (
        <>

            <FormDataConsumer>
                {({formData, ...rest}) => (
                    <>

                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput source="proveedor_rta" label="Proveedor"
                                       className={classes.proveedor}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       fullWidth
                                       InputLabelProps={{shrink: true}}
                            />

                            {/*estado_proveedor = 3 -> Inscripto*/}
                            <ProveedorBusquedaBtn callbackProveedor={updateProveedor} estado_proveedor={3}
                                                  className={classes.btnBusqueda} fullWidth={true}
                            />

                        </div>

                        <TextInput source="razon_social" label={"Razón Social"} variant="filled"
                                   inputProps={{readOnly: true}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.razon_social} fullWidth={true}/>

                        <TextInput source="razon_social_fantasia" label={"Razon Fantasia"} variant="filled"
                                   inputProps={{readOnly: true}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.razon_social_fantasia} fullWidth={true}
                        />
                        <br/>
                        <TextInput source="documento_tipo" label={"Tipo Doc."} variant="filled"
                                   inputProps={{readOnly: true,}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.documento_tipo} fullWidth={true}
                        />

                        <TextInput source="documento_nro" label={"Nro. Documento"}
                                   InputLabelProps={{shrink: true}} variant="filled"
                                   inputProps={{readOnly: true,}}
                                   className={classes.documento_nro}
                        />

                        <TextInput source="cuit_cuil" label={"CUIT/CUIL"} variant="filled"
                                   inputProps={{readOnly: true,}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.cuit_cuil} fullWidth={true}
                        />
                        <br/>

                        <ComboIvaResponsable readOnly={false} className={classes.combo}/>

                        <br/>

                        <Typography variant="h6">Domicilio Comercial</Typography>

                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput source="domicilio_pais" label={"Cod. Pais"}
                                       fullWidth={true}
                                       className={classes.codPais}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                                       style={{visible: false}}
                            />

                            <TextInput source="Pais.pais" label={"Pais Comercial"}
                                       fullWidth={true}
                                       className={classes.pais}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                            />


                            <PaisComercialBusquedaBtn callbackPais={updateComercialPais}
                                                      formClassName={classes.btnBusqueda}/>
                        </div>

                        {formData.domicilio_pais === 200 ? (
                            <div style={{display: 'inline-flex', width: '100%'}}>

                                <TextInput source="domicilio_provincia" label={"Provincia Comercial"}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           className={classes.provincia} fullWidth
                                           InputLabelProps={{shrink: true}}
                                />

                                <ProvinciaLegalBusquedaBtn callbackProvincia={updateProvinciaComercial}
                                                           fullWidth
                                                           className={classes.btnBusqueda}
                                />

                                <TextInput source="domicilio_ciudad" label={"Ciudad Comercial"}
                                           className={classes.ciudad} fullWidth
                                           variant="filled" inputProps={{readOnly: true,}}
                                           InputLabelProps={{shrink: true}}
                                />

                                <CiudadLegalBusquedaBtn callbackCiudad={updateCiudadComercial} fullWidth
                                                        className={classes.btnBusqueda}/>

                                <TextInput source="domicilio_partido" label={"Partido"} variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.partido} fullWidth={true}
                                />
                            </div>
                        ) : (
                            <>
                                <TextInput source="domicilio_provincia" label={"Provincia"} variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.provincia} fullWidth={true}
                                />

                                <TextInput source="domicilio_ciudad" label={"Ciudad"} variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.ciudad} fullWidth={true}
                                />

                                <TextInput source="domicilio_partido" label={"Partido"} variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.partido} fullWidth={true}
                                />
                            </>
                        )
                        }

                        <br/>

                        <TextInput source="domicilio_calle" label={"Calle"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.calle} fullWidth={true}
                        />

                        <TextInput source="domicilio_calle_nro" label={"Nro."} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.calle_nro} fullWidth={true}
                        />

                        <TextInput source="domicilio_piso" label={"Piso"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.piso} fullWidth={true}
                        />

                        <TextInput source="domicilio_departamento" label={"Depto"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.departamento} fullWidth={true}
                        />

                        <TextInput source="domicilio_codigo_postal" label={"Código Postal"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.codigo_postal} fullWidth={true}
                        />
                        <br/>

                        <Typography variant="h6">Contacto</Typography>

                        <TextInput source="contacto_correo_electronico" label={"Email"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.email} fullWidth={true}
                        />

                        <TextInput source="contacto_correo_electronico_alternativo" label={"Email alternativo"}
                                   variant="outlined" InputLabelProps={{shrink: true}}
                                   className={classes.emailAlt} fullWidth={true}
                        />
                        <br/>
                        <TextInput source="contacto_telefono_area" label={"Tel Área"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.area}
                                   fullWidth={true}
                        />

                        <TextInput source="contacto_telefono_nro" label={"Tel Nro"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro}
                                   fullWidth={true}/>

                        <TextInput source="contacto_telefono_alternativo_area" label={"T.Área Alt"}
                                   variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.area2} fullWidth={true}
                        />

                        <TextInput source="contacto_telefono_alternativo_nro" label={"Tel Nro Alt."}
                                   variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro} fullWidth={true}
                        />
                        <br/>

                        <TextInput source="contacto_celular_area" label={"Cel Área"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.area}
                                   fullWidth={true}
                        />

                        <TextInput source="contacto_celular_nro" label={"Tel Nro"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro}
                                   fullWidth={true}
                        />

                        <TextInput source="contacto_fax_area" label={"Fax Área"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.area2}
                                   fullWidth={true}
                        />

                        <TextInput source="contacto_fax_nro" label={"Fax Nro."} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro}
                                   fullWidth={true}
                        />
                        <br/>

                        {/**proveedor unidad*/}
                        <Typography variant="h6">Proveedor Unidad</Typography>
                        <TextInput source="codigo_pro_tvpublica"
                                   label={"Código TVPública"}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.codigo_pro_tvpublica}
                                   fullWidth={true}
                        />

                        <TextInput source="codigo_pro_rna"
                                   label={"Código RNA"}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.codigo_pro_rna}
                                   fullWidth={true}
                        />
                    </>
                )}
            </FormDataConsumer>

        </>
    );
};

export default OrdenDeCompraProveedor;
