import React, {useState} from 'react'
import {
    TextInput,
    Filter, FormTab, Loading,
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


export const ProveedorPersonaFisica = (props) => {

    const useStyles = makeStyles({
        // Proveedor Persona Física
        nombre: {display: 'inline-block', width: 200},
        apellido: {display: 'inline-block', width: 200, marginLeft: 32},
        sexo: {display: 'inline-block', width: 80, marginLeft: 32},
        fecha_nacimiento: {display: 'inline-block', width: 80, marginLeft: 32},
        nacionalidad: {display: 'inline-block', width: 300},
        estado_civil: {display: 'inline-block', width: 80, marginLeft: 32},
        correo_electronico: {display: 'inline-block', width: 200, marginLeft: 32},
    });
    const classes = useStyles();

    return (
        <div>
            {/*Persona Física*/}
            <Typography variant="h6">Persona Física</Typography>
            {/*Tabla:[proveedor_persona_fisica] , es un  solo registro por proveedor*/}
            <TextInput source="PersonaFisica.nombre" label={"Nombre"} variant="outlined"
                       className={classes.nombre} fullWidth={true} InputLabelProps={{shrink: true}} />

            <TextInput source="PersonaFisica.apellido" label={"Apellido"} variant="outlined"
                       className={classes.apellido} fullWidth={true} InputLabelProps={{shrink: true}} />

            <TextInput source="PersonaFisica.sexo" label={"Sexo"} variant="outlined"
                       className={classes.sexo} fullWidth={true} InputLabelProps={{shrink: true}} />

            <TextInput source="PersonaFisica.fecha_nacimiento" label={"Fecha Nacimiento"} variant="outlined"
                       className={classes.fecha_nacimiento} fullWidth={true} InputLabelProps={{shrink: true}} />
<br/>
            <TextInput source="PersonaFisica.nacionalidad" label={"Nacionalidad"} variant="outlined"
                       className={classes.nacionalidad} fullWidth={true} InputLabelProps={{shrink: true}} />

            <TextInput source="PersonaFisica.estado_civil" label={"Estado Civil"} variant="outlined"
                       className={classes.estado_civil} fullWidth={true} InputLabelProps={{shrink: true}} />

            <TextInput source="PersonaFisica.correo_electronico_pfisica" label={"Email"} variant="outlined"
                       className={classes.correo_electronico} fullWidth={true} InputLabelProps={{shrink: true}} />

        </div>
    );
};

export default ProveedorPersonaFisica;



