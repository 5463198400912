import React from "react";
import {
    FormTab,
    Show,
    TextInput,
    TabbedForm, Toolbar
} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    pais: {display: 'inline-block', width: 400, marginLeft: 8},
    pais_codigo_afip: {display: 'inline-block', width: 200, marginLeft: 8},
    pais_codigo_gestion: {display: 'inline-block', width: 200, marginLeft: 8},
}));

const CompraCptShow = props => {
    const PostTitle = ({record}) => {
        return <span>Comprobante de Compra: {record ? `${record.cpt_d}` : ''}</span>;
    };

    const PostEditActions = ({basePath, data, resource}) => (
        <Toolbar {...props}>
            <>
            </>
        </Toolbar>
    );

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm>
                <FormTab label="Comprobante de Compra">
                    <TextInput label="Tipo" source="cpt_tipo" inputProps={{readOnly: true,}}/>
                    <TextInput label="Descripción" source="cpt_d" inputProps={{readOnly: true,}}/>
                    <TextInput label="Abrev." source="cpt_abre" inputProps={{readOnly: true,}}/>
                    <TextInput label="Letra" source="cpt_letra" inputProps={{readOnly: true,}}/>
                    <TextInput label="Sucursal" source="cpt_sucursal" inputProps={{readOnly: true,}}/>
                    <TextInput label="Último Nro Usado" source="cpt_ultimo_nro_usado" inputProps={{readOnly: true,}}/>
                </FormTab>
            </TabbedForm>
        </Show>
    )
};
export default CompraCptShow;


