import React, {useCallback, useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput, maxLength, required, TopToolbar, SaveButton
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import PersonaTipoBusquedaBtn from "../personaTipo/PersonaTipoBusquedaBtn";
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles((theme) => ({
    persona_forma: {display: 'inline-block', width: 200, marginLeft: 8},
    persona_forma_d: {display: 'inline-block', width: 600, marginLeft: 8},
    persona_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    persona_tipo_d: {display: 'inline-block', width: 600, marginLeft: 108},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_persona_forma = values.persona_forma

    if (w_persona_forma===undefined) {
        errors.persona_forma = ['El campo Código es requerido'];
    }else {
        if (w_persona_forma === null) {
            errors.persona_forma = ['El campo Código es requerido'];
        } else {
            w_persona_forma = w_persona_forma.trim()
            if (w_persona_forma === '') {
                errors.persona_forma = ['El campo Código es requerido'];
            }
        }
    }


    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/PersonaForma`;

const validatepersona_forma = [required(), maxLength(10)];
const validatepersona_forma_d = [required(), maxLength(60)];

const PersonaFormaCreate = props => {
    const classes = useStyles();
    const [state, setState] = useState({});

    const PostTitle = ({record}) => {
        return <span>Alta de Persona Forma</span>;
    };

    const {
        PersonaTipo = '',
        PersonaTipoD = ''
    } = state;

    const formatPersonaTipo = v => {
        return PersonaTipo
    };

    const formatPersonaTipoD = v => {
        return PersonaTipoD
    };

    const updatePersonaTipo = useCallback(async (who) => {
        setState(state => ({
            ...state,
            PersonaTipo: who.persona_tipo,
            PersonaTipoD: who.persona_tipo_d.trim(),
        }));
    })

    const PostCreateActions = ({basePath, data, resource}) => (
        <TopToolbar>

        </TopToolbar>
    );

    // redirect to the related Author show page
    const redirect = (basePath, id, data) => `/PersonaForma`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Crear"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />

        </Toolbar>
    );

    const transform = data => {

        let wt_persona_tipo = PersonaTipo;


        return {
            ...data,
            wp_persona_tipo: wt_persona_tipo,
        }
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS PERSONA FORMA">
                    <TextInput
                        label="Código Persona Forma"
                        source="persona_forma"
                        formClassName={classes.persona_forma}
                        placeholder="Ingrese Código Persona Forma"
                        helperText="Código Persona Forma"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validatepersona_forma}
                    />

                    <br/>

                    <TextInput
                        label="Descripción Persona Forma"
                        source="persona_forma_d"
                        formClassName={classes.persona_forma_d}
                        placeholder="Ingrese Descripción Persona Forma"
                        helperText="Descripción Persona Forma"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validatepersona_forma_d}
                    />


                    <PersonaTipoBusquedaBtn callbackPersonaTipo={updatePersonaTipo} persona_tipo={PersonaTipo} formClassName={classes.btnBusqueda}/>
                    <TextInput
                        label="Código PersonaTipo"
                        source="persona_tipo"
                        value={PersonaTipo}
                        formClassName={classes.persona_tipo}
                        placeholder="Ingrese PersonaTipo"
                        helperText="Código PersonaTipo"
                        margin="normal"
                        format={formatPersonaTipo}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <TextField
                        id="persona_tipo_d-read-only-input"
                        value={PersonaTipoD}
                        InputProps={{
                            readOnly: true,
                        }}
                        formClassName={classes.persona_tipo_d}
                        variant="filled"
                        label="Descripción PersonaTipo"
                        placeholder="Ingrese Descripción PersonaTipo"
                        helperText="Descripción PersonaTipo"
                        margin="normal"
                        format={formatPersonaTipoD}
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default PersonaFormaCreate;


