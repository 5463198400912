import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField, useNotify, useRefresh, useMutation
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
import LineWeightIcon from "@material-ui/icons/LineWeight";
import SaveIcon from '@material-ui/icons/Save';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from "@material-ui/core/FormHelperText";

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if (w_apiurlserver == null) {
    w_apiurlserver = ''
}
w_apiurlserver = w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const useStyles2 = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));


const ContratacionEncuadreSecuenciaButtonEdit = ({record}) => {
    var wr_id = record.id

    var wr_secuencia_tipo = record.secuencia_tipo

    if (wr_secuencia_tipo == null) {
        wr_secuencia_tipo = ''
    }
    wr_secuencia_tipo = wr_secuencia_tipo.trim()

    var wr_secuencia_cumplido = record.secuencia_cumplido

    if (wr_secuencia_cumplido == null) {
        wr_secuencia_cumplido = ''
    }
    wr_secuencia_cumplido = wr_secuencia_cumplido.trim()


    var wr_usar_edit = record.usar_edit

    if (wr_usar_edit == null) {
        wr_usar_edit = ''
    }
    wr_usar_edit = wr_usar_edit.trim()

    const dataProviderTableroControl = useDataProvider();
    const [Estadoid, setEstadoid] = useState(0)
    const [Estadoid_contratacion_encuadre, setEstadoid_contratacion_encuadre] = useState(0)


    const [Estadoexpediente_referencia_gde, setEstadoexpediente_referencia_gde] = useState('')
    const [Estadocontratacion_tipo, setEstadocontratacion_tipo] = useState('')
    const [Estadosecuencia_item, setEstadosecuencia_item] = useState('')
    const [Estadosecuencia_tipo, setEstadosecuencia_tipo] = useState('')
    const [Estadosecuencia_dias, setEstadosecuencia_dias] = useState('')
    const [Estadosecuencia_d, setEstadosecuencia_d] = useState('')
    const [Estadosecuencia_fecha_tope, setEstadosecuencia_fecha_tope] = useState('')
    const [Estadosecuencia_fecha_cumplido, setEstadosecuencia_fecha_cumplido] = useState('')
    const [EstadoreadOnlysecuencia_fecha_cumplido, setEstadoreadOnlysecuencia_fecha_cumplido] = useState(true);
    const [Estadosecuencia_cumplido, setEstadosecuencia_cumplido] = useState('')
    const [Estadosecuencia_cumplido_estado, setEstadosecuencia_cumplido_estado] = useState('')
    const [Estadosecuencia_fecha_gde, setEstadosecuencia_fecha_gde] = useState('')
    const [Estadosecuencia_usuario_gde, setEstadosecuencia_usuario_gde] = useState('')
    const [Estadosecuencia_observacion, setEstadosecuencia_observacion] = useState('')
    const [openEditarConfirmacionContratacionEncuadreSecuencia, setopenEditarConfirmacionContratacionEncuadreSecuencia] = React.useState(false);
    const [helperTextsecuencia_fecha_cumplido, setHelperTextsecuencia_fecha_cumplido] = useState('');

    const classes = useStyles()
    const classes2 = useStyles2()
    const [open, setOpen] = React.useState(false);

    var wr_secuencia_tipo_editar = 'S'


    if (wr_secuencia_tipo === 'TITULO') {
        wr_secuencia_tipo_editar = 'N'
    }
    if (wr_secuencia_tipo === 'TOTDIAS-NORMAL') {
        wr_secuencia_tipo_editar = 'N'
    }
    if (wr_secuencia_tipo === 'TOTDIAS-IMPUGNACION') {
        wr_secuencia_tipo_editar = 'N'
    }
    if (wr_usar_edit === 'N') {
        wr_secuencia_tipo_editar = 'N'
    }

    if (wr_secuencia_tipo_editar === 'S') {
        const handleClickOpen = () => {
            var wr_id = record.id
            var wr_id_contratacion_encuadre = record.id_contratacion_encuadre
            var wr_expediente_referencia_gde = record.expediente_referencia_gde
            var wr_contratacion_tipo = record.contratacion_tipo
            var wr_secuencia_item = record.secuencia_item
            var wr_secuencia_tipo = record.secuencia_tipo
            var wr_secuencia_dias = record.secuencia_dias
            var wr_secuencia_d = record.secuencia_d
            var wr_secuencia_fecha_tope = record.secuencia_fecha_tope_edit
            var wr_secuencia_fecha_cumplido = record.secuencia_fecha_cumplido_edit
            var wr_secuencia_cumplido = record.secuencia_cumplido
            var wr_secuencia_cumplido_estado = record.secuencia_cumplido_estado
            var wr_secuencia_fecha_gde = record.secuencia_fecha_gde_edit
            var wr_secuencia_usuario_gde = record.secuencia_usuario_gde
            var wr_secuencia_observacion = record.secuencia_observacion

            setEstadoid(wr_id)
            setEstadoid_contratacion_encuadre(wr_id_contratacion_encuadre)
            setEstadoexpediente_referencia_gde(wr_expediente_referencia_gde)
            setEstadocontratacion_tipo(wr_contratacion_tipo)
            setEstadosecuencia_item(wr_secuencia_item)
            setEstadosecuencia_tipo(wr_secuencia_tipo)
            setEstadosecuencia_dias(wr_secuencia_dias)
            setEstadosecuencia_d(wr_secuencia_d)
            setEstadosecuencia_fecha_tope(wr_secuencia_fecha_tope)
            setEstadosecuencia_fecha_cumplido(wr_secuencia_fecha_cumplido)
            setEstadosecuencia_cumplido(wr_secuencia_cumplido)
            setEstadosecuencia_cumplido_estado(wr_secuencia_cumplido_estado)
            setEstadosecuencia_fecha_gde(wr_secuencia_fecha_gde)
            setEstadosecuencia_usuario_gde(wr_secuencia_usuario_gde)
            setEstadosecuencia_observacion(wr_secuencia_observacion)

            if (wr_secuencia_cumplido == null) {
                wr_secuencia_cumplido = ''
            }
            wr_secuencia_cumplido = wr_secuencia_cumplido.trim()
            if (wr_secuencia_cumplido === 'S') {
                setEstadoreadOnlysecuencia_fecha_cumplido(false)
            } else {
                setEstadoreadOnlysecuencia_fecha_cumplido(true)
            }

            setOpen(true);
        };


        const handlesecuencia_fecha_tope = (event) => {
            const Cambiasecuencia_fecha_tope1 = (wp_value) => {
                console.log('wp_value')
                console.log(wp_value)
                setEstadosecuencia_fecha_tope(wp_value)
            };

            var w_value = event.target.value
            console.log('w_value')
            console.log(w_value)
            // if(w_value==null){
            //     w_value=''
            // }
            // w_value=w_value.trim()

            Cambiasecuencia_fecha_tope1(w_value)

        };

        const handlesecuencia_fecha_cumplido = (event) => {
            const Cambiasecuencia_fecha_cumplido1 = (wp_value) => {
                console.log('wp_value')
                console.log(wp_value)
                setEstadosecuencia_fecha_cumplido(wp_value)
                setHelperTextsecuencia_fecha_cumplido('')
            };

            var w_value = event.target.value
            console.log('w_value')
            console.log(w_value)
            // if(w_value==null){
            //     w_value=''
            // }
            // w_value=w_value.trim()

            Cambiasecuencia_fecha_cumplido1(w_value)

        };

        const handlesecuencia_fecha_gde = (event) => {
            const Cambiasecuencia_fecha_gde1 = (wp_value) => {
                console.log('wp_value')
                console.log(wp_value)
                setEstadosecuencia_fecha_gde(wp_value)
            };

            var w_value = event.target.value
            console.log('w_value')
            console.log(w_value)
            // if(w_value==null){
            //     w_value=''
            // }
            // w_value=w_value.trim()

            Cambiasecuencia_fecha_gde1(w_value)

        };


        const handleClose = () => {
            setOpen(false);
        };


        const handleClickOpenEditarConfirmacionContratacionEncuadreSecuencia = () => {
            var w_validacion = ''
            var w_helpertextsecuencia_fecha_cumplido = ''
            setHelperTextsecuencia_fecha_cumplido(w_helpertextsecuencia_fecha_cumplido)
            var w_secuencia_cumplido = Estadosecuencia_cumplido
            if (w_secuencia_cumplido == null) {
                w_secuencia_cumplido = ''
            }
            w_secuencia_cumplido = w_secuencia_cumplido.trim()
            if (w_secuencia_cumplido === 'S') {
                var w_secuencia_fecha_cumplido = Estadosecuencia_fecha_cumplido
                console.log('w_secuencia_fecha_cumplido')
                console.log(w_secuencia_fecha_cumplido)
                if (w_secuencia_fecha_cumplido == null) {
                    w_secuencia_fecha_cumplido = ''
                }
                w_secuencia_fecha_cumplido = w_secuencia_fecha_cumplido.trim()
                console.log('w_secuencia_fecha_cumplido')
                console.log(w_secuencia_fecha_cumplido)
                if (w_secuencia_fecha_cumplido === '') {
                    w_validacion = 'E'
                    w_helpertextsecuencia_fecha_cumplido = 'Debe Ingresar la Fecha Cumplido'
                } else {
                    w_validacion = 'E'
                    dataProviderTableroControl.getList(
                        'ContratacionEncuadreSecuenciaValidacion',
                        {
                            filter: {
                                id_detelle: Estadoid,
                                id_contratacion_encuadre: Estadoid_contratacion_encuadre,
                                expediente_referencia_gde: Estadoexpediente_referencia_gde,
                                secuencia_item: Estadosecuencia_item,
                                secuencia_fecha_cumplido: w_secuencia_fecha_cumplido
                            },
                            sort: {field: 'id', order: 'DESC'},
                            pagination: {page: 1, perPage: 100},
                        },)
                        .then(({data}) => {
                            var w_validacion_final = data[0].validacion_final
                            if (w_validacion_final == null) {
                                w_validacion_final = ''
                            }
                            w_validacion_final = w_validacion_final.trim()
                            // console.log(w_validacion_final)
                            // alert(w_validacion_final)
                            if (w_validacion_final === 'E') {
                                w_validacion = 'E'
                                w_helpertextsecuencia_fecha_cumplido = 'Debe Ingresar la Fecha Cumplido Mayor o Igual al Item Anterior'
                                setHelperTextsecuencia_fecha_cumplido(w_helpertextsecuencia_fecha_cumplido)
                            } else {
                                setopenEditarConfirmacionContratacionEncuadreSecuencia(true);
                            }
                        })
                        .catch(error => {

                        })
                }
            }
            if (w_validacion === 'E') {
                setHelperTextsecuencia_fecha_cumplido(w_helpertextsecuencia_fecha_cumplido)
            } else {
                setopenEditarConfirmacionContratacionEncuadreSecuencia(true);
            }
        };

        const handleCloseEditarConfirmacionContratacionEncuadreSecuencia = () => {
            setopenEditarConfirmacionContratacionEncuadreSecuencia(false);
        };


        const BotonActualizarDetelle = (record) => {

            console.log('BotonActualizarDetelle')

            var w_mensaje = ' '

            const notify = useNotify();
            const refresh = useRefresh();

            const [generardetelleupdatecontratacionencuadresecuencia, {isLoading}] = useMutation({
                    type: 'update',
                    resource: 'ContratacionEncuadreSecuencia',
                    payload: {
                        id: Estadoid, data: {
                            id_contratacion_encuadre: Estadoid_contratacion_encuadre,
                            expediente_referencia_gde: Estadoexpediente_referencia_gde,
                            contratacion_tipo: Estadocontratacion_tipo,
                            secuencia_item: Estadosecuencia_item,
                            secuencia_tipo: Estadosecuencia_tipo,
                            secuencia_dias: Estadosecuencia_dias,
                            secuencia_d: Estadosecuencia_d,
                            secuencia_fecha_tope: Estadosecuencia_fecha_tope,
                            secuencia_fecha_cumplido: Estadosecuencia_fecha_cumplido,
                            secuencia_cumplido: Estadosecuencia_cumplido,
                            secuencia_cumplido_estado: Estadosecuencia_cumplido_estado,
                            secuencia_fecha_gde: Estadosecuencia_fecha_gde,
                            secuencia_usuario_gde: Estadosecuencia_usuario_gde,
                            secuencia_observacion: Estadosecuencia_observacion
                        }
                    }
                },
                {
                    onSuccess: ({data}) => {
                        setopenEditarConfirmacionContratacionEncuadreSecuencia(false);
                        refresh()
                        // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//------------------- cambia el estado del componente para vover a renderizar ----------------

                    },
                    onFailure: error => {
                        notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                    },
                }
            );


            return (
                <div>
                    <Button variant="outlined" color="primary"
                            onClick={handleClickOpenEditarConfirmacionContratacionEncuadreSecuencia}
                            style={{color: '#ffffff', backgroundColor: '#123B80'}}
                    >
                        <div title={'Actualizar la Contratacion Encuadre Secuencia)'}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <SaveIcon style={{color: '#ffffff', backgroundColor: '#123B80'}}/>
                            Actualizar
                        </div>
                    </Button>
                    <Dialog open={openEditarConfirmacionContratacionEncuadreSecuencia}
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">{'Actualizar la Contratacion Encuadre Secuencia'}
                        </DialogTitle>
                        <DialogContent>
                            ¿Quiere Actualizar la Secuencia?
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" color="primary"
                                    onClick={() => {
                                        generardetelleupdatecontratacionencuadresecuencia();
                                        setopenEditarConfirmacionContratacionEncuadreSecuencia(false);
                                    }
                                    }
                                    // disabled={loading}
                                    style={{color: '#ffffff', backgroundColor: '#123B80'}}
                            >
                                <div title={''}
                                     style={{
                                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                     }}>
                                    <SaveIcon style={{color: '#ffffff', backgroundColor: '#123B80'}}/>
                                    Actualizar
                                </div>
                            </Button>
                            <Button variant="outlined" color="primary"
                                    onClick={handleCloseEditarConfirmacionContratacionEncuadreSecuencia}>
                                <div title={''}
                                     style={{
                                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                     }}>
                                    <CancelPresentationIcon style={{color: '#123B80'}}/>
                                    Cancelar
                                </div>
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/*<Button variant="outlined"*/}
                    {/*        style={{color: '#ffffff', backgroundColor: '#123B80'}}*/}
                    {/*        onClick={generardetelleupdatecontratacionencuadresecuencia} disabled={loading}>*/}
                    {/*    <div title={''}*/}
                    {/*         style={{*/}
                    {/*             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'*/}
                    {/*         }}>*/}
                    {/*        <SaveIcon/>*/}
                    {/*        Actualizar*/}
                    {/*    </div>*/}
                    {/*</Button>*/}
                </div>
                // <Button label={w_mensaje}
                //         title={w_mensaje}
                //         onClick={generardetelle} disabled={loading}
                //         color="primary"
                // >
                //     <div>
                //         <LineWeightIcon style={{fontSize: 40, backgroundColor: '#ffffff', color: '#123B80'}}/>
                //     </div>
                // </Button>
            );

        };


        const handlesecuencia_observacion = (event) => {
            const Cambiasecuencia_observacion1 = (wp_value) => {
                console.log('wp_value')
                console.log(wp_value)
                setEstadosecuencia_observacion(wp_value)
            };

            var w_value = event.target.value
            console.log('w_value')
            console.log(w_value)
            // if(w_value==null){
            //     w_value=''
            // }
            // w_value=w_value.trim()

            Cambiasecuencia_observacion1(w_value)

        };

        const handlesecuencia_usuario_gde = (event) => {
            const Cambiasecuencia_usuario_gde1 = (wp_value) => {
                console.log('wp_value')
                console.log(wp_value)
                setEstadosecuencia_usuario_gde(wp_value)
            };

            var w_value = event.target.value
            console.log('w_value')
            console.log(w_value)
            // if(w_value==null){
            //     w_value=''
            // }
            // w_value=w_value.trim()

            Cambiasecuencia_usuario_gde1(w_value)

        };

        const handleChangesecuencia_cumplido = (event) => {
            const Cambiasecuencia_cumplido1 = (wp_value) => {
                console.log('wp_value')
                console.log(wp_value)
                setEstadosecuencia_cumplido(wp_value)
                if (wp_value == null) {
                    wp_value = ''
                }
                wp_value = wp_value.trim()
                setHelperTextsecuencia_fecha_cumplido('')
                if (wp_value === 'S') {
                    var datesecuencia_fecha_cumplido = new Date();
                    var duedatesecuencia_fecha_cumplido = new Date(datesecuencia_fecha_cumplido);
                    duedatesecuencia_fecha_cumplido.setDate(datesecuencia_fecha_cumplido.getDate());
                    var dsecuencia_fecha_cumplido = new Date(duedatesecuencia_fecha_cumplido || Date.now()),
                        monthsecuencia_fecha_cumplido = '' + (dsecuencia_fecha_cumplido.getMonth() + 1),
                        daysecuencia_fecha_cumplido = '' + (dsecuencia_fecha_cumplido.getDate()),
                        yearsecuencia_fecha_cumplido = dsecuencia_fecha_cumplido.getFullYear();

                    //console.log(month)

                    if (monthsecuencia_fecha_cumplido.length < 2) monthsecuencia_fecha_cumplido = '0' + monthsecuencia_fecha_cumplido;
                    if (daysecuencia_fecha_cumplido.length < 2) daysecuencia_fecha_cumplido = '0' + daysecuencia_fecha_cumplido;

                    // var w_f_carga1secuencia_fecha_cumplido = [daysecuencia_fecha_cumplido, monthsecuencia_fecha_cumplido, yearsecuencia_fecha_cumplido].join('/');
                    var w_f_carga2secuencia_fecha_cumplido = [yearsecuencia_fecha_cumplido, monthsecuencia_fecha_cumplido, daysecuencia_fecha_cumplido].join('-');
                    console.log(w_f_carga2secuencia_fecha_cumplido)
                    setEstadosecuencia_fecha_cumplido(w_f_carga2secuencia_fecha_cumplido)
                    setEstadoreadOnlysecuencia_fecha_cumplido(false)
                } else {
                    setEstadosecuencia_fecha_cumplido('')
                    setEstadoreadOnlysecuencia_fecha_cumplido(true)
                }
            };

            var w_value = event.target.value
            console.log('w_value')
            console.log(w_value)
            // if(w_value==null){
            //     w_value=''
            // }
            // w_value=w_value.trim()

            Cambiasecuencia_cumplido1(w_value)

        };


        return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    <div title={''}
                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                    >
                        <EditIcon style={{color: '#123B80', fontSize: 15}}/>
                    </div>
                </Button>
                <Dialog open={open} maxWidth={'xl'} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Detalle de Secuencia de Cumplimiento</DialogTitle>
                    <DialogContent>
                        <form>
                            <label>
                                <Table aria-label="custom pagination table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <TextField label="ID Contratacion Encuadre"
                                                           value={Estadoid_contratacion_encuadre}
                                                           inputProps={{readOnly: true}}
                                                           variant="filled"
                                                           style={{textAlign: 'right', margin: '1em'}}
                                                           options={{minimumFractionDigits: 0}}/>
                                            </TableCell>
                                            <TableCell>
                                                <NumberFormat
                                                    customInput={TextField} label="Item" value={Estadosecuencia_item}
                                                    style={{textAlign: 'right', margin: '1em'}}
                                                    inputProps={{readOnly: true}}
                                                    variant="filled"
                                                    options={{minimumFractionDigits: 0}}/>
                                            </TableCell>
                                            <TableCell>
                                                <TextField label="Contratacion Tipo" value={Estadocontratacion_tipo}
                                                           inputProps={{readOnly: true}}
                                                           SelectProps={{native: true,}}
                                                           variant="filled"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField label="Tipo"
                                                           variant="filled"
                                                           value={Estadosecuencia_tipo}
                                                           SelectProps={{native: true,}}
                                                           inputProps={{readOnly: true}}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                                <Table aria-label="custom pagination table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <TextField label="Expediente"
                                                           value={Estadoexpediente_referencia_gde}
                                                           multiline
                                                           fullWidth
                                                           variant="filled"
                                                           inputProps={{readOnly: true}}
                                                           SelectProps={{native: true,}}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                                <Table aria-label="custom pagination table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <TextField label="Descripción"
                                                           value={Estadosecuencia_d}
                                                           fullWidth
                                                           multiline
                                                           variant="filled"
                                                           inputProps={{readOnly: true}}
                                                           SelectProps={{native: true,}}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                                <Table aria-label="custom pagination table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <TextField
                                                    label="Observacion"
                                                    value={Estadosecuencia_observacion}
                                                    multiline
                                                    fullWidth
                                                    onChange={handlesecuencia_observacion}
                                                    SelectProps={{native: true,}}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                                <Table aria-label="custom pagination table">
                                    <TableHead>
                                        <TableRow>
                                            {/*<TableCell>*/}
                                            {/*   */}
                                            {/*</TableCell>*/}
                                            <TableCell>
                                                <NumberFormat
                                                    customInput={TextField} label="Dias" value={Estadosecuencia_dias}
                                                    style={{textAlign: 'right', margin: '1em'}}
                                                    inputProps={{readOnly: true}}
                                                    variant="filled"
                                                    options={{minimumFractionDigits: 0}}/>
                                                <br/>
                                            </TableCell>
                                            <TableCell>
                                                {/*<DateField label="Fec.Entrega" value={Estadosecuencia_fecha_tope} SelectProps={{*/}
                                                {/*    native: true,*/}
                                                {/*}}/>*/}
                                                <TextField
                                                    id="secuencia_fecha_tope_date"
                                                    label="Fecha Tope"
                                                    type="date"
                                                    value={Estadosecuencia_fecha_tope}
                                                    onChange={handlesecuencia_fecha_tope}
                                                    className={classes2.textField}
                                                    InputLabelProps={{shrink: true,}}
                                                    inputProps={{readOnly: true}}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {/*<DateField label="Fec.Entrega" value={Estadosecuencia_fecha_cumplido} SelectProps={{*/}
                                                {/*    native: true,*/}
                                                {/*}}/>*/}
                                                <TextField
                                                    id="secuencia_fecha_cumplido_date"
                                                    label="Fecha Cumplido"
                                                    type="date"
                                                    value={Estadosecuencia_fecha_cumplido}
                                                    onChange={handlesecuencia_fecha_cumplido}
                                                    className={classes2.textField}
                                                    InputLabelProps={{shrink: true,}}
                                                    inputProps={{readOnly: EstadoreadOnlysecuencia_fecha_cumplido}}
                                                />
                                                <FormHelperText style={{color: '#ff0000', backgroundColor: '#ffffff'}}>
                                                    {helperTextsecuencia_fecha_cumplido}
                                                </FormHelperText>
                                            </TableCell>
                                            <TableCell>
                                                {/*<TextField label="Cumplido"*/}
                                                {/*           variant="filled"*/}
                                                {/*           value={Estadosecuencia_cumplido}*/}
                                                {/*           SelectProps={{native: true,}}*/}
                                                {/*           inputProps={{readOnly: true}}*/}
                                                {/*/>*/}
                                                <FormControl className={classes.formControl}>
                                                    <InputLabel shrink id="secuencia_cumplido-label-label">
                                                        Cumplido
                                                    </InputLabel>
                                                    <Select
                                                        label="Cumplido"
                                                        labelId="secuencia_cumplido-label"
                                                        id="secuencia_cumplido-select"
                                                        value={Estadosecuencia_cumplido}
                                                        onChange={handleChangesecuencia_cumplido}
                                                    >
                                                        <MenuItem value={'S'}>S</MenuItem>
                                                        <MenuItem value={'N'}>N</MenuItem>
                                                    </Select>

                                                </FormControl>
                                            </TableCell>
                                            <TableCell>
                                                <TextField label="Cumplido Estado"
                                                           variant="filled"
                                                           value={Estadosecuencia_cumplido_estado}
                                                           SelectProps={{native: true,}}
                                                           inputProps={{readOnly: true}}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                                {/*<Table aria-label="custom pagination table">*/}
                                {/*    <TableHead>*/}
                                {/*        <TableRow>*/}
                                {/*            <TableCell>*/}

                                {/*            </TableCell>*/}

                                {/*        </TableRow>*/}
                                {/*    </TableHead>*/}
                                {/*</Table>*/}
                                <Table aria-label="custom pagination table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>

                                            </TableCell>
                                            <TableCell>
                                                {/*<DateField label="Fec.Entrega" value={Estadosecuencia_fecha_gde} SelectProps={{*/}
                                                {/*    native: true,*/}
                                                {/*}}/>*/}
                                                <TextField
                                                    id="secuencia_fecha_gde_date"
                                                    label="Fecha GDE"
                                                    type="date"
                                                    value={Estadosecuencia_fecha_gde}
                                                    onChange={handlesecuencia_fecha_gde}
                                                    className={classes2.textField}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField label="Usuario GDE"
                                                           value={Estadosecuencia_usuario_gde}
                                                           onChange={handlesecuencia_usuario_gde}
                                                           SelectProps={{
                                                               native: true,
                                                           }}
                                                />
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                                {/*<Table aria-label="custom pagination table">*/}
                                {/*    <TableHead>*/}
                                {/*        <TableRow>*/}
                                {/*            <TableCell>*/}

                                {/*            </TableCell>*/}
                                {/*            <TableCell>*/}

                                {/*            </TableCell>*/}
                                {/*            <TableCell>*/}
                                {/*                {''}*/}
                                {/*            </TableCell>*/}
                                {/*        </TableRow>*/}
                                {/*    </TableHead>*/}
                                {/*</Table>*/}
                                {/*<Table aria-label="custom pagination table">*/}
                                {/*    <TableHead>*/}
                                {/*        <TableRow>*/}
                                {/*            <TableCell>*/}

                                {/*            </TableCell>*/}
                                {/*            <TableCell>*/}

                                {/*            </TableCell>*/}
                                {/*            <TableCell>*/}

                                {/*            </TableCell>*/}
                                {/*        </TableRow>*/}
                                {/*    </TableHead>*/}
                                {/*</Table>*/}
                                {/*<Table aria-label="custom pagination table">*/}
                                {/*    <TableHead>*/}
                                {/*        <TableRow>*/}
                                {/*            <TableCell>*/}
                                {/*                {''}*/}
                                {/*            </TableCell>*/}
                                {/*            <TableCell>*/}

                                {/*            </TableCell>*/}
                                {/*            <TableCell>*/}

                                {/*            </TableCell>*/}
                                {/*        </TableRow>*/}
                                {/*    </TableHead>*/}
                                {/*</Table>*/}
                            </label>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <BotonActualizarDetelle/>
                        <Button variant="outlined" color="primary" onClick={handleClose}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                 }}>
                                <CancelPresentationIcon style={{color: '#123B80'}}/>
                                Cancelar
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    } else {
        return (
            <div>
            </div>
        )
    }

}

ContratacionEncuadreSecuenciaButtonEdit.defaultProps = {
    source: '',
    addLabel: true,
};

export default ContratacionEncuadreSecuenciaButtonEdit;