import React, {useCallback, useState, useEffect} from 'react';
import {
    useQuery,
    Datagrid,
    Pagination, useDataProvider,
    Loading, ListContextProvider, useMutation, TextInput, DateInput
} from 'react-admin';
import TextField from '@material-ui/core/TextField';
import keyBy from 'lodash/keyBy';
import IconBuscar from '@material-ui/icons/Search';
import IconSeleccionar from '@material-ui/icons/Done';
import {makeStyles} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {TextField as TextFieldMUI} from '@material-ui/core';
import IconCancelar from '@material-ui/icons/Close';
import SaveIcon from "@material-ui/core/SvgIcon/SvgIcon";
import RequerimientoConceptoBusquedaBtn from "../RequerimientoConcepto/RequerimientoConceptoBusquedaBtn";
import UniMedBusquedaBtn from "../UniMed/UniMedBusquedaBtn";
import NumberFormat from "react-number-format";
import IvaTasaTipoBusquedaBtn from "../IvaTasaTipo/IvaTasaTipoBusquedaBtn";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import PropTypes from "prop-types";
import ApiUrlServer from "../layout/ApiUrlServer";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },


    requerimiento_concepto: {display: 'inline-block', width: 250, marginLeft: 15},
    clase: {display: 'inline-block', width: 250, marginLeft: 32},
    renglon: {display: 'inline-block', width: 80, marginLeft: 32},
    renglon_d: {display: 'inline-block', width: 500, marginLeft: 32},
    fec_entrega: {display: 'inline-block', width: 150, marginLeft: 32},
    uni_med: {display: 'inline-block', width: 120, marginLeft: 15},
    cantidad: {display: 'inline-block', width: 100, marginLeft: 32},

    moneda: {display: 'inline-block', width: 150, marginLeft: 15},
    cotizacion: {display: 'inline-block', width: 150, marginLeft: 32},
    fecha_cotizacion: {display: 'inline-block', width: 150, marginLeft: 32},
    importe: {display: 'inline-block', width: 150, marginLeft: 32},
    importeIva: {display: 'inline-block', width: 150, marginLeft: 60},
    importe2: {display: 'inline-block', width: 150, marginLeft: 15},
    importe3: {display: 'inline-block', width: 150, marginLeft: 25},

    iva: {display: 'inline-block', width: 110, marginLeft: 15},
    btnBusqueda: {
        marginRight: '2rem',
        // width: 40,
        display: 'inline-block',
    },
});

export const OCDetalleAlta = (props) => {

    const dataProviderTableroControl = useDataProvider();

    console.log("Alta", props.Data)

    const [Estadoid, setEstadoid] = useState(null);
    const [Estadoid_orden_compra_cabecera, setEstadoid_orden_compra_cabecera] = useState(props.Data ? props.Data.id : null);
    const [Estadoorden_compra_nro, setEstadoorden_compra_nro] = useState(props.Data ? props.Data.orden_compra_nro : null);
    const [Estadoorden_compra_ampliacion_nro, setEstadoorden_compra_ampliacion_nro] = useState(props.Data ? props.Data.orden_compra_ampliacion_nro : null);
    const [Estadoitem, setEstadoitem] = useState('ASIGNAR');
    const [Estadoconcepto_requerimiento, setEstadoconcepto_requerimiento] = useState('');
    const [Estadoclase, setEstadoclase] = useState('');
    const [Estadorenglon_nro, setEstadorenglon_nro] = useState('');
    const [Estadorenglon_descripcion, setEstadorenglon_descripcion] = useState('');
    const [Estadounidad_medida, setEstadounidad_medida] = useState('');
    const [Estadounidad_medida_abre, setEstadounidad_medida_abre] = useState('');
    const [Estadocantidad, setEstadocantidad] = useState('');
    const [Estadofecha_entrega, setEstadofecha_entrega] = useState(null);
    const [Estadomoneda, setEstadomoneda] = useState(props.Data ? props.Data.moneda : null);
    const [Estadomoneda_abre, setEstadomoneda_abre] = useState('');
    const [EstadoIdMoneda, setEstadoIdMoneda] = useState(props.Data ? props.Data.id_moneda : null);
    const [Estadocotizacion, setEstadocotizacion] = useState(props.Data ? props.Data.moneda_cotizacion : null);
    const [EstadocotizacionFecha, setEstadocotizacionFecha] = useState(props.Data ? props.Data.moneda_cotizacion_fecha : null);
    const [Estadoprecio_unitario_sin_iva, setEstadoprecio_unitario_sin_iva] = useState(0);
    const [Estadodescuento_porcentaje, setEstadodescuento_porcentaje] = useState(0);
    const [Estadoprecio_unitario_sin_iva_con_dto, setEstadoprecio_unitario_sin_iva_con_dto] = useState(0);
    const [Estadoiva_porcentaje, setEstadoiva_porcentaje] = useState(0);
    const [Estadoiva_importe, setEstadoiva_importe] = useState(0);
    const [Estadototal_item, setEstadototal_item] = useState(0);
    const [Estadototal_importe_iva_pesos, setEstadototal_importe_iva_pesos] = useState(0);
    const [Estadototal_item_pesos, setEstadototal_item_pesos] = useState(0);


    const [open, setOpen] = useState(false);
    const classes = useStyles();

    useEffect(() => {

        dataProviderTableroControl.getList(
            'MonedaActivo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'S', id: props.Data.id_moneda},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setEstadomoneda(data[0].moneda);
                setEstadomoneda_abre(data[0].moneda_abre);

            })
            .catch(error => {
                setOpen(false);
            })

    }, [props.Data]);


    function NumberFormatCustom(props) {
        const {inputRef, onChange, ...other} = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={'.'}
                decimalSeparator={','}
                isNumericString
                prefix=""
            />
        );
    }

    NumberFormatCustom.propTypes = {
        inputRef: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,

    };

    const handlefecha_entrega = (event) => {
        const Cambiafecha_entrega1 = (wp_value) => {
            console.log(wp_value)
            setEstadofecha_entrega(wp_value)
        };

        var w_value = event.target.value
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiafecha_entrega1(w_value)

    };

    const handlecantidad = (event) => {
        const Cambiacantidad1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadocantidad(wp_value)

            var wh_cantidad = wp_value
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva
            var wh_descuento_porcentaje = Estadodescuento_porcentaje
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva


            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
            }
            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)


            // (a) Cantidad:
            // (b) Precio unitario sin iva:
            // (c) Descuento %:
            // (d) Precio unitario con descuento:   =  round(  b*(1-(c/100)),2)  RO
            // (e) IVA %:
            // (f) IVA Importe:     RO   =  round((a * d) * (e/100),2)

            var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);
            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100;

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiacantidad1(w_value)

    };

    const handleprecio_unitario_sin_iva = (event) => {
        const Cambiaprecio_unitario_sin_iva1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoprecio_unitario_sin_iva(wp_value)

            var wh_cantidad = Estadocantidad
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = wp_value
            var wh_descuento_porcentaje = Estadodescuento_porcentaje
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
            }
            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            // var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)
            var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiaprecio_unitario_sin_iva1(w_value)

    };

    const handleiva_porcentaje = (w_porcentaje) => {
        var wh_cantidad = Estadocantidad;
        var wh_cotizacion = Estadocotizacion;
        var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva;
        var wh_descuento_porcentaje = Estadodescuento_porcentaje;
        var wh_iva_porcentaje = w_porcentaje;

        var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

        if (wh_descuento_porcentaje === 0) {
        } else {
            wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
        }
        wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

        setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

        // var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)
        var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);

        wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

        setEstadoiva_importe(wr_iva_importe)

        var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

        wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

        setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

        var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

        wr_total_item = Math.round(wr_total_item * 100) / 100

        setEstadototal_item(wr_total_item)

        var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

        wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

        setEstadototal_item_pesos(wr_total_item_pesos)
    };

    const handleunidad_medida = useCallback(async (who) => {
        setEstadounidad_medida(who.unidad_medida);
        setEstadounidad_medida_abre(who.unidad_medida_abre);
    });

    const handleconcepto_requerimiento = useCallback(async (who) => {
        setEstadoconcepto_requerimiento(who.requerimiento_concepto);
        setEstadoclase(who.clase);
    });

    const handledescuento_porcentaje = (event) => {
        const Cambiadescuento_porcentaje1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadodescuento_porcentaje(wp_value)

            var wh_cantidad = Estadocantidad
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva
            var wh_descuento_porcentaje = wp_value
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
            }
            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);
            // var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiadescuento_porcentaje1(w_value)

    };

    const handleIvaTasaTipo = useCallback(async (who) => {
        setEstadoiva_porcentaje(who.porcentaje);
        handleiva_porcentaje(who.porcentaje);
        // handleiva_porcentaje(who.porcentaje);
    });

    const formatIvaPorcentaje = v => {
        return Estadoiva_porcentaje;
    };

    const formatEstadoconcepto_requerimiento = v => {
        return Estadoconcepto_requerimiento
    };

    const formatEstadoclase = v => {
        return Estadoclase
    };

    const handlerenglon_nro = (event) => {
        const Cambiarenglon_nro1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadorenglon_nro(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiarenglon_nro1(w_value)

    };

    const handlerenglon_descripcion = (event) => {
        const Cambiarenglon_descripcion1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadorenglon_descripcion(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiarenglon_descripcion1(w_value)

    };

    const formatEstadounidad_medida_abre = v => {
        return Estadounidad_medida_abre;
    };

    const BotonCrearDetelle = (Data) => {

        var w_mensaje = ' '

        // const notify = useNotify();
        // const refresh = useRefresh();
        var date = new Date();

        let data;
        const [generardetelle, {loading}] = useMutation({
                type: 'create',
                resource: 'OrdenCompraDetalle',
                payload: {
                    id: 0, data: {
                        id_orden_compra_cabecera: props.Data.id,
                        orden_compra_nro: props.Data.orden_compra_nro,
                        orden_compra_ampliacion_nro: props.Data.orden_compra_ampliacion_nro,
                        item: Estadoitem,
                        concepto_requerimiento: Estadoconcepto_requerimiento,
                        clase: Estadoclase,
                        renglon_nro: Estadorenglon_nro,
                        renglon_descripcion: Estadorenglon_descripcion,
                        unidad_medida: Estadounidad_medida,
                        unidad_medida_abre: Estadounidad_medida_abre,
                        cantidad: Estadocantidad,
                        id_moneda: props.Data.id_moneda,
                        moneda: Estadomoneda,
                        cotizacion: props.Data.moneda_cotizacion,
                        cotizacion_fecha: props.Data.moneda_cotizacion_fecha,
                        precio_unitario_sin_iva: Estadoprecio_unitario_sin_iva,
                        descuento_porcentaje: Estadodescuento_porcentaje,
                        precio_unitario_sin_iva_con_dto: Estadoprecio_unitario_sin_iva_con_dto,
                        iva_porcentaje: Estadoiva_porcentaje,
                        iva_importe: Estadoiva_importe,
                        total_item: Estadototal_item,
                        total_importe_iva_pesos: Estadototal_importe_iva_pesos,
                        total_item_pesos: Estadototal_item_pesos,
                        fecha_entrega: Estadofecha_entrega,
                        // fecha_necesidad: Estadofecha_necesidad
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    props.callbackHayCambios(Estadototal_item);
                    props.callbackCloseDrawer(false);
                },
                onFailure: error => {

                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle} disabled={loading}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    Guardar
                </div>
            </Button>
        );
    };

    const BtnVolver = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" onClick={() => {
            props.callbackCloseDrawer(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const validateUserCreation = async (values) => {
        const errors = {};

        if (!Estadorenglon_nro) {
            errors.renglon = 'Debe ingresar un valor';
        }

        if (!Estadorenglon_descripcion) {
            errors.d_renglon = 'Debe ingresar un valor';
        }

        if (!Estadocantidad) {
            errors.cantidad = 'Debe ingresar un valor';
        }

        if (!Estadounidad_medida) {
            errors.uni_med = 'Debe ingresar un valor';
        }

        if (!Estadoprecio_unitario_sin_iva) {
            errors.precio_sin_iva = 'Debe ingresar un valor';
        }

        if (!Estadoclase) {
            errors.clase = 'Debe ingresar un valor';
        }

        if (!Estadoconcepto_requerimiento) {
            errors.requerimiento_concepto = 'Debe ingresar un valor';
        }


        return errors
    };

    return (
        <div style={{marginTop: '4em', marginLeft: '2em'}}>
            <form
                // validate={validateUserCreation}
            >

                <div style={{display: 'inline-flex', width: '100%'}}>

                    <TextInput source="requerimiento_concepto"
                               label={"Concepto Requerimiento"}
                               fullWidth={true}
                               className={classes.requerimiento_concepto}
                               variant="filled" inputProps={{readOnly: true,}}
                               InputLabelProps={{shrink: true}}
                               value={Estadoconcepto_requerimiento}
                               format={formatEstadoconcepto_requerimiento}
                    />

                    <TextInput source="clase" label={"Clase"}
                               fullWidth={true}
                               className={classes.clase}
                               variant="filled" inputProps={{readOnly: true,}}
                               InputLabelProps={{shrink: true}}
                               value={Estadoclase}
                               format={formatEstadoclase}
                    />

                    <RequerimientoConceptoBusquedaBtn fullWidth={true}
                                                      callbackRequerimientoConcepto={handleconcepto_requerimiento}
                                                      className={classes.btnBusqueda}/>

                </div>

                <TextField label="Renglon" value={Estadorenglon_nro}
                           onChange={handlerenglon_nro} style={{textAlign: 'right', margin: '1em'}}
                           SelectProps={{native: true,}}
                           className={classes.renglon}
                           InputLabelProps={{shrink: true}}
                           source={"renglon"}
                />

                <TextField
                    label="Renglon Descripción"
                    value={Estadorenglon_descripcion}
                    onChange={handlerenglon_descripcion}
                    multiline style={{textAlign: 'right', margin: '1em'}}
                    fullWidth
                    SelectProps={{native: true,}}
                    className={classes.renglon_d}
                    InputLabelProps={{shrink: true}}
                    source={"d_renglon"}
                />

                <div style={{display: 'inline-flex', width: '100%'}}>

                    <TextInput label="Uni.Med."
                               source={"uni_med"}
                               value={Estadounidad_medida_abre}
                               format={formatEstadounidad_medida_abre}
                               className={classes.uni_med}
                               inputProps={{readOnly: true,}}
                               SelectProps={{
                                   native: true,
                               }}
                               InputLabelProps={{shrink: true}}
                    />

                    <UniMedBusquedaBtn
                        callbackUniMed={handleunidad_medida}
                        className={classes.btnBusqueda}/>

                    <NumberFormat
                        label="Cantidad" source="Cantidad"
                        customInput={TextField} value={Estadocantidad}
                        onChange={handlecantidad} className={classes.cantidad}
                        // style={{textAlign: 'right', margin: '1em'}}
                        options={{minimumFractionDigits: 2}}
                        // required
                        InputLabelProps={{shrink: true}}
                    />
                    <DateInput
                        label="Fecha de Entrega"
                        source={"fecha_entrega"}
                        className={classes.fec_entrega} fullWidth
                        defaultValue={Estadofecha_entrega}
                        value={Estadofecha_entrega}
                        onChange={handlefecha_entrega}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        // required
                    />


                </div>


                <TextField
                    label="Moneda"
                    value={Estadomoneda_abre}
                    inputProps={{readOnly: true}}
                    variant="filled"
                    className={classes.moneda}
                />

                <TextField
                    label="Cotización"
                    value={Estadocotizacion}
                    inputProps={{readOnly: true}}
                    variant="filled"
                    className={classes.cotizacion}
                />

                <DateInput
                    label="Fecha Cotización"
                    source={"moneda_cotizacion_fecha"}
                    className={classes.fecha_cotizacion} fullWidth
                    // value={EstadocotizacionFecha}
                    inputProps={{readOnly: true}}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="filled"

                />

                <br/>
                <br/>
                <NumberFormat
                    customInput={TextField} label="Precio Unit. Sin IVA"
                    value={Estadoprecio_unitario_sin_iva}
                    onChange={handleprecio_unitario_sin_iva}
                    style={{textAlign: 'right'}}
                    decimalScale={2}
                    options={{minimumFractionDigits: 2}}
                    className={classes.importe2}
                    InputLabelProps={{shrink: true}}
                    source={"precio_sin_iva"}

                />

                <TextField
                    label="Porc. Descuento"
                    value={Estadodescuento_porcentaje}
                    onChange={handledescuento_porcentaje}
                    style={{textAlign: 'right'}}
                    className={classes.importe}
                    options={{minimumFractionDigits: 2}}
                    InputProps={{
                        inputComponent: NumberFormatCustom
                    }}
                    InputLabelProps={{shrink: true}}
                />

                <TextField
                    label="Precio Unit. con Dto"
                    value={Estadoprecio_unitario_sin_iva_con_dto}
                    variant="filled"
                    // style={{textAlign: 'right'}}
                    InputProps={{
                        inputComponent: NumberFormatCustom
                    }}
                    className={classes.importe3}
                    InputLabelProps={{shrink: true}}
                />

                <br/>

                <div style={{display: 'inline-flex', width: '100%'}}>

                    <TextInput
                        label="IVA %"
                        value={Estadoiva_porcentaje}
                        format={formatIvaPorcentaje}
                        inputProps={{readOnly: true}}
                        variant="filled"
                        className={classes.iva}
                        onChange={handleiva_porcentaje}
                        InputLabelProps={{shrink: true}}
                        style={{marginTop: '15px'}}
                    />

                    <IvaTasaTipoBusquedaBtn
                        callbackIvaTasaTipo={handleIvaTasaTipo}
                        className={classes.btnBusqueda}/>

                    <TextField
                        label="IVA Importe"
                        value={Estadoiva_importe}
                        className={classes.importeIva}
                        variant="filled"
                        style={{textAlign: 'right', margin: '1em'}}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                        InputLabelProps={{shrink: true}}
                    />

                    <TextField
                        label="Total Ítem"
                        value={Estadototal_item}
                        name="numberformat"
                        id="importe_presupuesto_pesos_ing"
                        variant="filled"
                        className={classes.importe}
                        style={{textAlign: 'right', margin: '1em'}}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                        InputLabelProps={{shrink: true}}
                    />

                </div>

                <TextField
                    label="Total IVA $"
                    value={Estadototal_importe_iva_pesos}
                    name="numberformat"
                    id="importe_presupuesto_pesos_ing"
                    variant="filled"
                    className={classes.importe}
                    style={{textAlign: 'right', margin: '1em'}}
                    InputProps={{
                        inputComponent: NumberFormatCustom
                    }}
                    InputLabelProps={{shrink: true}}
                />

                <TextField
                    label="Total Ítem $"
                    value={Estadototal_item_pesos}
                    name="numberformat"
                    id="importe_presupuesto_pesos_ing"
                    variant="filled"
                    className={classes.importe}
                    style={{textAlign: 'right', margin: '1em'}}
                    InputProps={{
                        inputComponent: NumberFormatCustom
                    }}
                    InputLabelProps={{shrink: true}}
                />
                <br/><br/>
            </form>

            {
                Estadorenglon_nro &&
                Estadorenglon_descripcion &&
                Estadocantidad &&
                Estadounidad_medida &&
                Estadoprecio_unitario_sin_iva ?
                    <>
                        <BotonCrearDetelle/>
                    </>
                    :
                    <>
                        <Button disabled
                                variant="contained"
                                className={classes.button}>
                            Guardar
                        </Button>
                    </>
            }
            <br/> <br/>
            <BtnVolver/>

        </div>
    )
};
export default OCDetalleAlta;