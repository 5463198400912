import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    SelectInput,
    ReferenceInput,
    NumberInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';


const useStyles = makeStyles((theme) => ({
    documento_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    documento_tipo_d: {display: 'inline-block', width: 400, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_documento_tipo = values.documento_tipo

    if (w_documento_tipo===undefined) {
        errors.documento_tipo = ['El campo Código es requerido'];
    }else {
        if (w_documento_tipo === null) {
            errors.documento_tipo = ['El campo Código es requerido'];
        } else {
            w_documento_tipo = w_documento_tipo.trim()
            if (w_documento_tipo === '') {
                errors.documento_tipo = ['El campo Código es requerido'];
            }
        }
    }


    var w_documento_tipo_d = values.documento_tipo_d

    if (w_documento_tipo_d===undefined) {
        errors.documento_tipo_d = ['El campo Descripcion es requerido'];
    }else {
        if (w_documento_tipo_d === null) {
            errors.documento_tipo_d = ['El campo Descripcion es requerido'];
        } else {
            w_documento_tipo_d = w_documento_tipo_d.trim()
            if (w_documento_tipo_d === '') {
                errors.documento_tipo_d = ['El campo Descripcion es requerido'];
            }
        }
    }





    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/DocumentacionTipo`;

const AutoridadesCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Autoridades</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS Autoridades">
                    <TextInput label="Autoridad Código" source="autoridad_codigo"
                               variant="outlined"
                               formClassName={classes.autoridad_codigo}/>
                    <TextInput label="Nombre Apellido" source="nombre_apellido"
                               variant="outlined"
                               formClassName={classes.nombre_apellido}/>
                    <TextInput label="Adjudica Sector" source="adjudica_sector"
                               variant="outlined"
                               formClassName={classes.adjudica_sector}/>
                    <TextInput label="Firma Tipo" source="firma_tipo"
                               variant="outlined"
                               formClassName={classes.firma_tipo}/>
                    <NumberInput label="Importe Hasta Pesos" source="importe_hasta_pesos"
                                 variant="outlined"
                                 formClassName={classes.importe_hasta_pesos}  />

                    <br/>
                    <SelectInput
                        label="Pre Adjudica" source="pre_adjudica"
                        choices={[
                            {id: 'S', name: 'S'},
                            {id: 'N', name: 'N'},
                        ]} formClassName={classes.pre_adjudica}
                        variant="outlined"
                    />

                    <SelectInput
                        label="Conforma Adjudica" source="conforma_adjudica"
                        choices={[
                            {id: 'S', name: 'S'},
                            {id: 'N', name: 'N'},
                        ]} formClassName={classes.conforma_adjudica}
                        variant="outlined"
                    />

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default AutoridadesCreate;


