import React from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar, maxLength, required
} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    descripcion: {display: 'inline-block', width: 400, marginLeft: 8},
    codigo: {display: 'inline-block', width: 200, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >
        </Toolbar>
    )
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/IbruTipo`;

const IbruTipoCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Tipo IBRU</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                redirect={redirect}
                // toolbar={<PostCreateToolbar />} 
            >
                {/*Datos*/}
                <FormTab label="Tipo IBRU">
                    <TextInput label="Tipo IBRU" source="ibru_tipo"
                               validate={[maxLength(10), required()]}/>
                    <TextInput label="Descripción" source="ibru_tipo_d" validate={[maxLength(60)]}/>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default IbruTipoCreate;


