import React, {useState} from 'react';
import AutoridadesBusquedaDatagrid from "./AutoridadesBusquedaDatagrid"
import {Drawer} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/icons/Search';
import VerOCIcon from '@mui/icons-material/VisibilityOutlined';
import {makeStyles} from '@material-ui/core/styles';

export const AutoridadesBusquedaBtn = (props) => {
    const useStyles = makeStyles({
        paper: {
            width: '50%'
        },
        button: {
            justifyContent: 'left',
            display: 'inline-flex',
            fontWeight: 'bold',
            margin: 0,
            marginBottom: 0
        }
    });

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton color="primary" onClick={() => setOpen(!open)}>
                {props.oc > 0 ?
                    <VerOCIcon/>
                    :
                    <Icon/>
                }

            </IconButton>

            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <AutoridadesBusquedaDatagrid
                    callbackAutoridades={props.callbackAutoridades}
                    callbackCloseDrawer={handleDrawerOpen}
                    preAdjudica={props.preAdjudica}
                    conformaAdjudica={props.conformaAdjudica}
                />

            </Drawer>
        </div>
    )
};
export default AutoridadesBusquedaBtn;