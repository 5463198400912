import React, {useState} from "react";

import {
    FormTab,
    Show,
    TextInput,
    TabbedForm,
    DateInput,
    TopToolbar,
    SelectInput,
    useGetOne,
    DateTimeInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import ProveedorClasesGrid from "./ProveedorClasesGrid";
import ProveedorPersonaFisica from "./ProveedorPersonaFisica";
import ProveedorPersonaJuridica from "./ProveedorPersonaJuridica";
import ProveedorPersonaExtranjera from "./ProveedorPersonaExtranjera";
import ProveedorSocioList from "../proveedorSocio/ProveedorSocioList";
import ProveedorRepresentanteList from "../proveedorRepresentante/ProveedorRepresentanteList";
import ProveedorApoderadoList from "../proveedorApoderado/ProveedorApoderadoList";
import ProveedorDocumentacionShowList from "../proveedorDocumentacion/ProveedorDocumentacionShowList";

import DocumentoTipoCombo from "../componentes/DocumentoTipoCombo";
import IvaCombo from "../componentes/IvaCombo";
import ProvinciaLegalBusquedaBtn from "../Provincia/ProvinciaBusquedaBtn";
import CiudadLegalBusquedaBtn from "../Ciudad/CiudadBusquedaBtn";
// import EstadoProveedorCombo from "../componentes/EstadoProveedorCombo";

const useStyles = makeStyles((theme) => ({

    proveedor: {display: 'inline-block', width: 120},
    razon_social: {display: 'inline-block', width: 400},
    razon_social_fantasia: {display: 'inline-block', width: 400, marginLeft: 32},

    persona_tipo: {display: 'inline-block', width: 50},
    persona_tipo_d: {display: 'inline-block', width: 250},
    persona_forma_d: {display: 'inline-block', width: 250, marginLeft: 32},

    id_documento_tipo: {display: 'inline-block', width: 80},
    documento_tipo: {display: 'inline-block', width: 200, marginLeft: 32},
    documento_nro: {display: 'inline-block', width: 138, marginLeft: 32},
    cuit_cuil: {display: 'inline-block', width: 200, marginLeft: 32},

    email: {display: 'inline-block', width: 250},
    emailAlt: {display: 'inline-block', width: 250, marginLeft: 32},

    area: {display: 'inline-block', width: 50},
    nro: {display: 'inline-block', marginLeft: 32, width: 168,},
    area2: {display: 'inline-block', width: 50, marginLeft: 32},

    pais: {display: 'inline-block', width: 250},
    provincia: {display: 'inline-block', width: 350},
    partido: {display: 'inline-block', width: 250, marginLeft: 32},
    ciudad: {display: 'inline-block', width: 350, marginLeft: 32},

    calle: {display: 'inline-block', width: 410},
    calle_nro: {display: 'inline-block', width: 90, marginLeft: 32},

    piso: {display: 'inline-block', width: 50, marginLeft: 32},
    departamento: {display: 'inline-block', width: 50, marginLeft: 20},
    codigo_postal: {display: 'inline-block', width: 110, marginLeft: 20},

    d_iva: {display: 'inline-block', width: 300},
    ibru_exento: {display: 'inline-block', width: 80},
    ibru_tipo: {display: 'inline-block', width: 80, marginLeft: 32},

    ibru_exento_combo: {display: 'inline-block', width: 80},
    ibru_tipo_combo: {display: 'inline-block', width: 160, marginLeft: 112},

    ibru_tipo_d: {display: 'inline-block', width: 240, marginLeft: 32},
    ibru_nro_label: {display: 'inline-block', width: 120},
    ibru_nro: {display: 'inline-block', width: 160},
    ibru_jurisdiccion_provincia: {display: 'inline-block', width: 350, marginLeft: 32},

    es_pyme: {display: 'inline-block', width: 80},
    tiene_personas_con_discapacidad: {display: 'inline-block', width: 190, marginLeft: 112},
    tiene_participacion_estatal: {display: 'inline-block', width: 160, marginLeft: 32},
    tiene_participacion_entidades_publicas_no_estatales: {display: 'inline-block', width: 160, marginLeft: 32},
    registro_proveedor_estado_nro: {display: 'inline-block', width: 160, marginLeft: 32},

    // Uso interno

    codigo_pro_tvpublica: {display: 'inline-block', width: 160},
    codigo_pro_rna: {display: 'inline-block', width: 160, marginLeft: 32},
    fecha_alta: {display: 'inline-block', width: 200, marginLeft: 32},
    fecha_baja: {display: 'inline-block', width: 200, marginLeft: 32},

    estado_proveedor: {display: 'inline-block', width: 160},
    estado_proveedor_abre: {display: 'inline-block', width: 160},
    estado_proveedor_usuario: {display: 'inline-block', width: 200, marginLeft: 32},
    estado_proveedor_fecha_hora: {display: 'inline-block', width: 200, marginLeft: 32},

    observacion: {display: 'inline-block', width: 700},
    agenda: {display: 'inline-block', width: 700},

    socio_nro_ingreso: {display: 'inline-block', width: 200},
    nombre: {display: 'inline-block', width: 250},
    apellido: {display: 'inline-block', width: 250, marginLeft: 32},
    estado_civil: {display: 'inline-block', width: 150, marginLeft: 32},
}));

const ProveedorShow = props => {
    const classes = useStyles();
    const [state, setState] = useState({});
    const [Proveedor, setProveedor] = useState('');

    /// Búsqueda país
    var w_pais_legal = '', w_pais_comercial = '', w_persona_tipo = '';
    var w_d_pais_legal = '', w_d_pais_comercial = '';


    const {data: Data, loading, error} = useGetOne('proveedor', props.id);

    if (Data === undefined) {
    } else {

        w_persona_tipo = Data.persona_tipo ? Data.persona_tipo.trim() : '';

        if (Data.PaisComercial === undefined || Data.PaisComercial === null) {
        } else {
            w_pais_comercial = Data.PaisComercial.pais_codigo_afip === undefined ? 0 : Data.PaisComercial.pais_codigo_afip;
            w_d_pais_comercial = Data.PaisComercial.pais === undefined ? '' : Data.PaisComercial.pais.trim();
        }

        if (Data.PaisLegal === undefined || Data.PaisLegal === null) {

        } else {
            w_pais_legal = Data.PaisLegal.pais_codigo_afip === undefined ? 0 : Data.PaisLegal.pais_codigo_afip;
            w_d_pais_legal = Data.PaisLegal.pais === undefined ? '' : Data.PaisLegal.pais.trim();
        }
    }
    const [PersonaTipo, setPersonaTipo] = useState(w_persona_tipo);
    const {
        dPaisComercial = w_d_pais_comercial,
        codPaisComercial = w_pais_comercial,

        codPaisLegal = w_pais_legal,
        dPaisLegal = w_d_pais_legal,
    } = state;

    const formatPaisLegal = v => {
        return dPaisLegal
    };

    const formatPaisComercial = v => {
        return dPaisComercial
    };

    const establecerProveedor = value => {
        if (value == null || value === '') return '';
        setProveedor(value.trim());
        return value;
    };

    const establecerFecha = value => {
        if (value == null || value === '') return '';
        return value;
    };

    // Obtengo tipo de persona para cargar solapa correspondiente
    const establecerPersonaTipo = value => {
        if (value == null || value === '') return '';
        setPersonaTipo(value.trim());
        return value;
    };


    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };


    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>

        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        return <span>Proveedor Nro.: {record ? `${record.pro}` + `${record.razon_social}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>
                {/*Datos empresa*/}
                <FormTab label="DATOS DE LA EMPRESA">
                    <TextInput source="pro" label={"Nro. Proveedor"} formClassName={classes.proveedor}
                               variant="outlined" fullWidth={true}
                               format={establecerProveedor}
                    />
                    <br/>
                    {/*

                    <TextInput source="persona_tipo" label={"Cod.Per"}
                               formClassName={classes.persona_tipo} fullWidth={true}
                               variant="outlined" InputLabelProps={{shrink: true}}
                               format={establecerPersonaTipo} inputProps={{readOnly: true}}
                               onChange={(v) => setPersonaTipo(v.target.value)}/>
*/}

                    <TextInput source="PersonaTipo.persona_tipo_d" label={"Tipo de Persona"}
                               formClassName={classes.persona_tipo_d} fullWidth={true}
                               variant="outlined" InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                    />

                    <TextInput source="PersonaForma.persona_forma_d" label={"Persona Forma"}
                               formClassName={classes.persona_forma_d} fullWidth={true}
                               variant="outlined" InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}/>
                    <br/>

                    <TextInput source="razon_social" label={"Razón Social"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.razon_social} fullWidth={true}/>

                    <TextInput source="razon_social_fantasia" label={"Razón Social Fantasía"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.razon_social_fantasia} fullWidth={true}/>
                    <br/>

                    {/*             <TextInput source="DocumentoTipo.documento_tipo_d" label={"Tipo Doc."} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.documento_tipo} fullWidth={true}/>*/}

                    <TextInput source="documento_tipo" label={"Doc."}
                               variant="outlined" inputProps={{readOnly: true}}
                               formClassName={classes.id_documento_tipo}
                               fullWidth
                               InputLabelProps={{shrink: true}}
                    />

                    <TextInput source="DocumentoTipo.documento_tipo_d" label={"Tipo Doc."}
                               variant="outlined" inputProps={{readOnly: true}}
                               formClassName={classes.documento_tipo}
                               fullWidth
                               InputLabelProps={{shrink: true}}
                    />

                    <TextInput source="documento_nro" label={"Nro. Doc."} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.documento_nro} fullWidth={true}/>

                    <TextInput source="cuit_cuil" label={"CUIT/CUIL"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.cuit_cuil} fullWidth={true}
                               format={cuitCuilFormat}
                    />
                    <br/>

                    <TextInput source="correo_electronico" label={"Email"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.email} fullWidth={true}/>

                    <TextInput source="correo_electronico_alternativo" label={"Email alternativo"}
                               variant="outlined" InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.emailAlt} fullWidth={true}/>
                    <br/>
                    <TextInput source="telefono_area" label={"Tel Área"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.area}
                               fullWidth={true}/>
                    <TextInput source="telefono_nro" label={"Tel Nro"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.nro}
                               fullWidth={true}/>


                    <TextInput source="telefono_alternativo_area" label={"Tel Área"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.area2} fullWidth={true}/>
                    <TextInput source="telefono_alternativo_nro" label={"Tel Nro"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.nro} fullWidth={true}/>
                    <br/>
                    <TextInput source="celular_area" label={"Cel Área"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.area}
                               fullWidth={true}/>
                    <TextInput source="celular_nro" label={"Tel Nro"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.nro}
                               fullWidth={true}/>

                    <TextInput source="fax_area" label={"Fax Área"} variant="outlined" InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               formClassName={classes.area2}
                               fullWidth={true}/>
                    <TextInput source="fax_nro" label={"Fax Nro."} variant="outlined" InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               formClassName={classes.nro}
                               fullWidth={true}/>
                </FormTab>
                {/*Domicilios*/}

                <FormTab label="DOMICILIOS">
                    <Typography variant="h6">Domicilio Legal</Typography>

                    <TextInput source="PaisLegal.pais" label={"Pais Legal"}
                               fullWidth={true}
                               formClassName={classes.pais}
                               variant="outlined" inputProps={{readOnly: true,}}
                               value={dPaisLegal}
                               format={formatPaisLegal}
                               InputLabelProps={{shrink: true}}
                    />


                    <div style={{display: 'inline-flex', width: '100%'}}>

                        <TextInput source="legal_provincia" label={"Provincia Legal"}
                                   className={classes.provincia}
                                   variant="outlined" inputProps={{readOnly: true,}}
                                   fullWidth={true}
                                   InputLabelProps={{shrink: true}}
                        />

                        <TextInput source="legal_ciudad" label={"Ciudad Legal"}
                                   className={classes.ciudad}
                                   variant="outlined" inputProps={{readOnly: true,}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />

                        <TextInput source="legal_partido" label={"Partido"} variant="outlined"
                                   InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                                   className={classes.partido} fullWidth
                        />
                    </div>

                    <TextInput source="legal_calle" label={"Calle"} variant="outlined" InputLabelProps={{shrink: true}}
                               formClassName={classes.calle} fullWidth={true}/>

                    <TextInput source="legal_calle_nro" label={"Nro."} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.calle_nro} fullWidth={true}/>

                    <TextInput source="legal_piso" label={"Piso"} variant="outlined" InputLabelProps={{shrink: true}}
                               formClassName={classes.piso} fullWidth={true}/>

                    <TextInput source="legal_departamento" label={"Depto"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.departamento} fullWidth={true}/>

                    <TextInput source="legal_codigo_postal" label={"Código Postal"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.codigo_postal} fullWidth={true}/>
                    <br/>
                    <Typography variant="h6">Domicilio Comercial</Typography>

                    <TextInput source="ComercialPais.pais" label={"Pais Comercial"}
                               fullWidth={true}
                               formClassName={classes.pais}
                               variant="outlined" inputProps={{readOnly: true,}}
                               value={dPaisComercial}
                               format={formatPaisComercial}
                               InputLabelProps={{shrink: true}}
                    />

                    <div style={{display: 'inline-flex', width: '100%'}}>

                        <TextInput source="comercial_provincia" label={"Provincia Comercial"}
                                   className={classes.provincia}
                                   variant="outlined" inputProps={{readOnly: true,}}
                                   fullWidth={true}
                                   InputLabelProps={{shrink: true}}
                        />

                        <TextInput source="comercial_ciudad" label={"Ciudad Comercial"}
                                   className={classes.ciudad}
                                   variant="outlined" inputProps={{readOnly: true,}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />

                        <TextInput source="comercial_partido" label={"Partido"} variant="outlined"
                                   InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                                   className={classes.partido} fullWidth
                        />
                    </div>
                    <TextInput source="comercial_calle" label={"Calle"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.calle} fullWidth={true}/>
                    <TextInput source="comercial_calle_nro" label={"Nro."} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.calle_nro} fullWidth={true}/>
                    <TextInput source="comercial_piso" label={"Piso"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               formClassName={classes.piso} fullWidth={true}/>
                    <TextInput source="comercial_departamento" label={"Depto"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.departamento} fullWidth={true}/>
                    <TextInput source="comercial_codigo_postal" label={"Código Postal"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.codigo_postal} fullWidth={true}/>
                </FormTab>
                {/*Categorización*/}
                <FormTab label="CATEGORIZACION">

                    <Typography variant="h6">Ingreso Bruto</Typography>

                    {/*<TextInput source="ibru_iva" label={"Iva"} variant="outlined" InputLabelProps={{shrink: true}}*/}
                    {/*           formClassName={classes.d_iva} fullWidth={true}/>*/}

                    <IvaCombo readOnly={true}/>

                    <SelectInput source="ibru_exento"
                                 inputProps={{readOnly: true}}
                                 label="Ibru excento"
                                 formClassName={classes.ibru_exento_combo}
                                 fullWidth={true}
                                 variant="outlined"
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}
                    />

                    <SelectInput source="ibru_tipo" formClassName={classes.ibru_tipo_combo}
                                 inputProps={{readOnly: true}}
                                 variant="outlined"
                                 choices={[
                                     {id: 'CL', name: 'Convenio Local'},
                                     {id: 'CM', name: 'Convenio Multilateral'},
                                 ]}/>
                    <br/>
                    <TextInput source="ibru_nro" label={"Nro"} formClassName={classes.ibru_nro} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               fullWidth={true}/>

                    <TextInput source="ibru_jurisdiccion_provincia" label={"Jurisdicción"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               formClassName={classes.ibru_jurisdiccion_provincia} fullWidth={true}/>

                    <br/>

                    <SelectInput source="es_pyme" formClassName={classes.es_pyme} fullWidth={true}
                                 variant="outlined" InputLabelProps={{shrink: true}}
                                 inputProps={{readOnly: true}}
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}/>
                    <SelectInput source="tiene_personas_con_discapacidad"
                                 formClassName={classes.tiene_personas_con_discapacidad} fullWidth={true}
                                 variant="outlined" InputLabelProps={{shrink: true}}
                                 inputProps={{readOnly: true}}
                                 label={"Personas con discapacidad"}
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}/>

                    <SelectInput source="tiene_participacion_estatal"
                                 formClassName={classes.tiene_participacion_estatal} fullWidth={true}
                                 variant="outlined" InputLabelProps={{shrink: true}}
                                 inputProps={{readOnly: true}}
                                 label={"Part. Estatal"}
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}/>

                    <SelectInput source="tiene_participacion_entidades_publicas_no_estatales"
                                 formClassName={classes.tiene_participacion_entidades_publicas_no_estatales}
                                 fullWidth={true} inputProps={{readOnly: true}}
                                 label={"Part. Publ. No-Estatal"}
                                 variant="outlined" InputLabelProps={{shrink: true}}
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}/>

                    <TextInput source="registro_proveedor_estado_nro" label={"Estado Nro."} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.registro_proveedor_estado_nro} fullWidth={true}/>

                </FormTab>
                {/*Uso Interno*/}
                <FormTab label="USO INTERNO">
                    <TextInput source="codigo_pro_tvpublica" label={"Cod. Pro. TV Publica"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.codigo_pro_tvpublica} fullWidth={true}/>

                    <TextInput source="codigo_pro_rna" label={"Cod. Pro. RNA"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.codigo_pro_rna} fullWidth={true}/>

                    <DateInput source="fecha_alta" label={"Fecha Alta"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.fecha_alta} fullWidth={true}/>

                    <TextInput source="fecha_baja" label={"Fecha Baja"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               format={establecerFecha}
                               formClassName={classes.fecha_baja} fullWidth={true}/>
                    <br/>

                    <TextInput source="EstadoProveedor.estado_proveedor_abre" label={"Estado Proveedor"}
                               formClassName={classes.estado_proveedor_abre} fullWidth={true}
                               variant="outlined" InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                    />

                    {/*<EstadoProveedorCombo readOnly={true}/>*/}

                    <TextInput source="estado_proveedor_usuario" label={"Estado Pro. Usuario"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               formClassName={classes.estado_proveedor_usuario} fullWidth={true}/>

                    <DateTimeInput source="estado_proveedor_fecha_hora" label={"Estado Pro Fecha/h"} variant="outlined"
                                   inputProps={{readOnly: true}}
                                   InputLabelProps={{shrink: true}}
                                   options={{
                                       format: 'dd/MM/yyyy HH:mm:ss',
                                       ampm: false
                                   }}
                                   formClassName={classes.estado_proveedor_fecha_hora} fullWidth={true}/>
                    <br/>
                    <TextInput source="observacion" label={"Observación"} variant="outlined"
                               multiline
                               formClassName={classes.observacion} fullWidth={true}/>
                    <br/>
                    <TextInput source="agenda" label={"Agenda"} variant="outlined"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}} multiline
                               formClassName={classes.agenda} fullWidth={true}/>

                </FormTab>
                {/*Tipo de persona*/}
                <FormTab label="Tipo de Persona">
                    {PersonaTipo === 'PF' &&
                    <ProveedorPersonaFisica pro={Proveedor}/>
                    }

                    {PersonaTipo === 'PJ' &&
                    <ProveedorPersonaJuridica pro={Proveedor}/>
                    }

                    {PersonaTipo === 'PE' &&
                    <ProveedorPersonaExtranjera pro={Proveedor}/>
                    }

                </FormTab>
                <FormTab label="Clases">
                    {Proveedor &&
                    <ProveedorClasesGrid pro={Proveedor}/>
                    }
                </FormTab>
                {/*Representantes*/}
                <FormTab label="Representantes">
                    {Proveedor &&
                    <ProveedorRepresentanteList pro={Proveedor}/>
                    }
                </FormTab>
                {/*Apoderados*/}
                <FormTab label="Apoderados">
                    {Proveedor &&
                    <ProveedorApoderadoList pro={Proveedor}/>
                    }
                </FormTab>
                {/*Socios*/}
                <FormTab label="Socios">
                    {Proveedor &&
                    <ProveedorSocioList pro={Proveedor}/>
                    }
                </FormTab>
                {/*Documentacion*/}
                <FormTab label="Documentación">
                    {Proveedor &&
                    <ProveedorDocumentacionShowList pro={Proveedor}/>
                    }
                </FormTab>
            </TabbedForm>
        </Show>
    )
};
export default ProveedorShow;


