import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput, maxLength, required
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';


const useStyles = makeStyles((theme) => ({
    unidad_medida: {display: 'inline-block', width: 200, marginLeft: 8},
    unidad_medida_d: {display: 'inline-block', width: 600, marginLeft: 108},
    unidad_medida_abre: {display: 'inline-block', width: 200, marginLeft: 8},
    codigo_afip: {display: 'inline-block', width: 200, marginLeft: 108},
    codigo_gestion: {display: 'inline-block', width: 200, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_unidad_medida = values.unidad_medida

    if (w_unidad_medida===undefined) {
        errors.unidad_medida = ['El campo Código es requerido'];
    }else {
        if (w_unidad_medida === null) {
            errors.unidad_medida = ['El campo Código es requerido'];
        } else {
            w_unidad_medida = w_unidad_medida.trim()
            if (w_unidad_medida === '') {
                errors.unidad_medida = ['El campo Código es requerido'];
            }
        }
    }


    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/UniMed`;

const validateunidad_medida = [required(), maxLength(10)];
const validateunidad_medida_d = [required(), maxLength(60)];
const validateunidad_medida_abre = [maxLength(6)];
const validatecodigo_afip = [maxLength(10)];
const validatecodigo_gestion = [maxLength(10)];

const UniMedCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Unidad Medida</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS UNIDAD MEDIDA">
                    <TextInput
                        label="Código Unidad Medida"
                        source="unidad_medida"
                        formClassName={classes.unidad_medida}
                        placeholder="Ingrese Código Unidad Medida"
                        helperText="Código Unidad Medida"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validateunidad_medida}
                    />

                    <TextInput
                        label="Descripción Unidad Medida"
                        source="unidad_medida_d"
                        formClassName={classes.unidad_medida_d}
                        placeholder="Ingrese Descripción Unidad Medida"
                        helperText="Descripción Unidad Medida"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validateunidad_medida_d}
                    />

                    <br/>

                    <TextInput
                        label="Unidad Medida Abre."
                        source="unidad_medida_abre"
                        formClassName={classes.unidad_medida_abre}
                        placeholder="Ingrese Unidad Medida Abre."
                        helperText="Unidad Medida Abre."
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validateunidad_medida_abre}
                    />



                    <TextInput
                        label="Código AFIP"
                        source="codigo_afip"
                        formClassName={classes.codigo_afip}
                        placeholder="Ingrese Código AFIP"
                        helperText="Código AFIP"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validatecodigo_afip}
                    />


                    <TextInput
                        label="Código Gestion"
                        source="codigo_gestion"
                        formClassName={classes.codigo_gestion}
                        placeholder="Ingrese Código Gestion"
                        helperText="Código Gestion"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validatecodigo_gestion}
                    />

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default UniMedCreate;


