import React, {useEffect, Fragment, useState, useCallback} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField, useNotify, useRefresh, useMutation, FormTab
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
import LineWeightIcon from "@material-ui/icons/LineWeight";
import SaveIcon from '@material-ui/icons/Save';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AddIcon from '@material-ui/icons/Add';
import ProveedorBusquedaDialogBtn from "../proveedor/ProveedorBusquedaDialogBtn"
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
            isNumericString
            prefix=""
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


function NumberFormatCustomSin(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            // thousandSeparator={'.'}
            // decimalSeparator={','}
            isNumericString
            prefix=""
        />
    );
}

NumberFormatCustomSin.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


const ContratacionEncuadreAdjudicacionButtonEdit = ({record}) => {
    var wr_id = record.id

    const dataProviderTableroControl = useDataProvider();
    const [Estadoid, setEstadoid] = useState(0)
    const [Estadoid_contratacion_encuadre, setEstadoid_contratacion_encuadre] = useState(0)
    const [Estadoexpediente_referencia_gde, setEstadoexpediente_referencia_gde] = useState('')
    const [Estadocontratacion_tipo, setEstadocontratacion_tipo] = useState('')
    const [Estadorenglon_nro, setEstadorenglon_nro] = useState('')
    const [Estadorenglon_descripcion, setEstadorenglon_descripcion] = useState('')
    const [Estadoimporte_adjudicado_pesos, setEstadoimporte_adjudicado_pesos] = useState(0)
    const [Estadoorden_compra_nro, setEstadoorden_compra_nro] = useState(0)
    const [Estadoorden_compra_ampliacion_nro, setEstadoorden_compra_ampliacion_nro] = useState(0)
    const [Estadoproveedor_rta, setEstadoproveedor_rta] = useState('')
    const [Estadorazon_social, setEstadorazon_social] = useState('')
    const [Estadocodigo_pro_tvpublica, setEstadocodigo_pro_tvpublica] = useState('')
    const [Estadocodigo_pro_rna, setEstadocodigo_pro_rna] = useState('')

    const [helperTextrenglon_nro, setHelperTextrenglon_nro] = useState('');
    const [helperTextrenglon_descripcion, setHelperTextrenglon_descripcion] = useState('');
    const [helperTextorden_compra_nro, setHelperTextorden_compra_nro] = useState('');
    const [helperTextorden_compra_ampliacion_nro, setHelperTextorden_compra_ampliacion_nro] = useState('');
    const [helperTextimporte_adjudicado_pesos, setHelperTextimporte_adjudicado_pesos] = useState('');
    const [helperTextproveedor_rta, setHelperTextproveedor_rta] = useState('');
    const [openEditarConfirmacionContratacionEncuadreAdjudicacion, setopenEditarConfirmacionContratacionEncuadreAdjudicacion] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const classes = useStyles()
    const [openEditar, setOpenEditar] = React.useState(false);
    const [state, setState] = useState({});


    const updateProveedor = useCallback(async (who) => {

        setEstadoproveedor_rta(who.pro.trim())
        setEstadorazon_social(who.razon_social)
        setEstadocodigo_pro_tvpublica(who.codigo_pro_tvpublica)
        setEstadocodigo_pro_rna(who.codigo_pro_rna)


        // setState(state => ({
        //     ...state,
        //     proveedor_rta: who.pro.trim(),
        //     razon_social: who.razon_social,
        //     codigo_pro_tvpublica: who.codigo_pro_tvpublica,
        //     codigo_pro_rna: who.codigo_pro_rna,
        // }));
    })

    const handleClickOpenEditar = () => {
        var wr_id = record.id
        var wr_id_contratacion_encuadre = record.id_contratacion_encuadre
        var wr_expediente_referencia_gde = record.expediente_referencia_gde
        var wr_contratacion_tipo = record.contratacion_tipo
        var wr_renglon_nro = record.renglon_nro
        var wr_renglon_descripcion = record.renglon_descripcion
        var wr_importe_adjudicado_pesos = record.importe_adjudicado_pesos
        var wr_orden_compra_nro = record.orden_compra_nro
        var wr_orden_compra_ampliacion_nro = record.orden_compra_ampliacion_nro
        var wr_proveedor_rta = record.proveedor_rta
        var wr_razon_social = record.razon_social
        var wr_codigo_pro_tvpublica = record.codigo_pro_tvpublica
        var wr_codigo_pro_rna = record.codigo_pro_rna

        setEstadoid(wr_id)
        setEstadoid_contratacion_encuadre(wr_id_contratacion_encuadre)
        setEstadoexpediente_referencia_gde(wr_expediente_referencia_gde)
        setEstadocontratacion_tipo(wr_contratacion_tipo)
        setEstadorenglon_nro(wr_renglon_nro)
        setEstadorenglon_descripcion(wr_renglon_descripcion)
        setEstadoimporte_adjudicado_pesos(wr_importe_adjudicado_pesos)
        setEstadoorden_compra_nro(wr_orden_compra_nro)
        setEstadoorden_compra_ampliacion_nro(wr_orden_compra_ampliacion_nro)
        setEstadoproveedor_rta(wr_proveedor_rta)
        setEstadorazon_social(wr_razon_social)
        setEstadocodigo_pro_tvpublica(wr_codigo_pro_tvpublica)
        setEstadocodigo_pro_rna(wr_codigo_pro_rna)
        setHelperTextrenglon_nro('')
        setHelperTextrenglon_descripcion('')
        setHelperTextorden_compra_nro('');
        setHelperTextorden_compra_ampliacion_nro('');
        setHelperTextimporte_adjudicado_pesos('');
        setHelperTextproveedor_rta('');

        setOpenEditar(true);
    };

    const handleActualizar = () => {
        setOpenEditar(false);
    };


    const handleCloseEditar = () => {
        setOpenEditar(false);
    };


    const handleClickOpenEditarConfirmacionContratacionEncuadreAdjudicacion = () => {
        var w_validacion = ''
        var w_helpertextrenglon_nro = ''
        var w_helpertextrenglon_descripcion = ''
        var w_helpertextorden_compra_nro = ''
        var w_helpertextorden_compra_ampliacion_nro = ''
        var w_helpertextimporte_adjudicado_pesos = ''
        var w_helpertextproveedor_rta = ''

        var w_renglon_nro = Estadorenglon_nro
        if(w_renglon_nro==null){
            w_renglon_nro=''
        }
        w_renglon_nro=w_renglon_nro.trim()
        if(w_renglon_nro===''){
            w_validacion = 'E'
            w_helpertextrenglon_nro = 'Debe Ingresar Renglon Nro'
        }

        var w_renglon_descripcion = Estadorenglon_descripcion
        if(w_renglon_descripcion==null){
            w_renglon_descripcion=''
        }
        w_renglon_descripcion=w_renglon_descripcion.trim()
        if(w_renglon_descripcion===''){
            w_validacion = 'E'
            w_helpertextrenglon_descripcion = 'Debe Ingresar Renglon Descripcion'
        }

        var w_orden_compra_nro = Estadoorden_compra_nro
        if(w_orden_compra_nro==null){
            w_orden_compra_nro=''
        }
        if(w_orden_compra_nro===''){
            w_validacion = 'E'
            w_helpertextorden_compra_nro = 'Debe Ingresar Orden Compra Nro'
        }
        if(w_orden_compra_nro===0){
            w_validacion = 'E'
            w_helpertextorden_compra_nro = 'Debe Ingresar Orden Compra Nro'
        }

        var w_orden_compra_ampliacion_nro = Estadoorden_compra_ampliacion_nro
        // console.log('Estadoorden_compra_ampliacion_nro')
        // console.log(w_orden_compra_ampliacion_nro)
        // alert(w_orden_compra_ampliacion_nro)
        if(w_orden_compra_ampliacion_nro==null){
            w_orden_compra_ampliacion_nro=''
        }
        if(w_orden_compra_ampliacion_nro===''){
            w_validacion = 'E'
            w_helpertextorden_compra_ampliacion_nro = 'Debe Ingresar Orden Compra Ampliacion Nro'
        }

        var w_importe_adjudicado_pesos = Estadoimporte_adjudicado_pesos
        if(w_importe_adjudicado_pesos==null){
            w_importe_adjudicado_pesos=0
        }
        if(w_importe_adjudicado_pesos===''){
            w_importe_adjudicado_pesos=0
        }
        if(w_importe_adjudicado_pesos===0){
            w_validacion = 'E'
            w_helpertextimporte_adjudicado_pesos = 'Debe Ingresar Importe Adjudicado Pesos'
        }

        var w_proveedor_rta = Estadoproveedor_rta
        if(w_proveedor_rta==null){
            w_proveedor_rta=''
        }
        w_proveedor_rta=w_proveedor_rta.trim()
        if(w_proveedor_rta===''){
            w_validacion = 'E'
            w_helpertextproveedor_rta = 'Debe Ingresar Proveedor'
        }

        if(w_validacion === 'E') {
            setHelperTextrenglon_nro(w_helpertextrenglon_nro)
            setHelperTextrenglon_descripcion(w_helpertextrenglon_descripcion)
            setHelperTextorden_compra_nro(w_helpertextorden_compra_nro);
            setHelperTextorden_compra_ampliacion_nro(w_helpertextorden_compra_ampliacion_nro);
            setHelperTextimporte_adjudicado_pesos(w_helpertextimporte_adjudicado_pesos);
            setHelperTextproveedor_rta(w_helpertextproveedor_rta);

        }else {
            setopenEditarConfirmacionContratacionEncuadreAdjudicacion(true);
        }
    };

    const handleCloseEditarConfirmacionContratacionEncuadreAdjudicacion = () => {
        setopenEditarConfirmacionContratacionEncuadreAdjudicacion(false);
    };

    const BotonEditarDetelle = (record) => {

        var w_mensaje = ' '

        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelleupdatecontratacionencuadreadjudicacion, {loading}] = useMutation({
                type: 'update',
                resource: 'ContratacionEncuadreAdjudicacion',
                payload: {id: Estadoid, data: {id_contratacion_encuadre: Estadoid_contratacion_encuadre,
                        expediente_referencia_gde: Estadoexpediente_referencia_gde,
                        contratacion_tipo: Estadocontratacion_tipo,
                        renglon_nro: Estadorenglon_nro,
                        renglon_descripcion: Estadorenglon_descripcion,
                        importe_adjudicado_pesos: Estadoimporte_adjudicado_pesos,
                        orden_compra_nro: Estadoorden_compra_nro,
                        orden_compra_ampliacion_nro: Estadoorden_compra_ampliacion_nro,
                        proveedor_rta: Estadoproveedor_rta,
                        razon_social: Estadorazon_social,
                        codigo_pro_tvpublica: Estadocodigo_pro_tvpublica,
                        codigo_pro_rna: Estadocodigo_pro_rna
                    }}
            },
            {
                onSuccess: ({data}) => {
                    setopenEditarConfirmacionContratacionEncuadreAdjudicacion(false);
                    refresh()
                    // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------

                },
                onFailure: error => {
                    setopenEditarConfirmacionContratacionEncuadreAdjudicacion(false);
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );

        return (
            <div>
                <Button variant="outlined" color="primary"
                        onClick={handleClickOpenEditarConfirmacionContratacionEncuadreAdjudicacion}
                        style={{color: '#ffffff', backgroundColor: '#123B80'}}
                >
                    <div title={'Actualizar la Orden de Compra (Contratacion Encuadre Adjudicacion)'}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        <SaveIcon style={{color: '#ffffff', backgroundColor: '#123B80'}}/>
                        Actualizar
                    </div>
                </Button>
                <Dialog open={openEditarConfirmacionContratacionEncuadreAdjudicacion}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{'Actualizar la Contratacion Encuadre Adjudicacion'}
                    </DialogTitle>
                    <DialogContent>
                        ¿Quiere Actualizar la Orden de Compra?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary"
                                onClick={generardetelleupdatecontratacionencuadreadjudicacion}
                                disabled={loading}
                                style={{color: '#ffffff', backgroundColor: '#123B80'}}
                        >
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                 }}>
                                <SaveIcon style={{color: '#ffffff', backgroundColor: '#123B80'}}/>
                                Actualizar
                            </div>
                        </Button>
                        <Button variant="outlined" color="primary"
                                onClick={handleCloseEditarConfirmacionContratacionEncuadreAdjudicacion}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                <CancelPresentationIcon style={{color: '#123B80'}}/>
                                Cancelar
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
                {/*<Button variant="outlined" color="primary" onClick={generardetelleupdatecontratacionencuadreadjudicacion}*/}
                {/*        disabled={loading}*/}
                {/*        style={{color: '#ffffff', backgroundColor: '#123B80'}}*/}
                {/*>*/}
                {/*    <div title={''}*/}
                {/*         style={{*/}
                {/*             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'*/}
                {/*         }}>*/}
                {/*        <SaveIcon style={{color: '#ffffff', backgroundColor: '#123B80'}}/>*/}
                {/*        Actualizar*/}
                {/*    </div>*/}
                {/*</Button>*/}
                {/*<Button variant="outlined" color="primary" onClick={handleClickOpenEditarConfirmacionContratacionEncuadreAdjudicacion}*/}
                {/*        style={{color: '#ffffff', backgroundColor: '#123B80'}}*/}
                {/*>*/}
                {/*    <div title={'Confirmacion de la Orden de Compra (Contratacion Encuadre Adjudicacion)'}*/}
                {/*         style={{*/}
                {/*             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
                {/*        <SaveIcon style={{color: '#ffffff', backgroundColor: '#123B80'}}/>*/}
                {/*        Actualizar*/}
                {/*    </div>*/}
                {/*</Button>*/}
                {/*<Dialog open={openEditarConfirmacionContratacionEncuadreAdjudicacion} aria-labelledby="form-dialog-title">*/}
                {/*    <DialogTitle id="form-dialog-title">{'Confirmacion de la Contratacion Encuadre Adjudicacion'}</DialogTitle>*/}
                {/*    <DialogContent>*/}
                {/*        ¿Quiere Confirmar la Actualizacion de la Orden de Compra?*/}
                {/*    </DialogContent>*/}
                {/*    <DialogActions>*/}
                {/*        <Button variant="outlined" color="primary" onClick={generardetelleupdatecontratacionencuadreadjudicacion}*/}
                {/*                disabled={loading}*/}
                {/*                style={{color: '#ffffff', backgroundColor: '#123B80'}}*/}
                {/*        >*/}
                {/*            <div title={''}*/}
                {/*                 style={{*/}
                {/*                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'*/}
                {/*                 }}>*/}
                {/*                <SaveIcon style={{color: '#ffffff', backgroundColor: '#123B80'}}/>*/}
                {/*                Actualizar*/}
                {/*            </div>*/}
                {/*        </Button>*/}
                {/*        <Button variant="outlined" color="primary" onClick={handleCloseEditarConfirmacionContratacionEncuadreAdjudicacion}>*/}
                {/*            <div title={''}*/}
                {/*                 style={{*/}
                {/*                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
                {/*                <CancelPresentationIcon style={{color: '#123B80'}}/>*/}
                {/*                Cancelar*/}
                {/*            </div>*/}
                {/*        </Button>*/}
                {/*    </DialogActions>*/}
                {/*</Dialog>*/}
            </div>
            // <Button variant="outlined" color="primary" onClick={generardetelle}>
            //     <div title={''}
            //          style={{
            //              display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
            //         <SaveIcon style={{color: '#123B80'}}/>
            //         Guardar
            //     </div>
            // </Button>
            // <Button label={w_mensaje}
            //         title={w_mensaje}
            //         onClick={generardetelle} disabled={loading}
            //         color="primary"
            // >
            //     <div>
            //         <LineWeightIcon style={{fontSize: 40, backgroundColor: '#ffffff', color: '#123B80'}}/>
            //     </div>
            // </Button>
        );

    };


    const handlerenglon_nro = (event) => {
        const Cambiarenglon_nro1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadorenglon_nro(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiarenglon_nro1(w_value)

    };

    const handlerenglon_descripcion = (event) => {
        const Cambiarenglon_descripcion1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadorenglon_descripcion(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiarenglon_descripcion1(w_value)

    };

    const handleimporte_adjudicado_pesos = (event) => {
        const Cambiaimporte_adjudicado_pesos1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoimporte_adjudicado_pesos(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        // if(w_value===''){}else{
        //     w_value = Number(w_value)
        // }
        Cambiaimporte_adjudicado_pesos1(w_value)

    };

    const handleorden_compra_nro = (event) => {
        const Cambiaorden_compra_nro1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoorden_compra_nro(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiaorden_compra_nro1(w_value)

    };

    const handleorden_compra_ampliacion_nro = (event) => {
        const Cambiaorden_compra_ampliacion_nro1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoorden_compra_ampliacion_nro(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiaorden_compra_ampliacion_nro1(w_value)

    };

    return (
        <div>
            <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                    onClick={handleClickOpenEditar}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <EditIcon/>
                    Editar
                </div>
            </Button>
            <Dialog open={openEditar}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                // fullScreen={true}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Detalle de Orden de Compra (Contratacion Encuadre Adjudicacion)</DialogTitle>
                <DialogContent>
                    <form>
                        <label>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                label="Orden Compra Nro"
                                                value={Estadoorden_compra_nro}
                                                onChange={handleorden_compra_nro}
                                                name="numberformat"
                                                id="orden_compra_nro_ing"
                                                formClassName={classes.orden_compra_nro}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustomSin,
                                                }}
                                            />
                                            <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                                                {helperTextorden_compra_nro}
                                            </FormHelperText>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="Orden Compra Ampliacion Nro"
                                                value={Estadoorden_compra_ampliacion_nro}
                                                onChange={handleorden_compra_ampliacion_nro}
                                                name="numberformat"
                                                id="orden_compra_ampliacion_nro_ing"
                                                formClassName={classes.orden_compra_ampliacion_nro}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustomSin,
                                                }}
                                            />
                                            <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                                                {helperTextorden_compra_ampliacion_nro}
                                            </FormHelperText>
                                            <br/>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="Importe Adjudicado Pesos"
                                                value={Estadoimporte_adjudicado_pesos}
                                                onChange={handleimporte_adjudicado_pesos}
                                                name="numberformat"
                                                id="importe_presupuesto_ing"
                                                formClassName={classes.importe_adjudicado_pesos}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                            <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                                                {helperTextimporte_adjudicado_pesos}
                                            </FormHelperText>
                                            <br/>
                                        </TableCell>
                                        <TableCell>
                                            <TextField label="Renglon" value={Estadorenglon_nro}
                                                       onChange={handlerenglon_nro}
                                                       SelectProps={{native: true,}}
                                            />
                                            <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                                                {helperTextrenglon_nro}
                                            </FormHelperText>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                label="Renglon Descripción"
                                                value={Estadorenglon_descripcion}
                                                onChange={handlerenglon_descripcion}
                                                multiline
                                                fullWidth
                                                SelectProps={{native: true,}}
                                            />
                                            <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                                                {helperTextrenglon_descripcion}
                                            </FormHelperText>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">Proveedor Rta</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <ProveedorBusquedaDialogBtn callbackProveedor={updateProveedor}
                                                                        proveedor={Estadoproveedor_rta}
                                                                        formClassName={classes.btnBusqueda}/>
                                        </TableCell>
                                        <TableCell>
                                            <TextField label="Nro. Proveedor"
                                                       value={Estadoproveedor_rta}
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                                       inputProps={{readOnly: true}}
                                            />
                                            <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                                                {helperTextproveedor_rta}
                                            </FormHelperText>
                                        </TableCell>
                                        <TableCell>
                                            <TextField label="Cod. Pro. TV Publica"
                                                       value={Estadocodigo_pro_tvpublica}
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                                       inputProps={{readOnly: true}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField label="Cod. Pro. RNA"
                                                       value={Estadocodigo_pro_rna}
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                                       inputProps={{readOnly: true}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField label="Razón Social"
                                                       value={Estadorazon_social}
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                                       fullWidth
                                                       inputProps={{readOnly: true}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </label>
                    </form>
                </DialogContent>
                <DialogActions>
                    <BotonEditarDetelle/>
                    <Button variant="outlined" color="primary" onClick={handleCloseEditar}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <CancelPresentationIcon style={{color: '#123B80'}}/>
                            Cancelar
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}


export default ContratacionEncuadreAdjudicacionButtonEdit;