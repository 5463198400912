import React from "react";
import Imagetv from "../../layout/logos/TVP_WEB.svg";
import Imagec12 from "../../layout/logos/Canal12_WEB.svg";
import Imagerna from "../../layout/logos/RNA_WEB.svg";
import Imagerta from "../../layout/logos/RTA_WEB.svg";
import Imagetec from "../../layout/logos/logo_tecnopolis.png";
import Imageprincipalrta from '../../layout/headerRTA.jpg';
import './AvatarImage.css'

    // JN Recibe el medio y devuelve la imagen correspondiente. Se utiliza tambien en el loggin y arma el div mas grande en ese caso.
    // A mejorar que se pueda enviar el tamaño y modificar el estilo del div con mas opciones
const AvatarImage = ({medio, esLogin}) => {

    var idSize = esLogin ? 'containerGrande' : 'containerChico';
    var image = '';
    switch (medio.trim()) {
        case medio = 'Canal7':
            image = Imagetv;
            break;
        case medio = 'Canal12':
            image = Imagec12;
            break;
        case medio = 'RNA':
            image = Imagerna;
            break;
        case medio = 'Tecnopolis':
            image = Imagetec;
            break;
        case medio = 'UCO':
            image = Imagerta;
            break;
        case medio = 'PRTA':
            image = Imageprincipalrta;
            break;
        default:
            image = Imagerta;
    }


    return (
        <div id={idSize}>
            <img src={image}/>
        </div>
    )
};

export default AvatarImage;
