import React, {useState} from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import {Create, FormTab, Show, TextInput, TabbedForm, TextField, Datagrid} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({}));

const ProveedorRepresentanteShow = props => {
    const classes = useStyles();

    return (
        <Show {...props}>
            <TabbedForm>
                <TextField label="Proveedor" source="pro" sortable={false}/>
                <TextField label="Nro Apoderado" source="apoderado_nro_ingreso" sortable={false}/>

            </TabbedForm>
        </Show>
    )
};
export default ProveedorRepresentanteShow;


