import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField, useNotify, useRefresh, useMutation, FormTab
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
import LineWeightIcon from "@material-ui/icons/LineWeight";
import SaveIcon from '@material-ui/icons/Save';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import clsx from "clsx";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from "@material-ui/core/FormHelperText";
import FileCopyIcon from "@material-ui/icons/FileCopy";

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const useStyles2 = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));


const PasswordButtonEdit = ({record}) => {
    var w_username = record.username
    if(w_username==null){
        w_username=''
    }
    w_username=w_username.trim()
    var w_usuario = record.usuario
    if(w_usuario==null){
        w_usuario=''
    }
    w_usuario=w_usuario.trim()
    var w_es_administrador = record.es_administrador
    if(w_es_administrador==null){
        w_es_administrador=''
    }
    w_es_administrador=w_es_administrador.trim()
    console.log('w_es_administrador')
    console.log(w_es_administrador)
    var w_cambiar_password = 'S'

    if(w_usuario==='alber' || w_usuario==='flopez'){}else {
        if (w_username === 'alber' || w_username === 'flopez') {
            w_cambiar_password = 'N'
        } else {
            if (w_username === w_usuario) {
            } else {
                if(w_es_administrador==='SI') {
                    w_cambiar_password = 'N'
                }
            }
        }
    }
    const [Estadoid, setEstadoid] = useState(0)
    const [EstadoUsername, setEstadoUsername] = useState(w_username)
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);
    const [helperTextpassword, setHelperTextpassword] = useState('');
    const [helperTextconfirm_password, setHelperTextconfirm_password] = useState('');

    const [values, setValues] = React.useState({
        username: '',
        password: '',
        confirm_password: '',
        id_usu_com: 0,
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        console.log(prop)
        if(prop==='password') {
            setHelperTextpassword('');
        }
        if(prop==='confirm_password') {
            setHelperTextconfirm_password('');
        }
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };





    const handleClickOpen = () => {
        var wr_id = record.id

        setEstadoid(wr_id)

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [openPasswordEdit, setopenPasswordEdit] = React.useState(false);

    const handleClickOpenPasswordEdit = () => {
        var w_validacion = ''
        var w_helpertextpassword = ''
        var w_helpertextconfirm_password = ''
        setHelperTextpassword(w_helpertextpassword)
        setHelperTextconfirm_password(w_helpertextconfirm_password)

        var w_password = values.password
        if(w_password==null){
            w_password=''
        }
        w_password=w_password.trim()
        if(w_password===''){
            w_validacion = 'E'
            w_helpertextpassword = 'Debe Ingresar la Nueva Contraseña'
        }

        var w_confirm_password = values.confirm_password
        if(w_confirm_password==null){
            w_confirm_password=''
        }
        w_confirm_password=w_confirm_password.trim()
        if(w_confirm_password===''){
            w_validacion = 'E'
            w_helpertextconfirm_password = 'Falta Ingresar la Confirmación de la Contraseña'
        }


        if(w_validacion === 'E') {
            setHelperTextpassword(w_helpertextpassword)
            setHelperTextconfirm_password(w_helpertextconfirm_password)
        }else {
            if (w_password === w_confirm_password) {
                setopenPasswordEdit(true);
            }else {
                w_helpertextpassword = 'Las contraseñas no coinciden.'
                w_helpertextconfirm_password = 'Las contraseñas no coinciden.'
                setHelperTextpassword(w_helpertextpassword)
                setHelperTextconfirm_password(w_helpertextconfirm_password)
            }
        }
    };

    const handleClosePasswordEdit = () => {
        setopenPasswordEdit(false);
    };

    const BotonActualizarDetelle = (record) => {

        console.log('BotonActualizarDetelle')

        var w_mensaje = ' '

        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'update',
                resource: 'UserCopy',
                payload: {
                    id: Estadoid, data: {
                        password: values.password
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    setopenPasswordEdit(false);
                    refresh()
                    // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//--------------- cambia el estado del componente para vover a renderizar ----------------

                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpenPasswordEdit}
                        style={{color: '#ffffff', backgroundColor: '#004187'}}
                >
                    <div title={'Nueva Contraseña'}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        <SaveIcon/>
                        Actualizar Nueva Contraseña
                    </div>
                </Button>
                <Dialog open={openPasswordEdit} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{'Confirmacion de la Nueva Contraseña'}</DialogTitle>
                    <DialogContent>
                        ¿Quiere Confirmar la Nueva Contraseña?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={generardetelle} disabled={loading}
                                style={{color: '#ffffff', backgroundColor: '#004187'}}
                        >
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                 }}>
                                <SaveIcon style={{color: '#ffffff', backgroundColor: '#004187'}}/>
                                Aceptar
                            </div>
                        </Button>
                        <Button variant="outlined" color="primary" onClick={handleClosePasswordEdit}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                <CancelPresentationIcon style={{color: '#004187'}}/>
                                Cancelar
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>


        );

    };

    if(w_cambiar_password === 'N'){
        return (
            <div>
                <Button variant="outlined" color="primary">
                    <div title={''}
                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                    >
                        <VpnKeyIcon style={{color: '#808080', fontSize: 15}}/>
                    </div>
                </Button>
            </div>
        )
    }else {
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    <div title={'Cambiar Contraseña'}
                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                    >
                        <VpnKeyIcon style={{color: '#123B80', fontSize: 15}}/>
                    </div>
                </Button>
                <Dialog open={open} maxWidth={'xl'} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Cambiar Contraseña</DialogTitle>
                    <DialogContent>
                        <br/>
                        <br/>
                        <TextField label="Usuario"
                                   value={EstadoUsername}
                                   formClassName={classes.username}
                        />
                        <br/>
                        <br/>
                        <FormControl className={clsx(classes.margin, classes.textField)}>
                            <InputLabel htmlFor="standard-adornment-password">Nueva Contraseña</InputLabel>
                            <Input
                                formClassName={classes.password}
                                id="standard-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormHelperText style={{color: '#ff0000', backgroundColor: '#ffffff'}}>
                            {helperTextpassword}
                        </FormHelperText>
                        <br/>
                        <FormControl className={clsx(classes.margin, classes.textField)}>
                            <InputLabel htmlFor="standard-adornment-confirm-password">Confirmar Contraseña</InputLabel>
                            <Input
                                formClassName={classes.password}
                                id="standard-adornment-confirm-password"
                                type={values.showConfirmPassword ? 'text' : 'password'}
                                value={values.confirm_password}
                                onChange={handleChange('confirm_password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownConfirmPassword}
                                        >
                                            {values.showConfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormHelperText style={{color: '#ff0000', backgroundColor: '#ffffff'}}>
                            {helperTextconfirm_password}
                        </FormHelperText>
                        <br/>
                        <br/>
                    </DialogContent>
                    <DialogActions>
                        <BotonActualizarDetelle/>
                        <Button variant="outlined" color="primary" onClick={handleClose}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                 }}>
                                <CancelPresentationIcon style={{color: '#123B80'}}/>
                                Cancelar
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

PasswordButtonEdit.defaultProps = {
    source: '',
    addLabel: true,
};

export default PasswordButtonEdit;