import React from 'react'
import {
    Datagrid,
    EditButton,
    List,
    TextField
} from 'react-admin';

export const ProveedorPersonaExtranjeroList = (props) => (
    <List {...props} title="Listado de Proveedores"
          bulkActionButtons={false}
          perPage={25}>

        <Datagrid>
            <TextField label="Proveedor" source="pro" sortable={false}/>
            <TextField label="Pais" source="identificacion_tributaria_pais_origen" sortable={false}/>
            <TextField label="Tipo" source="identificacion_tributaria_tipo" sortable={false}/>
            <TextField label="Número" source="identificacion_tributaria_nro" sortable={false}/>
        </Datagrid>
    </List>
);


export default ProveedorPersonaExtranjeroList;
