import React, {useEffect, useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput,
    NumberField,
    Datagrid,
    DateInput,
    SaveButton,
    Link,
    useDataProvider,
    useNotify,
    useRefresh,
    useMutation,
    useRedirect
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from "@material-ui/core/FormHelperText";
import NumberFormat from 'react-number-format';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    id_usu_com: {display: 'inline-block', width: 300, marginLeft: 8},
    notificacion_codigo: {display: 'inline-block', width: 400, marginLeft: 8},
}));

const PostCreateToolbar = props => {

    return (
        <Toolbar {...props} >
            <SaveButton
                label="Guardar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            <Grid container spacing={3}>
                <Grid item xs>

                </Grid>
                <Grid item xs>
                    <Link to={`/UsuComNotificacionTipo/`}>
                        <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <Button
                                label="Salir"
                            >
                                <ExitToAppIcon/> Salir
                            </Button>
                        </div>
                    </Link>
                </Grid>
            </Grid>
        </Toolbar>
    )

};

const transform = data => {
    return {
        ...data
    }
};

const validateCreate = (values) => {
    const errors = {};

    var w_estado_encuadre = values.estado_encuadre

    if (w_estado_encuadre===undefined) {
        errors.estado_encuadre = ['El campo Código es requerido'];
    }else {
        if (w_estado_encuadre === null) {
            errors.estado_encuadre = ['El campo Código es requerido'];
        } else {
            w_estado_encuadre = w_estado_encuadre.trim()
            if (w_estado_encuadre === '') {
                errors.estado_encuadre = ['El campo Código es requerido'];
            }
        }
    }


    var w_estado_encuadre_d = values.estado_encuadre_d

    if (w_estado_encuadre_d===undefined) {
        errors.estado_encuadre_d = ['El campo Descripción Contratacion Estado es requerido'];
    }else {
        if (w_estado_encuadre_d === null) {
            errors.estado_encuadre_d = ['El campo Descripción Contratacion Estado es requerido'];
        } else {
            w_estado_encuadre_d = w_estado_encuadre_d.trim()
            if (w_estado_encuadre_d === '') {
                errors.estado_encuadre_d = ['El campo Descripción Contratacion Estado es requerido'];
            }
        }
    }




    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/UsuComNotificacionTipo`;

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
            isNumericString
            prefix=""
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const UsuComAreaCreate = props => {
    const classes = useStyles();

    const dataProviderTableroControl = useDataProvider();
    const [UsernameNotificacionTipo, setUsernameNotificacionTipo] = useState(1);
    const [Estadoid_usu_com, setEstadoid_usu_com] = useState(0)
    const [ComboUsername, setComboUsername] = useState([]);
    const [Estadonotificacion_codigo, setEstadonotificacion_codigo] = useState('');
    const [ComboManualNotificacionTipo, setComboManualNotificacionTipo] = useState([]);

    const [errorid_usu_com, setErrorid_usu_com] = useState(false);
    const [helperTextid_usu_com, setHelperTextid_usu_com] = useState('');

    const [errornotificacion_codigo, setErrornotificacion_codigo] = useState(false);
    const [helperTextnotificacion_codigo, setHelperTextnotificacion_codigo] = useState('');



    useEffect(() => {
        dataProviderTableroControl.getList(
            'UserUsuCom',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'U' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboUsername(data);
            })
            .catch(error => {

            })
        dataProviderTableroControl.getList(
            'NotificacionTipo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'U', id_usu_com: Estadoid_usu_com },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboManualNotificacionTipo(data);
            })
            .catch(error => {

            })
    }, [UsernameNotificacionTipo]);

    const CambiaEstado = () => {
        setUsernameNotificacionTipo(UsernameNotificacionTipo + 1);
    };

    const handleid_usu_com = (event) => {
        const Cambiaid_usu_com1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoid_usu_com(wp_value)
            CambiaEstado()
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextid_usu_com('');
        Cambiaid_usu_com1(w_value)

    };

    const handlenotificacion_codigo = (event) => {
        const Cambianotificacion_codigo1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadonotificacion_codigo(wp_value)
            CambiaEstado()
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextnotificacion_codigo('');
        Cambianotificacion_codigo1(w_value)

    };


    const PostTitle = ({record}) => {
        return <span>Nueva Usuario de Notificacion</span>;
    };

    const [openConfirmacionUsuarioNotificacion, setopenConfirmacionUsuarioNotificacion] = React.useState(false);

    const handleClickOpenConfirmacionUsuarioNotificacion = () => {
        var w_validacion = ''

        var w_helpertextid_usu_com = ''
        var w_helpertextnotificacion_codigo = ''

        var w_id_usu_com = Estadoid_usu_com
        if(w_id_usu_com==null){
            w_id_usu_com=0
        }
        // w_id_usu_com=w_id_usu_com.trim()
        if(w_id_usu_com===0){
            w_validacion = 'E'
            w_helpertextid_usu_com = 'Debe Ingresar Usuario'
        }

        var w_notificacion_codigo = Estadonotificacion_codigo
        if(w_notificacion_codigo==null){
            w_notificacion_codigo=''
        }
        w_notificacion_codigo=w_notificacion_codigo.trim()
        if(w_notificacion_codigo===''){
            w_validacion = 'E'
            w_helpertextnotificacion_codigo = 'Debe Ingresar Notificacion Tipo'
        }

        if(w_validacion === 'E') {
            setHelperTextid_usu_com(w_helpertextid_usu_com);
            setHelperTextnotificacion_codigo(w_helpertextnotificacion_codigo);
        }else {
            setopenConfirmacionUsuarioNotificacion(true);
        }
    };

    const handleCloseConfirmacionUsuarioNotificacion = () => {
        setopenConfirmacionUsuarioNotificacion(false);
    };

    const BotonCrearDetelle = (record) => {

        var w_mensaje = ' '

        const notify = useNotify();
        const redirectTo = useRedirect();

        const [generardetelle, {loading}] = useMutation({
                type: 'create',
                resource: 'UsuComNotificacionTipo',
                payload: {
                    data: {
                        id_usu_com: Estadoid_usu_com,
                        notificacion_codigo: Estadonotificacion_codigo
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    // console.log('data------BotonCrearDetelle')
                    // console.log(data)
                    notify(`Creada el Usuario de Notificacion N°: ${data.id}`, "success");
                    redirectTo(`/UsuComNotificacionTipo?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=25&sort=id`);
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpenConfirmacionUsuarioNotificacion}
                        style={{color: '#ffffff', backgroundColor: '#004187'}}
                >
                    <div title={'Confirmacion de Usuario de Notificacion'}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        <SaveIcon style={{color: '#ffffff', backgroundColor: '#004187'}}/>
                        Guardar
                    </div>
                </Button>
                <Dialog open={openConfirmacionUsuarioNotificacion} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{'Confirmacion de Usuario de Notificacion'}</DialogTitle>
                    <DialogContent>
                        ¿Quiere Confirmar Usuario de Notificacion?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={generardetelle}
                                style={{color: '#ffffff', backgroundColor: '#004187'}}
                        >
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                 }}>
                                <SaveIcon style={{color: '#ffffff', backgroundColor: '#004187'}}/>
                                Guardar
                            </div>
                        </Button>
                        <Button variant="outlined" color="primary" onClick={handleCloseConfirmacionUsuarioNotificacion}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                <CancelPresentationIcon style={{color: '#004187'}}/>
                                Cancelar
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        );

    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                toolbar={false}
                // toolbar={<PostCreateToolbar />}
                // validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS Usuario de Notificacion">
                    
                    <Typography variant="h6">Usuario</Typography>
                    <TextField
                        id="id_usu_com_ing"
                        select
                        label=""
                        // size="small"
                        // value={currency}
                        formClassName={classes.id_usu_com}
                        value={Estadoid_usu_com}
                        onChange={handleid_usu_com}
                        SelectProps={{
                            native: true,
                        }}
                        fullWidth={true}
                        // helperText="Por favor seleccione una Username"
                    >
                        {ComboUsername.map((option) => (
                            <option key={option.id_usu_com} value={option.id_usu_com}>
                                {option.username}
                            </option>
                        ))}
                    </TextField>
                    <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                        {helperTextid_usu_com}
                    </FormHelperText>
                    <br/>

                    <Typography variant="h6">Notificacion Tipo</Typography>
                    <TextField
                        id="notificacion_codigo_ing"
                        select
                        label=""
                        // size="small"
                        // value={currency}
                        formClassName={classes.notificacion_codigo}
                        value={Estadonotificacion_codigo}
                        onChange={handlenotificacion_codigo}
                        SelectProps={{
                            native: true,
                        }}
                        fullWidth={true}
                        // helperText="Por favor seleccione una Username"
                    >
                        {ComboManualNotificacionTipo.map((option) => (
                            <option key={option.notificacion_codigo} value={option.notificacion_codigo}>
                                {option.notificacion_d}
                            </option>
                        ))}
                    </TextField>
                    <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                        {helperTextnotificacion_codigo}
                    </FormHelperText>

                    
                    <br/>
                    

                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <BotonCrearDetelle/>
                            </Grid>
                            <Grid item xs>
                                <Link to={`/UsuComNotificacionTipo/`}>
                                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                        <Button
                                            label="Salir"
                                        >
                                            <ExitToAppIcon/> Salir
                                        </Button>
                                    </div>
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default UsuComAreaCreate;


