import React, {useState, useCallback} from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Create,
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    AutocompleteInput,
    SelectInput,
    DateInput,
    TopToolbar,
    ReferenceInput,
    Toolbar, SaveButton, DeleteButton,
    useGetOne, Loading, NumberInput, TextField, ShowButton, Datagrid, NumberField, ReferenceManyField
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";
import ContratacionSecuenciaInfo from "./ContratacionSecuenciaInfo";
import ContratacionSecuenciaButtonEdit from "./ContratacionSecuenciaButtonEdit";
import ContratacionSecuenciaButtonShow from "./ContratacionSecuenciaButtonShow";
import ContratacionSecuenciaButtonDelete from "./ContratacionSecuenciaButtonDelete";
import ContratacionSecuenciaButtonCreate from "./ContratacionSecuenciaButtonCreate";



const useStyles = makeStyles((theme) => ({
    contratacion_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    contratacion_d: {display: 'inline-block', width: 800, marginLeft: 202},
    monto_desde_pesos: {display: 'inline-block', width: 200, marginLeft: 8},
    monta_hasta_pesos: {display: 'inline-block', width: 200, marginLeft: 202},
    ultimo_nro_usado: {display: 'inline-block', width: 200, marginLeft: 404},
}));

const ContratacionTipoEdit = props => {
    const classes = useStyles();


    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );

    const redirect = (basePath, id, data) => `/ContratacionTipo`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            <DeleteButton undoable={false} redirect={redirect}/>
        </Toolbar>
    );
    const transform = data => {
        return {
            ...data
        }
    };

    const PostTitle = ({record}) => {
        return <span>Contratacion Tipo: {record ? `${record.contratacion_tipo}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={<CustomToolbar/>}
            >
                {/*Datos*/}
                <FormTab label="DATOS CONTRATACION TIPO">

                    <TextInput
                        label="Código Contratacion Tipo"
                        source="contratacion_tipo"
                        formClassName={classes.contratacion_tipo}
                        placeholder="Ingrese Código Contratacion Tipo"
                        helperText="Código Contratacion Tipo"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />


                    <TextInput
                        label="Descripción Contratacion Tipo"
                        source="contratacion_d"
                        formClassName={classes.contratacion_d}
                        placeholder="Ingrese Descripción Contratacion Tipo"
                        helperText="Descripción Contratacion Tipo"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    <NumberInput
                        id="standard-full-width"
                        label="Monto Desde Pesos"
                        source="monto_desde_pesos"
                        formClassName={classes.monto_desde_pesos}
                        placeholder="Ingrese Monto Desde Pesos"
                        helperText="Monto Desde Pesos"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />


                    <NumberInput
                        id="standard-full-width"
                        label="Monta Hasta Pesos"
                        source="monta_hasta_pesos"
                        formClassName={classes.monta_hasta_pesos}
                        placeholder="Ingrese Monta Hasta Pesos"
                        helperText="Monta Hasta Pesos"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />



                    <NumberInput
                        id="standard-full-width"
                        label="Ultimo Nro Usado"
                        source="ultimo_nro_usado"
                        formClassName={classes.ultimo_nro_usado}
                        placeholder="Ingrese Ultimo Nro Usado"
                        helperText="Ultimo Nro Usado"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                </FormTab>
                {/*"Secuencia de la Contratación"*/}
                <FormTab label="SECUENCIA DE LA CONTRATACIÓN">
                    <ContratacionSecuenciaButtonCreate/>
                    <ReferenceManyField
                        sort={{ field: 'secuencia_item', order: 'ASC' }}
                        perPage={500}
                        reference="ContratacionSecuencia"
                        target="id"
                        addLabel={false}
                    >
                        <Datagrid
                            rowStyle={RowStyle}
                            // expand={<ContratacionSecuenciaInfo/>}
                        >
                            <TextField label="Item" source="secuencia_item"
                                       style={{textAlign: 'right', margin: '1em' }}
                                       options={{ minimumFractionDigits: 0 }}
                            />
                            <TextField label="Tipo" source="secuencia_tipo" />
                            <NumberField label="Dias" source="secuencia_dias"
                                         style={{textAlign: 'right', margin: '1em' }}
                                         options={{ minimumFractionDigits: 0 }}
                            />
                            <TextField label="Descripción" source="secuencia_d" cellClassName={classes.title}/>
                            <ContratacionSecuenciaButtonEdit label="" />
                            {/*<ContratacionSecuenciaButtonShow label="" />*/}
                            <ContratacionSecuenciaButtonDelete label="" />
                        </Datagrid>
                    </ReferenceManyField>

                </FormTab>
            </TabbedForm>
        </Edit>
    )
};

const RowStyle = (record, index) => ({
    backgroundColor: record.secuencia_tipo  === undefined || record.estado_color  === undefined ? '' :
        record.estado_color.trim(),
});

export default ContratacionTipoEdit;


