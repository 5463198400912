import React, {useState} from 'react'
import {
    Loading,
    useQuery,
    Datagrid,
    TextField,
    Pagination,
    DateField,
    useRefresh,
    useDelete,
    Button,
    useDataProvider,
    useNotify, usePermissions, WithPermissions
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import IconEliminar from "@material-ui/icons/Delete";
import AdjuntoGeneradoButton from "../componentes/AdjuntoGeneradoButton";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EliminarProveedorDocumentacionAdjunto from "./EliminarProveedorDocumentacionAdjunto"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ProveedorDocumentacionAdjuntoAgregar from "./ProveedorDocumentacionAdjuntoAgregar";
import ProveedorDocumentacionAdjuntoNuevo from "./ProveedorDocumentacionAdjuntoNuevo"
import BtnAgregarDocumentacion from "../proveedorDocumentacion/BtnAgregarDocumentacion";

const role_proveedor_agregar_archivo_documentacion_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_proveedor_agregar_archivo_documentacion = w_permissions_control.indexOf('ROLE_PROVEEDOR_AGREGAR_ARCHIVO_DOCUMENTACION')
        if (w_role_proveedor_agregar_archivo_documentacion === -1) {
        } else {
            w_role_control = 'ROLE_PROVEEDOR_AGREGAR_ARCHIVO_DOCUMENTACION'
        }
    }

    return w_role_control
}


const role_proveedor_eliminar_archivo_documentacion_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_proveedor_eliminar_archivo_documentacion = w_permissions_control.indexOf('ROLE_PROVEEDOR_ELIMINAR_ARCHIVO_DOCUMENTACION')
        if (w_role_proveedor_eliminar_archivo_documentacion === -1) {
        } else {
            w_role_control = 'ROLE_PROVEEDOR_ELIMINAR_ARCHIVO_DOCUMENTACION'
        }
    }

    return w_role_control
}

export const ProveedorDocumentacionAdjuntoList = (props) => {
    const {permissions} = usePermissions();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({field: props.sort_field, order: 'DESC'})

    console.log(props.record)
    console.log('props.sort_field')
    console.log(props.sort_field)

    var w_documentacion_ingresar_vencimiento =props.record.documentacion_ingresar_vencimiento;
    if(w_documentacion_ingresar_vencimiento==null){
        w_documentacion_ingresar_vencimiento=''
    }
    w_documentacion_ingresar_vencimiento=w_documentacion_ingresar_vencimiento.trim()

    console.log('w_documentacion_ingresar_vencimiento')
    console.log(w_documentacion_ingresar_vencimiento)

    const {data, total, loading, error, refetch } = useQuery({
        type: 'getList',
        resource: 'ProveedorDocumentacionAdjunto',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: {pro: props.record.pro, id_proveedor_documentacion: props.record.id,
                documentacion_tipo: props.record.documentacion_tipo},
        }
    });

    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    // Eliminar Fila
    const BtnEliminar_ant = ({record}) => {
        const refresh = useRefresh();

        const [deleteOne, {isLoading, error}] = useDelete(
            'ProveedorDocumentacionAdjunto',
            record.id
        );
        const handleClick = () => {
            deleteOne();
            refresh();
        };
        if (error) {
            return <p>ERROR</p>;
        }
        return <Button variant="text" size={"small"} color="primary"
                       onClick={handleClick}
                       disabled={loading}
                       label={'Eliminar'}
        >
            <IconEliminar style={{color: 'red'}}/>
        </Button>
    };


    // Eliminar Fila
    const BtnEliminar = ({record}) => {
        var wr_id = record.id

        const dataProvider = useDataProvider();
        const notify = useNotify();
        const refresh = useRefresh();
        const [openEliminar, setOpenEliminar] = React.useState(false);

        const handleClickOpenEliminar = () => {
            // dataProvider.getList(
            //     'ProveedorDocumentacionAdjuntoEliminar',
            //     {
            //         filter: {
            //             id_adjunto: wr_id
            //         },
            //         sort: {field: 'id', order: 'DESC'},
            //         pagination: {page: 1, perPage: 100},
            //     },)
            //     .then(({data}) => {
            //         var wec_validacion = data[0].validacion
            //         if(wec_validacion==null){
            //             wec_validacion=''
            //         }
            //         wec_validacion=wec_validacion.trim()
            //         var wec_mensaje = data[0].mensaje
            //         if (wec_validacion === 'E') {
            //             notify(wec_mensaje, 'info', {}, false);
            //         } else {
                        setOpenEliminar(true);
                //     }
                //
                // })
                // .catch(error => {
                //
                // })

        };

        const BotonEliminarDetelle = (record) => {

            const refresh = useRefresh();

            const [deleteOne, {isLoading, error}] = useDelete(
                'ProveedorDocumentacionAdjunto',
                wr_id
            );
            const handleClickEliminar = () => {
                deleteOne();
                refetch();
            };
            if (error) {
                return <p>ERROR</p>;
            }
            return (
                <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                        onClick={handleClickEliminar}>
                    <div title={''}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        Si
                    </div>
                </Button>
            );

        };

        const handleNoEliminar = () => {
            setOpenEliminar(false);
        };

        return (
            <div>
                <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                        onClick={handleClickOpenEliminar}>
                    <div title={'Eliminar Archivo de la Documentación'}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        <DeleteForeverIcon style={{color: '#c00000', backgroundColor: '#ffffff'}}/>
                    </div>
                </Button>
                <Dialog open={openEliminar} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Archivo</DialogTitle>
                    <DialogContent>
                        ¿Seguro que quiere eliminar este Archivo?
                    </DialogContent>
                    <DialogActions>
                        <BotonEliminarDetelle/>
                        <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                onClick={handleNoEliminar}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                NO
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    return (
        <div>
            <div style={{display: 'inline-block', width: '100%', marginLeft: 8}}>
                {/*<WithPermissions*/}
                {/*    render={({permissions}) => (*/}
                {/*        role_proveedor_agregar_archivo_documentacion_permissions({permissions}) === 'ROLE_PROVEEDOR_AGREGAR_ARCHIVO_DOCUMENTACION'*/}
                {/*            ?   <ProveedorDocumentacionAdjuntoAgregar*/}
                {/*                    record={props.record}*/}
                {/*                    refetch={refetch}*/}
                {/*                />*/}
                {/*            : null*/}
                {/*    )}*/}
                {/*/>*/}
                <WithPermissions
                    render={({permissions}) => (
                        role_proveedor_agregar_archivo_documentacion_permissions({permissions}) === 'ROLE_PROVEEDOR_AGREGAR_ARCHIVO_DOCUMENTACION'
                            ?   <ProveedorDocumentacionAdjuntoNuevo
                                record={props.record}
                                refetch={refetch}
                            />
                            : null
                    )}
                />
            </div>
            <Datagrid
                data={keyBy(data, 'id')}
                ids={data.map(({id}) => id)}
                currentSort={sort}
                setSort={(field, order) => setSort({field, order})}
            >
                {/*<AdjuntoGeneradoButton label={<div style={{textAlign: 'center'}}*/}
                {/*                                   title={"Descarga de Archivos"}><CloudDownloadIcon /></div>}/>*/}
                <AdjuntoGeneradoButton label="Descargar Archivo"/>
                {/*<TextField source="adjunto_nombre" label={"Adjunto Nombre"}/>*/}
                {/*<TextField source="documentacion_tipo" label={"Tipo"}/>*/}
                <DateField source="fecha_ingreso" label={"Fecha Ingreso"}/>
                {w_documentacion_ingresar_vencimiento === 'S' &&
                    <DateField source="fecha_vencimiento" label={"Fecha Vencimiento"}/>
                }
                {role_proveedor_eliminar_archivo_documentacion_permissions({permissions}) === 'ROLE_PROVEEDOR_ELIMINAR_ARCHIVO_DOCUMENTACION' &&
                <BtnEliminar label={"Eliminar Archivo"}/>}
                {/*<EliminarProveedorDocumentacionAdjunto/>*/}
            </Datagrid>
            <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                total={total}
            />

        </div>
    );
};

export default ProveedorDocumentacionAdjuntoList;



