import React from 'react'
import {
    Datagrid,
    List,
    TextField,
    TextInput,
    Filter, CardActions, WithPermissions, ExportButton,
} from 'react-admin';
import TextFieldColor from "../componentes/TextFieldColor";

const Filtros = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" variant="outlined" source="q" alwaysOn/>
        <TextInput label="Código Notificacion Tipo" source="notificacion_codigo" alwaysOn/>
        <TextInput label="Descripción Notificacion Tipo" source="notificacion_d" alwaysOn/>
    </Filter>
);

const role_exportar_csv = ({permissions}) => {
    var w_role_control = '';
    var w_permissions_control = permissions;
    if (w_permissions_control == null) {
    } else {
        var w_role_exportar_csv = w_permissions_control.indexOf('ROLE_EXPORTAR_CSV');
        if (w_role_exportar_csv === -1) {
        } else {
            w_role_control = 'ROLE_EXPORTAR_CSV'
        }
    }
    return w_role_control
};

const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}

        <WithPermissions
            render={({permissions}) => (
                role_exportar_csv({permissions}) === 'ROLE_EXPORTAR_CSV'
                    ?
                    <ExportButton/>
                    : null
            )}
        />
    </CardActions>
);

export const NotificacionTipoList = (props) => (
    <List {...props} title="Listado de Notificacion Tipo"
          bulkActionButtons={false}
          perPage={25}
          filters={<Filtros/>}
          actions={<PostActions/>}
    >

        <Datagrid expand={false}>

            <TextField label="Código Notificacion Tipo" source="notificacion_codigo"/>
            <TextField label="Descripción Notificacion Tipo" source="notificacion_d"/>
            <TextField label="Link Notificacion Tipo" source="link"/>
            {/*<TextFieldColor label="Imagen (Falta)" />*/}
            <TextFieldColor label="Color"/>

            {/*<ShowButton/>*/}
            {/*<EditButton/>*/}
        </Datagrid>
    </List>
);


export default NotificacionTipoList;
