import React, {useEffect, useState} from 'react';
import {useQuery, Loading, Error, useDataProvider, useNotify, useRefresh, useMutation, useRedirect} from 'react-admin';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import Typography from '@material-ui/core/Typography';
import SaveIcon from "@material-ui/icons/Save";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import StarIcon from '@material-ui/icons/Star';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import AvatarImage from "../componentes/Avatar/AvatarImage";
import '../componentes/Avatar/AvatarImage.css'
import Imagerta from "../layout/logos/RTA_WEB.svg";
import Imagetv from "../layout/logos/TVP_WEB.svg";
import AddAlertIcon from '@material-ui/icons/AddAlert';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import NotificationsPausedIcon from '@material-ui/icons/NotificationsPaused';
import PantallaMensaje from "../componentes/PantallaMensaje";

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);

const useNotificacionStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    root2: {
        width: '100%',
        maxWidth: 600,
        backgroundColor: theme.palette.background.paper,
    },
    rounded: {
        color: '#fff',
        backgroundColor: '#fff',
    },
}));

const NotificacionBadge = function (props) {
    const classesNotificacion = useNotificacionStyles();
    const [EstadoBotonNotificacion, setEstadoBotonNotificacion] = useState(1);
    const dataProviderNotificacionBadgeEstado = useDataProvider();
    const [NotificacionBadgeEstado, setNotificacionBadgeEstado] = useState([]);
    const [cantidad_notificacion_usu, setcantidad_notificacion_usu] = useState('');
    const [Nloadingotificacion, setNloadingotificacion] = useState(true);
    const [Nerrorotificacion, setNerrorotificacion] = useState();
    const [loadingNotificacionBadgeEstado, setLoadingNotificacionBadgeEstado] = useState(false);
    const [errorNotificacionBadgeEstado, setErrorNotificacionBadgeEstado] = useState();
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [openNotificacionBadge, setOpenNotificacionBadge] = React.useState(false);

    // const handleClick = (event) => {
    //     setLoadingNotificacionBadgeEstado(true);
    //     dataProviderNotificacionBadgeEstado.getList(
    //         'NotificacionBadgeDetalle',
    //         {
    //             filter: {filtros: 'N', ingreso_por: 'Z'},
    //             sort: {field: 'id', order: 'DESC'},
    //             pagination: {page: 1, perPage: 100},
    //         },)
    //         .then(({data}) => {
    //             setNotificacionBadgeEstado(data);
    //             setLoadingNotificacionBadgeEstado(false);
    //             setAnchorEl(true);
    //         })
    //         .catch(error => {
    //             setErrorNotificacionBadgeEstado(error);
    //             setLoadingNotificacionBadgeEstado(false);
    //         })
    //
    // };
    //
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const handleClickNotificacionBadge = (event) => {
        setLoadingNotificacionBadgeEstado(true);
        dataProviderNotificacionBadgeEstado.getList(
            'NotificacionBadgeDetalle',
            {
                filter: {filtros: 'N', ingreso_por: 'Z'},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setNotificacionBadgeEstado(data);
                setLoadingNotificacionBadgeEstado(false);
                setOpenNotificacionBadge(true);
                cambiarestadobotonNotificacion()
            })
            .catch(error => {
                setErrorNotificacionBadgeEstado(error);
                setLoadingNotificacionBadgeEstado(false);
            })

    };

    const handleCloseNotificacionBadge = () => {
        setOpenNotificacionBadge(false);
    };

    // const handleNotificacionBadge = () => {
    //     setAnchorEl(null);
    // };

    setTimeout(() => {
        cambiarestadobotonNotificacion()
    },60 * 2 * 1000);//Digamos que quieres esperar al menos 2 minutos


    useEffect(() => {
        dataProviderNotificacionBadgeEstado.getList(
            'NotificacionBadge',
            {
                filter: {filtros: 'N', ingreso_por: 'Z'},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                // console.log(data)
                // console.log(data[0].cantidad_notificacion_usu)
                setcantidad_notificacion_usu(data[0].cantidad_notificacion_usu);
                setNloadingotificacion(false);
            })
            .catch(error => {
                setNerrorotificacion(error);

            })
    }, [EstadoBotonNotificacion]);

    const cambiarestadobotonNotificacion = () => {
        setEstadoBotonNotificacion(EstadoBotonNotificacion + 1);
    }

    // const {data: NDataotificacion,loading: Nloadingotificacion,error: Nerrorotificacion} = useQuery(
    //     {
    //         type: 'getList',
    //         resource: 'NotificacionBadge',
    //         payload: {
    //             filter: { listado: 'S', tablerocontrol: 'S'},
    //             sort: { field: 'id', order: 'DESC'  },
    //             pagination: { page: 1, perPage: 1000 },
    //         },
    //     });
//----------------- chequea datos estado del componente -----------------------------
//     if (loading) return <Loading loadingPrimary="  " loadingSecondary="Actualizando Notificacion" />  // no cargo todavia

    if (Nloadingotificacion) return <PantallaMensaje
                            p_imagen={'Alertinfonotificacion'}
                            p_mensajePrimario={'C'}
                            p_mensajeSecondario={''}
                        />  // no cargo todavia

    if (!cantidad_notificacion_usu) return null   ;           // no cargo todavia

    if (Nerrorotificacion) {
        // console.log('error')
        // eslint-disable-next-line no-undef
        // console.log(Nerrorotificacion)

        // setNloadingotificacion(true);
        // dataProviderNotificacionBadgeEstado.getList(
        //     'NotificacionBadge',
        //     {
        //         filter: {filtros: 'N', ingreso_por: 'Z'},
        //         sort: {field: 'id', order: 'DESC'},
        //         pagination: {page: 1, perPage: 100},
        //     },)
        //     .then(({data}) => {
        //         // console.log(data)
        //         // console.log(data[0].cantidad_notificacion_usu)
        //         setcantidad_notificacion_usu(data[0].cantidad_notificacion_usu);
        //         setNloadingotificacion(false);
        //     })
        //     .catch(error => {
        //         // setNerrorotificacion(error);
        //
        //     })
        // return <Error/>;                           // error
    };

    // if (loadingNotificacionBadgeEstado) return <Loading/>;                // no cargo todavia

    if (loadingNotificacionBadgeEstado) return <PantallaMensaje
                                                    p_imagen={'Alertinfonotificacion'}
                                                    p_mensajePrimario={'D'}
                                                    p_mensajeSecondario={''}
                                                />;                // no cargo todavia

    if (errorNotificacionBadgeEstado) {
        return <Error/>;                           // error
    }
    ;

    const BotonActualizarDetelleNotificacion = (recordnotif) => {

        // console.log('BotonActualizarDetelleNotificacion')
        // console.log(recordnotif)
        // var w_mensaje = ' '
        // var w_notificacion_d_leyenda = recordnotif.notificacion_d_leyenda
        // var w_notificacion_fh = recordnotif.notificacion_fh
        // console.log('w_notificacion_d_leyenda')
        // console.log(w_notificacion_d_leyenda)

        const redirectToNotificacion = useRedirect();
        const notifyNotificacion = useNotify();
        const refreshNotificacion = useRefresh();

        const [actualizardetalleNotificacion, {loading}] = useMutation({
                type: 'update',
                resource: 'NotificacionBadgeDetalle',
                payload: {
                    id: recordnotif.id, data: {recordnotif}
                }
            },
            {
                onSuccess: ({dataNotificacion}) => {
                    cambiarestadobotonNotificacion()
                    refreshNotificacion()
                    redirectToNotificacion(`${recordnotif.link_notificacion}`);
                    setOpenNotificacionBadge(false);

                    // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//------------------- cambia el estado del componente para vover a renderizar ----------------

                },
                onFailure: errorRNotificacion => {
                    notifyNotificacion(`Error durante la Actualizacion: ${errorRNotificacion.message}`, "warning");
                },
            }
        );

        return (
            <ListItem button onClick={actualizardetalleNotificacion} style={{backgroundColor: recordnotif.estado_color}}>
                <ListItemIcon>
                    <Avatar variant="rounded" className={classesNotificacion.rounded}>
                        {recordnotif.notificacion_codigo==undefined || recordnotif.notificacion_codigo==null ? '':
                            recordnotif.notificacion_codigo.trim()=='100' ? <AddAlertIcon style={{color: '#ff0000'}}/> :
                                recordnotif.notificacion_codigo.trim()=='101' ? <NotificationsActiveIcon style={{color: '#ff0000'}}/> :
                                    recordnotif.notificacion_codigo.trim()=='102' ? <NotificationImportantIcon style={{color: '#ff0000'}}/> :
                                        recordnotif.notificacion_codigo.trim()=='103' ? <NotificationsPausedIcon style={{color: '#ff0000'}}/> :
                                            <NotificationsIcon style={{color: '#ff0000'}}/>}

                        {/*<div id={'containerChico'}>*/}
                            {/*<img src={recordnotif.imagen_nombre == 'TV' ? Imagerta : Imagetv}/>*/}
                            {/*<img src={recordnotif.imagen_nombre}/>*/}
                        {/*</div>*/}
                    </Avatar>
                </ListItemIcon>
                <ListItemText
                    primary={recordnotif.notificacion_d+": "}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classesNotificacion.inline}
                                color="textPrimary"
                            >
                                {recordnotif.notificacion_leyenda}
                            </Typography>
                            {" "+recordnotif.notificacion_fh}
                        </React.Fragment>
                    }
                />
            </ListItem>
        );
    };

    // var IniciousernameList1 = NDataotificacion
    // const aggregationsusernamenotificacion = NDataotificacion
    //     .reduce(
    //         (stats, order) => {
    //             stats.cantidad_notificacion_usu = order.cantidad_notificacion_usu
    //             // console.log(order)
    //             return stats;
    //         },
    //         {
    //             cantidad_notificacion_usu: 0,
    //         }
    //     );
    //
    // // const  nbIniciousernameList = IniciousernameList1.reduce(nb => ++nb, 0)
    // const  cantidad_notificacion_usu = aggregationsusernamenotificacion.cantidad_notificacion_usu
    return (
        <div>
            <IconButton aria-label="cart" onClick={handleClickNotificacionBadge}>
                <StyledBadge badgeContent={cantidad_notificacion_usu===undefined ? '': cantidad_notificacion_usu}
                             // max={999}
                             max={99}
                             style={{color: '#ffffff'}}>
                    <NotificationsIcon style={{color: '#ff0000'}}/>
                </StyledBadge>
            </IconButton>
            <Dialog open={openNotificacionBadge}  maxWidth={'xl'} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Notificación</DialogTitle>
                <DialogContent>
                    <List component="nav" className={classesNotificacion.root2} aria-label="contacts">
                        {NotificacionBadgeEstado.length == 0 ?
                            <ListItem button style={{backgroundColor: '#808080'}}>
                                <ListItemIcon>
                                    <Avatar variant="rounded" className={classesNotificacion.rounded}>
                                        {<NotificationsIcon style={{color: '#808080'}}/>}
                                    </Avatar>
                                </ListItemIcon>
                                <ListItemText
                                    primary={"Sin Notificacion"}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classesNotificacion.inline}
                                                color="textPrimary"
                                            >
                                                {"Estas al dia"}
                                            </Typography>
                                            {"..."}
                                        </React.Fragment>
                                    }
                                />
                            </ListItem>
                            :
                            NotificacionBadgeEstado.map(recordnotifcacion => {
                            return (
                                <BotonActualizarDetelleNotificacion
                                    id={recordnotifcacion.id}
                                    notificacion_d_leyenda={recordnotifcacion.notificacion_d_leyenda}
                                    notificacion_fh={recordnotifcacion.notificacion_fh}
                                    link_notificacion={recordnotifcacion.link_notificacion}
                                    notificacion_d={recordnotifcacion.notificacion_d}
                                    notificacion_leyenda={recordnotifcacion.notificacion_leyenda}
                                    estado_color={recordnotifcacion.estado_color}
                                    imagen_nombre={recordnotifcacion.imagen_nombre}
                                    notificacion_codigo={recordnotifcacion.notificacion_codigo}
                                />
                            );
                        })}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleCloseNotificacionBadge}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <ExitToAppIcon style={{color: '#004187'}}/>
                            Salir
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );
}

export default NotificacionBadge;