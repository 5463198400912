import * as React from "react";
import {Create, TabbedForm, FormTab, TextInput} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    // razon_social: {display: 'inline-block', width: 400},
    // razon_social_fantasia: {display: 'inline-block', width: 400, marginLeft: 32},
}));

// export const ProveedorCreate = withStyles(styles)(({classes, ...props}) => (
const ProveedorSocioCreate = props => {
    const classes = useStyles();
    return (
        <Create {...props}>
            <TextInput label="Proveedor" source="pro"/>
            <TextInput label="Pais" source="identificacion_tributaria_pais_origen"/>
            <TextInput label="Tipo" source="identificacion_tributaria_tipo"/>
            <TextInput label="Número" source="identificacion_tributaria_nro"/>
        </Create>
    )
};
export default ProveedorSocioCreate;
