import React, {useState} from 'react';
import {
    useNotify, useRefresh, useMutation, ReferenceInput, SelectInput, useDataProvider, NumberInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import {Drawer} from "@material-ui/core";
import IconCancelar from '@material-ui/icons/Close';
import IconEdit from '@material-ui/icons/Edit';

const useStyles = makeStyles({
    socio_nro_ingreso: {display: 'inline-block', width: 100},
    tipoDocConyuge: {display: 'inline-block', width: 150,},
    nombre: {display: 'inline-block', width: 150,},
    apellido: {display: 'inline-block', width: 150, marginLeft: 28},

    documento_tipo: {display: 'inline-block', width: 80},
    documento_nro: {display: 'inline-block', width: 120, marginLeft: 28},
    cuit_cuil: {display: 'inline-block', width: 150, marginLeft: 28},

    correo_electronico: {display: 'inline-block', width: 350},

    limite_pesos: {display: 'inline-block', width: 180, marginLeft: 28},

    estado_civil: {display: 'inline-block', width: 120},
    socio_activo: {display: 'inline-block', width: 80},
});

const ProveedorRepresentanteEditar = ({record}) => {
        const classes = useStyles()
        const dataProviderTableroControl = useDataProvider();
        const [open, setOpen] = React.useState(false);

        const [idRepre, setIdRepre] = useState(record.id);
        const [proveedor, setEstadoproveedor] = useState('');

        const [usuario_ingreso_sistema, setUsuario_ingreso_sistema] = useState('');
        const [usuario_ingreso_sistema_clave, setUsuario_ingreso_sistema_clave] = useState('');

        const [nombre_repre, setNombre_repre] = useState('');
        const [apellido_repre, setApellido_repre] = useState('');
        const [cuit_cuil_repre, setCuit_cuil_repre] = useState('');
        const [id_documento_tipo_repre, setId_documento_tipo_repre] = useState('');
        const [documento_nro_repre, setDocumento_nro_repre] = useState('');
        const [correo_electronico_repre, setCorreo_electronico_repre] = useState('');
        const [correo_electronico_alternativo_repre, setCorreo_electronico_alternativo_repre] = useState('');

        const [limite_para_oferta_electronica, setLimite_para_oferta_electronica] = useState('');
        const [limite_para_oferta_electronica_pesos, setLimite_para_oferta_electronica_pesos] = useState(record.apellido_repre);
        const [es_administrador_legitimado, setEs_administrador_legitimado] = useState('');
        const [representante_activo, setRepresentante_activo] = useState('');

        const BtnCancelar = ({record}) => (
            <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
                setOpen(false)
            }}
            >
                <IconCancelar/>
                Volver
            </Button>
        );

        const BotonCrearDetelle = (props) => {
            const notify = useNotify();
            const refresh = useRefresh();

            const [generardetelle, {loading}] = useMutation({
                    type: 'update',
                    resource: 'ProveedorRepresentante',
                    payload: {
                        id: idRepre,
                        data: {
                            proveedor,
                            usuario_ingreso_sistema,
                            usuario_ingreso_sistema_clave,
                            nombre_repre,
                            apellido_repre,
                            cuit_cuil_repre,
                            id_documento_tipo_repre,
                            documento_nro_repre,
                            correo_electronico_repre,
                            correo_electronico_alternativo_repre,
                            limite_para_oferta_electronica,
                            limite_para_oferta_electronica_pesos,
                            es_administrador_legitimado,
                            representante_activo,
                        }
                    }
                },
                {
                    onSuccess: ({data}) => {
                        refresh()
                    },
                    onFailure: error => {
                        notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                    },
                }
            );


            return (
                <Button variant="outlined" color="primary" onClick={generardetelle}
                        disabled={props.pristine}>
                    <div title={''}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                         }}>
                        <SaveIcon style={{color: '#004187'}}/>
                        Guardar
                    </div>
                </Button>
            );

        };

        /// JN Mascara para el input cuit-cuil
        const cuitCuilFormat = value => {
            if (value == null || value === '') return '';

            //normalize string and remove all unnecessary characters
            var valueCuit = value.replace(/[^\d]/g, "");

            //check if number length equals to 11
            if (valueCuit.length == 11) {
                return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
            }

            return value
        };

        function handleTextInputChange({target: {name, value}}) {
            // setState(prevState => ({...prevState, [name]: value}));
        }


        const handleClickOpen = () => {
            setIdRepre(record.id);
            setEstadoproveedor(record.proveedor);
            setUsuario_ingreso_sistema(record.usuario_ingreso_sistema);
            setUsuario_ingreso_sistema_clave(record.usuario_ingreso_sistema_clave);
            setNombre_repre(record.nombre);
            setApellido_repre(record.apellido);
            setCuit_cuil_repre(record.cuit_cuil);
            setId_documento_tipo_repre(record.id_documento_tipo);
            setDocumento_nro_repre(record.documento_nro);
            setCorreo_electronico_repre(record.correo_electronico);
            setCorreo_electronico_alternativo_repre(record.correo_electronico_alternativo);
            setLimite_para_oferta_electronica(record.limite_para_oferta_electronica);
            setLimite_para_oferta_electronica_pesos(record.limite_para_oferta_electronica_pesos);
            setEs_administrador_legitimado(record.es_administrador_legitimado);
            setRepresentante_activo(record.representante_activo);

            setOpen(true);
        };
        const formatIdTipoDoc = v => {
            return id_documento_tipo_repre

        };

        const formatActivo = v => {
            return representante_activo
        };

        const formatAdmLegitimado = v => {
            return es_administrador_legitimado
        };

        const formatLimiteOferta = v => {
            return limite_para_oferta_electronica
        };

        return (
            <div>
                <Button variant="text" style={{color: '#123B80'}} size={"small"} onClick={handleClickOpen}>
                    <IconEdit/>
                    Editar
                </Button>
                <Drawer
                    classes={{paper: classes.paper}}
                    variant="persistent"
                    anchor="right"
                    open={open}
                >
                    <div>
                        <h3>Editar Socio</h3>
                    </div>
                    <br/>
                    <div style={{paddingLeft: "10px"}}>

                        <TextField source={"nombre_repre"} label={"Nombre"} variant={"outlined"}
                                   InputLabelProps={{shrink: true}}
                                   value={nombre_repre} onChange={(event) => setNombre_repre(event.target.value)}
                                   className={classes.nombre} fullWidth/>

                        <TextField source={"apellido_repre"} label={"Apellido"} variant={"outlined"}
                                   value={apellido_repre} onChange={(event) => setApellido_repre(event.target.value)}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.apellido} fullWidth/><br/> <br/>

                        <ReferenceInput reference="DocumentoTipo" variant="outlined"  source="id_documento_tipo_repre"
                                        label="Tipo Doc." value={id_documento_tipo_repre}
                                        className={classes.documento_tipo} sort={{field: 'orden', order: 'DESC'}}
                                        inputProps={{
                                            onChange: (e) => {
                                                setId_documento_tipo_repre(e.target.value);
                                            }
                                        }}
                                        format={formatIdTipoDoc}
                        >
                            <SelectInput InputLabelProps={{shrink: true}} optionText="documento_tipo_d" fullWidth={true}
                                         className={classes.documento_tipo_d}
                            />
                        </ReferenceInput>

                        <TextField source="documento_nro_repre" label={"Nro. Doc."} variant="outlined"
                                   value={documento_nro_repre}
                                   InputLabelProps={{shrink: true}}
                                   onChange={(event) => setDocumento_nro_repre(event.target.value)}
                                   className={classes.documento_nro} fullWidth={true}/>

                        <TextField source="cuit_cuil_repre" label={"CUIT/CUIL"} variant="outlined"
                                   InputLabelProps={{shrink: true}} value={cuit_cuil_repre}
                                   className={classes.cuit_cuil} fullWidth={true}
                                   format={cuitCuilFormat}
                                   onChange={(event) => setCuit_cuil_repre(event.target.value)}
                        /><br/>

                        <TextField source={"correo_electronico_repre"} label={"correo_electronico"} variant={"outlined"}
                                   InputLabelProps={{shrink: true}} className={classes.correo_electronico}
                                   value={correo_electronico_repre}
                                   onChange={(event) => setCorreo_electronico_repre(event.target.value)}
                                   fullWidth/>
                        <br/> <br/>

                        <TextField source={"correo_electronico_alternativo_repre"} label={"correo_electronico_alternativo"}
                                   variant={"outlined"} InputLabelProps={{shrink: true}}
                                   value={correo_electronico_alternativo_repre}
                                   className={classes.correo_electronico}
                                   onChange={(event) => setCorreo_electronico_alternativo_repre(event.target.value)}
                                   fullWidth/><br/> <br/>

                        <SelectInput value={limite_para_oferta_electronica}
                                     label="¿Límite oferta Elect.?" InputLabelProps={{shrink: true}}
                                     className={classes.socio_activo} variant="outlined"
                                     source={"limite_para_oferta_electronica_repre"}
                                     inputProps={{
                                         onChange: (e) => {
                                             setLimite_para_oferta_electronica(e.target.value);
                                         }
                                     }}
                                     format={formatLimiteOferta} fullWidth={true}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}
                        />
                        {limite_para_oferta_electronica === 'S' &&
                        <NumberInput
                            label={"Límite en pesos"}
                            variant={"outlined"} InputLabelProps={{shrink: true}}
                            value={limite_para_oferta_electronica_pesos}
                            className={classes.limite_pesos}
                            onChange={(event) => setLimite_para_oferta_electronica_pesos(event.target.value)}
                            fullWidth
                            source={"limite_para_oferta_electronica_pesos"}
                        />
                        }
                        <br/>
                        <SelectInput source="es_administrador_legitimado" value={es_administrador_legitimado}
                                     label="¿Adm. Legitimado?" InputLabelProps={{shrink: true}}
                                     className={classes.socio_activo} variant="outlined"
                                     source={"es_administrador_legitimado"}
                                     inputProps={{
                                         onChange: (e) => {
                                             setEs_administrador_legitimado(e.target.value);
                                         }
                                     }}
                                     format={formatAdmLegitimado} fullWidth={true}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}
                        />

                        <br/>
                        <SelectInput source="representante_activo" value={representante_activo}
                                     label="Activo" InputLabelProps={{shrink: true}}
                                     className={classes.socio_activo} variant="outlined"
                                     inputProps={{
                                         onChange: (e) => {
                                             setRepresentante_activo(e.target.value);
                                         }
                                     }}
                                     format={formatActivo} fullWidth={true}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}
                        />


                        <div>
                            <BotonCrearDetelle/>

                            <BtnCancelar/>
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }
;

export default ProveedorRepresentanteEditar;



