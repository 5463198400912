import React, {useState} from 'react';
import {
    useQuery,
    Datagrid,
    TextField,
    Loading,
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import IconSeleccionar from '@material-ui/icons/Done';
import {makeStyles} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import IconCancelar from '@material-ui/icons/Close';

export const EstadoOrdenCompraBusquedaDatagrid = (props) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(12);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'});

    const useStyles = makeStyles({
        button: {},
    });
    const classes = useStyles();

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'EstadoOrdenCompraCambio',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: {estado_anterior: props.estado},
        }
    });
// 10.10.0.65
    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    /*Botón seleccionar registro*/
    const BtnConfirmar = ({record}) => (
        <IconButton style={{fontSize: 15, padding: 2}}
                    onClick={() => {
                        props.callbackEstadoOrdenCompra(record);
                        props.callbackCloseDrawer(false)
                    }}
        >

            <IconSeleccionar style={{fontSize: 20}}/>
        </IconButton>
    );

    const BtnVolver = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" onClick={() => {
            props.callbackCloseDrawer(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );


    return (
        <div style={{margin: '3em'}}>
            <h3>SELECCIONAR NUEVO ESTADO</h3>
            {data.length === 0 ? (
                <h4>No hay opciones disponibles</h4>
            ) : (
                <Datagrid
                    data={keyBy(data, 'id')}
                    ids={data.map(({id}) => id)}
                    currentSort={sort}
                    setSort={(field, order) => setSort({field, order})}
                >
                    <BtnConfirmar/>
                    <TextField label="Estado" source="estado_nuevo"/>
                    <TextField label="Descripción" source="estado_d"/>
                </Datagrid>
            )}

            <BtnVolver/>
        </div>
    )
};
export default EstadoOrdenCompraBusquedaDatagrid;