import React from 'react';
import compose from 'recompose/compose';
import List from '@material-ui/core/List';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {DateField, translate} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from "@material-ui/core/TextField";
import NumberFormat from "react-number-format";
import DialogContent from "@material-ui/core/DialogContent";
import TableHead from "@material-ui/core/TableHead";

const styles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const VerInfoDetalle = ({
                            visitors = [], nb, translate,
                            ver_item,
                            ver_concepto_requerimiento,
                            ver_clase,
                            ver_renglon_nro,
                            ver_renglon_descripcion,
                            ver_unidad_medida,
                            ver_unidad_medida_abre,
                            ver_cantidad,
                            ver_fecha_entrega,
                            ver_moneda,
                            ver_cotizacion,
                            ver_precio_unitario_sin_iva,
                            ver_descuento_porcentaje,
                            ver_precio_unitario_sin_iva_con_dto,
                            ver_iva_porcentaje,
                            ver_iva_importe,
                            ver_total_item,
                            ver_total_importe_iva_pesos,
                            ver_total_item_pesos,
                            classes
                        }) => (
    <div>
        <form>
            <label>
                <Table  aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TextField label="Item" value={ver_item}
                                           inputProps={{readOnly: true}}
                                           style={{textAlign: 'right', margin: '1em' }}
                                           options={{ minimumFractionDigits: 0 }} />
                            </TableCell>
                            <TableCell>
                                <TextField  label="Concepto" value={ver_concepto_requerimiento}
                                            SelectProps={{native: true,}}
                                            inputProps={{readOnly: true}}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField  label="Clase" value={ver_clase}
                                            SelectProps={{native: true,}}
                                            inputProps={{readOnly: true}}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField label="Renglon" value={ver_renglon_nro}
                                           SelectProps={{native: true,}}
                                           inputProps={{readOnly: true}}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <Table  aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    label="Renglon Descripción"
                                    value={ver_renglon_descripcion}
                                    multiline
                                    fullWidth
                                    SelectProps={{native: true,}}
                                    inputProps={{readOnly: true}}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <Table  aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {/*<TextField label="Uni.Med." value={ver_unidad_medida} SelectProps={{*/}
                                {/*    native: true,*/}
                                {/*}}/>*/}
                                {/*<TextField label="Uni.Abr." value={ver_unidad_medida_abre} SelectProps={{*/}
                                {/*    native: true,*/}
                                {/*}}/>*/}
                                <TextField label="Uni.Med." inputProps={{readOnly: true}}
                                           value={ver_unidad_medida_abre} SelectProps={{
                                    native: true,
                                }}/>
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    customInput={TextField} label="Cantidad" value={ver_cantidad}
                                    inputProps={{readOnly: true}}
                                    style={{textAlign: 'right', margin: '1em' }}
                                    options={{ minimumFractionDigits: 2 }} />
                                <br/>
                            </TableCell>
                            <TableCell>
                                {/*<DateField label="Fec.Entrega" value={ver_fecha_entrega} SelectProps={{*/}
                                {/*    native: true,*/}
                                {/*}}/>*/}
                                <TextField
                                    id="date"
                                    label="Fec.Entrega"
                                    type="date"
                                    defaultValue={ver_fecha_entrega}
                                    inputProps={{readOnly: true}}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <Table  aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>

                            </TableCell>
                            <TableCell>
                                <TextField label="Moneda" value={ver_moneda} SelectProps={{
                                    native: true,
                                }}/>
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    customInput={TextField} label="Cotización" value={ver_cotizacion}
                                    inputProps={{readOnly: true}}
                                    style={{textAlign: 'right', margin: '1em' }}
                                    options={{ minimumFractionDigits: 5 }} />
                                <br/>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <Table  aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <NumberFormat
                                    customInput={TextField} label="PU s/IVA" value={ver_precio_unitario_sin_iva}
                                    inputProps={{readOnly: true}}
                                    style={{textAlign: 'right', margin: '1em' }}
                                    options={{ minimumFractionDigits: 2 }} />
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    customInput={TextField} label="Dto. %" value={ver_descuento_porcentaje}
                                    inputProps={{readOnly: true}}
                                    style={{textAlign: 'right', margin: '1em' }}
                                    options={{ minimumFractionDigits: 2 }} />
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    customInput={TextField} label="PU C/Dto." value={ver_precio_unitario_sin_iva_con_dto}
                                    inputProps={{readOnly: true}}
                                    style={{textAlign: 'right', margin: '1em' }}
                                    options={{ minimumFractionDigits: 2 }} />
                                <br/>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <Table  aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <NumberFormat
                                    customInput={TextField} label="IVA %" value={ver_iva_porcentaje}
                                    inputProps={{readOnly: true}}
                                    // inputProps={{readOnly: true}}
                                    style={{textAlign: 'right', margin: '1em' }}
                                    options={{ minimumFractionDigits: 2 }} />
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    customInput={TextField} label="IVA Importe" value={ver_iva_importe}
                                    inputProps={{readOnly: true}}
                                    style={{textAlign: 'right', margin: '1em' }}
                                    options={{ minimumFractionDigits: 2 }} />
                            </TableCell>
                            <TableCell>
                                {''}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <Table  aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <NumberFormat
                                    customInput={TextField} label="Total Ítem" value={ver_total_item}
                                    inputProps={{readOnly: true}}
                                    style={{textAlign: 'right', margin: '1em' }}
                                    options={{ minimumFractionDigits: 2 }} />
                            </TableCell>
                            <TableCell>

                            </TableCell>
                            <TableCell>

                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <Table  aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {''}
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    customInput={TextField} label="Total IVA $" value={ver_total_importe_iva_pesos}
                                    inputProps={{readOnly: true}}
                                    style={{textAlign: 'right', margin: '1em' }}
                                    options={{ minimumFractionDigits: 2 }} />
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    customInput={TextField} label="Total Ítem $" value={ver_total_item_pesos}
                                    inputProps={{readOnly: true}}
                                    style={{textAlign: 'right', margin: '1em' }}
                                    options={{ minimumFractionDigits: 2 }} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </label>
        </form>
    </div>

);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(VerInfoDetalle);

