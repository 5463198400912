import React, {useCallback, useState, useEffect} from 'react';
import {
    Datagrid,
    EditButton,
    List,
    TextField,
    TextInput,
    Filter,
    DateField,
    DateInput,
    NumberField,
    ReferenceInput, SelectInput, ShowButton, TopToolbar, WithPermissions,
    useRedirect, useNotify, useRefresh, useDataProvider
} from 'react-admin';
import LinkEditButton from "./LinkEditButton"
import OrdenCompraCabeceraInfo from "./OrdenCompraCabeceraInfo"
import AuditoriaButton from "../componentes/AuditoriaButton"
import withStyles from "@material-ui/core/styles/withStyles";
import ExportExcelButton from "./ExportExcelButton";
import OrdenCompraPdfGeneradoButton from "./OrdenCompraPdfGeneradoButton";
import EstadoOCActualizarBtn from "../EstadoOrdenCompra/EstadoOCActualizarBtn";

const Filtros = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" variant="outlined" source="q" alwaysOn/>
        <TextInput label="Número" source="orden_compra_nro" alwaysOn/>
        <TextInput label="Razón Social" source="razon_social" alwaysOn/>
        {/*<TextInput label="Año" source="orden_compra_ano" alwaysOn  />*/}
        <ReferenceInput label='Año'
                        source="id_orden_compra_ano"
                        reference="Ano"
                        onChange={(event, username, record) => console.log(`New deviceId `)}
                        alwaysOn
        >
            <SelectInput
                optionText="orden_compra_ano_d"
            />
        </ReferenceInput>
        {/*<TextInput label="Unidad" source="unidad_negocio_d" alwaysOn />*/}
        <ReferenceInput label='Unidad'
                        source="id_unidad_negocio"
                        reference="UnidadNegocio"
                        onChange={(event, username, record) => console.log(`New deviceId `)}
                        alwaysOn
        >
            <SelectInput
                optionText="unidad_negocio_d"
            />
        </ReferenceInput>
        {/*<TextInput label="Orden Compra Estado" source="orden_compra_estado" alwaysOn  />*/}

        <ReferenceInput label='Orden Compra Estado'
                        source="id_estado_orden_compra"
                        reference="EstadoOrdenCompra"
                        onChange={(event, username, record) => console.log(`New deviceId `)}
                        alwaysOn
        >
            <SelectInput
                optionText="estado"
            />
        </ReferenceInput>


        <TextInput label="Apl." source="orden_compra_ampliacion_nro"/>
        <DateInput label="Fecha - Desde" variant="outlined" source="orden_compra_fecha_desde"/>
        <DateInput label="Fecha - Hasta" variant="outlined" source="orden_compra_fecha_hasta"/>
        <TextInput label="Proveedor" source="proveedor_rta"/>
        <TextInput label="IVA Tipo" source="abre_iva_proveedor"/>
        <TextInput label="IVA Categoría" source="d_iva_proveedor"/>
        <TextInput label="Cuit-Cuil" source="cuit_cuil"/>
        <TextInput label="Emisora" source="emisora_d"/>
        <TextInput label="Area" source="area_d"/>
        <TextInput label="Gerencia" source="gerencia_d"/>
        <TextInput label="Sector" source="sector_d"/>
        <TextInput label="Programa" source="programa_d"/>
        <TextInput label="Tipo" source="contratacion_tipo"/>
        <TextInput label="Moneda" source="moneda_abre"/>
    </Filter>
);

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};

const role_orden_compra_consulta_actualizar_estado_permissions = ({permissions}) => {
    var w_role_orden_compra_consulta_actualizar_estado_permissions = ''
    var w_permissions_orden_compra_consulta_actualizar_estado = permissions
    if (w_permissions_orden_compra_consulta_actualizar_estado == null) {
    } else {
        var w_role_orden_compra_consulta_actualizar_estado = w_permissions_orden_compra_consulta_actualizar_estado.indexOf('ROLE_ORDEN_COMPRA_CONSULTA_ACTUALIZAR_ESTADO')
        if (w_role_orden_compra_consulta_actualizar_estado === -1) {
        } else {
            w_role_orden_compra_consulta_actualizar_estado_permissions = 'ROLE_ORDEN_COMPRA_CONSULTA_ACTUALIZAR_ESTADO'
        }
    }

    return w_role_orden_compra_consulta_actualizar_estado_permissions
}

const role_orden_compra_consulta_pdf_generado_permissions = ({permissions}) => {
    var w_role_orden_compra_consulta_pdf_generado_permissions = ''
    var w_permissions_orden_compra_consulta_pdf_generado = permissions
    if (w_permissions_orden_compra_consulta_pdf_generado == null) {
    } else {
        var w_role_orden_compra_consulta_pdf_generado = w_permissions_orden_compra_consulta_pdf_generado.indexOf('ROLE_ORDEN_COMPRA_CONSULTA_PDF_GENERADO')
        if (w_role_orden_compra_consulta_pdf_generado === -1) {
        } else {
            w_role_orden_compra_consulta_pdf_generado_permissions = 'ROLE_ORDEN_COMPRA_CONSULTA_PDF_GENERADO'
        }
    }

    return w_role_orden_compra_consulta_pdf_generado_permissions
}


const role_auditoria_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_auditoria = w_permissions_control.indexOf('ROLE_AUDITORIA')
        if (w_role_auditoria === -1) {
        } else {
            w_role_control = 'ROLE_AUDITORIA'
        }
    }

    return w_role_control
}

const role_orden_compra_consulta_mostrar_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_orden_compra_consulta_mostrar = w_permissions_control.indexOf('ROLE_ORDEN_COMPRA_CONSULTA_MOSTRAR')
        if (w_role_orden_compra_consulta_mostrar === -1) {
        } else {
            w_role_control = 'ROLE_ORDEN_COMPRA_CONSULTA_MOSTRAR'
        }
    }

    return w_role_control
}

const role_orden_compra_consulta_editar_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_orden_compra_consulta_editar = w_permissions_control.indexOf('ROLE_ORDEN_COMPRA_CONSULTA_EDITAR')
        if (w_role_orden_compra_consulta_editar === -1) {
        } else {
            w_role_control = 'ROLE_ORDEN_COMPRA_CONSULTA_EDITAR'
        }
    }

    return w_role_control
}

const role_exportar_xls = ({permissions}) => {
    var w_role_control = '';
    var w_permissions_control = permissions;
    if (w_permissions_control == null) {
    } else {
        var w_role_exportar_xls = w_permissions_control.indexOf('ROLE_EXPORTAR_XLS');
        if (w_role_exportar_xls === -1) {
        } else {
            w_role_control = 'ROLE_EXPORTAR_XLS'
        }
    }
    return w_role_control
};

const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <TopToolbar>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <WithPermissions
            render={({permissions}) => (
                role_exportar_xls({permissions}) === 'ROLE_EXPORTAR_XLS'
                    ?
                    <ExportExcelButton total={999999} fileName={"OrdenDeCompra"} sheetName={"OC"}/>
                    : null

            )}
        />
    </TopToolbar>
);

export const OrdenCompraCabeceraList = withStyles(styles)(({classes, permissions, ...props}) => {

    return (
        <div>
            <div style={{margin: '1em'}}>
                <List {...props} title="Listado de Orden Compra"
                      bulkActionButtons={false}
                      perPage={10}
                      empty={false}
                      filters={<Filtros/>}
                      actions={<PostActions/>}
                    // sort={{field: 'id', order: 'desc'}}
                >

                    <Datagrid rowStyle={RowStyle} expand={<OrdenCompraCabeceraInfo/>}>
                        <TextField label="Id" source="id"/>
                        <TextField label="Número" source="orden_compra_nro"/>
                        <TextField label="Apl." source="orden_compra_ampliacion_nro"/>
                        <DateField label="Fecha" source="orden_compra_fecha"/>
                        <TextField label="Año" source="orden_compra_ano"/>
                        <TextField label="Proveedor" source="proveedor_rta"/>
                        <TextField label="Razón Social" source="razon_social"/>
                        <TextField label="IVA Tipo" source="abre_iva_proveedor"/>
                        <TextField label="IVA Categoría" source="d_iva_proveedor"/>
                        <TextField label="Cuit-Cuil" source="cuit_cuil"/>
                        <TextField label="Unidad" source="unidad_negocio_d"/>
                        <TextField label="Emisora" source="emisora_d"/>
                        <TextField label="Area" source="area_d"/>
                        <TextField label="Gerencia" source="gerencia_d"/>
                        <TextField label="Sector" source="sector_d"/>
                        <TextField label="Programa" source="programa_d"/>
                        <TextField label="Tipo" source="contratacion_tipo"/>
                        <TextField label="Moneda" source="moneda_abre"/>
                        <NumberField label="Importe Total" source="importe_total"
                                     style={{textAlign: 'right', margin: '1em'}}
                                     options={{minimumFractionDigits: 2}}/>
                        <NumberField label="Importe Total Pesos" source="importe_total_pesos"
                                     style={{textAlign: 'right', margin: '1em'}}
                                     options={{minimumFractionDigits: 2}}/>
                        <TextField label="Es Apl." source="es_ampliacion"/>
                        <TextField label="Estado" source="orden_compra_estado"/>
                        {role_orden_compra_consulta_mostrar_permissions({permissions}) === 'ROLE_ORDEN_COMPRA_CONSULTA_MOSTRAR' &&
                        <ShowButton label="Mostrar"/>
                        }
                        {role_orden_compra_consulta_editar_permissions({permissions}) === 'ROLE_ORDEN_COMPRA_CONSULTA_EDITAR' &&
                        <LinkEditButton label="Editar"/>
                        }
                        {/*<EditButton label="Editar"/>*/}
                        {role_orden_compra_consulta_actualizar_estado_permissions({permissions}) === 'ROLE_ORDEN_COMPRA_CONSULTA_ACTUALIZAR_ESTADO' &&
                        <EstadoOCActualizarBtn label={"Actualizar Estado"}/>
                        }
                        {role_auditoria_permissions({permissions}) === 'ROLE_AUDITORIA' &&
                        <AuditoriaButton label="Auditoria"/>
                        }
                        {role_orden_compra_consulta_pdf_generado_permissions({permissions}) === 'ROLE_ORDEN_COMPRA_CONSULTA_PDF_GENERADO' &&
                        <OrdenCompraPdfGeneradoButton label="PDF Generado"/>
                        }
                        {/*<TextField label="Parametro Path" source="parametro_path"/>*/}
                        {/*<TextField label="pdf_generado_nombre" source="pdf_generado_nombre"/>*/}
                    </Datagrid>
                </List>
            </div>
        </div>
    )
});


// const RowStyle = (record, index) => ({
//     backgroundColor: record.orden_compra_estado  === undefined ? '' :
//         record.orden_compra_estado.trim() === 'ACTIVADO' ? '#c8ff40' :
//             record.orden_compra_estado.trim() === 'APROBADO' ? '#008000' :
//                 record.orden_compra_estado.trim() === 'CANCELADO' ? '#cdcdcd' :
//                     record.orden_compra_estado.trim() === 'CERRADO' ? '#7bb6b8' :
//                         record.orden_compra_estado.trim() === 'EN-APROBACION' ? '#009aff' :
//                             record.orden_compra_estado.trim() === 'EN-PROGRESO' ? '#ff8000' :
//                                 record.orden_compra_estado.trim() === 'EN-REVISION' ? '#c1004a' :
//                                     record.orden_compra_estado.trim() === 'EN-SUSPENSO' ? '#f2ff00' :
//                                         record.orden_compra_estado.trim() === 'REVISADO' ? '#8fcb3a' : 'white',
// });

const RowStyle = (record, index) => ({
    backgroundColor: record.orden_compra_estado === undefined || record.estado_color === undefined ? '' :
        record.estado_color.trim(),
});


export default OrdenCompraCabeceraList;
