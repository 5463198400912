import {SelectInput, ReferenceInput} from 'react-admin';
import React from "react";

const ComboActivoMoneda = (props) => {
    const isReadonly = props.readOnly;
    const isvariant = props.variant;
    return (
        <ReferenceInput label="Moneda" source="id_moneda" reference="MonedaActivo"
                        // variant="outlined"
                        variant={isvariant===undefined || isvariant===null ? 'outlined' : isvariant.trim()}
        >
            <SelectInput optionText="moneda_d_abre" InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: isReadonly}}/>
        </ReferenceInput>
    )
};

export default ComboActivoMoneda;
