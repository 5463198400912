import React, {useCallback, useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput, SaveButton, TopToolbar
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import RubroBusquedaBtn from "../Rubro/RubroBusquedaBtn";
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    btnBusqueda: {display: 'inline-block', width: 40, marginLeft: 48},
    clase: {display: 'inline-block', width: 200, marginLeft: 8},
    clase_d: {display: 'inline-block', width: 600, marginLeft: 108},
    rubro: {display: 'inline-block', width: 200, marginLeft: 8},
    rubro_d: {display: 'inline-block', width: 600, marginLeft: 22},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};


    var w_clase = values.clase

    var w_rubro = values.rubro

    if (w_clase===undefined) {
        errors.clase = ['El campo Código es requerido'];
    }else {
        if (w_clase === null) {
            errors.clase = ['El campo Código es requerido'];
        } else {
            w_clase = w_clase.trim()
            if (w_clase === '') {
                errors.clase = ['El campo Código es requerido'];
            }
        }
    }

    if (w_rubro===undefined) {
        errors.rubro = ['El campo Código es requerido'];
    }else {
        if (w_rubro === null) {
            errors.rubro = ['El campo Código es requerido'];
        } else {
            w_rubro = w_rubro.trim()
            if (w_rubro === '') {
                errors.rubro = ['El campo Código es requerido'];
            }
        }
    }


    return errors
};




const RubroClaseCreate = props => {
    const classes = useStyles();
    const [state, setState] = useState({});

    const PostTitle = ({record}) => {
        return <span>Alta de Rubro Clase</span>;
    };

    const {
        Rubro = '',
        RubroD = ''
    } = state;

    const formatRubro = v => {
        return Rubro
    };

    const formatRubroD = v => {
        return RubroD
    };

    const updateRubro = useCallback(async (who) => {
        setState(state => ({
            ...state,
            Rubro: who.rubro,
            RubroD: who.rubro_d.trim(),
        }));
    })

    const PostCreateActions = ({basePath, data, resource}) => (
        <TopToolbar>

        </TopToolbar>
    );

    // redirect to the related Author show page
    const redirect = (basePath, id, data) => `/RubroClase`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Crear"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />

        </Toolbar>
    );

    const transform = data => {

        let wt_rubro = Rubro;


        return {
            ...data,
            wp_rubro: wt_rubro,
        }
    };


    return (
        <Create title={<PostTitle/>} actions={<PostCreateActions/>}  {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={<CustomToolbar/>}
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS RUBRO CLASE">

                    <TextInput
                        label="Código Rubro"
                        source="rubro"
                        value={Rubro}
                        formClassName={classes.rubro}
                        placeholder="Ingrese Rubro"
                        helperText="Código Rubro"
                        margin="normal"
                        format={formatRubro}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <RubroBusquedaBtn callbackRubro={updateRubro} rubro={Rubro} formClassName={classes.btnBusqueda}/>
                    <TextField
                        id="rubro_d-read-only-input"
                        value={RubroD}
                        InputProps={{
                            readOnly: true,
                        }}
                        formClassName={classes.rubro_d}
                        variant="filled"
                        label="Descripción Rubro"
                        placeholder="Ingrese Descripción Rubro"
                        helperText="Descripción Rubro"
                        margin="normal"
                        format={formatRubroD}
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>
                    <TextInput
                        label="Código Clase"
                        source="clase"
                        formClassName={classes.clase}
                        placeholder="Ingrese Código Clase"
                        helperText="Código Clase"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <TextInput
                        label="Descripción Clase"
                        source="clase_d"
                        formClassName={classes.clase_d}
                        placeholder="Ingrese Descripción Clase"
                        helperText="Descripción Clase"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default RubroClaseCreate;


