import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import EstadoOrdenCompraSaveButtonCreate from "./EstadoOrdenCompraSaveButtonCreate"

const useStyles = makeStyles((theme) => ({
    estado: {display: 'inline-block', width: 200, marginLeft: 8},
    estado_d: {display: 'inline-block', width: 400, marginLeft: 8},
    secuencia: {display: 'inline-block', width: 50, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

            <EstadoOrdenCompraSaveButtonCreate
                label="Guardar"
                submitOnEnter={true}
                undoable={false}
                icon={<SaveIcon />}
            />

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_estado = values.estado

    if (w_estado===undefined) {
        errors.estado = ['El campo Código es requerido'];
    }else {
        if (w_estado === null) {
            errors.estado = ['El campo Código es requerido'];
        } else {
            w_estado = w_estado.trim()
            if (w_estado === '') {
                errors.estado = ['El campo Código es requerido'];
            }
        }
    }


    var w_estado_d = values.estado_d

    if (w_estado_d===undefined) {
        errors.estado_d = ['El campo Descripcion es requerido'];
    }else {
        if (w_estado_d === null) {
            errors.estado_d = ['El campo Descripcion es requerido'];
        } else {
            w_estado_d = w_estado_d.trim()
            if (w_estado_d === '') {
                errors.estado_d = ['El campo Descripcion es requerido'];
            }
        }
    }

    var w_secuencia = values.secuencia

    if (w_secuencia===undefined) {
        errors.secuencia = ['El campo Secuencia es requerido'];
    }else {
        if (w_secuencia === null) {
            errors.secuencia = ['El campo Secuencia es requerido'];
        } else {
            if (w_secuencia === 0) {
                errors.secuencia = ['El campo Secuencia es requerido'];
            }
        }
    }



    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/EstadoOrdenCompra`;

const EstadoOrdenCompraCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Estado Orden Compra</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS ESTADO ORDEN COMPRA">
                    <TextInput
                        label="Código Estado"
                        source="estado"
                        formClassName={classes.estado}
                        placeholder="Ingrese Código Estado"
                        helperText="Código"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Descripción"
                        source="estado_d"
                        formClassName={classes.estado_d}
                        multiline
                        placeholder="Ingrese Descripcion"
                        helperText="Descripcion"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <br/>

                    <NumberInput
                        label="Secuencia"
                        source="secuencia"
                        formClassName={classes.secuencia}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <br/>

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default EstadoOrdenCompraCreate;


