import React, {useState, useCallback, useEffect} from "react";
import {
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    SelectInput, DateTimeInput,
    DateInput,
    TopToolbar,
    Toolbar, SaveButton, useDataProvider,
    useGetOne, Loading, ListButton, WithPermissions
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import ProveedorDocumentacionList from "../proveedorDocumentacion/ProveedorDocumentacionList";
import BtnAgregarDocumentacion from "../proveedorDocumentacion/BtnAgregarDocumentacion"
import DatosProveedor from "./DatosProveedor";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

const useStyles = makeStyles((theme) => ({


    // Uso interno

    codigo_pro_tvpublica: {display: 'inline-block', width: 160},
    codigo_pro_rna: {display: 'inline-block', width: 160, marginLeft: 32},
    fecha_alta: {display: 'inline-block', width: 200, marginLeft: 32},
    fecha_baja: {display: 'inline-block', width: 200, marginLeft: 32},

    estado_proveedor: {display: 'inline-block', width: 160},
    estado_proveedor_abre: {display: 'inline-block', width: 312},
    estado_proveedor_d: {display: 'inline-block', width: 392},
    estado_proveedor_usuario: {display: 'inline-block', width: 200},
    estado_proveedor_fecha_hora: {display: 'inline-block', width: 200, marginLeft: 32},

    observacion: {display: 'inline-block', width: 700},
    agenda: {display: 'inline-block', width: 700},

    socio_nro_ingreso: {display: 'inline-block', width: 200},
    nombre: {display: 'inline-block', width: 250},
    apellido: {display: 'inline-block', width: 250, marginLeft: 32},
    estado_civil: {display: 'inline-block', width: 150, marginLeft: 32},
}))


const role_proveedor_agregar_documentacion_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_proveedor_agregar_documentacion = w_permissions_control.indexOf('ROLE_PROVEEDOR_AGREGAR_DOCUMENTACION')
        if (w_role_proveedor_agregar_documentacion === -1) {
        } else {
            w_role_control = 'ROLE_PROVEEDOR_AGREGAR_DOCUMENTACION'
        }
    }

    return w_role_control
}


const ProveedorDocumentacionModEdit = props => {

        const classes = useStyles();
        // const dataProvider = useDataProvider();
        const [state, setState] = useState({});
        const [Proveedor, setProveedor] = useState(props.id);

        const PostTitle = ({record}) => {
            return <span>Proveedor Nro.: {record ? `${record.pro}` + `${record.razon_social}` : ''}</span>;
        };

        /// Búsqueda país
        var w_pais_legal = '', w_pais_comercial = '', w_persona_tipo = '';
        var w_d_pais_legal = '', w_d_pais_comercial = '';
        var w_provincia_legal = '', w_provincia_comercial = '', w_d_provincia_legal = '', w_d_provincia_comercial = '';
        var w_ciudad_legal = '', w_ciudad_comercial = '', w_d_ciudad_legal = '', w_d_ciudad_comercial = '';
        var w_provincia_jurisdiccion = '', w_d_provincia_jurisdiccion = '';
        var w_documentoTipo, w_d_documentoTipo = '';
        var w_pro;
        let w_fecha_baja = false;


        const {data: Data} = useGetOne('proveedor', Proveedor);

        if (Data === undefined) {
        } else {
            w_pro = Data.pro;
            let valor = Data.id_persona_tipo;
            if (valor == 1) {
                w_persona_tipo = 'PF';
            } else if (valor == 2) {
                w_persona_tipo = 'PJ';
            } else if (valor == 3) {
                w_persona_tipo = 'PE';
            }

            w_d_documentoTipo = Data.documento_tipo ? Data.documento_tipo.trim() : '';

            // w_ciudad_comercial = Data.id_comercial_ciudad ? Data.id_comercial_ciudad : null;
            // w_d_ciudad_comercial = Data.provincia_comercial_d ? Data.provincia_comercial_d.trim() : '';
            if (Data.PaisComercial === undefined || Data.PaisComercial === null) {
                w_pais_comercial = 200;
                w_d_pais_comercial = 'ARGENTINA';
            } else {
                w_pais_comercial = Data.PaisComercial.pais_codigo_afip === undefined ? 0 : Data.PaisComercial.pais_codigo_afip;
                w_d_pais_comercial = Data.PaisComercial.pais === undefined ? '' : Data.PaisComercial.pais.trim();

                // if (w_pais_comercial === 200) {
                //     setVerCategorizacion(true)
                // }
            }
            if (Data.ProvinciaComercial === undefined || Data.ProvinciaComercial === null) {
            } else {
                w_provincia_comercial = Data.ProvinciaComercial.id ? Data.ProvinciaComercial.id : null;
                w_d_provincia_comercial = Data.ProvinciaComercial.provincia ? Data.ProvinciaComercial.provincia.trim() : '';
            }
            if (Data.CiudadComercial === undefined || Data.CiudadComercial === null) {
            } else {
                w_ciudad_comercial = Data.CiudadComercial.id ? Data.CiudadComercial.id : null;
                w_d_ciudad_comercial = Data.CiudadComercial.ciudad ? Data.CiudadComercial.ciudad.trim() : '';
            }

            if (Data.PaisLegal === undefined || Data.PaisLegal === null) {
                w_pais_legal = 200;
                w_d_pais_legal = 'ARGENTINA';
            } else {
                w_pais_legal = Data.PaisLegal.pais_codigo_afip === undefined ? 0 : Data.PaisLegal.pais_codigo_afip;
                w_d_pais_legal = Data.PaisLegal.pais === undefined ? '' : Data.PaisLegal.pais.trim();
            }

            if (Data.ProvinciaLegal === undefined || Data.ProvinciaLegal === null) {
            } else {
                w_provincia_legal = Data.ProvinciaLegal.id ? Data.ProvinciaLegal.id : null;
                w_d_provincia_legal = Data.ProvinciaLegal.provincia ? Data.ProvinciaLegal.provincia.trim() : '';
            }
            if (Data.CiudadLegal === undefined || Data.CiudadLegal === null) {
            } else {
                w_ciudad_legal = Data.CiudadLegal.id ? Data.CiudadLegal.id : null;
                w_d_ciudad_legal = Data.CiudadLegal.ciudad ? Data.CiudadLegal.ciudad.trim() : '';
            }

            if (Data.ProvinciaJurisdiccion === undefined || Data.ProvinciaJurisdiccion === null) {
            } else {
                w_provincia_jurisdiccion = Data.ProvinciaJurisdiccion.id ? Data.ProvinciaJurisdiccion.id : null;
                w_d_provincia_jurisdiccion = Data.ProvinciaJurisdiccion.provincia ? Data.ProvinciaJurisdiccion.provincia.trim() : '';
            }

            if (Data.DocumentoTipo === undefined || Data.DocumentoTipo === null) {
            } else {
                w_documentoTipo = Data.DocumentoTipo.id ? Data.DocumentoTipo.id : null;
                w_d_documentoTipo = Data.DocumentoTipo.documento_tipo_d ? Data.DocumentoTipo.documento_tipo_d.trim() : '';
            }
            if (Data.fecha_baja === undefined || Data.fecha_baja === null) {
            } else {
                w_fecha_baja = true
            }
        }


        const [PersonaTipo, setPersonaTipo] = useState(w_persona_tipo);


        /*
        useEffect(() => {
            console.log("PersonaTipo: ", PersonaTipo)
        }, [PersonaTipo]);
*/
        const formatPaisLegal = v => {
            return dPaisLegal
        };

        const formatPaisComercial = v => {
            return dPaisComercial
        };

        const formatProvinciaLegal = v => {
            return dProvinciaLegal
        };

        const formatProvinciaComercial = v => {
            return dProvinciaComercial
        };

        const formatCiudadLegal = v => {
            return dCiudadLegal
        };

        const formatCiudadComercial = v => {
            return dCiudadComercial
        };

        const formatProvinciaJurisdiccion = v => {
            return dProvinciaJurisdiccion
        };

        const formatDocumentoTipo = v => {
            return dDocumentoTipo
        };

        const {
            codPaisLegal = w_pais_legal,
            dPaisLegal = w_d_pais_legal,
            codProvinciaLegal = w_provincia_legal,
            dProvinciaLegal = w_d_provincia_legal,
            codCiudadLegal = w_ciudad_legal,
            dCiudadLegal = w_d_ciudad_legal,

            codPaisComercial = w_pais_comercial,
            dPaisComercial = w_d_pais_comercial,
            codProvinciaComercial = w_provincia_comercial,
            dProvinciaComercial = w_d_provincia_comercial,
            codCiudadComercial = w_ciudad_comercial,
            dCiudadComercial = w_d_ciudad_comercial,

            codProvinciaJurisdiccion = w_provincia_jurisdiccion,
            dProvinciaJurisdiccion = w_d_provincia_jurisdiccion,
            codDocumentoTipo = w_documentoTipo,
            dDocumentoTipo = w_d_documentoTipo
        } = state;


        const updateDocumentoTipo = useCallback(async (who) => {
            setState(state => ({
                ...state,
                codDocumentoTipo: who.id,
                dDocumentoTipo: who.documento_tipo_d.trim(),
            }));
        });

        const establecerProveedor = value => {
            if (value == null || value === '') return '';
            setProveedor(value.trim());
            return value;
        };

        const establecerFecha = value => {
            if (value == null || value === '') return '';
            return value;
        };

        /// JN Mascara para el input cuit-cuil
        const cuitCuilFormat = value => {
            if (value == null || value === '') return '';

            //normalize string and remove all unnecessary characters
            var valueCuit = value.replace(/[^\d]/g, "");

            //check if number length equals to 11
            if (valueCuit.length === 11) {
                return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
            }

            return value
        };

        const PostEditActions = ({basePath, data, resource}) => (
            <TopToolbar>
                <ListButton basePath={`/proveedor/`} label="Listado de Proveedores" icon={<PersonOutlineIcon />} />
            </TopToolbar>
        );

        const redirect = (basePath, id, data) => `/Proveedor/`;

        const CustomToolbar = props => (

            <Toolbar {...props}>
                {/*<SaveButton*/}
                {/*    label="Actualizar"*/}
                {/*    transform={transform}*/}
                {/*    submitOnEnter={false}*/}
                {/*    disabled={props.pristine}*/}
                {/*/>*/}
            </Toolbar>
        );
        const transform = data => {

            let w_cod_pais_legal = codPaisLegal;
            if (codPaisLegal > 0) {
            } else (w_cod_pais_legal = 0);

            let w_cod_pais_comercial = codPaisComercial;
            if (codPaisComercial > 0) {
            } else (w_cod_pais_comercial = 0);

            let w_cod_provincia_legal = codProvinciaLegal;
            if (codProvinciaLegal > 0) {
            } else (w_cod_provincia_legal = 0);

            let w_cod_provincia_comercial = codProvinciaComercial;
            if (codProvinciaComercial > 0) {
            } else (w_cod_provincia_comercial = 0);

            let w_cod_ciudad_legal = codCiudadLegal;
            if (codCiudadLegal > 0) {
            } else (w_cod_ciudad_legal = 0);

            let w_cod_ciudad_comercial = codCiudadComercial;
            if (codCiudadComercial > 0) {
            } else (w_cod_ciudad_comercial = 0);

            let w_cod_provincia_jurisdiccion = codProvinciaJurisdiccion;
            if (codProvinciaJurisdiccion > 0) {
            } else (w_cod_provincia_jurisdiccion = 0);

            let w_cod_documento_tipo = codDocumentoTipo;
            if (codDocumentoTipo > 0) {
            } else (w_cod_documento_tipo = 0);


            return {
                ...data,
                wp_pais_comercial: w_cod_pais_comercial,
                wp_pais_legal: w_cod_pais_legal,
                wp_provincia_comercial: w_cod_provincia_comercial,
                wp_provincia_legal: w_cod_provincia_legal,
                wp_ciudad_comercial: w_cod_ciudad_comercial,
                wp_ciudad_legal: w_cod_ciudad_legal,
                w_provincia_jurisdiccion: w_cod_provincia_jurisdiccion,
                wp_documento_tipo: w_cod_documento_tipo,
            }
        };

        return (
            <Edit title={<PostTitle/>} undoable={false} actions={<PostEditActions/>} {...props}>
                <TabbedForm
                    redirect={redirect}
                    toolbar={false}
                >
                    {/*Documentacion*/}
                    <FormTab label="Documentación">
                        <DatosProveedor w_pro={w_pro}/>
                        {Proveedor &&
                        <>

                        <WithPermissions
                            render={({permissions}) => (
                                role_proveedor_agregar_documentacion_permissions({permissions}) === 'ROLE_PROVEEDOR_AGREGAR_DOCUMENTACION'
                                    ?<BtnAgregarDocumentacion pro={Proveedor}/>
                                    : null
                            )}
                        />
                            <ProveedorDocumentacionList pro={Proveedor}/>
                        </>
                        }
                    </FormTab>
                </TabbedForm>
            </Edit>
        )
    }
;
export default ProveedorDocumentacionModEdit;


