import {AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT} from 'react-admin';
import ApiUrlServer from './layout/ApiUrlServer'

export default (type, params) => {


    if (type === AUTH_LOGIN) {

        const {username, password} = params;

         // const request = new Request('http://10.10.0.59:8080/api/login', {
        // const request = new Request('http://grupoprovemet.no-ip.org:8280/t900pro/api/login', {
        const request = new Request(ApiUrlServer+'/Auth/login', {
            method: 'POST',
            body: JSON.stringify({username: username, password: password}),
            credentials: 'include',
            headers: new Headers({'Content-Type': 'application/json'}),
        });
        return fetch(request)
            .then(response => {
                console.log(response.status)

                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();

            })
            .then(({access_token, roles}) => {
                localStorage.setItem('token', access_token);
                localStorage.setItem('role', roles);
            });
    }

    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        // ...
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    }
    return Promise.reject('Unknown method');
};
