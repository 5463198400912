import React, {useState, useCallback} from 'react';
import {useQuery} from 'react-admin';
import 'devextreme/dist/css/dx.light.css';
import Button from '@mui/material/Button';
import IconCancelar from '@material-ui/icons/Close';
import './ProveedorClasesBuscadorArbol.css'

import DataGrid, {
    Column, Selection, Grouping, SearchPanel, Pager, Paging
} from 'devextreme-react/data-grid';

function ProveedorClaseArbolBuscador(props) {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'});

    const [selectedClase, setSelectedClase] = useState(null);

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'clase',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: {},
        }
    });

    const BtnCancelar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" onClick={() => {
            props.callbackCloseDrawer(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const BtnSeleccionar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" onClick={() => {
            props.callbackCloseDrawer(false);
            props.callbackClase(selectedClase)
        }}
        >
            <IconCancelar/>
            Confirmar Selección
        </Button>
    );

    const selectionFilter = ['clase_d', '=', 'alpiste'];

    const onSelectionChanged = useCallback((e) => {
        const nuevoArray = [];
        // Recorro las filas seleccionadas y guardo en un nuevo array los datos que debo guardar
        e.selectedRowsData.map((item) => {
            nuevoArray.push(item);
        });
        setSelectedClase(nuevoArray);
    }, []);
    const allowedPageSizes = [5, 10, 'all'];
    return (
        <div>
            <DataGrid
                id="gridContainer"
                dataSource={data}
                keyExpr="id_clase"
                showBorders={true}
                selectNodesRecursive={true}
                selectByClick={true}
                selectAllMode={'page'}
                showCheckBoxesMode={true}
                onSelectionChanged={onSelectionChanged}
                defaultSelectionFilter={selectionFilter}
            >
                <Paging defaultPageSize={18} />

                <Selection selectAllMode={'page'} mode="multiple" deferred={false} showCheckBoxesMode={'always'}
                           showCheckBoxSelectorInGroupRow={true}/>
                {/*<GroupPanel visible={true}/>*/}
                <Grouping autoExpandAll={false}/>
                <SearchPanel visible={true}
                             width={240}
                             placeholder="Buscar..."
                />

                <Column dataField="rubro_d" caption={"Rubro"} groupIndex={0}/>
                <Column dataField="clase_d" caption={"Seleccionar Clases"}/>

            </DataGrid>
            <div>
                <div>
                    <br/>

                    {selectedClase &&
                    <BtnSeleccionar/>
                    }
                    {' '}
                    <BtnCancelar/>

                </div>
            </div>
        </div>
    );
}

export default ProveedorClaseArbolBuscador;