import React from 'react';
import {useDataProvider} from 'react-admin';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Button from '@material-ui/core/Button';
import {saveAs} from 'file-saver';
import CircularProgress from '@material-ui/core/CircularProgress';
import ImageIcon from '@material-ui/icons/Image';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import ArchiveIcon from '@material-ui/icons/Archive';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import ApiUrlServer from '../layout/ApiUrlServer'
import axios from 'axios';

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const AdjuntoGeneradoButton = ({record}) => {
    const {id: wr_id, adjunto_nombre: wr_adjunto_nombre = '', extension: wr_extension, parametro_path: wr_parametro_path} = record;
    const [estadoCircularProgress, setEstadoCircularProgress] = React.useState(0);

    const onClickHandlerAdjuntoGenerado = async () => {
        setEstadoCircularProgress(1);
        //--------------------------- si el pdf no está generado lo genera ----------------------

        var w_adjunto_nombre = wr_adjunto_nombre
        if(w_adjunto_nombre==null){
            w_adjunto_nombre=''
        }
        w_adjunto_nombre=w_adjunto_nombre.trim()

        var w_parametro_path = wr_parametro_path
        if(w_parametro_path==null){
            w_parametro_path=''
        }
        w_parametro_path=w_parametro_path.trim()

        var w_extension = wr_extension
        if(w_extension==null){
            w_extension=''
        }
        w_extension=w_extension.trim()


        var w_type = ''

        switch (w_extension) {
            case 'pdf':
                w_type = 'application/pdf'
                break
            case 'PDF':
                w_type = 'application/pdf'
                break
            case 'jpg':
                w_type = 'image/jpg'
                break
            case 'JPG':
                w_type = 'image/jpg'
                break
            case 'jpeg':
                w_type = 'image/jpeg'
                break
            case 'JPEG':
                w_type = 'image/jpeg'
                break
            case 'png':
                w_type = 'image/png'
                break
            case 'PNG':
                w_type = 'image/png'
                break
            case 'gif':
                w_type = 'image/gif'
                break
            case 'GIF':
                w_type = 'image/gif'
                break
            case 'xls':
                w_type = 'application/vnd.ms-excel'
                break
            case 'XLS':
                w_type = 'application/vnd.ms-excel'
                break
            case 'doc':
                w_type = 'application/msword'
                break
            case 'DOC':
                w_type = 'application/msword'
                break
            case 'docx':
                w_type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                break
            case 'DOCX':
                w_type = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                break
            case 'xlsx':
                w_type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                break
            case 'XLSX':
                w_type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                break
            case 'zip':
                w_type = 'application/zip'
                break
            case 'ZIP':
                w_type = 'application/zip'
                break
            case 'rar':
                w_type = 'application/rar'
                break
            case 'RAR':
                w_type = 'application/rar'
                break
            default:
                w_type = 'application/octet-stream'
                break
        }

        console.log('w_type')
        console.log(w_type)

        //--------------------------------------- no usa react admin -- usa Http para llamar a la api - pasa como parametro el nombre del archivo -------------------------


        var wa_location_hostname = window.location.hostname

        console.log('wa_location_hostname')
        console.log(wa_location_hostname)

        var wa_apiurlserver

        switch (wa_location_hostname) {
            case 'opi.radionacional.ar':
                wa_apiurlserver = 'https://opi.radionacional.ar:4443'
                break
            case 'proveedores.radionacional.ar':
                wa_apiurlserver = 'https://archivprov.radionacional.ar'
                break
            default:
                wa_apiurlserver = 'http://'+wa_location_hostname+':4620'
                break
        }

        var UrlServer = wa_apiurlserver+'/AdjuntoGeneradoDownload/'


        var URLToPDF = UrlServer +encodeURIComponent(w_parametro_path)+'/'+encodeURIComponent(w_adjunto_nombre)

        //-------------------- para bajar el archivo usamos la instruccion fetch de react en forma standard ----------------------

        const response = await fetch(URLToPDF);
        const fileContent = await response.blob();
        // Create file blob
        const blob = new Blob([fileContent], {type: w_type});
        saveAs(blob,w_adjunto_nombre);

        setEstadoCircularProgress(0);
     };
    switch (wr_extension) {
        case 'pdf':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <PictureAsPdfIcon style={{color: '#ff0000'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'PDF':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <PictureAsPdfIcon style={{color: '#ff0000'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'jpg':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#f74e31'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'JPG':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#f74e31'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'jpeg':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#f74e31'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'JPEG':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#f74e31'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'png':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#f74e31'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'PNG':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#f74e31'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'gif':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#f74e31'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'GIF':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#f74e31'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'xls':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#008000'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'XLS':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#008000'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'doc':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <FormatAlignJustifyIcon style={{color: '#0080ff'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'DOC':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <FormatAlignJustifyIcon style={{color: '#0080ff'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'docx':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <FormatAlignJustifyIcon style={{color: '#0080ff'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'DOCX':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <FormatAlignJustifyIcon style={{color: '#0080ff'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'xlsx':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#008000'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'XLSX':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ImageIcon style={{color: '#008000'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'zip':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ArchiveIcon style={{color: '#804000'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'ZIP':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ArchiveIcon style={{color: '#804000'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'rar':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ArchiveIcon style={{color: '#804000'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        case 'RAR':
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <ArchiveIcon style={{color: '#804000'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
        default:
            return (
                <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerAdjuntoGenerado}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Descargar Archivo: " + wr_adjunto_nombre}
                    >
                        <WebAssetIcon style={{color: '#000000'}}/>
                    </div>
                    {estadoCircularProgress === 1 ? (
                        <div style={{fontSize: '7px'}}>
                            <CircularProgress/>
                            {' Procesando la Descarga del Archivo...'}
                        </div>
                    ) : (
                        ''
                    )}
                </Button>
            );
            break
    }

};


AdjuntoGeneradoButton.defaultProps = {
    source: '',
    addLabel: true,
};

export default AdjuntoGeneradoButton;
