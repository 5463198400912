import React, {useEffect, useState, useCallback} from 'react'
import {
    Datagrid,
    TextField,
    useDataProvider,
    Pagination, Link, TextInput, ReferenceArrayInput, ReferenceInput, SelectInput,

} from 'react-admin';
import keyBy from 'lodash/keyBy';
import ProveedorInfo from "../proveedor/ProveedorInfo";
import Button from '@mui/material/Button';
import IconFiltrar from '@mui/icons-material/FilterAltOutlined';
import IconClear from '@mui/icons-material/Clear';
import {SelectArrayInput} from 'react-admin';
import ExportExcelButton from '../ProveedorClaseBuscador/ExportExcelButton'
import {useForm} from 'react-final-form';

export const ProveedorClaseBuscadorGrid = ({clase}) => {
    const [data, setData] = useState(null);
    const dataProvider = useDataProvider();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'});
    const [total, setTotal] = useState(0);

    const [actualizarFiltros, setActualizarFiltros] = useState(false);
    const [provincia, setProvincia] = useState('');
    const [razonSocial, setRazonSocial] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [pais, setPais] = useState('');
    const [estadoPro, setEstadoPro] = useState('');
    const [q, setQ] = useState('');
    const form = useForm();

    // PARA LIMPIAR LOS CAMPOS AL APRETAR LIMPIAR
    const handleClick = useCallback(() => {
        form.change('pais', undefined);
        form.change('provincia', undefined);
        form.change('ciudad', undefined);
        form.change('estado_proveedor_abre', undefined);
        form.change('q', undefined);
        form.change('razon_social', undefined);
    }, [form]);

    useEffect(() => {
        dataProvider.getList(
            'proveedorClaseBuscador',
            {
                filter: {
                    clase: clase,
                    pais: pais,
                    estado_proveedor_abre: estadoPro,
                    provincia: provincia,
                    razonSocial: razonSocial,
                    ciudad: ciudad,
                    q: q
                },
                sort: {field: 'id', order: 'ASC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setData(data);
                setTotal(data.length)
            })
            .catch(error => {
                console.log(error)
            })

    }, [clase, actualizarFiltros]);

    const LinkMostrarButton = ({record}) => {
        return (
            <Link to={`/proveedor/${record.id}/show`}>
                <div title={''}
                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                >
                    Mostrar
                </div>
            </Link>
        );
    };

    return (
        <div>
            {/*SI EL DATA TIENE REGISTROS, MUESTRO LA GRILLA*/}
            {data &&
            <div>
                {/* AGREGO LOS FILTROS*/}
                <div style={{display: "flex", alignItems: "baseline", flexWrap: 'wrap'}}>
                    {/*<div style={{display: "flex", justifyContent: "center", alignItems: "flex-start"}}>*/}
                    <ReferenceArrayInput label="Provincias" reference="Provincia"
                                         onChange={(event => setProvincia(event.target.value))}
                                         allowEmpty
                                         source={"provincia"} optionValue={"id"} variant="outlined"
                                         sort={{field: 'provincia', order: 'ASC'}}
                    >
                        <SelectArrayInput optionText="provincia"
                                          style={{minWidth: '120px', maxWidth: '500px'}}
                        />
                    </ReferenceArrayInput>

                    <TextInput label="Pais" source={"pais"} variant="outlined"
                               onChange={(event => setPais(event.target.value))}
                               style={{marginLeft: '32px', width: '120px', maxWidth: '150px'}}
                    />

                    <TextInput label="Ciudad" source={"ciudad"} variant="outlined"
                               onChange={(event => setCiudad(event.target.value))}
                               style={{marginLeft: '32px', width: '120px', maxWidth: '150px'}}
                    />

                    <TextInput label="Razón social" source={"razon_social"} variant="outlined"
                               style={{marginLeft: '32px', width: '120px', maxWidth: '150px'}}
                               onChange={(event => setRazonSocial(event.target.value))}
                               defaultValue={razonSocial}
                    />

                    <ReferenceInput label="Estado Pro." source="estado_proveedor_abre" reference="estadoProveedor"
                                    variant="outlined" onChange={(event => setEstadoPro(event.target.value))}
                                    defaultValue={estadoPro} allowEmpty
                    >
                        <SelectInput optionText="estado_proveedor_abre"
                                     style={{marginLeft: '32px', minWidth: '120px', maxWidth: '150px'}}
                                     defaultValue={estadoPro}
                        />
                    </ReferenceInput>


                    {/*<TextInput label="Estado Proveedor" source={"estado_proveedor_abre"} variant="outlined"*/}
                    {/*           style={{marginLeft: '32px', width: '130px', maxWidth: '150px'}}*/}
                    {/*           onChange={(event => setEstadoPro(event.target.value))}*/}
                    {/*           defaultValue={estadoPro}*/}
                    {/*/>*/}


                    <TextInput label="Buscar" source={"q"} variant="outlined"
                               onChange={(event => setQ(event.target.value))}
                               style={{marginLeft: '32px', width: '150px'}}
                    />

                    <div>
                        {/*AL APRETAR FILTRAR, SETEA EL ESTADO DE ACTUALIZAR FILTROS POR ENDE VA AL BUSCADOR A TRAER LOS DATOS FILTRADOS */}
                        <Button variant="contained" size="medium" startIcon={<IconFiltrar/>} color="primary"
                                style={{marginLeft: '32px'}}
                                onClick={() => (setActualizarFiltros(!actualizarFiltros))}>
                            Aplicar
                        </Button>

                        {/*LIMPIAR TODOS LOS FILTROS DE LA PANTALLA*/}
                        <Button variant="contained" size="medium" startIcon={<IconClear/>} color="inherit"
                                style={{marginLeft: '32px'}}
                                onClick={() =>
                                    (handleClick(),
                                            setActualizarFiltros(!actualizarFiltros),
                                            setProvincia(''),
                                            setRazonSocial(''),
                                            setCiudad(''),
                                            setPais(''),
                                            setEstadoPro(''),
                                            setQ('')
                                    )
                                }>
                            Limpiar
                        </Button>

                        <ExportExcelButton data={data} fileName={"ClasesBuscador"} sheetName={"Proveedor-Clases"}/>
                    </div>
                </div>
                {/* GRILLA */}
                <Datagrid
                    data={keyBy(data, 'id')}
                    ids={data.map(({id}) => id)}
                    currentSort={sort}
                    setSort={(field, order) => setSort({field, order})}
                    expand={<ProveedorInfo/>}
                >
                    <TextField label="Nro. Proveedor" source="pro"/>
                    <TextField label="Razón Social" source="razon_social"/>
                    {/*<TextField label="Tipo Persona" source="persona_tipo_d" sortable={false}/>*/}
                    <TextField label="Tipo Doc." source="documento_tipo_d" sortable={false}/>
                    <TextField label="Cuit / Cuil" source="cuit_cuil" sortable={false}/>
                    <TextField label="IVA" source="d_iva" sortable={false}/>
                    <TextField label="Estado Proveedor" source="estado_proveedor_abre" sortable={false}/>
                    <TextField label="Provincia" source="provincia_comercial_d" sortable={false}/>
                    <TextField label="Ciudad" source="ciudad_comercial_d" sortable={false}/>
                    <TextField label="Teléfono" source="telefono_nro" sortable={false}/>
                    <TextField label="Email" source="correo_electronico" sortable={false}/>

                    <LinkMostrarButton/>

                </Datagrid>
                <Pagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    total={total}
                />
            </div>
            }

        </div>
    );
};

export default ProveedorClaseBuscadorGrid;



