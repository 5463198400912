import React from 'react';
import compose from 'recompose/compose';
import List from '@material-ui/core/List';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {translate} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const VerValores = ({
                        visitors = [], nb, translate,
                        ver_razon_social_fantasia,
                        ver_legal_pais,
                        ver_legal_provincia,
                        ver_legal_partido,
                        ver_legal_ciudad,
                        ver_legal_calle,
                        ver_legal_calle_nro,
                        ver_legal_piso,
                        ver_legal_departamento,
                        ver_legal_codigo_postal,

                        ver_comercial_pais,
                        ver_comercial_provincia,
                        ver_comercial_partido,
                        ver_comercial_ciudad,
                        ver_comercial_calle,
                        ver_comercial_calle_nro,
                        ver_comercial_piso,
                        ver_comercial_departamento,
                        ver_comercial_codigo_postal,
                        ver_correo_electronico,
                        ver_correo_electronico_alternativo,

                        ver_telefono_area,
                        ver_telefono_nro,
                        ver_telefono_alternativo_area,
                        ver_telefono_alternativo_nro,
                        ver_celular_area,
                        ver_celular_nro,
                        ver_fax_area,
                        ver_fax_nro,
                        ver_ibru_exento,
                        ver_ibru_tipo,
                        ver_ibru_tipo_d,
                        ver_ibru_jurisdiccion_provincia,
                        ver_ibru_nro,

                        ver_tiene_personas_con_discapacidad,
                        ver_tiene_participacion_estatal,
                        ver_tiene_participacion_entidades_publicas_no_estatales,
                        ver_observacion,
                        ver_agenda,
                        ver_codigo_pro_tvpublica,
                        ver_codigo_pro_rna,
                        ver_estado_proveedor_d,
                        classes
                    }) => (
    <div>
        <List>
            <Table style={{backgroundColor: '#F2F3F4'}}>
                <TableRow>
                    <TableCell className={classes.textleft}>
                        <Typography><u>Domicilio Legal</u></Typography>

                        <Typography>
                            <b>País: </b> {ver_legal_pais}
                        </Typography>
                        <Typography>
                            <b>Provincia: </b> {ver_legal_provincia}
                        </Typography>
                        <Typography>
                            <b>Ciudad: </b> {ver_legal_ciudad}
                        </Typography>
                        <Typography>
                            <b>Partido: </b> {ver_legal_partido}
                        </Typography>
                        <Typography>
                            <b>Dirección: </b> {ver_legal_calle + ' ' + ver_legal_calle_nro}
                        </Typography>
                        <Typography>
                            <b>Piso/Depto: </b> {ver_legal_piso + ' ' + ver_legal_departamento}
                        </Typography>
                        <Typography>
                            <b>Cod. Postal: </b> {ver_legal_codigo_postal}
                        </Typography>
                        <Typography>
                            {''}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography><u>Domicilio Comercial</u></Typography>
                        <Typography>
                            <b>País: </b> {ver_comercial_pais}
                        </Typography>
                        <Typography>
                            <b>Provincia: </b> {ver_comercial_provincia}
                        </Typography>
                        <Typography>
                            <b>Ciudad: </b> {ver_comercial_ciudad}
                        </Typography>
                        <Typography>
                            <b>Partido: </b> {ver_comercial_partido}
                        </Typography>
                        <Typography>
                            <b>Dirección: </b> {ver_comercial_calle + ' ' + ver_comercial_calle_nro}
                        </Typography>
                        <Typography>
                            <b>Piso/Depto: </b> {ver_comercial_piso + ' ' + ver_comercial_departamento}
                        </Typography>
                        <Typography>
                            <b>Cod.Postal: </b> {ver_comercial_codigo_postal}
                        </Typography>
                        <Typography>
                            {''}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography>
                            <b>Raz.Social Fantasia: </b> {ver_razon_social_fantasia}
                        </Typography>
                        <Typography>
                            <b>Tel. Principal: </b> {ver_telefono_area + ver_telefono_nro}
                        </Typography>
                        <Typography>
                            <b>Tel. Alternativo: </b> {ver_telefono_alternativo_area + ver_telefono_alternativo_nro}
                        </Typography>
                        <Typography>
                            <b>Celular: </b> {ver_celular_area + ' ' + ver_celular_nro}
                        </Typography>
                        <Typography>
                            <b>Fax: </b> {ver_fax_area + ' ' + ver_fax_nro}
                        </Typography>
                        <Typography>
                            <b>Ibru Exento: </b> {ver_ibru_exento}
                        </Typography>
                        <Typography>
                            <b>Ibru Tipo: </b> {ver_ibru_tipo_d}
                        </Typography>
                        <Typography>
                            <b>Ibru Jurisdicción: </b> {ver_ibru_jurisdiccion_provincia}
                        </Typography>
                        <Typography>
                            <b>Ibru Nro.: </b> {ver_ibru_nro}
                        </Typography>
                    </TableCell>


                    <TableCell className={classes.textleft}>
                        <Typography></Typography>

                        <Typography>
                            <b>Email: </b> {ver_correo_electronico}
                        </Typography>
                        <Typography>
                            <b>Email Alternativo: </b> {ver_correo_electronico_alternativo}
                        </Typography>
                        <Typography>
                            <b>Personas con discapacidad : </b> {ver_tiene_personas_con_discapacidad}
                        </Typography>
                        <Typography>
                            <b>Partic. Estatal: </b> {ver_tiene_participacion_estatal}
                        </Typography>
                        <Typography>
                            <b>Partic. entidades publicas no
                                estatales: </b> {ver_tiene_participacion_entidades_publicas_no_estatales}
                        </Typography>
                        <Typography>
                            <b>Código Pro Tv Publica: </b> {ver_codigo_pro_tvpublica}
                        </Typography>
                        <Typography>
                            <b>Código Pro RNA: </b> {ver_codigo_pro_rna}
                        </Typography>
                        <Typography>
                            <b>Estado Proveedor: </b> {ver_estado_proveedor_d}
                        </Typography>
                    </TableCell>
                </TableRow>
            </Table>
        </List>

    </div>

);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(VerValores);

