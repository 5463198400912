import React, {useState} from "react";
import {
    FormTab,
    Show,
    TextInput,
    TabbedForm,
    TopToolbar,
    NumberInput,
    EditButton,
    SelectInput,
    usePermissions, TextField, NumberField, Datagrid
} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

    documentacion_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    documentacion_tipo_d: {display: 'inline-block', width: 400, marginLeft: 8},
}));

const AutoridadesShow = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    var w_role_auxiliar_editar = ''
    var w_permissions_admuno_auxiliar_editar = permissions
    if(w_permissions_admuno_auxiliar_editar==null){}else{
        var wa_role_auxiliar_editar = w_permissions_admuno_auxiliar_editar.indexOf('ROLE_AUXILIAR_EDITAR')
        if(wa_role_auxiliar_editar===-1){}else{
            w_role_auxiliar_editar = 'ROLE_AUXILIAR_EDITAR'
        }
    }
    const [DocumentacionTipo, setDocumentacionTipo] = useState('');
    const [PersonaTipo, setPersonaTipo] = useState('');
    // const [DocumentacionTipoSocioEstadoCivilCasado, setDocumentacionTipoSocioEstadoCivilCasado] = useState('');

    const establecerDocumentacionTipo = value => {
        if (value == null || value === '') return '';
        setDocumentacionTipo(value.trim());
        return value;
    };

    const establecerFecha = value => {
        if (value == null || value === '') return '';
        return value;
    };

    // Obtengo tipo de persona para cargar solapa correspondiente
    const establecerPersonaTipo = value => {
        if (value == null || value === '') return '';
        setPersonaTipo(value.trim());
        return value;
    };


    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    // const establecerDocumentacionTipoSocioEstadoCivilCasado = value => {
    //     if (value == null || value === '') return '';
    //     setDocumentacionTipoSocioEstadoCivilCasado(value.trim());
    //     return value;
    // };

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            {w_role_auxiliar_editar === 'ROLE_AUXILIAR_EDITAR' &&
                <EditButton basePath={basePath} record={data}/>
            }
        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        return <span>Autoridades: {record ? `${record.nombre_apellido}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>
                <FormTab label="DATOS Autoridades">

                    <TextInput label="Autoridad Código" source="autoridad_codigo"
                               formClassName={classes.autoridad_codigo} inputProps={{readOnly: true}}/>
                    <TextInput label="Nombre Apellido" source="nombre_apellido"
                               formClassName={classes.nombre_apellido} inputProps={{readOnly: true}}/>
                    <TextInput label="Adjudica Sector" source="adjudica_sector"
                               formClassName={classes.adjudica_sector} inputProps={{readOnly: true}}/>
                    <TextInput label="Firma Tipo" source="firma_tipo"
                               formClassName={classes.firma_tipo} inputProps={{readOnly: true}}/>
                    <NumberInput label="Importe Hasta Pesos" source="importe_hasta_pesos"
                                 formClassName={classes.importe_hasta_pesos} inputProps={{readOnly: true}}/>
                    <TextInput label="Pre Adjudica" source="pre_adjudica"
                               formClassName={classes.pre_adjudica} inputProps={{readOnly: true}}/>
                    <TextInput label="Conforma Adjudica" source="conforma_adjudica"
                               formClassName={classes.conforma_adjudica} inputProps={{readOnly: true}}/>


                    {/*<TextInput*/}
                    {/*    label="Código Documentacion Tipo"*/}
                    {/*    source="documentacion_tipo"*/}
                    {/*    formClassName={classes.documentacion_tipo}*/}
                    {/*    placeholder="Ingrese Código Documentacion Tipo"*/}
                    {/*    helperText="Código"*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}

                    <br/>




                </FormTab>
            </TabbedForm>
        </Show>
    )
};
export default AutoridadesShow;


