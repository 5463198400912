import React, {useState} from 'react';
import {useNotify, useRefresh, useMutation, ReferenceInput, SelectInput} from 'react-admin';
import {
    TextInput,
} from 'react-admin';
import Button from '@mui/material/Button';
import IconCancelar from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

    socio_nro_ingreso: {display: 'inline-block', width: 100},

    nombre: {display: 'inline-block', width: 150,},
    apellido: {display: 'inline-block', width: 150, marginLeft: 28},

    documento_tipo: {display: 'inline-block', width: 80},
    documento_nro: {display: 'inline-block', width: 120, marginLeft: 28},
    cuit_cuil: {display: 'inline-block', width: 150, marginLeft: 28},

    correo_electronico: {display: 'inline-block', width: 350},

    estado_civil: {display: 'inline-block', width: 80},
    socio_activo: {display: 'inline-block', width: 80},
}));


function ProveedorSocioAgregar({pro, callbackCloseDrawer}) {
    // const [page, setPage] = useState(1);
    // const [perPage, setPerPage] = useState(25);
    // const [sort, setSort] = useState({field: 'id', order: 'ASC'});
    const classes = useStyles();

    const [{
        proveedor,
        nombre_socio,
        apellido_socio,
        cuit_cuil_socio,
        id_documento_tipo_socio,
        documento_nro_socio,
        correo_electronico_socio,
        correo_electronico_alternativo_socio,
        id_estado_civil,
        conyuge_nombre,
        conyuge_apellido,
        conyuge_cuit_cuil,
        conyuge_id_documento_tipo,
        conyuge_documento_nro,
        socio_activo
    }, setState] = useState({
        proveedor: pro,
        nombre_socio: "",
        apellido_socio: "",
        cuit_cuil_socio: "",
        id_documento_tipo_socio: null,
        documento_nro_socio: "",
        correo_electronico_socio: "",
        correo_electronico_alternativo_socio: "",
        id_estado_civil: "",
        conyuge_nombre: "",
        conyuge_apellido: "",
        conyuge_cuit_cuil: "",
        conyuge_id_documento_tipo: null,
        conyuge_documento_nro: "",
        socio_activo: ""
    });

    /// JN Mascara para el input cuit-cuil
    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    function handleTextInputChange({target: {name, value}}) {
        setState(prevState => ({...prevState, [name]: value}));
    }

    const BtnCancelar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
            callbackCloseDrawer(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const BotonCrearDetelle = (record) => {

        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'create',
                resource: 'ProveedorSocio',
                payload: {
                    data: {
                        proveedor,
                        nombre_socio,
                        apellido_socio,
                        cuit_cuil_socio,
                        id_documento_tipo_socio,
                        documento_nro_socio,
                        correo_electronico_socio,
                        correo_electronico_alternativo_socio,
                        id_estado_civil,
                        conyuge_nombre,
                        conyuge_apellido,
                        conyuge_cuit_cuil,
                        conyuge_id_documento_tipo,
                        conyuge_documento_nro,
                        socio_activo
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <SaveIcon style={{color: '#004187'}}/>
                    Guardar
                </div>
            </Button>
        );

    };

    return (
        <div style={{paddingLeft: "10px"}}>
            <TextInput source={"nombre_socio"} label={"Nombre"} variant={"outlined"} InputLabelProps={{shrink: true}}
                       className={classes.nombre} onChange={handleTextInputChange} fullWidth/>
            <TextInput source={"apellido_socio"} label={"Apellido"} variant={"outlined"}
                       InputLabelProps={{shrink: true}}
                       className={classes.apellido} onChange={handleTextInputChange} fullWidth/><br/>

            <ReferenceInput label="Tipo Doc." source="id_documento_tipo_socio" className={classes.documento_tipo}
                            onChange={handleTextInputChange} sort={{field: 'orden', order: 'DESC'}}
                            reference="DocumentoTipo" variant="outlined">
                <SelectInput InputLabelProps={{shrink: true}} optionText="documento_tipo_d" fullWidth={true}
                             className={classes.documento_tipo_d}
                />
            </ReferenceInput>

            <TextInput source="documento_nro_socio" label={"Nro. Doc."} variant="outlined"
                       InputLabelProps={{shrink: true}} onChange={handleTextInputChange}
                       className={classes.documento_nro} fullWidth={true}/>

            <TextInput source="cuit_cuil_socio" label={"CUIT/CUIL"} variant="outlined"
                       InputLabelProps={{shrink: true}}
                       className={classes.cuit_cuil} fullWidth={true}
                       format={cuitCuilFormat} onChange={handleTextInputChange}
            /><br/>

            <TextInput source={"correo_electronico_socio"} label={"correo_electronico"} variant={"outlined"}
                       InputLabelProps={{shrink: true}} className={classes.correo_electronico}
                       onChange={handleTextInputChange} fullWidth/><br/>
            <TextInput source={"correo_electronico_alternativo_socio"} label={"correo_electronico_alternativo"}
                       variant={"outlined"} InputLabelProps={{shrink: true}}
                       className={classes.correo_electronico} onChange={handleTextInputChange} fullWidth/><br/>

            <SelectInput source="id_estado_civil"
                         label="¿Es casado?" InputLabelProps={{shrink: true}}
                         className={classes.estado_civil} onChange={handleTextInputChange}
                         fullWidth={true}
                         variant="outlined"
                         choices={[
                             {id: 'S', name: 'Si'},
                             {id: 'N', name: 'No'},
                         ]}
            />

            {id_estado_civil === 'S' &&
            <>
                <h3>Datos Cónyuge</h3>
                <TextInput source={"conyuge_nombre"} label={"nombre"} variant={"outlined"}
                           InputLabelProps={{shrink: true}} className={classes.nombre} onChange={handleTextInputChange}
                           fullWidth/>
                <TextInput source={"conyuge_apellido"} label={"apellido"} variant={"outlined"}
                           InputLabelProps={{shrink: true}} className={classes.apellido}
                           onChange={handleTextInputChange}
                           fullWidth/><br/>

                <ReferenceInput label="Tipo Doc." source="conyuge_id_documento_tipo" className={classes.documento_tipo}
                                onChange={handleTextInputChange} sort={{field: 'orden', order: 'DESC'}}
                                reference="DocumentoTipo" variant="outlined">
                    <SelectInput InputLabelProps={{shrink: true}} optionText="documento_tipo_d" fullWidth={true}
                                 className={classes.documento_tipo_d}
                    />
                </ReferenceInput>

                <TextInput source="conyuge_documento_nro" label={"Nro. Doc."} variant="outlined"
                           InputLabelProps={{shrink: true}} onChange={handleTextInputChange}
                           className={classes.documento_nro} fullWidth={true}/>

                <TextInput source="conyuge_cuit_cuil" label={"CUIT/CUIL"} variant="outlined"
                           InputLabelProps={{shrink: true}}
                           className={classes.cuit_cuil} fullWidth={true}
                           format={cuitCuilFormat} onChange={handleTextInputChange}
                />
            </>
            }
            <br/>

            <SelectInput source="socio_activo"
                         label="Activo" InputLabelProps={{shrink: true}}
                         className={classes.socio_activo} onChange={handleTextInputChange}
                         fullWidth={true}
                         variant="outlined"
                         choices={[
                             {id: 'S', name: 'Si'},
                             {id: 'N', name: 'No'},
                         ]}
            />

            <div>

                <BotonCrearDetelle/>

                <BtnCancelar/>


            </div>
        </div>
    );
}

export default ProveedorSocioAgregar;