import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import BuscarRubro from './BuscarRubrosList';
import BuscarClases from './BuscarClasesList';
import {makeStyles} from "@material-ui/core/styles";
import {useBuscadorContext} from "../Context/BuscadorProveedorClaseContextProvider";
import {
    SimpleForm, TabbedForm
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export default function HorizontalLabelPositionBelowStepper() {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const classes = useStyles();
    const {clasesList, limpiarClases, limpiarRubros} = useBuscadorContext();

    const getSteps = () => {
        return ['Seleccionar Rubros', "Seleccionar Clases", "Proveedores"];
    };

    const steps = getSteps();


    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <BuscarRubro/>
                );
            case 1:
                return (
                    <BuscarClases setActiveStep={setActiveStep}/>
                );
            // case 2:
            //     return (
            //         <>
            //             {clasesList.length > 0 ?
            //                 <SimpleForm toolbar={false}>
            //                     <VerProveedores/>
            //                 </SimpleForm>
            //                 :
            //                 <h4>No hay clases seleccionadas</h4>
            //             }
            //         </>
            //     );
        }
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        // let newSkipped = skipped;
        // if (isStepSkipped(activeStep)) {
        //     newSkipped = new Set(newSkipped.values());
        //     newSkipped.delete(activeStep);
        // }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        limpiarClases();
        // limpiarRubros();
    };

    return (
        <div>
            <Box sx={{width: '100%'}}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
            {activeStep !== 1 &&
            <div>
                <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                >
                    Volver
                </Button>

                <Button
                    variant="contained"
                    style={{color: '#ffffff', backgroundColor: '#123B80'}}
                    onClick={handleNext}
                    // className={classes.button}
                    disabled={activeStep === 2}
                >
                    {"Siguiente"}
                    {/*{activeStep === steps.length - 1 ? "Ver Proveedores" : "Siguiente"}*/}
                </Button>
            </div>
            }
            <div>
                <Typography className={classes.instructions}>
                    {getStepContent(activeStep)}
                </Typography>

            </div>
        </div>
    );
}