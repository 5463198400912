import React, {useState} from 'react';
import ComprobantesBusquedaDialogDataGrid from "./ComprobantesBusquedaDialogDataGrid"
import {Drawer} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import IconCancelar from "@material-ui/icons/Close";
import {TextField} from "react-admin";

export const ComprobantesBusquedaDialogBtn = (props) => {

    var w_devengado_porcentaje = props.record.devengado_porcentaje
    console.log('ComprobantesBusquedaDialogBtn----w_devengado_porcentaje')
    console.log(w_devengado_porcentaje)

    const useStyles = makeStyles({
        button: {
            justifyContent: 'left',
            display: 'inline-flex',
            fontWeight: 'bold',
            margin: 0,
            marginBottom: 0
        },
    });
    const classes = useStyles();

    const [openComprobantesBusqueda, setOpenComprobantesBusqueda] = useState(false);
    const [fullWidthComprobantesBusqued, setFullWidthComprobantesBusqued] = React.useState(true);
    const [maxWidthComprobantesBusqued, setMaxWidthComprobantesBusqued] = React.useState('lg');

    const handleDialogOpenComprobantesBusqueda = () => {
        setOpenComprobantesBusqueda(false);
    };

    return (
        <div>
            {w_devengado_porcentaje === 0 ? '' :
                <IconButton title={'Comprobantes'}
                    style={{color: '#123B80'}}
                            onClick={() => setOpenComprobantesBusqueda(!openComprobantesBusqueda)}>
                    <AssignmentIcon/>
                </IconButton>
            }
            <Drawer
                variant="persistent"
                anchor="right"
                open={openComprobantesBusqueda}
            >

                {openComprobantesBusqueda===true ?
                    <ComprobantesBusquedaDialogDataGrid
                        callbackComprobantes={props.callbackComprobantes}
                        orden_compra_nro={props.record.orden_compra_nro}
                        orden_compra_ampliacion_nro={props.record.orden_compra_ampliacion_nro}
                        unidad_negocio={props.record.unidad_negocio}
                        callbackCloseDialog={handleDialogOpenComprobantesBusqueda}
                    />
                : '' }
            </Drawer>
        </div>
    )
};
export default ComprobantesBusquedaDialogBtn;