import React from "react";

import {Show, TabbedForm, TextField} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const ProveedorRepresentanteShow = props => {

    return (
        <Show {...props}>
            <TabbedForm>
                <TextField label="Proveedor" source="pro" sortable={false}/>
                <TextField label="Nro Representante" source="representante_nro_ingreso" sortable={false}/>

            </TabbedForm>
        </Show>
    )
};
export default ProveedorRepresentanteShow;


