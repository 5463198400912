import React, {useEffect, useState, cloneElement} from 'react';
import {
    List,
    Datagrid,
    TextField,
    Responsive,
    CardActions,
    Filter,
    TextInput,
    NumberField,
    useDataProvider,
    useListContext,
    DateInput,
    SimpleForm,
    TopToolbar,
    sanitizeListRestProps,
    useMutation,
    SelectInput, ReferenceInput, DateField, WithPermissions
} from 'react-admin';
import withStyles from "@material-ui/core/styles/withStyles";
import TableroControlOrdenCompraGraficos from "./TableroControlOrdenCompraGraficos";
import Divider from "@material-ui/core/Divider";
import OrdenCompraCabeceraInfo from "../OrdenCompraCabecera/OrdenCompraCabeceraInfo";
import AuditoriaButton from "../componentes/AuditoriaButton";
// import DescargarExcel from "../Componentes/DescargarExcel";
import LinkMostrarButton from "../OrdenCompraCabecera/LinkMostrarButton"
import LinearProgresoDevengadoPorcentaje from "./LinearProgresoDevengadoPorcentaje"
import SeguimientoButton from "./SeguimientoButton"
import OrdenCompraPdfGeneradoButton from "../OrdenCompraCabecera/OrdenCompraPdfGeneradoButton";
import ExportExcelButton from "./ExportExcelButton";
import ComprobantesBusquedaDialogBtn from "./ComprobantesBusquedaDialogBtn"

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};

const MFiltro = (props, data, ids, baspath) => (
    <Filter {...props}>
        {/*<VerTotales label="Totales" props={props} alwaysOn/>*/}
        {/*<TextInput label="Buscar" source="q" variant="outlined" alwaysOn/>*/}
        <ReferenceInput label='Unidad'
                        source="id_unidad_negocio"
                        reference="UnidadNegocio"
                        onChange={(event,username,record) => console.log(`New deviceId `)}
                        alwaysOn
                        disabled={true}
        >
            <SelectInput
                optionText= "unidad_negocio_d"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <ReferenceInput label='Area'
                        source="id_unidad_area"
                        reference="UnidadArea"
                        onChange={(event,username,record) => console.log(`New deviceId `)}
                        alwaysOn
                        disabled={true}
        >
            <SelectInput
                optionText= "area_cd"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <ReferenceInput label='Encuadre'
                        source="id_contratacion_tipo"
                        reference="ContratacionTipo"
                        onChange={(event,username,record) => console.log(`New deviceId `)}
                        alwaysOn
                        disabled={true}
        >
            <SelectInput
                optionText= "contratacion_tipo"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <ReferenceInput label='Estado'
                        source="id_estado_orden_compra"
                        reference="EstadoOrdenCompra"
                        onChange={(event,username,record) => console.log(`New deviceId `)}
                        alwaysOn
                        disabled={true}
        >
            <SelectInput
                optionText= "estado_d"
                onClose={console.log('acatoy')}
            />
        </ReferenceInput>

        <TextInput disabled={true} label="Año" source="orden_compra_ano"   alwaysOn/>


    </Filter>
);

// const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter, selectedIds}) => {
//     return (
//         <CardActions>
//             {filters && React.cloneElement(filters, {
//                 resource,
//                 showFilter,
//                 displayedFilters,
//                 filterValues,
//                 context: 'button',
//             })}
//         </CardActions>
//     );
// }

const role_exportar_xls = ({permissions}) => {
    var w_role_control = '';
    var w_permissions_control = permissions;
    if (w_permissions_control == null) {
    } else {
        var w_role_exportar_xls = w_permissions_control.indexOf('ROLE_EXPORTAR_XLS');
        if (w_role_exportar_xls === -1) {
        } else {
            w_role_control = 'ROLE_EXPORTAR_XLS'
        }
    }
    return w_role_control
};

const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <TopToolbar>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <WithPermissions
            render={({permissions}) => (
                role_exportar_xls({permissions}) === 'ROLE_EXPORTAR_XLS'
                    ?
                    <ExportExcelButton total={999999} fileName={"TableroControlOrdenDeCompra"} sheetName={"TCOC"}/>
                    : null

            )}
        />
    </TopToolbar>
);

const VerTotales = ({props}) => {
}

const ListActions = ({
                         currentSort,
                         className,
                         resource,
                         filters,
                         displayedFilters,
                         exporter,
                         filterValues,
                         permanentFilter,
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showFilter,
                         maxResults,
                         total,
                         ...rest
                     }) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/*<CreateButton basePath={basePath} />*/}


        {/*JN Llamo a componente Descargar Excel*/}
        {/*<DescargarExcel w_controlador={'/TableroControlOrdenCompra'}*/}
        {/*                w_filtros={filterValues}*/}
        {/*                w_currentSort={currentSort}*/}
        {/*/>*/}

    </TopToolbar>
);

const role_orden_compra_tablero_mostrar_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if(w_permissions_control==null){}else{
        var w_role_orden_compra_tablero_mostrar = w_permissions_control.indexOf('ROLE_ORDEN_COMPRA_TABLERO_MOSTRAR')
        if(w_role_orden_compra_tablero_mostrar===-1){}else{
            w_role_control = 'ROLE_ORDEN_COMPRA_TABLERO_MOSTRAR'
        }
    }

    return w_role_control
}

const role_orden_compra_consulta_pdf_generado_permissions = ({permissions}) => {
    var w_role_orden_compra_consulta_pdf_generado_permissions = ''
    var w_permissions_orden_compra_consulta_pdf_generado = permissions
    if (w_permissions_orden_compra_consulta_pdf_generado == null) {
    } else {
        var w_role_orden_compra_consulta_pdf_generado = w_permissions_orden_compra_consulta_pdf_generado.indexOf('ROLE_ORDEN_COMPRA_CONSULTA_PDF_GENERADO')
        if (w_role_orden_compra_consulta_pdf_generado === -1) {
        } else {
            w_role_orden_compra_consulta_pdf_generado_permissions = 'ROLE_ORDEN_COMPRA_CONSULTA_PDF_GENERADO'
        }
    }

    return w_role_orden_compra_consulta_pdf_generado_permissions
}

const role_auditoria_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if(w_permissions_control==null){}else{
        var w_role_auditoria = w_permissions_control.indexOf('ROLE_AUDITORIA')
        if(w_role_auditoria===-1){}else{
            w_role_control = 'ROLE_AUDITORIA'
        }
    }

    return w_role_control
}


// Grilla general
export const TableroControlOrdenCompraList = withStyles(styles)(({classes, permissions, ...props}) => {

    const [EstadoGrafico, setEstadoGrafico] = useState(props.location.search);
    const [FiltroFecha, setFiltroFecha] = useState('');
    const [FechaDesde, setFechaDesde] = useState(0);
    const [FiltroUN, setFiltroUN] = useState('');

    /* const [handleDate] = useMutation(
         {
             type: 'update',
             resource: 'TableroControlOrdenCompra',
             payload: {id: 'estado_anterior'}
         },
         {
             undoable: true,
             onSuccess: ({data}) => {

             }
         })
 */

    const handleDate = (value) => {
        console.log("Div is clicked")
        console.log(value);
        setFechaDesde(value);
    }


    return (
        <div>
            <TableroControlOrdenCompraGraficos
                estado={EstadoGrafico}
                handleDate={() => handleDate()}
                fechaDesde={FechaDesde}
                FiltroUN={FiltroUN}
                setFiltroUN={setFiltroUN}
                callback={handleDate}
                // updateText={this.updateText}
                // handleToUpdate = {handleToUpdate.bind(this)}
                // onSelectLanguage={this.handleLanguage}
            />
            <br/>
            <List {...props}
                  title=" "
                // actions={<PostActions/>}
                  bulkActionButtons={false}
                // exporter={false}
                // filters={<MFiltro/>}
                  actions={<PostActions/>}
                  perPage={10}
                  empty={false}
                  sort={{ field: 'orden_compra_fecha', order: 'DESC' }}
                // filterDefaultValues={{ fecha_desde: FechaDesde, unidad_negocio_ing: FiltroUN}}
                // actions={<ListActions maxResults={50000}/>}
            >
                <Datagrid rowStyle={RowStyle}  expand={<OrdenCompraCabeceraInfo/>}>
                    <TextField label="ID" source="id"/>
                    <LinearProgresoDevengadoPorcentaje label="Devengado"/>
                    <ComprobantesBusquedaDialogBtn label="Comprob."/>
                    <TextField label="Número" source="orden_compra_nro"/>
                    <TextField label="Apl." source="orden_compra_ampliacion_nro"/>
                    <DateField label="Fecha" source="orden_compra_fecha"/>
                    <TextField label="Año" source="orden_compra_ano"/>
                    <TextField label="Unidad" source="unidad_negocio_d"/>
                    <TextField label="Emisora" source="emisora_d"/>
                    <TextField label="Area" source="area_d"/>
                    <TextField label="Tipo" source="contratacion_tipo"/>
                    <TextField label="Moneda" source="moneda_abre"/>
                    <NumberField label="Importe Total" source="importe_total"
                                 style={{textAlign: 'right', margin: '1em'}}
                                 options={{minimumFractionDigits: 2}}/>
                    <NumberField label="Importe Total Pesos" source="importe_total_pesos"
                                 style={{textAlign: 'right', margin: '1em'}}
                                 options={{minimumFractionDigits: 2}}/>
                    <TextField label="Es Apl." source="es_ampliacion"/>
                    <TextField label="Estado" source="orden_compra_estado"/>
                    {role_orden_compra_tablero_mostrar_permissions({permissions}) === 'ROLE_ORDEN_COMPRA_TABLERO_MOSTRAR' &&
                        <LinkMostrarButton label="Mostrar"/>
                    }
                    <SeguimientoButton label="Seguimiento"/>
                    {/*{role_auditoria_permissions({permissions}) === 'ROLE_AUDITORIA' &&*/}
                    {/*    <AuditoriaButton label="Auditoria" />*/}
                    {/*}*/}
                    {role_orden_compra_consulta_pdf_generado_permissions({permissions}) === 'ROLE_ORDEN_COMPRA_CONSULTA_PDF_GENERADO' &&
                        <OrdenCompraPdfGeneradoButton label="PDF Generado"/>
                    }
                </Datagrid>
            </List>
        </div>
    )
});

const RowStyle = (record, index) => ({
    backgroundColor: record.orden_compra_estado === undefined || record.estado_color === undefined ? '' :
        record.estado_color.trim(),
});


export default TableroControlOrdenCompraList;