import React, {useEffect, useState, useCallback} from 'react';
import {FormTab, useQuery, useNotify, useRefresh, useMutation, ReferenceInput, SelectInput} from 'react-admin';
import {
    TextInput,
} from 'react-admin';
import Button from '@mui/material/Button';
import IconCancelar from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    socio_nro_ingreso: {display: 'inline-block', width: 100},
    nombre: {display: 'inline-block', width: 150,},
    apellido: {display: 'inline-block', width: 150, marginLeft: 28},
    documento_tipo: {display: 'inline-block', width: 80},
    documento_nro: {display: 'inline-block', width: 120, marginLeft: 28},
    cuit_cuil: {display: 'inline-block', width: 150, marginLeft: 28},
    correo_electronico: {display: 'inline-block', width: 350},
    limite_para_oferta_electronica_apoderado: {display: 'inline-block', width: 80},
    limite_para_oferta_electronica_pesos: {display: 'inline-block', width: 120, marginLeft: 28},
    es_administrador_legitimado: {display: 'inline-block', width: 80},
    apoderado_activo: {display: 'inline-block', width: 80},
}));


function ProveedorApoderadoAgregar({pro, callbackCloseDrawer}) {
    // const [page, setPage] = useState(1);
    // const [perPage, setPerPage] = useState(25);
    // const [sort, setSort] = useState({field: 'id', order: 'ASC'});
    const [tieneLimitePesos, setTieneLimitePesos] = useState('N');
    const classes = useStyles();

    const [{
        proveedor,
        nombre_apoderado,
        apellido_apoderado,
        cuit_cuil_apoderado,
        id_documento_tipo_apoderado,
        documento_nro_apoderado,
        correo_electronico_apoderado,
        correo_electronico_alternativo_apoderado,
        limite_para_oferta_electronica_apoderado,
        limite_para_oferta_electronica_pesos,
        es_administrador_legitimado,
        apoderado_activo
    }, setState] = useState({
        proveedor: pro,
        nombre_apoderado: "",
        apellido_apoderado: "",
        cuit_cuil_apoderado: "",
        id_documento_tipo_apoderado: null,
        documento_nro_apoderado: "",
        correo_electronico_apoderado: "",
        correo_electronico_alternativo_apoderado: "",
        limite_para_oferta_electronica_apoderado: "N",
        limite_para_oferta_electronica_pesos: "",
        es_administrador_legitimado: "",
        apoderado_activo: ""
    });

    /// JN Mascara para el input cuit-cuil
    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    function handleTextInputChange({target: {name, value}}) {
        setState(prevState => ({...prevState, [name]: value}));
    }

    const BtnCancelar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" marginLeft="40px"
                onClick={() => {
                    callbackCloseDrawer(false)
                }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const BotonCrearDetelle = (record) => {
        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'create',
                resource: 'ProveedorApoderado',
                payload: {
                    data: {
                        proveedor,
                        nombre_apoderado,
                        apellido_apoderado,
                        cuit_cuil_apoderado,
                        id_documento_tipo_apoderado,
                        documento_nro_apoderado,
                        correo_electronico_apoderado,
                        correo_electronico_alternativo_apoderado,
                        tieneLimitePesos,
                        limite_para_oferta_electronica_pesos,
                        es_administrador_legitimado,
                        apoderado_activo
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <SaveIcon style={{color: '#004187'}}/>
                    Guardar
                </div>
            </Button>
        );

    };

    return (
        <div style={{paddingLeft: "10px"}}>
            <TextInput source={"nombre_apoderado"} label={"Nombre"} variant={"outlined"}
                       InputLabelProps={{shrink: true}}
                       className={classes.nombre} onChange={handleTextInputChange} fullWidth/>
            <TextInput source={"apellido_apoderado"} label={"Apellido"} variant={"outlined"}
                       InputLabelProps={{shrink: true}}
                       className={classes.apellido} onChange={handleTextInputChange} fullWidth/><br/>

            <ReferenceInput label="Tipo Doc." source="id_documento_tipo_apoderado"
                            className={classes.documento_tipo}
                            onChange={handleTextInputChange} sort={{field: 'orden', order: 'DESC'}}
                            reference="DocumentoTipo" variant="outlined">
                <SelectInput InputLabelProps={{shrink: true}} optionText="documento_tipo_d" fullWidth={true}
                             className={classes.documento_tipo_d}
                />
            </ReferenceInput>

            <TextInput source="documento_nro_apoderado" label={"Nro. Doc."} variant="outlined"
                       InputLabelProps={{shrink: true}} onChange={handleTextInputChange}
                       className={classes.documento_nro} fullWidth={true}/>

            <TextInput source="cuit_cuil_apoderado" label={"CUIT/CUIL"} variant="outlined"
                       InputLabelProps={{shrink: true}}
                       className={classes.cuit_cuil} fullWidth={true}
                       format={cuitCuilFormat} onChange={handleTextInputChange}
            /><br/>

            <TextInput source={"correo_electronico_apoderado"} label={"Corre Electrónico"} variant={"outlined"}
                       InputLabelProps={{shrink: true}} className={classes.correo_electronico}
                       onChange={handleTextInputChange} fullWidth/><br/>
            <TextInput source={"correo_electronico_alternativo_apoderado"} label={"Corre Electrónico Alternativo"}
                       variant={"outlined"} InputLabelProps={{shrink: true}}
                       className={classes.correo_electronico} onChange={handleTextInputChange} fullWidth/><br/>


            <SelectInput source="limite_para_oferta_electronica_apoderado"
                         label="¿Tiene límite para oferta electrónica?" InputLabelProps={{shrink: true}}
                         className={classes.limite_para_oferta_electronica_apoderado}
                         fullWidth={true}
                         variant="outlined"
                         inputProps={{
                             onChange: (e) => {
                                 setTieneLimitePesos(e.target.value);
                             }
                         }}
                         choices={[
                             {id: 'S', name: 'Si'},
                             {id: 'N', name: 'No'},
                         ]}
            />

            {tieneLimitePesos === 'S' &&
            <>
                < TextInput source={"limite_para_oferta_electronica_pesos"}
                            label={"Límite en pesos"}
                            variant={"outlined"}
                            InputLabelProps={{shrink: true}} className={classes.limite_para_oferta_electronica_pesos}
                            onChange={handleTextInputChange}
                            fullWidth={true}/>
            </>
            }

            <br/>

            <SelectInput source="es_administrador_legitimado"
                         label="Adm. Legitimado" InputLabelProps={{shrink: true}}
                         className={classes.es_administrador_legitimado}
                         fullWidth={true} onChange={handleTextInputChange}
                         variant="outlined"
                         choices={[
                             {id: 'S', name: 'Si'},
                             {id: 'N', name: 'No'},
                         ]}
            />

            <br/>

            <SelectInput source="apoderado_activo"
                         label="Activo" InputLabelProps={{shrink: true}}
                         className={classes.apoderado_activo}
                         fullWidth={true} onChange={handleTextInputChange}
                         variant="outlined"
                         choices={[
                             {id: 'S', name: 'Si'},
                             {id: 'N', name: 'No'},
                         ]}
            />

            <div>
                <BotonCrearDetelle/>
                <BtnCancelar/>
            </div>
        </div>
    );
}

export default ProveedorApoderadoAgregar;