import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput, TextField, NumberField, Datagrid
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";


const useStyles = makeStyles((theme) => ({
    contratacion_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    estado_encuadre_d: {display: 'inline-block', width: 800, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_estado_encuadre = values.estado_encuadre

    if (w_estado_encuadre===undefined) {
        errors.estado_encuadre = ['El campo Código es requerido'];
    }else {
        if (w_estado_encuadre === null) {
            errors.estado_encuadre = ['El campo Código es requerido'];
        } else {
            w_estado_encuadre = w_estado_encuadre.trim()
            if (w_estado_encuadre === '') {
                errors.estado_encuadre = ['El campo Código es requerido'];
            }
        }
    }


    var w_estado_encuadre_d = values.estado_encuadre_d

    if (w_estado_encuadre_d===undefined) {
        errors.estado_encuadre_d = ['El campo Descripción Contratacion Estado es requerido'];
    }else {
        if (w_estado_encuadre_d === null) {
            errors.estado_encuadre_d = ['El campo Descripción Contratacion Estado es requerido'];
        } else {
            w_estado_encuadre_d = w_estado_encuadre_d.trim()
            if (w_estado_encuadre_d === '') {
                errors.estado_encuadre_d = ['El campo Descripción Contratacion Estado es requerido'];
            }
        }
    }




    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/UnidadEmisora`;

const ContratacionEstadoCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Contratacion Estado</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS CONTRATACION ESTADO">




                    <TextInput
                        label="Código Contratacion Estado"
                        source="estado_encuadre"
                        formClassName={classes.estado_encuadre}
                        placeholder="Ingrese Código Contratacion Estado"
                        helperText="Código Contratacion Estado"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>
                    <TextInput
                        label="Descripción Contratacion Estado"
                        source="estado_encuadre_d"
                        formClassName={classes.estado_encuadre_d}
                        placeholder="Ingrese Descripción Contratacion Estado"
                        helperText="Descripción Contratacion Estado"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default ContratacionEstadoCreate;


