import React, {useCallback, useEffect, useState} from 'react'
import {TextInput, SimpleForm} from 'react-admin';

import {makeStyles} from "@material-ui/core";
import BtnAbrirArbolClases from "./BtnAbrirArbolClases";
import ProveedorClaseGrid from '../ProveedorClaseBuscador_ant/ProveedorClaseBuscadorGrid'

const useStyles = makeStyles((theme) => ({
    clase: {display: 'inline-block', width: 250}
}));

export const ProveedorClaseList = (props) => {
        const classes = useStyles();
        const [selectedClase, setSelectedClase] = useState('');
        const [selectedClaseDesc, setSelectedClaseDesc] = useState('');

        const handleDrawer = useCallback(async (who) => {
            setSelectedClase(who.id_clase);
            setSelectedClaseDesc(who.clase_d);

            if (who.id_clase) {
                return <ProveedorClaseGrid clase={selectedClase}/>
            }
        });

        const formatClase = v => {
            return selectedClaseDesc
        };


        return (
            <SimpleForm>
                <br/>
                <BtnAbrirArbolClases label={"Buscar Clase"}
                                     callbackClase={handleDrawer}
                />

                {selectedClase !== '' &&
                <>
                    <TextInput label={"Clase Filtrada"}
                               fullWidth={true}
                               className={classes.clase}
                               variant="filled" inputProps={{readOnly: true,}}
                               value={selectedClaseDesc}
                               format={formatClase}
                    />

                    <ProveedorClaseGrid clase={selectedClase}/>
                </>
                }
            </SimpleForm>
        )
    }
;


export default ProveedorClaseList;
