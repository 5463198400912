import React, {useState} from 'react';
import {Drawer} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';
import ProveedorBusquedaDatagrid from "./ProveedorBusquedaDatagrid";

export const ProveedorBusquedaBtn = (props) => {
    const useStyles = makeStyles({
        button: {
            justifyContent: 'left',
            display: 'inline-flex',
            fontWeight: 'bold',
            margin: 0,
            marginBottom: 0
        },
    });
    const classes = useStyles();

    const [open, setOpen] = useState(props.estadoDrawer);

    const handleDrawerOpen = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton color="primary" onClick={() => setOpen(!open)}>
                <Icon/>
            </IconButton>

            <Drawer
                variant="persistent"
                anchor="right"
                open={open}
            >
                <ProveedorBusquedaDatagrid
                    callbackProveedor={props.callbackProveedor}
                    callbackCloseDrawer={handleDrawerOpen}
                    estado_proveedor={props.estado_proveedor}
                />

            </Drawer>
        </div>
    )
};
export default ProveedorBusquedaBtn;