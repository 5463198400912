
import React, {useState, useEffect} from 'react'
import {DataGrid, GridToolbarContainer, GridToolbarQuickFilter} from '@mui/x-data-grid';
import {
    useDataProvider,
    useRedirect,
    useCreate,
    FormTab,
    ReferenceInput,
    useNotify,
    useRefresh,
    useMutation
} from 'react-admin';
import {useBuscadorContext} from "../Context/BuscadorProveedorClaseContextProvider";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconCancelar from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

const columns = [
    {field: 'persona_tipo_d', headerName: 'Rubro', width: 400},
    {field: 'persona_tipo', headerName: 'Clase', width: 400},
    {field: 'cantidad', headerName: 'Proveedores', width: 250}
];

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1)
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter/>
        </GridToolbarContainer>
    );
}

export const ProveedorDocumentacionCreate = ({setActiveStep, pro}) => {
    const classes = useStyles();
    const {addClases, rubrosList, limpiarClases, limpiarRubros} = useBuscadorContext();
    const redirectTo = useRedirect();
    const redirect = (basePath, id, data) => `/proveedorClaseBuscador/?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=10&sort=id`;

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedClases, setSelectedClases] = useState([]);
    const [data, setData] = useState([]);
    const [create, {isLoading, error}] = useCreate();

    const [errorpersona_tipo, setErrorpersona_tipo] = useState(false);
    const [helperTextpersona_tipo, setHelperTextpersona_tipo] = useState('');
    const [Estadopersona_tipo, setEstadopersona_tipo] = useState('')
    const [CombopersonaTipo, setCombopersonaTipo] = useState([]);

    const [errorpersona_forma, setErrorpersona_forma] = useState(false);
    const [selectpropsnativepersona_forma, setSelectPropsNativepersona_forma] = useState(false);
    const [helperTextpersona_forma, setHelperTextpersona_forma] = useState('');
    const [Estadopersona_forma, setEstadopersona_forma] = useState('')
    const [ComboPersonaForma, setComboPersonaForma] = useState([]);


    const dataProvider = useDataProvider();
    useEffect(() => {
        setLoading(true);
        dataProvider.getList(
            'PersonaTipo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'V' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setCombopersonaTipo(data);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(
                setLoading(false)
            )
    }, [rubrosList]);


    // useEffect(() => {
    //     console.log(rubrosList)
    // }, [rubrosList]);

    const handleActualizar = (async () => {
        alert(rubrosList)
        alert(Estadopersona_tipo)
        alert(Estadopersona_forma)

        if (rubrosList.length > 0) {
            await dataProvider.create('ProveedorTransferencia', {data: {
                    id_pro: rubrosList,
                    persona_tipo: Estadopersona_tipo,
                    persona_forma: Estadopersona_forma}});
            redirectTo(redirect);
            // setActiveStep(2);
        }

        // // await create('proveedorClaseBuscador', {clases: selectedClases});
        // if (selectedClases.length > 0) {
        //     await dataProvider.create('proveedorClaseBuscador', {data: {clases: selectedClases}});
        //     redirectTo(redirect);
        //     // setActiveStep(2);
        // }


    });

    const handlepersona_tipo = (event) => {
        const Cambiapersona_tipo1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadopersona_tipo(wp_value)



            setSelectPropsNativepersona_forma(true)

            dataProvider.getList(
                'PersonaForma',
                {
                    filter: {igual_persona_tipo: wp_value, usar_todos: 'V' },
                    sort: {field: 'id', order: 'DESC'},
                    pagination: {page: 1, perPage: 100},
                },)
                .then(({data}) => {
                    setComboPersonaForma(data);
                })
                .catch(error => {

                })

        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextpersona_tipo('');
        setErrorpersona_tipo(false)
        Cambiapersona_tipo1(w_value)

    };

    const handlepersona_forma = (event) => {
        const Cambiapersona_forma1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadopersona_forma(wp_value)
        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextpersona_forma('');
        setErrorpersona_forma(false)
        Cambiapersona_forma1(w_value)

    };

    const handleVolver = () => {
        setActiveStep(0);
        limpiarClases();
        limpiarRubros();
    };


    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '700px', width: '100%'}}>
            <div style={{flexDirection: 'row', alignItems: 'center', marginBottom: '5px'}}>


                <Button onClick={handleVolver}
                        className={classes.button}
                        variant="contained"
                        style={{color: '#2497FF'}}
                >VOLVER</Button>

                {rubrosList.length > 0 ?
                    <>
                        <Button onClick={handleActualizar}
                                variant="contained"
                                style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                className={classes.button}>
                            Agregar Varias Documentacion
                        </Button>
                    </>
                    :
                    <>
                        <Button disabled
                                variant="contained"
                            // style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                className={classes.button}>
                            Agregar Varias Documentacion
                        </Button>
                    </>
                }

            </div>


            {rubrosList.length > 0 ?
                <>
                    <Typography variant="h6">Persona Tipo</Typography>
                    <TextField
                        error={errorpersona_tipo}
                        id="persona_tipo_ing"
                        select
                        label=""
                        // size="small"
                        // value={currency}
                        formClassName={classes.persona_tipo}
                        value={Estadopersona_tipo}
                        onChange={handlepersona_tipo}
                        SelectProps={{
                            native: true,
                        }}
                        fullWidth={true}
                        variant="outlined"
                        helperText={helperTextpersona_tipo}
                    >
                        {CombopersonaTipo.map((option) => (
                            <option key={option.persona_tipo} value={option.persona_tipo}>
                                {option.persona_tipo_d}
                            </option>
                        ))}
                    </TextField>

                    <Typography variant="h6">Persona Forma</Typography>
                    <TextField
                        error={errorpersona_forma}
                        id="persona_forma_ing"
                        select
                        label=""
                        // size="small"
                        // value={currency}
                        formClassName={classes.persona_forma}
                        value={Estadopersona_forma}
                        onChange={handlepersona_forma}
                        SelectProps={{
                            native: selectpropsnativepersona_forma,
                        }}
                        fullWidth={true}
                        helperText={helperTextpersona_forma}
                        variant="outlined"
                        inputProps={{readOnly: true}}
                    >
                        {ComboPersonaForma.map((option) => (
                            <option key={option.persona_forma} value={option.persona_forma}>
                                {option.persona_forma_d}
                            </option>
                        ))}
                    </TextField>
                </>
                :
                <>
                    <Button disabled
                            variant="contained"
                        // style={{color: '#ffffff', backgroundColor: '#123B80'}}
                            className={classes.button}>
                        Debe Ingresar al menos una Documentacion
                    </Button>
                </>
            }
        </div>
    )
};

export default ProveedorDocumentacionCreate