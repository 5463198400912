import React, {useState} from "react";
import {
    FormTab,
    Show,
    TextInput,
    TabbedForm,
    TopToolbar,
    NumberInput,
    EditButton,
    ReferenceManyField, Datagrid, TextField, NumberField, usePermissions
} from 'react-admin';
import {makeStyles} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    contratacion_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    contratacion_d: {display: 'inline-block', width: 800, marginLeft: 202},
    monto_desde_pesos: {display: 'inline-block', width: 200, marginLeft: 8},
    monta_hasta_pesos: {display: 'inline-block', width: 200, marginLeft: 202},
    ultimo_nro_usado: {display: 'inline-block', width: 200, marginLeft: 404},
}));

const ContratacionTipoShow = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    var w_role_auxiliar_editar = ''
    var w_permissions_admuno_auxiliar_editar = permissions
    if(w_permissions_admuno_auxiliar_editar==null){}else{
        var wa_role_auxiliar_editar = w_permissions_admuno_auxiliar_editar.indexOf('ROLE_AUXILIAR_EDITAR')
        if(wa_role_auxiliar_editar===-1){}else{
            w_role_auxiliar_editar = 'ROLE_AUXILIAR_EDITAR'
        }
    }

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            {w_role_auxiliar_editar === 'ROLE_AUXILIAR_EDITAR' &&
                <EditButton basePath={basePath} record={data}/>
            }
        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        return <span>Contratacion Tipo: {record ? `${record.contratacion_tipo}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>
                <FormTab label="DATOS CONTRATACION TIPO">

                    <TextInput
                        label="Código Contratacion Tipo"
                        source="contratacion_tipo"
                        formClassName={classes.contratacion_tipo}
                        placeholder="Ingrese Código Contratacion Tipo"
                        helperText="Código Contratacion Tipo"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />



                    <TextInput
                        label="Descripción Contratacion Tipo"
                        source="contratacion_d"
                        formClassName={classes.contratacion_d}
                        placeholder="Ingrese Descripción Contratacion Tipo"
                        helperText="Descripción Contratacion Tipo"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>

                    <NumberInput
                        id="standard-full-width"
                        label="Monto Desde Pesos"
                        source="monto_desde_pesos"
                        formClassName={classes.monto_desde_pesos}
                        placeholder="Ingrese Monto Desde Pesos"
                        helperText="Monto Desde Pesos"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />


                    <NumberInput
                        id="standard-full-width"
                        label="Monta Hasta Pesos"
                        source="monta_hasta_pesos"
                        formClassName={classes.monta_hasta_pesos}
                        placeholder="Ingrese Monta Hasta Pesos"
                        helperText="Monta Hasta Pesos"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />



                    <NumberInput
                        id="standard-full-width"
                        label="Ultimo Nro Usado"
                        source="ultimo_nro_usado"
                        formClassName={classes.ultimo_nro_usado}
                        placeholder="Ingrese Ultimo Nro Usado"
                        helperText="Ultimo Nro Usado"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>
                </FormTab>
                {/*"Secuencia de la Contratación"*/}
                <FormTab label="SECUENCIA DE LA CONTRATACIÓN">
                    <ReferenceManyField
                        sort={{ field: 'secuencia_item', order: 'ASC' }}
                        perPage={500}
                        reference="ContratacionSecuencia"
                        target="id"
                        addLabel={false}
                    >
                        <Datagrid
                            rowStyle={RowStyle}
                            // expand={<ContratacionSecuenciaInfo/>}
                        >
                            <TextField label="Item" source="secuencia_item"
                                       style={{textAlign: 'right', margin: '1em' }}
                                       options={{ minimumFractionDigits: 0 }}
                            />
                            <TextField label="Tipo" source="secuencia_tipo" />
                            <NumberField label="Dias" source="secuencia_dias"
                                         style={{textAlign: 'right', margin: '1em' }}
                                         options={{ minimumFractionDigits: 0 }}
                            />
                            <TextField label="Descripción" source="secuencia_d" cellClassName={classes.title}/>
                        </Datagrid>
                    </ReferenceManyField>

                </FormTab>
            </TabbedForm>
        </Show>
    )
};

const RowStyle = (record, index) => ({
    backgroundColor: record.secuencia_tipo  === undefined || record.estado_color  === undefined ? '' :
        record.estado_color.trim(),
});

export default ContratacionTipoShow;


