import UserCopyCreate from './UserCopyCreate';
// import UserCopyEdit from './UserCopyEdit';
// import UserCopyShow from './UserCopyShow';
// import UserCopyList from './UserCopyList'



export default {
    create: UserCopyCreate,
    // edit: UserCopyEdit,
    // list: UserCopyList,
    // show: UserCopyShow

};
