import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField, useNotify, useRefresh, useMutation, FormTab, WithPermissions
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
import LineWeightIcon from "@material-ui/icons/LineWeight";
import SaveIcon from '@material-ui/icons/Save';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AddIcon from '@material-ui/icons/Add';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from "@material-ui/core/FormHelperText";

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const role_contratacion_visita_editar_tipo_encuadre = ({permissions}) => {
    var w_role_contratacion_visita_editar_tipo_encuadre = ''
    var w_permissions_admuno_contratacion_visita_editar_tipo_encuadre = permissions
    if (w_permissions_admuno_contratacion_visita_editar_tipo_encuadre == null) {
    } else {
        var wa_role_contratacion_visita_editar_tipo_encuadre = w_permissions_admuno_contratacion_visita_editar_tipo_encuadre.indexOf('ROLE_CONTRATACION_VISITA_EDITAR_TIPO_ENCUADRE')
        if (wa_role_contratacion_visita_editar_tipo_encuadre === -1) {
        } else {
            w_role_contratacion_visita_editar_tipo_encuadre = 'ROLE_CONTRATACION_VISITA_EDITAR_TIPO_ENCUADRE'
        }
    }
    return w_role_contratacion_visita_editar_tipo_encuadre
}

const EncuadreContratacionTipoButtonEdit = ({record}) => {
    var wr_id = record.id
    const [Encuadreid, setEncuadreid] = useState(wr_id)
    const dataProviderTableroControl = useDataProvider();
    const [EstadoCumplidosMostrarBoton, setEstadoCumplidosMostrarBoton] = useState('N')

    useEffect(() => {
        dataProviderTableroControl.getList(
            'ContratacionEncuadreSecuencia',
            {
                filter: {filtros: 'N', id: wr_id, usar_todos: 'V' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setEstadoCumplidosMostrarBoton(data[0].estadocumplidosmostrarboton);
            })
            .catch(error => {

            })
    }, [Encuadreid]);

    const [Estadocontratacion_tipo, setEstadocontratacion_tipo] = useState('')
    const [ComboManualContratacionTipo, setComboManualContratacionTipo] = useState([]);
    const [helperTextcontratacion_tipo, setHelperTextcontratacion_tipo] = useState('');
    const [Encuadreexpediente_referencia_gde, setEncuadreexpediente_referencia_gde] = useState('')


    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        var wr_id = record.id
        var wr_expediente_referencia_gde = record.expediente_referencia_gde
        var wr_contratacion_tipo_encuadre = record.contratacion_tipo_encuadre
        setEncuadreid(wr_id)
        setEncuadreexpediente_referencia_gde(wr_expediente_referencia_gde)
        setEstadocontratacion_tipo(wr_contratacion_tipo_encuadre)

        dataProviderTableroControl.getList(
            'ContratacionTipo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'N' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboManualContratacionTipo(data);
            })
            .catch(error => {

            })



        setOpen(true);
    };



    const handleClose = () => {
        setOpen(false);
    };


    const BotonCrearDetelle = (record) => {

        var w_mensaje = ' '

        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'update',
                resource: 'ContratacionEncuadreVisita',
                payload: {
                    id: Encuadreid, data: {
                        usar_contratacion_tipo: 'S',
                        expediente_referencia_gde: Encuadreexpediente_referencia_gde,
                        contratacion_tipo_encuadre: Estadocontratacion_tipo
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                    // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------

                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <SaveIcon style={{color: '#004187'}}/>
                    Guardar
                </div>
            </Button>
            // <Button label={w_mensaje}
            //         title={w_mensaje}
            //         onClick={generardetelle} disabled={loading}
            //         color="primary"
            // >
            //     <div>
            //         <LineWeightIcon style={{fontSize: 40, backgroundColor: '#ffffff', color: '#004187'}}/>
            //     </div>
            // </Button>
        );

    };

    const handlecontratacion_tipo = (event) => {
        const Cambiacontratacion_tipo1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadocontratacion_tipo(wp_value)
        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextcontratacion_tipo('')
        Cambiacontratacion_tipo1(w_value)

    };


    if(EstadoCumplidosMostrarBoton==='N'){
        return (
            <div>
            </div>
        );

    }else {
        return (
            <div>
                <WithPermissions
                    render={({permissions}) => (
                        role_contratacion_visita_editar_tipo_encuadre({permissions}) === 'ROLE_CONTRATACION_VISITA_EDITAR_TIPO_ENCUADRE'
                            ?
                            <div>
                                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                                    <div title={''}
                                         style={{
                                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                         }}>
                                        <EditIcon style={{color: '#004187'}}/>
                                        Editar Encuadre
                                    </div>
                                </Button>
                                <Dialog open={open} maxWidth={'xl'} aria-labelledby="form-dialog-title">
                                    <DialogTitle id="form-dialog-title">Encuadres</DialogTitle>
                                    <DialogContent>
                                        <form>
                                            <label>
                                                <Table aria-label="custom pagination table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Typography variant="h6">Contratacion Tipo</Typography>
                                                                <TextField
                                                                    id="contratacion_tipo_ing"
                                                                    select
                                                                    label=""
                                                                    // size="small"
                                                                    // value={currency}
                                                                    formClassName={classes.contratacion_tipo}
                                                                    value={Estadocontratacion_tipo}
                                                                    onChange={handlecontratacion_tipo}
                                                                    SelectProps={{
                                                                        native: true,
                                                                    }}
                                                                    fullWidth={true}
                                                                    // helperText="Por favor seleccione una Moneda"
                                                                >
                                                                    {ComboManualContratacionTipo.map((option) => (
                                                                        <option key={option.contratacion_tipo}
                                                                                value={option.contratacion_tipo}>
                                                                            {option.contratacion_d}
                                                                        </option>
                                                                    ))}
                                                                </TextField>
                                                                <FormHelperText style={{color: '#ff0000', backgroundColor: '#ffffff'}}>
                                                                    {helperTextcontratacion_tipo}
                                                                </FormHelperText>
                                                                <br/>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </label>
                                        </form>
                                    </DialogContent>
                                    <DialogActions>
                                        <BotonCrearDetelle/>
                                        <Button variant="outlined" color="primary" onClick={handleClose}>
                                            <div title={''}
                                                 style={{
                                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                                 }}>
                                                <CancelPresentationIcon style={{color: '#004187'}}/>
                                                Cancelar
                                            </div>
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            : null
                    )}
                />
            </div>
        );
    }

}


export default EncuadreContratacionTipoButtonEdit;