import React from 'react';
import compose from 'recompose/compose';
import List from '@material-ui/core/List';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {translate} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const VerInfo = ({
                     visitors = [], nb, translate,
                     ver_concepto_descripcion,
                     ver_estado_encuadre,
                     ver_estado_encuadre_d,
                     ver_en_progreso,
                     ver_en_progreso_motivo,
                     ver_en_impugnacion,
                     ver_en_impugnacion_motivo,
                     ver_en_suspenso,
                     ver_en_suspenso_motivo,
                     ver_en_revision,
                     ver_en_revision_motivo,
                     ver_en_anulado,
                     ver_en_anulado_motivo,
                     ver_en_finalizado,
                     ver_en_finalizado_motivo,
                     ver_visita_usuario,
                     ver_visita_usuario_fh,
                     ver_unidad_area_requiere,
                     ver_unidad_area_d,
                     ver_moneda_cotizacion,
                     ver_moneda_cotizacion_fecha,
                     ver_importe_adjudicado_pesos,
                     ver_es_tablero,
                     ver_contratacion_tipo,
                     ver_contratacion_d,
                     classes
                 }) => (
    <div>
        <List>
            <Table style={{backgroundColor: '#F2F3F4'}}>
                <TableRow>
                    <TableCell className={classes.textleft}>
                        <Typography>
                            <b>Encuadre: </b> {ver_contratacion_tipo}
                        </Typography>
                        <Typography>
                            {ver_contratacion_d}
                        </Typography>
                        <Typography>
                            <b>Concepto: </b> {ver_concepto_descripcion}
                        </Typography>
                        <Typography>
                            <b>Estado Actual: </b> {ver_estado_encuadre}
                        </Typography>
                        <Typography>
                            {ver_estado_encuadre_d}
                        </Typography>
                        {/*<Typography>*/}
                        {/*    <b>Contratacion: </b> {ver_contratacion_tipo}*/}
                        {/*</Typography>*/}
                        <Typography><u><b>Estados:</b></u></Typography>
                        <Typography>
                            <b>En Progreso: </b> {ver_en_progreso}
                        </Typography>
                        <Typography>
                            {ver_en_progreso_motivo}
                        </Typography>
                        <Typography>
                            <b>En Impugnación: </b> {ver_en_impugnacion}
                        </Typography>
                        <Typography>
                            {ver_en_impugnacion_motivo}
                        </Typography>
                        <Typography>
                            <b>En Suspenso: </b> {ver_en_suspenso}
                        </Typography>
                        <Typography>
                            {ver_en_suspenso_motivo}
                        </Typography>
                        <Typography>
                            <b>En Revisión: </b> {ver_en_revision}
                        </Typography>
                        <Typography>
                            {ver_en_revision_motivo}
                        </Typography>
                        <Typography>
                            <b>Anulado: </b> {ver_en_anulado}
                        </Typography>
                        <Typography>
                            {ver_en_anulado_motivo}
                        </Typography>
                        <Typography>
                            <b>Finalizado: </b> {ver_en_finalizado}
                        </Typography>
                        <Typography>
                            {ver_en_finalizado_motivo}
                        </Typography>
                        <Typography>
                            <b>Visita Usuario: </b> {ver_visita_usuario}
                        </Typography>
                        <Typography>
                            <b>Visita Fecha Hora: </b> {ver_visita_usuario_fh}
                        </Typography>
                        {ver_es_tablero==='N' ?
                            <div>
                                {/*<Typography>*/}
                                {/*    <b>Area: </b>*/}
                                {/*    /!*{ver_unidad_area_requiere} *!/*/}
                                {/*    {ver_unidad_area_d}*/}
                                {/*</Typography>*/}
                                {/*<Typography>*/}
                                {/*    <b>Cotización: </b>*/}
                                {/*    {ver_moneda_cotizacion === undefined ? '0,00000' :*/}
                                {/*        ver_moneda_cotizacion.toLocaleString(undefined, {*/}
                                {/*        minimumFractionDigits: 5,*/}
                                {/*        maximumFractionDigits: 16,*/}
                                {/*    })}*/}
                                {/*</Typography>*/}
                                <Typography>
                                    <b>Fecha Ctz.: </b> {ver_moneda_cotizacion_fecha}
                                </Typography>
                                <Typography>
                                    <b>Importe Adjudicado Pesos: </b>
                                    {ver_importe_adjudicado_pesos === undefined ? '0,00' :
                                        ver_importe_adjudicado_pesos.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 16,
                                    })}
                                </Typography>
                            </div>
                            :
                            <Typography>

                            </Typography>
                        }

                    </TableCell>
                </TableRow>
            </Table>
        </List>

    </div>

);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(VerInfo);

