import OrdenCompraDetalleInfo from "../OrdenCompraCabecera/OrdenCompraDetalleInfo";
import OrdenCompraDetalleButtonShow from "../OrdenCompraCabecera/OrdenCompraDetalleButtonShow";
import OrdenCompraDetalleButtonDelete from "../OrdenCompraCabecera/OrdenCompraDetalleButtonDelete";
import React, {useCallback, useState} from "react";
import {
    useQuery,
    Datagrid,
    TextField, NumberField, Pagination,
    Loading, ListContextProvider, TextInput, ReferenceInput, SelectInput, NumberInput, DateInput

} from 'react-admin';
import keyBy from 'lodash/keyBy';
import {makeStyles} from "@material-ui/core";
import OCDetalleBtnAccionGrid from "./OCDetalleBtnAccionGrid";
import {Grid} from '@mui/material';
import OCDetalleForm from "./OCDetalleForm";

const useStyles = makeStyles((theme) => ({
    // Uso interno
    moneda_cotizacion: {display: 'inline-block', width: 120, marginLeft: 32},
    moneda_cotizacion_fecha: {display: 'inline-block', width: 160, marginLeft: 32},

    precio_incluye_iva: {display: 'inline-block', width: 100},
    importe_subtotal: {display: 'inline-block', width: 250},
    importe_iva: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_total: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_subtotal_pesos: {display: 'inline-block', width: 250},
    importe_iva_pesos: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_total_pesos: {display: 'inline-block', width: 250, marginLeft: 32},
}));

export const OCDetalleDataGrid = ({data, callbackHayCambios}) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})
    const [filter, setFilter] = useState('');
    const classes = useStyles();

    const handleHayCambios = useCallback((who) => {
        callbackHayCambios(who);
    });


    return (
        <ListContextProvider value={{data, page, perPage, setPage, filter, setFilter, sort}}>
            <>
                <Datagrid
                    data={keyBy(data, 'id')}
                    ids={data.map(({id}) => id)}
                    currentSort={sort}
                    setSort={(field, order) => setSort({field, order})}
                    expand={<OrdenCompraDetalleInfo/>}
                >
                    <TextField label="Item" source="item"
                               style={{textAlign: 'right'}}
                               options={{minimumFractionDigits: 0}}/>
                    <TextField label="Concepto" source="concepto_requerimiento"/>
                    <TextField label="Clase" source="clase"/>
                    <TextField label="Renglon" source="renglon_nro"/>
                    <TextField label="Renglon Descripción" source="renglon_descripcion"
                               cellClassName={classes.title}/>
                    <TextField label="Moneda" source="moneda_abre_detalle"/>
                    <NumberField label="Total Ítem" source="total_item"
                                 style={{textAlign: 'right'}}
                                 options={{minimumFractionDigits: 2}}/>
                    <NumberField label="Total Ítem $" source="total_item_pesos"
                                 style={{textAlign: 'right'}}
                                 options={{minimumFractionDigits: 2}}
                    />


                    {/*Show*/}
                    <OCDetalleBtnAccionGrid
                        callbackHayCambios={callbackHayCambios}
                        accion={'SHOW'}
                        isReadOnly={true}

                    />

                    {/*Editar*/}
                    <OCDetalleBtnAccionGrid
                        callbackHayCambios={callbackHayCambios}
                        accion={'EDIT'}
                        isReadOnly={false}
                    />

                    {/* Eliminar */}
                    <OrdenCompraDetalleButtonDelete label=""
                                                    callbackHayCambios={callbackHayCambios}
                    />
                </Datagrid>

            </>
        </ListContextProvider>
    )
};

//
// const OCButtonEditar = ({record}) => (
//
//
// );


export default OCDetalleDataGrid;