// import React, {useState, useEffect} from 'react'
// import Box from '@mui/material/Box';
// import Stepper from "@material-ui/core/Stepper";
// import Step from "@material-ui/core/Step";
// import StepLabel from "@material-ui/core/StepLabel";
// import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";
// import BuscarProveedores from './BuscarProveedoresList';
// import ProveedorTransferenciaCreate from './ProveedorTransferenciaCreate';
// import {makeStyles} from "@material-ui/core/styles";
// import {useBuscadorProveTransfContext} from "../Context/BuscadorProveedorClaseContextProvider";
// import {
//     SimpleForm, TabbedForm
// } from 'react-admin';
//
//
// const useStyles = makeStyles((theme) => ({
//     root: {
//         width: "100%"
//     },
//     button: {
//         marginRight: theme.spacing(1)
//     },
//     instructions: {
//         marginTop: theme.spacing(1),
//         marginBottom: theme.spacing(1)
//     }
// }));
//
// export default function ProveedorTransferenciaList() {
//     const [activeStep, setActiveStep] = useState(0);
//     const [skipped, setSkipped] = useState(new Set());
//     const classes = useStyles();
//     const {nuevoList, limpiarNuevo, limpiarCuitCuil} = useBuscadorProveTransfContext();
//
//     const getSteps = () => {
//         return ['Seleccionar Proveedores a Transferir', "Nuevo Proveedores a Transferir"];
//     };
//
//     const steps = getSteps();
//
//
//     const getStepContent = (step) => {
//         switch (step) {
//             case 0:
//                 return (
//                     <BuscarProveedores/>
//                 );
//             case 1:
//                 return (
//                     <ProveedorTransferenciaCreate setActiveStep={setActiveStep}/>
//                 );
//             // case 2:
//             //     return (
//             //         <>
//             //             {nuevoList.length > 0 ?
//             //                 <SimpleForm toolbar={false}>
//             //                     <VerProveedores/>
//             //                 </SimpleForm>
//             //                 :
//             //                 <h4>No hay nuevo seleccionadas</h4>
//             //             }
//             //         </>
//             //     );
//         }
//     };
//
//     const isStepSkipped = (step) => {
//         return skipped.has(step);
//     };
//
//     const handleNext = () => {
//         // let newSkipped = skipped;
//         // if (isStepSkipped(activeStep)) {
//         //     newSkipped = new Set(newSkipped.values());
//         //     newSkipped.delete(activeStep);
//         // }
//
//         setActiveStep((prevActiveStep) => prevActiveStep + 1);
//         // setSkipped(newSkipped);
//     };
//
//     const handleBack = () => {
//         setActiveStep((prevActiveStep) => prevActiveStep - 1);
//         limpiarNuevo();
//         // limpiarCuitCuil();
//     };
//
//     return (
//         <div>
//             <Box sx={{width: '100%'}}>
//                 <Stepper activeStep={activeStep}>
//                     {steps.map((label, index) => {
//                         const stepProps = {};
//                         const labelProps = {};
//                         if (isStepSkipped(index)) {
//                             stepProps.completed = false;
//                         }
//                         return (
//                             <Step key={label} {...stepProps}>
//                                 <StepLabel {...labelProps}>{label}</StepLabel>
//                             </Step>
//                         );
//                     })}
//                 </Stepper>
//             </Box>
//             {activeStep !== 1 &&
//             <div>
//                 <Button
//                     disabled={activeStep === 0}
//                     onClick={handleBack}
//                     className={classes.button}
//                 >
//                     Volver
//                 </Button>
//
//                 <Button
//                     variant="contained"
//                     style={{color: '#ffffff', backgroundColor: '#123B80'}}
//                     onClick={handleNext}
//                     // className={classes.button}
//                     disabled={activeStep === 2}
//                 >
//                     {"Siguiente"}
//                     {/*{activeStep === steps.length - 1 ? "Ver Proveedores" : "Siguiente"}*/}
//                 </Button>
//             </div>
//             }
//             <div>
//                 <Typography className={classes.instructions}>
//                     {getStepContent(activeStep)}
//                 </Typography>
//
//             </div>
//         </div>
//     );
// }

import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import BuscarProveedores from './BuscarProveedoresList';
import ProveedorTransferenciaCreate from './ProveedorTransferenciaCreate';
import {makeStyles} from "@material-ui/core/styles";
import {useBuscadorContext} from "../Context/BuscadorProveedorClaseContextProvider";
import {
    SimpleForm, TabbedForm, useDataProvider, useNotify, useRedirect, useCreate, useRefresh
} from 'react-admin';
import Porcentaje from "../componentes/Porcentaje";
import ApiUrlServer from '../layout/ApiUrlServer'

var w_apiurlserver = ApiUrlServer;
if (w_apiurlserver == null) {
    w_apiurlserver = ''
}
w_apiurlserver = w_apiurlserver.trim();


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export default function HorizontalLabelPositionBelowStepper() {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const classes = useStyles();
    const {clasesList, rubrosList, limpiarClases, limpiarRubros} = useBuscadorContext();
    const [create] = useCreate('ProveedorTransferencia');
    const notify = useNotify();
    const refresh = useRefresh()

    const getSteps = () => {
        return ['Seleccionar Proveedores a Transferir'];
        // return ['Seleccionar Proveedores a Transferir', "Nuevo Proveedores a Transferir"];
    };

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})
    const [EstadoPorcentaje, setEstadoPorcentaje] = useState(0);
    const [EstadoTiempo, setEstadoTiempo] = useState('');
    const [EstadoPar, setEstadoPar] = useState('N');
    const [EstadoCantidad, setEstadoCantidad] = useState('');
    const [Estadoidmlsel, setEstadoidmlsel] = useState('');

    const steps = getSteps();
    const dataProvider = useDataProvider();
    const redirectTo = useRedirect();
    const redirect = (basePath, id, data) => `/proveedor/?filter=%7B%7D&order=DESC&page=1&perPage=10&sort=pro`;

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <BuscarProveedores/>
                );
            case 1:
                return (
                    <ProveedorTransferenciaCreate setActiveStep={setActiveStep}/>
                );
            // case 2:
            //     return (
            //         <>
            //             {clasesList.length > 0 ?
            //                 <SimpleForm toolbar={false}>
            //                     <VerProveedores/>
            //                 </SimpleForm>
            //                 :
            //                 <h4>No hay clases seleccionadas</h4>
            //             }
            //         </>
            //     );
        }
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        // let newSkipped = skipped;
        // if (isStepSkipped(activeStep)) {
        //     newSkipped = new Set(newSkipped.values());
        //     newSkipped.delete(activeStep);
        // }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        limpiarClases();
        // limpiarRubros();
    };


    const handleActualizar  = () => {
        var w_selectedIds = rubrosList
        var mseg = 1000 ;
        var msegMinuto = mseg * 60;
        var msegHora = msegMinuto * 60;
        var msegDia = msegHora * 24;
        var hoy = new Date()
        var wl_selectedIds = w_selectedIds.length
        var w_cant = 0
        var w_es_par
        var w_inicio_porcentaje = 0
        setEstadoPorcentaje(w_inicio_porcentaje);
        if (wl_selectedIds > 0) {
            var i = 0;
            function next() {
                if (i < wl_selectedIds) {
                    var w_selectedId_can = w_selectedIds[i]
                    w_cant = w_cant + 1

                    if(w_cant % 2 === 0  ){
                        w_es_par = 'S'
                    }else{
                        w_es_par = 'N'
                    }
                    setEstadoPar(w_es_par)

                    var w_idml_sel = '('+w_selectedIds[i]+')'
                    w_idml_sel = ''
                    setEstadoidmlsel(w_idml_sel)

                    var w_res_cantidad = ' ('+w_cant+'/'+wl_selectedIds+')'
                    setEstadoCantidad(w_res_cantidad)

                    var tiempo = new Date() - hoy

                    //calcular dias
                    var dias = Math.floor(tiempo / msegDia)
                    tiempo = tiempo - (dias * msegDia)

                    //calcula horas
                    var horas = Math.floor(tiempo / msegHora)
                    tiempo = tiempo - (horas * msegHora)

                    //calcula minutos
                    var minutos = Math.floor(tiempo /msegMinuto)
                    tiempo = tiempo - (minutos * msegMinuto)

                    //calcula segundos
                    var segundos = Math.floor(tiempo /mseg)
                    tiempo = tiempo - (segundos * mseg)

                    var w_mostrar_tiempo = (horas < 10 ? "0" + horas : horas) + ":" + (minutos < 10 ? "0" + minutos : minutos) + ":" + (segundos < 10 ? "0" + segundos : segundos)
                    // console.log(`Han pasado ${dias} dias ${horas} horas y ${minutos} minutis con ${segundos} segundo`)
                    setEstadoTiempo(w_mostrar_tiempo)

                    let w_generar_articulosml
                    const Selecciona = new FormData()
                    Selecciona.append('id', w_selectedId_can);
                    Selecciona.append('cantidad', w_cant);
                    Selecciona.append('cantidad_total', wl_selectedIds);

                    let wp_porcentaje = 0
                    if(wl_selectedIds===0){}else {
                        wp_porcentaje = ((w_cant / wl_selectedIds) * 100)
                        wp_porcentaje = Math.round(wp_porcentaje * 100) / 100
                    }
                    if(wp_porcentaje >= 100) {
                        setEstadoPorcentaje(wp_porcentaje);
                    }

                    dataProvider.getList(
                        'ProveedorTransferenciaCreate',
                        {
                            filter: {
                                id_pro: w_selectedId_can,
                                cantidad: w_cant, cantidad_total: wl_selectedIds,
                                apiurlserver: w_apiurlserver
                            },
                            sort,
                            pagination: {page: page, perPage: perPage},
                        },)
                        .then(({data}) => {

                            // console.log('data------ProveedorTransferencia')
                            // console.log(data)
                            // console.log('response.data.porcentaje------ProveedorTransferencia')
                            // console.log(data[0].porcentaje)
                            let w_error = data[0].error
                            let w_mensaje = data[0].mensaje
                            let w_porcentaje = data[0].porcentaje
                            let w_termino = data[0].termino
                            if (w_error === 'N') {
                                w_generar_articulosml = 'P'
                            } else {
                                notify(`Atención: ${w_mensaje}`, 'warning')

                            }
                            if (w_generar_articulosml === 'P') {
                                if (wl_selectedIds === 0) {
                                } else {
                                    setEstadoPorcentaje(w_porcentaje);
                                }
                                if (w_termino === 'S') {
                                    var wp_inicio_porcentaje = 0
                                    setEstadoPorcentaje(wp_inicio_porcentaje);
                                    redirectTo(redirect)
                                    notify('Proveedores Transferidos de los elementos Seleccionados', 'info', {}, false);
                                    refresh()
                                } else {
                                    i++;
                                    next();
                                }
                            }
                        })
                        .catch(error => {
                            wl_selectedIds = [];
                            if (error.response) {
                                //HTTP error happened
                                notify(`Error al Subir. Código de estado / error HTTP: ${error.response.status}`, 'warning')
                                // console.log("Upload error. HTTP error/status code=", error.response.status);
                            } else {
                                //some other error happened
                                notify(`Error al Subir. Código de mensaje / error HTTP: ${error.message}`, 'warning')
                                // console.log("Upload error. HTTP error/message code=", error.message);
                            }
                        })
                } else {
                    // last one done
                    wl_selectedIds = [];
                }
            }
            // kick off the first one
            next();
        }
    }


    return (
        <div>
            <Box sx={{width: '100%'}}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
            {activeStep !== 1 &&
            <div>
                {/*<Button*/}
                {/*    disabled={activeStep === 0}*/}
                {/*    onClick={handleBack}*/}
                {/*    className={classes.button}*/}
                {/*>*/}
                {/*    Volver*/}
                {/*</Button>*/}

                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    style={{color: '#ffffff', backgroundColor: '#123B80'}}*/}
                {/*    onClick={handleNext}*/}
                {/*    // className={classes.button}*/}
                {/*    disabled={activeStep === 2}*/}
                {/*>*/}
                {/*    {"Siguiente"}*/}
                {/*    /!*{activeStep === steps.length - 1 ? "Ver Proveedores" : "Siguiente"}*!/*/}
                {/*</Button>*/}

                {rubrosList.length > 0 ?
                    <>
                        <Button onClick={handleActualizar}
                                variant="contained"
                                style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                className={classes.button}>
                            Proveedores a Transferir
                        </Button>
                    </>
                    :
                    <>
                        <Button disabled
                                variant="contained"
                            // style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                className={classes.button}>
                            Proveedores a Transferir
                        </Button>
                    </>
                }

                <Porcentaje  porcentaje={EstadoPorcentaje}
                             tiempo={EstadoTiempo}
                             espar={EstadoPar}
                             cantidad={EstadoCantidad}
                             id_ml_sel={Estadoidmlsel}/>

            </div>
            }
            <div>
                <Typography className={classes.instructions}>
                    {getStepContent(activeStep)}
                </Typography>

            </div>
        </div>
    );
}