import React, {useState} from 'react'
import {
    Loading,
    useQuery,
    Datagrid,
    TextField,
    Pagination,
    DateField,
    useRefresh,
    useDelete,
    Button,
    useDataProvider,
    useNotify, usePermissions
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import ProveedorDocumentacionEdit from "../proveedorDocumentacion/ProveedorDocumentacionEdit";
import IconEliminar from "@material-ui/icons/Delete";
import BtnProveedorDocumentacionAdjunto from "../ProveedorDocumentacionAdjunto/BtnProveedorDocumentacionAdjunto";
import LinkSubirArchivo from "../ProveedorDocumentacionAdjunto/LinkSubirArchivo"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LinkProveedorDocumentacionMod from "../proveedorDocumentacionMod/LinkProveedorDocumentacionMod";

const role_proveedor_eliminar_documentacion_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_proveedor_eliminar_documentacion = w_permissions_control.indexOf('ROLE_PROVEEDOR_ELIMINAR_DOCUMENTACION')
        if (w_role_proveedor_eliminar_documentacion === -1) {
        } else {
            w_role_control = 'ROLE_PROVEEDOR_ELIMINAR_DOCUMENTACION'
        }
    }

    return w_role_control
}


export const ProveedorDocumentacionList = (props) => {
    const {permissions} = usePermissions();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'ProveedorDocumentacion',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: props.pro,
        }
    });

    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    // Eliminar Fila
    const BtnEliminar_ant = ({record}) => {
        const refresh = useRefresh();

        const [deleteOne, {isLoading, error}] = useDelete(
            'proveedorDocumentacion',
            record.id
        );
        const handleClick = () => {
            deleteOne();
            refresh();
        };
        if (error) {
            return <p>ERROR</p>;
        }
        return <Button variant="text" size={"small"} color="primary"
                       onClick={handleClick}
                       disabled={loading}
                       label={'Eliminar'}
        >
            <IconEliminar style={{color: 'red'}}/>
        </Button>
    };

    // Eliminar Fila
    const BtnEliminar = ({record}) => {
        var wr_id = record.id

        const dataProvider = useDataProvider();
        const notify = useNotify();
        const refresh = useRefresh();
        const [openEliminar, setOpenEliminar] = React.useState(false);

        const handleClickOpenEliminar = () => {
            // dataProvider.getList(
            //     'proveedorDocumentacionEliminar',
            //     {
            //         filter: {
            //             id_adjunto: wr_id
            //         },
            //         sort: {field: 'id', order: 'DESC'},
            //         pagination: {page: 1, perPage: 100},
            //     },)
            //     .then(({data}) => {
            //         var wec_validacion = data[0].validacion
            //         if(wec_validacion==null){
            //             wec_validacion=''
            //         }
            //         wec_validacion=wec_validacion.trim()
            //         var wec_mensaje = data[0].mensaje
            //         if (wec_validacion === 'E') {
            //             notify(wec_mensaje, 'info', {}, false);
            //         } else {
            setOpenEliminar(true);
            //     }
            //
            // })
            // .catch(error => {
            //
            // })

        };

        const BotonEliminarDetelle = (record) => {

            const refresh = useRefresh();

            const [deleteOne, {isLoading, error}] = useDelete(
                'proveedorDocumentacion',
                wr_id
            );
            const handleClickEliminar = () => {
                deleteOne();
                refresh();
            };
            if (error) {
                return <p>ERROR</p>;
            }
            return (
                <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                        onClick={handleClickEliminar}>
                    <div title={''}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        Si
                    </div>
                </Button>
            );

        };

        const handleNoEliminar = () => {
            setOpenEliminar(false);
        };

        return (
            <div>
                <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                        onClick={handleClickOpenEliminar}>
                    <div title={'Eliminar Documentación'}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        <DeleteForeverIcon style={{color: '#c00000', backgroundColor: '#ffffff'}}/>
                    </div>
                </Button>
                <Dialog open={openEliminar} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Documentación</DialogTitle>
                    <DialogContent>
                        ¿Seguro que quiere eliminar esta Documentación?
                    </DialogContent>
                    <DialogActions>
                        <BotonEliminarDetelle/>
                        <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                onClick={handleNoEliminar}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                NO
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    return (
        <div>
            <Datagrid
                data={keyBy(data, 'id')}
                ids={data.map(({id}) => id)}
                currentSort={sort}
                setSort={(field, order) => setSort({field, order})}
            >
                <TextField source="documentacion_nro_ingreso" label={"Nro Ingreso"}/>
                <TextField source="documentacion_tipo" label={"Tipo"}/>
                <TextField source="documentacion_tipo_d" label={"Detalle"}/>
                <TextField source="documentacion_ingresar_vencimiento" label={"Vencimiento"}/>
                <TextField source="documentacion_adjuntar_archivo" label={"Archivo Adjunto"}/>
                <BtnProveedorDocumentacionAdjunto label={"Adjuntar Archivo Documentacion"}/>
                {/*<LinkSubirArchivo label={"Adjuntar Documentacion"}/>*/}
                <DateField source="fecha_ingreso" label={"Fecha Ingreso"}/>
                <DateField source="fecha_vencimiento" label={"Fecha Vencimiento"}/>
                {/*<ProveedorDocumentacionEdit/>*/}
                {role_proveedor_eliminar_documentacion_permissions({permissions}) === 'ROLE_PROVEEDOR_ELIMINAR_DOCUMENTACION' &&
                <BtnEliminar label={"Eliminar Documentación"}/>}
            </Datagrid>
            <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                total={total}
            />

        </div>
    );
};

export default ProveedorDocumentacionList;



