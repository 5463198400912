import React, {useEffect, useState, useCallback} from 'react';
import {useQuery} from 'react-admin';
import 'devextreme/dist/css/dx.light.css';
import Button from '@mui/material/Button';
import IconCancelar from '@material-ui/icons/Close';

import {
    DataGrid,
    Column, Export, Selection, GroupPanel, Grouping, SearchPanel, Paging, Toolbar,
    Item
} from 'devextreme-react/data-grid';

import BtnProcesasClases from "./BtnProcesarClases";

function ProveedorClaseArbol(props) {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'});

    const [selectedClases, setSelectedClases] = useState([]);
    const [mostrarSeleccionados, setMostrarSeleccionados] = useState(["No se ha seleccionado ninguna clase"]);

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'clase',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: {},
        }
    });

    const onSelectionChanged = useCallback((e) => {

        const nuevoArray = [];
        const nuevoArray2 = [];

        // Recorro las filas seleccionadas y guardo en un nuevo array los datos que debo guardar
        e.selectedRowsData.map((item) => {
            console.log(item);
            nuevoArray.push(item.clase_d.trim());
            nuevoArray2.push(item.clase.trim());
        });

        /// Si hay registros seleccionados, guardo dos estados: uno para mostrar en pantalla y otro para enviar al servidor
        if (nuevoArray.length < 1) {
            setMostrarSeleccionados("No se ha seleccionado ninguna clase")
            setSelectedClases([]);
        } else {
            setMostrarSeleccionados(nuevoArray.join(', ')); /// muestro el array separado por coma para mostrar en pantalla
            setSelectedClases(nuevoArray2);
        }

    }, []);

    const BtnVolver = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" onClick={() => {
            props.callbackCloseDrawer(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const selectionFilter = ['clase_d', '=', 'alpiste'];

    return (
        <div>
            <DataGrid
                id="gridContainer"
                dataSource={data}
                keyExpr="id_clase"
                showBorders={true}
                selectNodesRecursive={true}
                selectByClick={true}
                selectAllMode={'page'}
                showCheckBoxesMode={true}
                onSelectionChanged={onSelectionChanged}
                defaultSelectionFilter={selectionFilter}

            >
                <Paging defaultPageSize={18}/>
                <Selection selectAllMode={'page'} mode="multiple" deferred={false} showCheckBoxesMode={'always'}
                           showCheckBoxSelectorInGroupRow={true}/>
                {/*<GroupPanel visible={true}/>*/}
                <Grouping autoExpandAll={false}/>

                <SearchPanel visible={true}
                             width={240}
                             placeholder="Buscar..."
                />

                <Column dataField="rubro_d" caption={"Rubro"} groupIndex={0}/>
                <Column dataField="clase_d" caption={"Seleccionar Clases"}/>
         {/*       <Toolbar>
                    <Item location="before">
                        <Button
                            // disabled={!selectedRowKeys.length}
                            // onClick={this.onClearButtonClicked}
                            text="Borrar selección"
                        />
                    </Item>

                    items.Add().Name("searchPanel");
                </Toolbar>*/}
            </DataGrid>
            <div>

                <span className="caption">Clases seleccionadas:{' '}
                    {mostrarSeleccionados}
                </span>
            </div>
            <div>
                <div>
                    <br/>
                    {selectedClases.length > 0 &&
                    <BtnProcesasClases pro={props.pro} data={selectedClases}/>
                    }
                    {' '}
                    <BtnVolver/>

                </div>
            </div>
        </div>
    );
}

export default ProveedorClaseArbol;