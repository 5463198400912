import React, {useState, useCallback} from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Create,
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    AutocompleteInput,
    SelectInput,
    DateInput,
    TopToolbar,
    ReferenceInput,
    Toolbar, SaveButton, DeleteButton,
    useGetOne, Loading, NumberInput, TextField, ShowButton
} from 'react-admin';
import {makeStyles} from "@material-ui/core";



const useStyles = makeStyles((theme) => ({

    documento_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    documento_tipo_d: {display: 'inline-block', width: 400, marginLeft: 8},
}));

const MonedaEdit = props => {
    const classes = useStyles();

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );

    const redirect = (basePath, id, data) => `/Moneda`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            <DeleteButton undoable={false} redirect={redirect}/>
        </Toolbar>
    );
    const transform = data => {
        return {
            ...data
        }
    };

    const PostTitle = ({record}) => {
        return <span>Moneda: {record ? `${record.moneda}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={<CustomToolbar/>}
            >
                {/*Datos*/}
                <FormTab label="DATOS MONEDA">


                    <TextInput
                        label="Moneda"
                        source="moneda"
                        formClassName={classes.moneda}
                        placeholder="Ingrese Moneda"
                        helperText="Moneda"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Descripción Moneda"
                        source="moneda_d"
                        formClassName={classes.moneda_d}
                        multiline
                        placeholder="Ingrese Descripción Moneda"
                        helperText="Descripción Moneda"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>



                    <TextInput
                        id="standard-full-width"
                        label="Moneda Abre."
                        source="moneda_abre"
                        formClassName={classes.moneda_abre}
                        multiline
                        placeholder="Ingrese Moneda Abre."
                        helperText="Moneda Abre."
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Código AFIP"
                        source="codigo_afip"
                        formClassName={classes.codigo_afip}
                        multiline
                        placeholder="Ingrese Código AFIP"
                        helperText="Código AFIP"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>


                    <TextInput
                        id="standard-full-width"
                        label="Código Gestion"
                        source="codigo_gestion"
                        formClassName={classes.codigo_gestion}
                        multiline
                        placeholder="Ingrese Código Gestion"
                        helperText="Código Gestion"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    {/*<TextInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Activo"*/}
                    {/*    source="activo"*/}
                    {/*    formClassName={classes.activo}*/}
                    {/*    multiline*/}
                    {/*    placeholder="Ingrese Activo"*/}
                    {/*    helperText="Activo"*/}
                    {/*    fullWidth*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="outlined"*/}
                    {/*/>*/}
                    <SelectInput source="activo" label="Activo"
                                 formClassName={classes.activo}
                                 placeholder="Ingrese Activo"
                                 helperText="Activo"
                                 choices={[
                                     { id: 'S', name: 'S' },
                                     { id: 'N', name: 'N' },
                                 ]} />
                    <br/>

                </FormTab>
            </TabbedForm>
        </Edit>
    )
};
export default MonedaEdit;


