import React, {useCallback, useState} from 'react'
import {TextInput, SimpleForm} from 'react-admin';

import {makeStyles} from "@material-ui/core";
import BtnAbrirArbolClasesBuscador from "./BtnAbrirArbolClasesBuscador";
import ProveedorClaseBuscadorGrid from './ProveedorClaseBuscadorGrid'

const useStyles = makeStyles((theme) => ({
    clase: {display: 'inline-block'}
}));

export const ProveedorClasesBuscadorList = (props) => {
        const classes = useStyles();
        const [selectedClase, setSelectedClase] = useState('');
        const [selectedClaseDesc, setSelectedClaseDesc] = useState('');

        const handleDrawer = useCallback(async (who) => {
            const nuevoArray = [];
            const nuevoArray2 = [];

            who.map((item) => {
                nuevoArray.push(' ' + item.clase_d.trim());
                nuevoArray2.push(item.id_clase);
            });

            setSelectedClaseDesc(nuevoArray);
            setSelectedClase(nuevoArray2);
        });

        const formatClase = v => {
            return selectedClaseDesc
        };

        return (
            <SimpleForm toolbar={<> </>}>
                <br/>
                <BtnAbrirArbolClasesBuscador label={"Buscar Clases"}
                                             callbackClase={handleDrawer}
                />

                {selectedClase !== '' &&
                <>

                    <TextInput label={"Clases Filtradas"}
                               fullWidth={true}
                               className={classes.clase}
                               variant="standard" inputProps={{readOnly: true,}}
                               value={selectedClaseDesc}
                               format={formatClase}
                               multiline
                    />

                    <ProveedorClaseBuscadorGrid clase={selectedClase}/>
                </>
                }
            </SimpleForm>
        )
    }
;

export default ProveedorClasesBuscadorList;
