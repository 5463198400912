import React, {useCallback, useState} from 'react';
import {
    useNotify, useRefresh, useMutation, ReferenceInput, SelectInput, useDataProvider, TextInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import {Drawer} from "@material-ui/core";
import IconCancelar from '@material-ui/icons/Close';
import IconEdit from '@material-ui/icons/Edit';
import DocumentacionTipoBusquedaBtn from "../DocumentacionTipo/DocumentacionTipoBusquedaBtn";

const useStyles = makeStyles({
    socio_nro_ingreso: {display: 'inline-block', width: 100},


    tipoDocConyuge: {display: 'inline-block', width: 150,},
    nombre: {display: 'inline-block', width: 150,},
    apellido: {display: 'inline-block', width: 150, marginLeft: 28},

    documento_tipo: {display: 'inline-block', width: 80},
    documento_nro: {display: 'inline-block', width: 120, marginLeft: 28},
    cuit_cuil: {display: 'inline-block', width: 150, marginLeft: 28},

    correo_electronico: {display: 'inline-block', width: 350},

    estado_civil: {display: 'inline-block', width: 120},
    socio_activo: {display: 'inline-block', width: 80},
});

const ProveedorDocumentacionEdit = ({record}) => {
    const classes = useStyles();
    const dataProviderTableroControl = useDataProvider();
    const [open, setOpen] = React.useState(false);
    const [idDocumentacion, setIdDocumentacion] = useState(record.id);
    // const [proveedor, setEstadoproveedor] = useState('');
    // const [documentacion_tipo, SetStatedocumentacion_tipo] = useState('');
    // const [documentacion_tipo_d, SetStatedocumentacion_tipo_d] = useState('');
    // const [documentacion_ingresar_vencimiento, SetStatedocumentacion_ingresar_vencimiento] = useState('');
    // const [documentacion_adjuntar_archivo, SetStatedocumentacion_adjuntar_archivo] = useState('');
    // const [fecha_ingreso, SetStatefecha_ingreso] = useState('');
    // const [fecha_vencimiento, SetStatefecha_vencimiento] = useState('');
    // const [id_documentacion_tipo, SetStateid_documentacion_tipo] = useState('');

    const [{
        proveedor,
        documentacion_tipo,
        documentacion_tipo_d,
        documentacion_ingresar_vencimiento,
        documentacion_adjuntar_archivo,
        fecha_ingreso,
        fecha_vencimiento,
        id_documentacion_tipo
    }, setState] = useState({
        proveedor: record.pro,
        documentacion_tipo: record.documentacion_tipo,
        documentacion_tipo_d: record.documentacion_tipo_d,
        documentacion_ingresar_vencimiento: record.documentacion_ingresar_vencimiento,
        documentacion_adjuntar_archivo: record.documentacion_adjuntar_archivo,
        fecha_ingreso: record.fecha_ingreso,
        fecha_vencimiento: record.fecha_vencimiento,
        id_documentacion_tipo: record.id_documentacion_tipo
    });


    const BtnCancelar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
            setOpen(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const BotonCrearDetelle = (props) => {
        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'update',
                resource: 'ProveedorDocumentacion',
                payload: {
                    id: idDocumentacion,
                    data: {
                        proveedor,
                        documentacion_tipo,
                        documentacion_tipo_d,
                        documentacion_ingresar_vencimiento,
                        documentacion_adjuntar_archivo,
                        fecha_ingreso,
                        fecha_vencimiento,
                        id_documentacion_tipo
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle}
                    disabled={props.pristine}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <SaveIcon style={{color: '#004187'}}/>
                    Guardar
                </div>
            </Button>
        );

    };

    /// JN Mascara para el input cuit-cuil
    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    const handleClickOpen = () => {
        setIdDocumentacion(record.id);
        // setEstadoproveedor(record.proveedor);
        // SetStatedocumentacion_tipo(record.documentacion_tipo);
        // SetStatedocumentacion_tipo_d(record.documentacion_tipo_d);
        // SetStatedocumentacion_ingresar_vencimiento(record.documentacion_ingresar_vencimiento);
        // SetStatedocumentacion_adjuntar_archivo(record.documentacion_adjuntar_archivo);
        // SetStatefecha_ingreso(record.fecha_ingreso);
        // SetStatefecha_vencimiento(record.fecha_vencimiento);
        // SetStateid_documentacion_tipo(record.id_documentacion_tipo);
        //
        setOpen(true);
    };
    const formatid_documentacion_tipo = v => {
        return id_documentacion_tipo
    };

    const formatdocumentacion_tipo = v => {
        return documentacion_tipo
    };

    const formatdocumentacion_tipo_d = v => {
        return documentacion_tipo_d
    };

    const formatdocumentacion_ingresar_vencimiento = v => {
        return documentacion_ingresar_vencimiento
    };

    const formatdocumentacion_adjuntar_archivo = v => {
        return documentacion_adjuntar_archivo
    };


    const updateDocumentacionTipo = useCallback(async (who) => {
        setState(state => ({
            ...state,
            id_documentacion_tipo: who.id,
            documentacion_tipo: who.documentacion_tipo,
            documentacion_tipo_d: who.documentacion_tipo_d,
            documentacion_ingresar_vencimiento: who.documentacion_ingresar_vencimiento,
            documentacion_adjuntar_archivo: who.documentacion_adjuntar_archivo,

        }));

    });


    return (
        <div>
            <Button variant="text" style={{color: '#123B80'}} size={"small"} onClick={handleClickOpen}>
                <IconEdit/>
                Editar
            </Button>

            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <div>
                    <h3>Editar Documentacion</h3>
                </div>
                <br/>
                <div style={{paddingLeft: "10px"}}>

                    <div style={{display: 'inline-flex', width: '100%'}}>
                        <TextInput source="id_documentacion_tipo" label={"Documentacion Tipo ID"}
                                   className={classes.id_documentacion_tipo}
                                   variant="outlined" inputProps={{readOnly: true}}
                                   value={id_documentacion_tipo} fullWidth
                                   format={formatid_documentacion_tipo}
                                   InputLabelProps={{shrink: true}}
                        />

                        <DocumentacionTipoBusquedaBtn
                            callbackDocumentacionTipo={updateDocumentacionTipo}
                            className={classes.btnBusqueda} fullWidth={true}/>
                    </div>



                    <TextInput source="documentacion_tipo" label={"Documentacion Tipo"}
                               className={classes.documentacion_tipo}
                               variant="filled" inputProps={{readOnly: true,}}
                               value={documentacion_tipo} fullWidth={true}
                               format={formatdocumentacion_tipo}
                               InputLabelProps={{shrink: true}}
                    />

                    <TextInput source="documentacion_tipo_d" label={"Documentacion Tipo Descripcion"}
                               className={classes.documentacion_tipo_d}
                               variant="filled" inputProps={{readOnly: true,}}
                               value={documentacion_tipo_d} fullWidth={true}
                               format={formatdocumentacion_tipo_d}
                               multiline
                               InputLabelProps={{shrink: true}}
                    />
                    <TextInput source="documentacion_adjuntar_archivo" label={"Documentacion Adjuntar Archivo"}
                               className={classes.documentacion_adjuntar_archivo}
                               variant="filled" inputProps={{readOnly: true,}}
                               value={documentacion_adjuntar_archivo} fullWidth={true}
                               format={formatdocumentacion_adjuntar_archivo}
                               InputLabelProps={{shrink: true}}
                    />
                    <TextInput source="documentacion_ingresar_vencimiento" label={"Documentacion Ingresar Vencimiento"}
                               className={classes.documentacion_ingresar_vencimiento}
                               variant="filled" inputProps={{readOnly: true,}}
                               value={documentacion_ingresar_vencimiento} fullWidth={true}
                               format={formatdocumentacion_ingresar_vencimiento}
                               InputLabelProps={{shrink: true}}
                    />


                    <div>

                        {/* {selectedClases.length > 0 &&
                    <BtnProcesasClases pro={props.pro} data={selectedClases}/>
                    }
                    {' '}*/}
                        <BotonCrearDetelle/>

                        <BtnCancelar/>


                    </div>
                </div>
            </Drawer>
        </div>
    )
        ;

}

ProveedorDocumentacionEdit.defaultProps = {
    source: '',
    addLabel: true,
};

export default ProveedorDocumentacionEdit;