import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import clsx from 'clsx';

function LinearProgresoCumplidoDiasPorcentaje(props) {
    const classes = useStyles2();

    var w_props_value = props.value
    var w_props_value2 = props.value2
    var w_props_value3 = props.value3
    return (
        <div style={{ backgroundColor: '#3a5f8b' }}
            title={'Dias Cumplidos: '+w_props_value2+' - Total Dias: '+w_props_value3}
            className={classes.root}>
            <div
                className={classes.value}
            >
                {/*<Box minWidth={35}>*/}
                {/*    <Typography variant="body2" color="textSecondary">{`${w_props_value.toLocaleString()}%`}</Typography>*/}
                {/*</Box>*/}
                {`${w_props_value.toLocaleString()} %`}
            </div>
            <div
                className={clsx(classes.bar, {
                    low: w_props_value < 30,
                    medium: w_props_value >= 30 && w_props_value <= 70,
                    high: w_props_value > 70
                })}
                style={{ maxWidth: `${w_props_value}%` }}
            />
        </div>
        // <Box display="flex" alignItems="center">
        //     <Box width="100%" mr={1}>
        //         <LinearProgress
        //             classes={{ root: classes.root2, bar: classes.bar }}
        //             variant="determinate"
        //             value={props.value}
        //         />
        //     </Box>
        //     <Box minWidth={35}>
        //         <Typography variant="body2" color="textSecondary">{`${Math.round(
        //             props.value,
        //         )}%`}</Typography>
        //     </Box>
        // </Box>
        // <Box display="flex" alignItems="center">
        //     <Box width="100%" mr={1}>
        //         <LinearProgress variant="determinate" {...props} />
        //     </Box>
        //     <Box minWidth={35}>
        //         <Typography variant="body2" color="textSecondary">{`${Math.round(
        //             props.value,
        //         )}%`}</Typography>
        //     </Box>
        // </Box>
    );
}

LinearProgresoCumplidoDiasPorcentaje.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    root2: {
        height: 10,
        borderRadius: 5
    },
    bar: ({ progress }) => ({
        borderRadius: 5,
        background: `linear-gradient(90deg, #6fcbb6 ${100 - progress}%, #9c64f4 100%)`
    })
});


const useStyles2 = makeStyles((theme) => ({
    root: {
        border: `1px solid ${theme.palette.divider}`,
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: 26,
        borderRadius: 2
    },
    value: {
        position: "absolute",
        lineHeight: "24px",
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    bar: {
        height: "100%",
        "&.low": {
            // backgroundColor: "#808080",
            backgroundColor: "#00ff00",
            color: '#004187'
        },
        "&.medium": {
            backgroundColor: "#00ff00",
            color: '#004187'
        },
        "&.high": {
            backgroundColor: "#00ff00",
            color: '#004187'
        }
    },
    bar2: {
        height: "100%",
        "&.low": {
            backgroundColor: "#f44336"
        },
        "&.medium": {
            backgroundColor: "#efbb5aa3"
        },
        "&.high": {
            backgroundColor: "#088208a3"
        }
    }
}));

export default function LinearWithValueLabel(record) {
    const classes = useStyles();
    // const [progress, setProgress] = React.useState(10);
    //
    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    //     }, 800);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);

    return (
        <div className={classes.root}>
            <LinearProgresoCumplidoDiasPorcentaje
                value={record.record.cumplido_dias_porcentaje===undefined ? 0 : record.record.cumplido_dias_porcentaje}
                value2={record.record.cumplido_dias===undefined ? 0 : record.record.cumplido_dias}
                value3={record.record.total_dias===undefined ? 0 : record.record.total_dias}
            />
        </div>
    );
}