import React, {useState, useCallback, useEffect} from "react";

import {
    Create,
    FormTab,
    TextInput,
    TabbedForm, required,
    DateInput,
    NumberInput, SelectInput, Toolbar, SaveButton, useDataProvider
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import ContratacionEncuadreBusquedaBtn from "../ContratacionEncuadre/ContratacionEncuadreBusquedaBtn";
import OrdenCompraBusquedaBtn from "./OrdenCompraBusquedaBtn";
import UnidadEmisoraBusquedaBtn from "../UnidadEmisora/UnidadEmisoraBusquedaBtn";
import UnidadGerenciaBusquedaBtn from "../UnidadGerencia/UnidadGerenciaBusquedaBtn";
import UnidadSectorBusquedaBtn from "../UnidadSector/UnidadSectorBusquedaBtn";
import UnidadProgramaBusquedaBtn from "../UnidadPrograma/UnidadProgramaBusquedaBtn";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ContratacionTipoBusquedaBtn from "../ContratacionTipo/ContratacionTipoBusquedaBtn";
import UnidadAreaBusquedaBtn from "../UnidadArea/UnidadAreaBusquedaBtn";
import UnidadNegocioBusquedaBtn from "../UnidadNegocio/UnidadNegocioBusquedaBtn";

const useStyles = makeStyles((theme) => ({

    d_pais: {display: 'inline-block', width: 300},

    btnBusqueda: {
        marginRight: '2rem',
        width: 80,
        display: 'inline-flex',
    },

    combo: {display: 'inline-block', width: 250, marginLeft: 32},
    switch: {marginLeft: 32},

    proveedor_rta: {display: 'inline-block', width: 250, marginLeft: 32},
    razon_social: {display: 'inline-block', width: 250, marginLeft: 32},
    razon_social_fantasia: {display: 'inline-block', width: 250, marginLeft: 32},
    documento_nro: {display: 'inline-block', width: 250, marginLeft: 32},
    cuit_cuil: {display: 'inline-block', width: 250, marginLeft: 32},
    domicilio_pais: {display: 'inline-block', width: 250, marginLeft: 32},
    domicilio_provincia: {display: 'inline-block', width: 250, marginLeft: 32},
    domicilio_partido: {display: 'inline-block', width: 250, marginLeft: 32},
    domicilio_ciudad: {display: 'inline-block', width: 250, marginLeft: 32},
    domicilio_calle: {display: 'inline-block', width: 250, marginLeft: 32},
    domicilio_calle_nro: {display: 'inline-block', width: 250, marginLeft: 32},
    domicilio_piso: {display: 'inline-block', width: 250, marginLeft: 32},
    domicilio_departamento: {display: 'inline-block', width: 250, marginLeft: 32},
    domicilio_codigo_postal: {display: 'inline-block', width: 250, marginLeft: 32},
    contacto_correo_electronico: {display: 'inline-block', width: 250, marginLeft: 32},
    contacto_correo_electronico_alternativo: {display: 'inline-block', width: 250, marginLeft: 32},
    contacto_telefono_area: {display: 'inline-block', width: 250, marginLeft: 32},
    contacto_telefono_nro: {display: 'inline-block', width: 250, marginLeft: 32},
    contacto_telefono_alternativo_area: {display: 'inline-block', width: 250, marginLeft: 32},
    contacto_telefono_alternativo_nro: {display: 'inline-block', width: 250, marginLeft: 32},
    contacto_celular_area: {display: 'inline-block', width: 250, marginLeft: 32},
    contacto_celular_nro: {display: 'inline-block', width: 250, marginLeft: 32},
    contacto_fax_area: {display: 'inline-block', width: 250, marginLeft: 32},
    contacto_fax_nro: {display: 'inline-block', width: 250, marginLeft: 32},
    moneda_cotizacion: {display: 'inline-block', width: 250, marginLeft: 32},
    moneda_cotizacion_fecha: {display: 'inline-block', width: 250, marginLeft: 32},
    codigo_pro_tvpublica: {display: 'inline-block', width: 250, marginLeft: 32},
    codigo_pro_rna: {display: 'inline-block', width: 250, marginLeft: 32},
    precio_incluye_iva: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_subtotal: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_iva: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_total: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_subtotal_pesos: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_iva_pesos: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_total_pesos: {display: 'inline-block', width: 250, marginLeft: 32},
    pre_adjudica_codigo: {display: 'inline-block', width: 250, marginLeft: 32},
    pre_adjudica_nombre_apellido: {display: 'inline-block', width: 250, marginLeft: 32},
    pre_adjudica_sector: {display: 'inline-block', width: 250, marginLeft: 32},
    pre_adjudica_firma_tipo: {display: 'inline-block', width: 250, marginLeft: 32},
    conforma_adjudica_codigo: {display: 'inline-block', width: 250, marginLeft: 32},
    conforma_adjudica_nombre_apellido: {display: 'inline-block', width: 250, marginLeft: 32},
    conforma_adjudica_sector: {display: 'inline-block', width: 250, marginLeft: 32},
    conforma_adjudica_firma_tipo: {display: 'inline-block', width: 250, marginLeft: 32},
    orden_compra_estado: {display: 'inline-block', width: 250, marginLeft: 32},

    es_ampliacion: {display: 'inline-block', width: 230},
    es_ampliacion_inicio: {display: 'inline-block', width: 230, marginLeft: 32},
    orden_compra_origen_nro: {display: 'inline-block', width: 180},
    orden_compra_origen_ampliacion_nro: {display: 'inline-block', width: 120, marginLeft: 32},

    orden_compra_nro: {display: 'inline-block', width: 180},
    orden_compra_ampliacion_nro: {display: 'inline-block', width: 120},

    orden_compra_fecha: {display: 'inline-block', width: 180},
    orden_compra_fecha2: {display: 'inline-block', width: 180, marginLeft: 32},

    orden_compra_fecha_origen: {display: 'inline-block', width: 180, marginLeft: 32},
    orden_compra_ano: {display: 'inline-block', width: 120, marginLeft: 32},

    contratacion_tipo: {display: 'inline-block', width: 140},
    contratacion_tipo_d: {display: 'inline-block', width: 600, marginLeft: 32},
    contratacion_ano: {display: 'inline-block', width: 140},
    contratacion_nro: {display: 'inline-block', width: 140},

    unidad_negocio: {display: 'inline-block', width: 140, marginLeft: 32},
    unidadAreaD: {display: 'inline-block', width: 360, marginLeft: 32},
    unidadAreaD2: {display: 'inline-block', width: 360, marginLeft: 10},

    procedimiento: {display: 'inline-block', width: 600},
    expediente: {display: 'inline-block', width: 600},
    informe_adjudicacion: {display: 'inline-block', width: 600},
    informe_adjudicacion_referencia_gde: {display: 'inline-block', width: 600},

    programa_d: {display: 'inline-block', width: 600, marginLeft: 32},
    sector_d: {display: 'inline-block', width: 600, marginLeft: 32},
    gerencia_d: {display: 'inline-block', width: 600, marginLeft: 32},
    emisora_d: {display: 'inline-block', width: 600, marginLeft: 32},

    descripcion: {display: 'inline-block', width: 600, marginLeft: 32},
    programa: {display: 'inline-block', width: 100},
    sector: {display: 'inline-block', width: 100},
    gerencia: {display: 'inline-block', width: 100},
    emisora: {display: 'inline-block', width: 100},
}));

const OrdenCompraCabeceraCreate = props => {
    const classes = useStyles();
    const [state, setState] = useState({});
    const [esAmpliacion, setEsAmpliacion] = useState(null);
    const [automatico, setAutomatico] = useState(true);
    const [readOnly, setReadOnly] = useState(null);

    const dataProvider = useDataProvider();
    const PostTitle = () => {
        return <span>Alta de Orden de Compra</span>;
    };

    const {
        idContratacion, contratacionD, unidadNegocio, unidadAreaD, contratacionAno, contratacionNro, contratacionTipo,
        OCid, OCnro, OCFechaOrigen, OCanoOrigen, OCorigenAmpliacionNro, OCampliacionNro, OCorigenNro, OCexpediente,
        emisora_d, gerencia_d, programa_d, sector_d, idArea, idUnidadNegocio,
        programa, gerencia, sector, emisora, unidadArea,
        id_unidad_emisora,
        id_unidad_gerencia,
        id_unidad_sector,
        id_unidad_programa
    } = state;


    const limpiarValores = () => {
        setAutomatico(true);
        setState(state => ({
            ...state,
            idContratacion: null,
            contratacionD: null,
            contratacionTipo: null,
            unidadNegocio: null,
            unidadAreaD: null,
            contratacionAno: null,
            contratacionNro: null,
            OCid: null,
            OCnro: null,
            OCFechaOrigen: null,
            OCanoOrigen: null,
            OCorigenNro: null,
            OCorigenAmpliacionNro: null,
            OCampliacionNro: null,
            OCexpediente: null,
            idArea: null
        }));
    };

    const updateContratacionEncuadre = useCallback(async (who) => {
        console.log("encuare", who)
        setState(state => ({
            ...state,
            idContratacion: who.id_contratacion_tipo,
            contratacionTipo: who.contratacion_tipo,
            contratacionD: who.contratacion_d.trim(),
            unidadNegocio: who.unidad_negocio_requiere ? who.unidad_negocio_requiere.trim() : '',
            unidadArea: who.unidad_area_requiere,
            unidadAreaD: who.unidad_area_d.trim(),
            contratacionAno: who.contratacion_ano,
            contratacionNro: who.id,
            idArea: who.id_unidad_area,
            idUnidadNegocio: who.id_unidad_negocio,
            OCexpediente: who.expediente_referencia_gde
        }));

    });

    const updateOrdenDeCompra = useCallback(async (who) => {
        console.log("Create OC", who)
        setState(state => ({
            ...state,
            OCid: who.id,
            OCorigenNro: who.orden_compra_nro,
            OCnro: who.orden_compra_nro,
            OCampliacionNro: "A asignar",
            OCorigenAmpliacionNro: who.orden_compra_ampliacion_nro,
            OCFechaOrigen: who.orden_compra_fecha_corta,
            OCanoOrigen: who.orden_compra_ano,
            OCexpediente: who.procedimeinto_referencia_gde,
            idContratacion: who.id_contratacion_tipo,
            sector_d: who.sector_d,
            programa_d: who.programa_d,
            gerencia_d: who.gerencia_d,
            emisora_d: who.emisora_d,
            sector: who.sector,
            programa: who.programa,
            gerencia: who.gerencia,
            emisora: who.emisora,
            id_unidad_sector: who.id_unidad_sector,
            id_unidad_programa: who.id_unidad_programa,
            id_unidad_gerencia: who.id_unidad_gerencia,
            id_unidad_emisora: who.id_unidad_emisora,
        }))
        ;

        if (who.id_contratacion_tipo > 0) {
            dataProvider.getList(
                'ContratacionEncuadre',
                {
                    filter: {id: who.id_contratacion_tipo},
                    sort: {field: 'id', order: 'DESC'},
                    pagination: {page: 1, perPage: 100},
                },)
                .then(({data}) => {
                    console.log("data:", data[0])
                    setState(state => ({
                        ...state,
                        contratacionD: data[0].contratacion_d,
                        contratacionTipo: data[0].contratacion_tipo,
                        unidadNegocio: data[0].unidad_negocio_requiere ? data[0].unidad_negocio_requiere.trim() : '',
                        unidadArea: data[0].unidad_area_requiere,
                        unidadAreaD: data[0].unidad_area_d,
                        contratacionAno: data[0].contratacion_ano,
                        contratacionNro: data[0].id,
                        idArea: data[0].id_unidad_area
                    }));
                })
                .catch(error => {

                })
        }
    });

    const updateUEmisora = useCallback(async (who) => {
        setState(state => ({
            ...state,
            emisora: who.emisora,
            emisora_d: who.emisora_d.trim(),
            id_unidad_emisora: who.id
        }));
    });

    const updateUGerencia = useCallback(async (who) => {
        setState(state => ({
            ...state,
            gerencia: who.gerencia,
            gerencia_d: who.gerencia_d.trim(),
            id_unidad_gerencia: who.id
        }));
    });

    const updateUPrograma = useCallback(async (who) => {
        setState(state => ({
            ...state,
            programa: who.programa,
            programa_d: who.programa_d.trim(),
            id_unidad_programa: who.id
        }));
    });

    const updateUSector = useCallback(async (who) => {
        setState(state => ({
            ...state,
            sector: who.sector,
            sector_d: who.sector_d.trim(),
            id_unidad_sector: who.id
        }));
    });

    const updateContratacionTipo = useCallback(async (who) => {
        setState(state => ({
            ...state,
            contratacionTipo: who.contratacion_tipo,
            contratacionD: who.contratacion_d.trim(),
            idContratacion: who.id
        }));
    });

    const updateUnidadArea = useCallback(async (who) => {
        setState(state => ({
            ...state,
            unidadArea: who.area,
            unidadAreaD: who.area_d.trim(),
            idArea: who.id
        }));
    });

    const updateUnidadNegocio = useCallback(async (who) => {
        setState(state => ({
            ...state,
            unidadNegocio: who.unidad_negocio,
            unidadNegocioD: who.unidad_negocio_d.trim(),
            idUnidadNegocio: who.id
        }));
    });


    const formatEmisora = v => {
        return emisora
    };
    const formatGerencia = v => {
        return gerencia
    };
    const formatPrograma = v => {
        return programa
    };
    const formatSector = v => {
        return sector
    };

    const formatEmisora_d = v => {
        return emisora_d
    };
    const formatGerencia_d = v => {
        return gerencia_d
    };
    const formatPrograma_d = v => {
        return programa_d
    };
    const formatSector_d = v => {
        return sector_d
    };
    const formatOCnro = v => {
        return OCnro
    };
    const formatOCFechaOrigen = v => {
        return OCFechaOrigen
    };
    const formatOCanoOrigen = v => {
        return OCanoOrigen
    };
    const formatOCorigenAmpliacionNro = v => {
        return OCorigenAmpliacionNro
    };
    const formatOCampliacionNro = v => {
        return OCampliacionNro
    };
    const formatOCorigenNro = v => {
        return OCorigenNro
    };
    const formatOCexpediente = v => {
        return OCexpediente
    };
    const formatContratacionD = v => {
        return contratacionD
    };
    const formatUnidadNegocio = v => {
        return unidadNegocio
    };
    const formatUnidadAreaD = v => {
        return unidadAreaD
    };
    const formatContratacionAno = v => {
        return contratacionAno
    };
    const formatContratacionNro = v => {
        return contratacionNro
    };
    const formatContratacionTipo = v => {
        return contratacionTipo
    };

    const CustomToolbar = props => (
        <Toolbar {...props}>
            <SaveButton
                label="Guardar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
        </Toolbar>
    );

    const transform = data => {

        let w_idContratacion = idContratacion;
        if (idContratacion > 0) {
        } else (w_idContratacion = 0);


        let w_OCid = OCid;
        if (OCid > 0) {
        } else (w_OCid = 0);

        let w_OCnro = OCnro;
        if (OCnro > 0) {
        } else (w_OCnro = 0);

        let w_OCorigenAmpliacionNro = OCorigenAmpliacionNro;
        /*     console.log("w_OCorigenAmpliacionNro", w_OCorigenAmpliacionNro)
             if (OCorigenAmpliacionNro) {
             } else (w_OCorigenAmpliacionNro = null);
             w_OCorigenAmpliacionNro = '0' +*/

        let w_OCorigenNro = OCorigenNro;
        if (OCorigenNro > 0) {
        } else (w_OCorigenNro = null);

        let w_idUArea = idArea;
        if (idArea > 0) {
        } else (w_idUArea = null);

        let w_OCexpediente = OCexpediente;

        return {
            ...data,
            w_OCid,
            w_OCnro,
            w_OCorigenAmpliacionNro,
            w_OCorigenNro,
            w_OCexpediente,
            contratacionAno,
            contratacionNro,
            contratacionTipo,
            w_idContratacion,
            programa,
            gerencia,
            emisora,
            sector,
            unidadArea,
            unidadNegocio,
            automatico,
            id_unidad_emisora,
            id_unidad_gerencia,
            id_unidad_sector,
            id_unidad_programa,
            idArea,
            idUnidadNegocio,
        }
    };


    const validateUserCreation = async (values) => {
        const errors = {};
        if (!automatico) {

            if (!contratacionTipo) {
                errors.contratacion_tipo = 'Debe ingresar un valor';
            }

            if (!values.orden_compra_nro) {
                errors.orden_compra_nro = 'Debe ingresar un valor';
            }
            if (!values.orden_compra_ampliacion_nro) {
                errors.orden_compra_ampliacion_nro = 'Debe ingresar un valor';
            }


            /*  const isEmailUnique = await checkExisteOrdenAmpliacion(values);
              if (!isEmailUnique) {
                  // Return a message directly
                  errors.orden_compra_nro = 'Ya existe la órden ' + `${values.orden_compra_nro}` + '/' + `${values.orden_compra_ampliacion_nro}`;
              }
              return errors*/
        }

        return errors
    };

    const checkExisteOrdenAmpliacion = (values) => {
        /* const {data, total, loading, error} = useQuery({
             type: 'getList',
             resource: 'OrdenCompraCabecera',
             payload: {
                 pagination: {page, perPage},
                 sort,
                 filter: {params: values},
             }
         });*/
    }

    return (
        <Create title={<PostTitle/>} undoable={false} {...props}>
            <TabbedForm validate={validateUserCreation}
                        toolbar={<CustomToolbar/>}
            >
                {/*Datos de la Cabecera*/}
                <FormTab label="NUEVA ORDEN DE COMPRA">

                    {/*No lo usamos porque tilda la pantalla*/}
                    {/*<OrdenDeCompraNuevaContratacion/>*/}

                    <div style={{display: 'inline-flex', width: '100%'}}>
                        <h4> ¿Desea crear una ampliación de una orden de compra? </h4>

                        <SelectInput source="es_ampliacion" className={classes.es_ampliacion_inicio} fullWidth={true}
                                     variant="outlined" InputLabelProps={{shrink: true}} label={"Es una ampliación S/N"}
                                     inputProps={{
                                         onChange: (e) => {
                                             setEsAmpliacion(e.target.value);
                                             limpiarValores();
                                         }
                                     }}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}/>
                    </div>

                    {esAmpliacion === 'S' &&
                    <>
                        <div style={{display: 'inline-flex', width: '100%'}}>
                            <h4> Seleccionar Orden de Compra de origen </h4>

                            <OrdenCompraBusquedaBtn callbackOrdenCompra={updateOrdenDeCompra}
                                                    className={classes.btnBusqueda} fullWidth={true}
                            />
                        </div>

                        <br/>
                        <div style={{display: 'inline-flex', width: '100%'}}>
                            <TextInput source="orden_compra_origen_nro" label="Nro. OC Origen"
                                       className={classes.orden_compra_origen_nro}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={OCorigenNro} fullWidth
                                       format={formatOCorigenNro}
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="orden_compra_origen_ampliacion_nro"
                                       label="Nro. ampliación"
                                       className={classes.orden_compra_origen_ampliacion_nro}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={OCorigenAmpliacionNro} fullWidth
                                       format={formatOCorigenAmpliacionNro}
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="orden_compra_fecha" label={"Fecha OC"}
                                       className={classes.orden_compra_fecha_origen}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={OCFechaOrigen} fullWidth
                                       format={formatOCFechaOrigen}
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="orden_compra_ano" label={"Año imputado"}
                                       className={classes.orden_compra_ano}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={OCanoOrigen} fullWidth
                                       format={formatOCanoOrigen}
                                       InputLabelProps={{shrink: true}}
                            />

                        </div>
                        <hr align='left' style={{width: '45%'}}/>
                        <br/>
                    </>
                    }
                    {esAmpliacion &&
                    <>
                        {esAmpliacion == 'S' &&
                        <>
                            <NumberInput label="Nro. Orden de Compra" source="orden_compra_nro"
                                         className={classes.orden_compra_nro}
                                         value={OCnro} fullWidth
                                         format={formatOCnro}
                                         InputLabelProps={{
                                             shrink: true,
                                         }} variant="outlined"
                            />

                            <TextInput source="orden_compra_ampliacion_nro"
                                       label="Nro. ampliación"
                                       value={OCampliacionNro} fullWidth
                                       format={formatOCampliacionNro}
                                       inputProps={{readOnly: true}}
                                       className={classes.orden_compra_origen_ampliacion_nro}
                                       variant="outlined"
                                       InputLabelProps={{shrink: true}}
                            />

                        </>
                        }
                        {esAmpliacion == 'N' &&
                        <>
                            {automatico ?
                                <>
                                    <TextInput label="Nro. Orden de Compra" source="orden_compra_nro"
                                               className={classes.orden_compra_nro} fullWidth
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               variant="filled" inputProps={{readOnly: true}}
                                               inicialValue={"A Asignar"}
                                    />

                                    <TextInput source="orden_compra_ampliacion_nro"
                                               label="Nro. ampliación"
                                               fullWidth
                                               className={classes.orden_compra_origen_ampliacion_nro}
                                               InputLabelProps={{shrink: true}}
                                               variant="filled" inputProps={{readOnly: true}}
                                               inicialValue={"A Asignar"}
                                    />
                                </> :
                                <>
                                    <NumberInput label="Nro. Orden de Compra" source="orden_compra_nro"
                                                 className={classes.orden_compra_nro} fullWidth
                                                 InputLabelProps={{
                                                     shrink: true,
                                                 }} variant="outlined"
                                                 required
                                    />

                                    <TextInput source="orden_compra_ampliacion_nro"
                                               label="Nro. ampliación"
                                               fullWidth
                                               className={classes.orden_compra_origen_ampliacion_nro}
                                               variant="outlined"
                                               InputLabelProps={{shrink: true}}
                                               required
                                    />
                                </>
                            }

                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={automatico}
                                        onChange={(v) => setAutomatico(!automatico)}
                                        name="checkedB"
                                        color="primary"
                                        className={classes.switch}
                                    />
                                }
                                label={`${automatico ? 'Asignación automática' : 'Asignación manual'}`}
                            />

                            <br/>
                        </>
                        }


                        <DateInput source="orden_compra_fecha" label={"Fecha Orden de Compra"}
                                   className={esAmpliacion == 'S' ? classes.orden_compra_fecha2 : classes.orden_compra_fecha}
                                   variant="outlined"
                                   initialValue={new Date()}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />

                        <TextInput source="orden_compra_ano" label={"Año a imputar"}
                                   className={classes.orden_compra_ano}
                                   variant="outlined"
                                   initialValue={new Date().getFullYear()}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />

                        <br/>
                    </>
                    }


                    {esAmpliacion === 'N' && automatico &&
                    <>
                        <div style={{display: 'inline-flex', width: '100%'}}>
                            <TextInput source="contratacion_nro" label={"Contratación ID"}
                                       className={classes.contratacion_nro}
                                       variant="filled" inputProps={{readOnly: true}}
                                       value={contratacionNro} fullWidth
                                       format={formatContratacionNro}
                                       InputLabelProps={{shrink: true}}
                            />

                            <ContratacionEncuadreBusquedaBtn
                                callbackContratacionEncuadre={updateContratacionEncuadre}
                                className={classes.btnBusqueda} fullWidth={true}/>
                        </div>
                        <br/>
                    </>
                    }


                    {esAmpliacion === 'S' &&
                    <>
                        <TextInput source="contratacion_nro" label={"Contratación ID"}
                                   className={classes.contratacion_nro}
                                   variant="filled" inputProps={{readOnly: true}}
                                   value={contratacionNro} fullWidth
                                   format={formatContratacionNro}
                                   InputLabelProps={{shrink: true}}
                        />
                        <br/>
                    </>
                    }

                    {esAmpliacion &&
                    <>

                        {automatico &&
                        <>
                            <TextInput source="contratacion_tipo" label={"Tipo Contratación"}
                                       className={classes.contratacion_tipo}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={contratacionTipo} fullWidth={true}
                                       format={formatContratacionTipo}
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="contratacion_d" label={"Tipo de Contratación"}
                                       className={classes.contratacion_tipo_d}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={contratacionD} fullWidth={true}
                                       format={formatContratacionD}
                                       InputLabelProps={{shrink: true}}
                            />

                            <br/>

                            <TextInput source="contratacion_ano" label={"Año Contratación"}
                                       className={classes.contratacion_ano}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={contratacionAno} fullWidth
                                       format={formatContratacionAno}
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="unidad_negocio" label={"Unidad de Negocio"}
                                       className={classes.unidad_negocio}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={unidadNegocio} fullWidth
                                       format={formatUnidadNegocio}
                                       InputLabelProps={{shrink: true}}
                            />


                            <TextInput source="unidadAreaD" label={"Unidead Area"}
                                       className={classes.unidadAreaD}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={unidadAreaD} fullWidth
                                       format={formatUnidadAreaD}
                                       InputLabelProps={{shrink: true}}
                            />
                            <br/>

                        </>
                        }

                        {!automatico &&
                        <>
                            <div style={{display: 'inline-flex', width: '100%'}}>

                                <TextInput source="contratacion_tipo" label={"Tipo Contratación"}
                                           className={classes.contratacion_tipo}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           value={contratacionTipo} fullWidth={true}
                                           format={formatContratacionTipo}
                                           InputLabelProps={{shrink: true}}
                                />

                                <TextInput source="contratacion_d" label={"Tipo de Contratación"}
                                           className={classes.contratacion_tipo_d}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           value={contratacionD} fullWidth={true}
                                           format={formatContratacionD}
                                           InputLabelProps={{shrink: true}}
                                />

                                <ContratacionTipoBusquedaBtn
                                    callbackContratacionTipo={updateContratacionTipo}
                                    className={classes.btnBusqueda} fullWidth={true}
                                />
                            </div>
                            <br/>
                            <div style={{display: 'inline-flex', width: '100%'}}>

                                <TextInput source="contratacion_ano" label={"Año Contratación"}
                                           className={classes.contratacion_ano}
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                           variant="outlined"
                                />

                                <TextInput source="unidad_negocio" label={"Unidad de Negocio"}
                                           className={classes.unidad_negocio}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           value={unidadNegocio} fullWidth
                                           format={formatUnidadNegocio}
                                           InputLabelProps={{shrink: true}}
                                />


                                <UnidadNegocioBusquedaBtn
                                    callbackUnidadNegocio={updateUnidadNegocio}
                                    className={classes.btnBusqueda} fullWidth={true}
                                />

                                <TextInput source="unidadAreaD" label={"Unidead Area"}
                                           className={classes.unidadAreaD2}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           value={unidadAreaD} fullWidth
                                           format={formatUnidadAreaD}
                                           InputLabelProps={{shrink: true}}
                                />

                                <UnidadAreaBusquedaBtn callbackUnidadArea={updateUnidadArea}
                                                       className={classes.btnBusqueda} fullWidth={true}
                                />

                            </div>
                        </>
                        }

                        <br/>
                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput source="emisora" label={"Cod.Emisora"}
                                       className={classes.emisora}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={emisora} fullWidth
                                       format={formatEmisora}
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="emisora_d" label={"Emisora"}
                                       className={classes.emisora_d}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={emisora_d} fullWidth
                                       format={formatEmisora_d}
                                       InputLabelProps={{shrink: true}}
                            />

                            <UnidadEmisoraBusquedaBtn
                                callbackUnidadEmisora={updateUEmisora} unidadNegocio={unidadNegocio}
                                className={classes.btnBusqueda} fullWidth={true}/>
                        </div>
                        <br/>
                        <div style={{display: 'inline-flex', width: '100%'}}>
                            <TextInput source="gerencia" label={"Cod.Gerencia"}
                                       className={classes.gerencia}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={gerencia} fullWidth
                                       format={formatGerencia}
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="gerencia_d" label={"Gerencia"}
                                       className={classes.gerencia_d}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={gerencia_d} fullWidth
                                       format={formatGerencia_d}
                                       InputLabelProps={{shrink: true}}
                            />
                            <UnidadGerenciaBusquedaBtn callbackUnidadGerencia={updateUGerencia}
                                                       unidadNegocio={unidadNegocio}
                                                       className={classes.btnBusqueda} fullWidth={true}/>
                        </div>
                        <br/>
                        <div style={{display: 'inline-flex', width: '100%'}}>
                            <TextInput source="sector" label={"Cod.Sector"}
                                       className={classes.sector}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={sector} fullWidth
                                       format={formatSector}
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="sector_d" label={"Sector"}
                                       className={classes.sector_d}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={sector_d} fullWidth
                                       format={formatSector_d}
                                       InputLabelProps={{shrink: true}}
                            />
                            <UnidadSectorBusquedaBtn callbackUnidadSector={updateUSector} unidadNegocio={unidadNegocio}
                                                     className={classes.btnBusqueda} fullWidth={true}/>
                        </div>
                        <br/>
                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput source="programa" label={"Cod.Programa"}
                                       className={classes.programa}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={programa} fullWidth
                                       format={formatPrograma}
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="programa_d" label={"Programa"}
                                       className={classes.programa_d}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       value={programa_d} fullWidth
                                       format={formatPrograma_d}
                                       InputLabelProps={{shrink: true}}
                            />
                            <UnidadProgramaBusquedaBtn callbackUnidadPrograma={updateUPrograma}
                                                       unidadNegocio={unidadNegocio}
                                                       className={classes.btnBusqueda} fullWidth={true}/>
                        </div>
                        <br/>

                        <TextInput source="procedimiento"
                                   label={"Procedimiento"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.procedimiento} fullWidth={true}/>
                        <br/>

                        {automatico ?
                            <TextInput label="Expediente" source="procedimeinto_referencia_gde"
                                       className={classes.expediente}
                                       fullWidth
                                       value={OCexpediente}
                                       format={formatOCexpediente}
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       variant="filled" inputProps={{readOnly: true,}}
                            />
                            :
                            <TextInput label="Expediente" source="procedimeinto_referencia_gde"
                                       className={classes.expediente} fullWidth
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       variant="outlined"
                            />
                        }

                        {/*17/3/23 Mingo pidió que no sea manual. Que se cargue desde la contratación*/}
                        {/*  {esAmpliacion === 'N' &&
                        <TextInput label="Expediente" source="procedimeinto_referencia_gde"
                                   className={classes.expediente}
                                   fullWidth
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                                   variant="outlined"
                        />
                        }*/}

                        <br/>

                        <TextInput source="informe_adjudicacion"
                                   label={"Informe Adjudicación"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.informe_adjudicacion} fullWidth={true}/>
                        <br/>
                        <TextInput source="informe_adjudicacion_referencia_gde"
                                   label={"Referencia Adjudicación GDE"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.informe_adjudicacion_referencia_gde} fullWidth={true}/>
                    </>
                    }

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default OrdenCompraCabeceraCreate;


