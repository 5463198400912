import React, {useState} from 'react';
import {
    useQuery,
    Datagrid,
    TextField,
    Pagination,
    Loading, DateField, TextInput, FormTab, ListContextProvider, NumberField
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import IconBuscar from '@material-ui/icons/Search';
import IconSeleccionar from '@material-ui/icons/Done';
import {makeStyles} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {TextField as TextFieldMUI} from '@material-ui/core';
import IconCancelar from '@material-ui/icons/Close';
import ReplayIcon from "@material-ui/icons/Replay";
import FilterListIcon from "@material-ui/icons/FilterList";
import YoutubeSearchedForIcon from "@material-ui/icons/YoutubeSearchedFor";
import IconClear from "@mui/icons-material/Clear";

export const ComprobantesBusquedaDialogDataGridDatagrid = (props) => {

    var wr_props_unidad_negocio_ing = props.unidad_negocio
    var wr_props_orden_compra_nro_ing = props.orden_compra_nro
    var wr_props_orden_compra_ampliacion_nro_ing = props.orden_compra_ampliacion_nro

    console.log('ComprobantesBusquedaDialogDataGridDatagrid----wr_props_unidad_negocio_ing')
    console.log(wr_props_unidad_negocio_ing)
    console.log('ComprobantesBusquedaDialogDataGridDatagrid----wr_props_orden_compra_nro_ing')
    console.log(wr_props_orden_compra_nro_ing)
    console.log('ComprobantesBusquedaDialogDataGridDatagrid----wr_props_orden_compra_ampliacion_nro_ing')
    console.log(wr_props_orden_compra_ampliacion_nro_ing)

    const [props_proveedor, setprops_proveedor] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({field: 'orden', order: 'ASC'})
    const [filter, setFilter] = useState('');
    const [busqueda, setBusqueda] = useState('');

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'ComprobantesFacComEncuadreQLista',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: {qb: filter, unidad_negocio_ing: wr_props_unidad_negocio_ing,
                orden_compra_nro: wr_props_orden_compra_nro_ing,
                orden_compra_ampliacion_nro: wr_props_orden_compra_ampliacion_nro_ing,
                utilizando_busqueda: 'S' },
        }
    });

    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    /*Botón seleccionar registro*/
    const BtnConfirmar = ({record}) => (
        <IconButton style={{fontSize: 15, padding: 2}}
                    onClick={() => {
                        props.callbackComprobantes(record);
                        props.callbackCloseDialog(false)
                    }}
        >

            <IconSeleccionar style={{fontSize: 20}}/>
        </IconButton>
    );

    // /*Botón de búsqueda*/
    // const BtnBuscar = ({event}) => (
    //     <Button
    //         onClick={() => {
    //             setFilter(busqueda);
    //             setPage(1);
    //             setPerPage(10);
    //         }}
    //         style={{color: '#ffffff', backgroundColor: '#64c8ff'}}
    //         size={'small'}
    //     >
    //         <IconBuscar style={{fontSize: 20}}/> <h4>{' buscar'}</h4>
    //     </Button>
    // );
    //
    // /*Botón de borrar*/
    // const BtnBorrar = ({event}) => (
    //     <Button
    //         onClick={() => {
    //             setFilter('');
    //             setBusqueda('');
    //             setPage(1);
    //             setPerPage(10);
    //         }}
    //         style={{color: '#ffffff', backgroundColor: '#123B80'}}
    //         size={'small'}
    //     >
    //         <ReplayIcon/>
    //         <FilterListIcon style={{fontSize: 20}}/>
    //         <h4>{' Borrar Filtros'}</h4>
    //     </Button>
    // );

    /*Botón de búsqueda*/
    const BtnBuscar = ({event}) => (
        <Button
            variant="contained"
            onClick={() => {
                setFilter(busqueda);
                setPage(1);
                setPerPage(10);
            }}
            style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
            size={"small"}
            startIcon={<IconBuscar/>}
        >
            Buscar
        </Button>
    );

    /*Botón de borrar*/
    const BtnBorrar = ({event}) => (
        <Button
            variant="contained"
            onClick={() => {
                setFilter('');
                setBusqueda('');
                setPage(1);
                setPerPage(10);
            }}
            style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
            size={'small'}
            startIcon={<YoutubeSearchedForIcon/>}
        >
            Borrar
        </Button>
    );

    const Btntitulo = ({event}) => (
        <h4>{'Comprobantes'}</h4>
    );

    const BtnVolver = ({record}) => (
        <Button variant="contained" size={"small"} color="primary"
                onClick={() => {
                    props.callbackCloseDialog(false)
                }}
                startIcon={<IconCancelar/>}
                style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
        >
            Volver
        </Button>
    );


    return (
        <div>
            <div style={{margin: '3em'}}>
                <Btntitulo/>
                <TextFieldMUI label="Buscar"
                              onChange={(v) => setBusqueda(v.target.value)}
                              defaultValue={filter}
                              style={{marginLeft: '2px', width: '200px', height: '100px'}}
                />

                <BtnBuscar/>

                {filter === '' ? '' :
                    <BtnBorrar/>
                }

                <ListContextProvider value={{
                    data: keyBy(data, 'id'),
                    ids: data.map(({ id }) => id),
                    total,
                    page,
                    perPage,
                    setPage,
                    currentSort: { field: 'orden', order: 'ASC' },
                    basePath: "/posts",
                    resource: 'posts',
                    selectedIds: []
                }}>
                    <Datagrid  rowStyle={RowStyle}
                               data={keyBy(data, 'id')}
                               ids={data.map(({id}) => id)}
                               currentSort={sort}
                               setSort={(field, order) => setSort({field, order})}
                    >
                        <TextField label="Legajo" source="n_leg"/>
                        <TextField label="Ingreso" source="f_leg"/>
                        <TextField label="Comprobante" source="abre"/>
                        <TextField label="Número" source="n_cpt"/>
                        <TextField label="Fecha" source="f_cpt"/>
                        <TextField label="Moneda" source="d_moneda_abre"/>
                        <NumberField label="Importe Total" source="tot_gral"
                                     style={{textAlign: 'right', margin: '1em'}}
                                     options={{minimumFractionDigits: 2}}/>
                        <TextField label="Proveedor" source="pro"/>
                        <TextField label="CUIT" source="cuit"/>
                        <TextField label="Razón Social" source="raz"/>
                    </Datagrid>
                    <Pagination
                        rowsPerPageOptions={[]}
                        page={page}
                        setPage={setPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        total={total}
                    />
                </ListContextProvider >
                <BtnVolver/>
            </div>
        </div>
    )
};


const RowStyle = (record, index) => ({
    backgroundColor: record.pro  === undefined || record.proveedor_ing === undefined ? '' :
        record.pro.trim() === record.proveedor_ing.trim() ? '#64c8ff' : '',
});

export default ComprobantesBusquedaDialogDataGridDatagrid;