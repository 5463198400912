import React from 'react';
import Grid from '@material-ui/core/Grid';
import VerInfo from './VerInfo'

// Método que carga más datos de cada registro. Haciendo Click en el registro se expande hacia abajo.
const UnidadNegocioInfo = ({record, ...rest}) => (
    <Grid container spacing={12}>
        <Grid container spacing={12}>
            <Grid container spacing={12}>
                <Grid item xs={12}>
                    <VerInfo
                        ver_domicilio_pais={record.d_pais}
                        ver_domicilio_provincia={record.domicilio_provincia}
                        ver_domicilio_partido={record.domicilio_partido}
                        ver_domicilio_ciudad={record.domicilio_ciudad}
                        ver_domicilio_calle={record.domicilio_calle}
                        ver_domicilio_calle_nro={record.domicilio_calle_nro}
                        ver_domicilio_piso={record.domicilio_piso}
                        ver_domicilio_departamento={record.domicilio_departamento}
                        ver_domicilio_codigo_postal={record.domicilio_codigo_postal}
                        ver_contacto_correo_electronico={record.contacto_correo_electronico}
                        ver_contacto_telefono_area={record.contacto_telefono_area}
                        ver_contacto_telefono_nro={record.contacto_telefono_nro}
                        ver_contacto_fax_area={record.contacto_fax_area}
                        ver_contacto_fax_nro={record.contacto_fax_nro}
                        ver_cuit={record.cuit}
                        ver_iva_responsable={record.iva_responsable}
                        ver_ibru_responsable={record.ibru_responsable}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>

);
export default UnidadNegocioInfo;
