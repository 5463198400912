import React, {useState} from "react";
import {
    FormTab,
    Create,
    TextInput,
    TabbedForm,
    required, Toolbar, SaveButton, DeleteButton
} from 'react-admin';
import Button from '@mui/material/Button';
import {makeStyles} from "@material-ui/core";
import PersonaTipoPersonaForma from "../componentes/PersonaTipoPersonaForma/PersonaTipoPersonaForma";
import CuitCuil from "../componentes/CuitCuil/CuitCuil";
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    razon_social: {display: 'inline-block', width: 400},
    cuit_cuil: {display: 'inline-block', width: 160},
    email: {display: 'inline-block', width: 270},
}));

const ProveedorCreate = props => {
        const classes = useStyles();
        const [personaTipo, setPersonaTipo] = useState(0);

        const PostTitle = ({record}) => {
            return <span>Nuevo Proveedor</span>;
        };

        const CustomToolbar = props => (

            <Toolbar {...props}>
                <SaveButton
                    label="Grabar"
                    transform={transform}
                    submitOnEnter={false}
                />
            </Toolbar>
        );

        const transform = data => {
            return {
                ...data
            }
        };

        /*        const validateEdit = (values, allValues) => {
                    const errors = {};
                    var w_return = 'N';

                    console.log("cuit: ", values.cuit_cuil)

                    if (values.cuit_cuil == undefined || values.cuit_cuil == '') {
                        errors.cuit_cuil = ['Debe ingresar un CUIT/CUIL válido'];
                        w_return = 'S'
                    }

                    if (w_return === 'S') {
                        return errors
                    }
                };*/

        const validateUserCreation = async (values) => {
            const errors = {};
            if (!values.cuit_cuil) {
                errors.cuit_cuil = 'Debe ingresar un CUIT/CUIL válido';
            }
            return errors
        };

        return (
            <Create {...props} title={<PostTitle/>}

            >
                <TabbedForm
                    toolbar={<CustomToolbar/>}
                    validate={validateUserCreation}
                >
                    <FormTab label="DATOS DE LA EMPRESA">

                        <PersonaTipoPersonaForma setPersonaTipo={setPersonaTipo} isReadonly={false}
                        />

                        <br/>

                        <TextInput source="razon_social" label={"Razón Social"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                            // validate={required()}
                                   formClassName={classes.razon_social}
                                   fullWidth/>

                        <br/>

                        <div style={{display: "flex", alignItems: "baseline"}}>

                            <CuitCuil personaTipo={personaTipo} isReadonly={false} validarExistenciaBD={true}

                                // handleEstadoCuit={handleEstadoCuit}
                            />

                        </div>
                        <br/>

                        <TextInput source="correo_electronico" label={"Email"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   formClassName={classes.email} fullWidth={true}/>

                    </FormTab>
                </TabbedForm>
            </Create>
        )
    }
;
export default ProveedorCreate;


