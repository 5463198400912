import React, {useCallback, useState} from 'react';
import {useNotify, useRefresh, useMutation, ReferenceInput, SelectInput} from 'react-admin';
import {
    TextInput,
} from 'react-admin';
import Button from '@mui/material/Button';
import IconCancelar from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {makeStyles} from "@material-ui/core";
import DocumentacionTipoBusquedaBtn from "../DocumentacionTipo/DocumentacionTipoBusquedaBtn";

const useStyles = makeStyles((theme) => ({

    documentacion_tipo: {display: 'inline-block', width: '100%'},
    documentacion_tipo_d: {display: 'inline-block', width: '100%'},
    documentacion_ingresar_vencimiento: {display: 'inline-block', width: '100%'},
    documentacion_adjuntar_archivo: {display: 'inline-block', width: '100%'},
    fecha_ingreso: {display: 'inline-block', width: '100%'},
    fecha_vencimiento: {display: 'inline-block', width: '100%'},
    id_documentacion_tipo: {display: 'inline-block', width: '100%'},

}));


function ProveedorDocumentacionAgregar({pro, callbackCloseDrawer}) {
    // const [page, setPage] = useState(1);
    // const [perPage, setPerPage] = useState(25);
    // const [sort, setSort] = useState({field: 'id', order: 'ASC'});
    const classes = useStyles();

    const [{
        proveedor,
        documentacion_tipo,
        documentacion_tipo_d,
        documentacion_ingresar_vencimiento,
        documentacion_adjuntar_archivo,
        fecha_ingreso,
        fecha_vencimiento,
        id_documentacion_tipo
    }, setState] = useState({
        proveedor: pro,
        documentacion_tipo: "",
        documentacion_tipo_d: "",
        documentacion_ingresar_vencimiento: "",
        documentacion_adjuntar_archivo: "",
        fecha_ingreso: null,
        fecha_vencimiento: null,
        id_documentacion_tipo: null
    });

    /// JN Mascara para el input cuit-cuil
    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    function handleTextInputChange({target: {name, value}}) {
        setState(prevState => ({...prevState, [name]: value}));
    }

    const BtnCancelar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
            callbackCloseDrawer(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const BotonCrearDetelle = (record) => {

        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'create',
                resource: 'ProveedorDocumentacion',
                payload: {
                    data: {
                        proveedor,
                        documentacion_tipo,
                        documentacion_tipo_d,
                        documentacion_ingresar_vencimiento,
                        documentacion_adjuntar_archivo,
                        fecha_ingreso,
                        fecha_vencimiento,
                        id_documentacion_tipo
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <SaveIcon style={{color: '#004187'}}/>
                    Guardar
                </div>
            </Button>
        );

    };

    const formatid_documentacion_tipo = v => {
        return id_documentacion_tipo
    };

    const formatdocumentacion_tipo = v => {
        return documentacion_tipo
    };

    const formatdocumentacion_tipo_d = v => {
        return documentacion_tipo_d
    };

    const formatdocumentacion_ingresar_vencimiento = v => {
        return documentacion_ingresar_vencimiento
    };

    const formatdocumentacion_adjuntar_archivo = v => {
        return documentacion_adjuntar_archivo
    };


    const updateDocumentacionTipo = useCallback(async (who) => {
        setState(state => ({
            ...state,
            id_documentacion_tipo: who.id,
            documentacion_tipo: who.documentacion_tipo,
            documentacion_tipo_d: who.documentacion_tipo_d,
            documentacion_ingresar_vencimiento: who.documentacion_ingresar_vencimiento,
            documentacion_adjuntar_archivo: who.documentacion_adjuntar_archivo,

        }));

    });

    return (
        <div style={{paddingLeft: "10px"}}>

            <div style={{display: 'inline-flex', width: '100%'}}>
                <TextInput source="id_documentacion_tipo" label={"Documentacion Tipo ID"}
                           className={classes.id_documentacion_tipo}
                           variant="outlined" inputProps={{readOnly: true}}
                           value={id_documentacion_tipo} fullWidth
                           format={formatid_documentacion_tipo}
                           InputLabelProps={{shrink: true}}
                />

                <DocumentacionTipoBusquedaBtn
                    callbackDocumentacionTipo={updateDocumentacionTipo}
                    className={classes.btnBusqueda} fullWidth={true}/>
            </div>



            <TextInput source="documentacion_tipo" label={"Documentacion Tipo"}
                       className={classes.documentacion_tipo}
                       variant="filled" inputProps={{readOnly: true,}}
                       value={documentacion_tipo} fullWidth={true}
                       format={formatdocumentacion_tipo}
                       InputLabelProps={{shrink: true}}
            />

            <TextInput source="documentacion_tipo_d" label={"Documentacion Tipo Descripcion"}
                       className={classes.documentacion_tipo_d}
                       variant="filled" inputProps={{readOnly: true,}}
                       value={documentacion_tipo_d} fullWidth={true}
                       format={formatdocumentacion_tipo_d}
                       multiline
                       InputLabelProps={{shrink: true}}
            />
            <TextInput source="documentacion_adjuntar_archivo" label={"Documentacion Adjuntar Archivo"}
                       className={classes.documentacion_adjuntar_archivo}
                       variant="filled" inputProps={{readOnly: true,}}
                       value={documentacion_adjuntar_archivo} fullWidth={true}
                       format={formatdocumentacion_adjuntar_archivo}
                       InputLabelProps={{shrink: true}}
            />
            <TextInput source="documentacion_ingresar_vencimiento" label={"Documentacion Ingresar Vencimiento"}
                       className={classes.documentacion_ingresar_vencimiento}
                       variant="filled" inputProps={{readOnly: true,}}
                       value={documentacion_ingresar_vencimiento} fullWidth={true}
                       format={formatdocumentacion_ingresar_vencimiento}
                       InputLabelProps={{shrink: true}}
            />


            <div>

                <BotonCrearDetelle/>

                <BtnCancelar/>


            </div>
        </div>
    );
}

export default ProveedorDocumentacionAgregar;