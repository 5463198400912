import React, {useState} from 'react';
import OCDetalleAlta from "./OCDetalleAlta"
import {Drawer} from "@material-ui/core";
import Button from '@mui/material/Button';
import Icon from '@material-ui/icons/AddCircleOutlineRounded';
import {makeStyles} from '@material-ui/core/styles';

export const OCDetalleBtnCrear = (props) => {

    const useStyles = makeStyles({
        paper: {
            width: '50%'
        },
        button: {
            justifyContent: 'left',
            display: 'inline-flex',
            fontWeight: 'bold',
            margin: 0,
            marginBottom: 0
        }
    });

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(false);
    };


    return (
        <>

            {(props.data.id_moneda > 0 && props.data.moneda_cotizacion > 0 && props.data.moneda_cotizacion_fecha) || (props.total > 0) ?
                <Button variant="contained" style={{backgroundColor: '#004187'}} endIcon={<Icon/>}
                        onClick={() => setOpen(!open)}>
                    Crear Item
                </Button>
                :
                <Button disabled
                        variant="contained"
                        className={classes.button}
                        endIcon={<Icon/>}
                >
                    Crear Item
                </Button>
            }

            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={open}
            >
                {open &&
                <>
                    <OCDetalleAlta
                        callbackHayCambios={props.callbackHayCambios}
                        callbackCloseDrawer={handleDrawerOpen}
                        Data={props.data}
                    />
                </>
                }
            </Drawer>
        </>
    )
};
export default OCDetalleBtnCrear;