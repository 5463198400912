import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField, useNotify, useRefresh, useMutation, useDelete
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from "@material-ui/icons/Save";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import axios from "axios";

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const EliminarProveedorDocumentacionAdjunto = ({record}) => {
    var wr_id = record.id

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const [openEliminar, setOpenEliminar] = React.useState(false);

    const handleClickOpenEliminar = () => {
        dataProvider.getList(
            'ProveedorDocumentacionAdjuntoEliminar',
            {
                filter: {
                    id_adjunto: wr_id
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                var wec_validacion = data[0].validacion
                if(wec_validacion==null){
                    wec_validacion=''
                }
                wec_validacion=wec_validacion.trim()
                var wec_mensaje = data[0].mensaje
                if (wec_validacion === 'E') {
                    notify(wec_mensaje, 'info', {}, false);
                } else {
                    setOpenEliminar(true);
                }

            })
            .catch(error => {

            })

    };

    const BotonEliminarDetelle = (record) => {

        const refresh = useRefresh();

        const [deleteOne, {isLoading, error}] = useDelete(
            'ProveedorDocumentacionAdjunto',
            wr_id
        );
        const handleClickEliminar = () => {
            deleteOne();
            refresh();
        };
        if (error) {
            return <p>ERROR</p>;
        }
        return (
            <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                    onClick={handleClickEliminar}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    Si
                </div>
            </Button>
        );

    };

    const handleNoEliminar = () => {
        setOpenEliminar(false);
    };

    return (
        <div>
            <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                    onClick={handleClickOpenEliminar}>
                <div title={'Eliminar Archivo de la Documentación'}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <DeleteForeverIcon style={{color: '#c00000', backgroundColor: '#ffffff'}}/>
                </div>
            </Button>
            <Dialog open={openEliminar} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Archivo</DialogTitle>
                <DialogContent>
                    ¿Seguro que quiere eliminar este Archivo?
                </DialogContent>
                <DialogActions>
                    <BotonEliminarDetelle/>
                    <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                            onClick={handleNoEliminar}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            NO
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

EliminarProveedorDocumentacionAdjunto.defaultProps = {
    source: '',
    addLabel: true,
};

export default EliminarProveedorDocumentacionAdjunto;