import React, {useCallback, useState} from 'react';
import {
    useNotify,
    useRefresh,
    useMutation,
    ReferenceInput,
    SelectInput,
    FileField,
    FileInput,
    useDelete, useUpdate, useCreate
} from 'react-admin';
import {
    TextInput,
} from 'react-admin';
import Button from '@mui/material/Button';
import IconCancelar from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {Drawer, makeStyles} from "@material-ui/core";
import DocumentacionTipoBusquedaBtn from "../DocumentacionTipo/DocumentacionTipoBusquedaBtn";
import Icon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
import ProveedorDocumentacionAdjuntoSaveButton from "./ProveedorDocumentacionAdjuntoSaveButton"
import IconEliminar from "@material-ui/icons/Delete";
import axios from 'axios';
import FilePondUpload from "./FilePondUpload"
import Compressor from 'compressorjs';
import ApiUrlServer from '../layout/ApiUrlServer'
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// Importar plugin
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AttachFileIcon from '@material-ui/icons/AttachFile';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview,FilePondPluginImageResize);


var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()


const useStyles = makeStyles((theme) => ({

    documentacion_tipo: {display: 'inline-block', width: '100%'},
    documentacion_tipo_d: {display: 'inline-block', width: '100%'},
    documentacion_ingresar_vencimiento: {display: 'inline-block', width: '100%'},
    documentacion_adjuntar_archivo: {display: 'inline-block', width: '100%'},
    fecha_ingreso: {display: 'inline-block', width: '100%'},
    fecha_vencimiento: {display: 'inline-block', width: '100%'},
    id_documentacion_tipo: {display: 'inline-block', width: '100%'},

}));


function ProveedorDocumentacionAdjuntoAgregar({record, refetch}) {
    // const [page, setPage] = useState(1);
    // const [perPage, setPerPage] = useState(25);
    // const [sort, setSort] = useState({field: 'id', order: 'ASC'});
    const classes = useStyles();

    var dateFechaEncuadre = new Date();
    var duedateFechaEncuadre = new Date(dateFechaEncuadre);
    duedateFechaEncuadre.setDate(dateFechaEncuadre.getDate());
    var dFechaEncuadre = new Date(duedateFechaEncuadre || Date.now()),
        monthFechaEncuadre = '' + (dFechaEncuadre.getMonth() + 1),
        dayFechaEncuadre = '' + (dFechaEncuadre.getDate()),
        yearFechaEncuadre = dFechaEncuadre.getFullYear();

    //console.log(month)

    if (monthFechaEncuadre.length < 2) monthFechaEncuadre = '0' + monthFechaEncuadre;
    if (dayFechaEncuadre.length < 2) dayFechaEncuadre = '0' + dayFechaEncuadre;

    // var w_f_carga1FechaEncuadre = [dayFechaEncuadre, monthFechaEncuadre, yearFechaEncuadre].join('/');
    var w_f_carga2FechaEncuadre = [yearFechaEncuadre, monthFechaEncuadre, dayFechaEncuadre].join('-');

    const [openAgregar, setOpenAgregar] = useState(false);

    const [documentacion_ingresar_vencimientoAgregar, setdocumentacion_ingresar_vencimientoAgregar] = useState(record.documentacion_ingresar_vencimiento);
    const [documentacion_adjuntar_archivoAgregar, setdocumentacion_adjuntar_archivoAgregar] = useState(record.documentacion_adjuntar_archivo);

    const [EstadoArchivosAdjuntos, setEstadoArchivosAdjuntos] = useState('')
    const [Estadofecha_vencimiento, setEstadofecha_vencimiento] = useState(w_f_carga2FechaEncuadre)
    const [errorfecha_vencimiento, setErrorfecha_vencimiento] = useState(false);
    const [helperTextfecha_vencimiento, setHelperTextfecha_vencimiento] = useState('');

    const handleDrawerOpenAgregar = () => {
        setOpenAgregar(false);
    };

    const cambiaArchivosAdjuntos = (event) => {
        const CambiaArchivosAdjuntos = (wp_value) => {
            console.log('wp_value----------------------CambiaArchivosAdjuntos')
            console.log(wp_value)
            setEstadoArchivosAdjuntos(wp_value)
            console.log('EstadoArchivosAdjuntos')
            console.log(EstadoArchivosAdjuntos)
        };

        console.log('event----------------------cambiaArchivosAdjuntos')
        console.log(event)

        var w_value = event
        CambiaArchivosAdjuntos(w_value)
    }

   const cambiafecha_vencimiento = (event) => {
        const Cambiafecha_vencimiento = (wp_value) => {
            // console.log('wp_value----------------------Cambiafecha_vencimiento')
            // console.log(wp_value)
            setEstadofecha_vencimiento(wp_value)
            // console.log('Estadofecha_vencimiento')
            // console.log(Estadofecha_vencimiento)
        };
        const Cambiafecha_vencimientoError = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadofecha_vencimiento(wp_value)
            // console.log('Estadofecha_vencimiento')
            // console.log(Estadofecha_vencimiento)
            setErrorfecha_vencimiento(true);

        };
        setHelperTextfecha_vencimiento('')
        setErrorfecha_vencimiento(false);
        var w_value = event.target.value
        // console.log('w_value----------------cambiafecha_vencimiento')
        // console.log(w_value)
        if(w_value){
            if(w_value===null){
                w_value=''
            }
            w_value=w_value.trim()
            var w_value_length = w_value.length
            // console.log('w_value_length')
            // console.log(w_value_length)
            if(w_value_length===10) {
                var wh_substring = w_value.substring(0, 4)
                // console.log('wh_substring')
                // console.log(wh_substring)
                var wh_ano = parseInt(wh_substring)
                // console.log('wh_ano')
                // console.log(wh_ano)
                if (wh_ano >= 2000) {
                    Cambiafecha_vencimiento(w_value)
                }else{
                    setHelperTextfecha_vencimiento('El año de la Fecha tiene que ser mayor o igual a 2000')
                    Cambiafecha_vencimientoError(w_value)
                }
            }else{
                setHelperTextfecha_vencimiento('Error en el Formato de Fecha')
                Cambiafecha_vencimientoError(w_value)
            }
        }else{
            setHelperTextfecha_vencimiento('Error en el Formato de Fecha')
            Cambiafecha_vencimientoError(w_value)
        }

    }

    const [files, setFiles] = useState([]);
    const [IDArchivoAdjunto, setIDArchivoAdjunto] = useState(0);

    const [{
        proveedor,
        documentacion_tipo,
        fecha_vencimiento,
        id_proveedor_documentacion,
        ArchivosAdjuntos,
        src
    }, setState] = useState({
        proveedor: record.pro,
        documentacion_tipo: record.documentacion_tipo,
        fecha_vencimiento: null,
        id_proveedor_documentacion: record.id,
        ArchivosAdjuntos: null,
        src: null
    });

    /// JN Mascara para el input cuit-cuil
    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    function handleTextInputChange({target: {name, value}}) {
        setState(prevState => ({...prevState, [name]: value}));
    }

    const BtnCancelar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
            handleDrawerOpenAgregar(false)
            setFiles([])
            setIDArchivoAdjunto(0)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const BotonProveedorDocumentacionAdjuntoSave = (record) => {

        const notify = useNotify();
        // const refresh = useRefresh();
        //
        // const [generardetelle, {loading}] = useMutation({
        //         type: 'create',
        //         resource: 'ProveedorDocumentacionAdjunto',
        //         payload: {
        //             data: {
        //                 proveedor,
        //                 documentacion_tipo,
        //                 fecha_vencimiento,
        //                 id_proveedor_documentacion,
        //                 ArchivosAdjuntos,
        //                 src
        //             }
        //         }
        //     },
        //     {
        //         onSuccess: ({data}) => {
        //             refresh()
        //         },
        //         onFailure: error => {
        //             notify(`Error durante la Actualizacion: ${error.message}`, "warning");
        //         },
        //     }
        // );
        //
        //
        // return (
        //     <Button variant="outlined" color="primary" onClick={generardetelle}>
        //         <div title={''}
        //              style={{
        //                  display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
        //              }}>
        //             <SaveIcon style={{color: '#004187'}}/>
        //             Guardar
        //         </div>
        //     </Button>
        // );
        const [update] = useCreate('ProveedorDocumentacionAdjunto');
        const refresh = useRefresh();

        const [generardetelle, {isLoading, error}]  = useMutation({
                type: 'create',
                resource: 'ProveedorDocumentacionAdjunto',
                payload: {
                    data: {
                        proveedor,
                        documentacion_tipo,
                        Estadofecha_vencimiento,
                        id_proveedor_documentacion,
                        EstadoArchivosAdjuntos,
                        src
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );
        const handleClick = () => {
            // generardetelle();
            // refresh();


            console.log('files')
            console.log(files)

            update(
                {
                    payload: {
                        data: {
                            proveedor,
                            documentacion_tipo,
                            Estadofecha_vencimiento,
                            id_proveedor_documentacion,
                            files,
                            IDArchivoAdjunto
                        }
                    }
                },
                {
                    onSuccess: ({data: newRecord}) => {
                        notify('Listo el Adjunto de la Documentación Solicitada', 'info', {
                            smart_count: 1,
                        });
                        refetch();
                    },
                }
            );

            // console.log('EstadoArchivosAdjuntos')
            // console.log(EstadoArchivosAdjuntos)
            // console.log('EstadoArchivosAdjuntos.path')
            // console.log(EstadoArchivosAdjuntos.path)
            //
            // let w_length = EstadoArchivosAdjuntos
            // let w_mandar_mail
            // let w_cant_length = 0
            // let w_cant_for = 0
            // if (w_length === undefined){
            //     notify('Debe Ingresar un Archivo para Adjuntar', 'warning', {
            //         smart_count: 1,
            //     });
            // }else {
            //     w_mandar_mail = 'N'
            //     w_cant_length = EstadoArchivosAdjuntos
            //     console.log('w_cant_length')
            //     console.log(w_cant_length)
            //     // alert(w_cant_length)
            //
            //     if(w_cant_length===''){
            //         notify('Debe Ingresar un Archivo para Adjuntar', 'warning', {
            //             smart_count: 1,
            //         });
            //     }else {
            //         notify('Actualizando Archivo Adjunto', 'info', {
            //             smart_count: 1,
            //         });
            //         let webApiUrl = w_apiurlserver+'/api'
            //
            //         let tokenStr = localStorage.getItem('token');
            //
            //         const datafile = new FormData()
            //         const file = EstadoArchivosAdjuntos;
            //         console.log('file------------------------EstadoArchivosAdjuntos')
            //         console.log(file)
            //         console.log('file')
            //         console.log(file)
            //         console.log('file.name')
            //         console.log(file.name)
            //
            //         let type_file = file.type
            //         if (type_file == null) {
            //             type_file = ''
            //         }
            //         type_file = type_file.trim()
            //
            //         console.log('type_file')
            //         console.log(type_file)
            //
            //         let type_image = type_file.substring(0, 6);
            //
            //         console.log('type_image')
            //         console.log(type_image)
            //
            //         // alert('TI')
            //
            //         // const result = file
            //
            //         // alert('new Compressor')
            //
            //         // compress.attach('file', {
            //         //     size: 4,
            //         //     quality: .75
            //         // }).then((result) => {
            //
            //         // compress.compress(file, {
            //         //
            //         //     size: 4, // the max size in MB, defaults to 2MB
            //         //     quality: .75, // the quality of the image, max is 1,
            //         //     maxWidth: 1920, // the max width of the output image, defaults to 1920px
            //         //     maxHeight: 1920, // the max height of the output image, defaults to 1920px
            //         //     resize: true, // defaults to true, set false if you do not want to resize the image width and height
            //         // }).then((result) => {
            //
            //
            //         if (type_image === 'image/') {
            //
            //             new Compressor(file, {
            //                 quality: 0.6,
            //                 success(result) {
            //
            //                     console.log('result')
            //                     console.log(result)
            //
            //                     // alert('hola-----entre---new Compressor')
            //
            //                     // datafile.append('file', result);
            //                     // datafile.append('name', result.name);
            //                     // datafile.append('description', 'Este archivo es subido por mi');
            //
            //
            //                     datafile.append('file', result, result.name);
            //                     // datafile.append('proveedor', proveedor);
            //                     // datafile.append('documentacion_tipo', documentacion_tipo);
            //                     // datafile.append('Estadofecha_vencimiento', Estadofecha_vencimiento);
            //                     // datafile.append('id_proveedor_documentacion', id_proveedor_documentacion);
            //
            //                     // datafile.append('id_rem_ent', formState.values.id);
            //                     // datafile.append('firma', formState.values.firma);
            //
            //                     console.log('datafile----for')
            //                     console.log(datafile)
            //                     console.log({datafile})
            //                     console.log('datafile----for---FIN')
            //
            //                     const resultfile = result
            //
            //                     console.log('resultfile----for')
            //                     console.log(resultfile)
            //                     console.log('resultfile----for---FIN')
            //
            //                     // axios.post(webApiUrl, {datafile}, { headers: {
            //                     //     'Content-Type': 'application/json',
            //                     //     "x-auth-token" : `${tokenStr}`,
            //                     //     // 'content-type': 'multipart/form-data',
            //                     //     }} )
            //                     //     .then(res => { // then print response status
            //                     //         console.log(res.statusText)
            //                     //     })
            //                     //     .catch((error) => {
            //                     //         console.log(error);
            //                     //     });
            //
            //
            //                     update(
            //                         {
            //                             payload: {
            //                                 data: {
            //                                     proveedor,
            //                                     documentacion_tipo,
            //                                     Estadofecha_vencimiento,
            //                                     id_proveedor_documentacion,
            //                                     resultfile
            //                                 }
            //                             }
            //                         },
            //                         {
            //                             onSuccess: ({data: newRecord}) => {
            //                                 notify('Listo Archivo Adjunto Actualizado', 'info', {
            //                                     smart_count: 1,
            //                                 });
            //                                 refresh();
            //                             },
            //                         }
            //                     );
            //
            //
            //                     // axios.create({
            //                     //     baseURL: webApiUrl,
            //                     //     timeout: 10000,
            //                     //     headers: {
            //                     //         'Content-Type': 'application/json',
            //                     //         "x-auth-token": `${tokenStr}`
            //                     //
            //                     //     },
            //                     // }).post('/ProveedorDocumentacionAdjunto', datafile).then((response) => {
            //                     //     w_cant_for = w_cant_for + 1
            //                     //     console.log('w_cant_for--post')
            //                     //     console.log(w_cant_for)
            //                     //     if (w_cant_length === w_cant_for) {
            //                     //         w_mandar_mail = 'P'
            //                     //     }
            //                     //     console.log('w_mandar_mail---Final_aux')
            //                     //     console.log(w_mandar_mail)
            //                     //     console.log("File " + file.name + " is uploaded");
            //                     //     if (w_mandar_mail === 'P') {
            //                     //         notify('Listo Archivo Adjunto Actualizado', 'info', {
            //                     //             smart_count: 1,
            //                     //         });
            //                     //         refresh();
            //                     //     }
            //                     // }).catch(function (error) {
            //                     //     console.log(error);
            //                     //     if (error.response) {
            //                     //         //HTTP error happened
            //                     //         console.log("Upload error. HTTP error/status code=", error.response.status);
            //                     //     } else {
            //                     //         //some other error happened
            //                     //         console.log("Upload error. HTTP error/message code=", error.message);
            //                     //     }
            //                     // });
            //                     // })
            //
            //                 },
            //                 error(err) {
            //                     console.log(err.message);
            //                     // alert('erro')
            //                 },
            //             });
            //         } else {
            //             datafile.append('file', file);
            //             // datafile.append('proveedor', proveedor);
            //             // datafile.append('documentacion_tipo', documentacion_tipo);
            //             // datafile.append('Estadofecha_vencimiento', Estadofecha_vencimiento);
            //             // datafile.append('id_proveedor_documentacion', id_proveedor_documentacion);
            //
            //
            //             // datafile.append('id_rem_ent', formState.values.id);
            //             // datafile.append('firma', formState.values.firma);
            //             datafile.append('name', file.name);
            //             datafile.append('description', 'Este archivo es subido por mi');
            //
            //             console.log('datafile----for')
            //             console.log(datafile)
            //             console.log({datafile})
            //             console.log('datafile----for---FIN')
            //
            //
            //             const resultfile = file
            //
            //             console.log('resultfile----for')
            //             console.log(resultfile)
            //             console.log('resultfile----for---FIN')
            //
            //             // axios.post(webApiUrl, {datafile}, { headers: {
            //             //     'Content-Type': 'application/json',
            //             //     "x-auth-token" : `${tokenStr}`,
            //             //     // 'content-type': 'multipart/form-data',
            //             //     }} )
            //             //     .then(res => { // then print response status
            //             //         console.log(res.statusText)
            //             //     })
            //             //     .catch((error) => {
            //             //         console.log(error);
            //             //     });
            //
            //             update(
            //                 {
            //                     payload: {
            //                         data: {
            //                             proveedor,
            //                             documentacion_tipo,
            //                             Estadofecha_vencimiento,
            //                             id_proveedor_documentacion,
            //                             resultfile
            //                         }
            //                     }
            //                 },
            //                 {
            //                     onSuccess: ({data: newRecord}) => {
            //                         notify('Listo Archivo Adjunto Actualizado', 'info', {
            //                             smart_count: 1,
            //                         });
            //                         refresh();
            //                     },
            //                 }
            //             );
            //
            //
            //             // axios.create({
            //             //     baseURL: webApiUrl,
            //             //     timeout: 10000,
            //             //     headers: {
            //             //         'Content-Type': 'application/json',
            //             //         "x-auth-token": `${tokenStr}`
            //             //
            //             //     },
            //             // }).post('/ProveedorDocumentacionAdjunto', datafile).then((response) => {
            //             //     w_cant_for = w_cant_for + 1
            //             //     console.log('w_cant_for--post')
            //             //     console.log(w_cant_for)
            //             //     if (w_cant_length === w_cant_for) {
            //             //         w_mandar_mail = 'P'
            //             //     }
            //             //     console.log('w_mandar_mail---Final_aux')
            //             //     console.log(w_mandar_mail)
            //             //     console.log("File " + file.name + " is uploaded");
            //             //     if (w_mandar_mail === 'P') {
            //             //         notify('Listo Archivo Adjunto Actualizado', 'info', {
            //             //             smart_count: 1,
            //             //         });
            //             //         refresh();
            //             //     }
            //             // }).catch(function (error) {
            //             //     console.log(error);
            //             //     if (error.response) {
            //             //         //HTTP error happened
            //             //         console.log("Upload error. HTTP error/status code=", error.response.status);
            //             //     } else {
            //             //         //some other error happened
            //             //         console.log("Upload error. HTTP error/message code=", error.message);
            //             //     }
            //             // });
            //         }
            //
            //     }
            // }

        };
        if (error) {
            return <p>ERROR</p>;
        }
        return  (
            <div>
                {IDArchivoAdjunto === 0 ?
                    <Button  style={{color: '#9b9b9b', backgroundColor: '#eaeaea'}}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <SaveIcon/>
                            Guardar
                        </div>
                    </Button>
                    :
                    <Button variant="outlined" color="primary" onClick={handleClick}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <SaveIcon style={{color: '#004187'}}/>
                            Guardar
                        </div>
                    </Button>
                }
            </div>
        );

    };

    const handleFileChange = (files) => {
        setFiles(files)
        // console.log('handleFileChange');
        // console.log(files);
        // console.log(files.length ? files[0].file : null);
        // // alert('handleFileChange')
        //
        // console.log('handleFileChange--file--type');
        // console.log(files.length ? files[0].file.type : null);
        //
        // if(files.length) {
        //
        //     if (files[0].file.type.includes('image')) {
        //         console.log('Comprimir imagen');
        //
        //         // Comprimir imagen
        //         const compressedImage = FilePondPluginImageResize(files[0].file);
        //
        //         // Cargar imagen comprimida
        //         setFiles(compressedImage);
        //
        //     } else {
        //         console.log('Cargar archivo original');
        //         // Cargar archivo original
        //         setFiles(files);
        //
        //     }
        // }


    };

    let tokenStr = localStorage.getItem('token');

    var wa_location_hostname = window.location.hostname

    console.log('wa_location_hostname')
    console.log(wa_location_hostname)

    var wa_apiurlserver

    switch (wa_location_hostname) {
        case 'opi.radionacional.ar':
            wa_apiurlserver = 'https://opi.radionacional.ar:4443/'
            break
        case 'proveedores.radionacional.ar':
            wa_apiurlserver = 'https://archivprov.radionacional.ar/'
            break
        default:
            wa_apiurlserver = 'http://'+wa_location_hostname+':4620'
            break
    }


    const handleRevertFile = (error, file) => {
        if (!error) {
            const updatedFiles = files.filter((f) => f !== file);
            setFiles(updatedFiles);
        }
    };

    const handleProcessFile = (error, file) => {
        if (!error) {
            setFiles([...files, file]);
        }
    };

    return (
        <div style={{paddingLeft: "10px"}}>
            <Button variant="contained" size={"small"} style={{color: '#ffffff', backgroundColor: '#123B80'}}
                    onClick={() => setOpenAgregar(!openAgregar)}>
                <div title={'Agregar Archivo de la Documentación'}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <Icon/>
                    <AttachFileIcon/>
                    Agregar Archivo Documentacion
                </div>
            </Button>
            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={openAgregar}
            >
                <div style={{display: 'inline-flex', width: '100%'}}>

                    <div>
                        <h3>Agregar Archivo Documentacion</h3>
                    </div>

                </div>
                {openAgregar===true ?
                    <div>
                        <div>
                            <div style={{display: 'inline-block', width: 400, marginLeft: 8}}>
                                {/*<ProveedorDocumentacionAdjuntoAgregar*/}
                                {/*    record={record}*/}
                                {/*    callbackCloseDrawer={handleDrawerOpen}*/}
                                {/*    // callback={props.callbackArt}*/}
                                {/*/>*/}
                            </div>
                            <Table aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {/*<div style={{display: 'inline-block', width: 400, marginLeft: 8}}>*/}
                                            {/*    <div style={{display: 'inline-block', width: 400, marginLeft: 8}}>*/}
                                            <BtnCancelar/>
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </TableCell>
                                        <TableCell>
                                            <TextField disabled label="Detalle"
                                                       value={record.documentacion_tipo_d}
                                                       SelectProps={{native: true,}}
                                                       multiline
                                                       rows={2}
                                                       variant="outlined"
                                                       style={{ width: 600 }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </div>
                        <div>
                            <Table  size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField disabled
                                                       label="Nro Ingreso"
                                                       value={record.documentacion_nro_ingreso}
                                                       SelectProps={{native: true,}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField disabled
                                                       label="Tipo"
                                                       value={record.documentacion_tipo}
                                                       SelectProps={{native: true,}}
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <TextField disabled
                                                       label="Vencimiento"
                                                       value={record.documentacion_ingresar_vencimiento}
                                                       SelectProps={{native: true,}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </div>
                        <div>
                            <br />
                            <br />
                        </div>
                    </div>
                        :
                        ''
                        }
            <div style={{display: 'inline-flex', width: '100%', marginLeft: 8}}>
                {/*<div>*/}
                    {/*<FilePondUpload />*/}
                {/*</div>*/}


                <div className="container mt-4">
                    <h2 className="mb-3">Agregar Archivo Documentacion</h2>
                    <FilePond
                        allowMultiple={false}
                        files={files}
                        name={'archivo'}
                        allowReorder={true}
                        instantUpload={true}
                        storeAsFile={true}
                        allowRevert={false}
                        imageResizeTargetWidth={1920}
                        imageResizeTargetHeight={1080}
                        imageResizeQuality={0.5}
                        onupdatefiles={(fileItems) => handleFileChange(fileItems)}
                        // server='http://localhost:4620/ProveedorDocumentacionAdjunto' // File upload api goes here}*/}
                        server={{
                            // url: 'http://localhost:4620/ArchivoAdjunto',
                            url: wa_apiurlserver+'/ArchivoAdjunto',
                            process: {
                                method: 'POST',
                                headers: {
                                    'X-CSRF-TOKEN': 'TOKEN',
                                    "x-auth-token": `${tokenStr}`
                                },
                                onload: (response) => {
                                    console.log(response);

                                    const json = response;
                                    const data = JSON.parse(json);

                                    console.log(data.fieldname); // salida: "archivo"
                                    console.log(data.id); // salida: 17

                                    setIDArchivoAdjunto(data.id)
                                },
                                onerror: (response) => {
                                    console.log(response);
                                }
                            }
                        }}
                        onprocessfile={handleProcessFile}
                        onremovefile={(error, file) => {
                            handleRevertFile(error, file);
                        }}
                        labelFileProcessingError={'Error al procesar el archivo'}
                        labelFileTypeNotAllowed={'Tipo de archivo no permitido'}
                        labelMaxFileSizeExceeded={'Tamaño máximo de archivo excedido'}
                        labelMaxFileSize={'Tamaño máximo de archivo'}
                        labelMaxFiles={'Número máximo de archivos'}
                        labelIdle='Arrastre el archivo para subir o <span class="filepond--label-action">haga clic para seleccionarlo</span>'
                        credits={''}
                        labelFileProcessingComplete={'Carga completa'}
                        labelFileProcessing={'Cargando...'}
                        labelTapToCancel={'Toque para Cancelar'}
                        labelTapToRetry={'Toca para Reintentar'}
                        labelTapToUndo={'Toque para Deshacer'}
                    />

                    {/*<FilePond*/}
                    {/*    files={files}*/}
                    {/*    allowReorder={true}*/}
                    {/*    allowMultiple={true}*/}
                    {/*    onupdatefiles={setFiles}*/}
                    {/*    labelIdle='Arrastra y suelta tus archivos o <span class="filepond--label-action">Navegar</span>'*/}
                    {/*/>*/}

                </div>

                {/*<FileInput source="ArchivosAdjuntos"*/}
                {/*           label="Adjuntar Archivo"*/}
                {/*    // label="Adjuntar Archivos Maximo 10MB"*/}
                {/*           multiple={false}*/}
                {/*           value={EstadoArchivosAdjuntos}*/}
                {/*           onChange={cambiaArchivosAdjuntos}*/}
                {/*    // minSize={1000} maxSize={10485760}*/}
                {/*           placeholder={<p>Arrastre el archivo para subir o haga clic para seleccionarlo.</p>}>*/}
                {/*    <FileField source="src" title="title" />*/}
                {/*</FileInput>*/}

            </div>

                {documentacion_ingresar_vencimientoAgregar === 'S' ?
                    <div style={{display: 'inline-flex', width: '100%', marginLeft: 8}}>

                        <TextField
                            error={errorfecha_vencimiento}
                            id="fecha_vencimiento"
                            source="fecha_vencimiento"
                            label="Fecha Vencimiento"
                            type="date"
                            formClassName={classes.fecha_vencimiento}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={Estadofecha_vencimiento}
                            onChange={cambiafecha_vencimiento}
                            helperText={helperTextfecha_vencimiento}
                        />


                    </div>:
                    <div>

                    </div>
                }

                <br/>
                <br/>
                <br/>


            <div>
                <div style={{display: 'inline-block', width: 400, marginLeft: 8}}>
                    {/*<ProveedorDocumentacionAdjuntoSaveButton*/}
                    {/*    label="Guardar"*/}
                    {/*    submitOnEnter={true}*/}
                    {/*    undoable={false}*/}
                    {/*/>*/}
                    <BotonProveedorDocumentacionAdjuntoSave/>
                </div>

                <div style={{display: 'inline-block', width: 400, marginLeft: 8}}>
                    <BtnCancelar/>
                </div>

            </div>

            </Drawer>
        </div>
    );
}

export default ProveedorDocumentacionAdjuntoAgregar;