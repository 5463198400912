import React, {Fragment} from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import {Button, translate, WithPermissions} from 'react-admin';
import CardContent from "@material-ui/core/CardContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from 'react-router-dom';
import cartuchonewlargo from "./cartuchonewlargo.png";
import cartuchonew from "./cartuchonew.png";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RefreshIcon from "@material-ui/icons/Refresh";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LineWeightIcon from "@material-ui/icons/LineWeight";
import TextField from "@material-ui/core/TextField";
import Imagetv from '../layout/tvpublica_logo_2021.bmp'; // Import using relative path
import Imagerta from '../layout/headerRTA.jpg';
import Imagec12 from '../layout/Canal_12_Trenque_Lauquen.bmp';
import Imagecrna from '../layout/Radio_Nacional_Logo_2021.jpg';
import Avatar from "@material-ui/core/Avatar"; // Import using relative path
import AccountCircle from '@material-ui/icons/AccountCircle';
import CustomerIcon from "@material-ui/icons/Assignment";
import CardIcon from "./CardIcon";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import BusinessIcon from "@material-ui/icons/Business";
import TableBody from '@material-ui/core/TableBody';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

const styles = {
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    cardleft: {
        overflow: 'inherit',
        textAlign: 'left',
        padding: 16,
        minHeight: 52,
    },
    button1: {
        fontWeight: 'bold',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': { color: 'orange', backgroundColor: 'red'  }
    },
    textright:{
        textAlign: 'right',
    },
    textleft:{
        textAlign: 'left',
    },
    textcenter:{
        textAlign: 'center',
    },

};

const role_admuno_ord_pag_confirmar_ver = ({permissions}) => {
    var w_role_ord_pag_confirmar_ver = ''
    var w_permissions_ord_pag_confirmar_ver = permissions
    if(w_permissions_ord_pag_confirmar_ver==null){}else{
        var w_role_admuno_ord_pag_confirmar_ver = w_permissions_ord_pag_confirmar_ver.indexOf('ROLE_ADMUNO_ORD_PAG_CONFIRMAR_VER')
        if(w_role_admuno_ord_pag_confirmar_ver===-1){}else{
            w_role_ord_pag_confirmar_ver = 'ROLE_ADMUNO_ORD_PAG_CONFIRMAR_VER'
        }
    }
    return w_role_ord_pag_confirmar_ver
}

const CantTableroControlOrdenCompraEstado = ({ value,    OrdComroleadmunounidadnegociotodas,
                                            OrdComcurrenciesunidadnegocio,
                                            OrdComUnidadNegocio, cambiaunidadnegocio,
                                            ver_cantidad,
                                            ver_orden_compra_ano,
                                            ver_orden_compra_estado,
                                            ver_unidad_negocio,
                                            ver_estado_d,
                                            handleClickActualizarFlowdePago,
                                            translate, classes }) => {


    var w_ruta_unidad_negocio_solo = `/OrdComEstado`
    var w_ruta_unidad_negocio = ``

    if(OrdComroleadmunounidadnegociotodas===1){
        var w_ordpagunidadnegocio = OrdComUnidadNegocio
        if(w_ordpagunidadnegocio==null){
            w_ordpagunidadnegocio=''
        }
        w_ordpagunidadnegocio=w_ordpagunidadnegocio.trim()
        if(w_ordpagunidadnegocio==''){}else {
            w_ruta_unidad_negocio_solo = `/OrdComEstado?filter=%7B"unidad_negocio"%3A"${OrdComUnidadNegocio}"%7D&order=ASC&page=1&perPage=25&sort=id`
            w_ruta_unidad_negocio = `unidad_negocio"%3A"${OrdComUnidadNegocio}"%2C"`
        }
    }

    function RowVer() {
        const [open, setOpen] = React.useState(false);


        return (
            <React.Fragment>
                <TableRow className={classes.root}>
                    <TableCell>
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon style={{
                                color: ver_cantidad === 0 ? '#e6e6e6' : '#000000',
                                backgroundColor: '#ffff00'
                            }} /> : <KeyboardArrowDownIcon style={{
                                color: ver_cantidad === 0 ? '#e6e6e6' : '#000000',
                                backgroundColor: '#ffff00'
                            }} />}
                        </IconButton>
                    </TableCell>
                    <TableCell>
                        <ListItem
                            button
                            to={`/OrdComEstado?displayedFilters=%7B"estado_flow"%3Atrue%7D&filter=%7B"${w_ruta_unidad_negocio}estado_flow"%3A"VER"%7D&order=ASC&page=1&perPage=25&sort=id`}
                            component={Link}
                        >
                            <ListItemText
                                primary={
                                    <div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="headline" component="h2">
                                                            <LineWeightIcon titleAccess={'VER Total Flow de Pago'}
                                                                            style={{color: '#ffff00'}} >
                                                            </LineWeightIcon>{'VER'}
                                                            {/*<div>{`Total Flow de Pago`}</div>*/}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </div>
                                }
                                secondary={
                                    <div>
                                    </div>
                                }

                                className={classes.listItemText}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    style={{
                                        color: '#000000',
                                        backgroundColor: '#ffff00'
                                    }}
                                >
                                    <div>

                                        {
                                            `${
                                                ver_cantidad === undefined || ver_cantidad === null ? '0' :
                                                    ver_cantidad.toLocaleString(undefined, {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 16,
                                                    })
                                            }`
                                        }

                                    </div>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }


    return (
        <div style={{width: 240, margin: '1em'}}>
            <Card style={{color: '#ffffff' }}>
                <CardContent>
                    <Table style={{ background: `url(${cartuchonew}) no-repeat`}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <MailOutlineIcon/>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="headline" component="h2">
                                        <div>{`Flow de Pago`}</div>
                                    </Typography>
                                    {/*<div>{`Cotización USD: ${cotiza_dolar_hoy === undefined ? '0,00000' :*/}
                                    {/*    cotiza_dolar_hoy.toLocaleString(undefined, {*/}
                                    {/*        minimumFractionDigits: 5,*/}
                                    {/*        maximumFractionDigits: 16,*/}
                                    {/*    })}.`}</div>*/}
                                    {/*<div>{`Fecha Cotización USD: ${fecha_cotiza}.`}</div>*/}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.textcenter}>
                                    <Typography variant="headline" component="h2">
                                        {OrdComUnidadNegocio==='PVM' ? <Avatar
                                            src={`${Imagerta}?size=${30}x${30}`}
                                            size={30}
                                            style={{ width: 30, height: 30 }}
                                        />: ''}
                                        {OrdComUnidadNegocio==='CPM' ? <Avatar
                                            src={`${Imagec12}?size=${30}x${30}`}
                                            size={30}
                                            style={{ width: 30, height: 30 }}
                                        />: ''}
                                        {OrdComUnidadNegocio==='' ?
                                            OrdComroleadmunounidadnegociotodas===1 ?
                                                <div>
                                                    <Avatar
                                                        src={`${Imagerta}?size=${30}x${30}`}
                                                        size={30}
                                                        style={{ width: 30, height: 30 }}
                                                    />
                                                    <Avatar
                                                        src={`${Imagetv}?size=${30}x${30}`}
                                                        size={30}
                                                        style={{ width: 30, height: 30 }}
                                                    />
                                                </div>
                                                : ''
                                            : ''}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="headline" component="h4">
                                        {'Unidad Negocio'}
                                    </Typography>
                                    <form className={styles.container} noValidate>
                                        <TextField
                                            id="ord_pag_unidad_negocio_ing"
                                            select
                                            label=""
                                            size="small"
                                            // value={currency}
                                            value={OrdComUnidadNegocio}
                                            onChange={cambiaunidadnegocio}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            // helperText="Por favor seleccione su Mes"
                                        >
                                            {OrdComcurrenciesunidadnegocio.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </form>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <ListItem
                        button
                        to={w_ruta_unidad_negocio_solo}
                        component={Link}
                    >
                        <ListItemText
                            primary={
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <MailOutlineIcon/>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="headline" component="h2">
                                                        <div>{`Flow de Pago`}</div>
                                                    </Typography>
                                                    {/*<div>{`Cotización USD: ${cotiza_dolar_hoy === undefined ? '0,00000' :*/}
                                                    {/*    cotiza_dolar_hoy.toLocaleString(undefined, {*/}
                                                    {/*        minimumFractionDigits: 5,*/}
                                                    {/*        maximumFractionDigits: 16,*/}
                                                    {/*    })}.`}</div>*/}
                                                    {/*<div>{`Fecha Cotización USD: ${fecha_cotiza}.`}</div>*/}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                </div>
                            }
                            secondary={
                                <div>
                                    {ver_cantidad=== undefined ? '' :
                                        ver_cantidad === 0 ? '' :
                                            <Button
                                                title="Se Encuentra Sumando"
                                            >
                                                <AddCircleIcon style={{ fontSize: 15 }} />

                                            </Button>
                                    }
                                </div>
                            }

                            className={classes.listItemText}
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                size="small"
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                style={{
                                    color: '#ffffff',
                                    backgroundColor: '#000000'
                                }}
                            >
                                <div>

                                    {
                                        `${
                                            ver_cantidad === undefined || ver_cantidad === null ? '0' :
                                                ver_cantidad.toLocaleString(undefined, {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 16,
                                                })
                                        }`
                                    }

                                </div>
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>

                    {/*<WithPermissions*/}
                    {/*    render={({ permissions }) => (*/}
                    {/*        role_admuno_ord_pag_confirmar_ver({permissions }) === 'ROLE_ADMUNO_ORD_PAG_CONFIRMAR_VER'*/}
                    {/*            ?*/}
                    <div>
                        <ListItem
                            button
                            to={`/OrdComEstado?displayedFilters=%7B"estado_flow"%3Atrue%7D&filter=%7B"${w_ruta_unidad_negocio}estado_flow"%3A"OK"%7D&order=ASC&page=1&perPage=25&sort=id`}
                            component={Link}
                        >
                            <ListItemText
                                primary={
                                    <div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="headline" component="h2">
                                                            <LineWeightIcon titleAccess={'OK Total Flow de Pago'}
                                                                            style={{color: '#c8ff40'}} >
                                                            </LineWeightIcon>{'OK'}
                                                            {/*<div>{`Total Flow de Pago`}</div>*/}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </div>
                                }
                                secondary={
                                    <div>
                                    </div>
                                }

                                className={classes.listItemText}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    style={{
                                        color: '#000000',
                                        backgroundColor: '#c8ff40'
                                    }}
                                >
                                    <div>

                                        {
                                            `${
                                                ver_cantidad === undefined || ver_cantidad === null ? '0' :
                                                    ver_cantidad.toLocaleString(undefined, {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 16,
                                                    })
                                            }`
                                        }

                                    </div>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>


                        <Table>
                            <TableHead>
                                <TableRow>




                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/*{rows.map((row) => (*/}
                                <RowVer />
                                {/*))}*/}
                            </TableBody>
                        </Table>
                        <ListItem
                            button
                            to={`/OrdComEstado?displayedFilters=%7B"estado_flow"%3Atrue%7D&filter=%7B"${w_ruta_unidad_negocio}estado_flow"%3A"ANU"%7D&order=ASC&page=1&perPage=25&sort=id`}
                            component={Link}
                        >
                            <ListItemText
                                primary={
                                    <div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="headline" component="h2">
                                                            <LineWeightIcon titleAccess={'ANULADO Total Flow de Pago'}
                                                                            style={{backgroundColor: '#e6e6e6', color: '#ffffff'}} >
                                                            </LineWeightIcon>{'ANULADO'}
                                                            {/*<div>{`Total Flow de Pago`}</div>*/}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </div>
                                }
                                secondary={
                                    <div>
                                    </div>
                                }

                                className={classes.listItemText}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="end"
                                    style={{
                                        color: '#000000',
                                        backgroundColor: '#e6e6e6'
                                    }}
                                >
                                    <div>

                                        {
                                            `${
                                                ver_cantidad === undefined || ver_cantidad === null ? '0' :
                                                    ver_cantidad.toLocaleString(undefined, {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 16,
                                                    })
                                            }`
                                        }

                                    </div>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>

                    </div>
                    {/*            : null*/}
                    {/*    )}*/}
                    {/*/>*/}
                    <Table style={{ background: `url(${cartuchonewlargo}) no-repeat`}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <MailOutlineIcon/>
                                </TableCell>
                                <TableCell>
                                    <div>{`Última Actualización:`}</div>
                                    <div>{`${ver_cantidad}.`}</div>
                                    <div>
                                        <Button label={'Actualizar'}
                                                title={'Actualizar'}
                                                onClick={handleClickActualizarFlowdePago}
                                        >
                                            <RefreshIcon/>
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </CardContent>
            </Card>
        </div>
    );
};

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(CantTableroControlOrdenCompraEstado);
