import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField, useNotify, useRefresh, useMutation, FormTab
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
import LineWeightIcon from "@material-ui/icons/LineWeight";
import SaveIcon from '@material-ui/icons/Save';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AddIcon from '@material-ui/icons/Add';

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const ContratacionSecuenciaButtonCreate = ({record}) => {
    var wr_id = record.id

    const dataProviderTableroControl = useDataProvider();
    const [Estadoid, setEstadoid] = useState(0)
    const [Estadoid_contratacion_tipo, setEstadoid_contratacion_tipo] = useState(0)
    const [Estadocontratacion_tipo, setEstadocontratacion_tipo] = useState('')
    const [Estadosecuencia_item, setEstadosecuencia_item] = useState('')
    const [Estadosecuencia_d, setEstadosecuencia_d] = useState('')
    const [Estadosecuencia_tipo, setEstadosecuencia_tipo] = useState('')
    const [Estadosecuencia_dias, setEstadosecuencia_dias] = useState(null)
    const [EstadoreadOnlysecuencia_dias, setEstadoreadOnlysecuencia_dias] = useState(true)
    const [ComboContratacionSecuenciaTipo, setComboContratacionSecuenciaTipo] = useState([]);

    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        var wr_id = null
        var wr_id_contratacion_tipo = record.id
        var wr_contratacion_tipo = record.contratacion_tipo
        var wr_secuencia_item = 'ASIGNAR'
        var wr_secuencia_d = ''
        var wr_secuencia_tipo = 'NORMAL'
        var wr_secuencia_dias = null

        if(wr_secuencia_tipo==null){
            wr_secuencia_tipo=''
        }
        wr_secuencia_tipo=wr_secuencia_tipo.trim()

        setEstadoid(wr_id)
        setEstadoid_contratacion_tipo(wr_id_contratacion_tipo)
        setEstadocontratacion_tipo(wr_contratacion_tipo)
        setEstadosecuencia_item(wr_secuencia_item)
        setEstadosecuencia_d(wr_secuencia_d)
        setEstadosecuencia_tipo(wr_secuencia_tipo)
        setEstadosecuencia_dias(wr_secuencia_dias)
        if(wr_secuencia_tipo==='') {
            setEstadoreadOnlysecuencia_dias(true)
            setEstadosecuencia_dias(null)
        }
        if(wr_secuencia_tipo==='TITULO') {
            setEstadoreadOnlysecuencia_dias(true)
            setEstadosecuencia_dias(null)
        }
        if(wr_secuencia_tipo==='NORMAL') {
            setEstadoreadOnlysecuencia_dias(false)
        }
        if(wr_secuencia_tipo==='IMPUGNACION') {
            setEstadoreadOnlysecuencia_dias(false)
        }
        if(wr_secuencia_tipo==='TOTDIAS-NORMAL') {
            setEstadoreadOnlysecuencia_dias(true)
            setEstadosecuencia_dias(null)
        }
        if(wr_secuencia_tipo==='TOTDIAS-IMPUGNACION') {
            setEstadoreadOnlysecuencia_dias(true)
            setEstadosecuencia_dias(null)
        }

        dataProviderTableroControl.getList(
            'ContratacionSecuenciaTipo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'S' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboContratacionSecuenciaTipo(data);
            })
            .catch(error => {
                setOpen(false);
            })

        setOpen(true);
    };

    const handlesecuencia_dias = (event) => {
        const Cambiasecuencia_dias1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadosecuencia_dias(wp_value)

        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if(w_value==null){
            w_value=''
        }
        w_value=w_value.trim()
        if(w_value===''){}else{
            w_value = Number(w_value)
        }
        Cambiasecuencia_dias1(w_value)

    };

    const handleClose = () => {
        setOpen(false);
    };


    const BotonCrearDetelle = (record) => {

        var w_mensaje = ' '

        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'create',
                resource: 'ContratacionSecuencia',
                payload: {id: Estadoid, data: {id_contratacion_tipo: Estadoid_contratacion_tipo,
                        contratacion_tipo: Estadocontratacion_tipo,
                        secuencia_item: Estadosecuencia_item,
                        secuencia_d: Estadosecuencia_d,
                        secuencia_tipo: Estadosecuencia_tipo,
                        secuencia_dias: Estadosecuencia_dias
                    }}
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                    // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------

                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <SaveIcon style={{color: '#004187'}}/>
                    Guardar
                </div>
            </Button>
            // <Button label={w_mensaje}
            //         title={w_mensaje}
            //         onClick={generardetelle} disabled={loading}
            //         color="primary"
            // >
            //     <div>
            //         <LineWeightIcon style={{fontSize: 40, backgroundColor: '#ffffff', color: '#004187'}}/>
            //     </div>
            // </Button>
        );

    };



    const handlesecuencia_d = (event) => {
        const Cambiasecuencia_d1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadosecuencia_d(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiasecuencia_d1(w_value)

    };



    const handlesecuencia_tipo = (event) => {
        const Cambiasecuencia_tipo1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadosecuencia_tipo(wp_value)
            var wr_secuencia_tipo = wp_value
            if(wr_secuencia_tipo==null){
                wr_secuencia_tipo=''
            }
            wr_secuencia_tipo=wr_secuencia_tipo.trim()
            if(wr_secuencia_tipo==='') {
                setEstadoreadOnlysecuencia_dias(true)
            }
            if(wr_secuencia_tipo==='TITULO') {
                setEstadoreadOnlysecuencia_dias(true)
            }
            if(wr_secuencia_tipo==='NORMAL') {
                setEstadoreadOnlysecuencia_dias(false)
            }
            if(wr_secuencia_tipo==='IMPUGNACION') {
                setEstadoreadOnlysecuencia_dias(false)
            }
            if(wr_secuencia_tipo==='TOTDIAS-NORMAL') {
                setEstadoreadOnlysecuencia_dias(true)
            }
            if(wr_secuencia_tipo==='TOTDIAS-IMPUGNACION') {
                setEstadoreadOnlysecuencia_dias(true)
            }
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiasecuencia_tipo1(w_value)

    };


    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <AddIcon style={{color: '#004187'}}/>
                    Crear
                </div>
            </Button>
            <Dialog open={open} maxWidth={'xl'} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Detalle de Secuencia de la Contratación</DialogTitle>
                <DialogContent>
                    <form>
                        <label>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField label="Secuencia Item" value={Estadosecuencia_item}
                                                       inputProps={{readOnly: true}}
                                                       style={{textAlign: 'right', margin: '1em' }}
                                                       options={{ minimumFractionDigits: 0 }} />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">Secuencia Tipo</Typography>
                                            <TextField label=""
                                                       value={Estadosecuencia_tipo}
                                                       onChange={handlesecuencia_tipo}
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                id="secuencia_tipo_ing"
                                                select
                                                label=""
                                                size="small"
                                                // value={currency}
                                                value={Estadosecuencia_tipo}
                                                onChange={handlesecuencia_tipo}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                // helperText="Por favor seleccione su Mes"
                                            >
                                                {ComboContratacionSecuenciaTipo.map((option) => (
                                                    <option key={option.secuencia_tipo} value={option.secuencia_tipo}>
                                                        {option.secuencia_tipo_d}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                customInput={TextField} label="Secuencia Dias" value={Estadosecuencia_dias}
                                                onChange={handlesecuencia_dias}
                                                inputProps={{readOnly: EstadoreadOnlysecuencia_dias}}
                                                style={{textAlign: 'right', margin: '1em' }}
                                                options={{ minimumFractionDigits: 0 }} />
                                            <br/>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                label="Secuencia Descripción"
                                                value={Estadosecuencia_d}
                                                multiline
                                                fullWidth
                                                onChange={handlesecuencia_d}
                                                SelectProps={{native: true,}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </label>
                    </form>
                </DialogContent>
                <DialogActions>
                    <BotonCrearDetelle/>
                    <Button variant="outlined" color="primary" onClick={handleClose}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <CancelPresentationIcon style={{color: '#004187'}}/>
                            Cancelar
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}


export default ContratacionSecuenciaButtonCreate;