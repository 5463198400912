import React from 'react';
import {
    Edit,
    TextField,
    Toolbar,
    SaveButton,
    SimpleForm,
    TabbedForm,
    TextInput,
    CardActions,
    BooleanInput,
    email, FormTab, PasswordInput, NumberInput, SelectInput, Link
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DialpadIcon from "@material-ui/icons/Dialpad";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import EmailIcon from "@material-ui/icons/Email";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import UserUsuComDatagrid from "./UserUsuComDatagrid";
import UserUsuComAreaDatagrid from "./UserUsuComAreaDatagrid"
import UserUsuComNotificacionDatagrid from "./UserUsuComNotificacionDatagrid"

const styles = {
    inlineBlock: {display: 'inline-flex', marginRight: '3rem'},
    inlineBlock2: {display: 'inline-flex', marginRight: '3rem'},
    username: {display: 'inline-block', width: 400, marginLeft: 8},
    password: {display: 'inline-block', width: 400, marginLeft: 8},
    version: {display: 'inline-block', width: 100, marginLeft: 8},
    account_expired: {display: 'inline-block', width: 200, marginLeft: 8},
    account_locked: {display: 'inline-block', width: 200, marginLeft: 8},
    enabled: {display: 'inline-block', width: 200, marginLeft: 8},
    password_expired: {display: 'inline-block', width: 200, marginLeft: 8},
    es_administrador: {display: 'inline-block', width: 100, marginLeft: 8},
    whatsapp_nro: {display: 'inline-block', width: 800, marginLeft: 8},
    email: {display: 'inline-block', width: 800, marginLeft: 8},
    enviar_whatsapp: {display: 'inline-block', width: 200, marginLeft: 8},
    enviar_correo: {display: 'inline-block', width: 200, marginLeft: 208},
    enviar_notificacion: {display: 'inline-block', width: 200, marginLeft: 208},
};

const UserUsuComTitle = ({ record }) => {
    // return <span>{record ? `Detalle de Orden de Pago ` : ''}</span>;
    return <span>{record ? `` : ''}</span>;
};

const PostActions = ({ resource, filters, displayedFilters, filterValues, basePath, showFilter }) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/*<Link to={`/UserUsuCom/`}>*/}
        {/*    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
        {/*        <Button*/}
        {/*            label="Volver a Usuarios del Sistema"*/}
        {/*        >*/}
        {/*            <LineWeightIcon/>*/}
        {/*        </Button>*/}
        {/*    </div>*/}
        {/*</Link>*/}
    </CardActions>
);

const transform = data => {
    return {
        ...data
    }
};

const PostEditToolbar = props => (
    // <Toolbar {...props} >
    //     <SaveButton />
    // </Toolbar>
    <Toolbar {...props} >
        <SaveButton
            label="Guardar"
            transform={transform}
            submitOnEnter={false}
            disabled={props.pristine}
        />
        <Grid container spacing={3}>
            <Grid item xs>

            </Grid>
            <Grid item xs>
                <Link to={`/UserUsuCom/`}>
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        <Button
                            label="Salir"
                        >
                            <ExitToAppIcon/> Salir
                        </Button>
                    </div>
                </Link>
            </Grid>
        </Grid>
    </Toolbar>
);

const role_uni_neg_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if(w_permissions_control==null){}else{
        var w_role_uni_neg = w_permissions_control.indexOf('ROLE_ADMUNO_UNIDAD_NEGOCIO_TODAS')
        if(w_role_uni_neg===-1){}else{
            w_role_control = 'ROLE_ADMUNO_UNIDAD_NEGOCIO_TODAS'
        }
    }
    return w_role_control
}

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/UserUsuCom`;

const validateEdit = (values, allValues) => {
    const errors = {};

    console.log(values)

    var w_banco_acreditado = values.banco_acreditado
    if(w_banco_acreditado==null){
        w_banco_acreditado=''
    }
    w_banco_acreditado=w_banco_acreditado.trim()

    var w_banco_rechazado = values.banco_rechazado
    if(w_banco_rechazado==null){
        w_banco_rechazado=''
    }
    w_banco_rechazado=w_banco_rechazado.trim()

    if (w_banco_acreditado==='') {
        errors.banco_acreditado = ['El campo Banco Acreditado es requerido'];
    }
    if (w_banco_rechazado==='') {
        errors.banco_rechazado = ['El campo Banco Rechazado es requerido'];
    }



    var w_banco_observado = values.banco_observado
    if(w_banco_observado==null){
        w_banco_observado=''
    }
    w_banco_observado=w_banco_observado.trim()


    if (w_banco_observado==='') {
        errors.banco_observado = ['El campo Banco Observado es requerido'];
    }

    var w_banco_fecha = values.banco_fecha
    if(w_banco_fecha==null){
        w_banco_fecha=''
    }
    w_banco_fecha=w_banco_fecha.trim()

    if (w_banco_fecha==='') {
        errors.banco_fecha = ['El campo Banco Fecha es requerido'];
    }



    if (w_banco_acreditado==='S') {
        if(w_banco_rechazado==='S') {
            errors.banco_acreditado = ["Tiene indicado que Banco Rechazado," +
            " para indicar que Banco Acreditado debe primero asignar Banco Rechazado = 'N'"];
        }
        // if(w_banco_observado==='S') {
        //     errors.banco_acreditado = ["Tiene indicado que Banco Observado," +
        //     " para indicar que Banco Acreditado debe primero asignar Banco Observado = 'N'"];
        // }
    }

    if (w_banco_rechazado==='S'){
        if (w_banco_acreditado==='S') {
            errors.banco_rechazado = ["Tiene indicado que Banco Acreditado," +
            " para indicar que Banco Rechazado debe primero asignar Banco Acreditado = 'N'"];
        }
        // if(w_banco_observado==='S') {
        //     errors.banco_rechazado = ["Tiene indicado que Banco Observado," +
        //     " para indicar que Banco Rechazado debe primero asignar Banco Observado = 'N'"];
        // }
    }

    if (w_banco_observado==='S'){
        var w_banco_observacion = values.banco_observacion
        if(w_banco_observacion==null){
            w_banco_observacion=''
        }
        w_banco_observacion=w_banco_observacion.trim()

        if (w_banco_observacion==='') {
            errors.banco_observacion = ['El campo Banco Observación es requerido'];
        }
        // if(w_banco_rechazado==='S') {
        //     errors.banco_observado = ["Tiene indicado que Banco Rechazado," +
        //     " para indicar que Banco Observado debe primero asignar Banco Rechazado = 'N'"];
        // }
        // if (w_banco_acreditado==='S') {
        //     errors.banco_observado = ["Tiene indicado que Banco Acreditado," +
        //     " para indicar que Banco Observado debe primero asignar Banco Acreditado = 'N'"];
        // }
    }


    return errors
};

export const UserUsuComEdit = withStyles(styles)(({ classes, permissions, ...props}) => (
    <Edit title={<UserUsuComTitle />} actions={<PostActions/>} {...props} >
        <TabbedForm toolbar={<PostEditToolbar/>}
                    // validate={validateEdit}
                    redirect={redirect}
        >
            {/*<div formClassName={classes.inlineBlock}>*/}
            {/*    <td style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center',*/}
            {/*        color: '#0064c8'}}><h4>Usuario del Sistema</h4></td>*/}
            {/*    <Divider />*/}
            {/*</div>*/}
            <FormTab label="DATOS USUARIO DEL SISTEMA">
                {/*<div></div>*/}
                <TextInput disabled={true}  label="Usuario" source="username" formClassName={classes.username}/>
                {/*<PasswordInput label="Contraseña" source="password"  formClassName={classes.password}/>*/}
                <NumberInput label="Version"  source="version"  formClassName={classes.version} />
                <br/>
                <div></div>
                <BooleanInput source="account_expired"
                              label="Cuenta Vencida"
                              formClassName={classes.account_expired}
                              options={{
                                  checkedIcon: <AccountCircleIcon  style={{ color: '#ff0000' }}/>,
                              }}
                />
                <BooleanInput  label="Cuenta Bloqueada"  source="account_locked"
                               formClassName={classes.account_locked}
                               options={{
                                   checkedIcon: <AccountCircleIcon  style={{ color: '#ff0000' }}/>,
                               }}
                />
                <BooleanInput   label="Usuario Habilitado"  source="enabled"
                                formClassName={classes.enabled}
                                options={{
                                    checkedIcon: <AccountCircleIcon style={{ color: '#32c832' }}/>,
                                }}
                />
                <BooleanInput label="Password Vencida"  source="password_expired"
                              formClassName={classes.password_expired}
                              options={{
                                  checkedIcon: <AccountCircleIcon style={{ color: '#ff0000' }}/>,
                              }}
                />


                <SelectInput  label={
                                  <div title={'Es Administrador'}
                                       style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                      <SupervisorAccountIcon/>
                                      {'Es Administrador'}
                                  </div>
                              }
                              source="es_administrador" formClassName={classes.es_administrador}
                              choices={[
                                  {id: 'S', name: 'SI'},
                                  {id: 'N', name: 'NO'},
                              ]}
                />

                <br/>

                <div></div>

                <TextInput source="whatsapp_nro"
                           label={<div    title={'Numero de Whatsapp'}
                                          style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                               <DialpadIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                               <WhatsAppIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                               {' Numero de Whatsapp'}
                           </div>}  variant="outlined"
                           InputLabelProps={{shrink: true}}
                           formClassName={classes.whatsapp_nro} fullWidth={true}/>



                <br/>

                <TextInput source="email"
                           label={<div title={'E-Mail (Correo)'}
                                       style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                               <ContactMailIcon  />
                               {' E-Mail (Correo)'}
                           </div>
                           }
                           variant="outlined"
                           InputLabelProps={{shrink: true}}
                           formClassName={classes.email}
                           validate={validateEmail}
                           fullWidth={true}/>

                <br/>

                <SelectInput label={
                                 <div title={'Enviar Whatsapp'}
                                      style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                     <AddToHomeScreenIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                     <WhatsAppIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                     {' Enviar Whatsapp'}
                                 </div>
                             }
                             source="enviar_whatsapp" formClassName={classes.enviar_whatsapp}
                             choices={[
                                 {id: 'S', name: 'SI'},
                                 {id: 'N', name: 'NO'},
                             ]}
                />


                <SelectInput label={
                                 <div title={'Enviar Correo'}
                                      style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                     <AddToHomeScreenIcon/>
                                     <EmailIcon/>
                                     {' Enviar Correo'}
                                 </div>
                             }
                             source="enviar_correo" formClassName={classes.enviar_correo}
                             choices={[
                                 {id: 'S', name: 'SI'},
                                 {id: 'N', name: 'NO'},
                             ]}
                />





                <SelectInput label={
                                 <div title={'Enviar Notificacion'}
                                      style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                     <AddToHomeScreenIcon style={{color: '#ff0000'}}/>
                                     <NotificationsIcon style={{color: '#ff0000'}}/>
                                     {' Enviar Notificacion'}
                                 </div>
                             }
                             source="enviar_notificacion" formClassName={classes.enviar_notificacion}
                             choices={[
                                 {id: 'S', name: 'SI'},
                                 {id: 'N', name: 'NO'},
                             ]}
                />
                <br/>
            </FormTab>

            <FormTab label="ROLE USUARIO DEL SISTEMA">
                <TextInput disabled={true}  label="Usuario" source="username" formClassName={classes.username}/>
                <UserUsuComDatagrid/>
            </FormTab>

            <FormTab label="AREA USUARIO DEL SISTEMA">
                <TextInput disabled={true}  label="Usuario" source="username" formClassName={classes.username}/>
                <UserUsuComAreaDatagrid/>
            </FormTab>

            <FormTab label="NOTIFICACION USUARIO DEL SISTEMA">
                <TextInput disabled={true}  label="Usuario" source="username" formClassName={classes.username}/>
                <UserUsuComNotificacionDatagrid/>
            </FormTab>

        </TabbedForm>
    </Edit>
));

const validateEmail = email();

export default withStyles(styles) (UserUsuComEdit)


