import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    SelectInput,
    TextInput,
    Filter,
    Responsive,
    CardActions, DateField, NumberField, Link, Button
} from 'react-admin';
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import PersonIcon from '@material-ui/icons/Person';
import ApartmentIcon from '@material-ui/icons/Apartment';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import VisibilityIcon from "@material-ui/icons/Visibility";
import NotificationsIcon from "@material-ui/icons/Notifications";
import TodayIcon from "@material-ui/icons/Today";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import EmailIcon from '@material-ui/icons/Email';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import DialpadIcon from '@material-ui/icons/Dialpad';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BlockIcon from '@material-ui/icons/Block';
import GavelIcon from '@material-ui/icons/Gavel';
import AddIcon from '@material-ui/icons/Add';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import UsuComAreaButtonDelete from "./UsuComAreaButtonDelete"
import Vertitle from "./Vertitle";

const FiltroUserUsuCom = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="q" alwaysOn />
        {/*<TextInput label="Proveedor" source="pro"/>*/}
        {/*<TextInput label="Razón Social"  source="raz"/>*/}
        {/*<SelectInput*/}
        {/*    source="hay_correo_pro"*/}
        {/*    label="Correo Inf. Pago"*/}
        {/*    choices={[*/}
        {/*        {id: 'SI', name: 'C/Correo Inf. Pago'},*/}
        {/*        {id: 'NI', name: 'S/Correo Inf. Pago'},*/}
        {/*        {id: 'NT', name: 'Sin contacto'}*/}
        {/*    ]}*/}
        {/*/>*/}

        {/*<SelectInput*/}
        {/*    source="usa_role_user"*/}
        {/*    // label="ROLE_MLADOS"*/}
        {/*    label={ <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
        {/*        <ViewModuleIcon style={{ color: '#35c81d' }}/>{'Funcionamiento de Modulo'}*/}
        {/*    </div>}*/}
        {/*    choices={[*/}
        {/*        {id: 'S', name: 'SI'},*/}
        {/*        {id: 'N', name: 'NO'},*/}
        {/*    ]} alwaysOn*/}
        {/*/>*/}
        {/*<SelectInput*/}
        {/*    source="usa_role_secu"*/}
        {/*    // label="ROLE_MLADOS_SECU"*/}
        {/*    label={ <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
        {/*        <AssignmentIndIcon style={{color: '#dcaadc'}}/>{'Usuarios del Sistema'}*/}
        {/*    </div>}*/}
        {/*    choices={[*/}
        {/*        {id: 'S', name: 'SI'},*/}
        {/*        {id: 'N', name: 'NO'},*/}
        {/*    ]} alwaysOn*/}
        {/*/>*/}
    </Filter>
);

const PostActions = ({ resource, filters, displayedFilters, filterValues, basePath, showFilter }) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <Link to={`/UsuComArea/create`}>
            <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                <Button
                    label="Crear Usuario de Area"
                >
                    <AddIcon/>
                </Button>
            </div>
        </Link>
        {/*<div>*/}
        {/*    <LineWeightIcon titleAccess={'Sin contacto'} style={{color: '#c0c0c0'}} ></LineWeightIcon>{'Sin contacto'}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*    <LineWeightIcon titleAccess={'C/Correo Inf. Pago'} style={{color: '#8080ff'}} ></LineWeightIcon>{'C/Correo Inf. Pago'}*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*    <LineWeightIcon titleAccess={'S/Correo Inf. Pago'} style={{color: '#ffaaaa'}} ></LineWeightIcon>{'S/Correo Inf. Pago'}*/}
        {/*</div>*/}
    </CardActions>
);

// expand={<UserUsuComInfo/>}
//xsmall={<MobileUserUsuComGrid />}

// Grilla general
export const UsuComAreaList = (props) =>  {
    return (
        <div>
            <div>
                <Vertitle/>
            </div>
            <div style={{ margin: '1em' }}>
                <List {...props} title="Listado de Usuario de Area" actions={<PostActions/>} perPage={25}
                      bulkActionButtons={false} exporter={false}
                      filters={<FiltroUserUsuCom />}>
                    <Responsive
                        xsmall={
                            <Datagrid rowClick="expand" rowStyle={RowStyle}
                                      // expand={<UserUsuComEdit/>}
                            >
                                <TextField label="Usuario"  source="username"/>
                                <TextField label="Area"  source="notificacion_codigo_d"/>
                            </Datagrid>
                        }
                        medium={
                            <Datagrid rowClick="expand" rowStyle={RowStyle}
                                      // expand={<UserUsuComEdit/>}
                            >
                                <TextField label={
                                        <div title={'Nombre de Usuario'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <PersonIcon style={{color: '#0064c8'}} />
                                            {'Usuario'}
                                        </div>
                                    }
                                           source="username"
                                />
                                <TextField
                                    label={
                                        <div title={'Unidad Negocio'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <AddAlertIcon style={{color: '#ff0000'}}/>
                                            {'Código'}
                                        </div>
                                    }
                                           source="unidad_negocio"
                                />
                                <TextField
                                    label={
                                        <div title={'Area Descripción'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            <AddAlertIcon style={{color: '#ff0000'}}/>
                                            {'Descripción'}
                                        </div>
                                    }
                                    source="area_d"
                                />
                                <UsuComAreaButtonDelete/>


                                {/*<LinkConsultarButton/>*/}
                            </Datagrid>
                        }
                    />
                </List>
            </div>
        </div>
    )
};

const RowStyle = (record, index) => ({
    backgroundColor: record.notificacion_codigo  === undefined || record.estado_color  === undefined ? '' :
        record.estado_color.trim(),
});

export default UsuComAreaList;



