import {DateInput, FormDataConsumer, NumberInput, SelectInput, TextInput} from 'react-admin';
import React, {useCallback} from "react";
import {useForm} from "react-final-form";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AutoridadesBusquedaBtn from "../Autoridades/AutoridadesBusquedaBtn";
import EstadoOrdenCompraBusquedaBtn from "../EstadoOrdenCompra/EstadoOrdenCompraBusquedaBtn";

const useStyles = makeStyles((theme) => ({
    pre_adjudica_codigo: {display: 'inline-block', width: 80},
    pre_adjudica_nombre_apellido: {display: 'inline-block', width: 250, marginLeft: 32},
    pre_adjudica_sector: {display: 'inline-block', width: 250, marginLeft: 32},
    pre_adjudica_firma_tipo: {display: 'inline-block', width: 250, marginLeft: 32},
    conforma_adjudica_codigo: {
        display: 'inline-block', width: 80
    },
    conforma_adjudica_nombre_apellido: {display: 'inline-block', width: 250, marginLeft: 32},
    conforma_adjudica_sector: {display: 'inline-block', width: 250, marginLeft: 32},
    conforma_adjudica_firma_tipo: {display: 'inline-block', width: 250, marginLeft: 32},
    orden_compra_estado: {display: 'inline-block', width: 180},
    orden_compra_estado_detalle: {display: 'inline-block', width: 800, marginLeft: 32},

    fecha_vencimiento_orden_compra: {display: 'inline-block', width: 180},
    fecha_vencimiento_enviar_correo_electronico_aviso: {display: 'inline-block', width: 80, marginLeft: 32},
    fecha_vencimiento_cantidad_dias_anterior_enviar_aviso: {display: 'inline-block', width: 200, marginLeft: 32},
    fecha_vencimiento_correo_electronico_aviso: {display: 'inline-block', width: 605},
}));

const OrdenDeCompraEstado = ({setPersonaTipo, isReadonly}) => {
        const {form, change} = useForm();
        const classes = useStyles();

        const updateAutoridadesPre = useCallback(async (who) => {
            console.log(who)
            change("pre_adjudica_codigo", who.autoridad_codigo.trim());
            change("pre_adjudica_nombre_apellido", who.nombre_apellido.trim());
            change("pre_adjudica_sector", who.adjudica_sector.trim());
            change("pre_adjudica_firma_tipo", who.firma_tipo.trim());
        }, [form]);

        const updateAutoridadesConforma = useCallback(async (who) => {
            console.log(who)
            change("conforma_adjudica_codigo", who.autoridad_codigo);
            change("conforma_adjudica_nombre_apellido", who.nombre_apellido.trim());
            change("conforma_adjudica_sector", who.adjudica_sector.trim());
            change("conforma_adjudica_firma_tipo", who.firma_tipo.trim());
        }, [form]);

        const updateEstadoOrdenCompra = useCallback(async (who) => {
            console.log(who)
            change("orden_compra_estado", who.estado);
            change("EstadoOrdenCompra.estado_d", who.estado_d.trim());
        }, [form]);


        return (
            <>
                <FormDataConsumer>
                    {({formData, ...rest}) => (
                        <>
                            <Typography variant="h6">Responsable Pre-Adjudica</Typography>

                            <div style={{display: 'inline-flex', width: '100%'}}>
                                <TextInput source="pre_adjudica_codigo" label={"Código"} variant="filled"
                                           InputLabelProps={{shrink: true}} inputProps={{readOnly: true,}}
                                           className={classes.pre_adjudica_codigo} fullWidth={true}/>

                                <AutoridadesBusquedaBtn preAdjudica={'S'} className={classes.btnBusqueda}
                                                        callbackAutoridades={updateAutoridadesPre}/>

                                <TextInput source="pre_adjudica_nombre_apellido" label={"Nombre y Apellido"}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           InputLabelProps={{shrink: true}}
                                           className={classes.pre_adjudica_nombre_apellido} fullWidth={true}/>

                                <TextInput source="pre_adjudica_sector" label={"Sector"} variant="filled"
                                           InputLabelProps={{shrink: true}} inputProps={{readOnly: true,}}
                                           className={classes.pre_adjudica_sector} fullWidth={true}/>

                                <TextInput source="pre_adjudica_firma_tipo" label={"Tipo de Firma"} variant="filled"
                                           InputLabelProps={{shrink: true}} inputProps={{readOnly: true,}}
                                           className={classes.pre_adjudica_firma_tipo} fullWidth={true}/>


                            </div>
                            <Typography variant="h6">Responsable Conforma</Typography>
                            <div style={{display: 'inline-flex', width: '100%'}}>
                                <TextInput source="conforma_adjudica_codigo" label={"Código"}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           InputLabelProps={{shrink: true}}
                                           className={classes.conforma_adjudica_codigo} fullWidth={true}/>

                                <AutoridadesBusquedaBtn conformaAdjudica={'S'} className={classes.btnBusqueda}
                                                        callbackAutoridades={updateAutoridadesConforma}/>

                                <TextInput source="conforma_adjudica_nombre_apellido"
                                           label={"Nombre y Apellido"}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           InputLabelProps={{shrink: true}}
                                           className={classes.conforma_adjudica_nombre_apellido} fullWidth={true}/>

                                <TextInput source="conforma_adjudica_sector" label={"Sector"}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           InputLabelProps={{shrink: true}}
                                           className={classes.conforma_adjudica_sector} fullWidth={true}/>

                                <TextInput source="conforma_adjudica_firma_tipo" label={"Tipo de Firma"}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           InputLabelProps={{shrink: true}}
                                           className={classes.conforma_adjudica_firma_tipo} fullWidth={true}/>

                            </div>
                            <br/>
                            <div style={{display: 'inline-flex', width: '100%'}}>
                                <TextInput source="orden_compra_estado" label={"Estado Actual"} variant="filled"
                                           InputLabelProps={{shrink: true}} inputProps={{readOnly: true,}}
                                           className={classes.orden_compra_estado} fullWidth={true}/>
{/*
                                           /// Unicamente se cambia desde el List
                                <EstadoOrdenCompraBusquedaBtn className={classes.btnBusqueda}
                                                              callbackEstadoOrdenCompra={updateEstadoOrdenCompra}/>*/}

                                <TextInput source="EstadoOrdenCompra.estado_d" label={"Descripción"} variant="filled"
                                           InputLabelProps={{shrink: true}} inputProps={{readOnly: true,}}
                                           className={classes.orden_compra_estado_detalle} fullWidth={true}
                                           multiline/>
                            </div>
                            <br/>

                            <Typography variant="h6">Vencimiento Orden de Compra</Typography>

                            <DateInput source="fecha_vencimiento_orden_compra" label={"Fecha Vencimiento"}
                                       variant="outlined"
                                       InputLabelProps={{shrink: true}}
                                       className={classes.fecha_vencimiento_orden_compra} fullWidth={true}
                            />

                            <SelectInput source="fecha_vencimiento_enviar_correo_electronico_aviso"
                                         className={classes.fecha_vencimiento_enviar_correo_electronico_aviso}
                                         fullWidth={true}
                                         variant="outlined" InputLabelProps={{shrink: true}} label={"Enviar Aviso"}
                                         choices={[
                                             {id: 'S', name: 'Si'},
                                             {id: 'N', name: 'No'},
                                         ]}/>

                            <NumberInput source="fecha_vencimiento_cantidad_dias_anterior_enviar_aviso"
                                         className={classes.fecha_vencimiento_cantidad_dias_anterior_enviar_aviso}
                                         fullWidth={true}
                                         variant="outlined" InputLabelProps={{shrink: true}}
                                         label={"Días de Anticipación Aviso"}
                            />
                            <br/>
                            <TextInput source="fecha_vencimiento_correo_electronico_aviso"
                                       className={classes.fecha_vencimiento_correo_electronico_aviso}
                                       fullWidth={true}
                                       variant="outlined" InputLabelProps={{shrink: true}} label={"E-mail de aviso"}
                            />

                        </>
                    )}

                </FormDataConsumer>
            </>
        );
    }
;

export default OrdenDeCompraEstado;
