import React, {useEffect, useState} from 'react';
import {useDataProvider, Loading, useNotify, useMutation, Button} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List"
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import TabletIcon from '@material-ui/icons/Tablet';
import CompareIcon from '@material-ui/icons/Compare';
import VignetteIcon from '@material-ui/icons/Vignette';
import PersonIcon from '@material-ui/icons/Person';
import ShopIcon from '@material-ui/icons/Shop';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PaymentIcon from '@material-ui/icons/Payment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WarningIcon from '@material-ui/icons/Warning';
import BackupIcon from "@material-ui/icons/Backup";
import Avatar from '@material-ui/core/Avatar';
import Imagepvm from '../layout/tvpublica_logo_2021.bmp'; // Import using relative path
import Imagecpm from '../layout/headerRTA.jpg';
import HistoryIcon from '@material-ui/icons/History';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import UndoIcon from '@material-ui/icons/Undo';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import IconButton from "@material-ui/core/IconButton";
import SupervisedUserCircleTwoToneIcon from '@material-ui/icons/SupervisedUserCircleTwoTone';
import CommentIcon from '@material-ui/icons/Comment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import cartuchonewlargo from "../TableroControl/cartuchonewlargo.png";
import LineWeightIcon from "@material-ui/icons/LineWeight";

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
    table: {
        minWidth: 650,
    },
});

//--------------------  recibe como parametro la fecha de proceso ------------------

const UserUsuComAreaDatagrid = props => {

//        console.log('w_valor parametro ',props)

    var w_valor = props

    console.log('UserUsuComAreaDatagrid----w_valor')
    console.log(w_valor.record.id)

    var visitorsarea = []

    const classes = useStyles()

    const dataProvider = useDataProvider();
    const [EstadoDetalle, setEstadoDetalle] = useState(1);
    const [pvmadjuntos, setPvmAdjuntos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const CambiaEstadoAdjuntos = () => {
        console.log('UserUsuComAreaDatagrid----CambiaEstadoAdjuntos')
        setEstadoDetalle(EstadoDetalle + 1);
    };

//--- el efect se ejecuta por primera vez y cuando cambia el estado del boton detall o cambia la fecha de proceso -------

    useEffect(() => {

        dataProvider.getList(
            'UsuComArea',
            {
                filter: {id: w_valor.record.id},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setPvmAdjuntos(data);
                setLoading(false);
            })
            .catch(error => {
                CambiaEstadoAdjuntos()
                // setError(error);
                // setLoading(false);

            })
    }, [EstadoDetalle, w_valor.record.id]);

    //----------------- chequea datos estado del componente -----------------------------

    if (loading) return <Loading loadingPrimary="  " loadingSecondary="Actualizando" />      // no cargo todavia

    if (error) {
        console.log('error')
        console.log(error)
        // return <Error/>;                           // error
    };

    //----------- si ya tiene los datos cargados en forma asincrónica actualiza el DOM --------------

    if (pvmadjuntos.length > 0) {

        visitorsarea = pvmadjuntos

        // console.log('visitorsarea')
        // console.log(visitorsarea)
        // alert('Hola')

//---------------------- botones detalle / sin detalle --------------------------

        const BotonEliminarDetelle = (recordarea) => {

            var w_id = recordarea.id

            console.log('w_id---BotonEliminar')
            console.log(w_id)

            var w_role = recordarea.role


            if(w_role==null){
                w_role=''
            }
            w_role=w_role.trim()

            console.log('w_role')
            console.log(w_role)

            var w_activo = recordarea.activo
            if(w_activo==null){
                w_activo=''
            }
            w_activo=w_activo.trim()

            console.log('w_activo')
            console.log(w_activo)

            console.log('recordarea---BotonEliminar')
            console.log(recordarea)

            var w_id_unidad_negocio_ing = recordarea.id_unidad_negocio_ing
            var w_id_unidad_area_ing = recordarea.id_unidad_area_ing
            var w_id_usu_com_ing = recordarea.id_usu_com_ing

            const CambiaEstado = () => {
                setEstadoDetalle(EstadoDetalle + 1);
            };

            const notify = useNotify();

            const [eliminardetelle, {loading}] = useMutation({
                    type: 'update',
                    resource: 'UsuComArea',
                    payload: {id: w_id, data: {role: w_role, status: 'B', id_unidad_negocio_ing: w_id_unidad_negocio_ing,
                            id_unidad_area_ing: w_id_unidad_area_ing,
                            id_usu_com_ing: w_id_usu_com_ing}}
                },
                {
                    onSuccess: ({data}) => {
                        // notify('('+w_role+') Seleccionada aprobado para Eliminar', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------
                        CambiaEstado()
                    },
                    onFailure: error => {
                        notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                    },
                }
            );
            return (
                <Button title={"Desmarcar"}
                    // label="Eliminar"
                        onClick={eliminardetelle} disabled={loading}
                        style={{color: w_activo === undefined ? '' : w_activo.trim() === 'S' ? '#32c832':
                                w_activo.trim() === 'V' ? '#e6e6e6' : '#a1a1a1'}}
                >
                    <ToggleOnIcon   style={{fontSize: 30}}/>
                </Button>
            );
        };


        const BotonGenerarDetelle = (recordarea) => {

            var w_id = recordarea.id

            console.log('w_id---BotonEliminar')
            console.log(w_id)

            var w_role = recordarea.role

            if(w_role==null){
                w_role=''
            }
            w_role=w_role.trim()

            console.log('w_role')
            console.log(w_role)

            var w_activo = recordarea.activo
            if(w_activo==null){
                w_activo=''
            }
            w_activo=w_activo.trim()

            console.log('w_activo')
            console.log(w_activo)

            var w_id_unidad_negocio_ing = recordarea.id_unidad_negocio_ing
            var w_id_unidad_area_ing = recordarea.id_unidad_area_ing
            var w_id_usu_com_ing = recordarea.id_usu_com_ing

            console.log('recordarea---BotonEliminar')
            console.log(recordarea)


            const CambiaEstado = () => {
                setEstadoDetalle(EstadoDetalle + 1);
            };

            const notify = useNotify();

            const [generardetelle, {loading}] = useMutation({
                    type: 'update',
                    resource: 'UsuComArea',
                    payload: {id: w_id, data: {role: w_role, status: 'A', id_unidad_negocio_ing: w_id_unidad_negocio_ing,
                            id_unidad_area_ing: w_id_unidad_area_ing,
                            id_usu_com_ing: w_id_usu_com_ing}}
                },
                {
                    onSuccess: ({data}) => {
                        // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------
                        CambiaEstado()
                    },
                    onFailure: error => {
                        notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                    },
                }
            );
            return (
                <Button title={"Marcar"}
                    // label="Eliminar"
                        onClick={generardetelle} disabled={loading}
                        style={{color: w_activo === undefined ? '' : w_activo.trim() === 'S' ? '#32c832' :
                                w_activo.trim() === 'V' ? '#ffbe00': '#4766ff'}}
                >
                    <ToggleOffIcon style={{fontSize: 30}}/>
                </Button>
            );
        };

        return (

            <div>
                <div>
                    <List>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Unidad Negocio'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Area'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Area Descripción'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                            {'Color'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                            {'Estado'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {visitorsarea.map(recordarea => {
                                return (
                                    <TableRow
                                        // style={{backgroundColor: recordarea.color === undefined
                                        //     ? '' : recordarea.color}}
                                    >
                                        <TableCell className={classes.textleft}>
                                            <Typography style={{color: '#000000'}} >
                                                {recordarea.unidad_negocio}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography style={{color: '#000000'}}>
                                                {recordarea.area}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography style={{color: '#000000'}}>
                                                {recordarea.area_d}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textcenter}>
                                            <Typography style={{color: '#000000'}}>
                                                <LineWeightIcon style={{fontSize: 40,
                                                    color: recordarea.color === undefined
                                                        ? '' : recordarea.color}}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textcenter}>
                                            <Typography style={{backgroundColor: '#ffffff'}} >
                                                {recordarea.activo.trim() === 'N' ?
                                                    <BotonGenerarDetelle  id = {recordarea.role_id}
                                                                          role = {recordarea.role}
                                                                          activo = {recordarea.activo}
                                                                          id_unidad_negocio_ing = {recordarea.id_unidad_negocio_ing}
                                                                          id_unidad_area_ing = {recordarea.id_unidad_area_ing}
                                                                          id_usu_com_ing = {recordarea.id_usu_com_ing}
                                                    />
                                                    :
                                                    <BotonEliminarDetelle id = {recordarea.role_id}
                                                                          role = {recordarea.role}
                                                                          activo = {recordarea.activo}
                                                                          id_unidad_negocio_ing = {recordarea.id_unidad_negocio_ing}
                                                                          id_unidad_area_ing = {recordarea.id_unidad_area_ing}
                                                                          id_usu_com_ing = {recordarea.id_usu_com_ing}
                                                    />}
                                            </Typography>
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                        </Table>
                    </List>
                </div>
            </div>
        )
    }else{
        return (
            <div>
                <Typography>
                    {'Sin Datos'}
                </Typography>
            </div>
        );
    }
}


export default UserUsuComAreaDatagrid;