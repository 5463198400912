import React from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import {
    FormTab,
    Edit,
    TextInput,
    TabbedForm, maxLength, required,
    TopToolbar,
    Toolbar, SaveButton, DeleteButton,
    ShowButton
} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    tipo: {display: 'inline-block', width: 400, marginLeft: 8},
    pais_codigo_afip: {display: 'inline-block', width: 200, marginLeft: 8},
    pais_codigo_gestion: {display: 'inline-block', width: 200, marginLeft: 8},
}));

const IbruTipoEdit = props => {
    const classes = useStyles();

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );

    const redirect = (basePath, id, data) => `/Pais`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            <DeleteButton undoable={false} redirect={redirect}/>
        </Toolbar>
    );
    const transform = data => {
        return {
            ...data
        }
    };

    const PostTitle = ({record}) => {
        return <span>Tipo IBRU: {record ? `${record.ibru_tipo_d}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm>
                <FormTab label="Tipo IBRU">
                    <TextInput label="Tipo IBRU" source="ibru_tipo"
                               validate={[maxLength(10), required()]}/>
                    <TextInput label="Descripción" source="ibru_tipo_d" validate={[maxLength(60)]}/>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};
export default IbruTipoEdit;


