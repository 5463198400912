import React, {useState} from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Create,
    FormTab,
    Show,
    TextInput,
    TabbedForm,
    TextField,
    DateInput,
    TopToolbar,
    NumberInput,
    ReferenceInput, AutocompleteInput,
    ReferenceManyField, Responsive, Datagrid, NumberField, DateField, SelectInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import OrdenCompraDetalleButtonShow from "./OrdenCompraDetalleButtonShow";
import OrdenCompraDetalleInfo from "./OrdenCompraDetalleInfo";

import ComboMonedaActivo from "../componentes/ComboMonedaActivo";
import OrdenCompraDetalleButtonCreate from "./OrdenCompraDetalleButtonCreate";
import OrdenCompraDetalleButtonEdit from "./OrdenCompraDetalleButtonEdit";
import OrdenCompraDetalleButtonDelete from "./OrdenCompraDetalleButtonDelete";
import {Grid} from "@mui/material";
import ProveedorBusquedaBtn from "../proveedor/ProveedorBusquedaBtn";
import ComboIvaResponsable from "../componentes/ComboIvaResponsable";
import PaisComercialBusquedaBtn from "../pais/PaisBusquedaBtn";
import ProvinciaLegalBusquedaBtn from "../Provincia/ProvinciaBusquedaBtn";
import CiudadLegalBusquedaBtn from "../Ciudad/CiudadBusquedaBtn";


const useStyles = makeStyles((theme) => ({

    es_ampliacion: {display: 'inline-block', width: 150},
    orden_compra_origen_nro: {display: 'inline-block', width: 180, marginLeft: 32},
    orden_compra_origen_ampliacion_nro: {display: 'inline-block', width: 120, marginLeft: 32},

    orden_compra_nro: {display: 'inline-block', width: 180},
    orden_compra_ampliacion_nro: {display: 'inline-block', width: 120},
    orden_compra_fecha: {display: 'inline-block', width: 180, marginLeft: 32},
    orden_compra_fecha_origen: {display: 'inline-block', width: 180, marginLeft: 32},
    orden_compra_ano: {display: 'inline-block', width: 120, marginLeft: 32},

    contratacion_tipo: {display: 'inline-block', width: 140},
    contratacion_tipo_d: {display: 'inline-block', width: 600, marginLeft: 32},
    contratacion_ano: {display: 'inline-block', width: 140},
    contratacion_nro: {display: 'inline-block', width: 140},
    unidad_area_requiere_cod: {display: 'inline-block', width: 100, marginLeft: 32},
    unidad_negocio_requiere: {display: 'inline-block', width: 140, marginLeft: 32},
    unidad_area_requiere: {display: 'inline-block', width: 380, marginLeft: 32},

    descripcion: {display: 'inline-block', width: 600, marginLeft: 32},
    programa: {display: 'inline-block', width: 100},
    sector: {display: 'inline-block', width: 100},
    gerencia: {display: 'inline-block', width: 100},
    emisora: {display: 'inline-block', width: 100},

    informe_adjudicacion_referencia_gde: {display: 'inline-block', width: 600},
    procedimiento: {display: 'inline-block', width: 600},
    expediente: {display: 'inline-block', width: 600},
    informe_adjudicacion: {display: 'inline-block', width: 600},

    combo: {display: 'inline-block', width: 250, marginLeft: 32},

    procedimeinto_referencia_gde: {display: 'inline-block', width: 250, marginLeft: 32},

    persona_forma: {marginLeft: 32},

    proveedor: {display: 'inline-block', width: 120},
    razon_social: {display: 'inline-block', width: 400},
    razon_social_fantasia: {display: 'inline-block', width: 400, marginLeft: 32},
    documento_tipo: {display: 'inline-block', width: 200},
    documento_nro: {display: 'inline-block', width: 138, marginLeft: 32},
    cuit_cuil: {display: 'inline-block', width: 200, marginLeft: 32},
    email: {display: 'inline-block', width: 280},
    emailAlt: {display: 'inline-block', width: 280, marginLeft: 32},
    area: {display: 'inline-block', width: 80},
    nro: {display: 'inline-block', marginLeft: 32, width: 168,},
    area2: {display: 'inline-block', width: 80, marginLeft: 32},
    pais: {display: 'inline-block', width: 250, marginLeft: 32},
    codPais: {display: 'inline-block', width: 80},
    provincia: {display: 'inline-block', width: 250},
    provinciaxl: {display: 'inline-block', width: 350},
    partido: {display: 'inline-block', width: 350, marginLeft: 32},
    ciudad: {display: 'inline-block', width: 350, marginLeft: 32},

    calle: {display: 'inline-block', width: 410},
    calle_nro: {display: 'inline-block', width: 90, marginLeft: 32},
    piso: {display: 'inline-block', width: 50, marginLeft: 32},
    departamento: {display: 'inline-block', width: 50, marginLeft: 20},
    codigo_postal: {display: 'inline-block', width: 110, marginLeft: 20},
    d_iva: {display: 'inline-block', width: 370},

    codigo_pro_tvpublica: {display: 'inline-block', width: 150},
    codigo_pro_rna: {display: 'inline-block', width: 150, marginLeft: 32},

    precio_incluye_iva: {display: 'inline-block', width: 100},
    importe_subtotal: {display: 'inline-block', width: 250},
    importe_iva: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_total: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_subtotal_pesos: {display: 'inline-block', width: 250},
    importe_iva_pesos: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_total_pesos: {display: 'inline-block', width: 250, marginLeft: 32},

    es_ampliacion_inicio: {display: 'inline-block', width: 230, marginLeft: 32},

    pre_adjudica_codigo: {display: 'inline-block', width: 80},
    pre_adjudica_nombre_apellido: {display: 'inline-block', width: 250, marginLeft: 32},
    pre_adjudica_sector: {display: 'inline-block', width: 250, marginLeft: 32},
    pre_adjudica_firma_tipo: {display: 'inline-block', width: 250, marginLeft: 32},
    conforma_adjudica_codigo: {
        display: 'inline-block', width: 80
    },
    conforma_adjudica_nombre_apellido: {display: 'inline-block', width: 250, marginLeft: 32},
    conforma_adjudica_sector: {display: 'inline-block', width: 250, marginLeft: 32},
    conforma_adjudica_firma_tipo: {display: 'inline-block', width: 250, marginLeft: 32},
    orden_compra_estado: {display: 'inline-block', width: 180},
    orden_compra_estado_detalle: {display: 'inline-block', width: 800, marginLeft: 32},
    title: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    text: {display: 'inline-block', width: 1200},
    moneda_cotizacion: {display: 'inline-block', width: 100, marginLeft: 32},
    moneda_cotizacion_fecha: {display: 'inline-block', width: 250, marginLeft: 32},

    fecha_vencimiento_orden_compra: {display: 'inline-block', width: 180},
    fecha_vencimiento_enviar_correo_electronico_aviso: {display: 'inline-block', width: 80, marginLeft: 32},
    fecha_vencimiento_cantidad_dias_anterior_enviar_aviso: {display: 'inline-block', width: 200, marginLeft: 115},
    fecha_vencimiento_correo_electronico_aviso: {display: 'inline-block', width: 605},
}));

const ProveedorShow = props => {
    const classes = useStyles();
    const [Proveedor, setProveedor] = useState('');
    const [PersonaTipo, setPersonaTipo] = useState('');
    // const [ProveedorSocioEstadoCivilCasado, setProveedorSocioEstadoCivilCasado] = useState('');

    const establecerProveedor = value => {
        if (value == null || value === '') return '';
        setProveedor(value.trim());
        return value;
    };

    const establecerFecha = value => {
        if (value == null || value === '') return '';
        return value;
    };

    // Obtengo tipo de persona para cargar solapa correspondiente
    const establecerPersonaTipo = value => {
        if (value == null || value === '') return '';
        setPersonaTipo(value.trim());
        return value;
    };


    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    // const establecerProveedorSocioEstadoCivilCasado = value => {
    //     if (value == null || value === '') return '';
    //     setProveedorSocioEstadoCivilCasado(value.trim());
    //     return value;
    // };

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>

        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        if (record.orden_compra_ampliacion_nro != null)
            return <span>Nro. Orden de Compra: {record ? `${record.orden_compra_nro}` + '/' + `${record.orden_compra_ampliacion_nro}` : ''}</span>;
        else
            return <span>Nro. Orden de Compra: {record ? `${record.orden_compra_nro}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>
                {/*Datos de la Cabecera*/}
                <FormTab label="DATOS DE LA CABECERA">

                    <NumberInput label="Nro. Orden de Compra" source="orden_compra_nro"
                                 formClassName={classes.orden_compra_nro}
                                 fullWidth
                                 InputLabelProps={{
                                     shrink: true,
                                 }}
                                 variant="filled"
                                 inputProps={{readOnly: true}}
                    />

                    <TextInput source="orden_compra_ampliacion_nro"
                               label="Nro. ampliación"
                               formClassName={classes.orden_compra_origen_ampliacion_nro}
                               variant="filled"
                               inputProps={{readOnly: true}} fullWidth
                               InputLabelProps={{shrink: true}}
                    />

                    <DateInput source="orden_compra_fecha" label={"Fecha Orden de Compra"}
                               formClassName={classes.orden_compra_fecha}
                               variant="filled"
                               inputProps={{readOnly: true}}
                               initialValue={new Date()}
                               fullWidth
                               InputLabelProps={{shrink: true}}
                    />

                    <TextInput source="orden_compra_ano" label={"Año a imputar"}
                               formClassName={classes.orden_compra_ano}
                               variant="filled"
                               inputProps={{readOnly: true}}
                               initialValue={new Date().getFullYear()}
                               fullWidth
                               InputLabelProps={{shrink: true}}
                    />

                    <br/>

                    <div style={{display: 'inline-flex', width: '100%'}}>

                        <SelectInput source="es_ampliacion" className={classes.es_ampliacion} fullWidth={true}
                                     variant="filled"
                                     inputProps={{readOnly: true}} InputLabelProps={{shrink: true}} label={"¿Es una ampliación?"}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}
                        />

                        <TextInput source="orden_compra_origen_nro" label="Nro. OC Origen"
                                   className={classes.orden_compra_origen_nro}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />

                        <TextInput source="orden_compra_origen_ampliacion_nro"
                                   label="Nro. ampliación"
                                   className={classes.orden_compra_origen_ampliacion_nro}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />
                    </div>

                    <hr align='left' style={{width: '45%'}}/>
                    <br/>


                    <TextInput source="contratacion_nro" label={"Contratación ID"}
                               className={classes.contratacion_nro}
                               variant="filled"
                               inputProps={{readOnly: true}}
                               vfullWidth
                               InputLabelProps={{shrink: true}}
                    />
                    <br/>


                    <TextInput source="contratacion_tipo" label={"Tipo Contratación"}
                               formClassName={classes.contratacion_tipo}
                               variant="filled"
                               inputProps={{readOnly: true}}
                               fullWidth
                               InputLabelProps={{shrink: true}}
                    />

                    <TextInput source="ContratacionTipo.contratacion_d" label={"Descripción"}
                               formClassName={classes.contratacion_tipo_d}
                               variant="filled"
                               inputProps={{readOnly: true}}
                               fullWidth
                               InputLabelProps={{shrink: true}}
                    />


                    <br/>

                    <TextInput source="contratacion_ano" label={"Año Contratación"}
                               formClassName={classes.contratacion_ano}
                               variant="filled"
                               inputProps={{readOnly: true}}
                               fullWidth
                               InputLabelProps={{shrink: true}}
                    />

                    <TextInput source="ContratacionEncuadre.unidad_negocio_requiere" label={"Unidad de Negocio"}
                               formClassName={classes.unidad_negocio_requiere}
                               variant="filled"
                               inputProps={{readOnly: true}}
                               fullWidth
                               InputLabelProps={{shrink: true}}
                    />

                    <TextInput source="area" label={"Cod. Area"}
                               formClassName={classes.unidad_area_requiere_cod}
                               variant="filled"
                               inputProps={{readOnly: true}}
                               fullWidth
                               InputLabelProps={{shrink: true}}
                    />

                    <TextInput source="Area.area_d" label={"Unidad Area"}
                               formClassName={classes.unidad_area_requiere}
                               variant="filled"
                               inputProps={{readOnly: true}}
                               fullWidth
                               InputLabelProps={{shrink: true}}
                    />
                    <br/>

                    <div style={{display: 'inline-flex', width: '100%'}}>
                        <TextInput source="emisora" label={"Emisora"}
                                   className={classes.emisora}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />

                        <TextInput source="Emisora.emisora_d" label={"Descripción"}
                                   className={classes.descripcion}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />


                    </div>

                    <br/>

                    <div style={{display: 'inline-flex', width: '100%'}}>
                        <TextInput source="gerencia" label={"Gerencia"}
                                   className={classes.gerencia}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />
                        <TextInput source="Gerencia.gerencia_d" label={"Descripción"}
                                   className={classes.descripcion}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />
                    </div>
                    <br/>
                    <div style={{display: 'inline-flex', width: '100%'}}>
                        <TextInput source="sector" label={"Sector"}
                                   className={classes.sector}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />
                        <TextInput source="Sector.sector_d" label={"Descripción"}
                                   className={classes.descripcion}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />
                    </div>
                    <br/>
                    <div style={{display: 'inline-flex', width: '100%'}}>
                        <TextInput source="programa" label={"Programa"}
                                   className={classes.programa}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />
                        <TextInput source="Programa.programa_d" label={"Descripción"}
                                   className={classes.descripcion}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                        />
                    </div>

                    <TextInput source="procedimiento"
                               label={"Procedimiento"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.procedimiento} fullWidth={true}/>
                    <br/>

                    <TextInput label="Expediente" source="procedimeinto_referencia_gde"
                               formClassName={classes.expediente}
                               fullWidth
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               variant="filled"
                               inputProps={{readOnly: true}}
                    />
                    <br/>
                    <TextInput source="informe_adjudicacion"
                               label={"Informe Adjudicación"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.informe_adjudicacion} fullWidth={true}/>
                    <br/>
                    <TextInput source="informe_adjudicacion_referencia_gde"
                               label={"Referencia Adjudicación GDE"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.informe_adjudicacion_referencia_gde} fullWidth={true}/>

                </FormTab>

                <FormTab label="DATOS DEL PROVEEDOR">
                    <>

                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput source="proveedor_rta" label="Proveedor"
                                       className={classes.proveedor}
                                       variant="filled" inputProps={{readOnly: true}}
                                       fullWidth
                                       InputLabelProps={{shrink: true}}
                            />

                        </div>

                        <TextInput source="razon_social" label={"Razón Social"} variant="filled"
                                   inputProps={{readOnly: true}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.razon_social} fullWidth={true}/>

                        <TextInput source="razon_social_fantasia" label={"Razon Fantasia"} variant="filled"
                                   inputProps={{readOnly: true}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.razon_social_fantasia} fullWidth={true}
                        />
                        <br/>
                        <TextInput source="documento_tipo" label={"Tipo Doc."} variant="filled"
                                   inputProps={{readOnly: true}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.documento_tipo} fullWidth={true}
                        />

                        <TextInput source="documento_nro" label={"Nro. Documento"}
                                   InputLabelProps={{shrink: true}} variant="filled"
                                   inputProps={{readOnly: true}}
                                   className={classes.documento_nro}
                        />

                        <TextInput source="cuit_cuil" label={"CUIT/CUIL"} variant="filled"
                                   inputProps={{readOnly: true}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.cuit_cuil} fullWidth={true}
                        />
                        <br/>

                        <ComboIvaResponsable readOnly={true} className={classes.combo}/>

                        <br/>

                        <Typography variant="h6">Domicilio Comercial</Typography>

                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput source="domicilio_pais" label={"Cod. Pais"}
                                       fullWidth={true}
                                       className={classes.codPais}
                                       variant="filled" inputProps={{readOnly: true}}
                                       InputLabelProps={{shrink: true}}
                                       style={{visible: false}}
                            />

                            <TextInput source="Pais.pais" label={"Pais Comercial"}
                                       fullWidth={true}
                                       className={classes.pais}
                                       variant="filled" inputProps={{readOnly: true}}
                                       InputLabelProps={{shrink: true}}
                            />

                        </div>

                                <TextInput source="domicilio_provincia" label={"Provincia"} variant="filled"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.provincia} fullWidth={true}
                                           inputProps={{readOnly: true}}
                                />

                                <TextInput source="domicilio_ciudad" label={"Ciudad"} variant="filled"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.ciudad} fullWidth={true}
                                           inputProps={{readOnly: true}}
                                />

                                <TextInput source="domicilio_partido" label={"Partido"} variant="filled"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.partido} fullWidth={true}
                                           inputProps={{readOnly: true}}
                                />


                        <br/>

                        <TextInput source="domicilio_calle" label={"Calle"} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.calle} fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />

                        <TextInput source="domicilio_calle_nro" label={"Nro."} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.calle_nro} fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />

                        <TextInput source="domicilio_piso" label={"Piso"} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.piso} fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />

                        <TextInput source="domicilio_departamento" label={"Depto"} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.departamento} fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />

                        <TextInput source="domicilio_codigo_postal" label={"Código Postal"} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.codigo_postal} fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />
                        <br/>

                        <Typography variant="h6">Contacto</Typography>

                        <TextInput source="contacto_correo_electronico" label={"Email"} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.email} fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />

                        <TextInput source="contacto_correo_electronico_alternativo" label={"Email alternativo"}
                                   variant="filled"
                                   inputProps={{readOnly: true}} InputLabelProps={{shrink: true}}
                                   className={classes.emailAlt} fullWidth={true}
                        />
                        <br/>
                        <TextInput source="contacto_telefono_area" label={"Tel Área"} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.area}
                                   fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />

                        <TextInput source="contacto_telefono_nro" label={"Tel Nro"} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro}
                                   inputProps={{readOnly: true}}
                                   fullWidth={true}/>

                        <TextInput source="contacto_telefono_alternativo_area" label={"T.Área Alt"}
                                   variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   inputProps={{readOnly: true}}
                                   className={classes.area2} fullWidth={true}
                        />

                        <TextInput source="contacto_telefono_alternativo_nro" label={"Tel Nro Alt."}
                                   variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro} fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />
                        <br/>

                        <TextInput source="contacto_celular_area" label={"Cel Área"} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.area}
                                   fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />

                        <TextInput source="contacto_celular_nro" label={"Tel Nro"} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro}
                                   fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />

                        <TextInput source="contacto_fax_area" label={"Fax Área"} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.area2}
                                   fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />

                        <TextInput source="contacto_fax_nro" label={"Fax Nro."} variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro}
                                   fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />
                        <br/>

                        {/**proveedor unidad*/}
                        <Typography variant="h6">Proveedor Unidad</Typography>
                        <TextInput source="codigo_pro_tvpublica"
                                   label={"Código TVPública"}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.codigo_pro_tvpublica}
                                   fullWidth={true}
                        />

                        <TextInput source="codigo_pro_rna"
                                   label={"Código RNA"}
                                   variant="filled"
                                   inputProps={{readOnly: true}}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.codigo_pro_rna}
                                   fullWidth={true}
                        />
                    </>
                </FormTab>

                {/* detalle de Productos /Servicios*/}
                <FormTab label="DETALLE DE PRODUCTOS/SERVICIOS">

                    {/**Moneda de la Compra*/}
                    <Typography variant="h6">Moneda de la Compra</Typography>

                    <div style={{display: "flex", alignItems: "baseline", width: '100%'}}>

                        <ComboMonedaActivo readOnly={true} className={classes.combo}/>

                        <NumberInput
                            label="Cotización"
                            source="moneda_cotizacion"
                            className={classes.moneda_cotizacion}
                            fullWidth={true}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                            inputProps={{readOnly: true}}
                        />

                        <DateInput source="moneda_cotizacion_fecha" label={"Fecha Cotización"}
                                   variant="filled"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.moneda_cotizacion_fecha} fullWidth={true}
                                   inputProps={{readOnly: true}}
                        />

                    </div>

                    <Grid container>
                        {/*<Grid item xs={12} sm={12} md={2}>*/}
                        {/*</Grid>*/}

                        <Grid item xs={12} sm={12} md={2}>
                            <h4>
                                Importe Subtotal:
                            </h4>
                            <NumberField
                                label="Importe Subtotal"
                                source="importe_subtotal"
                                className={classes.importe_subtotal}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginLeft: '10px'}}
                                variant="filled"
                                inputProps={{readOnly: true}}
                                options={{minimumFractionDigits: 2}}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2}>
                            <h4>
                                Importe del IVA:
                            </h4>
                            <NumberField
                                label="Importe del IVA"
                                source="importe_iva"
                                className={classes.importe_iva}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                inputProps={{readOnly: true}}
                                options={{minimumFractionDigits: 2}}
                                style={{marginLeft: '10px'}}
                            />
                        </Grid>


                        <Grid item xs={12} sm={12} md={2}>

                            <h4>
                                Total del Ítem:
                            </h4>
                            <NumberField
                                label="Total del Ítem"
                                source="importe_total"
                                className={classes.importe_total}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                inputProps={{readOnly: true}}
                                options={{minimumFractionDigits: 2}}
                                style={{marginLeft: '10px'}}
                            />
                        </Grid>
                    </Grid>
                    <br/>

                    <Grid container>
                        {/*<Grid item xs={12} sm={12} md={2}>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} sm={12} md={2}>
                            <h4>
                                Subtotal Pesos:
                            </h4>
                            <NumberField
                                label="Subtotal Pesos"
                                source="importe_subtotal_pesos"
                                className={classes.importe_subtotal_pesos}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                inputProps={{readOnly: true}}
                                options={{minimumFractionDigits: 2}}
                                style={{marginLeft: '10px'}}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2}>
                            <h4>
                                IVA Pesos
                            </h4>
                            <NumberField
                                label="IVA Pesos"
                                source="importe_iva_pesos"
                                className={classes.importe_iva_pesos}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                inputProps={{readOnly: true}}
                                options={{minimumFractionDigits: 2}}
                                style={{marginLeft: '10px'}}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={2}>
                            <h4>
                                Total ítem Pesos
                            </h4>
                            <NumberField
                                label="Total ítem Pesos"
                                source="importe_total_pesos"
                                className={classes.importe_total_pesos}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                inputProps={{readOnly: true}}
                                options={{minimumFractionDigits: 2}}
                                style={{marginLeft: '10px'}}
                            />
                        </Grid>
                    </Grid>
                    <br/>

                    {/*<OrdenCompraDetalleButtonCreate/>*/}

                    <ReferenceManyField
                        sort={{field: 'item', order: 'ASC'}}
                        perPage={500}
                        reference="OrdenCompraDetalle"
                        target="id"
                        addLabel={false}
                    >
                        <Datagrid expand={<OrdenCompraDetalleInfo/>}>
                            <TextField label="Item" source="item"
                                       style={{textAlign: 'right'}}
                                       options={{minimumFractionDigits: 0}}/>
                            <TextField label="Renglon" source="renglon_nro"/>

                            <TextField label="Renglon Descripción" source="renglon_descripcion"
                                       cellformClassName={classes.title}/>

                            <TextField label="Moneda" source="moneda"/>

                            <NumberField label="Total Ítem" source="total_item"
                                         style={{textAlign: 'right'}}
                                         options={{minimumFractionDigits: 2}}/>

                            <NumberField label="Total Ítem $" source="total_item_pesos"
                                         style={{textAlign: 'right'}}
                                         options={{minimumFractionDigits: 2}}/>

                            <OrdenCompraDetalleButtonShow label=""/>
                        </Datagrid>
                    </ReferenceManyField>


                </FormTab>
                {/*Leyendas*/}
                <FormTab label="LEYENDAS">

                    <TextInput multiline source="leyenda_cabecera" formClassName={classes.text}
                               InputLabelProps={{shrink: true}} variant="filled"
                               label="Leyenda Cabecera" fullWidth inputProps={{readOnly: true}}/>

                    <TextInput multiline source="leyenda_detalle" formClassName={classes.text}
                               label="Leyenda Detalle"
                               InputLabelProps={{shrink: true}} variant="filled"
                               fullWidth inputProps={{readOnly: true}}/>

                    <TextInput multiline source="leyenda_pie" formClassName={classes.text} label="Leyenda Pie"
                               InputLabelProps={{shrink: true}} variant="filled"
                               fullWidth inputProps={{readOnly: true}}/>

                    <TextInput multiline source="leyenda_seguro" formClassName={classes.text} label="Leyenda Seguro"
                               InputLabelProps={{shrink: true}} variant="filled"
                               fullWidth inputProps={{readOnly: true}}/>

                    <TextInput multiline source="leyenda_condicion_pago" formClassName={classes.text} fullWidth
                               InputLabelProps={{shrink: true}} variant="filled"
                               label="Leyenda Condición Pago" inputProps={{readOnly: true}}/>

                    <TextInput multiline source="leyenda_condicion_pago_moneda_extranjera" inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}} variant="filled"
                               label="Leyenda Condición Pago Moneda Extranjera" formClassName={classes.text}
                               fullWidth/>

                    <TextInput multiline source="leyenda_lugar_ejecucion" label="Leyenda Lugar Ejecución" fullWidth
                               InputLabelProps={{shrink: true}} variant="filled"
                               formClassName={classes.text} inputProps={{readOnly: true}}/>

                    <TextInput multiline source="leyenda_condicion_entrega_colocacion" fullWidth
                               InputLabelProps={{shrink: true}} variant="filled"
                               label="Leyenda Condición Entrega Colocación" formClassName={classes.text}
                               inputProps={{readOnly: true}}/>

                </FormTab>
                {/*Estado*/}
                <FormTab label="ESTADO">


                    <Typography variant="h6">Responsable Pre-Adjudica</Typography>

                    <TextInput source="pre_adjudica_codigo" label={"Código"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.pre_adjudica_codigo} fullWidth={true}/>

                    <TextInput source="pre_adjudica_nombre_apellido" label={"Nombre y Apellido"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.pre_adjudica_nombre_apellido} fullWidth={true}/>

                    <TextInput source="pre_adjudica_sector" label={"Sector"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.pre_adjudica_sector} fullWidth={true}/>

                    <TextInput source="pre_adjudica_firma_tipo" label={"Tipo de Firma"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.pre_adjudica_firma_tipo} fullWidth={true}/>


                    <Typography variant="h6">Responsable Conforma</Typography>

                    <TextInput source="conforma_adjudica_codigo" label={"Código"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.conforma_adjudica_codigo} fullWidth={true}/>

                    <TextInput source="conforma_adjudica_nombre_apellido" label={"Nombre y Apellido"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.conforma_adjudica_nombre_apellido} fullWidth={true}/>

                    <TextInput source="conforma_adjudica_sector" label={"Sector"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.conforma_adjudica_sector} fullWidth={true}/>

                    <TextInput source="conforma_adjudica_firma_tipo" label={"Tipo de firma"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.conforma_adjudica_firma_tipo} fullWidth={true}/>
                    <br/>
                    <TextInput source="orden_compra_estado" label={"Estado Actual"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.orden_compra_estado} fullWidth={true}/>

                    <TextInput source="EstadoOrdenCompra.estado_d" label={"Descripción"} variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.orden_compra_estado_detalle} fullWidth={true}
                               multiline
                    />

                    <Typography variant="h6">Vencimiento Orden de Compra</Typography>

                    <DateInput source="fecha_vencimiento_orden_compra" label={"Fecha Vencimiento"}
                               variant="filled"
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.fecha_vencimiento_orden_compra} fullWidth={true}
                    />

                    <SelectInput source="fecha_vencimiento_enviar_correo_electronico_aviso"
                                 formClassName={classes.fecha_vencimiento_enviar_correo_electronico_aviso}
                                 fullWidth={true} inputProps={{readOnly: true}}
                                 variant="filled"
                                 InputLabelProps={{shrink: true}} label={"Enviar Aviso"}
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}
                    />

                    <TextInput source="fecha_vencimiento_cantidad_dias_anterior_enviar_aviso"
                               formClassName={classes.fecha_vencimiento_cantidad_dias_anterior_enviar_aviso}
                               fullWidth={true} inputProps={{readOnly: true}}
                               variant="filled"
                               InputLabelProps={{shrink: true}}
                               label={"Días de Anticipación Aviso"}
                    />

                    <br/>

                    <TextInput source="fecha_vencimiento_correo_electronico_aviso"
                               formClassName={classes.fecha_vencimiento_correo_electronico_aviso}
                               fullWidth={true} inputProps={{readOnly: true}}
                               variant="filled"
                               InputLabelProps={{shrink: true}} label={"E-mail de aviso"}
                    />

                </FormTab>
            </TabbedForm>
        </Show>
    )
};
export default ProveedorShow;


