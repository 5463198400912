import React, {useCallback, useState} from 'react';
import {
    TextInput,
    useMutation,
    SaveButton,
    Toolbar,
    useRedirect,
    useNotify,
    useRefresh,
    useDataProvider
} from 'react-admin';

import IconSeleccionar from '@material-ui/icons/Done';
import {makeStyles} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import IconCancelar from '@material-ui/icons/Close';
import EstadoOrdenCompraBusquedaBtn from "./EstadoOrdenCompraBusquedaBtn";
import {Form, Field} from 'react-final-form';
import ActualizarEstadoBtn from "../OrdenCompraCabecera/ActualizarEstadoBtn";
import OrdenCompraBusquedaBtn from "../OrdenCompraCabecera/OrdenCompraBusquedaBtn";

export const EstadoOCActualizarForm = (props) => {

        const [IdNuevoEstado, setIdNuevoEstado] = useState('');
        const [NuevoEstado, setNuevoEstado] = useState('');
        const [NuevoEstadoDesc, setNuevoEstadoDesc] = useState('');
        const [HayCambioDeEstado, setHayCambioDeEstado] = useState(false);
        const [update] = useMutation();

        const useStyles = makeStyles({
            estado: {display: 'inline-block', width: 180},
            estadoMargin: {display: 'inline-block', width: 120, marginLeft: 32},
            descripcion: {display: 'inline-block', width: 800, marginLeft: 72},
            descripcionNueva: {display: 'inline-block', width: 800, marginLeft: 22},
        });
        const classes = useStyles();

        const updateEstadoOrdenCompra = useCallback(async (who) => {
            setIdNuevoEstado(who.estado_nuevo_id)
            setNuevoEstado(who.estado_nuevo);
            setNuevoEstadoDesc(who.estado_d.trim());
            if (who.estado_nuevo_id > 0)
                setHayCambioDeEstado(true)
        });

        const formatEstado = v => {
            return NuevoEstado;
        };

        const formatEstadoDesc = v => {
            return NuevoEstadoDesc;
        };

        const BtnVolver = ({record}) => (
            <Button variant="contained" size={"small"} color="primary" style={{marginLeft: 30}} onClick={() => {
                props.callbackCloseDrawer(false)
            }}
            >
                <IconCancelar/>
                Volver
            </Button>
        );

        const BotonActualizarEstado = () => {
            const notify = useNotify();
            const refresh = useRefresh();
            const dataProvider = useDataProvider();

            const approve = async () => {
                try {
                    await dataProvider.update('EstadoOrdenCompraCambio', {
                        id: props.id,
                        data: {
                            estado_nuevo_id: IdNuevoEstado,
                            estado_nuevo: NuevoEstado,
                            orden_compra_nro: props.orden_compra_nro,
                            orden_compra_ampliacion_nro: props.orden_compra_ampliacion_nro
                        }
                    });
                    // success side effects go here
                    const oc = props.orden_compra_nro + '/' + props.orden_compra_ampliacion_nro;
                    refresh();
                    notify(`El estado de la Orden de Compra ${oc} ha sido actualizado a ${NuevoEstado.trim()}`, {type: 'info'});
                } catch (error) {
                    // failure side effects go here
                    notify(`Error en la actualización: ${error.message}`, {type: 'warning'});
                }
            };

            const handleUpdate = () => {
                approve();
            };

            return (
                <>
                    <Button variant="outlined" color="primary" onClick={handleUpdate} disabled={!HayCambioDeEstado}>
                        <div title={''} style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            Actualizar Estado
                        </div>
                    </Button>

                </>
            );
        };


        const handleSubmit = useCallback((values) => {
            // event.preventDefault();
        }, []);

        return (
            <Form onSubmit={handleSubmit}>
                {({handleSubmit}) => (
                    <form onSubmit={handleSubmit} style={{margin: '3em'}}>
                        <h3>CAMBIO DE ESTADO</h3>

                        <TextInput
                            source="orden_compra_nro"
                            label={"Nro. OC"}
                            variant="filled"
                            InputLabelProps={{shrink: true}}
                            inputProps={{readOnly: true}}
                            className={classes.estado}
                            fullWidth
                        />

                        <TextInput
                            source="orden_compra_ampliacion_nro"
                            label={"Ampliación"}
                            variant="filled"
                            InputLabelProps={{shrink: true}}
                            inputProps={{readOnly: true}}
                            className={classes.estadoMargin}
                            fullWidth
                        />
                        <br/>

                        <div style={{display: "inline-flex", width: "100%"}}>
                            <TextInput
                                source="orden_compra_estado"
                                label={"Estado Actual"}
                                variant="filled"
                                InputLabelProps={{shrink: true}}
                                inputProps={{readOnly: true}}
                                className={classes.estado}
                                fullWidth
                            />

                            <TextInput
                                source="estado_d"
                                label={"Descripción"}
                                variant="filled"
                                InputLabelProps={{shrink: true}}
                                className={classes.descripcion}
                                fullWidth
                            />
                        </div>
                        <br/>
                        <div style={{display: "inline-flex", width: "100%"}}>
                            <TextInput
                                label={"Nuevo Estado"}
                                variant="filled"
                                InputLabelProps={{shrink: true}}
                                inputProps={{readOnly: true}}
                                className={classes.estado}
                                fullWidth
                                value={NuevoEstado}
                                format={formatEstado}
                            />

                            <EstadoOrdenCompraBusquedaBtn
                                className={classes.btnBusqueda}
                                estado={props.estado}
                                callbackEstadoOrdenCompra={updateEstadoOrdenCompra}
                            />

                            <TextInput
                                label={"Descripción"}
                                variant="filled"
                                InputLabelProps={{shrink: true}}
                                inputProps={{readOnly: true}}
                                className={classes.descripcionNueva}
                                fullWidth
                                value={NuevoEstadoDesc}
                                format={formatEstadoDesc}
                                multiline
                            />
                        </div>


                        <BotonActualizarEstado/>

                        <BtnVolver/>

                    </form>
                )}
            </Form>
        );
    }
;

export default EstadoOCActualizarForm;