import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput, TextField, NumberField, Datagrid
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";


const useStyles = makeStyles((theme) => ({
    contratacion_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    contratacion_d: {display: 'inline-block', width: 800, marginLeft: 202},
    monto_desde_pesos: {display: 'inline-block', width: 200, marginLeft: 8},
    monta_hasta_pesos: {display: 'inline-block', width: 200, marginLeft: 202},
    ultimo_nro_usado: {display: 'inline-block', width: 200, marginLeft: 404},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_contratacion_tipo = values.contratacion_tipo

    if (w_contratacion_tipo===undefined) {
        errors.contratacion_tipo = ['El campo Código es requerido'];
    }else {
        if (w_contratacion_tipo === null) {
            errors.contratacion_tipo = ['El campo Código es requerido'];
        } else {
            w_contratacion_tipo = w_contratacion_tipo.trim()
            if (w_contratacion_tipo === '') {
                errors.contratacion_tipo = ['El campo Código es requerido'];
            }
        }
    }


    var w_contratacion_d = values.contratacion_d

    if (w_contratacion_d===undefined) {
        errors.contratacion_d = ['El campo Descripción Contratacion Tipo es requerido'];
    }else {
        if (w_contratacion_d === null) {
            errors.contratacion_d = ['El campo Descripción Contratacion Tipo es requerido'];
        } else {
            w_contratacion_d = w_contratacion_d.trim()
            if (w_contratacion_d === '') {
                errors.contratacion_d = ['El campo Descripción Contratacion Tipo es requerido'];
            }
        }
    }




    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/UnidadEmisora`;

const ContratacionTipoCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Contratacion Tipo</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS CONTRATACION TIPO">
                    <TextInput
                        label="Código Contratacion Tipo"
                        source="contratacion_tipo"
                        formClassName={classes.contratacion_tipo}
                        placeholder="Ingrese Código Contratacion Tipo"
                        helperText="Código Contratacion Tipo"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <TextInput
                        label="Descripción Contratacion Tipo"
                        source="contratacion_d"
                        formClassName={classes.contratacion_d}
                        placeholder="Ingrese Descripción Contratacion Tipo"
                        helperText="Descripción Contratacion Tipo"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    <NumberInput
                        id="standard-full-width"
                        label="Monto Desde Pesos"
                        source="monto_desde_pesos"
                        formClassName={classes.monto_desde_pesos}
                        placeholder="Ingrese Monto Desde Pesos"
                        helperText="Monto Desde Pesos"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />


                    <NumberInput
                        id="standard-full-width"
                        label="Monta Hasta Pesos"
                        source="monta_hasta_pesos"
                        formClassName={classes.monta_hasta_pesos}
                        placeholder="Ingrese Monta Hasta Pesos"
                        helperText="Monta Hasta Pesos"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />



                    <NumberInput
                        id="standard-full-width"
                        label="Ultimo Nro Usado"
                        source="ultimo_nro_usado"
                        formClassName={classes.ultimo_nro_usado}
                        placeholder="Ingrese Ultimo Nro Usado"
                        helperText="Ultimo Nro Usado"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default ContratacionTipoCreate;


