import React from 'react';
import {useDataProvider, useRefresh} from 'react-admin';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Button from '@material-ui/core/Button';
import {saveAs} from 'file-saver';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const OrdenCompraPdfGeneradoButton = ({record}) => {
    const {id: wr_id, pdf_generado_nombre: wr_pdf_generado_nombre = '', parametro_path: wr_parametro_path, pdf_generado: wr_pdf_generado = '', orden_compra_estado: wr_estado} = record;
    const dataProviderPdfGenerado = useDataProvider();
    const [estadoCircularProgress, setEstadoCircularProgress] = React.useState(0);
    const classes = useStyles();
    const [openNoAprobado, setNoAprobadoOpen] = React.useState(false);
    const refresh = useRefresh()

    const handleNoAprobadoOpen = () => {
        setNoAprobadoOpen(true);
    };

    const handleNoAprobadoClose = () => {
        setNoAprobadoOpen(false);
    };

    const onClickHandlerPdfGenerado = async () => {
        setEstadoCircularProgress(1);
        //--------------------------- si el pdf no está generado lo genera ----------------------
        var w_pdf_generado_nombre = wr_pdf_generado_nombre
        if (wr_pdf_generado === 'N') {
            w_pdf_generado_nombre = 'RTA_OC_' + ('00000000' + record.orden_compra_nro.toString()).slice(-8) + '_' + ('000' + record.orden_compra_ampliacion_nro.toString()).slice(-3) + '.pdf'
            await dataProviderPdfGenerado.getOne('OrdenCompraReporte', {id: wr_id});
        }

        if(w_pdf_generado_nombre==null){
            w_pdf_generado_nombre=''
        }
        w_pdf_generado_nombre=w_pdf_generado_nombre.trim()

        var wa_location_hostname = window.location.hostname

        console.log('wa_location_hostname')
        console.log(wa_location_hostname)

        var wa_apiurlserver

        switch (wa_location_hostname) {
            case 'opi.radionacional.ar':
                wa_apiurlserver = 'https://opi.radionacional.ar:4620'
                break
            case 'proveedores.radionacional.ar':
                wa_apiurlserver = 'https://archivprov.radionacional.ar'
                break
            default:
                wa_apiurlserver = 'http://'+wa_location_hostname+':4620'
                break
        }



        var w_parametro_path = wr_parametro_path
        if(w_parametro_path==null){
            w_parametro_path=''
        }
        w_parametro_path=w_parametro_path.trim()
        // ------------------------------ The Endpoint produccion -----------------------------------------

        var UrlServer = wa_apiurlserver+'/AdjuntoGeneradoDownload/'

        var URLToPDF = UrlServer +encodeURIComponent(w_parametro_path)+'/'+encodeURIComponent(w_pdf_generado_nombre)

        //-------------------- para bajar el archivo usamos la instruccion fetch de react en forma standard ----------------------

        const response = await fetch(URLToPDF);
        const fileContent = await response.blob();
        // Create file blob
        const blob = new Blob([fileContent], {type: 'application/pdf'});
        saveAs(blob,w_pdf_generado_nombre);
        setEstadoCircularProgress(0);
        if (wr_pdf_generado === 'N') {
            refresh()
        }
    };

    var w_orden_compra_nro = record.orden_compra_nro
    var w_orden_compra_ampliacion_nro = record.orden_compra_ampliacion_nro

    /// Solo permito imprimir el PDF si el estado es aprobado
    if (wr_estado.trim() !== 'APROBADO') {
        return (
            <div>
                <Button
                    type="button" className="btn btn-success btn-block" onClick={handleNoAprobadoOpen}
                >
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Para generar PDF el estado debe ser APROBADO N°: "+w_orden_compra_nro+" Apl.: "+w_orden_compra_ampliacion_nro+"."}
                    >
                        <PictureAsPdfIcon style={{color: 'black'}}/>
                    </div>
                </Button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openNoAprobado}
                    onClose={handleNoAprobadoClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openNoAprobado}>
                        <div className={classes.paper}>
                            <h2 id="transition-modal-title">PDF No Generado</h2>
                            <p id="transition-modal-description">Para generar PDF, el estado debe ser APROBADO.</p>
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    } else
        switch (wr_pdf_generado) {
            case 'S':
                return (
                    <Button type="button" className="btn btn-success btn-block" onClick={onClickHandlerPdfGenerado}>
                        <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                             title={"Ver PDF ya generado"}
                        >
                            <PictureAsPdfIcon style={{color: '#83ff95'}}/>
                        </div>
                    </Button>
                );
            default:
                return (
                    <div>
                        <div>
                            <Button type="button" className="btn btn-success btn-block"
                                    onClick={onClickHandlerPdfGenerado}>
                                <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                                     title={"Generar PDF N°: "+w_orden_compra_nro+" Apl.: "+w_orden_compra_ampliacion_nro+"."}>
                                    <PictureAsPdfIcon style={{color: 'white'}}/>
                                </div>
                            </Button>
                        </div>
                        {estadoCircularProgress === 1 ? (
                            <div>
                                <CircularProgress/>
                                {' Procesando PDF Impresión Orden de Compra...'}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                );
        }
};


OrdenCompraPdfGeneradoButton.defaultProps = {
    source: '',
    addLabel: true,
};

export default OrdenCompraPdfGeneradoButton;
