import React from 'react';
import Grid from '@material-ui/core/Grid';
import VerInfo from './VerInfo'

// Método que carga más datos de cada registro. Haciendo Click en el registro se expande hacia abajo.
const ProveedorInfo = ({record, ...rest}) => (
    <Grid container spacing={12}>
        <Grid container spacing={12}>
            <Grid container spacing={12}>
                <Grid item xs={12}>
                    <VerInfo
                        ver_razon_social_fantasia={record.razon_social_fantasia}
                        ver_legal_pais={record.d_pais}
                        ver_legal_provincia={record.provincia_legal_d}
                        ver_legal_partido={record.legal_partido}
                        ver_legal_ciudad={record.ciudad_legal_d}
                        ver_legal_calle={record.legal_calle}
                        ver_legal_calle_nro={record.legal_calle_nro}
                        ver_legal_piso={record.legal_piso}
                        ver_legal_departamento={record.legal_departamento}
                        ver_legal_codigo_postal={record.legal_codigo_postal}
                        ver_comercial_pais={record.d_pais_comercial}
                        ver_comercial_provincia={record.provincia_comercial_d}
                        ver_comercial_partido={record.comercial_partido}
                        ver_comercial_ciudad={record.ciudad_comercial_d}
                        ver_comercial_calle={record.comercial_calle}
                        ver_comercial_calle_nro={record.comercial_calle_nro}
                        ver_comercial_piso={record.comercial_piso}
                        ver_comercial_departamento={record.comercial_departamento}
                        ver_comercial_codigo_postal={record.comercial_codigo_postal}
                        ver_correo_electronico={record.correo_electronico}
                        ver_correo_electronico_alternativo={record.correo_electronico_alternativo}
                        ver_telefono_area={record.telefono_area}
                        ver_telefono_nro={record.telefono_nro}
                        ver_telefono_alternativo_area={record.telefono_alternativo_area}
                        ver_telefono_alternativo_nro={record.telefono_alternativo_nro}
                        ver_celular_area={record.celular_area}
                        ver_celular_nro={record.celular_nro}
                        ver_fax_area={record.fax_area}
                        ver_fax_nro={record.fax_nro}
                        ver_ibru_exento={record.ibru_exento}
                        ver_ibru_tipo={record.ibru_tipo}
                        ver_ibru_tipo_d={record.ibru_tipo_d}
                        ver_ibru_jurisdiccion_provincia={record.ibru_jurisdiccion_provincia}
                        ver_ibru_nro={record.ibru_nro}
                        ver_tiene_personas_con_discapacidad={record.tiene_personas_con_discapacidad}
                        ver_tiene_participacion_estatal={record.tiene_participacion_estatal}
                        ver_tiene_participacion_entidades_publicas_no_estatales={record.tiene_participacion_entidades_publicas_no_estatales}
                        ver_observacion={record.observacion}
                        ver_agenda={record.agenda}
                        ver_codigo_pro_tvpublica={record.codigo_pro_tvpublica}
                        ver_codigo_pro_rna={record.codigo_pro_rna}
                        ver_estado_proveedor_d={record.estado_proveedor_d}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>

);
export default ProveedorInfo;
