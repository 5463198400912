import React, {useEffect, Fragment} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    useNotify,
    useRefresh
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import PolicyIcon from '@material-ui/icons/Policy';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import InputBase from "@material-ui/core/InputBase";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReplayIcon from "@material-ui/icons/Replay";
import FilterListIcon from "@material-ui/icons/FilterList";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const TableroControlContratacionDetalleAgrupadoButton = ({p_unidad_negocio_ingv,p_area_ing,
                                                             p_tipos_de_encuadre_ing,
                                                             p_estado_ing,p_fecha_ing}) => {

    const classes = useStyles()
    const notify = useNotify();
    const refresh = useRefresh();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id_unidad_negocio');

    const [orderEstado, setOrderEstado] = React.useState('-');
    const [orderUnidadNegocio, setOrderUnidadNegocio] = React.useState('↑');
    const [orderContratacionTipo, setOrderContratacionTipo] = React.useState('-');
    const [orderCantidad, setOrderCantidad] = React.useState('-');
    const [orderImporteContratacionPesos, setOrderImporteContratacionPesos] = React.useState('-');



    const [openDetalleAgrupado, setOpenDetalleAgrupado] = React.useState(false);
    const [DetalleAgrupado, setDetalleAgrupado] = React.useState([]);
    const [loadingDetalleAgrupado, setLoadingDetalleAgrupado] = React.useState(true);
    const [errorDetalleAgrupado, setErrorDetalleAgrupado] = React.useState();
    const [TotalDetalleAgrupado, setTotalDetalleAgrupado] = React.useState(0);
    const [EstadoBuscar, setEstadoBuscar] = React.useState('')
    const [EstadoCircularProgress, setEstadoCircularProgress] = React.useState(0)

    const dataProviderDetalleAgrupado = useDataProvider();

    const handleClickOpenDetalleAgrupado = () => {
        dataProviderDetalleAgrupado.getList(
            'TableroControlContratacion',
            {
                filter: {
                    tipografico_ing: 'S', ano_ing: '',
                    unidad_negocio_ingv: p_unidad_negocio_ingv, area_ing: p_area_ing,
                    tipos_de_encuadre_ing: p_tipos_de_encuadre_ing, estado_ing: p_estado_ing, fecha_ing: p_fecha_ing
                },
                sort: {field: orderBy, order: order},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setDetalleAgrupado(data);
                setLoadingDetalleAgrupado(false);
                setOpenDetalleAgrupado(true);
            })
            .catch(error => {
                // setErrorDetalleAgrupado(error);
                setLoadingDetalleAgrupado(false);
            })
        dataProviderDetalleAgrupado.getList(
            'TableroControlContratacion',
            {
                filter: {
                    tipografico_ing: 'T', ano_ing: '',
                    unidad_negocio_ingv: p_unidad_negocio_ingv, area_ing: p_area_ing,
                    tipos_de_encuadre_ing: p_tipos_de_encuadre_ing, estado_ing: p_estado_ing, fecha_ing: p_fecha_ing
                },
                sort: {field: orderBy, order: order},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setTotalDetalleAgrupado(data[0].importe_contratacion_pesos);
                setLoadingDetalleAgrupado(false);
                setOpenDetalleAgrupado(true);
            })
            .catch(error => {
                // setErrorDetalleAgrupado(error);
                setLoadingDetalleAgrupado(false);
            })

        if (loadingDetalleAgrupado) return <Loading/>;                // no cargo todavia

        if (errorDetalleAgrupado) {
            // console.log('error')
            // console.log(errorDetalleAgrupado)
            return <Error/>;                           // error
        }
        ;
    };

    const handleCloseDetalleAgrupado = () => {
        setOpenDetalleAgrupado(false);
        refresh()
    };

    const cambiaBuscar = (event) => {

        const CambiaBuscar = (wp_value) => {
            setEstadoCircularProgress(1)
            // if(wp_value==null){
            //     wp_value=''
            // }
            // wp_value=wp_value.trim()
            // wp_value=wp_value.toUpperCase()

            // console.log('wp_value--------------CambiaFecha')
            // console.log(wp_value)
            setEstadoBuscar(wp_value)
            dataProviderDetalleAgrupado.getList(
                'TableroControlContratacion',
                {
                    filter: {
                        tipografico_ing: 'S', ano_ing: '',
                        unidad_negocio_ingv: p_unidad_negocio_ingv, area_ing: p_area_ing, estadobuscar: wp_value,
                        tipos_de_encuadre_ing: p_tipos_de_encuadre_ing, estado_ing: p_estado_ing, fecha_ing: p_fecha_ing
                    },
                    sort: {field: orderBy, order: order},
                    pagination: {page: 1, perPage: 100},
                },)
                .then(({data}) => {
                    setDetalleAgrupado(data);
                    setLoadingDetalleAgrupado(false);
                    setEstadoCircularProgress(0)
                })
                .catch(error => {
                    // setErrorDetalleAgrupado(error);
                    setLoadingDetalleAgrupado(false);
                })
            dataProviderDetalleAgrupado.getList(
                'TableroControlContratacion',
                {
                    filter: {
                        tipografico_ing: 'T', ano_ing: '',
                        unidad_negocio_ingv: p_unidad_negocio_ingv, area_ing: p_area_ing, estadobuscar: wp_value,
                        tipos_de_encuadre_ing: p_tipos_de_encuadre_ing, estado_ing: p_estado_ing, fecha_ing: p_fecha_ing
                    },
                    sort: {field: orderBy, order: order},
                    pagination: {page: 1, perPage: 100},
                },)
                .then(({data}) => {
                    setTotalDetalleAgrupado(data[0].importe_contratacion_pesos);
                    setLoadingDetalleAgrupado(false);
                    setEstadoCircularProgress(0)
                })
                .catch(error => {
                    // setErrorDetalleAgrupado(error);
                    setLoadingDetalleAgrupado(false);
                })

            if (loadingDetalleAgrupado) return <Loading/>;                // no cargo todavia

            if (errorDetalleAgrupado) {
                // console.log('error')
                // console.log(errorDetalleAgrupado)
                return <Error/>;                           // error
            }
            ;
        };
        var w_value = event.target.value
        CambiaBuscar(w_value)

    }

    const handleChangeOrderBy = (recordbn) => {

        const CambiaOrderBy = (wp_order_nuevo,wp_recordbn) => {
            setEstadoCircularProgress(1)

            // console.log('wp_order_nuevo--------------CambiaFecha')
            // console.log(wp_order_nuevo)
            setOrder(wp_order_nuevo)

            // console.log('wp_recordbn--------------CambiaFecha')
            // console.log(wp_recordbn)
            setOrderBy(wp_recordbn)


            dataProviderDetalleAgrupado.getList(
                'TableroControlContratacion',
                {
                    filter: {
                        tipografico_ing: 'S', ano_ing: '',
                        unidad_negocio_ingv: p_unidad_negocio_ingv, area_ing: p_area_ing, estadobuscar: EstadoBuscar,
                        tipos_de_encuadre_ing: p_tipos_de_encuadre_ing, estado_ing: p_estado_ing, fecha_ing: p_fecha_ing
                    },
                    sort: {field: wp_recordbn, order: wp_order_nuevo},
                    pagination: {page: 1, perPage: 100},
                },)
                .then(({data}) => {
                    setDetalleAgrupado(data);
                    setLoadingDetalleAgrupado(false);
                    setEstadoCircularProgress(0)
                })
                .catch(error => {
                    // setErrorDetalleAgrupado(error);
                    setLoadingDetalleAgrupado(false);
                })
            dataProviderDetalleAgrupado.getList(
                'TableroControlContratacion',
                {
                    filter: {
                        tipografico_ing: 'T', ano_ing: '',
                        unidad_negocio_ingv: p_unidad_negocio_ingv, area_ing: p_area_ing, estadobuscar: EstadoBuscar,
                        tipos_de_encuadre_ing: p_tipos_de_encuadre_ing, estado_ing: p_estado_ing, fecha_ing: p_fecha_ing
                    },
                    sort: {field: wp_recordbn, order: wp_order_nuevo},
                    pagination: {page: 1, perPage: 100},
                },)
                .then(({data}) => {
                    setTotalDetalleAgrupado(data[0].importe_contratacion_pesos);
                    setLoadingDetalleAgrupado(false);
                    setEstadoCircularProgress(0)
                })
                .catch(error => {
                    // setErrorDetalleAgrupado(error);
                    setLoadingDetalleAgrupado(false);
                })

            if (loadingDetalleAgrupado) return <Loading/>;                // no cargo todavia

            if (errorDetalleAgrupado) {
                // console.log('error')
                // console.log(errorDetalleAgrupado)
                return <Error/>;                           // error
            }
            ;
        };

        // console.log(recordbn)
        var w_recordbn = recordbn
        if(w_recordbn==null){
            w_recordbn=''
        }
        w_recordbn=w_recordbn.trim()
        var w_usar_orden
        var w_order_nuevo
        var w_order = order
        if(w_order==null){
            w_order=''
        }
        w_order=w_order.trim()
        if(w_order==='asc'){
            w_usar_orden = '↓'
            w_order_nuevo ='desc'
        }else{
            w_usar_orden = '↑'
            w_order_nuevo = 'asc'
        }
        if(w_recordbn==='estado'){
            setOrderEstado(w_usar_orden);
            setOrderUnidadNegocio('-');
            setOrderContratacionTipo('-');
            setOrderCantidad('-');
            setOrderImporteContratacionPesos('-');
        }
        if(w_recordbn==='id_unidad_negocio'){
            setOrderEstado('-');
            setOrderUnidadNegocio(w_usar_orden);
            setOrderContratacionTipo('-');
            setOrderCantidad('-');
            setOrderImporteContratacionPesos('-');
        }
        if(w_recordbn==='contratacion_tipo'){
            setOrderEstado('-');
            setOrderUnidadNegocio('-');
            setOrderContratacionTipo(w_usar_orden);
            setOrderCantidad('-');
            setOrderImporteContratacionPesos('-');
        }
        if(w_recordbn==='cantidad'){
            setOrderEstado('-');
            setOrderUnidadNegocio('-');
            setOrderContratacionTipo('-');
            setOrderCantidad(w_usar_orden);
            setOrderImporteContratacionPesos('-');
        }
        if(w_recordbn==='importe_contratacion_pesos'){
            setOrderEstado('-');
            setOrderUnidadNegocio('-');
            setOrderContratacionTipo('-');
            setOrderCantidad('-');
            setOrderImporteContratacionPesos(w_usar_orden);
        }

        CambiaOrderBy(w_order_nuevo,w_recordbn)

    };

    // var importe_contratacion_pesos = 0
    //
    // if (TotalDetalleAgrupado.length > 0) {
    //     var untableroList = TotalDetalleAgrupado
    //
    //     const aggregations = untableroList
    //         .reduce(
    //             (stats, order) => {
    //                 stats.importe_contratacion_pesos = order.importe_contratacion_pesos
    //                 // console.log(order)
    //                 return stats;
    //             },
    //             {
    //                 importe_contratacion_pesos: 0,
    //             }
    //         );
    //
    //     importe_contratacion_pesos = aggregations.importe_contratacion_pesos
    // }
    return (
        <div>
            <Button
                label="Detalle Agrupado"
                title="Detalle Agrupado"
                onClick={handleClickOpenDetalleAgrupado}
                style={{color: '#ffffff', backgroundColor: '#123B80'}}
            >
                <div style={{
                    display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                >
                    <GroupWorkIcon style={{color: '#ffffff'}}/>
                    {'Detalle Agrupado'}
                </div>
            </Button>
            <Dialog open={openDetalleAgrupado} maxWidth={'xl'}  aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <div variant="outlined" color="primary"
                            style={{color: '#ffffff', backgroundColor: '#123B80'}}
                    >
                        <div title={'Detalle Agrupado'}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <GroupWorkIcon style={{color: '#ffffff'}}/>
                            {'Detalle Agrupado'}
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {DetalleAgrupado===undefined ? '':
                        <List>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="headline" component="h2">
                                                <InputBase
                                                    placeholder="Buscar…"
                                                    classes={{
                                                        root: classes.inputRoot,
                                                        input: classes.inputInput,
                                                    }}
                                                    value={EstadoBuscar}
                                                    onChange={cambiaBuscar}
                                                    inputProps={{'aria-label': 'search'}}
                                                />

                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="headline" component="h2">
                                                {EstadoCircularProgress === 1 ?
                                                    <div>
                                                        <CircularProgress/>
                                                        {' Buscando Detalle Agrupado'}
                                                    </div>
                                                    : ''
                                                }
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                                {'Estado'}
                                                <Button onClick={() => { handleChangeOrderBy('estado') }}
                                                        variant="contained">{orderEstado}</Button>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                {'Unidad Negocio'}
                                                <Button  onClick={() => { handleChangeOrderBy('id_unidad_negocio') }}
                                                         variant="contained">{orderUnidadNegocio}</Button>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                {'Contratacion Tipo'}
                                                <Button onClick={() => { handleChangeOrderBy('contratacion_tipo') }}
                                                        variant="contained">{orderContratacionTipo}</Button>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                {'Cantidad'}
                                                <Button onClick={() => { handleChangeOrderBy('cantidad') }}
                                                        variant="contained">{orderCantidad}</Button>
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                {'Contratación $'}
                                                <Button onClick={() => { handleChangeOrderBy('importe_contratacion_pesos') }}
                                                        variant="contained">{orderImporteContratacionPesos}</Button>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {DetalleAgrupado.map(record => {
                                    return (
                                        <TableRow
                                            style={{textAlign: 'left',
                                                backgroundColor: record.estado  === undefined || record.estado_color  === undefined ? '' :
                                                    record.estado_color.trim()}}
                                        >
                                            <TableCell className={classes.textleft}>
                                                <Typography>
                                                    {record.estado}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.textleft}>
                                                <Typography>
                                                    {record.unidad_negocio_d}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.textleft}>
                                                <Typography>
                                                    {record.contratacion_tipo}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.textcenter}>
                                                <Typography>
                                                    {record.cantidad === undefined ? '0' :
                                                        record.cantidad.toLocaleString(undefined, {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 16,
                                                        })}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={classes.textright}>
                                                <Typography>
                                                    {record.importe_contratacion_pesos === undefined ? '0,00' :
                                                        record.importe_contratacion_pesos === null ? '0,00' :
                                                        record.importe_contratacion_pesos.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 16,
                                                        })}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.textcenter}>
                                            <Typography>
                                                {''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textcenter}>
                                            <Typography>
                                                {''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textcenter}>
                                            <Typography>
                                                {''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textcenter}>
                                            <Typography>
                                                {'Total: '}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textright}>
                                            <Typography style={{
                                                color: TotalDetalleAgrupado === undefined ? 'white' :
                                                    TotalDetalleAgrupado === null ? '#a7c5f5' :
                                                    TotalDetalleAgrupado === 0 ? '#a7c5f5' : '#4766ff'
                                            }}>
                                                {TotalDetalleAgrupado === undefined ? '' :
                                                    TotalDetalleAgrupado === null ? '0,00' :
                                                    TotalDetalleAgrupado === 0 ? '0,00' :
                                                        TotalDetalleAgrupado.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 16,
                                                        })}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </List>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleCloseDetalleAgrupado}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <ExitToAppIcon style={{color: '#123B80'}}/>
                            Salir
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}

TableroControlContratacionDetalleAgrupadoButton.defaultProps = {
    source: '',
    addLabel: true,
};

export default TableroControlContratacionDetalleAgrupadoButton;