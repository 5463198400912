import * as React from "react";
import {Edit, TextInput, ReferenceInput, AutocompleteInput} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({}));

// export const ProveedorCreate = withStyles(styles)(({classes, ...props}) => (
const ProveedorCreate = props => {
    const classes = useStyles();
    return (
        <Edit {...props}>
            <TextInput label="Proveedor" source="pro"/>
            <TextInput label="Pais" source="identificacion_tributaria_pais_origen"/>
            <ReferenceInput label="Origen Pais" source="pais_codigo_afip_extranjero" reference="pais">
                <AutocompleteInput optionText="pais" formClassName={classes.pais} variant="outlined"
                                   InputLabelProps={{shrink: true}}/>
            </ReferenceInput>
            <TextInput label="Tipo" source="identificacion_tributaria_tipo"/>
            <TextInput label="Número" source="identificacion_tributaria_nro"/>
        </Edit>
    )
};
export default ProveedorCreate;
