import React, {useState, useCallback, useEffect} from 'react';
import {
    useDataProvider,
    TextInput, useGetOne,
    DateInput, useNotify, useRefresh, useMutation,
} from 'react-admin';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
import LineWeightIcon from "@material-ui/icons/LineWeight";
import SaveIcon from '@material-ui/icons/Save';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AddIcon from '@material-ui/icons/Add';
import RequerimientoConceptoBusquedaBtn from "../RequerimientoConcepto/RequerimientoConceptoBusquedaBtn";
import UniMedBusquedaBtn from "../UniMed/UniMedBusquedaBtn";
import IvaTasaTipoBusquedaBtn from "../IvaTasaTipo/IvaTasaTipoBusquedaBtn";
import PropTypes from "prop-types";

var w_apiurlserver = ApiUrlServer
if (w_apiurlserver == null) {
    w_apiurlserver = ''
}
w_apiurlserver = w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },


    requerimiento_concepto: {display: 'inline-block', width: 250, marginLeft: 15},
    clase: {display: 'inline-block', width: 250, marginLeft: 32},
    renglon_d: {display: 'inline-block', width: 800, marginLeft: 32},
    fec_entrega: {display: 'inline-block', width: 150, marginLeft: 32},
    uni_med: {display: 'inline-block', width: 120, marginLeft: 32},
    cantidad: {display: 'inline-block', width: 80, marginLeft: 32},

    moneda: {display: 'inline-block', width: 150, marginLeft: 32},
    cotizacion: {display: 'inline-block', width: 150, marginLeft: 32},
    fecha_cotizacion: {display: 'inline-block', width: 150, marginLeft: 32},
    importe: {display: 'inline-block', width: 150, marginLeft: 32},
    importe2: {display: 'inline-block', width: 150, marginLeft: 16},

    iva: {display: 'inline-block', width: 110, marginLeft: 32},
    btnBusqueda: {
        marginRight: '2rem',
        // width: 40,
        display: 'inline-block',
    },
});

const OrdenCompraDetalleButtonCreate = ({Data}) => {
    console.log("DATA CREATE BTN", Data)
    const dataProviderTableroControl = useDataProvider();
    const [Estadoid, setEstadoid] = useState(0)
    const [Estadoid_orden_compra_cabecera, setEstadoid_orden_compra_cabecera] = useState(0)
    const [Estadoorden_compra_nro, setEstadoorden_compra_nro] = useState(0)
    const [Estadoorden_compra_ampliacion_nro, setEstadoorden_compra_ampliacion_nro] = useState(0)
    const [Estadoitem, setEstadoitem] = useState('')
    const [Estadoconcepto_requerimiento, setEstadoconcepto_requerimiento] = useState('')
    const [Estadoclase, setEstadoclase] = useState('')
    const [Estadorenglon_nro, setEstadorenglon_nro] = useState('')
    const [Estadorenglon_descripcion, setEstadorenglon_descripcion] = useState('')
    const [Estadounidad_medida, setEstadounidad_medida] = useState('')
    const [Estadounidad_medida_abre, setEstadounidad_medida_abre] = useState('')
    const [Estadocantidad, setEstadocantidad] = useState('')
    const [Estadofecha_entrega, setEstadofecha_entrega] = useState('')
    const [Estadomoneda, setEstadomoneda] = useState('')
    const [Estadomoneda_abre, setEstadomoneda_abre] = useState('')
    const [EstadoIdMoneda, setEstadoIdMoneda] = useState(0)
    const [Estadocotizacion, setEstadocotizacion] = useState(Data.moneda_cotizacion)
    const [EstadocotizacionFecha, setEstadocotizacionFecha] = useState(Data.moneda_cotizacion_fecha)
    const [Estadoprecio_unitario_sin_iva, setEstadoprecio_unitario_sin_iva] = useState('')
    const [Estadodescuento_porcentaje, setEstadodescuento_porcentaje] = useState('')
    const [Estadoprecio_unitario_sin_iva_con_dto, setEstadoprecio_unitario_sin_iva_con_dto] = useState('')
    const [Estadoiva_porcentaje, setEstadoiva_porcentaje] = useState('')
    const [Estadoiva_importe, setEstadoiva_importe] = useState('')
    const [Estadototal_item, setEstadototal_item] = useState('')
    const [Estadototal_importe_iva_pesos, setEstadototal_importe_iva_pesos] = useState('')
    const [Estadototal_item_pesos, setEstadototal_item_pesos] = useState('')
    const [Estadofecha_necesidad, setEstadofecha_necesidad] = useState('')
    const [ComboMoneda, setComboMoneda] = useState([]);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        var wr_id = null;
        var wr_id_orden_compra_cabecera = Data.id;
        var wr_orden_compra_nro = Data.orden_compra_nro;
        var wr_orden_compra_ampliacion_nro = Data.orden_compra_ampliacion_nro;
        var wr_item = 'ASIGNAR';
        var wr_concepto_requerimiento = null;
        var wr_clase = null;
        var wr_renglon_nro = null;
        var wr_renglon_descripcion = null;
        var wr_unidad_medida = null;
        var wr_unidad_medida_abre = null;
        var wr_cantidad = 0;
        var wr_fecha_entrega = null;
        var wr_moneda = Data.moneda;
        var wr_id_moneda = Data.id_moneda;
        var wr_cotizacion = Data.moneda_cotizacion;
        var wr_cotizacion_fecha = Data.moneda_cotizacion_fecha;
        var wr_precio_unitario_sin_iva = 0;
        var wr_descuento_porcentaje = 0;
        var wr_precio_unitario_sin_iva_con_dto = 0;
        var wr_iva_porcentaje = 0;
        var wr_iva_importe = 0;
        var wr_total_item = 0;
        var wr_total_importe_iva_pesos = 0;
        var wr_total_item_pesos = 0;
        var wr_fecha_necesidad = null;


        setEstadoid(wr_id);
        setEstadoid_orden_compra_cabecera(wr_id_orden_compra_cabecera);
        setEstadoorden_compra_nro(wr_orden_compra_nro);
        setEstadoorden_compra_ampliacion_nro(wr_orden_compra_ampliacion_nro);
        setEstadoitem(wr_item);
        setEstadoconcepto_requerimiento(wr_concepto_requerimiento);
        setEstadoclase(wr_clase);
        setEstadorenglon_nro(wr_renglon_nro);
        setEstadorenglon_descripcion(wr_renglon_descripcion);
        setEstadounidad_medida(wr_unidad_medida);
        setEstadounidad_medida_abre(wr_unidad_medida_abre);
        setEstadocantidad(wr_cantidad);
        setEstadofecha_entrega(wr_fecha_entrega);
        setEstadomoneda(wr_moneda);
        setEstadoIdMoneda(wr_id_moneda);
        setEstadocotizacion(wr_cotizacion);
        setEstadocotizacionFecha(wr_cotizacion_fecha);
        setEstadoprecio_unitario_sin_iva(wr_precio_unitario_sin_iva);
        setEstadodescuento_porcentaje(wr_descuento_porcentaje);
        setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto);
        setEstadoiva_porcentaje(wr_iva_porcentaje);
        setEstadoiva_importe(wr_iva_importe);
        setEstadototal_item(wr_total_item);
        setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos);
        setEstadototal_item_pesos(wr_total_item_pesos);
        setEstadofecha_necesidad(wr_fecha_necesidad);

        dataProviderTableroControl.getList(
            'MonedaActivo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'S', id: Data.id_moneda},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboMoneda(data);
                setEstadomoneda(data[0].moneda);
                setEstadomoneda_abre(data[0].moneda_abre);

            })
            .catch(error => {
                setOpen(false);
            })

        setOpen(true);
    };

    const handleActualizar = () => {
        setOpen(false);
    };

    function NumberFormatCustom(props) {
        const {inputRef, onChange, ...other} = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={'.'}
                decimalSeparator={','}
                isNumericString
                prefix=""
            />
        );
    }

    NumberFormatCustom.propTypes = {
        inputRef: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,

    };

    const handlefecha_entrega = (event) => {
        const Cambiafecha_entrega1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadofecha_entrega(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiafecha_entrega1(w_value)

    };


    const handlecantidad = (event) => {
        const Cambiacantidad1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadocantidad(wp_value)

            var wh_cantidad = wp_value
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva
            var wh_descuento_porcentaje = Estadodescuento_porcentaje
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva


            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
            }
            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)


            // (a) Cantidad:
            // (b) Precio unitario sin iva:
            // (c) Descuento %:
            // (d) Precio unitario con descuento:   =  round(  b*(1-(c/100)),2)  RO
            // (e) IVA %:
            // (f) IVA Importe:     RO   =  round((a * d) * (e/100),2)

            var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);
            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100;

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiacantidad1(w_value)

    };

    const handleprecio_unitario_sin_iva = (event) => {
        const Cambiaprecio_unitario_sin_iva1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoprecio_unitario_sin_iva(wp_value)

            var wh_cantidad = Estadocantidad
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = wp_value
            var wh_descuento_porcentaje = Estadodescuento_porcentaje
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
            }
            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            // var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)
            var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiaprecio_unitario_sin_iva1(w_value)

    };

    const handleiva_porcentaje = (w_porcentaje) => {
        var wh_cantidad = Estadocantidad;
        var wh_cotizacion = Estadocotizacion;
        var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva;
        var wh_descuento_porcentaje = Estadodescuento_porcentaje;
        var wh_iva_porcentaje = w_porcentaje;

        var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

        if (wh_descuento_porcentaje === 0) {
        } else {
            wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
        }
        wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

        setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

        // var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)
        var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);

        wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

        setEstadoiva_importe(wr_iva_importe)

        var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

        wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

        setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

        var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

        wr_total_item = Math.round(wr_total_item * 100) / 100

        setEstadototal_item(wr_total_item)

        var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

        wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

        setEstadototal_item_pesos(wr_total_item_pesos)
    };

    const handledescuento_porcentaje = (event) => {
        const Cambiadescuento_porcentaje1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadodescuento_porcentaje(wp_value)

            var wh_cantidad = Estadocantidad
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva
            var wh_descuento_porcentaje = wp_value
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
            }
            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);
            // var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiadescuento_porcentaje1(w_value)

    };

    const handleIvaTasaTipo = useCallback(async (who) => {
        setEstadoiva_porcentaje(who.porcentaje);
        handleiva_porcentaje(who.porcentaje);
        // handleiva_porcentaje(who.porcentaje);
    });


    const formatIvaPorcentaje = v => {
        return Estadoiva_porcentaje;
    };

    const handleClose = () => {
        setOpen(false);
    };

    const BotonCrearDetelle = (Data) => {

        var w_mensaje = ' '

        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'create',
                resource: 'OrdenCompraDetalle',
                payload: {
                    id: Estadoid, data: {
                        id_orden_compra_cabecera: Estadoid_orden_compra_cabecera,
                        orden_compra_nro: Estadoorden_compra_nro,
                        orden_compra_ampliacion_nro: Estadoorden_compra_ampliacion_nro,
                        item: Estadoitem,
                        concepto_requerimiento: Estadoconcepto_requerimiento,
                        clase: Estadoclase,
                        renglon_nro: Estadorenglon_nro,
                        renglon_descripcion: Estadorenglon_descripcion,
                        unidad_medida: Estadounidad_medida,
                        unidad_medida_abre: Estadounidad_medida_abre,
                        cantidad: Estadocantidad,
                        id_moneda: EstadoIdMoneda,
                        moneda: Estadomoneda,
                        cotizacion: Estadocotizacion,
                        cotizacion_fecha: EstadocotizacionFecha,
                        precio_unitario_sin_iva: Estadoprecio_unitario_sin_iva,
                        descuento_porcentaje: Estadodescuento_porcentaje,
                        precio_unitario_sin_iva_con_dto: Estadoprecio_unitario_sin_iva_con_dto,
                        iva_porcentaje: Estadoiva_porcentaje,
                        iva_importe: Estadoiva_importe,
                        total_item: Estadototal_item,
                        total_importe_iva_pesos: Estadototal_importe_iva_pesos,
                        total_item_pesos: Estadototal_item_pesos,
                        fecha_entrega: Estadofecha_entrega,
                        fecha_necesidad: Estadofecha_necesidad
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                    // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------

                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle} disabled={loading}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <SaveIcon style={{color: '#004187'}}/>
                    Guardar
                </div>
            </Button>
            // <Button label={w_mensaje}
            //         title={w_mensaje}
            //         onClick={generardetelle} disabled={loading}
            //         color="primary"
            // >
            //     <div>
            //         <LineWeightIcon style={{fontSize: 40, backgroundColor: '#ffffff', color: '#004187'}}/>
            //     </div>
            // </Button>
        );

    };

    // const handleconcepto_requerimiento = (event) => {
    //     const Cambiaconcepto_requerimiento1 = (wp_value) => {
    //         console.log('wp_value')
    //         console.log(wp_value)
    //         setEstadoconcepto_requerimiento(wp_value)
    //     };
    //
    //     var w_value = event.target.value
    //     console.log('w_value')
    //     console.log(w_value)
    //     // if(w_value==null){
    //     //     w_value=''
    //     // }
    //     // w_value=w_value.trim()
    //
    //     Cambiaconcepto_requerimiento1(w_value)
    //
    // };

    const handleconcepto_requerimiento = useCallback(async (who) => {
        setEstadoconcepto_requerimiento(who.requerimiento_concepto);
        setEstadoclase(who.clase);
    });

    const formatEstadoconcepto_requerimiento = v => {
        return Estadoconcepto_requerimiento
    };

    const formatEstadoclase = v => {
        return Estadoclase
    };

    const handlerenglon_nro = (event) => {
        const Cambiarenglon_nro1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadorenglon_nro(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiarenglon_nro1(w_value)

    };

    const handlerenglon_descripcion = (event) => {
        const Cambiarenglon_descripcion1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadorenglon_descripcion(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiarenglon_descripcion1(w_value)

    };

    const handleunidad_medida = useCallback(async (who) => {
        setEstadounidad_medida(who.unidad_medida);
        setEstadounidad_medida_abre(who.unidad_medida_abre);
    });

    const formatEstadounidad_medida_abre = v => {
        return Estadounidad_medida_abre;
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <AddIcon style={{color: '#004187'}}/>
                    Crear
                </div>
            </Button>
            <Dialog open={open}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                // fullScreen={true}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Detalle de Productos/Servicios</DialogTitle>
                <DialogContent>
                    <form>

                        {/*  <TextField label="Item" value={Estadoitem}
                                   inputProps={{readOnly: true}}
                                   style={{textAlign: 'right', margin: '1em'}}
                                   options={{minimumFractionDigits: 0}}/>*/}

                        <div style={{display: 'inline-flex', width: '100%'}}>


                            <TextInput source="requerimiento_concepto"
                                       label={"Concepto Requerimiento"}
                                       fullWidth={true}
                                       className={classes.requerimiento_concepto}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                                       value={Estadoconcepto_requerimiento}
                                       format={formatEstadoconcepto_requerimiento}
                                       required
                            />

                            <TextInput source="clase" label={"Clase"}
                                       fullWidth={true}
                                       className={classes.clase}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                                       value={Estadoclase}
                                       format={formatEstadoclase}
                                       required
                            />

                            <RequerimientoConceptoBusquedaBtn fullWidth={true}
                                                              callbackRequerimientoConcepto={handleconcepto_requerimiento}
                                                              className={classes.btnBusqueda}/>

                        </div>

                        <TextField label="Renglon" value={Estadorenglon_nro}
                                   onChange={handlerenglon_nro} style={{textAlign: 'right', margin: '1em'}}
                                   SelectProps={{native: true,}}
                                   className={classes.renglon}
                                   InputLabelProps={{shrink: true}}
                                   required
                        />

                        <TextField
                            label="Renglon Descripción"
                            value={Estadorenglon_descripcion}
                            onChange={handlerenglon_descripcion}
                            multiline style={{textAlign: 'right', margin: '1em'}}
                            fullWidth
                            SelectProps={{native: true,}}
                            className={classes.renglon_d}
                            InputLabelProps={{shrink: true}}
                            required
                        />

                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput label="Uni.Med."
                                // required
                                       value={Estadounidad_medida_abre}
                                       format={formatEstadounidad_medida_abre}
                                // style={{textAlign: 'right', margin: '1em'}}
                                       className={classes.uni_med}
                                       inputProps={{readOnly: true,}}
                                       SelectProps={{
                                           native: true,
                                       }}
                            />

                            <UniMedBusquedaBtn
                                callbackUniMed={handleunidad_medida}
                                className={classes.btnBusqueda}/>

                            <NumberFormat
                                label="Cantidad"
                                customInput={TextField} value={Estadocantidad}
                                onChange={handlecantidad} className={classes.cantidad}
                                // style={{textAlign: 'right', margin: '1em'}}
                                options={{minimumFractionDigits: 2}}
                                // required
                            />
                            <DateInput
                                label="Fecha de Entrega"
                                source={"fecha_entrega"}
                                className={classes.fec_entrega} fullWidth
                                defaultValue={Estadofecha_entrega}
                                value={Estadofecha_entrega}
                                onChange={handlefecha_entrega}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                // required
                            />


                        </div>


                        <TextField
                            label="Moneda"
                            value={Estadomoneda_abre}
                            inputProps={{readOnly: true}}
                            variant="filled"
                            className={classes.moneda}
                        />

                        <TextField
                            label="Cotización"
                            value={Estadocotizacion}
                            inputProps={{readOnly: true}}
                            variant="filled"
                            className={classes.cotizacion}
                        />

                        <DateInput
                            label="Fecha Cotización"
                            source={"moneda_cotizacion_fecha"}
                            className={classes.fecha_cotizacion} fullWidth
                            // value={EstadocotizacionFecha}
                            inputProps={{readOnly: true}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                            // onChange={(v) => setEstadocotizacionFecha(v.target.value)}

                        />

                        <br/>
                        <br/>
                        <NumberFormat
                            customInput={TextField} label="Precio Unit. Sin IVA"
                            value={Estadoprecio_unitario_sin_iva}
                            onChange={handleprecio_unitario_sin_iva}
                            style={{textAlign: 'right'}}
                            decimalScale={2}
                            options={{minimumFractionDigits: 2}}
                            className={classes.importe}

                        />

                        <TextField
                            label="Porc. Descuento"
                            value={Estadodescuento_porcentaje}
                            onChange={handledescuento_porcentaje}
                            style={{textAlign: 'right'}}
                            className={classes.importe}
                            options={{minimumFractionDigits: 2}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                        />

                        <TextField
                            label="Precio Unit. con Dto"
                            value={Estadoprecio_unitario_sin_iva_con_dto}
                            variant="filled"
                            // style={{textAlign: 'right'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                            className={classes.importe2}
                        />

                        <br/>

                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput
                                label="IVA %"
                                value={Estadoiva_porcentaje}
                                format={formatIvaPorcentaje}
                                inputProps={{readOnly: true}}
                                variant="filled"
                                className={classes.iva}
                                onChange={handleiva_porcentaje}
                            />

                            <IvaTasaTipoBusquedaBtn
                                callbackIvaTasaTipo={handleIvaTasaTipo}
                                className={classes.btnBusqueda}/>

                            <TextField
                                label="IVA Importe"
                                value={Estadoiva_importe}
                                className={classes.importe}
                                variant="filled"
                                style={{textAlign: 'right', margin: '1em'}}
                                InputProps={{
                                    inputComponent: NumberFormatCustom
                                }}
                            />

                            <TextField
                                label="Total Ítem"
                                value={Estadototal_item}
                                name="numberformat"
                                id="importe_presupuesto_pesos_ing"
                                variant="filled"
                                className={classes.importe}
                                style={{textAlign: 'right', margin: '1em'}}
                                InputProps={{
                                    inputComponent: NumberFormatCustom
                                }}
                            />

                        </div>

                        <TextField
                            label="Total IVA $"
                            value={Estadototal_importe_iva_pesos}
                            name="numberformat"
                            id="importe_presupuesto_pesos_ing"
                            variant="filled"
                            className={classes.importe}
                            style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                        />

                        <TextField
                            label="Total Ítem $"
                            value={Estadototal_item_pesos}
                            name="numberformat"
                            id="importe_presupuesto_pesos_ing"
                            variant="filled"
                            className={classes.importe}
                            style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                        />

                    </form>
                </DialogContent>
                <DialogActions>

                    {Estadorenglon_nro &&
                    Estadorenglon_descripcion &&
                    Estadocantidad &&
                    Estadounidad_medida &&
                    Estadoprecio_unitario_sin_iva ?
                        <>
                            <BotonCrearDetelle/>
                        </>
                        :
                        <>
                            <Button disabled
                                    variant="contained"
                                // style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                    className={classes.button}>
                                Guardar
                            </Button>
                        </>
                    }

                    <Button variant="outlined" color="primary" onClick={handleClose}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <CancelPresentationIcon style={{color: '#004187'}}/>
                            Cancelar
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}


export default OrdenCompraDetalleButtonCreate;