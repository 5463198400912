import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField, useNotify, useRefresh, useMutation
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from "@material-ui/icons/Save";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const ContratacionSecuenciaButtonDelete = ({record}) => {
    var wr_id = record.id
    var wr_id_orden_compra_cabecera = record.id_orden_compra_cabecera
    var wrf_secuencia_tipo = record.secuencia_tipo

    if(wrf_secuencia_tipo==null){
        wrf_secuencia_tipo=''
    }
    wrf_secuencia_tipo=wrf_secuencia_tipo.trim()
    var w_usar_boton = 'S'

    if(wrf_secuencia_tipo==='TOTDIAS-NORMAL'){
        w_usar_boton = 'N'
    }

    if(wrf_secuencia_tipo==='TOTDIAS-IMPUGNACION'){
        w_usar_boton = 'N'
    }
    const [open, setOpen] = React.useState(false);

    if (w_usar_boton === 'S') {

        const handleClickOpen = () => {
            setOpen(true);
        };

        const BotonEliminarDetelle = (record) => {

            console.log('BotonEliminarDetelle')

            var w_mensaje = ' '

            const notify = useNotify();
            const refresh = useRefresh();

            const [generardetelle, {loading}] = useMutation({
                    type: 'delete',
                    resource: 'ContratacionSecuencia',
                    payload: {id: wr_id, id_orden_compra_cabecera: wr_id_orden_compra_cabecera}
                },
                {
                    onSuccess: ({data}) => {
                        refresh()
                        notify('(' + wr_id + ') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------
                        setOpen(false);
                    },
                    onFailure: error => {
                        notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                    },
                }
            );


            return (
                <Button variant="outlined" color="primary" onClick={generardetelle}>
                    <div title={''}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                         }}>
                        Si
                    </div>
                </Button>
            );

        };

        const handleSi = () => {

        };

        const handleNo = () => {
            setOpen(false);
        };

        return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    <div title={''}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                         }}>
                        <DeleteForeverIcon style={{color: '#c00000'}}/>
                        Eliminar
                    </div>
                </Button>
                <Dialog open={open} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Detalle de Secuencia de la Contratación</DialogTitle>
                    <DialogContent>
                        ¿Seguro que quiere eliminar este Detalle?
                    </DialogContent>
                    <DialogActions>
                        <BotonEliminarDetelle/>
                        <Button variant="outlined" color="primary" onClick={handleNo}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                 }}>
                                NO
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }else{
        return (
            <div>
            </div>
        );
    }
}

ContratacionSecuenciaButtonDelete.defaultProps = {
    source: '',
    addLabel: true,
};

export default ContratacionSecuenciaButtonDelete;