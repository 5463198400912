import {FormDataConsumer, FormTab, TextInput} from 'react-admin';
import React, {useCallback, useEffect, useState} from "react";
import {useForm, useFormState} from "react-final-form";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PaisLegalBusquedaBtn from "../pais/PaisBusquedaBtn";
import ProvinciaLegalBusquedaBtn from "../Provincia/ProvinciaBusquedaBtn";
import CiudadLegalBusquedaBtn from "../Ciudad/CiudadBusquedaBtn";
import PaisComercialBusquedaBtn from "../pais/PaisBusquedaBtn";

const useStyles = makeStyles((theme) => ({
    pais: {display: 'inline-block', width: 80},
    pais_d: {display: 'inline-block', width: 240, marginLeft: 32},
    provincia: {display: 'inline-block', width: 350},
    provinciaxl: {display: 'inline-block', width: 350},
    partido: {display: 'inline-block', width: 350, marginLeft: 32},
    ciudad: {display: 'inline-block', width: 350, marginLeft: 32},
    btnBusqueda: {
        marginRight: '2rem',
        // width: 40,
        display: 'inline-block',
    },
    calle: {display: 'inline-block', width: 410},
    calle_nro: {display: 'inline-block', width: 90, marginLeft: 32},
    piso: {display: 'inline-block', width: 50, marginLeft: 32},
    departamento: {display: 'inline-block', width: 50, marginLeft: 20},
    codigo_postal: {display: 'inline-block', width: 110, marginLeft: 20},

}));

const ProveedorDomicilios = ({w_pro}) => {
    const {form, change} = useForm();
    const formState = useFormState();
    const classes = useStyles();

    const updateDocumentoTipo = useCallback(async (who) => {
        change("id_documento_tipo", who.id);
        change("documento_tipo_d", who.documento_tipo_d.trim());
    }, [form]);

    const updatePaisLegal = useCallback(async (who) => {
        change("legal_pais", who.pais_codigo_afip);
        change("PaisLegal.pais", who.pais.trim());
        change("legal_provincia", '');
        change("legal_ciudad", '')
    }, [form]);

    const updateProvinciaLegal = useCallback(async (who) => {
        change("legal_provincia", who.provincia);
        if (who.cod_afip.trim() == '901')
            change("legal_ciudad", 'CIUDAD AUTONOMA DE BUENOS AIRES')

    }, [form]);

    const updateCiudadLegal = useCallback(async (who) => {
        change("legal_ciudad", who.ciudad)
    });

    const updatePaisComercial = useCallback(async (who) => {
        change("comercial_pais", who.pais_codigo_afip);
        change("PaisComercial.pais", who.pais.trim());
        change("comercial_provincia", '');
        change("comercial_ciudad", '')
    }, [form]);

    const updateProvinciaComercial = useCallback(async (who) => {
        change("comercial_provincia", who.provincia);
        if (who.cod_afip.trim() == '901')
            change("comercial_ciudad", 'CIUDAD AUTONOMA DE BUENOS AIRES')
    }, [form]);

    const updateCiudadComercial = useCallback(async (who) => {
        change("comercial_ciudad", who.ciudad)
    }, [form]);


    //JN: Al cargar el formulario consulto si el pais esta vacío, propongo Argentina
    useEffect(() => {

        // console.log('formState', formState);
        let pais_legal_initial = formState.values['legal_pais'];
        let pais_comercial_initial = formState.values['comercial_pais'];

        if (pais_legal_initial > 0) {
        } else {
            change("legal_pais", 200);
            change("PaisLegal.pais", 'ARGENTINA');
        }

        if (pais_comercial_initial > 0) {
        } else {
            change("comercial_pais", 200);
            change("PaisComercial.pais", 'ARGENTINA');
        }
    }, [form]);

    return (
        <>
            <FormDataConsumer>
                {({formData, ...rest}) => (
                    <>
                        <Typography variant="h6">Domicilio Legal</Typography>
                        <br/>
                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput source="legal_pais" label={"Cod.Afip"}
                                       fullWidth={true}
                                       className={classes.pais}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="PaisLegal.pais" label={"Pais Legal"}
                                       fullWidth={true}
                                       className={classes.pais_d}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                            />

                            <PaisLegalBusquedaBtn callbackPais={updatePaisLegal} className={classes.btnBusqueda}/>
                        </div>

                        {formData.legal_pais === 200 ?
                            <div style={{display: 'inline-flex', width: '100%'}}>

                                {/*                        <Direccion id_provincia={"id_legal_provincia"} id_ciudad={"id_legal_ciudad"}
                                       partido={"legal_partido"}/>*/
                                }

                                <TextInput source="legal_provincia" label={"Provincia Legal"}
                                           className={classes.provincia}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           fullWidth={true}
                                           InputLabelProps={{shrink: true}}
                                />
                                <ProvinciaLegalBusquedaBtn callbackProvincia={updateProvinciaLegal}
                                                           className={classes.btnBusqueda}
                                />

                                <TextInput source="legal_ciudad" label={"Ciudad Legal"}
                                           className={classes.ciudad}
                                           variant="filled" inputProps={{readOnly: true,}}
                                           fullWidth={true}
                                           InputLabelProps={{shrink: true}}
                                />

                                <CiudadLegalBusquedaBtn callbackCiudad={updateCiudadLegal} fullWidth
                                                        className={classes.btnBusqueda}/>

                                <TextInput source="legal_partido" label={"Partido"} variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.partido} fullWidth
                                />
                            </div>
                            :
                            <>


                                <TextInput source="legal_provincia" label={"Provincia"} variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.provincia} fullWidth
                                />

                                <TextInput source="legal_ciudad" label={"Ciudad"} variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.ciudad} fullWidth
                                />

                                <TextInput source="legal_partido" label={"Partido"} variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.partido} fullWidth
                                />
                            </>
                        }

                        <br/>
                        <TextInput source="legal_calle" label={"Calle"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.calle} fullWidth={true}/>

                        <TextInput source="legal_calle_nro" label={"Nro."} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.calle_nro} fullWidth={true}/>

                        <TextInput source="legal_piso" label={"Piso"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.piso} fullWidth={true}/>

                        <TextInput source="legal_departamento" label={"Depto"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.departamento} fullWidth={true}/>

                        <TextInput source="legal_codigo_postal" label={"Código Postal"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.codigo_postal} fullWidth={true}/>
                        <br/>
                        <Typography variant="h6">Domicilio Comercial</Typography>

                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput source="comercial_pais" label={"Cod.Afip"}
                                       fullWidth={true}
                                       className={classes.pais}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="PaisComercial.pais" label={"Pais Comercial"}
                                       fullWidth={true}
                                       className={classes.pais_d}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                            />


                            <PaisComercialBusquedaBtn callbackPais={updatePaisComercial}
                                                      className={classes.btnBusqueda}/>

                        </div>

                        {formData.comercial_pais === 200 ?
                            <>
                                <div style={{display: 'inline-flex', width: '100%'}}>

                                    <TextInput source="comercial_provincia" label={"Provincia Comercial"}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               className={classes.provincia}
                                               fullWidth={true}
                                               InputLabelProps={{shrink: true}}
                                    />

                                    <ProvinciaLegalBusquedaBtn callbackProvincia={updateProvinciaComercial} fullWidth
                                                               className={classes.btnBusqueda}
                                    />


                                    <TextInput source="comercial_ciudad" label={"Ciudad Comercial"}
                                               className={classes.ciudad}
                                               variant="filled" inputProps={{readOnly: true,}}
                                               fullWidth={true}
                                               InputLabelProps={{shrink: true}}
                                    />

                                    <CiudadLegalBusquedaBtn callbackCiudad={updateCiudadComercial} fullWidth
                                                            className={classes.btnBusqueda}/>

                                    <TextInput source="comercial_partido" label={"Partido"} variant="outlined"
                                               InputLabelProps={{shrink: true}}
                                               className={classes.partido} fullWidth={true}/>
                                </div>
                            </>
                            :
                            <>
                                <TextInput source="comercial_provincia" label={"Provincia"} variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.provincia} fullWidth={true}/>

                                <TextInput source="comercial_ciudad" label={"Ciudad"} variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.ciudad} fullWidth={true}/>

                                <TextInput source="comercial_partido" label={"Partido"} variant="outlined"
                                           InputLabelProps={{shrink: true}}
                                           className={classes.partido} fullWidth={true}/>
                            </>
                        }

                        <br/>
                        <TextInput source="comercial_calle" label={"Calle"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.calle} fullWidth={true}/>
                        <TextInput source="comercial_calle_nro" label={"Nro."} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.calle_nro} fullWidth={true}/>
                        <TextInput source="comercial_piso" label={"Piso"} variant="outlined"
                                   InputLabelProps={{shrink: true}}

                                   className={classes.piso} fullWidth={true}/>
                        <TextInput source="comercial_departamento" label={"Depto"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.departamento} fullWidth={true}/>
                        <TextInput source="comercial_codigo_postal" label={"Código Postal"}
                                   variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.codigo_postal} fullWidth={true}/>

                    </>
                )}
            </FormDataConsumer>
        </>
    );
};

export default ProveedorDomicilios;
