import React, {useState} from "react";
import {FormTab, Show, TextInput, TabbedForm, TopToolbar, NumberInput, EditButton, usePermissions} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    estado_encuadre: {display: 'inline-block', width: 200, marginLeft: 8},
    estado_encuadre_d: {display: 'inline-block', width: 800, marginLeft: 8},
}));

const ContratacionEstadoShow = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    var w_role_auxiliar_editar = ''
    var w_permissions_admuno_auxiliar_editar = permissions
    if(w_permissions_admuno_auxiliar_editar==null){}else{
        var wa_role_auxiliar_editar = w_permissions_admuno_auxiliar_editar.indexOf('ROLE_AUXILIAR_EDITAR')
        if(wa_role_auxiliar_editar===-1){}else{
            w_role_auxiliar_editar = 'ROLE_AUXILIAR_EDITAR'
        }
    }

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            {w_role_auxiliar_editar === 'ROLE_AUXILIAR_EDITAR' &&
                <EditButton basePath={basePath} record={data}/>
            }
        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        return <span>Contratacion Tipo: {record ? `${record.estado_encuadre}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>
                <FormTab label="DATOS CONTRATACION ESTADO">

                    <TextInput
                        label="Código Contratacion Estado"
                        source="estado_encuadre"
                        formClassName={classes.estado_encuadre}
                        placeholder="Ingrese Código Contratacion Estado"
                        helperText="Código Contratacion Estado"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>


                    <TextInput
                        label="Descripción Contratacion Estado"
                        source="estado_encuadre_d"
                        formClassName={classes.estado_encuadre_d}
                        placeholder="Ingrese Descripción Contratacion Estado"
                        helperText="Descripción Contratacion Estado"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>





                </FormTab>
            </TabbedForm>
        </Show>
    )
};
export default ContratacionEstadoShow;


