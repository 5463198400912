import {FormDataConsumer, SelectInput, TextInput} from 'react-admin';
import React, {useCallback} from "react";
import {useForm} from "react-final-form";
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import ProvinciaLegalBusquedaBtn from "../Provincia/ProvinciaBusquedaBtn";

import IvaCombo from "../componentes/IvaCombo";

const useStyles = makeStyles((theme) => ({
    d_iva: {display: 'inline-block', width: 300},
    ibru_exento: {display: 'inline-block', width: 80, marginLeft: 32},
    ibru_tipo: {display: 'inline-block', width: 80, marginLeft: 32},

    ibru_exento_combo: {display: 'inline-block', width: 80},
    ibru_tipo_combo: {display: 'inline-block', width: 160, marginLeft: 112},
    provincia: {display: 'inline-block', width: 120},
    ibru_tipo_d: {display: 'inline-block', width: 240, marginLeft: 32},
    ibru_nro_label: {display: 'inline-block', width: 120},
    ibru_nro: {display: 'inline-block', width: 160, marginLeft: 32},
    ibru_jurisdiccion_provincia: {display: 'inline-block', width: 350},

    es_pyme: {display: 'inline-block', width: 80},
    tiene_personas_con_discapacidad: {display: 'inline-block', width: 190, marginLeft: 32},
    tiene_participacion_estatal: {display: 'inline-block', width: 160, marginLeft: 32},
    tiene_participacion_entidades_publicas_no_estatales: {display: 'inline-block', width: 160, marginLeft: 32},
    registro_proveedor_estado_nro: {display: 'inline-block', width: 160, marginLeft: 32},
}));

const ProveedorCategorizacion = ({w_pro}) => {
    const {form, change} = useForm();
    const classes = useStyles();

    const updateProvinciaJurisdiccion = useCallback(async (who) => {
        change("ibru_jurisdiccion_provincia", who.provincia.trim());
    }, [form]);

    return (
        <>
            <FormDataConsumer>
                {({formData, ...rest}) => (
                    <>
                        <Typography variant="h6">Ingreso Bruto</Typography>

                        <IvaCombo readOnly={false}/>

                        <SelectInput source="ibru_exento"
                                     label="Ibru excento" initialValue={"N"}
                                     className={classes.ibru_exento}
                                     fullWidth={true}
                                     variant="outlined"
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}
                        />

                        <SelectInput source="ibru_tipo" className={classes.ibru_tipo}
                                     variant="outlined" fullWidth label={"Ibru Nro."}
                                     choices={[
                                         {id: 'CL', name: 'Convenio Local'},
                                         {id: 'CM', name: 'Convenio Multilateral'},
                                     ]}/>

                        <TextInput source="ibru_nro" label={"Ibru Nro."} className={classes.ibru_nro} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   fullWidth={true}/>
                        <br/>

                        <div style={{display: 'inline-flex', width: '100%'}}>
                            <TextInput source="ibru_jurisdiccion_provincia" label={"Jurisdicción Provincia"}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       className={classes.ibru_jurisdiccion_provincia} fullWidth
                                       InputLabelProps={{shrink: true}}
                            />

                            <ProvinciaLegalBusquedaBtn callbackProvincia={updateProvinciaJurisdiccion} fullWidth
                                                       className={classes.btnBusqueda}
                            />
                        </div>

                        <br/>

                        <SelectInput source="es_pyme" className={classes.es_pyme} fullWidth={true}
                                     variant="outlined" InputLabelProps={{shrink: true}} initialValue={"N"}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}/>
                        <SelectInput source="tiene_personas_con_discapacidad"
                                     className={classes.tiene_personas_con_discapacidad} fullWidth={true}
                                     variant="outlined" InputLabelProps={{shrink: true}} initialValue={"N"}
                                     label={"Personas con discapacidad"}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}/>

                        <SelectInput source="tiene_participacion_estatal" initialValue={"N"}
                                     className={classes.tiene_participacion_estatal} fullWidth={true}
                                     variant="outlined" InputLabelProps={{shrink: true}}
                                     label={"Part. Estatal"}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}/>

                        <SelectInput source="tiene_participacion_entidades_publicas_no_estatales"
                                     className={classes.tiene_participacion_entidades_publicas_no_estatales}
                                     fullWidth={true} initialValue={"N"}
                                     label={"Part. Publ. No-Estatal"}
                                     variant="outlined" InputLabelProps={{shrink: true}}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}/>

                        <TextInput source="registro_proveedor_estado_nro" label={"Estado Nro."}
                                   variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.registro_proveedor_estado_nro} fullWidth={true}/>

                    </>
                )}
            </FormDataConsumer>
        </>
    );
};

export default ProveedorCategorizacion;
