import React, {useState, useEffect} from 'react'
import {DataGrid, GridToolbarContainer, GridToolbarQuickFilter} from '@mui/x-data-grid';
import {useDataProvider} from 'react-admin';
import {useBuscadorContext} from "../Context/BuscadorProveedorClaseContextProvider";

/// Columnas de la grilla
const columns = [
    {field: 'rubro_d', headerName: 'Seleccionar Rubros', width: 400},
    {field: 'cantidad', headerName: 'Proveedores', width: 250}
];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter/>
        </GridToolbarContainer>
    );
}

const localizedTextsMap = {
    noRowsLabel: 'Sin filas',
    noResultsOverlayLabel: 'No se han encontrado resultados.',
    errorOverlayDefaultLabel: 'Ocurrió un error.',

    // Density selector toolbar button text
    toolbarDensity: 'Densidad',
    toolbarDensityLabel: 'Densidad',
    toolbarDensityCompact: 'Compacto',
    toolbarDensityStandard: 'Estándar',
    toolbarDensityComfortable: 'Cómodo',

    // Columns selector toolbar button text
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Seleccionar columnas',

    // Filters toolbar button text
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarFiltersTooltipHide: 'Ocultar filtros',
    toolbarFiltersTooltipShow: 'Mostrar filtros',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Búsqueda…',
    toolbarQuickFilterLabel: 'Búsqueda',
    toolbarQuickFilterDeleteIconLabel: 'Despejado',

    // Export selector toolbar button text
    toolbarExport: 'Exportar',
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'Descargar como CSV',
    toolbarExportPrint: 'Impresión',
    toolbarExportExcel: 'Descargar como Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Buscar columna',
    columnsPanelTextFieldPlaceholder: 'Título de la columna',
    columnsPanelDragIconLabel: 'Reordenar columna',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',

    // Filter panel text
    filterPanelAddFilter: 'Añadir filtro',
    filterPanelDeleteIconLabel: 'Borrar',
    filterPanelLinkOperator: 'Operador lógico',
    filterPanelOperators: 'Operador', // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor del filtro',

    // Filter operators text
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'es igual',
    filterOperatorStartsWith: 'comienza con',
    filterOperatorEndsWith: 'termina con',
    filterOperatorIs: 'es',
    filterOperatorNot: 'no es',
    filterOperatorAfter: 'es despues',
    filterOperatorOnOrAfter: 'está en o después',
    filterOperatorBefore: 'es antes',
    filterOperatorOnOrBefore: 'está en o antes',
    filterOperatorIsEmpty: 'Está vacío',
    filterOperatorIsNotEmpty: 'No esta vacío',
    filterOperatorIsAnyOf: 'es cualquiera de',

    // Filter values text
    filterValueAny: 'ningún',
    filterValueTrue: 'verdadero',
    filterValueFalse: 'falso',

    // Column menu text
    columnMenuLabel: 'Menú',
    columnMenuShowColumns: 'Mostrar columnas',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Ocultar',
    columnMenuUnsort: 'Desclasificar',
    columnMenuSortAsc: 'Ordenar por ASC',
    columnMenuSortDesc: 'Ordenar por DESC',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,
    columnHeaderFiltersLabel: 'Mostrar filtros',
    columnHeaderSortIconLabel: 'Clasificar',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
            ? `${count.toLocaleString()} filas seleccionadas`
            : `${count.toLocaleString()} fila seleccionada`,

    // Total row amount footer text
    footerTotalRows: 'Filas Totales:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Selección de casilla de verificación',
    checkboxSelectionSelectAllRows: 'Seleccionar todas las filas',
    checkboxSelectionUnselectAllRows: 'Deseleccionar todas las filas',
    checkboxSelectionSelectRow: 'Seleccionar fila',
    checkboxSelectionUnselectRow: 'Deseleccionar fila',

    // Texto de celda booleano
    booleanCellTrueLabel: 'sí',
    booleanCellFalseLabel: 'no',

    // Celda de acciones más texto
    actionsCellMore: 'más',

    // Texto de anclaje de columna
    pinToLeft: 'Alfiler a la izquierda',
    pinToRight: 'Alfiler a derecha',
    unpin: 'Desprender',

    // Tree Data
    treeDataGroupingHeaderName: 'Grupo',
    treeDataExpand: 'ver niños',
    treeDataCollapse: 'ocultar niños',

    // Grouping columns
    groupingColumnHeaderName: 'Grupo',
    groupColumn: (name) => `Agrupar por ${name}`,
    unGroupColumn: (name) => `Deja de agrupar por ${name}`,

    // Master/detail
    detailPanelToggle: 'Alternar panel de detalles',
    expandDetailPanel: 'Expandir',
    collapseDetailPanel: 'Colapsar',

    // Claves de traducción de componentes principales usadas
    MuiTablePagination: {
        labelRowsPerPage: 'Filas por página:',
        labelDisplayedRows: ({ from, to, count }) =>
            `${from} - ${to} de mas de ${count}`,
    },

    // Row reordering text
    rowReorderingHeaderName: 'Reordenación de filas',

    // Aggregation
    aggregationMenuItemHeader: 'Agregación',
    aggregationFunctionLabelSum: 'suma',
    aggregationFunctionLabelAvg: 'promedio',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'Talla',
};

export const BuscarRubrosList = () => {
    const {addRubros, limpiarClases, limpiarRubros} = useBuscadorContext();

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({field: 'rubro_d', order: 'ASC'})
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedRubros, setSelectedRubros] = useState([]);
    const [data, setData] = useState([]);

    const dataProvider = useDataProvider();

    useEffect(() => {
        limpiarClases();
        limpiarRubros();
        setLoading(true);

        dataProvider.getList(
            'Rubro',
            {
                filter: {
                    grilla: 'rubro'
                },
                sort,
                pagination: {page: page, perPage: perPage},
            },)
            .then(({data}) => {
                setData(data);
                setTotal(data.length);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(
                setLoading(false)
            )
    }, [sort]);  /// Cada vez que cambiar el sort, se vuelve a ejecutar la llamada al servidor

    // useEffect(() => {
    //     console.log("total", total);
    // }, [total]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '700px', width: '100%'}}>

            <DataGrid
                disableVirtualization
                keepNonExistentRowsSelected
                checkboxSelection
                pagination
                NoRowsOverlay
                selectionModel={selectedRubros}
                onSelectionModelChange={(e) => {  /// Cuando cambia la selección, guardo en estado
                    setSelectedRubros(e);
                    addRubros(e)
                }}
                rows={data}
                columns={columns}
                components={{Toolbar: CustomToolbar}}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                // components={{
                //     Toolbar: QuickSearchToolbar,
                //     // LoadingOverlay: LinearProgress
                // }}
                loading={loading}
                autoPageSize
                localeText={localizedTextsMap}
            />
        </div>
    )
};

export default BuscarRubrosList