import React from "react";
import * as XLSX from "xlsx";
import {useDataProvider, useListContext, Button} from 'react-admin';
import DownloadIcon from '@mui/icons-material/Download';

const ExportExcelButton = ({total, fileName, sheetName}) => {
    const {filterValues} = useListContext();
    const dataProvider = useDataProvider();

    let w_fileName, w_sheetName;
    fileName ? w_fileName = fileName + ".xlsx" : w_fileName = 'planilla.xlsx';
    sheetName ? w_sheetName = sheetName : w_sheetName = 'Hoja 1';

    const handleClick = () => {
        // Data para exportar
        dataProvider.getList(
            'proveedorClaseBuscador',
            {
                filter: {
                    // clase: clasesList,
                    pais: filterValues.pais,
                    estado_proveedor_abre: filterValues.estadoPro,
                    provincia: filterValues.provincia,
                    razonSocial: filterValues.razonSocial,
                    ciudad: filterValues.ciudad,
                    q: filterValues.q
                },
                sort: {field: 'id', order: 'ASC'},
                pagination: {page: 1, perPage: total},
            },)
            .then(({data}) => {
                downloadExcel(data);
            })
            .catch(error => {
                console.log(error)
            });
    };

    // JN Creo un nuevo JSON sólo con los campos que quiero mostrar en el excel

    // Ajusta el ancho de las columnas automaticamente segun el contenido
    const fitToColumn = data_export => {
        const columnWidths = [];
        for (const property in data_export[0]) {
            columnWidths.push({wch: Math.max(property ? property.toString().length : 0, ...data_export.map(obj => obj[property] ? obj[property].toString().length : 0))});
        }
        return columnWidths;
    };

    const downloadExcel = (data) => {
        let data_export = data.map(({
                                        pro,
                                        razon_social,
                                        documento_tipo_d,
                                        cuit_cuil,
                                        persona_tipo_d,
                                        persona_forma_d,
                                        d_pais_comercial,
                                        provincia_comercial_d,
                                        comercial_partido,
                                        ciudad_comercial_d,
                                        comercial_calle,
                                        comercial_calle_nro,
                                        comercial_piso,
                                        comercial_departamento,
                                        comercial_codigo_postal,
                                        correo_electronico,
                                        telefono_area,
                                        telefono_nro,
                                        codigo_pro_tvpublica,
                                        codigo_pro_rna
                                    }) => ({
            pro,
            razon_social,
            documento_tipo_d,
            cuit_cuil,
            persona_tipo_d,
            persona_forma_d,
            d_pais_comercial,
            provincia_comercial_d,
            comercial_partido,
            ciudad_comercial_d,
            comercial_calle,
            comercial_calle_nro,
            comercial_piso,
            comercial_departamento,
            comercial_codigo_postal,
            correo_electronico,
            telefono_area,
            telefono_nro,
            codigo_pro_tvpublica,
            codigo_pro_rna
        }));

        const worksheet = XLSX.utils.json_to_sheet(data_export);
        worksheet['!cols'] = fitToColumn(data_export);

        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, w_sheetName);
        XLSX.utils.sheet_add_aoa(worksheet, [["Pro", "Razón Social", "Documento Tipo", "Cuit-Cuil", "Persona Tipo", "Persona Forma", "Pais", "Provincia", "Partido", "Ciudad", "Calle", "Nro Calle", "Piso", "Departamento", "Cód. Postal", "Email", "Tel.Cod.Area", "Tel.Número", "Cód. TV Pública", "Cód. RNA"]], {origin: "A1"});

        XLSX.writeFile(workbook, w_fileName);
    };
    return (
        <Button
            onClick={() => handleClick()}
            label="excel"
        >
            <DownloadIcon/>
        </Button>
    )
};

export default ExportExcelButton;
