import React, { Fragment }  from 'react';
import {Button, useNotify, useMutation, useRefresh, useRedirect, useUnselectAll} from 'react-admin';
import FilterListIcon from '@material-ui/icons/FilterList';
import ReplayIcon from '@material-ui/icons/Replay';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const BotonFiltrosUsuComNotificacionTipo = () => {
    const notify = useNotify();
    const redirectTo = useRedirect();
    const refresh = useRefresh()
    const unselectAll = useUnselectAll();

    const [handleClick , { loading }] = useMutation(
        {
            type: 'update',
            resource: 'OrdenCompraCabeceraSeleccionar',
            payload: {id: 1, data: {status: 'BorrarFiltros'}}

        },
        {
            undoable: true,
            onSuccess: ({ data }) => {
                // refresh()
                unselectAll();
                redirectTo(`/UsuComNotificacionTipo?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=25&sort=id`);
                refresh()
                notify('Procesando Borrar Filtros' , 'info', {}, true);
            },
            onFailure: (error) => notify(`Error: ${error.message}`, 'warning'),
        }

    )
    return (
        <Fragment>
            <Button
                label="Borrar Filtros"
                title="Borrar Filtros"
                onClick={handleClick}
                style={{color: '#ffffff', backgroundColor: '#123B80'}}
            >
                <div>
                    <ReplayIcon/>
                    <FilterListIcon/>
                </div>
            </Button>
        </Fragment>
    );
};

export default BotonFiltrosUsuComNotificacionTipo;
