import React from 'react'
import {
    Datagrid,
    EditButton,
    List,
    TextField,
    TextInput,
    Filter,
    DateField,
    NumberInput,
    DateInput, ShowButton, ReferenceArrayInput, SelectArrayInput, TopToolbar, WithPermissions,
    FilterList, FilterListItem
} from 'react-admin';
import ProveedorInfo from "./ProveedorInfo"
import withStyles from "@material-ui/core/styles/withStyles";
import ExportExcelButton from "./ExportExcelButton";
import LinkProveedorDocumentacionMod from "../proveedorDocumentacionMod/LinkProveedorDocumentacionMod";
// import BtnAbrirArbolClasesBuscador from "../ProveedorClaseBuscador/BtnAbrirArbolClasesBuscador";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};


const role_proveedor_consulta_mostrar_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_proveedor_consulta_mostrar = w_permissions_control.indexOf('ROLE_PROVEEDOR_CONSULTA_MOSTRAR')
        if (w_role_proveedor_consulta_mostrar === -1) {
        } else {
            w_role_control = 'ROLE_PROVEEDOR_CONSULTA_MOSTRAR'
        }
    }

    return w_role_control
}

const role_proveedor_consulta_editar_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_proveedor_consulta_editar = w_permissions_control.indexOf('ROLE_PROVEEDOR_CONSULTA_EDITAR')
        if (w_role_proveedor_consulta_editar === -1) {
        } else {
            w_role_control = 'ROLE_PROVEEDOR_CONSULTA_EDITAR'
        }
    }

    return w_role_control
}


const role_proveedor_consulta_documentacion_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_proveedor_consulta_documentacion = w_permissions_control.indexOf('ROLE_PROVEEDOR_CONSULTA_DOCUMENTACION')
        if (w_role_proveedor_consulta_documentacion === -1) {
        } else {
            w_role_control = 'ROLE_PROVEEDOR_CONSULTA_DOCUMENTACION'
        }
    }

    return w_role_control
}

const role_exportar_xls = ({permissions}) => {
    var w_role_control = '';
    var w_permissions_control = permissions;
    if (w_permissions_control == null) {
    } else {
        var w_role_exportar_xls = w_permissions_control.indexOf('ROLE_EXPORTAR_XLS');
        if (w_role_exportar_xls === -1) {
        } else {
            w_role_control = 'ROLE_EXPORTAR_XLS'
        }
    }
    return w_role_control
};

const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <TopToolbar>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <WithPermissions
            render={({permissions}) => (
                role_exportar_xls({permissions}) === 'ROLE_EXPORTAR_XLS'
                    ?
                    <ExportExcelButton total={999999} fileName={"Proveedores"} sheetName={"Proveedores"}/>
                    : null

            )}
        />
    </TopToolbar>
);

const Filtros = (props) => (
    <Filter {...props}>
        <FilterList
            label=""
            // icon={<AttachFileIcon />}
            alwaysOn
        >
            <FilterListItem
                label={ <div title={'Documentación Vigente'}
                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <AttachFileIcon style={{color: '#0fc000'}}/>{'VIGENTE'}
                </div>}
                value={{ hay_documentacion_vencida: 'V' }}
            />
            <FilterListItem
                label={ <div title={'Documentación Vencida'}
                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <AttachFileIcon style={{color: '#c00000'}}/>{'VENCIDA'}
                </div>}
                value={{ hay_documentacion_vencida: 'R' }}
            />
        </FilterList>
        <FilterList
            // label="Has newsletter"
            // icon={<AttachFileIcon />}
            alwaysOn
        >
            <FilterListItem
                label={ <div title={'Sin Documentación'}
                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#1961f3'}}>
                    {'SIN DOCUMENTACIÓN'}
                </div>}
                value={{ hay_adjunto: 'N' }}
            />
            <FilterListItem
                label={ <div title={'Debe Documentación'}
                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#ffc000'}}>
                    {'DEBE DOCUMENTACIÓN'}
                </div>}
                value={{ hay_adjunto: 'A' }}
            />
            <FilterListItem
                label={ <div title={'Presento Documentación'}
                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0fc000'}}>
                    {'PRESENTO DOCUMENTACIÓN'}
                </div>}
                value={{ hay_adjunto: 'V' }}
            />
        </FilterList>

        <TextInput label="Pais" source={"pais"} variant="outlined" style={{width: '120px'}} alwaysOn/>

        {/*<ReferenceArrayInput label="Provincias" reference="Provincia" alwaysOn*/}
        {/*    // onChange={(event => setProvincia(event.target.value))}*/}
        {/*                     allowEmpty style={{minWidth: '200px'}}*/}
        {/*                     source={"provincia"} optionValue={"provincia"} variant="outlined"*/}
        {/*                     sort={{field: 'provincia', order: 'ASC'}}*/}
        {/*>*/}
        {/*    <SelectArrayInput optionText="provincia" InputLabelProps={{shrink: true}}*/}
        {/*    />*/}
        {/*</ReferenceArrayInput>*/}

        <TextInput label="Provincia" source={"provincia"} variant="outlined" alwaysOn/>

        <TextInput label="Ciudad" source={"ciudad"} variant="outlined" alwaysOn/>
        <TextInput label="Razón social" variant="outlined" source="razon_social" alwaysOn/>
        <TextInput label="Buscar" variant="outlined" source="q" alwaysOn/>

        <TextInput label="Nro. Proveedor" variant="outlined" source="proveedor"/>
        <TextInput label="Estado Proveedor" variant="outlined" source="estado_proveedor_abre"/>
        <TextInput label="Tipo Persona" variant="outlined" source="persona_tipo_d"/>
        <TextInput label="Forma Persona" variant="outlined" source="persona_forma_d"/>
        <NumberInput label="Nro. Documento" variant="outlined" source="documento_nro"/>
        <TextInput label="CUIT-CUIL" variant="outlined" source="cuit_cuil"/>

        <TextInput label="IVA" variant="outlined" source="d_iva"/>
        <TextInput label="Es Pyme" variant="outlined" source="es_pyme"/>
        <DateInput label="Fecha Alta - Desde" variant="outlined" source="fecha_alta_desde"/>
        <DateInput label="Fecha Alta - Hasta" variant="outlined" source="fecha_alta_hasta"/>

    </Filter>
);

export const ProveedorList = withStyles(styles)(({classes, permissions, ...props}) => {
    return (
        <>
            <List {...props} title="Listado de Proveedores"
                  bulkActionButtons={false}
                  perPage={10}
                  filters={<Filtros/>}
                  actions={<PostActions/>}
            >

                <Datagrid expand={<ProveedorInfo/>}>
                    <TextField label="Nro. Proveedor" source="pro"/>
                    <TextField label="Razón Social" source="razon_social"/>
                    <TextField label="Tipo Persona" source="persona_tipo_d" sortable={false}/>
                    <TextField label="Forma Persona" source="persona_forma_d" sortable={false}/>
                    <TextField label="Tipo Doc." source="documento_tipo_d" sortable={false}/>
                    {/*<TextField label="Nro. Doc." source="documento_nro" sortable={false} />*/}
                    <TextField label="Cuit / Cuil" source="cuit_cuil" sortable={false}/>
                    <TextField label="IVA" source="d_iva" sortable={false}/>
                    <TextField label="Es Pyme" source="es_pyme" sortable={false}/>
                    <TextField label="Estado Proveedor" source="estado_proveedor_abre" sortable={false}/>

                    <DateField label="Fecha Alta" source="fecha_alta"/>

                    {role_proveedor_consulta_mostrar_permissions({permissions}) === 'ROLE_PROVEEDOR_CONSULTA_MOSTRAR' &&
                    <ShowButton/>}
                    {role_proveedor_consulta_editar_permissions({permissions}) === 'ROLE_PROVEEDOR_CONSULTA_EDITAR' &&
                    <EditButton/>}
                    {role_proveedor_consulta_documentacion_permissions({permissions}) === 'ROLE_PROVEEDOR_CONSULTA_DOCUMENTACION' &&
                    <LinkProveedorDocumentacionMod/>}
                </Datagrid>
            </List>
        </>
    )
});


export default ProveedorList;
