import React, {useState} from 'react'
import {
    Loading, useQuery, Datagrid, TextField, Pagination, Button, useDelete, useRefresh, ListContextProvider
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import IconEliminar from '@material-ui/icons/Delete';


export const ProveedorClasesGrid = (props) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(25);
    const [sort, setSort] = useState({field: 'clase', order: 'ASC'})


    // Get Data
    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'ProveedorClase',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: props.pro,
        }
    });
    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    // Eliminar Fila
    const BtnEliminar = ({record}) => {
        const refresh = useRefresh();
        const [deleteOne, {isLoading, error}] = useDelete(
            'clase',
            record.id
        );
        const handleClick = () => {
            deleteOne();
            refresh();
        };
        if (error) {
            return <p>ERROR</p>;
        }
        return <Button variant="text" size={"small"} color="primary"
                       onClick={handleClick}
                       disabled={loading}
                       label={'Eliminar'}
        >
            <IconEliminar style={{color: 'red'}}/>
        </Button>


    };

    return (
        <ListContextProvider value={{data, total, page, perPage, setPage, sort}}>
            <Datagrid
                data={keyBy(data, 'id')}
                ids={data.map(({id}) => id)}
                currentSort={sort}
                setSort={(field, order) => setSort({field, order})}
            >
                <TextField source="clase" label={"Clase"}/>
                <TextField source="clase_d" label={"Descripción"} sortable={false}/>
                <TextField source="rubro_d" label={"Rubro"} sortable={false}/>
                <BtnEliminar/>

            </Datagrid>
            <Pagination
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                total={total}
            />

        </ListContextProvider>
    );
};

export default ProveedorClasesGrid;



