import React, {Fragment} from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BotonFiltrosUsuComNotificacionTipo from "./BotonFiltrosUsuComNotificacionTipo";

const drawerWidth = 500;
const drawerLineWeight = 400;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        height: drawerWidth,
    },
    drawerPaperLineWeight: {
        height: drawerLineWeight,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const role_admuno_calendario_menu = ({permissions}) => {
    var w_role_calendario_menu = ''
    var w_permissions_calendario_menu = permissions
    if(w_permissions_calendario_menu==null){}else{
        var w_role_admuno_calendario_menu = w_permissions_calendario_menu.indexOf('ROLE_ADMUNO_CALENDARIO_MENU')
        if(w_role_admuno_calendario_menu===-1){}else{
            w_role_calendario_menu = 'ROLE_ADMUNO_CALENDARIO_MENU'
        }
    }
    return w_role_calendario_menu
}

const Vertitle = () => {
    const classes = useStyles();
    const theme = useTheme();
    const [openLineWeight, setOpenLineWeight] = React.useState(false);

    const handleDrawerLineWeightOpen = () => {
        setOpenLineWeight(true);
    };

    const handleDrawerLineWeightClose = () => {
        setOpenLineWeight(false);
    };


    return (
        <CardContent>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="headline" component="h2">
                                <BotonFiltrosUsuComNotificacionTipo/>
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="headline" component="h2">
                                {'Usuario de Notificacion'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="headline" component="h2">

                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
        </CardContent>
    )
}

export default Vertitle