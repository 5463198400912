import React from 'react';
import {Link} from 'react-admin';
import Button from '@material-ui/core/Button';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const LinkProveedorDocumentacionMod = ({record}) => {

    var w_hay_adjunto = record.hay_adjunto
    if(w_hay_adjunto==null){
        w_hay_adjunto=''
    }
    w_hay_adjunto=w_hay_adjunto.trim()

    var w_hay_documentacion_vencida = record.hay_documentacion_vencida
    if(w_hay_documentacion_vencida==null){
        w_hay_documentacion_vencida=''
    }
    w_hay_documentacion_vencida=w_hay_documentacion_vencida.trim()


    return (
        <Link to={`/proveedorDocumentacionMod/${record.id}`}>
            <Button
                title={w_hay_adjunto===undefined ||
                    w_hay_adjunto===null ? '' :
                        w_hay_adjunto.trim()==='V' ? 'Presento Documentación' :
                            w_hay_adjunto.trim()==='A' ? 'Debe Documentación' : 'Sin Documentación'
                }
                style={{color: w_hay_adjunto===undefined ||
                    w_hay_adjunto===null ? '' :
                        w_hay_adjunto.trim()==='V' ? '#0fc000' :
                            w_hay_adjunto.trim()==='A' ? '#ffc000' : '',
                }}
                color="primary"
                size={"small"}
                startIcon={
                    <AttachFileIcon
                        titleAccess={w_hay_documentacion_vencida===undefined ||
                            w_hay_documentacion_vencida===null ? '' :
                                w_hay_documentacion_vencida.trim()==='V' ? 'Documentación Vigente' :
                                    w_hay_documentacion_vencida.trim()==='R' ? 'Documentación Vencida' : ''
                        }
                        style={{color: w_hay_documentacion_vencida===undefined ||
                            w_hay_documentacion_vencida===null ? '' :
                                w_hay_documentacion_vencida.trim()==='V' ? '#0fc000' :
                                    w_hay_documentacion_vencida.trim()==='R' ? '#c00000' : '',
                        }}
                    />
                }
            >
                Documentación
            </Button>
        </Link>
    );
}

LinkProveedorDocumentacionMod.defaultProps = {
    source: '',
    addLabel: true,
};

export default LinkProveedorDocumentacionMod;