import {SelectInput, ReferenceInput, FormDataConsumer, TextInput, NumberInput, DateInput, FormTab} from 'react-admin';
import React, {useCallback, useState} from "react";
import {useForm} from "react-final-form";
import {makeStyles} from "@material-ui/core";
import ProveedorBusquedaBtn from "../proveedor/ProveedorBusquedaBtn";
import Typography from "@material-ui/core/Typography";
import PaisComercialBusquedaBtn from "../pais/PaisBusquedaBtn";
import ProvinciaLegalBusquedaBtn from "../Provincia/ProvinciaBusquedaBtn";
import CiudadLegalBusquedaBtn from "../Ciudad/CiudadBusquedaBtn";
import ComboMonedaActivo from "../componentes/ComboMonedaActivo";
import ComboIvaResponsable from "../componentes/ComboIvaResponsable";
import PersonaTipoPersonaForma from "../componentes/PersonaTipoPersonaForma/PersonaTipoPersonaForma";
import DocumentoTipoBusquedaBtn from "../DocumentoTipo/DocumentoTipoBusquedaBtn";
import CuitCuil from "../componentes/CuitCuil/CuitCuil";

const useStyles = makeStyles((theme) => ({

    proveedor: {display: 'inline-block', width: 120},
    razon_social: {display: 'inline-block', width: 400},
    razon_social_fantasia: {display: 'inline-block', width: 400, marginLeft: 32},

    persona_tipo: {display: 'inline-block', width: 50},
    persona_tipo_d: {display: 'inline-block', width: 250, marginLeft: 32},

    persona_forma_d: {display: 'inline-block', width: 250, marginLeft: 32},

    id_documento_tipo: {display: 'inline-block', width: 80},
    documento_tipo: {display: 'inline-block', width: 200, marginLeft: 32},
    documento_nro: {display: 'inline-block', width: 138, marginLeft: 32},
    cuit_cuil: {display: 'inline-block', width: 200, marginLeft: 32},

    email: {display: 'inline-block', width: 250},
    emailAlt: {display: 'inline-block', width: 250, marginLeft: 32},

    area: {display: 'inline-block', width: 50},
    nro: {display: 'inline-block', marginLeft: 32, width: 168,},
    area2: {display: 'inline-block', width: 50, marginLeft: 32},

}));

const ProveedorDatosEmpresa = ({w_pro}) => {
    const {form, change} = useForm();
    const classes = useStyles();
    const [PersonaTipo, setPersonaTipo] = useState('');

    const updateDocumentoTipo = useCallback(async (who) => {
        change("id_documento_tipo", who.id);
        change("documento_tipo", who.documento_tipo);
        change("DocumentoTipo.documento_tipo_d", who.documento_tipo_d.trim());
    }, [form]);

    return (
        <>
            <FormDataConsumer>
                {({formData, ...rest}) => (
                    <>
                        <TextInput source="pro" label={"Nro. Proveedor"} className={classes.proveedor}
                                   inputProps={{readOnly: true}}
                                   variant="filled" fullWidth={true}
                        />
                        <br/>

                        <PersonaTipoPersonaForma setPersonaTipo={setPersonaTipo} isReadonly={false}/>

                        <br/>

                        <TextInput source="razon_social" label={"Razón Social"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.razon_social} fullWidth={true}/>

                        <TextInput source="razon_social_fantasia" label={"Razón Social Fantasía"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.razon_social_fantasia} fullWidth={true}/>
                        <br/>

                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput source="documento_tipo" label={"Doc."}
                                       variant="filled" inputProps={{readOnly: true}}
                                       className={classes.id_documento_tipo}
                                       fullWidth hidden
                                       InputLabelProps={{shrink: true}}
                            />

                            <TextInput source="DocumentoTipo.documento_tipo_d" label={"Tipo Doc."}
                                       variant="filled" inputProps={{readOnly: true}}
                                       className={classes.documento_tipo}
                                       fullWidth
                                       InputLabelProps={{shrink: true}}
                            />

                            <DocumentoTipoBusquedaBtn callbackDocumentoTipo={updateDocumentoTipo} fullWidth
                                                      className={classes.btnBusqueda}
                            />

                            <TextInput source="documento_nro" label={"Nro. Doc."} variant="outlined"
                                       InputLabelProps={{shrink: true}}
                                       className={classes.documento_nro} fullWidth={true}/>
                        </div>

                        <CuitCuil personaTipo={PersonaTipo} isReadonly={false} validarExistenciaBD={true} pro={w_pro}/>

                        <br/>

                        <TextInput source="correo_electronico" label={"Email"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.email} fullWidth={true}/>

                        <TextInput source="correo_electronico_alternativo" label={"Email alternativo"}
                                   variant="outlined" InputLabelProps={{shrink: true}}
                                   className={classes.emailAlt} fullWidth={true}/>
                        <br/>
                        <TextInput source="telefono_area" label={"Tel Área"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.area}
                                   fullWidth={true}/>

                        <TextInput source="telefono_nro" label={"Tel Nro"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro}
                                   fullWidth={true}/>


                        <TextInput source="telefono_alternativo_area" label={"T.Área Alt."} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.area2} fullWidth={true}/>

                        <TextInput source="telefono_alternativo_nro" label={"Tel Nro Alt."} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro} fullWidth={true}/>
                        <br/>
                        <TextInput source="celular_area" label={"Cel Área"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.area}
                                   fullWidth={true}/>

                        <TextInput source="celular_nro" label={"Tel Nro"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro}
                                   fullWidth={true}/>

                        <TextInput source="fax_area" label={"Fax Área"} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.area2}
                                   fullWidth={true}/>

                        <TextInput source="fax_nro" label={"Fax Nro."} variant="outlined"
                                   InputLabelProps={{shrink: true}}
                                   className={classes.nro}
                                   fullWidth={true}/>


                    </>
                )}
            </FormDataConsumer>

        </>
    );
};

export default ProveedorDatosEmpresa;
