import React from 'react';
import {Link} from 'react-admin';
import VisibilityIcon from '@material-ui/icons/Visibility';

const LinkMostrarButtonVisita = ({record}) => {
    return (
        <Link to={`/ContratacionEncuadre/${record.id}/show/1`}>
            <div title={''}
                 style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#123B80'}}
            >
                <VisibilityIcon style={{fontSize: 20}}/> Mostrar
            </div>
        </Link>
    );
}

LinkMostrarButtonVisita.defaultProps = {
    source: '',
    addLabel: true,
};

export default LinkMostrarButtonVisita;