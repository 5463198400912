import React, {useState} from 'react'
import {
    TextInput,
    Filter, FormTab, Loading, DateInput
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


export const ProveedorPersonaJuridica = (props) => {

    const useStyles = makeStyles({

        // Proveedor Persona Jutidica
        constitucion_fecha: {display: 'inline-block', width: 200},
        constitucion_lugar: {display: 'inline-block', width: 200, marginLeft: 32},
        constitrucion_dato_registral: {display: 'inline-block', width: 200, marginLeft: 32},
        registro_publico_comercio_incripcion_nro: {display: 'inline-block', width: 200},
        inspeccion_general_justicia_inscripcion_nro: {display: 'inline-block', width: 200, marginLeft: 32},

    });
    const classes = useStyles();
    const isReadonly = props.readOnly;
    return (

        <div>
            <Typography variant="h6">Persona Jurídica</Typography>

            <DateInput source="PersonaJuridica.constitucion_fecha" label={"Fecha"} variant="outlined"
                       inputProps={{readOnly: isReadonly}}
                       className={classes.constitucion_fecha} fullWidth={true} InputLabelProps={{shrink: true}} />

            <TextInput source="PersonaJuridica.constitucion_lugar" label={"Lugar"} variant="outlined"
                       inputProps={{readOnly: isReadonly}}
                       className={classes.constitucion_lugar} fullWidth={true} InputLabelProps={{shrink: true}} />

            <TextInput source="PersonaJuridica.constitrucion_dato_registral" label={"Dato Registral"} variant="outlined"
                       inputProps={{readOnly: isReadonly}}
                       className={classes.constitrucion_dato_registral} fullWidth={true} InputLabelProps={{shrink: true}} />
            <br />
            <TextInput source="PersonaJuridica.registro_publico_comercio_incripcion_nro" label={"Comercio Inscripción Nro."}
                       inputProps={{readOnly: isReadonly}}
                       variant="outlined"
                       className={classes.registro_publico_comercio_incripcion_nro} fullWidth={true} InputLabelProps={{shrink: true}} />

            <TextInput source="PersonaJuridica.inspeccion_general_justicia_inscripcion_nro"
                       inputProps={{readOnly: isReadonly}}
                       label={"General Justicia Inscripción Nro."} variant="outlined"
                       className={classes.inspeccion_general_justicia_inscripcion_nro} fullWidth={true} InputLabelProps={{shrink: true}} />
        </div>

    );
};

export default ProveedorPersonaJuridica;



