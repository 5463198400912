import React from 'react'
import {
    Datagrid,
    List,
    TextField,
    TextInput,
    Filter,
    TopToolbar,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    Link, WithPermissions
} from 'react-admin';
import ProveedorInfo from ".././proveedor/ProveedorInfo"
import withStyles from "@material-ui/core/styles/withStyles";
import ExportExcelButton from "./ExportExcelButton";
// import BtnAbrirArbolClasesBuscador from "../ProveedorClaseBuscador/BtnAbrirArbolClasesBuscador";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};

const role_proveedor_consulta_mostrar_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if (w_permissions_control == null) {
    } else {
        var w_role_proveedor_consulta_mostrar = w_permissions_control.indexOf('ROLE_PROVEEDOR_CONSULTA_MOSTRAR')
        if (w_role_proveedor_consulta_mostrar === -1) {
        } else {
            w_role_control = 'ROLE_PROVEEDOR_CONSULTA_MOSTRAR'
        }
    }

    return w_role_control
};

const role_exportar_xls = ({permissions}) => {
    var w_role_control = '';
    var w_permissions_control = permissions;
    if (w_permissions_control == null) {
    } else {
        var w_role_exportar_xls = w_permissions_control.indexOf('ROLE_EXPORTAR_XLS');
        if (w_role_exportar_xls === -1) {
        } else {
            w_role_control = 'ROLE_EXPORTAR_XLS'
        }
    }
    return w_role_control
};

export const ProveedorClaseBuscadorList = withStyles(styles)(({classes, permissions, ...props}) => {

    const Filtros = (props) => (
        <Filter {...props}>
            <TextInput label="Pais" source={"pais"} variant="outlined" style={{width: '120px'}} alwaysOn/>
            <ReferenceArrayInput label="Provincias" reference="Provincia" alwaysOn
                                 allowEmpty style={{width: '120px', minWidth: '200px'}}
                                 source={"provincia"} optionValue={"id"} variant="outlined"
                                 sort={{field: 'provincia', order: 'ASC'}}
            >
                <SelectArrayInput optionText="provincia" InputLabelProps={{shrink: true}}
                />
            </ReferenceArrayInput>

            <TextInput label="Ciudad" source={"ciudad"} variant="outlined" style={{width: '120px'}} alwaysOn/>
            <TextInput label="Razón social" variant="outlined" source="razonSocial" alwaysOn style={{width: '120px'}}/>

            <ReferenceInput label="Estado Pro." source="estado_proveedor_abre" reference="estadoProveedor" alwaysOn
                            variant="outlined" allowEmpty style={{width: '120px'}}
            >
                <SelectInput optionText="estado_proveedor_abre"
                             style={{marginLeft: '32px', minWidth: '120px', maxWidth: '150px'}}
                />
            </ReferenceInput>

            <TextInput label="Buscar" variant="outlined" source="q" alwaysOn style={{width: '120px'}}/>
        </Filter>
    );

    const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
        <TopToolbar>
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <WithPermissions
                render={({permissions}) => (
                    role_exportar_xls({permissions}) === 'ROLE_EXPORTAR_XLS'
                        ?
                        <ExportExcelButton total={999999} fileName={"Proveedores"} sheetName={"Proveedores"}/>
                        : null

                )}
            />
        </TopToolbar>
    );


    const LinkMostrarButton = ({record}) => {
        return (
            <Link to={`/proveedor/${record.id}/show`}>
                <div title={''}
                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                >
                    Mostrar
                </div>
            </Link>
        );
    };


    return (
        <List {...props} title="Listado de Proveedores"
              bulkActionButtons={false}
              perPage={10}
              filters={<Filtros/>}
              actions={<PostActions/>}
        >

            <Datagrid expand={<ProveedorInfo/>}>
                <TextField label="Nro. Proveedor" source="pro"/>
                <TextField label="Razón Social" source="razon_social"/>
                {/*<TextField label="Tipo Persona" source="persona_tipo_d" sortable={false}/>*/}
                <TextField label="Tipo Doc." source="documento_tipo_d" sortable={false}/>
                <TextField label="Cuit / Cuil" source="cuit_cuil" sortable={false}/>
                <TextField label="IVA" source="d_iva" sortable={false}/>
                <TextField label="Estado Proveedor" source="estado_proveedor_abre" sortable={false}/>
                <TextField label="Provincia" source="provincia_comercial_d" sortable={false}/>
                <TextField label="Ciudad" source="ciudad_comercial_d" sortable={false}/>
                <TextField label="Teléfono" source="telefono_nro" sortable={false}/>
                <TextField label="Email" source="correo_electronico" sortable={false}/>

                {role_proveedor_consulta_mostrar_permissions({permissions}) === 'ROLE_PROVEEDOR_CONSULTA_MOSTRAR' &&
                <LinkMostrarButton/>
                }

            </Datagrid>
        </List>
    )
});


export default ProveedorClaseBuscadorList;
