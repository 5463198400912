import React, {useState} from "react";

import {Show, TabbedForm, TextField} from 'react-admin';

const ProveedorShow = props => {
    // const [Proveedor, setProveedor] = useState('');
    // const [PersonaTipo, setPersonaTipo] = useState('');

    return (
        <Show {...props}>
            <TabbedForm>
                <TextField label="Proveedor" source="pro" sortable={false}/>
                <TextField label="Pais" source="identificacion_tributaria_pais_origen" sortable={false}/>
                <TextField label="Tipo" source="identificacion_tributaria_tipo" sortable={false}/>
                <TextField label="Número" source="identificacion_tributaria_nro" sortable={false}/>
            </TabbedForm>
        </Show>
    )
};
export default ProveedorShow;


