import React, {useEffect, useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput,
    NumberField,
    Datagrid,
    DateInput,
    SaveButton,
    Link,
    useDataProvider,
    useNotify,
    useRefresh,
    useMutation,
    useRedirect, SimpleForm, BooleanInput, email, PasswordInput, SelectInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from "@material-ui/core/FormHelperText";
import NumberFormat from 'react-number-format';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import PropTypes from 'prop-types';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import EmailIcon from "@material-ui/icons/Email";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DialpadIcon from "@material-ui/icons/Dialpad";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BotonVerUsuarioaCopiar from "./BotonVerUsuarioaCopiar"
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
    username: {display: 'inline-block', width: 400, marginLeft: 8},
    password: {display: 'inline-block', width: 400, marginLeft: 8},
    version: {display: 'inline-block', width: 100, marginLeft: 8},
    account_expired: {display: 'inline-block', width: 200, marginLeft: 8},
    account_locked: {display: 'inline-block', width: 200, marginLeft: 8},
    enabled: {display: 'inline-block', width: 200, marginLeft: 8},
    password_expired: {display: 'inline-block', width: 200, marginLeft: 8},
    es_administrador: {display: 'inline-block', width: 100, marginLeft: 8},
    whatsapp_nro: {display: 'inline-block', width: 800, marginLeft: 8},
    email: {display: 'inline-block', width: 800, marginLeft: 8},
    enviar_whatsapp: {display: 'inline-block', width: 200, marginLeft: 8},
    enviar_correo: {display: 'inline-block', width: 200, marginLeft: 208},
    enviar_notificacion: {display: 'inline-block', width: 200, marginLeft: 208},
}));

const PostCreateToolbar = props => {

    return (
        <Toolbar {...props} >
            <SaveButton
                label="Copiar Usuario"
                transform={transform}
                submitOnEnter={false}
                icon={<FileCopyIcon />}
            />
            <Grid container spacing={3}>
                <Grid item xs>

                </Grid>
                <Grid item xs>
                    <Link to={`/UserUsuCom/`}>
                        <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <Button
                                label="Salir"
                            >
                                <ExitToAppIcon/> Salir
                            </Button>
                        </div>
                    </Link>
                </Grid>
            </Grid>
        </Toolbar>
    )

};

const transform = data => {
    return {
        ...data
    }
};

const validateCreate = (values) => {
    const errors = {};

    var w_estado_encuadre = values.estado_encuadre

    if (w_estado_encuadre===undefined) {
        errors.estado_encuadre = ['El campo Código es requerido'];
    }else {
        if (w_estado_encuadre === null) {
            errors.estado_encuadre = ['El campo Código es requerido'];
        } else {
            w_estado_encuadre = w_estado_encuadre.trim()
            if (w_estado_encuadre === '') {
                errors.estado_encuadre = ['El campo Código es requerido'];
            }
        }
    }


    var w_estado_encuadre_d = values.estado_encuadre_d

    if (w_estado_encuadre_d===undefined) {
        errors.estado_encuadre_d = ['El campo Descripción Copiar Usuario Estado es requerido'];
    }else {
        if (w_estado_encuadre_d === null) {
            errors.estado_encuadre_d = ['El campo Descripción Copiar Usuario Estado es requerido'];
        } else {
            w_estado_encuadre_d = w_estado_encuadre_d.trim()
            if (w_estado_encuadre_d === '') {
                errors.estado_encuadre_d = ['El campo Descripción Copiar Usuario Estado es requerido'];
            }
        }
    }




    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/UserUsuCom`;

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix=""
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const UserCopyCreate = props => {
    const classes = useStyles();

    var dateFechaEncuadre = new Date();
    var duedateFechaEncuadre = new Date(dateFechaEncuadre);
    duedateFechaEncuadre.setDate(dateFechaEncuadre.getDate());
    var dFechaEncuadre = new Date(duedateFechaEncuadre || Date.now()),
        monthFechaEncuadre = '' + (dFechaEncuadre.getMonth() + 1),
        dayFechaEncuadre = '' + (dFechaEncuadre.getDate()),
        yearFechaEncuadre = dFechaEncuadre.getFullYear();

    //console.log(month)

    if (monthFechaEncuadre.length < 2) monthFechaEncuadre = '0' + monthFechaEncuadre;
    if (dayFechaEncuadre.length < 2) dayFechaEncuadre = '0' + dayFechaEncuadre;

    // var w_f_carga1FechaEncuadre = [dayFechaEncuadre, monthFechaEncuadre, yearFechaEncuadre].join('/');
    var w_f_carga2FechaEncuadre = [yearFechaEncuadre, monthFechaEncuadre, dayFechaEncuadre].join('-');

    var dateMonedaCotizacionFecha = new Date();
    var duedateMonedaCotizacionFecha = new Date(dateMonedaCotizacionFecha);
    duedateMonedaCotizacionFecha.setDate(dateMonedaCotizacionFecha.getDate()-1);
    var dMonedaCotizacionFecha = new Date(duedateMonedaCotizacionFecha || Date.now()),
        monthMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getMonth() + 1),
        dayMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getDate()),
        yearMonedaCotizacionFecha = dMonedaCotizacionFecha.getFullYear();

    //console.log(month)

    if (monthMonedaCotizacionFecha.length < 2) monthMonedaCotizacionFecha = '0' + monthMonedaCotizacionFecha;
    if (dayMonedaCotizacionFecha.length < 2) dayMonedaCotizacionFecha = '0' + dayMonedaCotizacionFecha;

    // var w_f_carga1MonedaCotizacionFecha = [dayMonedaCotizacionFecha, monthMonedaCotizacionFecha, yearMonedaCotizacionFecha].join('/');
    var w_f_carga2MonedaCotizacionFecha = [yearMonedaCotizacionFecha, monthMonedaCotizacionFecha, dayMonedaCotizacionFecha].join('-');

    const dataProviderTableroControl = useDataProvider();
    const [EstadoUserCopy, setEstadoUserCopy] = useState(1);
    const [Estadofecha_encuadre, setEstadofecha_encuadre] = useState(w_f_carga2FechaEncuadre)
    const [errorfecha_encuadre, setErrorfecha_encuadre] = useState(false);
    const [helperTextfecha_encuadre, setHelperTextfecha_encuadre] = useState('');
    const [Estadoexpediente_referencia_gde, setEstadoexpediente_referencia_gde] = useState('')
    const [Estadocontratacion_tipo, setEstadocontratacion_tipo] = useState('')
    const [Estadocontratacion_ano, setEstadocontratacion_ano] = useState(yearFechaEncuadre)
    const [Estadomoneda, setEstadomoneda] = useState('PES')
    const [Estadomoneda_cotizacion, setEstadomoneda_cotizacion] = useState(1)
    const [EstadoreadOnlymoneda_cotizacion, setEstadoreadOnlymoneda_cotizacion] = useState(true);
    const [Estadovariantmoneda_cotizacion, setEstadovariantmoneda_cotizacion] = useState('filled');
    const [Estadomoneda_cotizacion_fecha, setEstadomoneda_cotizacion_fecha] = useState(w_f_carga2MonedaCotizacionFecha)
    const [EstadoreadOnlymoneda_cotizacion_fecha, setEstadoreadOnlymoneda_cotizacion_fecha] = useState(true);
    const [Estadovariantmoneda_cotizacion_fecha, setEstadovariantmoneda_cotizacion_fecha] = useState('filled');
    const [errormoneda_cotizacion_fecha, setErrormoneda_cotizacion_fecha] = useState(false);
    const [helperTextmoneda_cotizacion_fecha, setHelperTextmoneda_cotizacion_fecha] = useState('');
    const [Estadoimporte_presupuesto, setEstadoimporte_presupuesto] = useState(0)
    const [Estadoimporte_presupuesto_pesos, setEstadoimporte_presupuesto_pesos] = useState(0)
    const [Estadoimporte_adjudicado_pesos, setEstadoimporte_adjudicado_pesos] = useState(0)
    const [Estadoconcepto_descripcion, setEstadoconcepto_descripcion] = useState('')


    const [Estadounidad_negocio, setEstadounidad_negocio] = useState('');
    const [Estadounidad_area, setEstadounidad_area] = useState('');
    const [ComboManualUnidadNegocio, setComboManualUnidadNegocio] = useState([]);
    const [ComboManualArea, setComboManualArea] = useState([]);

    const [errorcontratacion_tipo, setErrorcontratacion_tipo] = useState(false);
    const [helperTextcontratacion_tipo, setHelperTextcontratacion_tipo] = useState('');

    const [errormoneda, setErrormoneda] = useState(false);
    const [helperTextmoneda, setHelperTextmoneda] = useState('');

    const [errorunidad_negocio, setErrorunidad_negocio] = useState(false);
    const [helperTextunidad_negocio, setHelperTextunidad_negocio] = useState('');

    const [errorunidad_area, setErrorunidad_area] = useState(false);
    const [helperTextunidad_area, setHelperTextunidad_area] = useState('');

    const [errorexpediente_referencia_gde, setErrorexpediente_referencia_gde] = useState(false);
    const [helperTextexpediente_referencia_gde, setHelperTextexpediente_referencia_gde] = useState('');

    const [Estadoid_usu_com, setEstadoid_usu_com] = useState(0)
    const [ComboUsername, setComboUsername] = useState([]);
    const [helperTextid_usu_com, setHelperTextid_usu_com] = useState('');

    const [Estadousername, setEstadousername] = useState('')
    const [helperTextusername, setHelperTextusername] = useState('');

    const [Estadopassword, setEstadopassword] = useState('')
    const [helperTextpassword, setHelperTextpassword] = useState('');

    const [values, setValues] = React.useState({
        username: '',
        password: '',
        id_usu_com: 0,
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        console.log(prop)
        if(prop==='password') {
            setHelperTextpassword('');
        }
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    useEffect(() => {
        dataProviderTableroControl.getList(
            'UserUsuCom',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'U' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboUsername(data);
            })
            .catch(error => {

            })
    }, [EstadoUserCopy]);

    const CambiaEstado = () => {
        setEstadoUserCopy(EstadoUserCopy + 1);
    };


    const PostTitle = ({record}) => {
        return <span>Nueva Usuario a Copiar</span>;
    };

    const [openCopiarUsuario, setopenCopiarUsuario] = React.useState(false);

    const handleClickOpenCopiarUsuario = () => {
        var w_validacion = ''
        var w_helpertextusername = ''
        var w_helpertextpassword = ''
        var w_helpertextid_usu_com = ''

        var w_username = Estadousername
        if(w_username==null){
            w_username=''
        }
        w_username=w_username.trim()
        if(w_username===''){
            w_validacion = 'E'
            w_helpertextusername = 'Debe Ingresar Nuevo Usuario'
        }

        var w_password = values.password
        if(w_password==null){
            w_password=''
        }
        w_password=w_password.trim()
        if(w_password===''){
            w_validacion = 'E'
            w_helpertextpassword = 'Debe Ingresar la Contraseña del Nuevo Usuario'
        }

        var w_id_usu_com = Estadoid_usu_com
        if(w_id_usu_com==null){
            w_id_usu_com=0
        }
        if(w_id_usu_com===0){
            w_validacion = 'E'
            w_helpertextid_usu_com = 'Debe Ingresar el Usuario a Copiar'
        }

        if(w_validacion === 'E') {
            setHelperTextusername(w_helpertextusername)
            setHelperTextpassword(w_helpertextpassword)
            setHelperTextid_usu_com(w_helpertextid_usu_com);
        }else {
            setopenCopiarUsuario(true);
        }
    };

    const handleCloseCopiarUsuario = () => {
        setopenCopiarUsuario(false);
    };

    const BotonCopiarUsuario = (record) => {

        var w_mensaje = ' '

        const notify = useNotify();
        const redirectTo = useRedirect();

        const [generardetelle, {loading}] = useMutation({
                type: 'create',
                resource: 'UserCopy',
                payload: {
                    data: {
                        username: Estadousername,
                        password: values.password,
                        id_usu_com: Estadoid_usu_com
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    // console.log('data------BotonCopiarUsuario')
                    // console.log(data)
                    notify(`Creada la Copiar Usuario N°: ${data.id}`, "success");
                    redirectTo(`/UserUsuCom?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=25&sort=id`);
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpenCopiarUsuario}
                        style={{color: '#ffffff', backgroundColor: '#004187'}}
                >
                    <div title={'Copiar del Usuario'}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        <FileCopyIcon style={{color: '#ffffff', backgroundColor: '#004187'}}/>
                        Copiar
                    </div>
                </Button>
                <Dialog open={openCopiarUsuario} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{'Confirmacion de la Copiar del Usuario'}</DialogTitle>
                    <DialogContent>
                        ¿Quieres Confirmar la Copiar del Usuario y Crear el Nuevo Usuario?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={generardetelle}
                                style={{color: '#ffffff', backgroundColor: '#004187'}}
                        >
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                 }}>
                                <FileCopyIcon style={{color: '#ffffff', backgroundColor: '#004187'}}/>
                                Aceptar
                            </div>
                        </Button>
                        <Button variant="outlined" color="primary" onClick={handleCloseCopiarUsuario}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                <CancelPresentationIcon style={{color: '#004187'}}/>
                                Cancelar
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const handleusername = (event) => {
        const Cambiausername1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadousername(wp_value)
            CambiaEstado()
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextusername('');
        Cambiausername1(w_value)

    };

    const handlepassword = (event) => {
        const Cambiapassword1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadopassword(wp_value)
            CambiaEstado()
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextpassword('');
        Cambiapassword1(w_value)

    };

    const handleid_usu_com = (event) => {
        const Cambiaid_usu_com1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoid_usu_com(wp_value)
            CambiaEstado()
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextid_usu_com('');
        Cambiaid_usu_com1(w_value)

    };



    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                toolbar={false}
                // toolbar={<PostCreateToolbar />}
                // validate={validateCreate}
                redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="COPIAR USUARIO">
                    <div formClassName={classes.inlineBlock}>
                        <td style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center',
                            color: '#0064c8'}}><h4>Copiar Usuario del Sistema</h4></td>
                        <Divider />
                    </div>
                    <br/>
                    <br/>
                    <TextField label="Nuevo Usuario"
                               value={Estadousername}
                               onChange={handleusername}
                               formClassName={classes.username}
                    />
                    <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                        {helperTextusername}
                    </FormHelperText>
                    <br/>
                    <br/>
                    <FormControl className={clsx(classes.margin, classes.textField)}>
                        <InputLabel htmlFor="standard-adornment-password">Contraseña del Nuevo Usuario</InputLabel>
                        <Input
                            formClassName={classes.password}
                            id="standard-adornment-password"
                            type={values.showPassword ? 'text' : 'password'}
                            value={values.password}
                            onChange={handleChange('password')}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                        {helperTextpassword}
                    </FormHelperText>
                    <br/>
                    <br/>
                    <InputLabel variant="h6">Usuario a Copiar</InputLabel>
                    <TextField
                        id="id_usu_com_ing"
                        select
                        label=""
                        // size="small"
                        // value={currency}
                        formClassName={classes.id_usu_com}
                        value={Estadoid_usu_com}
                        onChange={handleid_usu_com}
                        SelectProps={{
                            native: true,
                        }}
                        // fullWidth={true}
                        // helperText="Por favor seleccione una Username"
                    >
                        {ComboUsername.map((option) => (
                            <option key={option.id_usu_com} value={option.id_usu_com}>
                                {option.username}
                            </option>
                        ))}
                    </TextField>

                    <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                        {helperTextid_usu_com}
                    </FormHelperText>
                    <br/>

                    <BotonVerUsuarioaCopiar id_usu_copy={Estadoid_usu_com} new_username={Estadousername}/>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{ backgroundColor: '#e7e7e7' }}>
                        <Grid container spacing={3} >
                            <Grid item xs>
                                <BotonCopiarUsuario/>
                            </Grid>
                            <Grid item xs>
                                <Link to={`/UserUsuCom/`}>
                                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                        <Button
                                            label="Salir"
                                        >
                                            <ExitToAppIcon/> Salir
                                        </Button>
                                    </div>
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                    <br/>

                </FormTab>
            </TabbedForm>
        </Create>
    )
};




export default UserCopyCreate;


