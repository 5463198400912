import {createContext, useContext, useState} from "react";

export const BuscadorContext = createContext({});

export const useBuscadorContext = () => useContext(BuscadorContext);

const BuscadorContextProvider = ({children}) => {
    const [rubrosList, setRubrosList] = useState([]);
    const [clasesList, setClasesList] = useState([]);

    const addRubros = (rubros) => {
        setRubrosList(rubros);
    };
    const addClases = (clases) => {
        setClasesList(clases);
    };

    const limpiarClases = () => {
        setClasesList([]);
    };

    const limpiarRubros = () => {
        setRubrosList([]);
    };

    return (
        <BuscadorContext.Provider
            value={{
                rubrosList,
                clasesList,
                addRubros,
                addClases,
                limpiarClases,
                limpiarRubros
            }}
        >
            {children}
        </BuscadorContext.Provider>
    );
};

export default BuscadorContextProvider;
