import React from 'react';
import {AppBar, translate, UserMenu, MenuItemLink, WithPermissions} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ApiUrlServer from './ApiUrlServer'
import UserName from "./UserName"
import NotificacionBadge from "./NotificacionBadge"
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import CardMedia from '@material-ui/core/CardMedia';
// import Imageprincipalrta from '../layout/headerRTA.jpg';
import Imageprincipalrta from '../layout/logos/RTA_NAV.png';

console.log('ApiUrlServer-AppBar');
console.log(ApiUrlServer);

var w_apiurlserver = ApiUrlServer;
if (w_apiurlserver == null) {
    w_apiurlserver = ''
}
w_apiurlserver = w_apiurlserver.trim();

var w_logo, w_subtitulo = '';

if (w_apiurlserver === 'https://opi.radionacional.ar:4443/') {
    w_subtitulo = '';
    // w_subtitulo = '  RTA - Proveedores'
} else {
    if (w_apiurlserver === 'https://archivprov.radionacional.ar/') {
        w_subtitulo = '';
        // w_subtitulo = '  RTA - Proveedores'
    } else {
        if (w_apiurlserver === 'http://192.168.0.32:4620') {
            w_subtitulo = '';
            // w_subtitulo = '  RTA - Proveedores'
        } else {
            w_subtitulo = ' - DESARROLLO';
        }
    }
}

w_logo = <CardMedia
    style={{
        width: "auto",
        maxHeight: "40px"
    }}
    component="img"
    image={Imageprincipalrta}
    title="RTA"
/>;

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};

const role_secu_menu = ({permissions}) => {
    var w_role_secu_menu = '';
    var w_permissions_secu_menu = permissions
    if (w_permissions_secu_menu == null) {
    } else {
        var wa_role_secu_menu = w_permissions_secu_menu.indexOf('ROLE_SECU')
        if (wa_role_secu_menu === -1) {
        } else {
            w_role_secu_menu = 'ROLE_SECU'
        }
    }
    return w_role_secu_menu
};

const role_par_sis_secu_menu = ({permissions}) => {
    var w_role_par_sis_secu_menu = '';
    var w_permissions_par_sis_secu_menu = permissions;
    if (w_permissions_par_sis_secu_menu == null) {
    } else {
        var wa_role_par_sis_secu_menu = w_permissions_par_sis_secu_menu.indexOf('ROLE_PARAMETRO_SISTEMA')
        if (wa_role_par_sis_secu_menu === -1) {
        } else {
            w_role_par_sis_secu_menu = 'ROLE_PARAMETRO_SISTEMA'
        }
    }
    return w_role_par_sis_secu_menu
};

const role_par_path_secu_menu = ({permissions}) => {
    var w_role_par_path_secu_menu = '';
    var w_permissions_par_path_secu_menu = permissions;
    if (w_permissions_par_path_secu_menu == null) {
    } else {
        var wa_role_par_path_secu_menu = w_permissions_par_path_secu_menu.indexOf('ROLE_PARAMETRO_PATH')
        if (wa_role_par_path_secu_menu === -1) {
        } else {
            w_role_par_path_secu_menu = 'ROLE_PARAMETRO_PATH'
        }
    }
    return w_role_par_path_secu_menu
};

const CustomUserMenu = translate(({translate, ...props}) => (
    <UserMenu {...props}>
        <UserName/>
        <WithPermissions
            render={({permissions}) => (
                role_secu_menu({permissions}) === 'ROLE_SECU'
                    ? <MenuItemLink
                        // style={{color: '#0064c8'}}
                        to={`/UserUsuCom/`}
                        primaryText={'Usuarios del Sistema'}
                        leftIcon={<AssignmentIndIcon style={{color: '#dcaadc'}}/>}
                    />
                    : null
            )}
        />
        <WithPermissions
            render={({permissions}) => (
                role_par_sis_secu_menu({permissions}) === 'ROLE_PARAMETRO_SISTEMA'
                    ? <MenuItemLink
                        to={`/ParametroSistema/`}
                        primaryText={'Parametros del Sistema'}
                        leftIcon={<AssignmentLateIcon style={{color: '#a7c5f5'}}/>}
                    />
                    : null
            )}
        />
        <WithPermissions
            render={({permissions}) => (
                role_par_path_secu_menu({permissions}) === 'ROLE_PARAMETRO_PATH'
                    ? <MenuItemLink
                        to={`/ParametroPath/`}
                        primaryText={'Parametros del Path'}
                        leftIcon={<AssignmentLateIcon style={{color: '#00c8ff'}}/>}
                    />
                    : null
            )}
        />
    </UserMenu>
));

const CustomAppBar = ({classes, ...props}) => (
    <AppBar {...props} userMenu={<CustomUserMenu/>}
            style={{color: '#ffffff', backgroundColor: '#091E40'}}
    >
        <Typography
            variant="title"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
        {w_logo}
        {w_subtitulo}
        <span className={classes.spacer}/>
        <NotificacionBadge/>
    </AppBar>
);

export default withStyles(styles)(CustomAppBar);
