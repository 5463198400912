import React, {useEffect, useState} from 'react';
import {useDataProvider, Loading, Error, useRedirect, useNotify, useRefresh, useMutation, Button} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List"
import LineWeightIcon from '@material-ui/icons/LineWeight';

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
    table: {
        minWidth: 650,
    },
});

//--------------------  recibe como parametro la fecha de proceso ------------------

const TextFieldColorElegir = props => {

//        console.log('w_valor parametro ',props)

    // console.log('TextFieldColorElegir----props')
    // console.log(props)

    var w_valor = props

    // console.log('TextFieldColorElegir----w_valor')
    // console.log(w_valor.id)

    var w_valor_id = props.id

    // console.log('TextFieldColorElegir----w_valor_id')
    // console.log(w_valor_id)

    var w_valor_color = props.color

    if(w_valor_color==null){
        w_valor_color=''
    }
    w_valor_color=w_valor_color.trim()

    var w_tabla = props.tabla
    if(w_tabla==null){
        w_tabla=''
    }
    w_tabla=w_tabla.trim()

    // console.log('TextFieldColorElegir----w_valor_color')
    // console.log(w_valor_color)

    var visitors = []
    var visitors2 = []
    var visitors3 = []

    const classes = useStyles()

    const dataProvider = useDataProvider();
    const [EstadoDetalle, setEstadoDetalle] = useState(1);
    const [pvmparte1, setPvmParte1] = useState([]);
    const [pvmparte2, setPvmParte2] = useState([]);
    const [pvmparte3, setPvmParte3] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const CambiaEstadoAdjuntos = () => {
        // console.log('TextFieldColorElegir----CambiaEstadoAdjuntos')
        setEstadoDetalle(EstadoDetalle + 1);
    };

//--- el efect se ejecuta por primera vez y cuando cambia el estado del boton detall o cambia la fecha de proceso -------

    useEffect(() => {

        dataProvider.getList(
            'ColorElegirDetalle',
            {
                filter: {id: w_valor.id, color: w_valor_color, parte: 1},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setPvmParte1(data);
                setLoading(false);
            })
            .catch(error => {
                CambiaEstadoAdjuntos()
                // setError(error);
                // setLoading(false);

            })
        dataProvider.getList(
            'ColorElegirDetalle',
            {
                filter: {id: w_valor.id, color: w_valor_color, parte: 2},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setPvmParte2(data);
                setLoading(false);
            })
            .catch(error => {
                CambiaEstadoAdjuntos()
                // setError(error);
                // setLoading(false);

            })
        dataProvider.getList(
            'ColorElegirDetalle',
            {
                filter: {id: w_valor.id, color: w_valor_color, parte: 3},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setPvmParte3(data);
                setLoading(false);
            })
            .catch(error => {
                CambiaEstadoAdjuntos()
                // setError(error);
                // setLoading(false);

            })
    }, [EstadoDetalle, w_valor.id]);

    //----------------- chequea datos estado del componente -----------------------------

    if (loading) return <Loading loadingPrimary="  " loadingSecondary="Actualizando" />      // no cargo todavia

    if (error) {
        // console.log('error')
        // console.log(error)
        // return <Error/>;                           // error
    };

    //----------- si ya tiene los datos cargados en forma asincrónica actualiza el DOM --------------

    if (pvmparte1.length > 0) {

        visitors = pvmparte1

        visitors2 = pvmparte2

        visitors3 = pvmparte3

        // console.log('visitors')
        // console.log(visitors)
        // alert('Hola')

//---------------------- botones detalle / sin detalle --------------------------
        const BotonGenerarDetelleColor = (record) => {

            // console.log('BotonGenerarDetelleColor')

            var w_color = record.color
            if(w_color==null){
                w_color=''
            }
            w_color=w_color.trim()

            // console.log('w_color')
            // console.log(w_color)
            //
            // console.log('record---BotonEliminar')
            // console.log(record)

            var w_mensaje = ' '

            const CambiaEstado = () => {
                setEstadoDetalle(EstadoDetalle + 1);
            };

            const notify = useNotify();
            const refresh = useRefresh();

            const [generardetelle, {loading}] = useMutation({
                    type: 'update',
                    resource: 'ColorElegirDetalle',
                    payload: {id: w_valor_id, data: {color: w_color, tabla: w_tabla}}
                },
                {
                    onSuccess: ({data}) => {
                        refresh()
                        // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------
                        CambiaEstado()

                    },
                    onFailure: error => {
                        notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                    },
                }
            );


            return (
                <Button label={w_mensaje}
                        title={w_mensaje}
                        onClick={generardetelle} disabled={loading}
                        style={{
                            backgroundColor: w_valor_color.trim() === w_color.trim() ? '#32c832' : '#4766ff',
                            color: '#000000'
                        }}
                >
                    <div>
                        <LineWeightIcon style={{fontSize: 40, backgroundColor: '#ffffff', color: w_color}}/>
                    </div>
                </Button>
            );

        };

        return (

            <div>
                <div>
                    <List>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{backgroundColor: '#f44336'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Rojo'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#e91e63'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Rosa'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#9c27b0'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Morado'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#673ab7'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Morado Oscuro'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#3f51b5'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'índigo'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#2196f3'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Azul'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#03a9f4'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Azul Claro'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {visitors.map(record => {
                                return (
                                    <TableRow>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c1, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c1}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c1}
                                                                           d_color  = {record.d_color_c1}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c2, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c2}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c2}
                                                                           d_color  = {record.d_color_c2}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c3, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c3}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c3}
                                                                           d_color  = {record.d_color_c3}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c4, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c4}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c4}
                                                                           d_color  = {record.d_color_c4}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c5, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c5}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c5}
                                                                           d_color  = {record.d_color_c5}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c6, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c6}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c6}
                                                                           d_color  = {record.d_color_c6}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c7, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c7}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c7}
                                                                           d_color  = {record.d_color_c7}/>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </Table>
                        {/*    Parte2*/}
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{backgroundColor: '#00bcd4'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Cian'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#009688'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Verde Azulado'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#4caf50'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Verde'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#8bc34a'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Verde Claro'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#cddc39'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Lima'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#ffeb3b'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Amarillo'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {visitors2.map(record => {
                                return (
                                    <TableRow>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c8, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c8}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c8}
                                                                           d_color  = {record.d_color_c8}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c9, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c9}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c9}
                                                                           d_color  = {record.d_color_c9}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c10, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c10}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c10}
                                                                           d_color  = {record.d_color_c10}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c11, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c11}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c11}
                                                                           d_color  = {record.d_color_c11}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c12, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c12}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c12}
                                                                           d_color  = {record.d_color_c12}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c13, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c13}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c13}
                                                                           d_color  = {record.d_color_c13}/>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </Table>
                        {/*    Parte 3*/}
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{backgroundColor: '#ffc107'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Ámbar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#ff9800'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Naranja'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#ff5722'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Naranja Intenso'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#795548'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Marrón'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#9e9e9e'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Gris'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{backgroundColor: '#607d8b'}}>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#ffffff'}}>
                                            {'Gris Azulado'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Seleccionar'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {visitors3.map(record => {
                                return (
                                    <TableRow>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c14, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c14}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c14}
                                                                           d_color  = {record.d_color_c14}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c15, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c15}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c15}
                                                                           d_color  = {record.d_color_c15}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c16, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c16}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c16}
                                                                           d_color  = {record.d_color_c16}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c17, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c17}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c17}
                                                                           d_color  = {record.d_color_c17}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c18, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c18}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c18}
                                                                           d_color  = {record.d_color_c18}/>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft} style={{backgroundColor: record.color_c19, color: '#ffffff'}}>
                                            <Typography>
                                                <div title={'Detalle'}
                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                    {record.d_color_c19}
                                                </div>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <BotonGenerarDetelleColor  color    = {record.color_c19}
                                                                           d_color  = {record.d_color_c19}/>
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </Table>
                    </List>
                </div>
            </div>
        )
    }else{
        return (
            <div>
                <Typography>
                    {'...'}
                </Typography>
            </div>
        );
    }
}


export default TextFieldColorElegir;