import React from 'react';
import {useMutation, Button, useRedirect, useNotify, useRefresh} from 'react-admin';
import IconGuardar from '@material-ui/icons/Save';

const BtnProcesasClases = ({pro, data}) => {
    const redirectTo = useRedirect()
    const notify = useNotify();
    const refresh = useRefresh();

    const [actualizar, {loading}] = useMutation(
        {
            type: 'update',
            resource: 'Clase',
            payload: {id: pro, data: {data}}
        },
        {
            onSuccess: () => {
                refresh();
                notify('El archivo fue descargado con éxito', 'info', {
                    smart_count: 1,
                });
            },
            onFailure: error => {
                notify(`Error: No se ha podido descargar el archivo: ${error.message}`, "warning");
            }
        }
    );
    return (

        <Button variant="contained" size={"small"} color="primary"
                onClick={actualizar}
                disabled={loading}
                label={'Grabar Clases'}
        >
            <IconGuardar/>
        </Button>
    )
    // return <Button label="Exportar" onClick={exportar} disabled={loading}/>;
};

export default BtnProcesasClases;