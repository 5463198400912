import React, {useState} from 'react';
import EstadoOCActualizarForm from "./EstadoOCActualizarForm"
import {Drawer} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/icons/Update';
import {makeStyles} from '@material-ui/core/styles';

export const EstadoOCActualizarBtn = ({record, ...props}) => {

    const useStyles = makeStyles({
        paper: {
            width: '70%'
        },
        button: {
            justifyContent: 'left',
            display: 'inline-flex',
            fontWeight: 'bold',
            margin: 0,
            marginBottom: 0
        }
    });

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(false);
    };

    const handleClick = () => {
        props.callbackHayCambios(); // llama a la función callbackHayCambios con el nuevo valor
    };

    return (
        <div>
            <IconButton color="primary" onClick={() => setOpen(!open)}>
                <Icon/>
            </IconButton>

            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={open}
            >
                {
                    open &&
                    <EstadoOCActualizarForm
                        callbackCloseDrawer={handleDrawerOpen}
                        callbackHayCambios={props.callbackHayCambios}

                        id={record.id}
                        estado={record.orden_compra_estado}
                        estado_d={record.estado_d}
                        estado_usuario={record.orden_compra_estado_usuario}
                        estado_usuario_fh={record.orden_compra_estado_usuario_fh}
                        orden_compra_nro={record.orden_compra_nro}
                        orden_compra_ampliacion_nro={record.orden_compra_ampliacion_nro}

                    />
                }
            </Drawer>
        </div>
    )
};
export default EstadoOCActualizarBtn;