import React from 'react';
import compose from 'recompose/compose';
import List from '@material-ui/core/List';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {translate} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const TableroControlContratacionGerenteInicioVerInfo = ({
                     visitors = [], nb, translate,
                     ver_concepto_descripcion,
                     classes
                 }) => (
    <div>
        <List>
            <Table style={{backgroundColor: '#F2F3F4'}}>
                <TableRow>
                    <TableCell className={classes.textleft}>
                        <Typography>
                            <b>Concepto: </b> {ver_concepto_descripcion}
                        </Typography>
                    </TableCell>
                </TableRow>
            </Table>
        </List>

    </div>

);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(TableroControlContratacionGerenteInicioVerInfo);

