import React, {useState} from 'react';
import {NumberInput, useNotify, useRefresh, useMutation, ReferenceInput, SelectInput} from 'react-admin';
import {
    TextInput,
} from 'react-admin';
import Button from '@mui/material/Button';
import IconCancelar from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    socio_nro_ingreso: {display: 'inline-block', width: 100},
    nombre: {display: 'inline-block', width: 150,},
    apellido: {display: 'inline-block', width: 150, marginLeft: 28},
    documento_tipo: {display: 'inline-block', width: 80},
    documento_nro: {display: 'inline-block', width: 120, marginLeft: 28},
    cuit_cuil: {display: 'inline-block', width: 150, marginLeft: 28},
    correo_electronico: {display: 'inline-block', width: 350},
    limite_para_oferta_electronica_repr: {display: 'inline-block', width: 80},
    limite_para_oferta_electronica_pesos_repr: {display: 'inline-block', width: 120, marginLeft: 28},
    es_administrador_legitimado: {display: 'inline-block', width: 60},
    representante_activo: {display: 'inline-block', width: 60},
}));

function ProveedorRepresentanteAgregar({pro, callbackCloseDrawer}) {
    const [tieneLimitePesos, setTieneLimitePesos] = useState('N');
    const classes = useStyles();

    const [{
        proveedor,
        nombre_representante,
        apellido_representante,
        cuit_cuil_representante,
        id_documento_tipo_representante,
        documento_nro_representante,
        correo_electronico_representante,
        correo_electronico_alternativo_repr,
        limite_para_oferta_electronica_repr,
        limite_para_oferta_electronica_pesos_repr,
        es_administrador_legitimado,
        representante_activo,
    }, setState] = useState({
        proveedor: pro,
        nombre_representante: "",
        apellido_representante: "",
        cuit_cuil_representante: "",
        id_documento_tipo_representante: null,
        documento_nro_representante: "",
        correo_electronico_representante: "",
        correo_electronico_alternativo_repr: "",
        limite_para_oferta_electronica_repr: "",
        limite_para_oferta_electronica_pesos_repr: "",
        es_administrador_legitimado: "",
        representante_activo: "",
    });

    /// JN Mascara para el input cuit-cuil
    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    function handleTextInputChange({target: {name, value}}) {
        setState(prevState => ({...prevState, [name]: value}));
    }

    const BtnCancelar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" onClick={() => {
            callbackCloseDrawer(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const BotonCrearDetelle = (record) => {

        var w_mensaje = ' '

        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'create',
                resource: 'ProveedorRepresentante',
                payload: {
                    data: {
                        proveedor,
                        nombre_representante,
                        apellido_representante,
                        cuit_cuil_representante,
                        id_documento_tipo_representante,
                        documento_nro_representante,
                        correo_electronico_representante,
                        correo_electronico_alternativo_repr,
                        limite_para_oferta_electronica_repr,
                        limite_para_oferta_electronica_pesos_repr,
                        es_administrador_legitimado,
                        representante_activo
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <SaveIcon style={{color: '#004187'}}/>
                    Guardar
                </div>
            </Button>
        );

    };

    return (
        <div style={{paddingLeft: "10px"}}>
            <TextInput source={"nombre_representante"} label={"Nombre"} variant={"outlined"}
                       InputLabelProps={{shrink: true}}
                       className={classes.nombre} onChange={handleTextInputChange} fullWidth/>
            <TextInput source={"apellido_representante"} label={"Apellido"} variant={"outlined"}
                       InputLabelProps={{shrink: true}}
                       className={classes.apellido} onChange={handleTextInputChange} fullWidth/><br/>

            <ReferenceInput label="Tipo Doc." source="id_documento_tipo_representante"
                            className={classes.documento_tipo}
                            onChange={handleTextInputChange} sort={{field: 'orden', order: 'DESC'}}
                            reference="DocumentoTipo" variant="outlined">
                <SelectInput InputLabelProps={{shrink: true}} optionText="documento_tipo_d" fullWidth={true}
                             className={classes.documento_tipo_d}
                />
            </ReferenceInput>

            <TextInput source="documento_nro_representante" label={"Nro. Doc."} variant="outlined"
                       InputLabelProps={{shrink: true}} onChange={handleTextInputChange}
                       className={classes.documento_nro} fullWidth={true}/>

            <TextInput source="cuit_cuil_representante" label={"CUIT/CUIL"} variant="outlined"
                       InputLabelProps={{shrink: true}}
                       className={classes.cuit_cuil} fullWidth={true}
                       format={cuitCuilFormat} onChange={handleTextInputChange}
            /><br/>

            <TextInput source={"correo_electronico_representante"} label={"E-mail"} variant={"outlined"}
                       InputLabelProps={{shrink: true}} className={classes.correo_electronico}
                       onChange={handleTextInputChange} fullWidth/><br/>

            <TextInput source={"correo_electronico_alternativo_repr"} label={"E-mail alternativo"}
                       variant={"outlined"} InputLabelProps={{shrink: true}}
                       className={classes.correo_electronico} onChange={handleTextInputChange} fullWidth/><br/>

            <br/>

            <SelectInput source="limite_para_oferta_electronica_repr"
                         label="¿Tiene límite para oferta electrónica?" InputLabelProps={{shrink: true}}
                         className={classes.limite_para_oferta_electronica_repr}
                         fullWidth={true}
                         variant="outlined"
                         inputProps={{
                             onChange: (e) => {
                                 setTieneLimitePesos(e.target.value);
                             }
                         }}
                         choices={[
                             {id: 'S', name: 'Si'},
                             {id: 'N', name: 'No'},
                         ]}
            />

            {tieneLimitePesos === 'S' &&
            <>
                < NumberInput source="limite_para_oferta_electronica_pesos_repr"
                              label={"Límite en pesos"}
                              variant={"outlined"}
                              InputLabelProps={{shrink: true}}
                              className={classes.limite_para_oferta_electronica_pesos_repr}
                              onChange={handleTextInputChange}
                              fullWidth={true}/>
            </>
            }
            <br/>
            <SelectInput source="es_administrador_legitimado"
                         label="Adm. Legitimado"
                         className={classes.es_administrador_legitimado}
                         fullWidth={true} onChange={handleTextInputChange}
                         variant="outlined"
                         choices={[
                             {id: 'S', name: 'Si'},
                             {id: 'N', name: 'No'},
                         ]}
            />

            <br/>

            <SelectInput source="representante_activo"
                         label="Activo" onChange={handleTextInputChange}
                         className={classes.representante_activo}
                         fullWidth={true}
                         variant="outlined"
                         choices={[
                             {id: 'S', name: 'Si'},
                             {id: 'N', name: 'No'},
                         ]}
            />

            <div>

                <BotonCrearDetelle/>

                <BtnCancelar/>


            </div>
        </div>
    );
}

export default ProveedorRepresentanteAgregar;