import React, {useState} from 'react';
import {
    useQuery,
    Datagrid,
    TextField,
    Pagination,
    Loading, DateField, TextInput, FormTab, ListContextProvider
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import IconBuscar from '@material-ui/icons/Search';
import IconSeleccionar from '@material-ui/icons/Done';
import {makeStyles} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {TextField as TextFieldMUI} from '@material-ui/core';
import IconCancelar from '@material-ui/icons/Close';
import ReplayIcon from "@material-ui/icons/Replay";
import FilterListIcon from "@material-ui/icons/FilterList";
import YoutubeSearchedForIcon from "@material-ui/icons/YoutubeSearchedFor";
import IconClear from "@mui/icons-material/Clear";

export const ProveedorBusquedaDialogDataGridDatagrid = (props) => {

    var wr_props_proveedor_ing = props.proveedor

    const [props_proveedor, setprops_proveedor] = useState('');
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})
    const [filter, setFilter] = useState('');
    const [busqueda, setBusqueda] = useState('');

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'ProveedorQLista',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: {qb: filter, proveedor_ing: wr_props_proveedor_ing, utilizando_busqueda: 'S' },
        }
    });

    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    /*Botón seleccionar registro*/
    const BtnConfirmar = ({record}) => (
        <IconButton style={{fontSize: 15, padding: 2}}
            onClick={() => {
                props.callbackProveedor(record);
                props.callbackCloseDialog(false)
            }}
        >

            <IconSeleccionar style={{fontSize: 20}}/>
        </IconButton>
    );

    // /*Botón de búsqueda*/
    // const BtnBuscar = ({event}) => (
    //     <Button
    //         onClick={() => {
    //             setFilter(busqueda);
    //             setPage(1);
    //             setPerPage(10);
    //         }}
    //         style={{color: '#ffffff', backgroundColor: '#64c8ff'}}
    //         size={'small'}
    //     >
    //         <IconBuscar style={{fontSize: 20}}/> <h4>{' buscar'}</h4>
    //     </Button>
    // );
    //
    // /*Botón de borrar*/
    // const BtnBorrar = ({event}) => (
    //     <Button
    //         onClick={() => {
    //             setFilter('');
    //             setBusqueda('');
    //             setPage(1);
    //             setPerPage(10);
    //         }}
    //         style={{color: '#ffffff', backgroundColor: '#123B80'}}
    //         size={'small'}
    //     >
    //         <ReplayIcon/>
    //         <FilterListIcon style={{fontSize: 20}}/>
    //         <h4>{' Borrar Filtros'}</h4>
    //     </Button>
    // );

    /*Botón de búsqueda*/
    const BtnBuscar = ({event}) => (
        <Button
            variant="contained"
            onClick={() => {
                setFilter(busqueda);
                setPage(1);
                setPerPage(10);
            }}
            style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
            size={"small"}
            startIcon={<IconBuscar/>}
        >
            Buscar
        </Button>
    );

    /*Botón de borrar*/
    const BtnBorrar = ({event}) => (
        <Button
            variant="contained"
            onClick={() => {
                setFilter('');
                setBusqueda('');
                setPage(1);
                setPerPage(10);
            }}
            style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
            size={'small'}
            startIcon={<YoutubeSearchedForIcon/>}
        >
            Borrar
        </Button>
    );

    const Btntitulo = ({event}) => (
        <h4>{'Proveedor'}</h4>
    );

    const BtnVolver = ({record}) => (
        <Button variant="contained" size={"small"} color="primary"
                onClick={() => {
                    props.callbackCloseDialog(false)
                }}
                startIcon={<IconCancelar/>}
                style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
        >
            Volver
        </Button>
    );


    return (
        <div>
            <div style={{margin: '3em'}}>
                <Btntitulo/>
                <TextFieldMUI label="Buscar"
                              onChange={(v) => setBusqueda(v.target.value)}
                              defaultValue={filter}
                              style={{marginLeft: '2px', width: '200px', height: '100px'}}
                />

                <BtnBuscar/>

                {filter === '' ? '' :
                    <BtnBorrar/>
                }

                <ListContextProvider value={{
                    data: keyBy(data, 'id'),
                    ids: data.map(({ id }) => id),
                    total,
                    page,
                    perPage,
                    setPage,
                    currentSort: { field: 'id', order: 'ASC' },
                    basePath: "/posts",
                    resource: 'posts',
                    selectedIds: []
                }}>
                <Datagrid  rowStyle={RowStyle}
                           data={keyBy(data, 'id')}
                    ids={data.map(({id}) => id)}
                    currentSort={sort}
                    setSort={(field, order) => setSort({field, order})}
                >
                    <BtnConfirmar/>
                    <TextField label="Nro. Proveedor" source="pro"/>
                    <TextField label="Razón Social" source="razon_social"/>
                    {/*<TextField label="Tipo Persona" source="persona_tipo_d" sortable={false}/>*/}
                    {/*<TextField label="Forma Persona" source="persona_forma_d" sortable={false}/>*/}
                    <TextField label="Tipo Doc." source="documento_tipo_d" sortable={false}/>
                    <TextField label="Cuit / Cuil" source="cuit_cuil" sortable={false}/>
                    {/*<TextField label="IVA" source="d_iva" sortable={false}/>*/}
                    {/*<TextField label="Es Pyme" source="es_pyme" sortable={false}/>*/}
                    {/*<TextField label="Estado Proveedor" source="estado_proveedor_abre" sortable={false}/>*/}
                    <TextField source="comercial_provincia" label={"Provincia"} sortable={false}/>
                    <TextField source="comercial_ciudad" label={"Ciudad"}  sortable={false}/>
                    {/*<DateField label="Fecha Alta" source="fecha_alta" sortable={false}/>*/}
                </Datagrid>
                <Pagination
                    rowsPerPageOptions={[]}
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    total={total}
                />
                </ListContextProvider >
                <BtnVolver/>
            </div>
        </div>
    )
};


const RowStyle = (record, index) => ({
    backgroundColor: record.pro  === undefined || record.proveedor_ing === undefined ? '' :
        record.pro.trim() === record.proveedor_ing.trim() ? '#64c8ff' : '',
});

export default ProveedorBusquedaDialogDataGridDatagrid;