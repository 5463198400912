import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    useNotify,
    useRedirect, useMutation, useRefresh, useUnselectAll, SelectInput, NumberInput, BooleanInput, FormTab
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import PolicyIcon from '@material-ui/icons/Policy';
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import HistoryIcon from '@material-ui/icons/History';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import InputLabel from "@material-ui/core/InputLabel";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Imagepvm from '../layout/tvpublica_logo_2021.bmp'; // Import using relative path
import Imagecpm from '../layout/headerRTA.jpg';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CardContent from "@material-ui/core/CardContent";
import DashboardIcon from "@material-ui/icons/Dashboard";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import WebIcon from "@material-ui/icons/Web";
import PersonIcon from "@material-ui/icons/Person";
import AssistantIcon from "@material-ui/icons/Assistant";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SupervisedUserCircleTwoToneIcon from "@material-ui/icons/SupervisedUserCircleTwoTone";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CommentIcon from "@material-ui/icons/Comment";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SwitchCameraIcon from "@material-ui/icons/SwitchCamera";
import VignetteIcon from "@material-ui/icons/Vignette";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import ShopIcon from "@material-ui/icons/Shop";
import cartuchonewlargo from "../TableroControl/cartuchonewlargo.png";
import CardMedia from "@material-ui/core/CardMedia";
import Imageprincipalrta from "../layout/headerRTA.jpg";
import AddBoxIcon from "@material-ui/icons/AddBox";
import TabletIcon from "@material-ui/icons/Tablet";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SearchIcon from "@material-ui/icons/Search";
import FilterFramesIcon from "@material-ui/icons/FilterFrames";
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import BusinessIcon from "@material-ui/icons/Business";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import TableChartIcon from "@material-ui/icons/TableChart";
import Avatar from "@material-ui/core/Avatar";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PieChartIcon from "@material-ui/icons/PieChart";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import LineWeightIcon from "@material-ui/icons/LineWeight";
import DataUsageIcon from '@material-ui/icons/DataUsage';
import AirplayIcon from '@material-ui/icons/Airplay';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import DialpadIcon from "@material-ui/icons/Dialpad";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import EmailIcon from "@material-ui/icons/Email";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
    rotateIcon: {
        animation: "spin 4s linear infinite"
    },
    root: {
        transform: 90,
        borderRadius: 5,
        width: 50,
        height: "100%"
    },
    rootb: {
        width: 500,
    },
    paper: {
        padding: '6px 16px',
    },
    root2: {
        width: '100%',
    },
    content: {
        justifyContent: "center"
    }
});

const AccordionSummary = withStyles({
    content: {
        flexGrow: 0
    }
})(MuiAccordionSummary);

const BotonVerUsuarioaCopiar = ({id_usu_copy, new_username}) => {

    console.log(id_usu_copy)

    var wr_id_usu_copy = id_usu_copy

    console.log(wr_id_usu_copy)

    console.log(new_username)

    var wr_new_username = new_username

    console.log(wr_new_username)

    const classes = useStyles()


    const [EstadoUsuCopy, setEstadoUsuCopy] = React.useState('');
    const [openUsuarioaCopiar, setOpenUsuarioaCopiar] = React.useState(false);
    const [ObjetoUsuarioaCopiar, setObjetoUsuarioaCopiar] = React.useState([]);
    const [openBottomNavigationUsuarioaCopiar, setOpenBottomNavigationUsuarioaCopiar] = useState(false);


    const [ObjetoUsuarioaCopiarRole, setObjetoUsuarioaCopiarRole] = React.useState([]);
    const [openBottomNavigationUsuarioaCopiarRole, setOpenBottomNavigationUsuarioaCopiarRole] = useState(false);

    const [ObjetoUsuarioaCopiarArea, setObjetoUsuarioaCopiarArea] = React.useState([]);
    const [openBottomNavigationUsuarioaCopiarArea, setOpenBottomNavigationUsuarioaCopiarArea] = useState(false);

    const [ObjetoUsuarioaCopiarNotificacion, setObjetoUsuarioaCopiarNotificacion] = React.useState([]);
    const [openBottomNavigationUsuarioaCopiarNotificacion, setOpenBottomNavigationUsuarioaCopiarNotificacion] = useState(false);


    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();
    const [valueBottomNavigationUsuarioaCopiar, setValueBottomNavigationUsuarioaCopiar] = React.useState('U');

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const dataProvider = useDataProvider();

    const handleClickOpenUsuarioaCopiar = () => {

        if (wr_id_usu_copy === 0) {
            setOpenUsuarioaCopiar(false);
        }else {
            setOpenUsuarioaCopiar(true);
            setValueBottomNavigationUsuarioaCopiar('U')
            dataProvider.getList(
                'UserCopy',
                {
                    filter: {
                        uso_usu_copy: 'U',
                        id_usu_copy: wr_id_usu_copy
                    },
                    sort: {field: 'visita_usuario_fh', order: 'DESC'},
                    pagination: {page: 1, perPage: 100},
                },
            )
                .then(({data}) => {
                    setEstadoUsuCopy(data[0].username)
                    setObjetoUsuarioaCopiar(data);
                    setLoading(false);
                    setOpenBottomNavigationUsuarioaCopiar(true);
                    setOpenBottomNavigationUsuarioaCopiarRole(false);
                    setOpenBottomNavigationUsuarioaCopiarArea(false);
                    setOpenBottomNavigationUsuarioaCopiarNotificacion(false);
                })
                .catch(error => {
                    // setError(error);
                    setLoading(false);

                })
            if (loading) return <Loading/>;                // no cargo todavia

            if (error) {
                console.log('error')
                console.log(error)
                return <Error/>;                           // error
            }
            ;
        }
    };

    const handleCloseUsuarioaCopiar = () => {
        setOpenUsuarioaCopiar(false);
    };



    const handleDrawerOpenBottomNavigationUsuarioaCopiarU = () => {
        dataProvider.getList(
            'UserCopy',
            {
                filter: {
                    uso_usu_copy: 'U',
                    id_usu_copy: wr_id_usu_copy
                },
                sort: {field: 'visita_usuario_fh', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },
        )
            .then(({data}) => {
                setObjetoUsuarioaCopiar(data);
                setLoading(false);
                setOpenBottomNavigationUsuarioaCopiar(true);
                setOpenBottomNavigationUsuarioaCopiarRole(false);
                setOpenBottomNavigationUsuarioaCopiarArea(false);
                setOpenBottomNavigationUsuarioaCopiarNotificacion(false);
            })
            .catch(error => {
                // setError(error);
                setLoading(false);

            })

    }

    const handleDrawerOpenBottomNavigationUsuarioaCopiarR = () => {
        dataProvider.getList(
            'UserCopy',
            {
                filter: {
                    uso_usu_copy: 'R',
                    id_usu_copy: wr_id_usu_copy
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },
        )
            .then(({data}) => {
                setObjetoUsuarioaCopiarRole(data);
                setLoading(false);
                setOpenBottomNavigationUsuarioaCopiar(false);
                setOpenBottomNavigationUsuarioaCopiarRole(true);
                setOpenBottomNavigationUsuarioaCopiarArea(false);
                setOpenBottomNavigationUsuarioaCopiarNotificacion(false);
            })
            .catch(error => {
                // setError(error);
                setLoading(false);

            })

    }


    const handleDrawerOpenBottomNavigationUsuarioaCopiarA = () => {
        dataProvider.getList(
            'UserCopy',
            {
                filter: {
                    uso_usu_copy: 'A',
                    id_usu_copy: wr_id_usu_copy
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },
        )
            .then(({data}) => {
                setObjetoUsuarioaCopiarArea(data);
                setLoading(false);
                setOpenBottomNavigationUsuarioaCopiar(false);
                setOpenBottomNavigationUsuarioaCopiarRole(false);
                setOpenBottomNavigationUsuarioaCopiarArea(true);
                setOpenBottomNavigationUsuarioaCopiarNotificacion(false);
            })
            .catch(error => {
                // setError(error);
                setLoading(false);

            })

    }

    const handleDrawerOpenBottomNavigationUsuarioaCopiarN = () => {
        dataProvider.getList(
            'UserCopy',
            {
                filter: {
                    uso_usu_copy: 'N',
                    id_usu_copy: wr_id_usu_copy
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },
        )
            .then(({data}) => {
                setObjetoUsuarioaCopiarNotificacion(data);
                setLoading(false);
                setOpenBottomNavigationUsuarioaCopiar(false);
                setOpenBottomNavigationUsuarioaCopiarRole(false);
                setOpenBottomNavigationUsuarioaCopiarArea(false);
                setOpenBottomNavigationUsuarioaCopiarNotificacion(true);
            })
            .catch(error => {
                // setError(error);
                setLoading(false);

            })

    }

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpenUsuarioaCopiar}>
                <div title={'Ver Usuario a Copiar'}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <InsertDriveFileIcon/>
                    Ver Usuario a Copiar
                </div>
            </Button>
            <Dialog open={openUsuarioaCopiar}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                // maxWidth={'lg'}
                    aria-labelledby="draggable-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <DialogContentText style={{color: '#ffffff', backgroundColor: '#123B80'}}>
                        <Typography variant="headline" component="h4" className={classes.textleft}>
                            {'Ver Usuario a Copiar de '+EstadoUsuCopy}
                        </Typography>
                    </DialogContentText>
                </DialogTitle>
                <DialogContent>
                    <BottomNavigation
                        value={valueBottomNavigationUsuarioaCopiar}
                        onChange={(event, newValue) => {
                            setValueBottomNavigationUsuarioaCopiar(newValue);
                        }}
                        showLabels
                        className={classes.rootb}
                    >
                        <BottomNavigationAction label="DATOS"
                                                value="U"
                                                onClick={handleDrawerOpenBottomNavigationUsuarioaCopiarU}
                                                icon={<DataUsageIcon />} />
                        <BottomNavigationAction label="ROLE" value="R"
                                                onClick={handleDrawerOpenBottomNavigationUsuarioaCopiarR}
                                                icon={<AirplayIcon />} />
                        <BottomNavigationAction label="AREA" value="A"
                                                onClick={handleDrawerOpenBottomNavigationUsuarioaCopiarA}
                                                icon={<LocationOnIcon />} />
                        <BottomNavigationAction label="NOTIFICACION" value="N"
                                                onClick={handleDrawerOpenBottomNavigationUsuarioaCopiarN}
                                                icon={<NotificationsIcon />} />
                    </BottomNavigation>
                    <Divider />

                        {openBottomNavigationUsuarioaCopiar=== true ?
                            <div>
                                <div>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        {ObjetoUsuarioaCopiar.map(recordusuarioacopiar => {
                                            return (
                                                <div>
                                                    <br/>
                                                    <br/>
                                                    <TextField disabled={true}  label="Nuevo Usuario" value={wr_new_username}
                                                               formClassName={classes.username}/>
                                                    <TextField disabled={true}  label="Version" value={recordusuarioacopiar.version}
                                                                 formClassName={classes.version} />
                                                    <br/>
                                                    <br/>
                                                    <br/>
                                                    <div></div>

                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                disabled
                                                                checked={recordusuarioacopiar.account_expired}
                                                                name="account_expired"
                                                                style={{ color: recordusuarioacopiar.account_expired === true ? '#ff0000' : '#f0f0f0'  }}
                                                            />
                                                        }
                                                        label="Cuenta Vencida"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                disabled
                                                                checked={recordusuarioacopiar.account_locked}
                                                                name="account_locked"
                                                                style={{ color: recordusuarioacopiar.account_locked === true ? '#ff0000' : '#f0f0f0'  }}
                                                            />
                                                        }
                                                        label="Cuenta Bloqueada"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                disabled
                                                                checked={recordusuarioacopiar.enabled}
                                                                name="enabled"
                                                                style={{ color: recordusuarioacopiar.enabled === true ? '#32c832' : '#f0f0f0'  }}
                                                            />
                                                        }
                                                        label="Usuario Habilitado"
                                                    />
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                disabled
                                                                checked={recordusuarioacopiar.password_expired}
                                                                name="password_expired"
                                                                style={{ color: recordusuarioacopiar.password_expired === true ? '#ff0000' : '#f0f0f0'  }}
                                                            />
                                                        }
                                                        label="Password Vencidao"
                                                    />


                                                    <TextField  disabled={true}  label={
                                                        <div title={'Es Administrador'}
                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                            <SupervisorAccountIcon/>
                                                            {'Es Administrador'}
                                                        </div>
                                                    }
                                                                  value={recordusuarioacopiar.es_administrador} formClassName={classes.es_administrador}
                                                                  choices={[
                                                                      {id: 'S', name: 'SI'},
                                                                      {id: 'N', name: 'NO'},
                                                                  ]}
                                                    />

                                                    <br/>

                                                    <div></div>

                                                    <TextField disabled={true}  value={recordusuarioacopiar.whatsapp_nro}
                                                               label={<div    title={'Numero de Whatsapp'}
                                                                              style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                   <DialpadIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                                                   <WhatsAppIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                                                   {' Numero de Whatsapp'}
                                                               </div>}
                                                               formClassName={classes.whatsapp_nro}
                                                               fullWidth={true}
                                                    />



                                                    <br/>
                                                    <br/>
                                                    <br/>

                                                    <TextField disabled={true}  value={recordusuarioacopiar.email}
                                                               label={<div title={'E-Mail (Correo)'}
                                                                           style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                   <ContactMailIcon  />
                                                                   {' E-Mail (Correo)'}
                                                               </div>
                                                               }
                                                               variant="outlined"
                                                               InputLabelProps={{shrink: true, readOnly: true}}
                                                               formClassName={classes.email}
                                                               fullWidth={true}/>

                                                    <br/>
                                                    <br/>
                                                    <br/>

                                                    <TextField disabled={true}  label={
                                                                <div title={'Enviar Whatsapp'}
                                                                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                    <AddToHomeScreenIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                                                    <WhatsAppIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                                                    {' Enviar Whatsapp'}
                                                                </div>
                                                                }
                                                                value={recordusuarioacopiar.enviar_whatsapp}
                                                                formClassName={classes.enviar_whatsapp}
                                                                choices={[
                                                                     {id: 'S', name: 'SI'},
                                                                     {id: 'N', name: 'NO'},
                                                                 ]}
                                                    />


                                                    <TextField disabled={true}  label={
                                                        <div title={'Enviar Correo'}
                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                            <AddToHomeScreenIcon/>
                                                            <EmailIcon/>
                                                            {' Enviar Correo'}
                                                        </div>
                                                    }
                                                                 value={recordusuarioacopiar.enviar_correo}
                                                               formClassName={classes.enviar_correo}
                                                                 choices={[
                                                                     {id: 'S', name: 'SI'},
                                                                     {id: 'N', name: 'NO'},
                                                                 ]}
                                                    />





                                                    <TextField disabled={true}  label={
                                                        <div title={'Enviar Notificacion'}
                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                            <AddToHomeScreenIcon style={{color: '#ff0000'}}/>
                                                            <NotificationsIcon style={{color: '#ff0000'}}/>
                                                            {' Enviar Notificacion'}
                                                        </div>
                                                    }
                                                                 value={recordusuarioacopiar.enviar_notificacion}
                                                               formClassName={classes.enviar_notificacion}
                                                                 choices={[
                                                                     {id: 'S', name: 'SI'},
                                                                     {id: 'N', name: 'NO'},
                                                                 ]}
                                                    />
                                                    <br/>
                                                </div>
                                            );
                                        })}
                                    </Table>
                                </div>
                            </div>
                        : ' ' }

                    {openBottomNavigationUsuarioaCopiarRole=== true ?
                        <div>
                            <div>
                                <List>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                                        {'Detalle'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                        {'ROLE'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                        {''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                        {'Descripcion'}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {ObjetoUsuarioaCopiarRole.map(recordrole => {
                                            return (
                                                <TableRow>
                                                    <TableCell className={classes.textleft}>
                                                        <Typography>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className={classes.textleft}>
                                                                            <Typography style={{color: recordrole.ordenManual === undefined
                                                                                    ? '' : recordrole.ordenManual === 1
                                                                                        ? '#32c832' :'#000000'}}>
                                                                                {recordrole.menu==='Inicio' ? <DashboardIcon/> : ''}
                                                                                {recordrole.menu==='Contrataciones' ? <WebAssetIcon/> : ''}
                                                                                {recordrole.menu==='Ordenes de Compra' ? <WebIcon/> : ''}
                                                                                {recordrole.menu==='Proveedores' ? <PersonIcon/> : ''}
                                                                                {recordrole.menu==='Tablas Auxiliares' ? <AssistantIcon/> : ''}
                                                                                {recordrole.menu==='Mis Notificaciones Hist.' ? <NotificationsIcon/> : ''}

                                                                                {recordrole.menu==='Saldo ML' ? <SupervisedUserCircleTwoToneIcon style={{ color: '#0085ff' }}/> : ''}
                                                                                {recordrole.menu==='Nota ML' ? <LocalOfferIcon/> : ''}
                                                                                {recordrole.menu==='PreMensaje ML' ? <CommentIcon/> : ''}
                                                                                {recordrole.menu==='Proveedor Grupo' ? <ContactMailIcon/> : ''}
                                                                                {recordrole.menu==='Cliente Grupo' ? <PersonIcon/> : ''}
                                                                                {recordrole.menu==='Constancia AFIP' ? <AccountBoxIcon/> : ''}
                                                                                {recordrole.menu==='Parametros' ? <SwitchCameraIcon/> : ''}
                                                                                {/*{recordrole.menu==='Parametros Pasar A' ? <SwitchCameraIcon/> : ''}*/}
                                                                                {recordrole.menu==='Familia' ? <VignetteIcon/> : ''}
                                                                                {recordrole.menu==='Cotizador Menu' ? <VisibilityIcon style={{color: '#aa0000', backgroundColor: '#ffffff'}}/> : ''}
                                                                                {/*{recordrole.menu==='Usuarios del Sistema' ? <WarningIcon style={{color: '#fffd1e', backgroundColor: '#000000'}}/> : ''}*/}
                                                                                {recordrole.menu==='Usuarios del Sistema' ? <AssignmentIndIcon style={{color: '#dcaadc'}} /> : ''}
                                                                                {recordrole.menu==='Parametros del Sistema' ? <AssignmentLateIcon style={{ color: '#a7c5f5'}} /> : ''}
                                                                                {recordrole.menu==='xxl' ? <ShopIcon style={{ background: `url(${cartuchonewlargo}) no-repeat`}} /> : ''}
                                                                                {recordrole.menu}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell className={classes.textleft}>
                                                                            <Typography style={{color: '#000000'}}>
                                                                                {recordrole.submenu==='Bienvenido' ? <CardMedia
                                                                                    style={{
                                                                                        width: "auto",
                                                                                        maxHeight: "40px"
                                                                                    }}
                                                                                    component="img"
                                                                                    image={Imageprincipalrta}
                                                                                    title="RTA"
                                                                                /> : ''}
                                                                                {recordrole.submenu==='Contrataciones' ? <WebAssetIcon/> : ''}
                                                                                {recordrole.submenu==='Ordenes de Compra' ? <WebIcon/> : ''}
                                                                                {recordrole.submenu==='Nueva Contratación' ? <AddBoxIcon/> : ''}
                                                                                {recordrole.submenu==='Consulta Contrataciones' ? <WebAssetIcon/> : ''}
                                                                                {recordrole.submenu==='Consulta Contrataciones (G)' ? <WebAssetIcon style={{color: '#64b4ff'}}/> : ''}
                                                                                {recordrole.submenu==='Vista Contrataciones' ? <VisibilityIcon/> : ''}
                                                                                {recordrole.submenu==='Tablero Control' ? <TabletIcon/> : ''}
                                                                                {recordrole.submenu==='Listado de Proveedores' ? <PersonOutlineIcon/> : ''}
                                                                                {recordrole.submenu==='Nuevo Proveedor' ? <PersonAddIcon/> : ''}
                                                                                {recordrole.submenu==='Buscador por Clase' ? <SearchIcon/> : ''}


                                                                                {recordrole.submenu==='Contratación' ? <FilterFramesIcon/> : ''}
                                                                                {recordrole.submenu==='Compras' ? <ShopTwoIcon/> : ''}
                                                                                {recordrole.submenu==='Estructura Unidades' ? <BusinessIcon/> : ''}
                                                                                {recordrole.submenu==='Tablas AFIP' ? <AccountBalanceIcon/> : ''}
                                                                                {recordrole.submenu==='Otras Tablas' ? <TableChartIcon/> : ''}
                                                                                {recordrole.submenu==='Notificacion' ? <NotificationsIcon/> : ''}
                                                                                {recordrole.submenu}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell className={classes.textleft}>
                                                                            <Typography style={{color: '#000000'}}>
                                                                                {recordrole.boton==='Todos los Articulos ML' ? <Avatar
                                                                                    src={`${Imagepvm}?size=${30}x${30}`}
                                                                                    size={30}
                                                                                    style={{ width: 30, height: 30 }}
                                                                                /> : ''}
                                                                                {recordrole.boton==='Todos los Articulos ML' ? <Avatar
                                                                                    src={`${Imagecpm}?size=${30}x${30}`}
                                                                                    size={30}
                                                                                    style={{ width: 30, height: 30 }}
                                                                                /> : ''}
                                                                                {recordrole.boton==='Articulos ML Provemet' ? <Avatar
                                                                                    src={`${Imagepvm}?size=${30}x${30}`}
                                                                                    size={30}
                                                                                    style={{ width: 30, height: 30 }}
                                                                                /> : ''}
                                                                                {recordrole.boton==='Articulos ML Complemet' ? <Avatar
                                                                                    src={`${Imagecpm}?size=${30}x${30}`}
                                                                                    size={30}
                                                                                    style={{ width: 30, height: 30 }}
                                                                                /> : ''}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell className={classes.textleft}>
                                                                            <Typography style={{color: '#5abebe'}}>
                                                                                {recordrole.boton==='Auditoria' ?
                                                                                    <Button variant="outlined" color="primary">
                                                                                        <div title={'Auditoria Interna'}
                                                                                             style={{
                                                                                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                                            <PolicyIcon style={{color: '#ff8040'}}/>
                                                                                        </div>
                                                                                    </Button>
                                                                                    : ''}
                                                                                {recordrole.boton==='Mis Notificaciones Hist. Todas' ?
                                                                                    <SelectInput  disabled={true}
                                                                                                  label={  <div title={'Usuario de la Notificacion'}
                                                                                                                style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                                                      <PersonIcon style={{color: '#0064c8'}} />
                                                                                                  </div>
                                                                                                  }
                                                                                                  source="username"
                                                                                                  choices={[
                                                                                                      {id: 'TODOS', name: 'Todos'},
                                                                                                      {id: 'DISTINTO', name: 'Distintos al Mio'},
                                                                                                  ]}
                                                                                    />
                                                                                    : ''}
                                                                                {recordrole.boton==='Tablas Auxiliares Crear' ?
                                                                                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                                        <Button
                                                                                            label="Crear"
                                                                                        >
                                                                                            <AddIcon/>
                                                                                        </Button>
                                                                                    </div>
                                                                                    : ''}
                                                                                {recordrole.boton==='Tablas Auxiliares Editar' ?
                                                                                    <div title={''}
                                                                                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                                    >
                                                                                        <EditIcon style={{fontSize: 20}}/> Editar
                                                                                    </div>
                                                                                    : ''}
                                                                                {recordrole.boton==='Listado de Proveedores Mostrar' ?
                                                                                    <div title={''}
                                                                                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                                    >
                                                                                        <VisibilityIcon style={{fontSize: 20}}/> Mostrar
                                                                                    </div>
                                                                                    : ''}
                                                                                {recordrole.boton==='Listado de Proveedores Editar' ?
                                                                                    <div title={''}
                                                                                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                                    >
                                                                                        <EditIcon style={{fontSize: 20}}/> Editar
                                                                                    </div>
                                                                                    : ''}

                                                                                {recordrole.boton==='Consulta Contrataciones Mostrar' ?
                                                                                    <div title={''}
                                                                                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                                    >
                                                                                        <VisibilityIcon style={{fontSize: 20}}/> Mostrar
                                                                                    </div>
                                                                                    : ''}
                                                                                {recordrole.boton==='Contrataciones Seguimiento Completo' ?
                                                                                    <FormatListNumberedIcon/>
                                                                                    : ''}
                                                                                {recordrole.boton==='Contrataciones Seguimiento Limitado' ?
                                                                                    <FormatListBulletedIcon/>
                                                                                    : ''}
                                                                                {recordrole.boton==='Estados Contrataciones' ?
                                                                                    <ListAltIcon/>
                                                                                    : ''}
                                                                                {recordrole.boton==='Vista Contrataciones Mostrar' ?
                                                                                    <div title={''}
                                                                                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                                    >
                                                                                        <VisibilityIcon style={{fontSize: 20}}/> Mostrar
                                                                                    </div>
                                                                                    : ''}
                                                                                {recordrole.boton==='Vista Contrataciones Editar' ?
                                                                                    <div title={''}
                                                                                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                                    >
                                                                                        <EditIcon style={{fontSize: 20}}/> Editar
                                                                                    </div>
                                                                                    : ''}
                                                                                {recordrole.boton==='Vista Contrataciones Visitar' ?
                                                                                    <Button variant="outlined" color="primary">
                                                                                        <div title={''}
                                                                                             style={{
                                                                                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                                            <VisibilityIcon/>
                                                                                        </div>
                                                                                    </Button>
                                                                                    : ''}
                                                                                {recordrole.boton==='Ver Grafico de la Unidad de Negocio Invariablemente' ?
                                                                                    <PieChartIcon style={{fontSize: 20}}/>
                                                                                    : ''}
                                                                                {recordrole.boton==='Ver Grafico de la Unidad de Area Invariablemente' ?
                                                                                    <PieChartIcon style={{fontSize: 20}}/>
                                                                                    : ''}
                                                                                {recordrole.boton==='Tablero Control Mostrar' ?
                                                                                    <div title={''}
                                                                                         style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                                    >
                                                                                        <VisibilityIcon style={{fontSize: 20}}/> Mostrar
                                                                                    </div>
                                                                                    : ''}

                                                                                {recordrole.boton==='Vista Contrataciones Visitas Historicas' ?
                                                                                    <Button variant="outlined" color="primary">
                                                                                        <div title={''}
                                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                                        >
                                                                                            <HistoryIcon/>
                                                                                        </div>
                                                                                    </Button>
                                                                                    : ''}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell className={classes.textleft}>
                                                                            <Typography style={{color: '#5abebe'}}>
                                                                                {recordrole.boton}
                                                                            </Typography>
                                                                        </TableCell>
                                                                        <TableCell className={classes.textleft}>
                                                                            <Typography style={{color: '#c895c8'}}>
                                                                                {recordrole.subboton}
                                                                            </Typography>
                                                                        </TableCell>

                                                                    </TableRow>
                                                                </TableHead>
                                                            </Table>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.textleft}>
                                                        <Typography>
                                                            {recordrole.role}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.textcenter}>
                                                        <Typography  style={{backgroundColor: '#ffffff'}} >
                                                            <ToggleOffIcon style={{fontSize: 30, color: '#32c832'}}/>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.textleft}>
                                                        <Typography style={{color: '#000000'}}>
                                                            {recordrole.descripcion}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </Table>
                                </List>
                            </div>
                        </div>
                        : ' ' }
                    {openBottomNavigationUsuarioaCopiarArea=== true ?
                        <div>
                            <div>
                                <List>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                                        {'Unidad Negocio'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                                        {'Area'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                                        {'Area Descripción'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                        {'Color'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                        {'Estado'}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {ObjetoUsuarioaCopiarArea.map(recordarea => {
                                            return (
                                                <TableRow
                                                    // style={{backgroundColor: recordarea.color === undefined
                                                    //     ? '' : recordarea.color}}
                                                >
                                                    <TableCell className={classes.textleft}>
                                                        <Typography style={{color: '#000000'}} >
                                                            {recordarea.unidad_negocio}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.textleft}>
                                                        <Typography style={{color: '#000000'}}>
                                                            {recordarea.area}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.textleft}>
                                                        <Typography style={{color: '#000000'}}>
                                                            {recordarea.area_d}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.textcenter}>
                                                        <Typography style={{color: '#000000'}}>
                                                            <LineWeightIcon style={{fontSize: 40,
                                                                color: recordarea.color === undefined
                                                                    ? '' : recordarea.color}}/>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.textcenter}>
                                                        <Typography style={{backgroundColor: '#ffffff'}} >
                                                            <ToggleOffIcon style={{fontSize: 30, color: '#32c832'}}/>
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </Table>
                                </List>
                            </div>
                        </div>
                        : ' ' }
                    {openBottomNavigationUsuarioaCopiarNotificacion=== true ?
                        <div>
                            <div>
                                <List>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                                        {'Notificacion'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                                        {'NotificacionDescripción'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                        {'Color'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                        {'Estado'}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {ObjetoUsuarioaCopiarNotificacion.map(recordnotificacion => {
                                            return (
                                                <TableRow
                                                    // style={{backgroundColor: recordnotificacion.color === undefined
                                                    //     ? '' : recordnotificacion.color}}
                                                >
                                                    <TableCell className={classes.textleft}>
                                                        <Typography style={{color: '#000000'}}>
                                                            {recordnotificacion.notificacion_codigo_ing}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.textleft}>
                                                        <Typography style={{color: '#000000'}}>
                                                            {recordnotificacion.notificacion_d}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.textcenter}>
                                                        <Typography style={{color: '#000000'}}>
                                                            <LineWeightIcon style={{fontSize: 40,
                                                                color: recordnotificacion.color === undefined
                                                                    ? '' : recordnotificacion.color}}/>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.textcenter}>
                                                        <Typography style={{backgroundColor: '#ffffff'}} >
                                                            <ToggleOffIcon style={{fontSize: 30, color: '#32c832'}}/>
                                                        </Typography>
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })}
                                    </Table>
                                </List>
                            </div>
                        </div>
                        : ' ' }
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleCloseUsuarioaCopiar}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <ExitToAppIcon style={{color: '#004187'}}/>
                            Salir
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default BotonVerUsuarioaCopiar;