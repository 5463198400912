import React, {useCallback, useState} from "react";
import {
    FormTab,
    Show,
    TextInput,
    TabbedForm,
    TopToolbar,
    NumberInput,
    EditButton,
    usePermissions,
    useGetOne
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
    clase: {display: 'inline-block', width: 200, marginLeft: 8},
    clase_d: {display: 'inline-block', width: 600, marginLeft: 108},
    rubro: {display: 'inline-block', width: 200, marginLeft: 8},
    rubro_d: {display: 'inline-block', width: 600, marginLeft: 108},
}));

const RubroClaseShow = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    var w_role_auxiliar_editar = ''
    var w_permissions_admuno_auxiliar_editar = permissions
    if(w_permissions_admuno_auxiliar_editar==null){}else{
        var wa_role_auxiliar_editar = w_permissions_admuno_auxiliar_editar.indexOf('ROLE_AUXILIAR_EDITAR')
        if(wa_role_auxiliar_editar===-1){}else{
            w_role_auxiliar_editar = 'ROLE_AUXILIAR_EDITAR'
        }
    }
    const [state, setState] = useState({});

    /// Búsqueda Rubro
    var w_rubro_d = '';

    const {data: Data, loading: Loading} = useGetOne('RubroClase', props.id);

    console.log(Data)

    if (Data === undefined) {
    } else {
        if (Data.Rubro === undefined || Data.Rubro === null) {
        } else {
            w_rubro_d = Data.rubro ? Data.Rubro.rubro_d.trim() : '';
        }
    }

    const {
        RubroD = w_rubro_d,
    } = state;



    const formatRubroD = v => {
        return RubroD
    };



    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            {w_role_auxiliar_editar === 'ROLE_AUXILIAR_EDITAR' &&
                <EditButton basePath={basePath} record={data}/>
            }
        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        return <span>Rubro Clase: {record ? `${record.clase_d}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>
                <FormTab label="DATOS RUBRO CLASE">
                    <TextInput
                        label="Código Rubro"
                        source="rubro"
                        formClassName={classes.rubro}
                        placeholder="Ingrese Código Rubro"
                        helperText="Código Rubro"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <TextField
                        id="rubro_d-read-only-input"
                        value={RubroD}
                        InputProps={{
                            readOnly: true,
                        }}
                        formClassName={classes.rubro_d}
                        variant="filled"
                        label="Descripción Rubro"
                        placeholder="Ingrese Descripción Rubro"
                        helperText="Descripción Rubro"
                        margin="normal"
                        format={formatRubroD}
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>
                    <TextInput
                        label="Código Clase"
                        source="clase"
                        formClassName={classes.clase}
                        placeholder="Ingrese Código Clase"
                        helperText="Código Clase"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <TextInput
                        label="Descripción Clase"
                        source="clase_d"
                        formClassName={classes.clase_d}
                        placeholder="Ingrese Descripción Clase"
                        helperText="Descripción Clase"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                </FormTab>
            </TabbedForm>
        </Show>
    )
};
export default RubroClaseShow;


