import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
// import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {translate} from 'react-admin';
import sliderPrincipal from '../layout/headerRTA.jpg';

const styles = {};

const mediaUrl = sliderPrincipal;

const Welcome = ({classes, translate}) => (
    <Card>
        <div
            style={{
                display: "flex",
                alignItem: "center",
                justifyContent: "center"
            }}
        >

            <CardMedia
                style={{
                    width: "auto",
                    maxHeight: "120px"
                }}
                component="img"
                image={mediaUrl}
                title="RTA"
            />
        </div>
        <div  style={{
            display: "flex",
            alignItem: "center",
            justifyContent: "center"
        }}
        >
            <h2 variant="headline">
                {/*{'Contrataciones - Ordenes de Compras - Proveedores'}*/}
            </h2>
        </div>
    </Card>
);


const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(Welcome);
