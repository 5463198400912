import React, {useState} from "react";
import {FormTab, Show, TextInput, TabbedForm, TopToolbar, NumberInput, EditButton, usePermissions} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    secuencia_tipo: {display: 'inline-block', width: 600, marginLeft: 8},
    contratacion_d: {display: 'inline-block', width: 600, marginLeft: 8},
}));

const ContratacionSecuenciaTipoShow = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    var w_role_auxiliar_editar = ''
    var w_permissions_admuno_auxiliar_editar = permissions
    if(w_permissions_admuno_auxiliar_editar==null){}else{
        var wa_role_auxiliar_editar = w_permissions_admuno_auxiliar_editar.indexOf('ROLE_AUXILIAR_EDITAR')
        if(wa_role_auxiliar_editar===-1){}else{
            w_role_auxiliar_editar = 'ROLE_AUXILIAR_EDITAR'
        }
    }

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            {w_role_auxiliar_editar === 'ROLE_AUXILIAR_EDITAR' &&
                <EditButton basePath={basePath} record={data}/>
            }
        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        return <span>Contratacion Secuencia Tipo: {record ? `${record.secuencia_tipo}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>
                <FormTab label="DATOS CONTRATACION SECUENCIA TIPO">

                    <TextInput
                        label="Código Contratacion Secuencia Tipo"
                        source="secuencia_tipo"
                        formClassName={classes.secuencia_tipo}
                        placeholder="Ingrese Código Contratacion Secuencia Tipo"
                        helperText="Código Contratacion Secuencia Tipo"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>

                    <TextInput
                        label="Descripción Contratacion Secuencia Tipo"
                        source="secuencia_tipo_d"
                        formClassName={classes.secuencia_tipo_d}
                        placeholder="Ingrese Descripción Contratacion Secuencia Tipo"
                        helperText="Descripción Contratacion Secuencia Tipo"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>

                </FormTab>
            </TabbedForm>
        </Show>
    )
};
export default ContratacionSecuenciaTipoShow;


