import React from 'react'
import {
    Datagrid,
    EditButton,
    List,
    TextField,
    TextInput,
    Filter,
ShowButton, CardActions, WithPermissions, CreateButton, ExportButton,
} from 'react-admin';
import withStyles from "@material-ui/core/styles/withStyles";


const Filtros = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" variant="outlined" source="q" alwaysOn/>
        <TextInput label="Ciudad" source="ciudad" alwaysOn />
    </Filter>
);

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};

const role_auxiliar_editar_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if(w_permissions_control==null){}else{
        var w_role_auxiliar_editar = w_permissions_control.indexOf('ROLE_AUXILIAR_EDITAR')
        if(w_role_auxiliar_editar===-1){}else{
            w_role_control = 'ROLE_AUXILIAR_EDITAR'
        }
    }

    return w_role_control
}

const role_auxiliar_crear_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if(w_permissions_control==null){}else{
        var w_role_auxiliar_crear = w_permissions_control.indexOf('ROLE_AUXILIAR_CREAR')
        if(w_role_auxiliar_crear===-1){}else{
            w_role_control = 'ROLE_AUXILIAR_CREAR'
        }
    }

    return w_role_control
}

const role_exportar_csv = ({permissions}) => {
    var w_role_control = '';
    var w_permissions_control = permissions;
    if (w_permissions_control == null) {
    } else {
        var w_role_exportar_csv = w_permissions_control.indexOf('ROLE_EXPORTAR_CSV');
        if (w_role_exportar_csv === -1) {
        } else {
            w_role_control = 'ROLE_EXPORTAR_CSV'
        }
    }
    return w_role_control
};

const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <WithPermissions
            render={({ permissions }) => (
                role_auxiliar_crear_permissions({permissions }) === 'ROLE_AUXILIAR_CREAR'
                    ?
                    <CreateButton/>
                    : null
            )}
        />

        <WithPermissions
            render={({permissions}) => (
                role_exportar_csv({permissions}) === 'ROLE_EXPORTAR_CSV'
                    ?
                    <ExportButton/>
                    : null

            )}
        />
    </CardActions>
);

export const CiudadList = withStyles(styles)(({classes, permissions, ...props}) => {
    return (
    <List {...props} title="Listado de Ciudad"
          bulkActionButtons={false}
          perPage={25}
          actions={<PostActions/>}
          filters={<Filtros/>}>

        <Datagrid expand={false}>
            <TextField label="Ciudad" source="ciudad" />
            <ShowButton/>
            {role_auxiliar_editar_permissions({permissions}) === 'ROLE_AUXILIAR_EDITAR' &&
                <EditButton/>
            }
        </Datagrid>
    </List>
    )
});


export default CiudadList;
