import React from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar, maxLength, required
} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    descripcion: {display: 'inline-block', width: 400, marginLeft: 8},
    codigo: {display: 'inline-block', width: 200, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_valor = values.compraCpt;

    if ((w_valor === undefined) || (w_valor === null)) {
        errors.pais = ['El campo Código es requerido'];
    } else {
        w_valor = w_valor.trim();
        if (w_valor === '') {
            errors.pais = ['El campo Código es requerido'];
        }
    }

    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/CompraCpt`;

const CompraCptCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Comprobante de Compra</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // validate={validateCreate}
                redirect={redirect}
                // toolbar={<PostCreateToolbar />} 
            >
                {/*Datos*/}
                <FormTab label="Comprobante">
                    <TextInput label="Tipo" source="cpt_tipo" sortable={false} validate={[maxLength(10), required()]}/>
                    <TextInput label="Descripción" source="cpt_d" sortable={false} validate={[maxLength(60)]}/>
                    <TextInput label="Abrev." source="cpt_abre" sortable={false} validate={[maxLength(10)]}/>
                    <TextInput label="Letra" source="cpt_letra" sortable={false} validate={[maxLength(1)]}/>
                    <TextInput label="Sucursal" source="cpt_sucursal" sortable={false}/>
                    <TextInput label="Último Nro Usado" source="cpt_ultimo_nro_usado" sortable={false}/>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default CompraCptCreate;


