// import React, {useState, useEffect} from 'react'
// import {DataGrid, GridToolbarContainer, GridToolbarQuickFilter} from '@mui/x-data-grid';
// import {Datagrid, TextField, useDataProvider} from 'react-admin';
// import {useBuscadorProveTransfContextProvider} from "../Context/BuscadorProveedorClaseContextProvider";
//
//
// /// Columnas de la grilla
// const columns = [
//     {field: 'unidad_negocio', headerName: 'UN', width: 80},
//     {field: 'pro', headerName: 'Proveedor', width: 80},
//     {field: 'razon_social', headerName: 'Razón Social', width: 300},
//     {field: 'cuit_cuil', headerName: 'Cuit / Cuil', width: 120},
//     {field: 'd_iva', headerName: 'IVA', width: 250},
//     {field: 'correo_electronico', headerName: 'Correo Electronico', width: 250},
//     {field: 'telefono_area', headerName: 'Telefono Area', width: 150},
//     {field: 'telefono_nro', headerName: 'Telefono Nro', width: 250},
//     {field: 'fecha_alta_convert', headerName: 'Fecha Alta', width: 100},
//
//
// ];
//
// function CustomToolbar() {
//     return (
//         <GridToolbarContainer>
//             <GridToolbarQuickFilter/>
//         </GridToolbarContainer>
//     );
// }
//
// export const BuscarProveedoresList = () => {
//     const {addCuitCuil, limpiarNuevo, limpiarCuitCuil} = useBuscadorProveTransfContextProvider();
//
//     const [page, setPage] = useState(1);
//     const [perPage, setPerPage] = useState(10);
//     const [sort, setSort] = useState({field: 'cuit_cuil', order: 'ASC'})
//     const [total, setTotal] = useState(0);
//     const [loading, setLoading] = useState(false);
//     const [selectedCuitCuil, setSelectedCuitCuil] = useState([]);
//     const [data, setData] = useState([]);
//
//     const dataProvider = useDataProvider();
//
//     useEffect(() => {
//         limpiarNuevo();
//         limpiarCuitCuil();
//         setLoading(true);
//
//         dataProvider.getList(
//             'ProveedorTransferencia',
//             {
//                 filter: {
//                     grilla: 'ProveedorTransferencia'
//                 },
//                 sort,
//                 pagination: {page: page, perPage: perPage},
//             },)
//             .then(({data}) => {
//                 setData(data);
//                 setTotal(data.length);
//             })
//             .catch(error => {
//                 console.log(error)
//             })
//             .finally(
//                 setLoading(false)
//             )
//     }, [sort]);  /// Cada vez que cambiar el sort, se vuelve a ejecutar la llamada al servidor
//
//     // useEffect(() => {
//     //     console.log("total", total);
//     // }, [total]);
//
//     return (
//         <div style={{display: 'flex', flexDirection: 'column', height: '700px', width: '100%'}}>
//
//             <DataGrid
//                 disableVirtualization
//                 keepNonExistentRowsSelected
//                 checkboxSelection
//                 pagination
//                 NoRowsOverlay
//                 selectionModel={selectedCuitCuil}
//                 onSelectionModelChange={(e) => {  /// Cuando cambia la selección, guardo en estado
//                     setSelectedCuitCuil(e);
//                     addCuitCuil(e)
//                 }}
//                 rows={data}
//                 columns={columns}
//                 components={{Toolbar: CustomToolbar}}
//                 componentsProps={{
//                     toolbar: {
//                         showQuickFilter: true,
//                     },
//                 }}
//                 // components={{
//                 //     Toolbar: QuickSearchToolbar,
//                 //     // LoadingOverlay: LinearProgress
//                 // }}
//                 loading={loading}
//                 autoPageSize
//             />
//         </div>
//     )
// };
//
// export default BuscarProveedoresList


import React, {useState, useEffect} from 'react'
import {DataGrid, GridToolbarContainer, GridToolbarQuickFilter} from '@mui/x-data-grid';
import {FormTab, useDataProvider} from 'react-admin';
import {useBuscadorContext} from "../Context/BuscadorProveedorClaseContextProvider";
import ApiUrlServer from '../layout/ApiUrlServer'
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

var w_apiurlserver = ApiUrlServer;
if (w_apiurlserver == null) {
    w_apiurlserver = ''
}
w_apiurlserver = w_apiurlserver.trim();

const localizedTextsMap = {
    noRowsLabel: 'Sin filas',
    noResultsOverlayLabel: 'No se han encontrado resultados.',
    errorOverlayDefaultLabel: 'Ocurrió un error.',

    // Density selector toolbar button text
    toolbarDensity: 'Densidad',
    toolbarDensityLabel: 'Densidad',
    toolbarDensityCompact: 'Compacto',
    toolbarDensityStandard: 'Estándar',
    toolbarDensityComfortable: 'Cómodo',

    // Columns selector toolbar button text
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Seleccionar columnas',

    // Filters toolbar button text
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarFiltersTooltipHide: 'Ocultar filtros',
    toolbarFiltersTooltipShow: 'Mostrar filtros',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Búsqueda…',
    toolbarQuickFilterLabel: 'Búsqueda',
    toolbarQuickFilterDeleteIconLabel: 'Despejado',

    // Export selector toolbar button text
    toolbarExport: 'Exportar',
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'Descargar como CSV',
    toolbarExportPrint: 'Impresión',
    toolbarExportExcel: 'Descargar como Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Buscar columna',
    columnsPanelTextFieldPlaceholder: 'Título de la columna',
    columnsPanelDragIconLabel: 'Reordenar columna',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',

    // Filter panel text
    filterPanelAddFilter: 'Añadir filtro',
    filterPanelDeleteIconLabel: 'Borrar',
    filterPanelLinkOperator: 'Operador lógico',
    filterPanelOperators: 'Operador', // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor del filtro',

    // Filter operators text
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'es igual',
    filterOperatorStartsWith: 'comienza con',
    filterOperatorEndsWith: 'termina con',
    filterOperatorIs: 'es',
    filterOperatorNot: 'no es',
    filterOperatorAfter: 'es despues',
    filterOperatorOnOrAfter: 'está en o después',
    filterOperatorBefore: 'es antes',
    filterOperatorOnOrBefore: 'está en o antes',
    filterOperatorIsEmpty: 'Está vacío',
    filterOperatorIsNotEmpty: 'No esta vacío',
    filterOperatorIsAnyOf: 'es cualquiera de',

    // Filter values text
    filterValueAny: 'ningún',
    filterValueTrue: 'verdadero',
    filterValueFalse: 'falso',

    // Column menu text
    columnMenuLabel: 'Menú',
    columnMenuShowColumns: 'Mostrar columnas',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Ocultar',
    columnMenuUnsort: 'Desclasificar',
    columnMenuSortAsc: 'Ordenar por ASC',
    columnMenuSortDesc: 'Ordenar por DESC',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,
    columnHeaderFiltersLabel: 'Mostrar filtros',
    columnHeaderSortIconLabel: 'Clasificar',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
            ? `${count.toLocaleString()} filas seleccionadas`
            : `${count.toLocaleString()} fila seleccionada`,

    // Total row amount footer text
    footerTotalRows: 'Filas Totales:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Selección de casilla de verificación',
    checkboxSelectionSelectAllRows: 'Seleccionar todas las filas',
    checkboxSelectionUnselectAllRows: 'Deseleccionar todas las filas',
    checkboxSelectionSelectRow: 'Seleccionar fila',
    checkboxSelectionUnselectRow: 'Deseleccionar fila',

    // Texto de celda booleano
    booleanCellTrueLabel: 'sí',
    booleanCellFalseLabel: 'no',

    // Celda de acciones más texto
    actionsCellMore: 'más',

    // Texto de anclaje de columna
    pinToLeft: 'Alfiler a la izquierda',
    pinToRight: 'Alfiler a derecha',
    unpin: 'Desprender',

    // Tree Data
    treeDataGroupingHeaderName: 'Grupo',
    treeDataExpand: 'ver niños',
    treeDataCollapse: 'ocultar niños',

    // Grouping columns
    groupingColumnHeaderName: 'Grupo',
    groupColumn: (name) => `Agrupar por ${name}`,
    unGroupColumn: (name) => `Deja de agrupar por ${name}`,

    // Master/detail
    detailPanelToggle: 'Alternar panel de detalles',
    expandDetailPanel: 'Expandir',
    collapseDetailPanel: 'Colapsar',

    // Claves de traducción de componentes principales usadas
    MuiTablePagination: {
        labelRowsPerPage: 'Filas por página:',
        labelDisplayedRows: ({ from, to, count }) =>
            `${from} - ${to} de mas de ${count}`,
    },

    // Texto de reordenación de filas
    rowReorderingHeaderName: 'Reordenación de filas',

    // Agregación
    aggregationMenuItemHeader: 'Agregación',
    aggregationFunctionLabelSum: 'suma',
    aggregationFunctionLabelAvg: 'promedio',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'Talla',


};


/// Columnas de la grilla
const columns = [
    // {field: 'id', headerName: 'ID', width: 150, hide: true},
    {field: 'unidad_negocio', headerName: 'Unidad', width: 80},
    {field: 'pro', headerName: 'Proveedor', width: 80},
    {field: 'razon_social', headerName: 'Razón Social', width: 300},
    {field: 'cuit_cuil', headerName: 'Cuit / Cuil', width: 120},
    {field: 'd_iva', headerName: 'IVA', width: 250},
    {field: 'correo_electronico', headerName: 'Correo Electronico', width: 250},
    {field: 'telefono_area', headerName: 'Telefono Area', width: 150},
    {field: 'telefono_nro', headerName: 'Telefono Nro', width: 250},
    {field: 'fecha_alta', headerName: 'Fecha Alta', type: 'date', width: 100,
        valueGetter: ({ value }) => value && new Date(value)},
    {field: 'fecha_baja', headerName: 'Fecha Baja', type: 'date', width: 100, hide: true,
        valueGetter: ({ value }) => value && new Date(value)},


    {field: 'comercial_provincia', headerName: 'Provincia', width: 250, hide: true},
    {field: 'comercial_ciudad', headerName: 'Ciudad', width: 250, hide: true},
    {field: 'comercial_calle', headerName: 'Calle', width: 250, hide: true},
    {field: 'comercial_calle_nro', headerName: 'Nro', width: 100, hide: true},
    {field: 'comercial_piso', headerName: 'Piso', width: 100, hide: true},
    {field: 'comercial_departamento', headerName: 'Dpt', width: 100, hide: true},
    {field: 'comercial_codigo_postal', headerName: 'CP', width: 150, hide: true},
    // {field: 'correo_electronico', headerName: 'ID', width: 150, hide: true},
    {field: 'correo_electronico_alternativo', headerName: 'Correo Electronico Alternativo', width: 300, hide: true},
    // {field: 'telefono_area', headerName: 'ID', width: 150, hide: true},
    // {field: 'telefono_nro', headerName: 'ID', width: 150, hide: true},
    {field: 'fax_area', headerName: 'Fax Area', width: 150, hide: true},
    {field: 'fax_nro', headerName: 'Fax Nro', width: 250, hide: true},
    {field: 'documento_tipo_d', headerName: 'Documento Tipo', width: 100, hide: true},
    // {field: 'documento_nro', headerName: 'Documento Nro', width: 150, hide: true},
    // {field: 'cuit_cuil', headerName: 'ID', width: 150, hide: true},
    // {field: 'iva', headerName: 'ID', width: 150, hide: true},
    {field: 'ibru_nro', headerName: 'Ibru Nro', width: 150, hide: true},
    {field: 'observacion', headerName: 'Observacion', width: 500, hide: true},
    {field: 'agenda', headerName: 'Agenda', width: 500, hide: true},
    {field: 'codigo_pro_tvpublica', headerName: 'Cod. Pro. TV Publica', width: 150, hide: true},
    {field: 'codigo_pro_rna', headerName: 'Cod. Pro. RNA', width: 150, hide: true},
    // {field: 'estado_proveedor', headerName: 'ID', width: 150, hide: true},

];

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter/>
        </GridToolbarContainer>
    );
}

export const BuscarRubrosList = () => {
    const {addRubros, limpiarClases, limpiarRubros} = useBuscadorContext();
    const [EstadoUnidadNegocio, setEstadoUnidadNegocio] = useState(1);
    const [Estadounidad_negocio, setEstadounidad_negocio] = useState('Canal7');
    const [ComboManualUnidadNegocio, setComboManualUnidadNegocio] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({field: 'fecha_alta', order: 'DESC'})
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedRubros, setSelectedRubros] = useState([]);
    const [data, setData] = useState([]);

    const [sortModel, setSortModel] = React.useState([
        {
            field: 'fecha_alta',
            sort: 'asc',
        },
    ]);

    const dataProvider = useDataProvider();

    useEffect(() => {
        limpiarClases();
        limpiarRubros();
        setLoading(true);
        dataProvider.getList(
            'UnidadNegocio',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'P' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboManualUnidadNegocio(data);
            })
            .catch(error => {

            })
        dataProvider.getList(
            'ProveedorTransferencia',
            {
                filter: {
                    grilla: 'ProveedorTransferencia',
                    apiurlserver: w_apiurlserver, unidad_negocio: Estadounidad_negocio
                },
                sort,
                pagination: {page: page, perPage: perPage},
            },)
            .then(({data}) => {
                setData(data);
                setTotal(data.length);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(
                setLoading(false)
            )
    }, [sort,EstadoUnidadNegocio]);  /// Cada vez que cambiar el sort, se vuelve a ejecutar la llamada al servidor

    // useEffect(() => {
    //     console.log("total", total);
    // }, [total]);

    const CambiaEstado = () => {
        setEstadoUnidadNegocio(EstadoUnidadNegocio + 1);
    };


    const handleunidad_negocio = (event) => {
        const Cambiaunidad_negocio1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadounidad_negocio(wp_value)
            CambiaEstado()
        };
        var w_value = event.target.value
        Cambiaunidad_negocio1(w_value)

    };

    return (
        <div>
            <Typography variant="h6">Unidad Negocio</Typography>
            <TextField
                id="unidad_negocio_ing"
                select
                label=""
                formClassName={{display: 'inline-block', width: 400, marginLeft: 8}}
                value={Estadounidad_negocio}
                onChange={handleunidad_negocio}
                SelectProps={{
                    native: true,
                }}
                fullWidth={true}
            >
                {ComboManualUnidadNegocio.map((option) => (
                    <option key={option.unidad_negocio} value={option.unidad_negocio}>
                        {option.unidad_negocio_d}
                    </option>
                ))}
            </TextField>
        <div style={{display: 'flex', flexDirection: 'column', height: '700px', width: '100%'}}>

            <DataGrid
                disableVirtualization
                keepNonExistentRowsSelected
                checkboxSelection
                pagination
                NoRowsOverlay
                selectionModel={selectedRubros}
                onSelectionModelChange={(e) => {  /// Cuando cambia la selección, guardo en estado
                    setSelectedRubros(e);
                    addRubros(e)
                }}
                rows={data}
                columns={columns}
                components={{Toolbar: CustomToolbar}}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                // components={{
                //     Toolbar: QuickSearchToolbar,
                //     // LoadingOverlay: LinearProgress
                // }}
                loading={loading}
                autoPageSize
                localeText={localizedTextsMap}
                rowsPerPageOptions={[5, 10]}
            />
        </div>

        </div>
    )
};

export default BuscarRubrosList