import React from 'react';
import Grid from '@material-ui/core/Grid';
import VerInfo from './VerInfo'
import {Datagrid, DateField, NumberField, TextField} from "react-admin";

// Método que carga más datos de cada registro. Haciendo Click en el registro se expande hacia abajo.
const ContratacionEncuadreInfo = ({record, ...rest}) => (
    <Grid container spacing={12}>
        <Grid container spacing={12}>
            <Grid container spacing={12}>
                <Grid item xs={12}>
                    <VerInfo
                        ver_concepto_descripcion={record.concepto_descripcion}
                        ver_estado_encuadre={record.estado_encuadre}
                        ver_estado_encuadre_d={record.estado_encuadre_d}
                        ver_en_progreso={record.en_progreso}
                        ver_en_progreso_motivo={record.en_progreso_motivo}
                        ver_en_impugnacion={record.en_impugnacion}
                        ver_en_impugnacion_motivo={record.en_impugnacion_motivo}
                        ver_en_suspenso={record.en_suspenso}
                        ver_en_suspenso_motivo={record.en_suspenso_motivo}
                        ver_en_revision={record.en_revision}
                        ver_en_revision_motivo={record.en_revision_motivo}
                        ver_en_anulado={record.en_anulado}
                        ver_en_anulado_motivo={record.en_anulado_motivo}
                        ver_en_finalizado={record.en_finalizado}
                        ver_en_finalizado_motivo={record.en_finalizado_motivo}
                        ver_visita_usuario={record.visita_usuario}
                        ver_visita_usuario_fh={record.visita_usuario_fh}
                        ver_unidad_area_requiere={record.unidad_area_requiere}
                        ver_unidad_area_d={record.unidad_area_d}
                        ver_moneda_cotizacion={record.moneda_cotizacion}
                        ver_moneda_cotizacion_fecha={record.moneda_cotizacion_fecha}
                        ver_importe_adjudicado_pesos={record.importe_adjudicado_pesos}
                        ver_es_tablero={record.es_tablero}
                        ver_contratacion_tipo={record.contratacion_tipo}
                        ver_contratacion_d={record.contratacion_d}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>

);
export default ContratacionEncuadreInfo;
