import React, {useCallback, useEffect, useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput,
    NumberField,
    Datagrid,
    DateInput,
    SaveButton,
    Link,
    useDataProvider,
    useNotify,
    useRefresh,
    useMutation,
    useRedirect
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";
import ComboContratacionTipo from "../componentes/ComboContratacionTipo";
import ComboArea from "../componentes/ComboArea";
import ComboActivoMoneda from "../componentes/ComboMonedaActivo";
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from "@material-ui/core/FormHelperText";
import NumberFormat from 'react-number-format';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import PropTypes from 'prop-types';
import UnidadAreaBusquedaBtn from "../UnidadArea/UnidadAreaBusquedaBtn";

const useStylesDetelle = makeStyles((theme) => ({
    contratacion_tipo: {display: 'inline-block', width: 850, marginLeft: 8},
    unidad_negocio: {display: 'inline-block', width: 400, marginLeft: 8},
    btnBusquedaUnidadArea: {display: 'inline-block', width: 40, marginLeft: 208},
    unidad_area: {display: 'inline-block', width: 40, marginLeft: 8},
    unidad_area_d: {display: 'inline-block', width: 555, marginLeft: 8},
    id: {display: 'inline-block', width: 200, marginLeft: 8},
    fecha_encuadre: {display: 'inline-block', width: 200, marginLeft: 8},
    expediente_referencia_gde: {display: 'inline-block', width: 850, marginLeft: 8},
    expediente_referencia_gde2: {display: 'inline-block', width: 800, marginLeft: 58},
    contratacion_ano: {display: 'inline-block', width: 200, marginLeft: 108},
    moneda: {display: 'inline-block', width: 400, marginLeft: 8},
    moneda_cotizacion: {display: 'inline-block', width: 80, marginLeft: 2},
    moneda_cotizacion_fecha: {display: 'inline-block', width: 150, marginLeft: 90},
    importe_presupuesto: {display: 'inline-block', width: 200, marginLeft: 8},
    importe_presupuesto_pesos: {display: 'inline-block', width: 200, marginLeft: 108},
    importe_adjudicado_pesos: {display: 'inline-block', width: 200, marginLeft: 88},
    concepto_descripcion: {display: 'inline-block', width: 850, marginLeft: 8},
    estado_encuadre2: {display: 'inline-block', width: 200, marginLeft: 8},
}));


// redirect to the related Author show page
const redirect = (basePath, id, data) => `/ContratacionEncuadre`;

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
            isNumericString
            prefix=""
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const ContratacionEncuadreButtonEdit = ({record}) => {
    const classesDetelle = useStylesDetelle();
    // console.log('ContratacionEncuadreButtonEdit----record')
    // console.log(record)

    var wr_id = record.id

    var wr_moneda = record.moneda
    if(wr_moneda==null){
        wr_moneda=''
    }
    wr_moneda=wr_moneda.trim()
    var we_variantmoneda_cotizacion
    var we_readOnlymoneda_cotizacion

    if(wr_moneda==='PES'){
        we_variantmoneda_cotizacion = 'filled'
        we_readOnlymoneda_cotizacion = true
    }else {
        we_variantmoneda_cotizacion = 'outlined'
        we_readOnlymoneda_cotizacion = false
    }

    var wr_expediente_referencia_gde = record.expediente_referencia_gde
    if(wr_expediente_referencia_gde==null){
        wr_expediente_referencia_gde=''
    }
    wr_expediente_referencia_gde=wr_expediente_referencia_gde.trim()

    var wr_contratacion_tipo_encuadre = record.contratacion_tipo_encuadre
    if(wr_contratacion_tipo_encuadre==null){
        wr_contratacion_tipo_encuadre=''
    }
    // wr_contratacion_tipo_encuadre=wr_contratacion_tipo_encuadre.trim()

    // console.log('wr_contratacion_tipo_encuadre')
    // console.log(wr_contratacion_tipo_encuadre)

    var wr_contratacion_ano =  record.contratacion_ano

    var wr_unidad_negocio = record.unidad_negocio_requiere
    if(wr_unidad_negocio==null){
        wr_unidad_negocio=''
    }
    wr_unidad_negocio=wr_unidad_negocio.trim()

    var wr_unidad_area =  record.UnidadArea


    var dateFechaEncuadre = new Date(record.fecha_encuadre);
    var duedateFechaEncuadre = new Date(dateFechaEncuadre);
    duedateFechaEncuadre.setDate(dateFechaEncuadre.getDate());
    var dFechaEncuadre = new Date(duedateFechaEncuadre || Date.now()),
        monthFechaEncuadre = '' + (dFechaEncuadre.getMonth() + 1),
        dayFechaEncuadre = '' + (dFechaEncuadre.getDate()),
        yearFechaEncuadre = dFechaEncuadre.getFullYear();

    // if(wr_contratacion_ano==null){
    //     wr_contratacion_ano=yearFechaEncuadre
    // }
    //console.log(month)

    if (monthFechaEncuadre.length < 2) monthFechaEncuadre = '0' + monthFechaEncuadre;
    if (dayFechaEncuadre.length < 2) dayFechaEncuadre = '0' + dayFechaEncuadre;

    // var w_f_carga1FechaEncuadre = [dayFechaEncuadre, monthFechaEncuadre, yearFechaEncuadre].join('/');
    var w_f_carga2FechaEncuadre = [yearFechaEncuadre, monthFechaEncuadre, dayFechaEncuadre].join('-');
    // console.log(w_f_carga2FechaEncuadre)

    var dateMonedaCotizacionFecha = new Date(record.moneda_cotizacion_fecha);
    var duedateMonedaCotizacionFecha = new Date(dateMonedaCotizacionFecha);
    duedateMonedaCotizacionFecha.setDate(dateMonedaCotizacionFecha.getDate()-1);
    var dMonedaCotizacionFecha = new Date(duedateMonedaCotizacionFecha || Date.now()),
        monthMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getMonth() + 1),
        dayMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getDate()),
        yearMonedaCotizacionFecha = dMonedaCotizacionFecha.getFullYear();

    //console.log(month)

    if (monthMonedaCotizacionFecha.length < 2) monthMonedaCotizacionFecha = '0' + monthMonedaCotizacionFecha;
    if (dayMonedaCotizacionFecha.length < 2) dayMonedaCotizacionFecha = '0' + dayMonedaCotizacionFecha;

    // var w_f_carga1MonedaCotizacionFecha = [dayMonedaCotizacionFecha, monthMonedaCotizacionFecha, yearMonedaCotizacionFecha].join('/');
    var w_f_carga2MonedaCotizacionFecha = [yearMonedaCotizacionFecha, monthMonedaCotizacionFecha, dayMonedaCotizacionFecha].join('-');
    // console.log(w_f_carga2MonedaCotizacionFecha)

    const dataProviderTableroControl = useDataProvider();
    const [EstadoID, setEstadoID] = useState(wr_id);
    const [EstadoContratacionEncuadre, setEstadoContratacionEncuadre] = useState(1);
    const [Estadofecha_encuadre, setEstadofecha_encuadre] = useState(w_f_carga2FechaEncuadre)
    const [errorfecha_encuadre, setErrorfecha_encuadre] = useState(false);
    const [errorcontratacion_ano, setErrorcontratacion_ano] = useState(false);
    const [helperTextcontratacion_ano, setHelperTextcontratacion_ano] = useState('');
    const [helperTextfecha_encuadre, setHelperTextfecha_encuadre] = useState('');
    const [Estadoexpediente_referencia_gde, setEstadoexpediente_referencia_gde] = useState(wr_expediente_referencia_gde)
    const [Estadocontratacion_tipo, setEstadocontratacion_tipo] = useState(wr_contratacion_tipo_encuadre)
    const [Estadocontratacion_ano, setEstadocontratacion_ano] = useState(wr_contratacion_ano)
    const [Estadomoneda, setEstadomoneda] = useState(wr_moneda)
    const [Estadomoneda_cotizacion, setEstadomoneda_cotizacion] = useState(record.moneda_cotizacion)
    const [EstadoreadOnlymoneda_cotizacion, setEstadoreadOnlymoneda_cotizacion] = useState(we_readOnlymoneda_cotizacion);
    const [Estadovariantmoneda_cotizacion, setEstadovariantmoneda_cotizacion] = useState(we_variantmoneda_cotizacion);
    const [Estadomoneda_cotizacion_fecha, setEstadomoneda_cotizacion_fecha] = useState(w_f_carga2MonedaCotizacionFecha)
    const [EstadoreadOnlymoneda_cotizacion_fecha, setEstadoreadOnlymoneda_cotizacion_fecha] = useState(true);
    const [Estadovariantmoneda_cotizacion_fecha, setEstadovariantmoneda_cotizacion_fecha] = useState('filled');
    const [errormoneda_cotizacion, setErrormoneda_cotizacion] = useState(false);
    const [helperTextmoneda_cotizacion, setHelperTextmoneda_cotizacion] = useState('');
    const [errormoneda_cotizacion_fecha, setErrormoneda_cotizacion_fecha] = useState(false);
    const [helperTextmoneda_cotizacion_fecha, setHelperTextmoneda_cotizacion_fecha] = useState('');
    const [errorimporte_presupuesto, setErrorimporte_presupuesto] = useState(false);
    const [helperTextimporte_presupuesto, setHelperTextimporte_presupuesto] = useState('');
    const [Estadoimporte_presupuesto, setEstadoimporte_presupuesto] = useState(record.importe_presupuesto)
    const [Estadoimporte_presupuesto_pesos, setEstadoimporte_presupuesto_pesos] = useState(record.importe_presupuesto_pesos)
    const [Estadoimporte_adjudicado_pesos, setEstadoimporte_adjudicado_pesos] = useState(record.importe_adjudicado_pesos)
    const [Estadoconcepto_descripcion, setEstadoconcepto_descripcion] = useState(record.concepto_descripcion)
    const [ComboMoneda, setComboMoneda] = useState([]);
    const [ComboManualContratacionTipo, setComboManualContratacionTipo] = useState([]);
    const [Estadounidad_negocio, setEstadounidad_negocio] = useState(wr_unidad_negocio);
    const [Estadounidad_area, setEstadounidad_area] = useState(wr_unidad_area);
    const [ComboManualUnidadNegocio, setComboManualUnidadNegocio] = useState([]);
    const [ComboManualArea, setComboManualArea] = useState([]);

    const [errorcontratacion_tipo, setErrorcontratacion_tipo] = useState(false);
    const [helperTextcontratacion_tipo, setHelperTextcontratacion_tipo] = useState('');

    const [errormoneda, setErrormoneda] = useState(false);
    const [helperTextmoneda, setHelperTextmoneda] = useState('');

    const [errorunidad_negocio, setErrorunidad_negocio] = useState(false);
    const [helperTextunidad_negocio, setHelperTextunidad_negocio] = useState('');

    const [errorunidad_area, setErrorunidad_area] = useState(false);
    const [helperTextunidad_area, setHelperTextunidad_area] = useState('Código Unidad Area');

    const [errorexpediente_referencia_gde, setErrorexpediente_referencia_gde] = useState(false);
    const [helperTextexpediente_referencia_gde, setHelperTextexpediente_referencia_gde] = useState('');


    const [errorconcepto_descripcion, setErrorconcepto_descripcion] = useState(false);
    const [helperTextconcepto_descripcion, setHelperTextconcepto_descripcion] = useState('');



    useEffect(() => {
        dataProviderTableroControl.getList(
            'MonedaActivo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'U' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboMoneda(data);
            })
            .catch(error => {

            })
        dataProviderTableroControl.getList(
            'ContratacionTipo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'U' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboManualContratacionTipo(data);
            })
            .catch(error => {

            })
        dataProviderTableroControl.getList(
            'UnidadNegocio',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'U' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboManualUnidadNegocio(data);
            })
            .catch(error => {

            })
        dataProviderTableroControl.getList(
            'UnidadArea',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'U', unidad_negocio: Estadounidad_negocio },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboManualArea(data);
            })
            .catch(error => {

            })
    }, [EstadoContratacionEncuadre]);

    const CambiaEstado = () => {
        setEstadoContratacionEncuadre(EstadoContratacionEncuadre + 1);
    };


    const handlemoneda = (event) => {
        const Cambiamoneda1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadomoneda(wp_value)

            var w_moneda = wp_value
            if(w_moneda==null) {
                w_moneda = ''
            }
            w_moneda=w_moneda.trim()
            if(w_moneda==='PES'){
                var wh_importe_presupuesto = Estadoimporte_presupuesto
                var wh_cotizacion = 1

                // console.log('wh_importe_presupuesto')
                // console.log(wh_importe_presupuesto)
                // console.log('wh_cotizacion')
                // console.log(wh_cotizacion)
                var w_importe_presupuesto_pesos = (wh_importe_presupuesto * wh_cotizacion)
                w_importe_presupuesto_pesos = Math.round(w_importe_presupuesto_pesos * 100) / 100

                setEstadoimporte_presupuesto_pesos(w_importe_presupuesto_pesos)
                setEstadomoneda_cotizacion(wh_cotizacion)
                setEstadovariantmoneda_cotizacion('filled')
                setEstadoreadOnlymoneda_cotizacion(true)
                // setEstadovariantmoneda_cotizacion_fecha('filled')
                // setEstadoreadOnlymoneda_cotizacion_fecha(true)
            }else{
                setEstadovariantmoneda_cotizacion('outlined')
                setEstadoreadOnlymoneda_cotizacion(false)
                // setEstadovariantmoneda_cotizacion_fecha('outlined')
                // setEstadoreadOnlymoneda_cotizacion_fecha(false)

                var w_estadofecha_encuadre =  Estadofecha_encuadre
                if(w_estadofecha_encuadre==null){
                    w_estadofecha_encuadre=''
                }
                w_estadofecha_encuadre=w_estadofecha_encuadre.trim()
                var dateMonedaCotizacionFecha = new Date(w_estadofecha_encuadre+"T00:00:00");
                // console.log('dateMonedaCotizacionFecha')
                // console.log(dateMonedaCotizacionFecha)
                var duedateMonedaCotizacionFecha = new Date(dateMonedaCotizacionFecha);
                duedateMonedaCotizacionFecha.setDate(dateMonedaCotizacionFecha.getDate()-1);
                var dMonedaCotizacionFecha = new Date(duedateMonedaCotizacionFecha || Date.now()),
                    monthMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getMonth() + 1),
                    dayMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getDate()),
                    yearMonedaCotizacionFecha = dMonedaCotizacionFecha.getFullYear();

                //console.log(month)

                if (monthMonedaCotizacionFecha.length < 2) monthMonedaCotizacionFecha = '0' + monthMonedaCotizacionFecha;
                if (dayMonedaCotizacionFecha.length < 2) dayMonedaCotizacionFecha = '0' + dayMonedaCotizacionFecha;

                var w_f_carga1MonedaCotizacionFecha = [dayMonedaCotizacionFecha, monthMonedaCotizacionFecha, yearMonedaCotizacionFecha].join('/');
                var w_f_carga2MonedaCotizacionFecha = [yearMonedaCotizacionFecha, monthMonedaCotizacionFecha, dayMonedaCotizacionFecha].join('-');
                // console.log('w_f_carga2MonedaCotizacionFecha')
                // console.log(w_f_carga2MonedaCotizacionFecha)
                setEstadomoneda_cotizacion_fecha(w_f_carga2MonedaCotizacionFecha)

                dataProviderTableroControl.getList(
                    'MonedaActivo',
                    {
                        filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'C',
                            fecha: w_f_carga1MonedaCotizacionFecha, codigo_afip: w_moneda },
                        sort: {field: 'id', order: 'DESC'},
                        pagination: {page: 1, perPage: 100},
                    },)
                    .then(({data}) => {
                        // console.log(data)
                        // console.log(data[0].cotiza)
                        setEstadomoneda_cotizacion(data[0].cotiza);
                    })
                    .catch(error => {

                    })
            }

        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextmoneda('');
        setErrormoneda(false)
        Cambiamoneda1(w_value)

    };

    const handleunidad_negocio = (event) => {
        const Cambiaunidad_negocio1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadounidad_negocio(wp_value)
            setHelperTextunidad_area('Debe Ingresar Area');
            setErrorunidad_area(true)
            setEstadounidad_area(stateunidad_area => ({
                ...stateunidad_area,
                area: '',
            }));
            setStateunidad_area(stateunidad_area => ({
                ...stateunidad_area,
                area: '',
                areaD: '',
            }));
            CambiaEstado()
        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextunidad_negocio('');
        Cambiaunidad_negocio1(w_value)

    };

    const handleunidad_area = (event) => {
        const Cambiaunidad_area1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadounidad_area(wp_value)
        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextunidad_area('Código Unidad Area');
        setErrorunidad_area(false)
        Cambiaunidad_area1(w_value)

    };

    const handlecontratacion_tipo = (event) => {
        const Cambiacontratacion_tipo1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadocontratacion_tipo(wp_value)
        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextcontratacion_tipo('')
        Cambiacontratacion_tipo1(w_value)

    };

    const handlecontratacion_ano = (event) => {
        const Cambiacontratacion_ano1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadocontratacion_ano(wp_value)
        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextcontratacion_ano('');
        setErrorcontratacion_ano(false)
        Cambiacontratacion_ano1(w_value)

    };

    const handlemoneda_cotizacion = (event) => {
        const Cambiamoneda_cotizacion1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadomoneda_cotizacion(wp_value)

            var wh_importe_presupuesto = Estadoimporte_presupuesto
            var wh_cotizacion = wp_value

            // console.log('wh_importe_presupuesto')
            // console.log(wh_importe_presupuesto)
            // console.log('wh_cotizacion')
            // console.log(wh_cotizacion)
            var w_importe_presupuesto_pesos = (wh_importe_presupuesto * wh_cotizacion)
            w_importe_presupuesto_pesos = Math.round(w_importe_presupuesto_pesos * 100) / 100

            setEstadoimporte_presupuesto_pesos(w_importe_presupuesto_pesos)
        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        setHelperTextmoneda_cotizacion('');
        setErrormoneda_cotizacion(false)
        Cambiamoneda_cotizacion1(w_value)

    };

    const handleimporte_presupuesto = (event) => {
        const Cambiaimporte_presupuesto1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadoimporte_presupuesto(wp_value)

            var wh_importe_presupuesto = wp_value
            var wh_cotizacion = Estadomoneda_cotizacion

            // console.log('wh_importe_presupuesto')
            // console.log(wh_importe_presupuesto)
            // console.log('wh_cotizacion')
            // console.log(wh_cotizacion)
            var w_importe_presupuesto_pesos = (wh_importe_presupuesto * wh_cotizacion)
            w_importe_presupuesto_pesos = Math.round(w_importe_presupuesto_pesos * 100) / 100

            setEstadoimporte_presupuesto_pesos(w_importe_presupuesto_pesos)

        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextimporte_presupuesto('');
        setErrorimporte_presupuesto(false)
        Cambiaimporte_presupuesto1(w_value)

    };

    const handleimporte_presupuesto_pesos = (event) => {
        const Cambiaimporte_presupuesto_pesos1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadoimporte_presupuesto_pesos(wp_value)
        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiaimporte_presupuesto_pesos1(w_value)

    };

    const handleimporte_adjudicado_pesos = (event) => {
        const Cambiaimporte_adjudicado_pesos1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadoimporte_adjudicado_pesos(wp_value)
        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiaimporte_adjudicado_pesos1(w_value)

    };


    const handleexpediente_referencia_gde = (event) => {
        const Cambiaexpediente_referencia_gde1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadoexpediente_referencia_gde(wp_value)
        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextexpediente_referencia_gde('')
        setErrorexpediente_referencia_gde(false)
        Cambiaexpediente_referencia_gde1(w_value)

    };

    const handleconcepto_descripcion = (event) => {
        const Cambiaconcepto_descripcion1 = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadoconcepto_descripcion(wp_value)
        };

        var w_value = event.target.value
        // console.log('w_value')
        // console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setErrorconcepto_descripcion(false);
        setHelperTextconcepto_descripcion('');
        Cambiaconcepto_descripcion1(w_value)

    };

    const cambiafecha_encuadre = (event) => {
        const Cambiafecha_encuadre = (wp_value) => {
            // console.log('wp_value----------------------Cambiafecha_encuadre')
            // console.log(wp_value)
            setEstadofecha_encuadre(wp_value)
            // console.log('Estadofecha_encuadre')
            // console.log(Estadofecha_encuadre)

            var dateMonedaCotizacionFecha = new Date(wp_value+"T00:00:00");
            // console.log('dateMonedaCotizacionFecha')
            // console.log(dateMonedaCotizacionFecha)
            var duedateMonedaCotizacionFecha = new Date(dateMonedaCotizacionFecha);
            duedateMonedaCotizacionFecha.setDate(dateMonedaCotizacionFecha.getDate()-1);
            var dMonedaCotizacionFecha = new Date(duedateMonedaCotizacionFecha || Date.now()),
                monthMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getMonth() + 1),
                dayMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getDate()),
                yearMonedaCotizacionFecha = dMonedaCotizacionFecha.getFullYear();

            //console.log(month)

            if (monthMonedaCotizacionFecha.length < 2) monthMonedaCotizacionFecha = '0' + monthMonedaCotizacionFecha;
            if (dayMonedaCotizacionFecha.length < 2) dayMonedaCotizacionFecha = '0' + dayMonedaCotizacionFecha;

            var w_f_carga1MonedaCotizacionFecha = [dayMonedaCotizacionFecha, monthMonedaCotizacionFecha, yearMonedaCotizacionFecha].join('/');
            var w_f_carga2MonedaCotizacionFecha = [yearMonedaCotizacionFecha, monthMonedaCotizacionFecha, dayMonedaCotizacionFecha].join('-');
            // console.log('w_f_carga2MonedaCotizacionFecha')
            // console.log(w_f_carga2MonedaCotizacionFecha)
            setEstadomoneda_cotizacion_fecha(w_f_carga2MonedaCotizacionFecha)

            var w_moneda = Estadomoneda
            if(w_moneda==null) {
                w_moneda = ''
            }
            w_moneda=w_moneda.trim()
            if(w_moneda==='PES'){}else {

                dataProviderTableroControl.getList(
                    'MonedaActivo',
                    {
                        filter: {
                            filtros: 'N', ingreso_por: 'Z', usar_todos: 'C',
                            fecha: w_f_carga1MonedaCotizacionFecha, codigo_afip: Estadomoneda
                        },
                        sort: {field: 'id', order: 'DESC'},
                        pagination: {page: 1, perPage: 100},
                    },)
                    .then(({data}) => {
                        // console.log(data)
                        // console.log(data[0].cotiza)
                        setEstadomoneda_cotizacion(data[0].cotiza);
                    })
                    .catch(error => {

                    })
            }
        };
        const Cambiafecha_encuadreError = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadofecha_encuadre(wp_value)
            // console.log('Estadofecha_encuadre')
            // console.log(Estadofecha_encuadre)
            setErrorfecha_encuadre(true);

        };
        setHelperTextfecha_encuadre('')
        setErrorfecha_encuadre(false);
        var w_value = event.target.value
        // console.log('w_value----------------cambiafecha_encuadre')
        // console.log(w_value)
        if(w_value){
            if(w_value===null){
                w_value=''
            }
            w_value=w_value.trim()
            var w_value_length = w_value.length
            // console.log('w_value_length')
            // console.log(w_value_length)
            if(w_value_length===10) {
                var wh_substring = w_value.substring(0, 4)
                // console.log('wh_substring')
                // console.log(wh_substring)
                var wh_ano = parseInt(wh_substring)
                // console.log('wh_ano')
                // console.log(wh_ano)
                if (wh_ano >= 2000) {
                    Cambiafecha_encuadre(w_value)
                }else{
                    setHelperTextfecha_encuadre('El año de la Fecha tiene que ser mayor o igual a 2000')
                    Cambiafecha_encuadreError(w_value)
                }
            }else{
                setHelperTextfecha_encuadre('Error en el Formato de Fecha')
                Cambiafecha_encuadreError(w_value)
            }
        }else{
            setHelperTextfecha_encuadre('Error en el Formato de Fecha')
            Cambiafecha_encuadreError(w_value)
        }

    }


    const cambiamoneda_cotizacion_fecha = (event) => {
        const Cambiamoneda_cotizacion_fecha = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadomoneda_cotizacion_fecha(wp_value)
            // console.log('Estadomoneda_cotizacion_fecha')
            // console.log(Estadomoneda_cotizacion_fecha)
        };
        const Cambiamoneda_cotizacion_fechaError = (wp_value) => {
            // console.log('wp_value')
            // console.log(wp_value)
            setEstadomoneda_cotizacion_fecha(wp_value)
            // console.log('Estadomoneda_cotizacion_fecha')
            // console.log(Estadomoneda_cotizacion_fecha)
            setErrormoneda_cotizacion_fecha(true);

        };
        setHelperTextmoneda_cotizacion_fecha('')
        setErrormoneda_cotizacion_fecha(false);
        var w_value = event.target.value
        // console.log('w_value----------------cambiamoneda_cotizacion_fecha')
        // console.log(w_value)
        if(w_value){
            if(w_value===null){
                w_value=''
            }
            w_value=w_value.trim()
            var w_value_length = w_value.length
            // console.log('w_value_length')
            // console.log(w_value_length)
            if(w_value_length===10) {
                var wh_substring = w_value.substring(0, 4)
                // console.log('wh_substring')
                // console.log(wh_substring)
                var wh_ano = parseInt(wh_substring)
                // console.log('wh_ano')
                // console.log(wh_ano)
                if (wh_ano >= 2000) {
                    Cambiamoneda_cotizacion_fecha(w_value)
                }else{
                    setHelperTextmoneda_cotizacion_fecha('El año de la Fecha tiene que ser mayor o igual a 2000')
                    Cambiamoneda_cotizacion_fechaError(w_value)
                }
            }else{
                setHelperTextmoneda_cotizacion_fecha('Error en el Formato de Fecha')
                Cambiamoneda_cotizacion_fechaError(w_value)
            }
        }else{
            setHelperTextmoneda_cotizacion_fecha('Error en el Formato de Fecha')
            Cambiamoneda_cotizacion_fechaError(w_value)
        }

    }

    const PostTitle = ({record}) => {
        return <span>Nueva Contratación</span>;
    };

    const [openConfirmacionCambioContratacion, setopenConfirmacionCambioContratacion] = React.useState(false);

    const handleClickOpenConfirmacionCambioContratacion = () => {
        var w_validacion = ''
        var w_helpertextcontratacion_ano = ''
        var w_helpertextexpediente_referencia_gde = ''
        var w_helpertextcontratacion_tipo = ''
        var w_helpertextmoneda = ''
        var w_helpertextmoneda_cotizacion = ''
        var w_helpertextunidad_negocio = ''
        var w_helpertextunidad_area = ''
        var w_helpertextimporte_presupuesto = ''
        var w_helpertextconcepto_descripcion = ''


        var w_concepto_descripcion = Estadoconcepto_descripcion
        if(w_concepto_descripcion==null){
            w_concepto_descripcion=''
        }
        w_concepto_descripcion=w_concepto_descripcion.trim()
        if(w_concepto_descripcion===''){
            w_validacion = 'E'
            w_helpertextconcepto_descripcion = 'Debe Ingresar Concepto'
            setErrorconcepto_descripcion(true)
        }

        var w_importe_presupuesto = Estadoimporte_presupuesto
        if(w_importe_presupuesto==null || w_importe_presupuesto===''){
            w_importe_presupuesto=0
        }

        // console.log('w_importe_presupuesto')
        // console.log(w_importe_presupuesto)

        if(w_importe_presupuesto===0 || w_importe_presupuesto==='0'){
            w_validacion = 'E'
            w_helpertextimporte_presupuesto = 'Debe Ingresar el Importe Presupuesto'
            setErrorimporte_presupuesto(true)
        }

        var w_contratacion_ano = Estadocontratacion_ano
        if(w_contratacion_ano==null || w_contratacion_ano===''){
            w_contratacion_ano=0
        }
        if(w_contratacion_ano===0 || w_contratacion_ano==='0'){
            w_validacion = 'E'
            w_helpertextcontratacion_ano = 'Debe Ingresar el Año Contratacion'
            setErrorcontratacion_ano(true)
        }

        var w_expediente_referencia_gde = Estadoexpediente_referencia_gde
        if(w_expediente_referencia_gde==null){
            w_expediente_referencia_gde=''
        }
        w_expediente_referencia_gde=w_expediente_referencia_gde.trim()
        if(w_expediente_referencia_gde===''){
            w_validacion = 'E'
            w_helpertextexpediente_referencia_gde = 'Debe Ingresar Expediente'
            setErrorexpediente_referencia_gde(true)
        }

        var w_contratacion_tipo = Estadocontratacion_tipo
        if(w_contratacion_tipo==null){
            w_contratacion_tipo=''
        }
        w_contratacion_tipo=w_contratacion_tipo.trim()
        if(w_contratacion_tipo===''){
            w_validacion = 'E'
            w_helpertextcontratacion_tipo = 'Debe Ingresar Contratacion Tipo'
        }

        var w_moneda = Estadomoneda
        if(w_moneda==null){
            w_moneda=''
        }
        w_moneda=w_moneda.trim()
        if(w_moneda===''){
            w_validacion = 'E'
            w_helpertextmoneda = 'Debe Ingresar Moneda'
            setErrormoneda(true)
        }

        var w_moneda_cotizacion = Estadomoneda_cotizacion
        if(w_moneda_cotizacion==null || w_moneda_cotizacion===''){
            w_moneda_cotizacion=0
        }
        if(w_moneda_cotizacion===0 || w_moneda_cotizacion==='0'){
            w_validacion = 'E'
            w_helpertextmoneda_cotizacion = 'Debe Ingresar la Cotización'
            setErrormoneda_cotizacion(true)
        }

        var w_unidad_negocio = Estadounidad_negocio
        if(w_unidad_negocio==null){
            w_unidad_negocio=''
        }
        w_unidad_negocio=w_unidad_negocio.trim()
        if(w_unidad_negocio===''){
            w_validacion = 'E'
            w_helpertextunidad_negocio = 'Debe Ingresar Unidad Negocio'
        }

        var w_unidad_area = Estadounidad_area.area
        if(w_unidad_area==null){
            w_unidad_area=''
        }
        w_unidad_area=w_unidad_area.trim()
        if(w_unidad_area===''){
            w_validacion = 'E'
            w_helpertextunidad_area = 'Debe Ingresar Area'
            setErrorunidad_area(true)
        }


        if(w_validacion === 'E') {
            setHelperTextconcepto_descripcion(w_helpertextconcepto_descripcion);
            setHelperTextcontratacion_ano(w_helpertextcontratacion_ano)
            setHelperTextexpediente_referencia_gde(w_helpertextexpediente_referencia_gde)
            setHelperTextcontratacion_tipo(w_helpertextcontratacion_tipo)
            setHelperTextmoneda(w_helpertextmoneda);
            setHelperTextmoneda_cotizacion(w_helpertextmoneda_cotizacion);
            setHelperTextunidad_negocio(w_helpertextunidad_negocio);
            setHelperTextunidad_area(w_helpertextunidad_area);
            setHelperTextimporte_presupuesto(w_helpertextimporte_presupuesto);
        }else {
            setopenConfirmacionCambioContratacion(true);
        }
    };

    const handleCloseConfirmacionCambioContratacion = () => {
        setopenConfirmacionCambioContratacion(false);
    };

    const BotonActualizarPrincipalDetelle = (record) => {

        var w_mensaje = ' '

        const notify = useNotify();
        const redirectTo = useRedirect();

        const [actualizardetelle, {loading}] = useMutation({
                type: 'update',
                resource: 'ContratacionEncuadre',
                payload: {
                    id: EstadoID, data: {
                        fecha_encuadre: Estadofecha_encuadre+"T00:00:00",
                        expediente_referencia_gde: Estadoexpediente_referencia_gde,
                        contratacion_tipo: Estadocontratacion_tipo,
                        contratacion_ano: Estadocontratacion_ano,
                        moneda: Estadomoneda,
                        moneda_cotizacion: Estadomoneda_cotizacion,
                        moneda_cotizacion_fecha: Estadomoneda_cotizacion_fecha+"T00:00:00",
                        importe_presupuesto: Estadoimporte_presupuesto,
                        importe_presupuesto_pesos: Estadoimporte_presupuesto_pesos,
                        importe_adjudicado_pesos: Estadoimporte_adjudicado_pesos,
                        concepto_descripcion: Estadoconcepto_descripcion,
                        unidad_negocio: Estadounidad_negocio,
                        unidad_area: Estadounidad_area.area
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    setopenConfirmacionCambioContratacion(false);
                    console.log('data------BotonActualizarPrincipalDetelle')
                    console.log(data)
                    notify(`Actualizando la Contratacion N°: ${EstadoID}`, "success");
                    redirectTo(`/ContratacionEncuadre/${EstadoID}/1`);
                    // redirectTo(`/ContratacionEncuadreVisita?displayedFilters=%7B%7D&filter=%7B%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpenConfirmacionCambioContratacion}
                        style={{color: '#ffffff', backgroundColor: '#123B80'}}
                >
                    <div title={'Confirmacion de los cambios de la Contratacion'}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        <SaveIcon style={{color: '#ffffff', backgroundColor: '#123B80'}}/>
                        Actualizar
                    </div>
                </Button>
                <Dialog open={openConfirmacionCambioContratacion} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{'Confirmacion de los cambios de la Contratacion'}</DialogTitle>
                    <DialogContent>
                        ¿Quiere Confirmar los Cambios de la Contratacion?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={actualizardetelle} disabled={loading}
                                style={{color: '#ffffff', backgroundColor: '#123B80'}}
                        >
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                 }}>
                                <SaveIcon style={{color: '#ffffff', backgroundColor: '#123B80'}}/>
                                Actualizar
                            </div>
                        </Button>
                        <Button variant="outlined" color="primary" onClick={handleCloseConfirmacionCambioContratacion}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                <CancelPresentationIcon style={{color: '#123B80'}}/>
                                Cancelar
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        );

    };

    const [stateunidad_area, setStateunidad_area] = useState({});

    const {
        area = record===undefined ?  '' : record.UnidadArea===undefined ? '' : record.UnidadArea,
        areaD = record===undefined ?  '' : record.UnidadArea===undefined ? '' : record.UnidadArea.area_d.trim()
    } = stateunidad_area;

    const formatarea = v => {
        return area
    };

    const formatareaD = v => {
        return areaD
    };

    const updatearea = useCallback(async (who) => {
        setStateunidad_area(stateunidad_area => ({
            ...stateunidad_area,
            area: who.area,
            areaD: who.area_d.trim(),
        }));
        setEstadounidad_area(stateunidad_area => ({
            ...stateunidad_area,
            area: who.area,
        }));
        setHelperTextunidad_area('Código Unidad Area');
        setErrorunidad_area(false)
    })

    return (
        <div>

            <NumberInput label="ID Contratacion" source="id"
                         inputProps={{readOnly: true}}
                         formClassName={classesDetelle.id}
                         options={{ minimumFractionDigits: 0 }} />

            <TextField error={errorexpediente_referencia_gde}
                       source="expediente_referencia_gde"
                       label={"Expediente"}
                       variant="outlined"
                       value={Estadoexpediente_referencia_gde}
                       onChange={handleexpediente_referencia_gde}
                       InputLabelProps={{shrink: true}}
                       formClassName={classesDetelle.expediente_referencia_gde2}
                       // fullWidth={true}
                       helperText={helperTextexpediente_referencia_gde}
            />

            <TextInput
                label="Estado Actual"
                source="estado_encuadre"
                formClassName={classesDetelle.estado_encuadre2}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="filled"
                inputProps={{readOnly: true}}
            />
            <Typography variant="h6">Contratacion Tipo</Typography>
            <TextField
                id="contratacion_tipo_ing"
                select
                label=""
                // size="small"
                // value={currency}
                formClassName={classesDetelle.contratacion_tipo}
                value={Estadocontratacion_tipo}
                onChange={handlecontratacion_tipo}
                SelectProps={{
                    native: true, readOnly: true
                }}
                // fullWidth={true}
                variant="filled"
                disabled
                // helperText="Por favor seleccione una Moneda"
            >
                {ComboManualContratacionTipo.map((option) => (
                    <option key={option.contratacion_tipo} value={option.contratacion_tipo}>
                        {option.contratacion_d}
                    </option>
                ))}
            </TextField>
            <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                {helperTextcontratacion_tipo}
            </FormHelperText>
            <br/>
            <br/>
                    <TextField
                        error={errorfecha_encuadre}
                        id="fecha_encuadre"
                        source="fecha_encuadre"
                        label="Fecha"
                        type="date"
                        formClassName={classesDetelle.fecha_encuadre}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={Estadofecha_encuadre}
                        onChange={cambiafecha_encuadre}
                        helperText={helperTextfecha_encuadre}
                    />
                    {/*<FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>*/}
                    {/*    {helperTextfecha_encuadre}*/}
                    {/*</FormHelperText>*/}

                    <NumberFormat
                        error={errorcontratacion_ano}
                        customInput={TextField}
                        id="ano_ing"
                        label="Año Contratacion"
                        size="small"
                        formClassName={classesDetelle.contratacion_ano}
                        value={Estadocontratacion_ano}
                        onChange={handlecontratacion_ano}
                        helperText={helperTextcontratacion_ano}
                        format="####"
                    />
                    <br/>

                    {/*<FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>*/}
                    {/*    {helperTextexpediente_referencia_gde}*/}
                    {/*</FormHelperText>*/}
                    <br/>


                    <Typography variant="h6">Unidad Negocio</Typography>
                    <TextField
                        id="unidad_negocio_ing"
                        select
                        label=""
                        // size="small"
                        // value={currency}
                        formClassName={classesDetelle.unidad_negocio}
                        value={Estadounidad_negocio}
                        onChange={handleunidad_negocio}
                        SelectProps={{
                            native: true,
                        }}
                        // fullWidth={true}
                        // helperText="Por favor seleccione una Moneda"
                    >
                        {ComboManualUnidadNegocio.map((option) => (
                            <option key={option.unidad_negocio} value={option.unidad_negocio}>
                                {option.unidad_negocio_d}
                            </option>
                        ))}
                    </TextField>
                    <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                        {helperTextunidad_negocio}
                    </FormHelperText>
                    <br/>
                    <Typography variant="h6">Area</Typography>
                    {/*<TextField*/}
                    {/*    id="unidad_area_ing"*/}
                    {/*    select*/}
                    {/*    label=""*/}
                    {/*    // size="small"*/}
                    {/*    // value={currency}*/}
                    {/*    formClassName={classesDetelle.unidad_area}*/}
                    {/*    value={Estadounidad_area}*/}
                    {/*    onChange={handleunidad_area}*/}
                    {/*    SelectProps={{*/}
                    {/*        native: true,*/}
                    {/*    }}*/}
                    {/*    fullWidth={true}*/}
                    {/*    // helperText="Por favor seleccione una Moneda"*/}
                    {/*>*/}
                    {/*    {ComboManualArea.map((option) => (*/}
                    {/*        <option key={option.area} value={option.area}>*/}
                    {/*            {option.area_d_area}*/}
                    {/*        </option>*/}
                    {/*    ))}*/}
                    {/*</TextField>*/}
                    {/*<br/>*/}


                    <TextField
                        error={errorunidad_area}
                        label="Código Unidad Area"
                        source="unidad_area"
                        value={Estadounidad_area.area}
                        formClassName={classesDetelle.unidad_area}
                        variant="filled"
                        placeholder="Ingrese Unidad Area"
                        helperText={helperTextunidad_area}
                        // margin="normal"
                        format={formatarea}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <UnidadAreaBusquedaBtn callbackUnidadArea={updatearea}
                                           area={Estadounidad_area.area}
                                           unidad_negocio={Estadounidad_negocio}
                                           usar_todos={'U'}
                                           formClassName={classesDetelle.btnBusquedaUnidadArea}/>
                    <TextField
                        id="rubro_d-read-only-input"
                        value={areaD}
                        InputProps={{
                            readOnly: true,
                        }}
                        formClassName={classesDetelle.unidad_area_d}
                        variant="filled"
                        label="Descripción Unidad Area"
                        placeholder="Ingrese Descripción Unidad Area"
                        helperText="Descripción Unidad Area"
                        // margin="normal"
                        format={formatareaD}
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>
                    {/*<FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>*/}
                    {/*    {helperTextunidad_area}*/}
                    {/*</FormHelperText>*/}
                    {/*<br/>*/}
                    <Typography variant="h6">Moneda</Typography>
                    <TextField
                        error={errormoneda}
                        id="moneda_ing"
                        select
                        label=""
                        // size="small"
                        // value={currency}
                        formClassName={classesDetelle.moneda}
                        value={Estadomoneda}
                        onChange={handlemoneda}
                        SelectProps={{
                            native: true,
                        }}
                        fullWidth={true}
                        helperText={helperTextmoneda}
                    >
                        {ComboMoneda.map((option) => (
                            <option key={option.moneda} value={option.moneda}>
                                {option.moneda_d_abre}
                            </option>
                        ))}
                    </TextField>
                    {/*<FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>*/}
                    {/*    {helperTextmoneda}*/}
                    {/*</FormHelperText>*/}
                    {/*<NumberFormat*/}
                    {/*    customInput={TextField}*/}
                    {/*    id="moneda_cotizacion_ing"*/}
                    {/*    label="Cotización"*/}
                    {/*    size="small"*/}
                    {/*    formClassName={classesDetelle.moneda_cotizacion}*/}
                    {/*    value={Estadomoneda_cotizacion}*/}
                    {/*    onChange={handlemoneda_cotizacion}*/}
                    {/*    variant={Estadovariantmoneda_cotizacion}*/}
                    {/*    inputProps={{readOnly: EstadoreadOnlymoneda_cotizacion}}*/}
                    {/*    // format="###########.#####"*/}
                    {/*/>*/}
                    {/*<br/>*/}
                    <TextField
                        error={errormoneda_cotizacion}
                        label="Cotización"
                        value={Estadomoneda_cotizacion}
                        onChange={handlemoneda_cotizacion}
                        name="numberformat"
                        id="moneda_cotizacion_ing"
                        formClassName={classesDetelle.moneda_cotizacion}
                        variant={Estadovariantmoneda_cotizacion}
                        helperText={helperTextmoneda_cotizacion}
                        InputProps={{
                            inputComponent: NumberFormatCustom,readOnly: EstadoreadOnlymoneda_cotizacion
                        }}
                    />

                    <TextField
                        error={errormoneda_cotizacion_fecha}
                        id="moneda_cotizacion_fecha_ing"
                        label="Fecha"
                        type="date"
                        formClassName={classesDetelle.moneda_cotizacion_fecha}
                        className={classesDetelle.moneda_cotizacion_fecha}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={Estadomoneda_cotizacion_fecha}
                        onChange={cambiamoneda_cotizacion_fecha}
                        variant={Estadovariantmoneda_cotizacion_fecha}
                        inputProps={{readOnly: EstadoreadOnlymoneda_cotizacion_fecha}}
                    />
                    <FormHelperText style={{ color: '#ff0000', backgroundColor: '#ffffff' }}>
                        {helperTextmoneda_cotizacion_fecha}
                    </FormHelperText>
                    <br/>
                    {/*<NumberFormat*/}
                    {/*    customInput={TextField}*/}
                    {/*    id="importe_presupuesto_ing"*/}
                    {/*    label="Importe Presupuesto"*/}
                    {/*    size="small"*/}
                    {/*    formClassName={classesDetelle.importe_presupuesto}*/}
                    {/*    value={Estadoimporte_presupuesto}*/}
                    {/*    onChange={handleimporte_presupuesto}*/}
                    {/*    // format="#,###,###,###,###.##"*/}
                    {/*/>*/}

                    <TextField
                        error={errorimporte_presupuesto}
                        label="Importe Presupuesto"
                        value={Estadoimporte_presupuesto}
                        onChange={handleimporte_presupuesto}
                        name="numberformat"
                        id="importe_presupuesto_ing"
                        helperText={helperTextimporte_presupuesto}
                        formClassName={classesDetelle.importe_presupuesto}
                        InputProps={{
                            inputComponent: NumberFormatCustom,
                        }}
                    />

                    {/*<NumberFormat*/}
                    {/*    customInput={TextField}*/}
                    {/*    id="importe_presupuesto_pesos_ing"*/}
                    {/*    label="Importe Adjudicado Pesos"*/}
                    {/*    size="small"*/}
                    {/*    name="numberformat"*/}
                    {/*    formClassName={classesDetelle.importe_presupuesto_pesos}*/}
                    {/*    value={Estadoimporte_presupuesto_pesos}*/}
                    {/*    onChange={handleimporte_presupuesto_pesos}*/}
                    {/*    // format="#,###,###,###,###.##"*/}
                    {/*    variant="filled"*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}

                    <TextField
                        label="Importe Presupuesto $"
                        value={Estadoimporte_presupuesto_pesos}
                        onChange={handleimporte_presupuesto_pesos}
                        name="numberformat"
                        id="importe_presupuesto_pesos_ing"
                        formClassName={classesDetelle.importe_presupuesto_pesos}
                        variant="filled"
                        InputProps={{
                            inputComponent: NumberFormatCustom,readOnly: true
                        }}
                    />

                    {/*<NumberFormat*/}
                    {/*    customInput={TextField}*/}
                    {/*    id="importe_adjudicado_pesos_ing"*/}
                    {/*    label="Importe Adjudicado Pesos"*/}
                    {/*    size="small"*/}
                    {/*    formClassName={classesDetelle.importe_adjudicado_pesos}*/}
                    {/*    value={Estadoimporte_adjudicado_pesos}*/}
                    {/*    onChange={handleimporte_adjudicado_pesos}*/}
                    {/*    // format="#,###,###,###,###.##"*/}
                    {/*    variant="filled"*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}


                    <TextField
                        label="Importe Adjudicado Pesos"
                        value={Estadoimporte_adjudicado_pesos}
                        onChange={handleimporte_adjudicado_pesos}
                        name="numberformat"
                        id="importe_adjudicado_pesos_ing"
                        formClassName={classesDetelle.importe_adjudicado_pesos}
                        variant="filled"
                        InputProps={{
                            inputComponent: NumberFormatCustom,readOnly: true
                        }}
                    />

                    {/*<NumberInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Importe Adjudicado Pesos"*/}
                    {/*    source="importe_adjudicado_pesos"*/}
                    {/*    formClassName={classesDetelle.importe_adjudicado_pesos}*/}
                    {/*    // placeholder="Ingrese Importe Adjudicado Pesos"*/}
                    {/*    helperText="Importe Adjudicado Pesos"*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="filled"*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}

                    {/*<DateInput source="fecha_encuadre" label={"Fecha"}*/}
                    {/*           variant="outlined"*/}
                    {/*           value={Estadofecha_encuadre}*/}
                    {/*           // onChange={handlefecha_encuadre}*/}
                    {/*           // inputProps={{readOnly: false}}*/}
                    {/*           // InputLabelProps={{shrink: false}}*/}
                    {/*           formClassName={classesDetelle.fecha_encuadre}*/}
                    {/*/>*/}

                    {/*<TextInput source="expediente_referencia_gde" label={"Expediente"}*/}
                    {/*           variant="outlined"*/}
                    {/*           value={Estadoexpediente_referencia_gde}*/}
                    {/*           onChange={handleexpediente_referencia_gde}*/}
                    {/*           InputLabelProps={{shrink: true}}*/}
                    {/*           formClassName={classesDetelle.expediente_referencia_gde}*/}
                    {/*           fullWidth={true}*/}
                    {/*/>*/}

                    {/*<ComboContratacionTipo label={"Encuadre"} readOnly={false} formClassName={classesDetelle.combo}/>*/}
                    {/*<br/>*/}
                    {/*<NumberInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Año"*/}
                    {/*    source="contratacion_ano"*/}
                    {/*    value={Estadocontratacion_ano}*/}
                    {/*    onChange={handlecontratacion_ano}*/}
                    {/*    formClassName={classesDetelle.contratacion_ano}*/}
                    {/*    placeholder="Ingrese Año"*/}
                    {/*    helperText="Año"*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="outlined"*/}
                    {/*/>*/}
                    {/*/!*<br/>*!/*/}

                    {/*<ComboUnidadNegocio readOnly={false} formClassName={classesDetelle.combo2}/>*/}
                    {/*<ComboArea readOnly={false} formClassName={classesDetelle.combo}/>*/}
                    {/*<br/>*/}

                    {/*<Typography variant="h6">Moneda</Typography>*/}
                    {/*<TextField label=""*/}
                    {/*           value={Estadomoneda}*/}
                    {/*           onChange={handlemoneda}*/}
                    {/*           SelectProps={{*/}
                    {/*               native: true,*/}
                    {/*           }}*/}
                    {/*/>*/}

                    {/*<ComboActivoMoneda readOnly={false} formClassName={classesDetelle.combo}/>*/}

                    {/*<NumberInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Cotización"*/}
                    {/*    source="moneda_cotizacion"*/}
                    {/*    formClassName={classesDetelle.moneda_cotizacion}*/}
                    {/*    placeholder="Ingrese Cotización"*/}
                    {/*    helperText="Cotización"*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="outlined"*/}
                    {/*/>*/}

                    {/*<DateInput source="moneda_cotizacion_fecha" label={"Fecha Ctz."}*/}
                    {/*    // variant="filled"*/}
                    {/*    // inputProps={{readOnly: false}}*/}
                    {/*    // InputLabelProps={{shrink: false}}*/}
                    {/*    formClassName={classesDetelle.moneda_cotizacion_fecha}*/}
                    {/*/>*/}
                    {/*<br/>*/}

                    {/*<NumberInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Importe Presupuesto"*/}
                    {/*    source="importe_presupuesto"*/}
                    {/*    formClassName={classesDetelle.importe_presupuesto}*/}
                    {/*    placeholder="Ingrese Importe Presupuesto"*/}
                    {/*    helperText="Importe Presupuesto"*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="outlined"*/}
                    {/*/>*/}


                    {/*<NumberInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Importe Presupuesto $"*/}
                    {/*    source="importe_presupuesto_pesos"*/}
                    {/*    formClassName={classesDetelle.importe_presupuesto_pesos}*/}
                    {/*    // placeholder="Ingrese Importe Presupuesto $"*/}
                    {/*    helperText="Importe Presupuesto $"*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="filled"*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}

                    {/*<NumberInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Importe Adjudicado Pesos"*/}
                    {/*    source="importe_adjudicado_pesos"*/}
                    {/*    formClassName={classesDetelle.importe_adjudicado_pesos}*/}
                    {/*    // placeholder="Ingrese Importe Adjudicado Pesos"*/}
                    {/*    helperText="Importe Adjudicado Pesos"*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="filled"*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}
                    <br/>
            <br/>
            <br/>
            <br/>
                    <TextField error={errorconcepto_descripcion}
                               source="concepto_descripcion"
                               label={"Concepto"}
                               variant="outlined"
                               value={Estadoconcepto_descripcion}
                               onChange={handleconcepto_descripcion}
                               InputLabelProps={{shrink: true}}
                               formClassName={classesDetelle.concepto_descripcion}
                               fullWidth={true}
                               multiline
                               helperText={helperTextconcepto_descripcion}
                    />

                    {/*<TextInput source="concepto_descripcion" label={"Concepto"}*/}
                    {/*           error={errorconcepto_descripcion}*/}
                    {/*           variant="outlined"*/}
                    {/*           multiline*/}
                    {/*           value={Estadoconcepto_descripcion}*/}
                    {/*           onChange={handleconcepto_descripcion}*/}
                    {/*           InputLabelProps={{shrink: true}}*/}
                    {/*           formClassName={classesDetelle.concepto_descripcion}*/}
                    {/*           fullWidth={true}*/}
                    {/*           helperText={helperTextconcepto_descripcion}*/}
                    {/*/>*/}


                    {/*<TextInput source="concepto_descripcion" label={"Concepto"}*/}
                    {/*           multiline*/}
                    {/*           InputLabelProps={{shrink: true}} inputProps={{readOnly: false}}*/}
                    {/*           formClassName={classesDetelle.concepto_descripcion} fullWidth={true}/>*/}




                    {/*Fecha		[fecha_encuadre] [datetime] NOT NULL,*/}
                    {/*Expediente	[expediente_referencia_gde] [nvarchar](200) NOT NULL,*/}
                    {/*Encuadre	[contratacion_tipo_encuadre] [char](20) NOT NULL,    [Combo] tabla: [ contratacion_tipo]*/}

                    {/*Año		        [contratacion_ano] [int] NULL,*/}
                    {/*Unidad Negocio	[unidad_negocio_requiere] [char](20) NULL,    [Combo]  tabla:[unidad_negocio]*/}
                    {/*Area		    [unidad_area_requiere] [char](20) NULL,     [Combo]  tabla: [unidad_area]*/}

                    {/*Moneda		[moneda] [char](10) NULL,                       [Combo o ventana]  tabla: moneda  where activo = 'S'*/}
                    {/*Cotización	[moneda_cotizacion] [decimal](16, 5) NULL,*/}
                    {/*Fecha Ctz.	[moneda_cotizacion_fecha] [datetime] NULL,      Propone: ([fecha_encuadre]-1)*/}

                    {/*Importe Presupuesto	[importe_presupuesto] [decimal](16, 2) NULL,         9,999,999,999,999.99*/}
                    {/*Importe Presupuesto $	[importe_presupuesto_pesos] [decimal](16, 2) NULL,   9,999,999,999,999.99   [RO] round([importe_presupuesto] * [moneda_cotizacion],2)*/}

                    {/*Importe Adjudicado Pesos	[importe_adjudicado_pesos] [decimal](16, 2) NULL,    9,999,999,999,999.99   [RO]*/}

                    <br/>
            <br/>
            <br/>
            <br/>
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <BotonActualizarPrincipalDetelle/>
                            </Grid>
                            {/*<Grid item xs>*/}
                            {/*    <Link to={`/ContratacionEncuadre/`}>*/}
                            {/*        <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
                            {/*            <Button*/}
                            {/*                label="Salir"*/}
                            {/*                style={{color: '#ffffff', backgroundColor: '#123B80'}}*/}
                            {/*            >*/}
                            {/*                <ExitToAppIcon/> Salir*/}
                            {/*            </Button>*/}
                            {/*        </div>*/}
                            {/*    </Link>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </div>
        </div>

    )
};
export default ContratacionEncuadreButtonEdit;


