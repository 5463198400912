import React from 'react';
import Grid from '@material-ui/core/Grid';
import VerInfoSecuencia from './VerInfoSecuencia'

// Método que carga más datos de cada registro. Haciendo Click en el registro se expande hacia abajo.
const ContratacionEncuadreInfo = ({record, ...rest}) => (
    <Grid container spacing={12}>
        <Grid container spacing={12}>
            <Grid container spacing={12}>
                <Grid item xs={12}>
                    <VerInfoSecuencia
                        ver_secuencia_observacion={record.secuencia_observacion}
                        ver_usuario={record.usuario}
                        ver_usuario_fh={record.usuario_fh}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>

);
export default ContratacionEncuadreInfo;
