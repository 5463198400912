import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput, Filter, SelectInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';


const useStyles = makeStyles((theme) => ({
    documento_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    documento_tipo_d: {display: 'inline-block', width: 400, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_documento_tipo = values.documento_tipo

    if (w_documento_tipo===undefined) {
        errors.documento_tipo = ['El campo Código es requerido'];
    }else {
        if (w_documento_tipo === null) {
            errors.documento_tipo = ['El campo Código es requerido'];
        } else {
            w_documento_tipo = w_documento_tipo.trim()
            if (w_documento_tipo === '') {
                errors.documento_tipo = ['El campo Código es requerido'];
            }
        }
    }


    var w_documento_tipo_d = values.documento_tipo_d

    if (w_documento_tipo_d===undefined) {
        errors.documento_tipo_d = ['El campo Descripcion es requerido'];
    }else {
        if (w_documento_tipo_d === null) {
            errors.documento_tipo_d = ['El campo Descripcion es requerido'];
        } else {
            w_documento_tipo_d = w_documento_tipo_d.trim()
            if (w_documento_tipo_d === '') {
                errors.documento_tipo_d = ['El campo Descripcion es requerido'];
            }
        }
    }

    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/Moneda`;

const MonedaCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Moneda</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS MONEDA">


                    <TextInput
                        label="Moneda"
                        source="moneda"
                        formClassName={classes.moneda}
                        placeholder="Ingrese Moneda"
                        helperText="Moneda"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Descripción Moneda"
                        source="moneda_d"
                        formClassName={classes.moneda_d}
                        multiline
                        placeholder="Ingrese Descripción Moneda"
                        helperText="Descripción Moneda"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>



                    <TextInput
                        id="standard-full-width"
                        label="Moneda Abre."
                        source="moneda_abre"
                        formClassName={classes.moneda_abre}
                        multiline
                        placeholder="Ingrese Moneda Abre."
                        helperText="Moneda Abre."
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Código AFIP"
                        source="codigo_afip"
                        formClassName={classes.codigo_afip}
                        multiline
                        placeholder="Ingrese Código AFIP"
                        helperText="Código AFIP"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>


                    <TextInput
                        id="standard-full-width"
                        label="Código Gestion"
                        source="codigo_gestion"
                        formClassName={classes.codigo_gestion}
                        multiline
                        placeholder="Ingrese Código Gestion"
                        helperText="Código Gestion"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    {/*<TextInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Activo"*/}
                    {/*    source="activo"*/}
                    {/*    formClassName={classes.activo}*/}
                    {/*    multiline*/}
                    {/*    placeholder="Ingrese Activo"*/}
                    {/*    helperText="Activo"*/}
                    {/*    fullWidth*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="outlined"*/}
                    {/*/>*/}

                    <SelectInput source="activo" label="Activo"
                                 formClassName={classes.activo}
                                 placeholder="Ingrese Activo"
                                 helperText="Activo"
                                 choices={[
                        { id: 'S', name: 'S' },
                        { id: 'N', name: 'N' },
                    ]} />
                    <br/>

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default MonedaCreate;


