import React, {useEffect, Fragment, useState, useCallback} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField, useNotify, useRefresh, useMutation, FormTab
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
import LineWeightIcon from "@material-ui/icons/LineWeight";
import SaveIcon from '@material-ui/icons/Save';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import AddIcon from '@material-ui/icons/Add';
import ProveedorBusquedaDialogBtn from "../proveedor/ProveedorBusquedaDialogBtn"
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
            isNumericString
            prefix=""
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


const ContratacionEncuadreAdjudicacionButtonShow = ({record}) => {
    var wr_id = record.id

    const dataProviderTableroControl = useDataProvider();
    const [Estadoid, setEstadoid] = useState(0)
    const [Estadoid_contratacion_encuadre, setEstadoid_contratacion_encuadre] = useState(0)
    const [Estadoexpediente_referencia_gde, setEstadoexpediente_referencia_gde] = useState('')
    const [Estadocontratacion_tipo, setEstadocontratacion_tipo] = useState('')
    const [Estadorenglon_nro, setEstadorenglon_nro] = useState('')
    const [Estadorenglon_descripcion, setEstadorenglon_descripcion] = useState('')
    const [Estadoimporte_adjudicado_pesos, setEstadoimporte_adjudicado_pesos] = useState(0)
    const [Estadoorden_compra_nro, setEstadoorden_compra_nro] = useState(0)
    const [Estadoorden_compra_ampliacion_nro, setEstadoorden_compra_ampliacion_nro] = useState(0)
    const [Estadoproveedor_rta, setEstadoproveedor_rta] = useState('')
    const [Estadorazon_social, setEstadorazon_social] = useState('')
    const [Estadocodigo_pro_tvpublica, setEstadocodigo_pro_tvpublica] = useState('')
    const [Estadocodigo_pro_rna, setEstadocodigo_pro_rna] = useState('')

    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const classes = useStyles()
    const [openShow, setOpenShow] = React.useState(false);
    const [state, setState] = useState({});


    const updateProveedor = useCallback(async (who) => {

        setEstadoproveedor_rta(who.pro.trim())
        setEstadorazon_social(who.razon_social)
        setEstadocodigo_pro_tvpublica(who.codigo_pro_tvpublica)
        setEstadocodigo_pro_rna(who.codigo_pro_rna)


        // setState(state => ({
        //     ...state,
        //     proveedor_rta: who.pro.trim(),
        //     razon_social: who.razon_social,
        //     codigo_pro_tvpublica: who.codigo_pro_tvpublica,
        //     codigo_pro_rna: who.codigo_pro_rna,
        // }));
    })

    const handleClickOpenShow = () => {
        var wr_id = record.id
        var wr_id_contratacion_encuadre = record.id_contratacion_encuadre
        var wr_expediente_referencia_gde = record.expediente_referencia_gde
        var wr_contratacion_tipo = record.contratacion_tipo
        var wr_renglon_nro = record.renglon_nro
        var wr_renglon_descripcion = record.renglon_descripcion
        var wr_importe_adjudicado_pesos = record.importe_adjudicado_pesos
        var wr_orden_compra_nro = record.orden_compra_nro
        var wr_orden_compra_ampliacion_nro = record.orden_compra_ampliacion_nro
        var wr_proveedor_rta = record.proveedor_rta
        var wr_razon_social = record.razon_social
        var wr_codigo_pro_tvpublica = record.codigo_pro_tvpublica
        var wr_codigo_pro_rna = record.codigo_pro_rna

        setEstadoid(wr_id)
        setEstadoid_contratacion_encuadre(wr_id_contratacion_encuadre)
        setEstadoexpediente_referencia_gde(wr_expediente_referencia_gde)
        setEstadocontratacion_tipo(wr_contratacion_tipo)
        setEstadorenglon_nro(wr_renglon_nro)
        setEstadorenglon_descripcion(wr_renglon_descripcion)
        setEstadoimporte_adjudicado_pesos(wr_importe_adjudicado_pesos)
        setEstadoorden_compra_nro(wr_orden_compra_nro)
        setEstadoorden_compra_ampliacion_nro(wr_orden_compra_ampliacion_nro)
        setEstadoproveedor_rta(wr_proveedor_rta)
        setEstadorazon_social(wr_razon_social)
        setEstadocodigo_pro_tvpublica(wr_codigo_pro_tvpublica)
        setEstadocodigo_pro_rna(wr_codigo_pro_rna)

        setOpenShow(true);
    };

    const handleActualizar = () => {
        setOpenShow(false);
    };


    const handleCloseShow = () => {
        setOpenShow(false);
    };

    return (
        <div>
            <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                    onClick={handleClickOpenShow}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <VisibilityIcon/>
                    Mostrar
                </div>
            </Button>
            <Dialog open={openShow}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                // fullScreen={true}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Detalle de Orden de Compra (Contratacion Encuadre Adjudicacion)</DialogTitle>
                <DialogContent>
                    <form>
                        <label>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                label="Orden Compra Nro"
                                                value={Estadoorden_compra_nro}
                                                inputProps={{readOnly: true}}
                                                name="numberformat"
                                                id="orden_compra_nro_ing"
                                                formClassName={classes.orden_compra_nro}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="Orden Compra Ampliacion Nro"
                                                value={Estadoorden_compra_ampliacion_nro}
                                                inputProps={{readOnly: true}}
                                                name="numberformat"
                                                id="orden_compra_ampliacion_nro_ing"
                                                formClassName={classes.orden_compra_ampliacion_nro}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                            <br/>
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                label="Importe Adjudicado Pesos"
                                                value={Estadoimporte_adjudicado_pesos}
                                                inputProps={{readOnly: true}}
                                                name="numberformat"
                                                id="importe_presupuesto_ing"
                                                formClassName={classes.importe_adjudicado_pesos}
                                                InputProps={{
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                            <br/>
                                        </TableCell>
                                        <TableCell>
                                            <TextField label="Renglon" value={Estadorenglon_nro}
                                                       inputProps={{readOnly: true}}
                                                       SelectProps={{native: true,}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                label="Renglon Descripción"
                                                value={Estadorenglon_descripcion}
                                                inputProps={{readOnly: true}}
                                                multiline
                                                fullWidth
                                                SelectProps={{native: true,}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">Proveedor Rta</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <TextField label="Nro. Proveedor"
                                                       value={Estadoproveedor_rta}
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                                       inputProps={{readOnly: true}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField label="Cod. Pro. TV Publica"
                                                       value={Estadocodigo_pro_tvpublica}
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                                       inputProps={{readOnly: true}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField label="Cod. Pro. RNA"
                                                       value={Estadocodigo_pro_rna}
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                                       inputProps={{readOnly: true}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField label="Razón Social"
                                                       value={Estadorazon_social}
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                                       fullWidth
                                                       inputProps={{readOnly: true}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </label>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                            onClick={handleCloseShow}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <ExitToAppIcon/>
                            Salir
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}


export default ContratacionEncuadreAdjudicacionButtonShow;