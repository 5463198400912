import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const ContratacionSecuenciaButtonShow = ({record}) => {
    var wr_id = record.id

    var wr_orden_compra_nro = record.orden_compra_nro

    if(wr_orden_compra_nro==null){
        wr_orden_compra_nro=0
    }
    // wr_orden_compra_nro=wr_orden_compra_nro.trim()

    var wr_orden_compra_ampliacion_nro= record.orden_compra_ampliacion_nro

    if(wr_orden_compra_ampliacion_nro==null){
        wr_orden_compra_ampliacion_nro=0
    }
    // wr_orden_compra_ampliacion_nro_tiene=wr_orden_compra_ampliacion_nro_tiene.trim()

    const dataProviderTableroControl = useDataProvider();
    const [Estadoitem, setEstadoitem] = useState('')
    const [Estadoconcepto_requerimiento, setEstadoconcepto_requerimiento] = useState('')
    const [Estadoclase, setEstadoclase] = useState('')
    const [Estadorenglon_nro, setEstadorenglon_nro] = useState('')
    const [Estadorenglon_descripcion, setEstadorenglon_descripcion] = useState('')
    const [Estadounidad_medida, setEstadounidad_medida] = useState('')
    const [Estadounidad_medida_abre, setEstadounidad_medida_abre] = useState('')
    const [Estadocantidad, setEstadocantidad] = useState('')
    const [Estadofecha_entrega, setEstadofecha_entrega] = useState('')
    const [Estadomoneda, setEstadomoneda] = useState('')
    const [Estadocotizacion, setEstadocotizacion] = useState('')
    const [Estadoprecio_unitario_sin_iva, setEstadoprecio_unitario_sin_iva] = useState('')
    const [Estadodescuento_porcentaje, setEstadodescuento_porcentaje] = useState('')
    const [Estadoprecio_unitario_sin_iva_con_dto, setEstadoprecio_unitario_sin_iva_con_dto] = useState('')
    const [Estadoiva_porcentaje, setEstadoiva_porcentaje] = useState('')
    const [Estadoiva_importe, setEstadoiva_importe] = useState('')
    const [Estadototal_item, setEstadototal_item] = useState('')
    const [Estadototal_importe_iva_pesos, setEstadototal_importe_iva_pesos] = useState('')
    const [Estadototal_item_pesos, setEstadototal_item_pesos] = useState('')
    const [ComboContratacionSecuenciaTipo, setComboContratacionSecuenciaTipo] = useState([]);

    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        var wr_item = record.item
        var wr_concepto_requerimiento = record.concepto_requerimiento
        var wr_clase = record.clase
        var wr_renglon_nro = record.renglon_nro
        var wr_renglon_descripcion = record.renglon_descripcion
        var wr_unidad_medida = record.unidad_medida
        var wr_unidad_medida_abre = record.unidad_medida_abre
        var wr_cantidad = record.cantidad
        var wr_fecha_entrega = record.fecha_entrega
        var wr_moneda = record.moneda
        var wr_cotizacion = record.cotizacion
        var wr_precio_unitario_sin_iva = record.precio_unitario_sin_iva
        var wr_descuento_porcentaje = record.descuento_porcentaje
        var wr_precio_unitario_sin_iva_con_dto = record.precio_unitario_sin_iva_con_dto
        var wr_iva_porcentaje = record.iva_porcentaje
        var wr_iva_importe = record.iva_importe
        var wr_total_item = record.total_item
        var wr_total_importe_iva_pesos = record.total_importe_iva_pesos
        var wr_total_item_pesos = record.total_item_pesos

        setEstadoitem(wr_item)
        setEstadoconcepto_requerimiento(wr_concepto_requerimiento)
        setEstadoclase(wr_clase)
        setEstadorenglon_nro(wr_renglon_nro)
        setEstadorenglon_descripcion(wr_renglon_descripcion)
        setEstadounidad_medida(wr_unidad_medida)
        setEstadounidad_medida_abre(wr_unidad_medida_abre)
        setEstadocantidad(wr_cantidad)
        setEstadofecha_entrega(wr_fecha_entrega)
        setEstadomoneda(wr_moneda)
        setEstadocotizacion(wr_cotizacion)
        setEstadoprecio_unitario_sin_iva(wr_precio_unitario_sin_iva)
        setEstadodescuento_porcentaje(wr_descuento_porcentaje)
        setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)
        setEstadoiva_porcentaje(wr_iva_porcentaje)
        setEstadoiva_importe(wr_iva_importe)
        setEstadototal_item(wr_total_item)
        setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)
        setEstadototal_item_pesos(wr_total_item_pesos)

        dataProviderTableroControl.getList(
            'ContratacionSecuenciaTipo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'S' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboContratacionSecuenciaTipo(data);
            })
            .catch(error => {
                setOpen(false);
            })

        setOpen(true);


    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <VisibilityIcon style={{color: '#004187'}}/>
                    Mostrar
                </div>
            </Button>
            <Dialog open={open} fullScreen={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Detalle de Secuencia de la Contratación</DialogTitle>
                <DialogContent>
                    <form>
                        <label>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField label="Item" value={Estadoitem}
                                                       inputProps={{readOnly: true}}
                                                       style={{textAlign: 'right', margin: '1em' }}
                                                       options={{ minimumFractionDigits: 0 }} />
                                        </TableCell>
                                        <TableCell>
                                            <TextField  label="Concepto" value={Estadoconcepto_requerimiento}
                                                        SelectProps={{native: true,}}
                                                        inputProps={{readOnly: true}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField  label="Clase" value={Estadoclase}
                                                        SelectProps={{native: true,}}
                                                        inputProps={{readOnly: true}}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField label="Renglon" value={Estadorenglon_nro}
                                                       SelectProps={{native: true,}}
                                                       inputProps={{readOnly: true}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                label="Renglon Descripción"
                                                value={Estadorenglon_descripcion}
                                                multiline
                                                fullWidth
                                                SelectProps={{native: true,}}
                                                inputProps={{readOnly: true}}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {/*<TextField label="Uni.Med." value={Estadounidad_medida} SelectProps={{*/}
                                            {/*    native: true,*/}
                                            {/*}}/>*/}
                                            {/*<TextField label="Uni.Abr." value={Estadounidad_medida_abre} SelectProps={{*/}
                                            {/*    native: true,*/}
                                            {/*}}/>*/}
                                            <TextField label="Uni.Med." inputProps={{readOnly: true}}
                                                       value={Estadounidad_medida_abre} SelectProps={{
                                                native: true,
                                            }}/>
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                customInput={TextField} label="Cantidad" value={Estadocantidad}
                                                inputProps={{readOnly: true}}
                                                style={{textAlign: 'right', margin: '1em' }}
                                                options={{ minimumFractionDigits: 2 }} />
                                            <br/>
                                        </TableCell>
                                        <TableCell>
                                            {/*<DateField label="Fec.Entrega" value={Estadofecha_entrega} SelectProps={{*/}
                                            {/*    native: true,*/}
                                            {/*}}/>*/}
                                            <TextField
                                                id="date"
                                                label="Fec.Entrega"
                                                type="date"
                                                defaultValue={Estadofecha_entrega}
                                                inputProps={{readOnly: true}}
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="h6">ContratacionSecuenciaTipo</Typography>
                                            <TextField label=""
                                                       value={Estadomoneda}
                                                       inputProps={{readOnly: true}}
                                                       SelectProps={{
                                                           native: true,
                                                       }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                id="moneda_ing"
                                                select
                                                label=""
                                                size="small"
                                                // value={currency}
                                                value={Estadomoneda}
                                                inputProps={{readOnly: true}}
                                                SelectProps={{
                                                    native: false,
                                                }}
                                                // helperText="Por favor seleccione su Mes"
                                            >
                                                {ComboContratacionSecuenciaTipo.map((option) => (
                                                    <option key={option.secuencia_tipo} value={option.secuencia_tipo} inputProps={{readOnly: true}}>
                                                        {option.secuencia_tipo_d}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                customInput={TextField} label="Cotización" value={Estadocotizacion}
                                                inputProps={{readOnly: true}}
                                                style={{textAlign: 'right', margin: '1em' }}
                                                options={{ minimumFractionDigits: 5 }} />
                                            <br/>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <NumberFormat
                                                customInput={TextField} label="PU s/IVA" value={Estadoprecio_unitario_sin_iva}
                                                inputProps={{readOnly: true}}
                                                style={{textAlign: 'right', margin: '1em' }}
                                                options={{ minimumFractionDigits: 2 }} />
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                customInput={TextField} label="Dto. %" value={Estadodescuento_porcentaje}
                                                inputProps={{readOnly: true}}
                                                style={{textAlign: 'right', margin: '1em' }}
                                                options={{ minimumFractionDigits: 2 }} />
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                customInput={TextField} label="PU C/Dto." value={Estadoprecio_unitario_sin_iva_con_dto}
                                                inputProps={{readOnly: true}}
                                                style={{textAlign: 'right', margin: '1em' }}
                                                options={{ minimumFractionDigits: 2 }} />
                                            <br/>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <NumberFormat
                                                customInput={TextField} label="IVA %" value={Estadoiva_porcentaje}
                                                inputProps={{readOnly: true}}
                                                // inputProps={{readOnly: true}}
                                                style={{textAlign: 'right', margin: '1em' }}
                                                options={{ minimumFractionDigits: 2 }} />
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                customInput={TextField} label="IVA Importe" value={Estadoiva_importe}
                                                inputProps={{readOnly: true}}
                                                style={{textAlign: 'right', margin: '1em' }}
                                                options={{ minimumFractionDigits: 2 }} />
                                        </TableCell>
                                        <TableCell>
                                            {''}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <NumberFormat
                                                customInput={TextField} label="Total Ítem" value={Estadototal_item}
                                                inputProps={{readOnly: true}}
                                                style={{textAlign: 'right', margin: '1em' }}
                                                options={{ minimumFractionDigits: 2 }} />
                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <Table  aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {''}
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                customInput={TextField} label="Total IVA $" value={Estadototal_importe_iva_pesos}
                                                inputProps={{readOnly: true}}
                                                style={{textAlign: 'right', margin: '1em' }}
                                                options={{ minimumFractionDigits: 2 }} />
                                        </TableCell>
                                        <TableCell>
                                            <NumberFormat
                                                customInput={TextField} label="Total Ítem $" value={Estadototal_item_pesos}
                                                inputProps={{readOnly: true}}
                                                style={{textAlign: 'right', margin: '1em' }}
                                                options={{ minimumFractionDigits: 2 }} />
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </label>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleClose}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <ExitToAppIcon style={{color: '#004187'}}/>
                            Salir
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}

ContratacionSecuenciaButtonShow.defaultProps = {
    source: '',
    addLabel: true,
};

export default ContratacionSecuenciaButtonShow;