import React, {useCallback, useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput, SaveButton
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import PaisLegalBusquedaBtn from "../pais/PaisBusquedaBtn";


const useStyles = makeStyles((theme) => ({
    unidad_negocio: {display: 'inline-block', width: 200, marginLeft: 32},
    unidad_negocio_d: {display: 'inline-block', width: 400, marginLeft: 32},
    domicilio_pais: {display: 'inline-block', width: 350, marginLeft: 32},
    domicilio_provincia: {display: 'inline-block', width: 350, marginLeft: 160},
    domicilio_partido: {display: 'inline-block', width: 510, marginLeft: 32},
    domicilio_ciudad: {display: 'inline-block', width: 510, marginLeft: 32},
    domicilio_calle: {display: 'inline-block', width: 410, marginLeft: 32},
    domicilio_calle_nro: {display: 'inline-block', width: 90, marginLeft: 32},
    domicilio_piso: {display: 'inline-block', width: 50, marginLeft: 32},
    domicilio_departamento: {display: 'inline-block', width: 50, marginLeft: 20},
    domicilio_codigo_postal: {display: 'inline-block', width: 110, marginLeft: 20},
    contacto_correo_electronico: {display: 'inline-block', width: 510, marginLeft: 32},
    contacto_telefono_area: {display: 'inline-block', width: 110, marginLeft: 32},
    contacto_telefono_nro: {display: 'inline-block', width: 200, marginLeft: 20},
    contacto_fax_area: {display: 'inline-block', width: 110, marginLeft: 20},
    contacto_fax_nro: {display: 'inline-block', width: 200, marginLeft: 20},
    cuit: {display: 'inline-block', width: 200, marginLeft: 32},
    iva_responsable: {display: 'inline-block', width: 310, marginLeft: 20},
    ibru_responsable: {display: 'inline-block', width: 310, marginLeft: 20},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_unidad_negocio = values.unidad_negocio

    if (w_unidad_negocio===undefined) {
        errors.unidad_negocio = ['El campo Código es requerido'];
    }else {
        if (w_unidad_negocio === null) {
            errors.unidad_negocio = ['El campo Código es requerido'];
        } else {
            w_unidad_negocio = w_unidad_negocio.trim()
            if (w_unidad_negocio === '') {
                errors.unidad_negocio = ['El campo Código es requerido'];
            }
        }
    }


    var w_unidad_negocio_d = values.unidad_negocio_d

    if (w_unidad_negocio_d===undefined) {
        errors.unidad_negocio_d = ['El campo Descripcion es requerido'];
    }else {
        if (w_unidad_negocio_d === null) {
            errors.unidad_negocio_d = ['El campo Descripcion es requerido'];
        } else {
            w_unidad_negocio_d = w_unidad_negocio_d.trim()
            if (w_unidad_negocio_d === '') {
                errors.unidad_negocio_d = ['El campo Descripcion es requerido'];
            }
        }
    }





    return errors
};



const UnidadNegocioCreate = props => {
    const classes = useStyles();
    const [state, setState] = useState({});

    const PostTitle = ({record}) => {
        return <span>Alta de Unidad Negocio</span>;
    };

    const {
        codDomicilioPais = '',
        dDomicilioPais = '',

    } = state;

    const formatDomicilioPais = v => {
        return dDomicilioPais
    };



    const updateDomicilioPais = useCallback(async (who) => {
        setState(state => ({
            ...state,
            codDomicilioPais: who.pais_codigo_afip,
            dDomicilioPais: who.pais.trim(),
        }));
    })

    /// JN Mascara para el input cuit-cuil
    const CuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    // redirect to the related Author show page
    const redirect = (basePath, id, data) => `/UnidadNegocio`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Crear"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}

            />

        </Toolbar>
    );
    const transform = data => {

        let w_cod_domicilio_pais = codDomicilioPais;
        if (codDomicilioPais > 0) {
        } else (w_cod_domicilio_pais = 0);

        return {
            ...data,
            wp_domicilio_pais: w_cod_domicilio_pais,
        }
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={<CustomToolbar/>}
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS UNIDAD NEGOCIO">
                    <TextInput
                        label="Código Unidad Negocio"
                        source="unidad_negocio"
                        formClassName={classes.unidad_negocio}
                        placeholder="Ingrese Código Unidad Negocio"
                        helperText="Código"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />

                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Descripción Unidad Negocio"
                        source="unidad_negocio_d"
                        formClassName={classes.unidad_negocio_d}
                        multiline
                        placeholder="Ingrese Descripcion Unidad Negocio"
                        helperText="Descripcion"
                        fullWidth={true}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>
                    <PaisLegalBusquedaBtn callbackPais={updateDomicilioPais} formClassName={classes.btnBusqueda}/>

                    <TextInput source="domicilio_pais" label={"Pais"}
                               fullWidth={true}
                               formClassName={classes.domicilio_pais}
                               variant="filled" inputProps={{readOnly: true,}}
                               value={dDomicilioPais}
                               format={formatDomicilioPais}
                               InputLabelProps={{shrink: true}}
                    />



                    <TextInput source="domicilio_provincia" label={"Provincia"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_provincia} fullWidth={true}/>
                    <br/>
                    <TextInput source="domicilio_partido" label={"Partido"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_partido} fullWidth={true}/>

                    <TextInput source="domicilio_ciudad" label={"Ciudad"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_ciudad} fullWidth={true}/>
                    <br/>
                    <TextInput source="domicilio_calle" label={"Calle"} variant="outlined" InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_calle} fullWidth={true}/>

                    <TextInput source="domicilio_calle_nro" label={"Nro."} variant="outlined" InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_calle_nro} fullWidth={true}/>

                    <TextInput source="domicilio_piso" label={"Piso"} variant="outlined" InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_piso} fullWidth={true}/>

                    <TextInput source="domicilio_departamento" label={"Depto"} variant="outlined" InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_departamento} fullWidth={true}/>

                    <TextInput source="domicilio_codigo_postal" label={"Código Postal"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_codigo_postal} fullWidth={true}/>
                    <br/>


                    <TextInput source="contacto_correo_electronico" label={"Contacto Correo Electronico"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_correo_electronico} fullWidth={true}/>
                    <br/>

                    <TextInput source="contacto_telefono_area" label={"Contacto Telefono Area"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_telefono_area} fullWidth={true}/>


                    <TextInput source="contacto_telefono_nro" label={"Contacto Telefono Nro"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_telefono_nro} fullWidth={true}/>


                    <TextInput source="contacto_fax_area" label={"Contacto Fax Area"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_fax_area} fullWidth={true}/>


                    <TextInput source="contacto_fax_nro" label={"Contacto Fax Nro"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_fax_nro} fullWidth={true}/>
                    <br/>


                    <TextInput source="cuit" label={"CUIT"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.cuit} fullWidth={true}
                               format={CuilFormat}
                    />
                    <TextInput source="iva_responsable" label={"IVA Responsable"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.iva_responsable} fullWidth={true}/>


                    <TextInput source="ibru_responsable" label={"IBRU Responsable"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.ibru_responsable} fullWidth={true}/>
                    <br/>

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default UnidadNegocioCreate;


