import React from 'react';
import {Link} from 'react-admin';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';

const LinkEditButton = ({record}) => {

    var w_username = record.username
    var w_usuario = record.usuario

    if(w_username==null){
        w_username=''
    }
    w_username=w_username.trim()
    if(w_usuario==null){
        w_usuario=''
    }
    w_usuario=w_usuario.trim()

    if(w_username===w_usuario){
        return (
            <Link to={`/UserUsuCom/${record.id}`}>
                <div title={''}
                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <RemoveRedEye style={{fontSize: 30, color: '#ff4819'}}/>
                </div>
            </Link>
        );
    }else {
        return (
            <Link to={`/UserUsuCom/${record.id}`}>
                <div title={''}
                     style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <RemoveRedEye style={{fontSize: 30, color: '#0064c8'}}/>
                </div>
            </Link>
        );
    }
}


LinkEditButton.defaultProps = {
    source: '',
    addLabel: true,
};

export default LinkEditButton;