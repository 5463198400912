import {
    SelectInput,
    ReferenceInput,
    FormDataConsumer,
    NumberInput,
    DateInput, TextInput,
    useQuery, Datagrid, TextField, NumberField, Loading
} from 'react-admin';
import React, {useCallback, useState, useGetOne, useEffect} from "react";
import {useForm} from "react-final-form";
import {makeStyles, TextField as TextFieldMUI} from "@material-ui/core";
import OCDetalleBtnCrear from "./OCDetalleBtnCrear";
import OCDetalleDataGrid from "../OrdenCompraDetalle/OCDetalleDataGrid";
import OCDetalleTotales from "./OCDetalleTotales";

const useStyles = makeStyles((theme) => ({

    moneda_cotizacion: {display: 'inline-block', width: 120, marginLeft: 32},
    moneda_cotizacion_fecha: {display: 'inline-block', width: 160, marginLeft: 32},

    precio_incluye_iva: {display: 'inline-block', width: 100},
    importe_subtotal: {display: 'inline-block', width: 250},
    importe_iva: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_total: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_subtotal_pesos: {display: 'inline-block', width: 250},
    importe_iva_pesos: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_total_pesos: {display: 'inline-block', width: 250, marginLeft: 32},

}));

const OrdenDeCompraDetalleProductos = ({ParamData}) => {
    const {form, change} = useForm();
    const classes = useStyles();

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})
    const [hayCambios, setHayCambios] = useState(false);
    const [monedaInhabilitado, setmonedaInhabilitado] = useState(true);

    /// Utilizo para re-renderizar detalle
    const handleHayCambios = useCallback((who) => {
        setHayCambios(who);
        console.log(who)
    }, []);

    const HandleMoneda = (event) => {
        /// si moneda es pesos, pongo cotizacion en 1 y fecha del día de ayer
        if (event.target.value == 3) {
            change("moneda_cotizacion", 1);
            var date = new Date();
            date.setDate(date.getDate() - 1);
            change("moneda_cotizacion_fecha", date);
            setmonedaInhabilitado(true);
        } else {
            setmonedaInhabilitado(false);
        }
    };

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'OrdenCompraDetalle',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: {
                ParamOC: ParamData.orden_compra_nro,
                ParamAmpliacion: ParamData.orden_compra_ampliacion_nro,
            },
        }
    }, [hayCambios]);


    useEffect(() => {
        if (total > 0)
            setmonedaInhabilitado(true);
        else
            setmonedaInhabilitado(false);
    }, [total]);


    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    return (
        <>
            <FormDataConsumer>
                {({formData, ...rest}) => (
                    <div>
                        {/* MONEDA */}
                        <div>

                            <ReferenceInput label="Moneda" source="id_moneda" reference="MonedaActivo"
                                            onChange={HandleMoneda}
                            >
                                <SelectInput optionText="moneda_d_abre" InputLabelProps={{shrink: true}}
                                             className={classes.combo}
                                             inputProps={{readOnly: monedaInhabilitado}}
                                             variant={monedaInhabilitado === true ? 'filled' : 'outlined'}
                                />
                            </ReferenceInput>

                            <NumberInput
                                label="Cotización"
                                source="moneda_cotizacion"
                                className={classes.moneda_cotizacion}
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant={monedaInhabilitado === true ? 'filled' : 'outlined'}
                                inputProps={{readOnly: monedaInhabilitado}}
                            />

                            <DateInput source="moneda_cotizacion_fecha" label={"Fecha Cotización"}
                                       variant={monedaInhabilitado === true ? 'filled' : 'outlined'}
                                       InputLabelProps={{shrink: true}}
                                       className={classes.moneda_cotizacion_fecha} fullWidth={true}
                                       inputProps={{readOnly: monedaInhabilitado}}
                            />
                        </div>

                        {/* TOTALES */}
                        {total > 0 &&
                        <OCDetalleTotales
                            data={data}
                            hayCambios={hayCambios}
                        />
                        }

                        {/* CREAR BOTÓN */}
                        <OCDetalleBtnCrear
                            data={formData}
                            total={total}
                            callbackHayCambios={handleHayCambios}
                        />

                        {/* GRILLA DETALLE */}
                        {total > 0 &&
                        <OCDetalleDataGrid
                            data={data}
                            callbackHayCambios={handleHayCambios}
                        />
                        }

                    </div>
                )}
            </FormDataConsumer>
        </>
    );
}


export default OrdenDeCompraDetalleProductos;
