import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import Image from "./headerRTAB.jpg"
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import {createTheme, makeStyles} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import LoginBackground from './logos/Login.jpg'
import {Notification, useLogin, useNotify, useTranslate} from 'react-admin';
import {RTATheme} from './themes';
import CardMedia from "@material-ui/core/CardMedia";
import sliderPrincipal from "./headerRTA.jpg";
import AvatarImage from "../componentes/Avatar/AvatarImage";
import Typography from "@material-ui/core/Typography";

const mediaUrl = sliderPrincipal;

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundImage: `url(${LoginBackground})`,

        // backgroundImage: `linear-gradient( rgba(8, 8, 37, 0.85), rgba(0, 15, 80, 0.675)), url("${LoginBackground}")`,
        // backgroundImage: `linear-gradient(to right, #4880EC, #019CAD), url("${LoginBackground}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // opacity: 0.2
    },
    card: {
        minWidth: 300,
        marginTop: '6em',

    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
    img: {
        opacity: 0.1
    }
}));

const renderInput = ({
                         meta: {touched, error} = {},
                         input: {...inputProps},
                         ...props
                     }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const Login = ({location}) => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();

    const handleSubmit = auth => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/').catch(
            error => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning'
                );
            }
        );
    };

    const validate = values => {
        const errors = {};
        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    return (
        <div>
            {/*<CardMedia*/}
            {/*    style={{*/}
            {/*        width: "auto",*/}
            {/*        maxHeight: "100px",*/}
            {/*    }}*/}
            {/*    component="img"*/}
            {/*    image={mediaUrl}*/}
            {/*    title="Contemplative Reptile"*/}
            {/*/>*/}
            <Form
                onSubmit={handleSubmit}
                validate={validate}
                render={({handleSubmit}) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={classes.main}>
                            <Card className={classes.card}>
                                <div className={classes.avatar}>
                                    <AvatarImage medio={'rta'} esLogin={true}/>
                                </div>
                                <div className={classes.form}>
                                    <div className={classes.input}>
                                        <Field
                                            autoFocus
                                            name="username"
                                            component={renderInput}
                                            label={translate('ra.auth.username')}
                                            disabled={loading}
                                        />
                                    </div>
                                    <div className={classes.input}>
                                        <Field
                                            name="password"
                                            component={renderInput}
                                            label={translate('ra.auth.password')}
                                            type="password"
                                            disabled={loading}
                                        />
                                    </div>
                                </div>
                                <CardActions className={classes.actions}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                        disabled={loading}
                                        className={classes.button}
                                        fullWidth
                                    >
                                        {loading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                            />
                                        )}
                                        {translate('ra.auth.sign_in')}
                                    </Button>
                                </CardActions>
                            </Card>
                            <Notification/>
                        </div>
                    </form>

                )}
            />
        </div>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = props => (
    <ThemeProvider theme={createTheme(RTATheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
