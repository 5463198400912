import React, {useState} from "react";
import {FormTab, Show, TextInput, TabbedForm, TopToolbar, NumberInput, EditButton, usePermissions} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    persona_forma: {display: 'inline-block', width: 200, marginLeft: 8},
    persona_forma_d: {display: 'inline-block', width: 800, marginLeft: 8},
}));

const PersonaFormaShow = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    var w_role_auxiliar_editar = ''
    var w_permissions_admuno_auxiliar_editar = permissions
    if(w_permissions_admuno_auxiliar_editar==null){}else{
        var wa_role_auxiliar_editar = w_permissions_admuno_auxiliar_editar.indexOf('ROLE_AUXILIAR_EDITAR')
        if(wa_role_auxiliar_editar===-1){}else{
            w_role_auxiliar_editar = 'ROLE_AUXILIAR_EDITAR'
        }
    }
    const [PersonaForma, setPersonaForma] = useState('');
    const [PersonaTipo, setPersonaTipo] = useState('');
    // const [PersonaFormaSocioPersonaFormaCasado, setPersonaFormaSocioPersonaFormaCasado] = useState('');

    const establecerPersonaForma = value => {
        if (value == null || value === '') return '';
        setPersonaForma(value.trim());
        return value;
    };

    const establecerFecha = value => {
        if (value == null || value === '') return '';
        return value;
    };

    // Obtengo tipo de persona para cargar solapa correspondiente
    const establecerPersonaTipo = value => {
        if (value == null || value === '') return '';
        setPersonaTipo(value.trim());
        return value;
    };


    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    // const establecerPersonaFormaSocioPersonaFormaCasado = value => {
    //     if (value == null || value === '') return '';
    //     setPersonaFormaSocioPersonaFormaCasado(value.trim());
    //     return value;
    // };

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            {w_role_auxiliar_editar === 'ROLE_AUXILIAR_EDITAR' &&
                <EditButton basePath={basePath} record={data}/>
            }
        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        return <span>Persona Forma: {record ? `${record.persona_forma}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>
                <FormTab label="DATOS PERSONA FORMA">
                    <TextInput
                        label="Código Persona Forma"
                        source="persona_forma"
                        formClassName={classes.persona_forma}
                        placeholder="Ingrese Código Persona Forma"
                        helperText="Código Persona Forma"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />

                    <br/>


                    <TextInput
                        label="Descripción Persona Forma"
                        source="persona_forma_d"
                        formClassName={classes.persona_forma_d}
                        placeholder="Ingrese Descripción Persona Forma"
                        helperText="Descripción Persona Forma"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />

                </FormTab>
            </TabbedForm>
        </Show>
    )
};
export default PersonaFormaShow;


