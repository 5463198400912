import React from 'react';
import compose from 'recompose/compose';
import List from '@material-ui/core/List';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {translate} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const VerInfo = ({
                        visitors = [], nb, translate,
                        ver_orden_compra_estado,
                        ver_estado_d,
                        ver_orden_compra_estado_usuario,
                        ver_orden_compra_estado_usuario_fh,
                        ver_orden_compra_origen_nro,
                        ver_orden_compra_origen_ampliacion_nro,
                        ver_contratacion_d,
                        ver_contratacion_nro,
                        ver_contratacion_ano,
                        ver_procedimeinto_referencia_gde,
                        ver_informe_adjudicacion_referencia_gde,
                        ver_codigo_pro_tvpublica,
                        ver_codigo_pro_rna,
                        classes
                    }) => (
    <div>
        <List>
            <Table style={{backgroundColor: '#F2F3F4'}}>
                <TableRow>
                    <TableCell className={classes.textleft}>
                        <Typography><u><b>Estado: {ver_orden_compra_estado}</b></u></Typography>
                        <Typography>
                            {ver_estado_d}
                        </Typography>
                        <Typography>
                            <b>Usuario: </b> {ver_orden_compra_estado_usuario}
                        </Typography>
                        <Typography>
                            <b>Fecha Hora: </b> {ver_orden_compra_estado_usuario_fh}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography><u></u></Typography>
                        <Typography>
                            <b>Oco Origen: </b> {ver_orden_compra_origen_nro}
                        </Typography>
                        <Typography>
                            <b>Apl Origen: </b> {ver_orden_compra_origen_ampliacion_nro}
                        </Typography>
                        <Typography>
                            {''}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography><u></u></Typography>
                        <Typography>
                            <b>Contratación Tipo: </b> {ver_contratacion_d}
                        </Typography>
                        <Typography>
                            <b>Contratación Nro.: </b> {ver_contratacion_nro}
                        </Typography>
                        <Typography>
                            <b>Contratación Año: </b> {ver_contratacion_ano}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography><u></u></Typography>
                        <Typography>
                            <b>Procedimiento GDE: </b> {ver_procedimeinto_referencia_gde}
                        </Typography>
                        <Typography>
                            <b>Adjudicación GDE: </b> {ver_informe_adjudicacion_referencia_gde}
                        </Typography>
                        <Typography>
                            {''}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography></Typography>
                        <Typography>
                            <b>Código Proveedor Tv Pública: </b> {ver_codigo_pro_tvpublica}
                        </Typography>
                        <Typography>
                            <b>Código Proveedor RNA: </b> {ver_codigo_pro_rna}
                        </Typography>
                        <Typography>
                            {''}
                        </Typography>
                    </TableCell>
                </TableRow>
            </Table>
        </List>

    </div>

);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(VerInfo);

