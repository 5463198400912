import React, {useState} from "react";
import {FormTab, Show, TextInput, TabbedForm, TopToolbar, NumberInput, EditButton, usePermissions} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";

const useStyles = makeStyles((theme) => ({
    unidad_negocio: {display: 'inline-block', width: 200, marginLeft: 8},
    unidad_negocio_d: {display: 'inline-block', width: 400, marginLeft: 8},
    sector: {display: 'inline-block', width: 200, marginLeft: 8},
    sector_d: {display: 'inline-block', width: 400, marginLeft: 8},
}));

const UnidadSectorShow = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    var w_role_auxiliar_editar = ''
    var w_permissions_admuno_auxiliar_editar = permissions
    if(w_permissions_admuno_auxiliar_editar==null){}else{
        var wa_role_auxiliar_editar = w_permissions_admuno_auxiliar_editar.indexOf('ROLE_AUXILIAR_EDITAR')
        if(wa_role_auxiliar_editar===-1){}else{
            w_role_auxiliar_editar = 'ROLE_AUXILIAR_EDITAR'
        }
    }
    const [EstadoOrdenCompra, setEstadoOrdenCompra] = useState('');
    const [PersonaTipo, setPersonaTipo] = useState('');
    // const [EstadoOrdenCompraSocioEstadoCivilCasado, setEstadoOrdenCompraSocioEstadoCivilCasado] = useState('');

    const establecerEstadoOrdenCompra = value => {
        if (value == null || value === '') return '';
        setEstadoOrdenCompra(value.trim());
        return value;
    };

    const establecerFecha = value => {
        if (value == null || value === '') return '';
        return value;
    };

    // Obtengo tipo de persona para cargar solapa correspondiente
    const establecerPersonaTipo = value => {
        if (value == null || value === '') return '';
        setPersonaTipo(value.trim());
        return value;
    };


    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    // const establecerEstadoOrdenCompraSocioEstadoCivilCasado = value => {
    //     if (value == null || value === '') return '';
    //     setEstadoOrdenCompraSocioEstadoCivilCasado(value.trim());
    //     return value;
    // };

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            {w_role_auxiliar_editar === 'ROLE_AUXILIAR_EDITAR' &&
                <EditButton basePath={basePath} record={data}/>
            }
        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        return <span>Unidad Sector: {record ? `${record.unidad_negocio}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>
                <FormTab label="DATOS UNIDAD SECTOR">

                    <ComboUnidadNegocio readOnly={true} />

                    <TextInput
                        label="Código Unidad Sector"
                        source="sector"
                        formClassName={classes.sector}
                        placeholder="Ingrese Código Sector"
                        helperText="Código"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />

                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Descripción Unidad Sector"
                        source="sector_d"
                        formClassName={classes.sector_d}
                        multiline
                        placeholder="Ingrese Descripcion Unidad Sector"
                        helperText="Descripcion"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>

                </FormTab>
            </TabbedForm>
        </Show>
    )
};
export default UnidadSectorShow;


