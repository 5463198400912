import React from 'react';
import compose from 'recompose/compose';
import List from '@material-ui/core/List';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {translate} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'rows',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const VerInfo = ({   visitors = [], nb, translate,
                     ver_domicilio_pais,
                     ver_domicilio_provincia,
                     ver_domicilio_partido,
                     ver_domicilio_ciudad,
                     ver_domicilio_calle,
                     ver_domicilio_calle_nro,
                     ver_domicilio_piso,
                     ver_domicilio_departamento,
                     ver_domicilio_codigo_postal,
                     ver_contacto_correo_electronico,
                     ver_contacto_telefono_area,
                     ver_contacto_telefono_nro,
                     ver_contacto_fax_area,
                     ver_contacto_fax_nro,
                     ver_cuit,
                     ver_iva_responsable,
                     ver_ibru_responsable,
                     classes
                 }) => (
    <div>
        <List>
            <Table style={{backgroundColor: '#F2F3F4'}}>
                <TableRow>
                    <TableCell className={classes.textleft}>
                        <Typography><u><b></b></u></Typography>
                        <Typography>
                            <b>Pais: </b> {ver_domicilio_pais}
                        </Typography>
                        <Typography>
                            <b>Provincia: </b> {ver_domicilio_provincia}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography><u></u></Typography>
                        <Typography>
                            <b>Partido: </b> {ver_domicilio_partido}
                        </Typography>
                        <Typography>
                            <b>Ciudad: </b> {ver_domicilio_ciudad}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography><u><b>Calle:</b></u></Typography>
                        <Typography>
                            {ver_domicilio_calle}
                        </Typography>
                        <Typography>
                            <b>Nro.: </b> {ver_domicilio_calle_nro}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography>
                            <b>Piso: </b> {ver_domicilio_piso}
                        </Typography>
                        <Typography>
                            <b>Depto: </b> {ver_domicilio_departamento}
                        </Typography>
                        <Typography>
                            <b>Código Postal: </b> {ver_domicilio_codigo_postal}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography><u><b>Contacto Correo Electronico:</b></u></Typography>
                        <Typography>
                            {ver_contacto_correo_electronico}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography><u><b>Contacto Telefono:</b></u></Typography>
                        <Typography>
                            <b>Area: </b> {ver_contacto_telefono_area}
                        </Typography>
                        <Typography>
                            <b>Nro: </b> {ver_contacto_telefono_nro}
                        </Typography>
                        <Typography><u><b>Contacto Fax:</b></u></Typography>
                        <Typography>
                            <b>Area: </b> {ver_contacto_fax_area}
                        </Typography>
                        <Typography>
                            <b>Nro: </b> {ver_contacto_fax_nro}
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.textleft}>
                        <Typography></Typography>
                        <Typography>
                            <b>CUIT: </b> {ver_cuit}
                        </Typography>
                        <Typography>
                            <b>IVA Responsable: </b> {ver_iva_responsable}
                        </Typography>
                        <Typography>
                            <b>IBRU Responsable: </b> {ver_ibru_responsable}
                        </Typography>
                    </TableCell>
                </TableRow>
            </Table>
        </List>

    </div>

);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(VerInfo);

