import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles(
    theme => ({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                height: '100%',
            },
            [theme.breakpoints.down('lg')]: {
                height: '100vh',
                marginTop: '-3em',
            },
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        message: {
            textAlign: 'center',
            fontFamily: 'Roboto, sans-serif',
            opacity: 0.5,
            margin: '0 1em',
        },
        root: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        rotateIcon: {
            animation: "spin 4s linear infinite"
        },
    }),
    { name: 'RaLoading' }
);

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))(Badge);

const PantallaMensaje = (props) => {
    // console.log(props)
    // console.log(props.mensajePrimario)
    // console.log(props.mensajeSecondario)
    const [progress, setProgress] = React.useState(10);
    var w_imagen = props.p_imagen
    if(w_imagen==null){
        w_imagen=''
    }
    w_imagen=w_imagen.trim()
    const {
        className,
        mensajePrimario = props.p_mensajePrimario,
        mensajeSecondario = props.p_mensajeSecondario,
    } = props;
    const classes = useStyles(props);
    // const translate = useTranslate();

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
        }, 100);
        return () => {
            clearInterval(timer);
        };
    }, []);

    switch (w_imagen) {
        case 'LinearProgress':
            return (
                <div className={classnames(classes.container, className)}>
                    <div className={classes.message}>
                        <div className={classes.root}>
                            <LinearProgress style={{color: '#ffffff', backgroundColor: '#ff0000'}}/>
                        </div>
                        <h1>{mensajePrimario}</h1>
                        <div>{mensajeSecondario}.</div>
                    </div>
                </div>
            );
        case 'CircularProgressred':
            return (
                <div className={classnames(classes.container, className)}>
                    <div className={classes.message}>
                        <CircularProgress className={classes.icon} style={{color: '#ff0000', backgroundColor: '#ffffff'}} />
                        <h1>{mensajePrimario}</h1>
                        <div>{mensajeSecondario}.</div>
                    </div>
                </div>
            );
        case 'CircularProgress':
            return (
                <div className={classnames(classes.container, className)}>
                    <div className={classes.message}>
                        <CircularProgress className={classes.icon} style={{color: '#0080ff', backgroundColor: '#ffffff'}} />
                        <h1>{mensajePrimario}</h1>
                        <div>{mensajeSecondario}.</div>
                    </div>
                </div>
            );
        case 'CircularProgressModal':
            return (
                <div className={classnames(classes.container, className)}>
                    <div className={classes.message}>
                        <CircularProgress className={classes.icon} style={{color: '#0080ff'}} />
                        <h1>{mensajePrimario}</h1>
                        <div>{mensajeSecondario}.</div>
                    </div>
                </div>
            );
        case 'CircularProgressModalabajo':
            return (
                <div>
                    <div className={classnames(classes.container, className)}>
                        <div className={classes.message}>
                            <CircularProgress className={classes.icon} style={{color: '#64c8ff'}} />
                            <div>{'  '}</div>
                            <div>{'  '}</div>
                            <div>{'  '}</div>
                            <div>{'  '}</div>
                            <div>{'  '}</div>
                            <div>{'  '}</div>
                            <div>{'  '}</div>
                            <div>{'  '}</div>
                            <div>{'  '}</div>
                            <div>{'  '}</div>
                            <div>{'  '}</div>
                            <div>{'  '}</div>
                            <div style={{ width: '100%' }}>
                                <Box component="span" display="block" p={1} m={1} >

                                </Box>
                                <Box component="span" display="block" p={1} m={1} >

                                </Box>
                                <Box component="span" display="block" p={1} m={1} >

                                </Box>
                                <Box component="span" display="block" p={1} m={1} >

                                </Box>
                                <Box component="span" display="block" p={1} m={1} >

                                </Box>
                                <Box component="span" display="block" p={1} m={1} >

                                </Box>
                                <Box component="span" display="block" p={1} m={1} >

                                </Box>
                                <Box component="span" display="block" p={1} m={1} >

                                </Box>
                            </div>
                            <h1>{mensajePrimario}</h1>
                            <div>{mensajeSecondario}.</div>
                        </div>
                    </div>
                </div>
            );
        case 'Alertinfosintitle':
            return (
                <div>
                    <div severity="info">
                        <div>{mensajePrimario}</div>
                        <strong>{mensajeSecondario}.</strong>
                    </div>
                </div>
            );
        case 'Alertinfosintitlesinpunto':
            return (
                <div>
                    <div severity="info">
                        <div>{mensajePrimario}</div>
                        <strong>{mensajeSecondario}</strong>
                    </div>
                </div>
            );
        case 'Alertinfonotificacion':
            return (
                <div>
                    <IconButton aria-label="cart" >
                        <StyledBadge badgeContent={mensajePrimario} style={{color: '#ffffff'}}>
                            {/*<NotificationsActiveIcon style={{color: '#f2ff00'}}/>*/}
                            <NotificationsActiveIcon style={{color: '#f2ff00'}} className={classes.rotateIcon} />
                            <style>
                                {`
                                    @keyframes spin {
                                         0% { transform: rotate(-25deg); }
                                         25% { transform: rotate(0deg); }
                                         50% { transform: rotate(40deg); }
                                         75% { transform: rotate(0deg); }
                                         100% { transform: rotate(-25deg); }
                                    }
                                `}
                            </style>
                        </StyledBadge>
                    </IconButton>
                </div>
            );
        case 'Alerterrorsintitle':
            return (
                <div>
                    <div severity="error">
                        <div>{mensajePrimario}</div>
                        <strong>{mensajeSecondario}.</strong>
                    </div>
                </div>
            );
        case 'Alerterror':
            return (
                <div className={classes.root}>
                    <div severity="error">
                        <div>Error</div>
                        {mensajePrimario} — <strong>{mensajeSecondario}.</strong>
                    </div>
                </div>
            );
        case 'Alertwarning':
            return (
                <div className={classes.root}>
                    <div severity="warning">
                        <div>Advertencia</div>
                        {mensajePrimario} — <strong>{mensajeSecondario}.</strong>
                    </div>
                </div>
            );
        case 'Alertinfo':
            return (
                <div className={classes.root}>
                    <div severity="info">
                        <div>Información</div>
                        {mensajePrimario} — <strong>{mensajeSecondario}.</strong>
                    </div>
                </div>
            );
        case 'Alertsuccess':
            return (
                <div className={classes.root}>
                    <div severity="success">
                        <div>Exito</div>
                        {mensajePrimario} — <strong>{mensajeSecondario}.</strong>
                    </div>
                </div>
            );
        default:
            return (
                <div className={classnames(classes.container, className)}>
                    <div className={classes.message}>
                        <h1>{mensajePrimario}</h1>
                        <div>{mensajeSecondario}.</div>
                    </div>
                </div>
            );
    }
};

// PantallaMensaje.propTypes = {
//     classes: PropTypes.object,
//     className: PropTypes.string,
//     mensajePrimario: PropTypes.string,
//     mensajeSecondario: PropTypes.string,
// };
//
// PantallaMensaje.defaultProps = {
//     mensajePrimario: 'Sin Datos',
//     mensajeSecondario: 'Sin Información para los filtros Ingresados',
// };

export default PantallaMensaje;
