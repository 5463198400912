import React from 'react';
import {Link} from 'react-admin';
import SearchIcon from '@material-ui/icons/Search';
import Icon from "@material-ui/icons/Add";
import Button from "@mui/material/Button";

const LinkSubirArchivo = ({record}) => {
    return (
        <Link to={`/ProveedorDocumentacionAdjunto/${record.id}`}>
            <div title={'Adjuntar Documentacion'}
                 style={{
                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center',
                 }}>
                <Button variant="contained" size={"small"} style={{color: '#ffffff', backgroundColor: '#123B80'}}>
                    <Icon/>
                    Adjuntar Documentacion
                </Button>
            </div>
        </Link>
    );
}

LinkSubirArchivo.defaultProps = {
    source: '',
    addLabel: true,
};

export default LinkSubirArchivo;