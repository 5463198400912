import {SelectInput, ReferenceInput, TextInput} from 'react-admin';
import React from "react";

const ComboContratacionEstado = (props) => {
    const isReadonly = props.readOnly;
    const islabel = props.label;
    const isvariant = props.variant;
    return (
        <ReferenceInput label={islabel===undefined || islabel===null ? 'Estado' :
            islabel.trim()==='' ? 'Estado' : islabel}
                        source="id_estado_encuadre" reference="ContratacionEstado"
                        // variant="outlined"
                        variant={isvariant===undefined || isvariant===null ? 'outlined' : isvariant.trim()}
        >
            <SelectInput optionText="estado_encuadre_d" InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: isReadonly}} />
        </ReferenceInput>
    )
};

export default ComboContratacionEstado;
