import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    NumberField,
    DateField, useNotify, useRefresh, useMutation
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from "@material-ui/icons/Save";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const ContratacionEncuadreAdjudicacionButtonDelete = ({record}) => {
    var wr_id = record.id
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const BotonEliminarDetelle = (record) => {

        console.log('BotonEliminarDetelle')

        var w_mensaje = ' '

        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'delete',
                resource: 'ContratacionEncuadreAdjudicacion',
                payload: {id: wr_id}
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                    notify('('+wr_id+') Seleccionado Eliminado el Detelle', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------
                    setOpen(false);
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                    onClick={generardetelle}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    Si
                </div>
            </Button>
        );

    };

    const handleSi = () => {

    };

    const handleNo = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                    onClick={handleClickOpen}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <DeleteForeverIcon style={{color: '#c00000', backgroundColor: '#ffffff'}}/>
                    Eliminar
                </div>
            </Button>
            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Detalle de la Contratacion Encuadre Adjudicacion</DialogTitle>
                <DialogContent>
                    ¿Seguro que quiere eliminar este Detalle de la Orden de Compra?
                </DialogContent>
                <DialogActions>
                    <BotonEliminarDetelle/>
                    <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                            onClick={handleNo}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            NO
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

ContratacionEncuadreAdjudicacionButtonDelete.defaultProps = {
    source: '',
    addLabel: true,
};

export default ContratacionEncuadreAdjudicacionButtonDelete;