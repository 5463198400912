import React, {useState, useCallback} from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Create,
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    AutocompleteInput,
    SelectInput,
    DateInput,
    TopToolbar,
    ReferenceInput,
    Toolbar, SaveButton, DeleteButton,
    useGetOne, Loading, NumberInput, TextField, ShowButton
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";


const useStyles = makeStyles((theme) => ({
    unidad_negocio: {display: 'inline-block', width: 200, marginLeft: 8},
    unidad_negocio_d: {display: 'inline-block', width: 400, marginLeft: 8},
    programa: {display: 'inline-block', width: 200, marginLeft: 8},
    programa_d: {display: 'inline-block', width: 400, marginLeft: 8},
}));

const UnidadProgramaEdit = props => {
    const classes = useStyles();
    const [state, setState] = useState({});
    const [EstadoOrdenCompra, setEstadoOrdenCompra] = useState('');
    const [PersonaTipo, setPersonaTipo] = useState('');

    /// Búsqueda país
    var w_pais_legal = '', w_pais_comercial = '';

    const {data: Data, loading: Loading} = useGetOne('EstadoOrdenCompra', props.id);

    if (Data === undefined) {
    } else {
        w_pais_legal = Data.legal_pais;
        w_pais_comercial = Data.PaisComercial ? Data.PaisComercial.pais.trim() : '';
    }

    const {
        PaisLegal = w_pais_legal, PaisComercial = w_pais_comercial,
    } = state;

    const formatPaisLegal = v => {
        return PaisLegal
    };

    const formatPaisComercial = v => {
        return PaisComercial
    };

    const updatePaisLegal = useCallback(async (who) => {
        setState(state => ({
            ...state,
            pais_codigo_afip_legal: who.pais_codigo_afip,
            PaisLegal: who.pais.trim(),
        }));
    })


    const updatePaisComercial = useCallback(async (who) => {
        setState(state => ({
            ...state,
            pais_codigo_afip_comercial: who.pais_codigo_afip,
            PaisComecial: who.pais.trim(),
        }));
    })

    const establecerEstadoOrdenCompra = value => {
        if (value == null || value === '') return '';
        setEstadoOrdenCompra(value.trim());
        return value;
    };

    const establecerFecha = value => {
        if (value == null || value === '') return '';
        return value;
    };

    // Obtengo tipo de persona para cargar solapa correspondiente
    const establecerPersonaTipo = value => {
        if (value == null || value === '') return '';
        setPersonaTipo(value.trim());
        return value;
    };

    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );

    const redirect = (basePath, id, data) => `/UnidadPrograma`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            <DeleteButton undoable={false} redirect={redirect}/>
        </Toolbar>
    );
    const transform = data => {
        return {
            ...data
        }
    };

    const PostTitle = ({record}) => {
        return <span>Unidad Programa: {record ? `${record.unidad_negocio}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={<CustomToolbar/>}
            >
                {/*Datos*/}
                <FormTab label="DATOS UNIDAD PROGRAMA">

                    <ComboUnidadNegocio readOnly={true}  formClassName={classes.unidad_negocio}/>
                    <br/>

                    <TextInput
                        label="Código Unidad Programa"
                        source="programa"
                        formClassName={classes.programa}
                        placeholder="Ingrese Código Programa"
                        helperText="Código"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Descripción Unidad Programa"
                        source="programa_d"
                        formClassName={classes.programa_d}
                        multiline
                        placeholder="Ingrese Descripcion Unidad Programa"
                        helperText="Descripcion"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                </FormTab>
            </TabbedForm>
        </Edit>
    )
};
export default UnidadProgramaEdit;


