// import React, {useCallback, useState} from 'react';
// import {useNotify, useRefresh, useMutation, ReferenceInput, SelectInput} from 'react-admin';
// import {
//     TextInput,
// } from 'react-admin';
// import Button from '@mui/material/Button';
// import IconCancelar from '@material-ui/icons/Close';
// import SaveIcon from '@material-ui/icons/Save';
// import {makeStyles} from "@material-ui/core";
// import DocumentacionTipoBusquedaBtn from "../DocumentacionTipo/DocumentacionTipoBusquedaBtn";
//
// const useStyles = makeStyles((theme) => ({
//
//     documentacion_tipo: {display: 'inline-block', width: '100%'},
//     documentacion_tipo_d: {display: 'inline-block', width: '100%'},
//     documentacion_ingresar_vencimiento: {display: 'inline-block', width: '100%'},
//     documentacion_adjuntar_archivo: {display: 'inline-block', width: '100%'},
//     fecha_ingreso: {display: 'inline-block', width: '100%'},
//     fecha_vencimiento: {display: 'inline-block', width: '100%'},
//     id_documentacion_tipo: {display: 'inline-block', width: '100%'},
//
// }));
//
//
// function ProveedorDocumentacionVariasAgregar({pro, callbackCloseAVDDrawer}) {
//     // const [page, setPage] = useState(1);
//     // const [perPage, setPerPage] = useState(25);
//     // const [sort, setSort] = useState({field: 'id', order: 'ASC'});
//     const classes = useStyles();
//
//     const [{
//         proveedor,
//         documentacion_tipo,
//         documentacion_tipo_d,
//         documentacion_ingresar_vencimiento,
//         documentacion_adjuntar_archivo,
//         fecha_ingreso,
//         fecha_vencimiento,
//         id_documentacion_tipo
//     }, setState] = useState({
//         proveedor: pro,
//         documentacion_tipo: "",
//         documentacion_tipo_d: "",
//         documentacion_ingresar_vencimiento: "",
//         documentacion_adjuntar_archivo: "",
//         fecha_ingreso: null,
//         fecha_vencimiento: null,
//         id_documentacion_tipo: null
//     });
//
//     /// JN Mascara para el input cuit-cuil
//     const cuitCuilFormat = value => {
//         if (value == null || value === '') return '';
//
//         //normalize string and remove all unnecessary characters
//         var valueCuit = value.replace(/[^\d]/g, "");
//
//         //check if number length equals to 11
//         if (valueCuit.length == 11) {
//             return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
//         }
//
//         return value
//     };
//
//     function handleTextInputChange({target: {name, value}}) {
//         setState(prevState => ({...prevState, [name]: value}));
//     }
//
//     const BtnCancelar = ({record}) => (
//         <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
//             callbackCloseAVDDrawer(false)
//         }}
//         >
//             <IconCancelar/>
//             Volver
//         </Button>
//     );
//
//     const BotonCrearDetelle = (record) => {
//
//         const notify = useNotify();
//         const refresh = useRefresh();
//
//         const [generardetelle, {loading}] = useMutation({
//                 type: 'create',
//                 resource: 'ProveedorDocumentacion',
//                 payload: {
//                     data: {
//                         proveedor,
//                         documentacion_tipo,
//                         documentacion_tipo_d,
//                         documentacion_ingresar_vencimiento,
//                         documentacion_adjuntar_archivo,
//                         fecha_ingreso,
//                         fecha_vencimiento,
//                         id_documentacion_tipo
//                     }
//                 }
//             },
//             {
//                 onSuccess: ({data}) => {
//                     refresh()
//                 },
//                 onFailure: error => {
//                     notify(`Error durante la Actualizacion: ${error.message}`, "warning");
//                 },
//             }
//         );
//
//
//         return (
//             <Button variant="outlined" color="primary" onClick={generardetelle}>
//                 <div title={''}
//                      style={{
//                          display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
//                      }}>
//                     <SaveIcon style={{color: '#004187'}}/>
//                     Guardar
//                 </div>
//             </Button>
//         );
//
//     };
//
//     const formatid_documentacion_tipo = v => {
//         return id_documentacion_tipo
//     };
//
//     const formatdocumentacion_tipo = v => {
//         return documentacion_tipo
//     };
//
//     const formatdocumentacion_tipo_d = v => {
//         return documentacion_tipo_d
//     };
//
//     const formatdocumentacion_ingresar_vencimiento = v => {
//         return documentacion_ingresar_vencimiento
//     };
//
//     const formatdocumentacion_adjuntar_archivo = v => {
//         return documentacion_adjuntar_archivo
//     };
//
//
//     const updateDocumentacionTipo = useCallback(async (who) => {
//         setState(state => ({
//             ...state,
//             id_documentacion_tipo: who.id,
//             documentacion_tipo: who.documentacion_tipo,
//             documentacion_tipo_d: who.documentacion_tipo_d,
//             documentacion_ingresar_vencimiento: who.documentacion_ingresar_vencimiento,
//             documentacion_adjuntar_archivo: who.documentacion_adjuntar_archivo,
//
//         }));
//
//     });
//
//     return (
//         <div style={{paddingLeft: "10px"}}>
//
//
//
//
//             <div style={{display: 'inline-flex', width: '100%'}}>
//                 <TextInput source="id_documentacion_tipo" label={"Documentacion Tipo ID"}
//                            className={classes.id_documentacion_tipo}
//                            variant="outlined" inputProps={{readOnly: true}}
//                            value={id_documentacion_tipo} fullWidth
//                            format={formatid_documentacion_tipo}
//                            InputLabelProps={{shrink: true}}
//                 />
//
//                 <DocumentacionTipoBusquedaBtn
//                     callbackDocumentacionTipo={updateDocumentacionTipo}
//                     className={classes.btnBusqueda} fullWidth={true}/>
//             </div>
//
//
//
//             <TextInput source="documentacion_tipo" label={"Documentacion Tipo"}
//                        className={classes.documentacion_tipo}
//                        variant="filled" inputProps={{readOnly: true,}}
//                        value={documentacion_tipo} fullWidth={true}
//                        format={formatdocumentacion_tipo}
//                        InputLabelProps={{shrink: true}}
//             />
//
//             <TextInput source="documentacion_tipo_d" label={"Documentacion Tipo Descripcion"}
//                        className={classes.documentacion_tipo_d}
//                        variant="filled" inputProps={{readOnly: true,}}
//                        value={documentacion_tipo_d} fullWidth={true}
//                        format={formatdocumentacion_tipo_d}
//                        multiline
//                        InputLabelProps={{shrink: true}}
//             />
//             <TextInput source="documentacion_adjuntar_archivo" label={"Documentacion Adjuntar Archivo"}
//                        className={classes.documentacion_adjuntar_archivo}
//                        variant="filled" inputProps={{readOnly: true,}}
//                        value={documentacion_adjuntar_archivo} fullWidth={true}
//                        format={formatdocumentacion_adjuntar_archivo}
//                        InputLabelProps={{shrink: true}}
//             />
//             <TextInput source="documentacion_ingresar_vencimiento" label={"Documentacion Ingresar Vencimiento"}
//                        className={classes.documentacion_ingresar_vencimiento}
//                        variant="filled" inputProps={{readOnly: true,}}
//                        value={documentacion_ingresar_vencimiento} fullWidth={true}
//                        format={formatdocumentacion_ingresar_vencimiento}
//                        InputLabelProps={{shrink: true}}
//             />
//
//
//             <div>
//
//                 <BotonCrearDetelle/>
//
//                 <BtnCancelar/>
//
//
//             </div>
//         </div>
//     );
// }
//
// export default ProveedorDocumentacionVariasAgregar;



import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import BuscarProveedoresDocumentacion from './BuscarProveedoresDocumentacionList';
import ProveedorDocumentacionCreate from './ProveedorDocumentacionCreate';
import {makeStyles} from "@material-ui/core/styles";
import {useBuscadorContext} from "../Context/BuscadorProveedorClaseContextProvider";
import {
    SimpleForm, TabbedForm, useDataProvider, useNotify, useRedirect, useCreate, useRefresh, useMutation
} from 'react-admin';
import Porcentaje from "../componentes/Porcentaje";
import ApiUrlServer from '../layout/ApiUrlServer'
import IconCancelar from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

var w_apiurlserver = ApiUrlServer;
if (w_apiurlserver == null) {
    w_apiurlserver = ''
}
w_apiurlserver = w_apiurlserver.trim();


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    button: {
        marginRight: theme.spacing(1)
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

function ProveedorDocumentacionVariasAgregar(pro, callbackCloseAVDDrawer) {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const classes = useStyles();
    const {clasesList, rubrosList, limpiarClases, limpiarRubros} = useBuscadorContext();
    const [create] = useCreate('ProveedorDocumentacion');
    const notify = useNotify();
    const refresh = useRefresh()

    const BtnCancelar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
            callbackCloseAVDDrawer(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const getSteps = () => {
        return ['Seleccionar para Agregar Varias Documentaciones'];
        // return ['Seleccionar Agregar Varias Documentaciones', "Nuevo Agregar Varias Documentaciones"];
    };

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})
    const [EstadoPorcentaje, setEstadoPorcentaje] = useState(0);
    const [EstadoTiempo, setEstadoTiempo] = useState('');
    const [EstadoPar, setEstadoPar] = useState('N');
    const [EstadoCantidad, setEstadoCantidad] = useState('');
    const [Estadoidmlsel, setEstadoidmlsel] = useState('');

    const steps = getSteps();
    const dataProvider = useDataProvider();
    const redirectTo = useRedirect();
    const redirect = (basePath, id, data) => `/proveedor/?filter=%7B%7D&order=DESC&page=1&perPage=10&sort=pro`;

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <BuscarProveedoresDocumentacion pro={pro}
                    />
                );
            case 1:
                return (
                    <ProveedorDocumentacionCreate setActiveStep={setActiveStep}
                                                  pro={pro}/>
                );
            // case 2:
            //     return (
            //         <>
            //             {clasesList.length > 0 ?
            //                 <SimpleForm toolbar={false}>
            //                     <VerProveedores/>
            //                 </SimpleForm>
            //                 :
            //                 <h4>No hay clases seleccionadas</h4>
            //             }
            //         </>
            //     );
        }
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        // let newSkipped = skipped;
        // if (isStepSkipped(activeStep)) {
        //     newSkipped = new Set(newSkipped.values());
        //     newSkipped.delete(activeStep);
        // }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        // setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        limpiarClases();
        // limpiarRubros();
    };


    const handleActualizar  = () => {
        var w_selectedIds = rubrosList
        var mseg = 1000 ;
        var msegMinuto = mseg * 60;
        var msegHora = msegMinuto * 60;
        var msegDia = msegHora * 24;
        var hoy = new Date()
        var wl_selectedIds = w_selectedIds.length
        var w_cant = 0
        var w_es_par
        var w_inicio_porcentaje = 0
        setEstadoPorcentaje(w_inicio_porcentaje);
        if (wl_selectedIds > 0) {
            var i = 0;
            function next() {
                if (i < wl_selectedIds) {
                    var w_selectedId_can = w_selectedIds[i]
                    w_cant = w_cant + 1

                    if(w_cant % 2 === 0  ){
                        w_es_par = 'S'
                    }else{
                        w_es_par = 'N'
                    }
                    setEstadoPar(w_es_par)

                    var w_idml_sel = '('+w_selectedIds[i]+')'
                    w_idml_sel = ''
                    setEstadoidmlsel(w_idml_sel)

                    var w_res_cantidad = ' ('+w_cant+'/'+wl_selectedIds+')'
                    setEstadoCantidad(w_res_cantidad)

                    var tiempo = new Date() - hoy

                    //calcular dias
                    var dias = Math.floor(tiempo / msegDia)
                    tiempo = tiempo - (dias * msegDia)

                    //calcula horas
                    var horas = Math.floor(tiempo / msegHora)
                    tiempo = tiempo - (horas * msegHora)

                    //calcula minutos
                    var minutos = Math.floor(tiempo /msegMinuto)
                    tiempo = tiempo - (minutos * msegMinuto)

                    //calcula segundos
                    var segundos = Math.floor(tiempo /mseg)
                    tiempo = tiempo - (segundos * mseg)

                    var w_mostrar_tiempo = (horas < 10 ? "0" + horas : horas) + ":" + (minutos < 10 ? "0" + minutos : minutos) + ":" + (segundos < 10 ? "0" + segundos : segundos)
                    // console.log(`Han pasado ${dias} dias ${horas} horas y ${minutos} minutis con ${segundos} segundo`)
                    setEstadoTiempo(w_mostrar_tiempo)

                    let w_generar_articulosml
                    const Selecciona = new FormData()
                    Selecciona.append('id', w_selectedId_can);
                    Selecciona.append('cantidad', w_cant);
                    Selecciona.append('cantidad_total', wl_selectedIds);

                    let wp_porcentaje = 0
                    if(wl_selectedIds===0){}else {
                        wp_porcentaje = ((w_cant / wl_selectedIds) * 100)
                        wp_porcentaje = Math.round(wp_porcentaje * 100) / 100
                    }
                    if(wp_porcentaje >= 100) {
                        setEstadoPorcentaje(wp_porcentaje);
                    }

                    dataProvider.getList(
                        'ProveedorDocumentacionCreate',
                        {
                            filter: {
                                id_documentacion_tipo: w_selectedId_can,
                                cantidad: w_cant, cantidad_total: wl_selectedIds,
                                apiurlserver: w_apiurlserver,
                                proveedor: pro
                            },
                            sort,
                            pagination: {page: page, perPage: perPage},
                        },)
                        .then(({data}) => {

                            // console.log('data------ProveedorDocumentacion')
                            // console.log(data)
                            // console.log('response.data.porcentaje------ProveedorDocumentacion')
                            // console.log(data[0].porcentaje)
                            let w_error = data[0].error
                            let w_mensaje = data[0].mensaje
                            let w_porcentaje = data[0].porcentaje
                            let w_termino = data[0].termino
                            if (w_error === 'N') {
                                w_generar_articulosml = 'P'
                            } else {
                                notify(`Atención: ${w_mensaje}`, 'warning')

                            }
                            if (w_generar_articulosml === 'P') {
                                if (wl_selectedIds === 0) {
                                } else {
                                    setEstadoPorcentaje(w_porcentaje);
                                }
                                if (w_termino === 'S') {
                                    var wp_inicio_porcentaje = 0
                                    setEstadoPorcentaje(wp_inicio_porcentaje);
                                    // redirectTo(redirect)
                                    notify('Agregado Varias Documentaciones de los elementos Seleccionados', 'info', {}, false);
                                    refresh()
                                } else {
                                    i++;
                                    next();
                                }
                            }
                        })
                        .catch(error => {
                            wl_selectedIds = [];
                            if (error.response) {
                                //HTTP error happened
                                notify(`Error al Subir. Código de estado / error HTTP: ${error.response.status}`, 'warning')
                                // console.log("Upload error. HTTP error/status code=", error.response.status);
                            } else {
                                //some other error happened
                                notify(`Error al Subir. Código de mensaje / error HTTP: ${error.message}`, 'warning')
                                // console.log("Upload error. HTTP error/message code=", error.message);
                            }
                        })
                } else {
                    // last one done
                    wl_selectedIds = [];
                }
            }
            // kick off the first one
            next();
        }
    }



    return (
        <div>
            <Box sx={{width: '100%'}}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
            {activeStep !== 1 &&
            <div>
                {/*<Button*/}
                {/*    disabled={activeStep === 0}*/}
                {/*    onClick={handleBack}*/}
                {/*    className={classes.button}*/}
                {/*>*/}
                {/*    Volver*/}
                {/*</Button>*/}

                {/*<Button*/}
                {/*    variant="contained"*/}
                {/*    style={{color: '#ffffff', backgroundColor: '#123B80'}}*/}
                {/*    onClick={handleNext}*/}
                {/*    // className={classes.button}*/}
                {/*    disabled={activeStep === 2}*/}
                {/*>*/}
                {/*    {"Siguiente"}*/}
                {/*    /!*{activeStep === steps.length - 1 ? "Ver Proveedores" : "Siguiente"}*!/*/}
                {/*</Button>*/}

                <div style={{display: 'inline-block', width: 600, marginLeft: 8}}>

                    {rubrosList.length > 0 ?
                        <>
                            <Button onClick={handleActualizar}
                                    variant="contained"
                                    style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                    className={classes.button}>
                                <SaveIcon style={{color: '#ffffff', backgroundColor: '#123B80'}}/>
                                Agregar Varias Documentaciones
                            </Button>
                        </>
                        :
                        <>
                            <Button disabled
                                    variant="contained"
                                // style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                    className={classes.button}>
                                <SaveIcon
                                    // style={{color: '#004187'}}
                                />
                                Agregar Varias Documentaciones
                            </Button>
                        </>
                    }

                    <Porcentaje  porcentaje={EstadoPorcentaje}
                                 tiempo={EstadoTiempo}
                                 espar={EstadoPar}
                                 cantidad={EstadoCantidad}
                                 id_ml_sel={Estadoidmlsel}/>

                </div>
                {/*<div style={{display: 'inline-block', width: 400, marginLeft: 8}}>*/}

                {/*    <BtnCancelar/>*/}

                {/*</div>*/}

            </div>
            }
            <div>
                <Typography className={classes.instructions}>
                    {getStepContent(activeStep)}
                </Typography>

            </div>
        </div>
    );
}

export default ProveedorDocumentacionVariasAgregar;