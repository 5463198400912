import React, {useEffect, useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput,
    NumberField,
    Datagrid,
    DateInput,
    SaveButton,
    Link,
    useDataProvider,
    useNotify,
    useRefresh,
    useMutation,
    useRedirect, SimpleForm, BooleanInput, email, PasswordInput, SelectInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DialogContent from "@material-ui/core/DialogContent";
import FormHelperText from "@material-ui/core/FormHelperText";
import NumberFormat from 'react-number-format';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import PropTypes from 'prop-types';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import EmailIcon from "@material-ui/icons/Email";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DialpadIcon from "@material-ui/icons/Dialpad";

const useStyles = makeStyles((theme) => ({
    username: {display: 'inline-block', width: 400, marginLeft: 8},
    password: {display: 'inline-block', width: 400, marginLeft: 8},
    version: {display: 'inline-block', width: 100, marginLeft: 8},
    account_expired: {display: 'inline-block', width: 200, marginLeft: 8},
    account_locked: {display: 'inline-block', width: 200, marginLeft: 8},
    enabled: {display: 'inline-block', width: 200, marginLeft: 8},
    password_expired: {display: 'inline-block', width: 200, marginLeft: 8},
    es_administrador: {display: 'inline-block', width: 100, marginLeft: 8},
    whatsapp_nro: {display: 'inline-block', width: 800, marginLeft: 8},
    email: {display: 'inline-block', width: 800, marginLeft: 8},
    enviar_whatsapp: {display: 'inline-block', width: 200, marginLeft: 8},
    enviar_correo: {display: 'inline-block', width: 200, marginLeft: 208},
    enviar_notificacion: {display: 'inline-block', width: 200, marginLeft: 208},
}));

const PostCreateToolbar = props => {

    return (
        <Toolbar {...props} >
            <SaveButton
                label="Guardar"
                transform={transform}
                disabled={props.pristine}
                submitOnEnter={false}
            />
            <Grid container spacing={3}>
                <Grid item xs>

                </Grid>
                <Grid item xs>
                    <Link to={`/UserUsuCom/`}>
                        <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <Button
                                label="Salir"
                            >
                                <ExitToAppIcon/> Salir
                            </Button>
                        </div>
                    </Link>
                </Grid>
            </Grid>
        </Toolbar>
    )

};

const transform = data => {
    return {
        ...data
    }
};

const validateCreate = (values) => {
    const errors = {};

    var w_estado_encuadre = values.estado_encuadre

    if (w_estado_encuadre===undefined) {
        errors.estado_encuadre = ['El campo Código es requerido'];
    }else {
        if (w_estado_encuadre === null) {
            errors.estado_encuadre = ['El campo Código es requerido'];
        } else {
            w_estado_encuadre = w_estado_encuadre.trim()
            if (w_estado_encuadre === '') {
                errors.estado_encuadre = ['El campo Código es requerido'];
            }
        }
    }


    var w_estado_encuadre_d = values.estado_encuadre_d

    if (w_estado_encuadre_d===undefined) {
        errors.estado_encuadre_d = ['El campo Descripción Contratacion Estado es requerido'];
    }else {
        if (w_estado_encuadre_d === null) {
            errors.estado_encuadre_d = ['El campo Descripción Contratacion Estado es requerido'];
        } else {
            w_estado_encuadre_d = w_estado_encuadre_d.trim()
            if (w_estado_encuadre_d === '') {
                errors.estado_encuadre_d = ['El campo Descripción Contratacion Estado es requerido'];
            }
        }
    }




    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/UserUsuCom`;

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix=""
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const UserUsuComCreate = props => {
    const classes = useStyles();

    var dateFechaEncuadre = new Date();
    var duedateFechaEncuadre = new Date(dateFechaEncuadre);
    duedateFechaEncuadre.setDate(dateFechaEncuadre.getDate());
    var dFechaEncuadre = new Date(duedateFechaEncuadre || Date.now()),
        monthFechaEncuadre = '' + (dFechaEncuadre.getMonth() + 1),
        dayFechaEncuadre = '' + (dFechaEncuadre.getDate()),
        yearFechaEncuadre = dFechaEncuadre.getFullYear();

    //console.log(month)

    if (monthFechaEncuadre.length < 2) monthFechaEncuadre = '0' + monthFechaEncuadre;
    if (dayFechaEncuadre.length < 2) dayFechaEncuadre = '0' + dayFechaEncuadre;

    // var w_f_carga1FechaEncuadre = [dayFechaEncuadre, monthFechaEncuadre, yearFechaEncuadre].join('/');
    var w_f_carga2FechaEncuadre = [yearFechaEncuadre, monthFechaEncuadre, dayFechaEncuadre].join('-');

    var dateMonedaCotizacionFecha = new Date();
    var duedateMonedaCotizacionFecha = new Date(dateMonedaCotizacionFecha);
    duedateMonedaCotizacionFecha.setDate(dateMonedaCotizacionFecha.getDate()-1);
    var dMonedaCotizacionFecha = new Date(duedateMonedaCotizacionFecha || Date.now()),
        monthMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getMonth() + 1),
        dayMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getDate()),
        yearMonedaCotizacionFecha = dMonedaCotizacionFecha.getFullYear();

    //console.log(month)

    if (monthMonedaCotizacionFecha.length < 2) monthMonedaCotizacionFecha = '0' + monthMonedaCotizacionFecha;
    if (dayMonedaCotizacionFecha.length < 2) dayMonedaCotizacionFecha = '0' + dayMonedaCotizacionFecha;

    // var w_f_carga1MonedaCotizacionFecha = [dayMonedaCotizacionFecha, monthMonedaCotizacionFecha, yearMonedaCotizacionFecha].join('/');
    var w_f_carga2MonedaCotizacionFecha = [yearMonedaCotizacionFecha, monthMonedaCotizacionFecha, dayMonedaCotizacionFecha].join('-');

    const dataProviderTableroControl = useDataProvider();
    const [EstadoContratacionEncuadre, setEstadoContratacionEncuadre] = useState(1);
    const [Estadofecha_encuadre, setEstadofecha_encuadre] = useState(w_f_carga2FechaEncuadre)
    const [errorfecha_encuadre, setErrorfecha_encuadre] = useState(false);
    const [helperTextfecha_encuadre, setHelperTextfecha_encuadre] = useState('');
    const [Estadoexpediente_referencia_gde, setEstadoexpediente_referencia_gde] = useState('')
    const [Estadocontratacion_tipo, setEstadocontratacion_tipo] = useState('')
    const [Estadocontratacion_ano, setEstadocontratacion_ano] = useState(yearFechaEncuadre)
    const [Estadomoneda, setEstadomoneda] = useState('PES')
    const [Estadomoneda_cotizacion, setEstadomoneda_cotizacion] = useState(1)
    const [EstadoreadOnlymoneda_cotizacion, setEstadoreadOnlymoneda_cotizacion] = useState(true);
    const [Estadovariantmoneda_cotizacion, setEstadovariantmoneda_cotizacion] = useState('filled');
    const [Estadomoneda_cotizacion_fecha, setEstadomoneda_cotizacion_fecha] = useState(w_f_carga2MonedaCotizacionFecha)
    const [EstadoreadOnlymoneda_cotizacion_fecha, setEstadoreadOnlymoneda_cotizacion_fecha] = useState(true);
    const [Estadovariantmoneda_cotizacion_fecha, setEstadovariantmoneda_cotizacion_fecha] = useState('filled');
    const [errormoneda_cotizacion_fecha, setErrormoneda_cotizacion_fecha] = useState(false);
    const [helperTextmoneda_cotizacion_fecha, setHelperTextmoneda_cotizacion_fecha] = useState('');
    const [Estadoimporte_presupuesto, setEstadoimporte_presupuesto] = useState(0)
    const [Estadoimporte_presupuesto_pesos, setEstadoimporte_presupuesto_pesos] = useState(0)
    const [Estadoimporte_adjudicado_pesos, setEstadoimporte_adjudicado_pesos] = useState(0)
    const [Estadoconcepto_descripcion, setEstadoconcepto_descripcion] = useState('')
    const [ComboMoneda, setComboMoneda] = useState([]);
    const [ComboManualContratacionTipo, setComboManualContratacionTipo] = useState([]);
    const [Estadounidad_negocio, setEstadounidad_negocio] = useState('');
    const [Estadounidad_area, setEstadounidad_area] = useState('');
    const [ComboManualUnidadNegocio, setComboManualUnidadNegocio] = useState([]);
    const [ComboManualArea, setComboManualArea] = useState([]);

    const [errorcontratacion_tipo, setErrorcontratacion_tipo] = useState(false);
    const [helperTextcontratacion_tipo, setHelperTextcontratacion_tipo] = useState('');

    const [errormoneda, setErrormoneda] = useState(false);
    const [helperTextmoneda, setHelperTextmoneda] = useState('');

    const [errorunidad_negocio, setErrorunidad_negocio] = useState(false);
    const [helperTextunidad_negocio, setHelperTextunidad_negocio] = useState('');

    const [errorunidad_area, setErrorunidad_area] = useState(false);
    const [helperTextunidad_area, setHelperTextunidad_area] = useState('');

    const [errorexpediente_referencia_gde, setErrorexpediente_referencia_gde] = useState(false);
    const [helperTextexpediente_referencia_gde, setHelperTextexpediente_referencia_gde] = useState('');



    useEffect(() => {
        dataProviderTableroControl.getList(
            'MonedaActivo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'U' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboMoneda(data);
            })
            .catch(error => {

            })
        dataProviderTableroControl.getList(
            'ContratacionTipo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'U' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboManualContratacionTipo(data);
            })
            .catch(error => {

            })
        dataProviderTableroControl.getList(
            'UnidadNegocio',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'U' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboManualUnidadNegocio(data);
            })
            .catch(error => {

            })
        dataProviderTableroControl.getList(
            'UnidadArea',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'U', unidad_negocio: Estadounidad_negocio },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboManualArea(data);
            })
            .catch(error => {

            })
    }, [EstadoContratacionEncuadre]);

    const CambiaEstado = () => {
        setEstadoContratacionEncuadre(EstadoContratacionEncuadre + 1);
    };


    const handlemoneda = (event) => {
        const Cambiamoneda1 = (wp_value) => {
            setEstadomoneda(wp_value)

            var w_moneda = wp_value
            if(w_moneda==null) {
                w_moneda = ''
            }
            w_moneda=w_moneda.trim()
            if(w_moneda==='PES'){
                var wh_importe_presupuesto = Estadoimporte_presupuesto
                var wh_cotizacion = 1

                console.log('wh_importe_presupuesto')
                console.log(wh_importe_presupuesto)
                console.log('wh_cotizacion')
                console.log(wh_cotizacion)
                var w_importe_presupuesto_pesos = (wh_importe_presupuesto * wh_cotizacion)
                w_importe_presupuesto_pesos = Math.round(w_importe_presupuesto_pesos * 100) / 100

                setEstadoimporte_presupuesto_pesos(w_importe_presupuesto_pesos)
                setEstadomoneda_cotizacion(wh_cotizacion)
                setEstadovariantmoneda_cotizacion('filled')
                setEstadoreadOnlymoneda_cotizacion(true)
                // setEstadovariantmoneda_cotizacion_fecha('filled')
                // setEstadoreadOnlymoneda_cotizacion_fecha(true)
            }else{
                setEstadovariantmoneda_cotizacion('outlined')
                setEstadoreadOnlymoneda_cotizacion(false)
                // setEstadovariantmoneda_cotizacion_fecha('outlined')
                // setEstadoreadOnlymoneda_cotizacion_fecha(false)

                var w_estadofecha_encuadre =  Estadofecha_encuadre
                if(w_estadofecha_encuadre==null){
                    w_estadofecha_encuadre=''
                }
                w_estadofecha_encuadre=w_estadofecha_encuadre.trim()
                var dateMonedaCotizacionFecha = new Date(w_estadofecha_encuadre+"T00:00:00");
                console.log('dateMonedaCotizacionFecha')
                console.log(dateMonedaCotizacionFecha)
                var duedateMonedaCotizacionFecha = new Date(dateMonedaCotizacionFecha);
                duedateMonedaCotizacionFecha.setDate(dateMonedaCotizacionFecha.getDate()-1);
                var dMonedaCotizacionFecha = new Date(duedateMonedaCotizacionFecha || Date.now()),
                    monthMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getMonth() + 1),
                    dayMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getDate()),
                    yearMonedaCotizacionFecha = dMonedaCotizacionFecha.getFullYear();

                //console.log(month)

                if (monthMonedaCotizacionFecha.length < 2) monthMonedaCotizacionFecha = '0' + monthMonedaCotizacionFecha;
                if (dayMonedaCotizacionFecha.length < 2) dayMonedaCotizacionFecha = '0' + dayMonedaCotizacionFecha;

                var w_f_carga1MonedaCotizacionFecha = [dayMonedaCotizacionFecha, monthMonedaCotizacionFecha, yearMonedaCotizacionFecha].join('/');
                var w_f_carga2MonedaCotizacionFecha = [yearMonedaCotizacionFecha, monthMonedaCotizacionFecha, dayMonedaCotizacionFecha].join('-');
                console.log('w_f_carga2MonedaCotizacionFecha')
                console.log(w_f_carga2MonedaCotizacionFecha)
                setEstadomoneda_cotizacion_fecha(w_f_carga2MonedaCotizacionFecha)

                dataProviderTableroControl.getList(
                    'MonedaActivo',
                    {
                        filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'C',
                            fecha: w_f_carga1MonedaCotizacionFecha, codigo_afip: w_moneda },
                        sort: {field: 'id', order: 'DESC'},
                        pagination: {page: 1, perPage: 100},
                    },)
                    .then(({data}) => {
                        console.log(data)
                        console.log(data[0].cotiza)
                        setEstadomoneda_cotizacion(data[0].cotiza);
                    })
                    .catch(error => {

                    })
            }

        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextmoneda('');
        Cambiamoneda1(w_value)

    };

    const handleunidad_negocio = (event) => {
        const Cambiaunidad_negocio1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadounidad_negocio(wp_value)
            CambiaEstado()
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextunidad_negocio('');
        Cambiaunidad_negocio1(w_value)

    };

    const handleunidad_area = (event) => {
        const Cambiaunidad_area1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadounidad_area(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextunidad_area('');
        Cambiaunidad_area1(w_value)

    };

    const handlecontratacion_tipo = (event) => {
        const Cambiacontratacion_tipo1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadocontratacion_tipo(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextcontratacion_tipo('')
        Cambiacontratacion_tipo1(w_value)

    };

    const handlecontratacion_ano = (event) => {
        const Cambiacontratacion_ano1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadocontratacion_ano(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiacontratacion_ano1(w_value)

    };

    const handlemoneda_cotizacion = (event) => {
        const Cambiamoneda_cotizacion1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadomoneda_cotizacion(wp_value)

            var wh_importe_presupuesto = Estadoimporte_presupuesto
            var wh_cotizacion = wp_value

            console.log('wh_importe_presupuesto')
            console.log(wh_importe_presupuesto)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            var w_importe_presupuesto_pesos = (wh_importe_presupuesto * wh_cotizacion)
            w_importe_presupuesto_pesos = Math.round(w_importe_presupuesto_pesos * 100) / 100

            setEstadoimporte_presupuesto_pesos(w_importe_presupuesto_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiamoneda_cotizacion1(w_value)

    };

    const handleimporte_presupuesto = (event) => {
        const Cambiaimporte_presupuesto1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoimporte_presupuesto(wp_value)

            var wh_importe_presupuesto = wp_value
            var wh_cotizacion = Estadomoneda_cotizacion

            console.log('wh_importe_presupuesto')
            console.log(wh_importe_presupuesto)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            var w_importe_presupuesto_pesos = (wh_importe_presupuesto * wh_cotizacion)
            w_importe_presupuesto_pesos = Math.round(w_importe_presupuesto_pesos * 100) / 100

            setEstadoimporte_presupuesto_pesos(w_importe_presupuesto_pesos)

        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiaimporte_presupuesto1(w_value)

    };

    const handleimporte_presupuesto_pesos = (event) => {
        const Cambiaimporte_presupuesto_pesos1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoimporte_presupuesto_pesos(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiaimporte_presupuesto_pesos1(w_value)

    };

    const handleimporte_adjudicado_pesos = (event) => {
        const Cambiaimporte_adjudicado_pesos1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoimporte_adjudicado_pesos(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiaimporte_adjudicado_pesos1(w_value)

    };


    const handleexpediente_referencia_gde = (event) => {
        const Cambiaexpediente_referencia_gde1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoexpediente_referencia_gde(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        setHelperTextexpediente_referencia_gde('')
        Cambiaexpediente_referencia_gde1(w_value)

    };

    const handleconcepto_descripcion = (event) => {
        const Cambiaconcepto_descripcion1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoconcepto_descripcion(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiaconcepto_descripcion1(w_value)

    };

    const cambiafecha_encuadre = (event) => {
        const Cambiafecha_encuadre = (wp_value) => {
            console.log('wp_value----------------------Cambiafecha_encuadre')
            console.log(wp_value)
            setEstadofecha_encuadre(wp_value)
            console.log('Estadofecha_encuadre')
            console.log(Estadofecha_encuadre)

            var dateMonedaCotizacionFecha = new Date(wp_value+"T00:00:00");
            console.log('dateMonedaCotizacionFecha')
            console.log(dateMonedaCotizacionFecha)
            var duedateMonedaCotizacionFecha = new Date(dateMonedaCotizacionFecha);
            duedateMonedaCotizacionFecha.setDate(dateMonedaCotizacionFecha.getDate()-1);
            var dMonedaCotizacionFecha = new Date(duedateMonedaCotizacionFecha || Date.now()),
                monthMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getMonth() + 1),
                dayMonedaCotizacionFecha = '' + (dMonedaCotizacionFecha.getDate()),
                yearMonedaCotizacionFecha = dMonedaCotizacionFecha.getFullYear();

            //console.log(month)

            if (monthMonedaCotizacionFecha.length < 2) monthMonedaCotizacionFecha = '0' + monthMonedaCotizacionFecha;
            if (dayMonedaCotizacionFecha.length < 2) dayMonedaCotizacionFecha = '0' + dayMonedaCotizacionFecha;

            var w_f_carga1MonedaCotizacionFecha = [dayMonedaCotizacionFecha, monthMonedaCotizacionFecha, yearMonedaCotizacionFecha].join('/');
            var w_f_carga2MonedaCotizacionFecha = [yearMonedaCotizacionFecha, monthMonedaCotizacionFecha, dayMonedaCotizacionFecha].join('-');
            console.log('w_f_carga2MonedaCotizacionFecha')
            console.log(w_f_carga2MonedaCotizacionFecha)
            setEstadomoneda_cotizacion_fecha(w_f_carga2MonedaCotizacionFecha)

            var w_moneda = Estadomoneda
            if(w_moneda==null) {
                w_moneda = ''
            }
            w_moneda=w_moneda.trim()
            if(w_moneda==='PES'){}else {

                dataProviderTableroControl.getList(
                    'MonedaActivo',
                    {
                        filter: {
                            filtros: 'N', ingreso_por: 'Z', usar_todos: 'C',
                            fecha: w_f_carga1MonedaCotizacionFecha, codigo_afip: Estadomoneda
                        },
                        sort: {field: 'id', order: 'DESC'},
                        pagination: {page: 1, perPage: 100},
                    },)
                    .then(({data}) => {
                        console.log(data)
                        console.log(data[0].cotiza)
                        setEstadomoneda_cotizacion(data[0].cotiza);
                    })
                    .catch(error => {

                    })
            }
        };
        const Cambiafecha_encuadreError = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadofecha_encuadre(wp_value)
            console.log('Estadofecha_encuadre')
            console.log(Estadofecha_encuadre)
            setErrorfecha_encuadre(true);

        };
        setHelperTextfecha_encuadre('')
        setErrorfecha_encuadre(false);
        var w_value = event.target.value
        console.log('w_value----------------cambiafecha_encuadre')
        console.log(w_value)
        if(w_value){
            if(w_value===null){
                w_value=''
            }
            w_value=w_value.trim()
            var w_value_length = w_value.length
            console.log('w_value_length')
            console.log(w_value_length)
            if(w_value_length===10) {
                var wh_substring = w_value.substring(0, 4)
                console.log('wh_substring')
                console.log(wh_substring)
                var wh_ano = parseInt(wh_substring)
                console.log('wh_ano')
                console.log(wh_ano)
                if (wh_ano >= 2000) {
                    Cambiafecha_encuadre(w_value)
                }else{
                    setHelperTextfecha_encuadre('El año de la Fecha tiene que ser mayor o igual a 2000')
                    Cambiafecha_encuadreError(w_value)
                }
            }else{
                setHelperTextfecha_encuadre('Error en el Formato de Fecha')
                Cambiafecha_encuadreError(w_value)
            }
        }else{
            setHelperTextfecha_encuadre('Error en el Formato de Fecha')
            Cambiafecha_encuadreError(w_value)
        }

    }


    const cambiamoneda_cotizacion_fecha = (event) => {
        const Cambiamoneda_cotizacion_fecha = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadomoneda_cotizacion_fecha(wp_value)
            console.log('Estadomoneda_cotizacion_fecha')
            console.log(Estadomoneda_cotizacion_fecha)
        };
        const Cambiamoneda_cotizacion_fechaError = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadomoneda_cotizacion_fecha(wp_value)
            console.log('Estadomoneda_cotizacion_fecha')
            console.log(Estadomoneda_cotizacion_fecha)
            setErrormoneda_cotizacion_fecha(true);

        };
        setHelperTextmoneda_cotizacion_fecha('')
        setErrormoneda_cotizacion_fecha(false);
        var w_value = event.target.value
        console.log('w_value----------------cambiamoneda_cotizacion_fecha')
        console.log(w_value)
        if(w_value){
            if(w_value===null){
                w_value=''
            }
            w_value=w_value.trim()
            var w_value_length = w_value.length
            console.log('w_value_length')
            console.log(w_value_length)
            if(w_value_length===10) {
                var wh_substring = w_value.substring(0, 4)
                console.log('wh_substring')
                console.log(wh_substring)
                var wh_ano = parseInt(wh_substring)
                console.log('wh_ano')
                console.log(wh_ano)
                if (wh_ano >= 2000) {
                    Cambiamoneda_cotizacion_fecha(w_value)
                }else{
                    setHelperTextmoneda_cotizacion_fecha('El año de la Fecha tiene que ser mayor o igual a 2000')
                    Cambiamoneda_cotizacion_fechaError(w_value)
                }
            }else{
                setHelperTextmoneda_cotizacion_fecha('Error en el Formato de Fecha')
                Cambiamoneda_cotizacion_fechaError(w_value)
            }
        }else{
            setHelperTextmoneda_cotizacion_fecha('Error en el Formato de Fecha')
            Cambiamoneda_cotizacion_fechaError(w_value)
        }

    }

    const PostTitle = ({record}) => {
        return <span>Nueva Usuario</span>;
    };

    const [openConfirmacionContratacion, setopenConfirmacionContratacion] = React.useState(false);

    const handleClickOpenConfirmacionContratacion = () => {
        var w_validacion = ''
        var w_helpertextexpediente_referencia_gde = ''
        var w_helpertextcontratacion_tipo = ''
        var w_helpertextmoneda = ''
        var w_helpertextunidad_negocio = ''
        var w_helpertextunidad_area = ''

        var w_expediente_referencia_gde = Estadoexpediente_referencia_gde
        if(w_expediente_referencia_gde==null){
            w_expediente_referencia_gde=''
        }
        w_expediente_referencia_gde=w_expediente_referencia_gde.trim()
        if(w_expediente_referencia_gde===''){
            w_validacion = 'E'
            w_helpertextexpediente_referencia_gde = 'Debe Ingresar Expediente'
        }

        var w_contratacion_tipo = Estadocontratacion_tipo
        if(w_contratacion_tipo==null){
            w_contratacion_tipo=''
        }
        w_contratacion_tipo=w_contratacion_tipo.trim()
        if(w_contratacion_tipo===''){
            w_validacion = 'E'
            w_helpertextcontratacion_tipo = 'Debe Ingresar Contratacion Tipo'
        }

        var w_moneda = Estadomoneda
        if(w_moneda==null){
            w_moneda=''
        }
        w_moneda=w_moneda.trim()
        if(w_moneda===''){
            w_validacion = 'E'
            w_helpertextmoneda = 'Debe Ingresar Moneda'
        }

        var w_unidad_negocio = Estadounidad_negocio
        if(w_unidad_negocio==null){
            w_unidad_negocio=''
        }
        w_unidad_negocio=w_unidad_negocio.trim()
        if(w_unidad_negocio===''){
            w_validacion = 'E'
            w_helpertextunidad_negocio = 'Debe Ingresar Unidad Negocio'
        }

        var w_unidad_area = Estadounidad_area
        if(w_unidad_area==null){
            w_unidad_area=''
        }
        w_unidad_area=w_unidad_area.trim()
        if(w_unidad_area===''){
            w_validacion = 'E'
            w_helpertextunidad_area = 'Debe Ingresar Area'
        }

        if(w_validacion === 'E') {
            setHelperTextexpediente_referencia_gde(w_helpertextexpediente_referencia_gde)
            setHelperTextcontratacion_tipo(w_helpertextcontratacion_tipo)
            setHelperTextmoneda(w_helpertextmoneda);
            setHelperTextunidad_negocio(w_helpertextunidad_negocio);
            setHelperTextunidad_area(w_helpertextunidad_area);

        }else {
            setopenConfirmacionContratacion(true);
        }
    };

    const handleCloseConfirmacionContratacion = () => {
        setopenConfirmacionContratacion(false);
    };

    const BotonCrearDetelle = (record) => {

        var w_mensaje = ' '

        const notify = useNotify();
        const redirectTo = useRedirect();

        const [generardetelle, {loading}] = useMutation({
                type: 'create',
                resource: 'ContratacionEncuadre',
                payload: {
                    data: {
                        fecha_encuadre: Estadofecha_encuadre+"T00:00:00",
                        expediente_referencia_gde: Estadoexpediente_referencia_gde,
                        contratacion_tipo: Estadocontratacion_tipo,
                        contratacion_ano: Estadocontratacion_ano,
                        moneda: Estadomoneda,
                        moneda_cotizacion: Estadomoneda_cotizacion,
                        moneda_cotizacion_fecha: Estadomoneda_cotizacion_fecha+"T00:00:00",
                        importe_presupuesto: Estadoimporte_presupuesto,
                        importe_presupuesto_pesos: Estadoimporte_presupuesto_pesos,
                        importe_adjudicado_pesos: Estadoimporte_adjudicado_pesos,
                        concepto_descripcion: Estadoconcepto_descripcion,
                        unidad_negocio: Estadounidad_negocio,
                        unidad_area: Estadounidad_area
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    // console.log('data------BotonCrearDetelle')
                    // console.log(data)
                    notify(`Creada la Contratacion N°: ${data.id}`, "success");
                    redirectTo(`/ContratacionEncuadre?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=25&sort=id`);
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpenConfirmacionContratacion}
                        style={{color: '#ffffff', backgroundColor: '#004187'}}
                >
                    <div title={'Confirmacion de Contratacion'}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        <SaveIcon style={{color: '#ffffff', backgroundColor: '#004187'}}/>
                        Guardar
                    </div>
                </Button>
                <Dialog open={openConfirmacionContratacion} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{'Confirmacion de Contratacion'}</DialogTitle>
                    <DialogContent>
                        ¿Quiere Confirmar Contratacion?
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={generardetelle}
                                style={{color: '#ffffff', backgroundColor: '#004187'}}
                        >
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                 }}>
                                <SaveIcon style={{color: '#ffffff', backgroundColor: '#004187'}}/>
                                Guardar
                            </div>
                        </Button>
                        <Button variant="outlined" color="primary" onClick={handleCloseConfirmacionContratacion}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                <CancelPresentationIcon style={{color: '#004187'}}/>
                                Cancelar
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        );

    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                toolbar={false}
                toolbar={<PostCreateToolbar />}
                // validate={validateCreate}
                redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS DEL USUARIO">
                    <div formClassName={classes.inlineBlock}>
                        <td style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center',
                            color: '#0064c8'}}><h4>Usuario del Sistema</h4></td>
                        <Divider />
                    </div>
                    <div></div>
                    <TextInput label="Usuario" defaultValue={''} source="username" formClassName={classes.username}/>
                    <PasswordInput label="Contraseña" defaultValue={''} source="password"  formClassName={classes.password}/>
                    <NumberInput  defaultValue={0} label="Version"  source="version"  formClassName={classes.version} />
                    <br/>
                    {/*<TextInput*/}
                    {/*    name="password"*/}
                    {/*    // @ts-ignore*/}
                    {/*    source="password"*/}
                    {/*    label={"Password"}*/}
                    {/*    type="password"*/}
                    {/*    formClassName={classes.inlineBlock}*/}
                    {/*/>*/}
                    {/*<TextInput  defaultValue={''} label="E-Mail"  source="email" validate={validateEmail} formClassName={classes.inlineBlock}/>*/}
                    <div></div>
                    <BooleanInput defaultValue={false}
                                  source="account_expired"
                                  label="Cuenta Vencida"
                                  formClassName={classes.account_expired}
                                  options={{
                                      checkedIcon: <AccountCircleIcon  style={{ color: '#ff0000' }}/>,
                                  }}
                    />
                    <BooleanInput  defaultValue={false}
                                    label="Cuenta Bloqueada"  source="account_locked"
                                  formClassName={classes.account_locked}
                                  options={{
                                      checkedIcon: <AccountCircleIcon  style={{ color: '#ff0000' }}/>,
                                  }}
                    />
                    <BooleanInput   defaultValue={true}
                                    label="Usuario Habilitado"  source="enabled"
                                    formClassName={classes.enabled}
                                    options={{
                                        checkedIcon: <AccountCircleIcon style={{ color: '#32c832' }}/>,
                                    }}
                    />
                    <BooleanInput defaultValue={false}
                                  label="Password Vencida"  source="password_expired"
                                  formClassName={classes.password_expired}
                                  options={{
                                      checkedIcon: <AccountCircleIcon style={{ color: '#ff0000' }}/>,
                                  }}
                    />


                    <SelectInput  defaultValue={'N'}
                                  label={
                        <div title={'Es Administrador'}
                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <SupervisorAccountIcon/>
                            {'Es Administrador'}
                        </div>
                    }
                               source="es_administrador" formClassName={classes.es_administrador}
                                 choices={[
                                     {id: 'S', name: 'SI'},
                                     {id: 'N', name: 'NO'},
                                 ]}
                    />

                    <br/>

                    <div></div>

                    <TextInput defaultValue={''}
                               source="whatsapp_nro"
                               label={<div    title={'Numero de Whatsapp'}
                                              style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                   <DialpadIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                   <WhatsAppIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                   {' Numero de Whatsapp'}
                               </div>}  variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.whatsapp_nro} fullWidth={true}/>



                    <br/>

                    <TextInput defaultValue={''}
                               source="email"
                               label={<div title={'E-Mail (Correo)'}
                                           style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                           <ContactMailIcon  />
                                           {' E-Mail (Correo)'}
                                       </div>
                                        }
                               variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.email}
                               validate={validateEmail}
                               fullWidth={true}/>

                    <br/>

                    <SelectInput defaultValue={'N'}
                                 label={
                                     <div title={'Enviar Whatsapp'}
                                          style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                         <AddToHomeScreenIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                         <WhatsAppIcon style={{color: '#ffffff', backgroundColor: '#00bb2d'}} />
                                         {' Enviar Whatsapp'}
                                     </div>
                                 }
                                 source="enviar_whatsapp" formClassName={classes.enviar_whatsapp}
                                 choices={[
                                     {id: 'S', name: 'SI'},
                                     {id: 'N', name: 'NO'},
                                 ]}
                    />


                    <SelectInput defaultValue={'N'}
                                 label={
                                     <div title={'Enviar Correo'}
                                          style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                         <AddToHomeScreenIcon/>
                                         <EmailIcon/>
                                         {' Enviar Correo'}
                                     </div>
                                 }
                                 source="enviar_correo" formClassName={classes.enviar_correo}
                                 choices={[
                                     {id: 'S', name: 'SI'},
                                     {id: 'N', name: 'NO'},
                                 ]}
                    />





                    <SelectInput defaultValue={'N'}
                        label={
                        <div title={'Enviar Notificacion'}
                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                            <AddToHomeScreenIcon style={{color: '#ff0000'}}/>
                            <NotificationsIcon style={{color: '#ff0000'}}/>
                            {' Enviar Notificacion'}
                        </div>
                    }
                               source="enviar_notificacion" formClassName={classes.enviar_notificacion}
                                 choices={[
                                     {id: 'S', name: 'SI'},
                                     {id: 'N', name: 'NO'},
                                 ]}
                    />
                    <br/>

                    <div></div>

                </FormTab>
            </TabbedForm>
        </Create>
    )
};


const validateEmail = email();

export default UserUsuComCreate;


