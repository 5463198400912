import React from 'react';
import {useQuery, Loading, Error, MenuItemLink, Link} from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from "@material-ui/icons/Edit";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PasswordButtonEdit from "../UserUsuCom/PasswordButtonEdit";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 500,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

const UserName = function (props) {
    const classes = useStyles();
    const {data, loading, error} = useQuery(
        {
            type: 'getList',
            resource: 'UserNameTitulo',
            payload: {
                filter: { listado: 'S', tablerocontrol: 'S'},
                sort: { field: 'id', order: 'DESC'  },
                pagination: { page: 1, perPage: 1000 },
            },
        });
//----------------- chequea datos estado del componente -----------------------------
    if (loading) return <Loading loadingPrimary="  " loadingSecondary="Actualizando" />      // no cargo todavia

    if (!data) return null   ;           // no cargo todavia

    if (error) {
        // console.log('error')
        // eslint-disable-next-line no-undef
        // console.log(error)
        return <Error/>;                           // error
    };

    var IniciousernameList1 = data
    const aggregationsusername = IniciousernameList1
        .reduce(
            (stats, order) => {
                stats.username = order.username
                // console.log(order)
                return stats;
            },
            {
                username: '',
            }
        );

    const  nbIniciousernameList = IniciousernameList1.reduce(nb => ++nb, 0)

    // console.log('nbIniciousernameList')
    // console.log(nbIniciousernameList)
    //
    // console.log('data')
    // console.log(data)

    const  username = aggregationsusername.username


    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <MenuItemLink style={{color: '#123B80'}}
                                              to={``}
                                              primaryText={'USUARIO: '+username}
                                              leftIcon={<PersonIcon style={{color: '#123B80'}} />}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <PasswordButtonEdit record={data[0]}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );

    // return (
    //     <MenuItemLink style={{color: '#123B80'}}
    //                   to={``}
    //                   primaryText={'USUARIO: '+username}
    //                   leftIcon={<PersonIcon style={{color: '#123B80'}} />}
    //     />
    // );
}

export default UserName;