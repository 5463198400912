import React, {useState} from 'react';
import {
    useQuery,
    Datagrid,
    TextField,
    Pagination,
    Loading, ListContextProvider
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import IconBuscar from '@material-ui/icons/Search';
import IconSeleccionar from '@material-ui/icons/Done';
import {makeStyles} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {TextField as TextFieldMUI} from '@material-ui/core';
import IconCancelar from '@material-ui/icons/Close';

export const ContratacionTipoBusquedaDatagrid = (props) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(12);
    const [sort, setSort] = useState({field: 'contratacion_tipo', order: 'ASC'});
    const [filter, setFilter] = useState('');
    const [busqueda, setBusqueda] = useState('');

    const useStyles = makeStyles({
        button: {},
    });
    const classes = useStyles();

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'ContratacionTipo',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: {q: filter},
        }
    });

    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    /*Botón seleccionar registro*/
    const BtnConfirmar = ({record}) => (
        <IconButton style={{fontSize: 15, padding: 2}}
                    onClick={() => {
                        props.callbackContratacionTipo(record);
                        props.callbackCloseDrawer(false)
                    }}
        >

            <IconSeleccionar style={{fontSize: 20}}/>
        </IconButton>
    );

    /*Botón de búsqueda*/
    const BtnBuscar = ({event}) => (
        <Button
            onClick={() => {
                setFilter(busqueda);
            }}
        >
            <IconBuscar style={{fontSize: 20}}/> <h4>{' buscar'}</h4>
        </Button>
    );

    const BtnVolver = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" onClick={() => {
            props.callbackCloseDrawer(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );


    return (
        <ListContextProvider value={{data, total, page, perPage, setPage, filter, setFilter, sort}}>
            <div style={{margin: '3em'}}>
                <TextFieldMUI label="Buscar"
                              onChange={(v) => setBusqueda(v.target.value)}
                              defaultValue={filter}
                />


                <BtnBuscar/>

                <Datagrid
                    data={keyBy(data, 'id')}
                    ids={data.map(({id}) => id)}
                    currentSort={sort}
                    setSort={(field, order) => setSort({field, order})}
                >
                    <BtnConfirmar/>
                    <TextField label="Contratacion" source="contratacion_tipo"/>
                    <TextField label="Descripción" source="contratacion_d"/>
                </Datagrid>
                <Pagination
                    rowsPerPageOptions={[]}
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    total={total}
                />
                <BtnVolver/>
            </div>
        </ListContextProvider>
    )
};
export default ContratacionTipoBusquedaDatagrid;