import React from 'react';
import {Admin, fetchUtils, Resource} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';


import './App.css';
import authProvider from './authProvider';
import themeReducer from './themeReducer';
import {Layout, Login, Menu} from './layout';
import simpleRestProvider from 'ra-data-simple-rest';
import spanishMessages from 'aor-language-spanish';
import ApiUrlServer from './layout/ApiUrlServer'
import TableroControl from "./TableroControl/TableroControl"
import ProveedorList from "./proveedor/ProveedorList";
import ProveedorCreate from "./proveedor/ProveedorCreate";
import ProveedorShow from "./proveedor/ProveedorShow";
import ProveedorEdit from "./proveedor/ProveedorEdit";

import ProveedorClaseList from "./proveedorClase/ProveedorClaseList";
import ProveedorClaseShow from "./proveedorClase/ProveedorClaseShow";

import ProveedorRepresentanteList from "./proveedorRepresentante/ProveedorRepresentanteList";
import ProveedorRepresentanteShow from "./proveedorRepresentante/ProveedorRepresentanteShow";

import ProveedorSocioList from "./proveedorSocio/ProveedorSocioList";
import ProveedorSocioShow from "./proveedorSocio/ProveedorSocioShow";
import ProveedorSocioCreate from "./proveedorSocio/ProveedorSocioCreate";

import ProveedorApoderadoList from "./proveedorApoderado/ProveedorApoderadoList";
import ProveedorApoderadoShow from "./proveedorApoderado/ProveedorApoderadoShow";
import ProveedorApoderadoEdit from "./proveedorApoderado/ProveedorApoderadoEdit";

import ProveedorDocumentacionList from "./proveedorDocumentacion/ProveedorDocumentacionList";
import ProveedorDocumentacionAdjuntoList from "./ProveedorDocumentacionAdjunto/ProveedorDocumentacionAdjuntoList";
import ProveedorDocumentacionModEdit from "./proveedorDocumentacionMod/proveedorDocumentacionModEdit"

// import ProveedorDocumentacionArchivoAdjuntoList from "./ProveedorDocumentacionAdjunto/ProveedorDocumentacionSubeArchivo"



import EstadoProveedorList from "./EstadoProveedor/EstadoProveedorList";
import ProveedorClaseBuscadorList from "./ProveedorClaseBuscador/ProveedorClaseBuscadorList"
import ProveedorClaseBuscador from "./ProveedorClaseBuscador/ProveedorClaseBuscador"
import BuscarClasesList from "./ProveedorClaseBuscador_ant/ProveedorClaseBuscadorList"

import {RTATheme} from './layout/themes';

import ContratacionEncuadreGerenteList
    from "./ContratacionEncuadreGerente/ContratacionEncuadreGerenteList";

import IvaList from "./iva/IvaList";
import ProveedorPersonaExtranjeroCreate from "./proveedorPersonaExtranjero/ProveedorPersonaExtranjeroCreate";
import ProveedorPersonaExtranjeroShow from "./proveedorPersonaExtranjero/ProveedorPersonaExtranjeroShow";
import ProveedorPersonaExtranjeroEdit from "./proveedorPersonaExtranjero/ProveedorPersonaExtranjeroEdit";
import ProveedorPersonaExtranjeroList from "./proveedorPersonaExtranjero/ProveedorPersonaExtranjeroList";

import PaisList from "./pais/PaisList";
import PaisCreate from "./pais/PaisCreate"
import PaisEdit from "./pais/PaisEdit";
import PaisShow from "./pais/PaisShow";
import PersonaTipoList from "./personaTipo/PersonaTipoList";
import PersonaFormaList from "./PersonaForma/PersonaFormaList";
import PersonaFormaCreate from "./PersonaForma/PersonaFormaCreate";
import PersonaFormaEdit from "./PersonaForma/PersonaFormaEdit";
import PersonaFormaShow from "./PersonaForma/PersonaFormaShow";
import PersonaTipoCreate from "./personaTipo/PersonaTipoCreate";

import OrdenCompraCabeceraList from "./OrdenCompraCabecera/OrdenCompraCabeceraList";
import OrdenCompraCabeceraCreate from "./OrdenCompraCabecera/OrdenCompraCabeceraCreate";
import OrdenCompraCabeceraShow from "./OrdenCompraCabecera/OrdenCompraCabeceraShow";
import OrdenCompraCabeceraEdit from "./OrdenCompraCabecera/OrdenCompraCabeceraEdit";

import EstadoOrdenCompraList from "./EstadoOrdenCompra/EstadoOrdenCompraList"
import EstadoOrdenCompraCreate from "./EstadoOrdenCompra/EstadoOrdenCompraCreate"
import EstadoOrdenCompraEdit from "./EstadoOrdenCompra/EstadoOrdenCompraEdit";
import EstadoOrdenCompraShow from "./EstadoOrdenCompra/EstadoOrdenCompraShow";

import UnidadNegocioList from "./UnidadNegocio/UnidadNegocioList"
import UnidadNegocioCreate from "./UnidadNegocio/UnidadNegocioCreate"
import UnidadNegocioEdit from "./UnidadNegocio/UnidadNegocioEdit";
import UnidadNegocioShow from "./UnidadNegocio/UnidadNegocioShow";

import UnidadEmisoraList from "./UnidadEmisora/UnidadEmisoraList"
import UnidadEmisoraCreate from "./UnidadEmisora/UnidadEmisoraCreate"
import UnidadEmisoraEdit from "./UnidadEmisora/UnidadEmisoraEdit";
import UnidadEmisoraShow from "./UnidadEmisora/UnidadEmisoraShow";

import UnidadAreaList from "./UnidadArea/UnidadAreaList"
import UnidadAreaCreate from "./UnidadArea/UnidadAreaCreate"
import UnidadAreaEdit from "./UnidadArea/UnidadAreaEdit";
import UnidadAreaShow from "./UnidadArea/UnidadAreaShow";

import UnidadGerenciaList from "./UnidadGerencia/UnidadGerenciaList"
import UnidadGerenciaCreate from "./UnidadGerencia/UnidadGerenciaCreate"
import UnidadGerenciaEdit from "./UnidadGerencia/UnidadGerenciaEdit";
import UnidadGerenciaShow from "./UnidadGerencia/UnidadGerenciaShow";

import UnidadSectorList from "./UnidadSector/UnidadSectorList"
import UnidadSectorCreate from "./UnidadSector/UnidadSectorCreate"
import UnidadSectorEdit from "./UnidadSector/UnidadSectorEdit";
import UnidadSectorShow from "./UnidadSector/UnidadSectorShow";

import UnidadProgramaList from "./UnidadPrograma/UnidadProgramaList"
import UnidadProgramaCreate from "./UnidadPrograma/UnidadProgramaCreate"
import UnidadProgramaEdit from "./UnidadPrograma/UnidadProgramaEdit";
import UnidadProgramaShow from "./UnidadPrograma/UnidadProgramaShow";

import ProvinciaList from "./Provincia/ProvinciaList"
import ProvinciaCreate from "./Provincia/ProvinciaCreate"
import ProvinciaEdit from "./Provincia/ProvinciaEdit";
import ProvinciaShow from "./Provincia/ProvinciaShow";

import CiudadList from "./Ciudad/CiudadList"
import CiudadCreate from "./Ciudad/CiudadCreate"
import CiudadEdit from "./Ciudad/CiudadEdit";
import CiudadShow from "./Ciudad/CiudadShow";

import DocumentoTipoList from "./DocumentoTipo/DocumentoTipoList"
import DocumentoTipoCreate from "./DocumentoTipo/DocumentoTipoCreate"
import DocumentoTipoEdit from "./DocumentoTipo/DocumentoTipoEdit";
import DocumentoTipoShow from "./DocumentoTipo/DocumentoTipoShow";

import EstadoCivilList from "./EstadoCivil/EstadoCivilList"
import EstadoCivilCreate from "./EstadoCivil/EstadoCivilCreate"
import EstadoCivilEdit from "./EstadoCivil/EstadoCivilEdit";
import EstadoCivilShow from "./EstadoCivil/EstadoCivilShow";

import SexoTipoList from "./SexoTipo/SexoTipoList"
import SexoTipoCreate from "./SexoTipo/SexoTipoCreate"
import SexoTipoEdit from "./SexoTipo/SexoTipoEdit";
import SexoTipoShow from "./SexoTipo/SexoTipoShow";


import RubroList from "./Rubro/RubroList"
import RubroCreate from "./Rubro/RubroCreate"
import RubroEdit from "./Rubro/RubroEdit";
import RubroShow from "./Rubro/RubroShow";

import RubroClaseList from "./RubroClase/RubroClaseList"
import RubroClaseCreate from "./RubroClase/RubroClaseCreate"
import RubroClaseEdit from "./RubroClase/RubroClaseEdit";
import RubroClaseShow from "./RubroClase/RubroClaseShow";


import UniMedList from "./UniMed/UniMedList"
import UniMedCreate from "./UniMed/UniMedCreate"
import UniMedEdit from "./UniMed/UniMedEdit";
import UniMedShow from "./UniMed/UniMedShow";


import AdjudicacionCriterioList from "./AdjudicacionCriterio/AdjudicacionCriterioList"
import AdjudicacionCriterioCreate from "./AdjudicacionCriterio/AdjudicacionCriterioCreate"
import AdjudicacionCriterioEdit from "./AdjudicacionCriterio/AdjudicacionCriterioEdit";
import AdjudicacionCriterioShow from "./AdjudicacionCriterio/AdjudicacionCriterioShow";

import ContratacionTipoList from "./ContratacionTipo/ContratacionTipoList"
import ContratacionTipoCreate from "./ContratacionTipo/ContratacionTipoCreate"
import ContratacionTipoEdit from "./ContratacionTipo/ContratacionTipoEdit";
import ContratacionTipoShow from "./ContratacionTipo/ContratacionTipoShow";

import ContratacionSecuenciaTipoList from "./ContratacionSecuenciaTipo/ContratacionSecuenciaTipoList"
import ContratacionSecuenciaTipoCreate from "./ContratacionSecuenciaTipo/ContratacionSecuenciaTipoCreate"
import ContratacionSecuenciaTipoEdit from "./ContratacionSecuenciaTipo/ContratacionSecuenciaTipoEdit";
import ContratacionSecuenciaTipoShow from "./ContratacionSecuenciaTipo/ContratacionSecuenciaTipoShow";

import ContratacionEstadoList from "./ContratacionEstado/ContratacionEstadoList"
import ContratacionEstadoCreate from "./ContratacionEstado/ContratacionEstadoCreate"
import ContratacionEstadoEdit from "./ContratacionEstado/ContratacionEstadoEdit";
import ContratacionEstadoShow from "./ContratacionEstado/ContratacionEstadoShow";

import MonedaList from "./Moneda/MonedaList"
import MonedaCreate from "./Moneda/MonedaCreate"
import MonedaEdit from "./Moneda/MonedaEdit";
import MonedaShow from "./Moneda/MonedaShow";

import DocumentacionTipoList from "./DocumentacionTipo/DocumentacionTipoList"
import DocumentacionTipoCreate from "./DocumentacionTipo/DocumentacionTipoCreate"
import DocumentacionTipoEdit from "./DocumentacionTipo/DocumentacionTipoEdit";
import DocumentacionTipoShow from "./DocumentacionTipo/DocumentacionTipoShow";


import ContratacionEncuadreList from "./ContratacionEncuadre/ContratacionEncuadreList"
import ContratacionEncuadreCreate from "./ContratacionEncuadre/ContratacionEncuadreCreate"
import ContratacionEncuadreEdit from "./ContratacionEncuadre/ContratacionEncuadreEdit";
import ContratacionEncuadreShow from "./ContratacionEncuadre/ContratacionEncuadreShow";

import NotificacionList from "./Notificacion/NotificacionList"
import NotificacionTipoList from "./NotificacionTipo/NotificacionTipoList";
import UsuComNotificacionTipoList from "./UsuComNotificacionTipo/UsuComNotificacionTipoList"

import ContratacionEncuadreVisitaList from "./ContratacionEncuadreVisita/ContratacionEncuadreVisitaList"

import TableroControlContratacionList from "./TableroControlContratacion/TableroControlContratacionList"
import UserUsuCom from "./UserUsuCom/Index"
import UserCopy from "./UserCopy/Index"
import UsuComNotificacionTipoCreate from "./UsuComNotificacionTipo/UsuComNotificacionTipoCreate";
import ParametroSistemaList from "./ParametroSistema/ParametroSistemaList"

import UsuComAreaList from "./UsuComArea/UsuComAreaList"
import UsuComAreaCreate from "./UsuComArea/UsuComAreaCreate";

import AutoridadesList from "./Autoridades/AutoridadesList"
import AutoridadesCreate from "./Autoridades/AutoridadesCreate"
import AutoridadesEdit from "./Autoridades/AutoridadesEdit"
import AutoridadesShow from "./Autoridades/AutoridadesShow"
import BuscadorContextProvider from "./Context/BuscadorProveedorClaseContextProvider";

import CompraCptList from "./CompraCpt/CompraCptList"
import CompraCptCreate from "./CompraCpt/CompraCptCreate"
import CompraCptEdit from "./CompraCpt/CompraCptEdit"
import CompraCptShow from "./CompraCpt/CompraCptShow"

import RequerimientoConceptoList from "./RequerimientoConcepto/RequerimientoConceptoList"
import RequerimientoConceptoCreate from "./RequerimientoConcepto/RequerimientoConceptoCreate"
import RequerimientoConceptoEdit from "./RequerimientoConcepto/RequerimientoConceptoEdit"
import RequerimientoConceptoShow from "./RequerimientoConcepto/RequerimientoConceptoShow"

import IbruTipoList from "./IbruTipo/IbruTipoList"
import IbruTipoCreate from "./IbruTipo/IbruTipoCreate"
import IbruTipoEdit from "./IbruTipo/IbruTipoEdit"
import IbruTipoShow from "./IbruTipo/IbruTipoShow"

import ProveedorTransferenciaList from "./ProveedorTransferencia/ProveedorTransferenciaList"
 
import ParametroPathList from "./ParametroPath/ParametroPathList"
import ParametroPathCreate from "./ParametroPath/ParametroPathCreate"
import ParametroPathEdit from "./ParametroPath/ParametroPathEdit"
import ParametroPathShow from "./ParametroPath/ParametroPathShow"
import ProveedorDocumentacionAdjuntoVer from "./ProveedorDocumentacionAdjunto/ProveedorDocumentacionAdjuntoVer";
import ProveedorDocumentacionAdjuntoEdit from "./ProveedorDocumentacionAdjunto/ProveedorDocumentacionAdjuntoEdit"
import TableroControlOrdenCompraList from "./TableroControlOrdenCompra/TableroControlOrdenCompraList"

const messages = {
    sp: spanishMessages
};

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'en') {
        return import('./i18n/en').then(messages => messages.default);
    }

    // Always fallback on english
    return spanishMessages;
}, 'sp', {
    allowMissing: true,
    onMissingKey: (key, _, __) => key
});

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const token = localStorage.getItem('token');
    //options.headers.set('x-auth-token', `${token}`);
    options.headers.set('auth', `${token}`);
    options.headers.set('Access-Control-Expose-Headers', 'Content-Range');
    options.headers.set('Content-Range', 'moneda 0-2/2')
    return fetchUtils.fetchJson(url, options);
};

//----------------------------- extension del dataprovider para blobs ---------------------------
const customDataProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => {
    const dataProvider = simpleRestProvider(apiUrl, httpClient);

    const getBlob = async (resource, params) => {
        const url = `${apiUrl}/${resource}?${JSON.stringify(params)}`;
        const options = {
            headers: new Headers({
                Accept: 'application/pdf',
            }),
            responseType: 'blob',
        };
        return httpClient(url, options).then(({ body }) => {
            return Promise.resolve(body);
        });
    };

    return {
        ...dataProvider,
        getBlob,
    };
};





const dataProvider = customDataProvider(ApiUrlServer, httpClient) //simpleRestProvider(ApiUrlServer, httpClient);

const App = () => (
    <BuscadorContextProvider>
        <Admin
            title="RTA - Proveedores"
            // theme={myTheme}
            theme={RTATheme}
            dataProvider={dataProvider}
            customReducers={{theme: themeReducer}}
            loginPage={Login}
            layout={Layout}
            menu={Menu}
            locale="es"
            i18nProvider={i18nProvider}
            authProvider={authProvider}
            dashboard={TableroControl}
        >
            <Resource name="TableroControl"
                      list={TableroControl}
            />
            <Resource name="proveedor"
                      list={ProveedorList}
                      create={ProveedorCreate}
                      show={ProveedorShow}
                      edit={ProveedorEdit}
            />

            <Resource name="personaTipo"
                      list={PersonaTipoList}
                      create={PersonaTipoCreate}
            />

            <Resource name="PersonaForma"
                      list={PersonaFormaList}
                      create={PersonaFormaCreate}
                      edit={PersonaFormaEdit}
                      show={PersonaFormaShow}
            />

            <Resource name="iva"
                      list={IvaList}
                // create={ivaCreate}
            />

            <Resource name="estadoProveedor"
                      list={EstadoProveedorList}
                // create={ivaCreate}
            />

            <Resource name="proveedorPersonaExtranjero"
                      list={ProveedorPersonaExtranjeroList}
                      create={ProveedorPersonaExtranjeroCreate}
                      edit={ProveedorPersonaExtranjeroEdit}
                      show={ProveedorPersonaExtranjeroShow}
            />

            <Resource name="proveedorSocio"
                      list={ProveedorSocioList}
                      show={ProveedorSocioShow}
                      create={ProveedorSocioCreate}
            />

            <Resource name="proveedorClase"
                      list={ProveedorClaseList}
                      show={ProveedorClaseShow}
            />

            <Resource name="proveedorClaseBuscador"
                      list={ProveedorClaseBuscadorList}
            />


            <Resource name="ProveedorClaseBuscar"
                      list={ProveedorClaseBuscador}
            />

            <Resource name="proveedorRepresentante"
                      list={ProveedorRepresentanteList}
                      show={ProveedorRepresentanteShow}
            />

            <Resource name="proveedorApoderado"
                      list={ProveedorApoderadoList}
                      show={ProveedorApoderadoShow}
                      edit={ProveedorApoderadoEdit}
            />

            <Resource name="proveedorDocumentacion"
                      list={ProveedorDocumentacionList}
            />

            <Resource name="ProveedorDocumentacionAdjunto"
                      list={ProveedorDocumentacionAdjuntoList}
                      edit={ProveedorDocumentacionAdjuntoEdit}
            />

            <Resource name="ProveedorDocumentacionMod"
                      edit={ProveedorDocumentacionModEdit}
            />

            <Resource name="ProveedorDocumentacionArchivoAdjunto"
                      // list={ProveedorDocumentacionArchivoAdjuntoList}
            />

            <Resource name="ProveedorDocumentacionAdjuntoEliminar"
                // list={ProveedorDocumentacionArchivoAdjuntoList}
            />

            <Resource name="pais"
                      list={PaisList}
                      create={PaisCreate}
                      show={PaisShow}
                      edit={PaisEdit}
            />

            <Resource name="OrdenCompraCabecera"
                      list={OrdenCompraCabeceraList}
                      create={OrdenCompraCabeceraCreate}
                      show={OrdenCompraCabeceraShow}
                      edit={OrdenCompraCabeceraEdit}
            />

            <Resource name="EstadoOrdenCompra"
                      list={EstadoOrdenCompraList}
                      create={EstadoOrdenCompraCreate}
                      edit={EstadoOrdenCompraEdit}
                      show={EstadoOrdenCompraShow}
            />

            <Resource name="UnidadNegocio"
                      list={UnidadNegocioList}
                      create={UnidadNegocioCreate}
                      edit={UnidadNegocioEdit}
                      show={UnidadNegocioShow}
            />

            <Resource name="OrdenCompraReporteDownload"
            />

            <Resource name="UnidadNegocioTodas"
            />

            <Resource name="UnidadEmisora"
                      list={UnidadEmisoraList}
                      create={UnidadEmisoraCreate}
                      edit={UnidadEmisoraEdit}
                      show={UnidadEmisoraShow}
            />

            <Resource name="UnidadArea"
                      list={UnidadAreaList}
                      create={UnidadAreaCreate}
                      edit={UnidadAreaEdit}
                      show={UnidadAreaShow}
            />

            <Resource name="UnidadGerencia"
                      list={UnidadGerenciaList}
                      create={UnidadGerenciaCreate}
                      edit={UnidadGerenciaEdit}
                      show={UnidadGerenciaShow}
            />

            <Resource name="UnidadSector"
                      list={UnidadSectorList}
                      create={UnidadSectorCreate}
                      edit={UnidadSectorEdit}
                      show={UnidadSectorShow}
            />

            <Resource name="UnidadPrograma"
                      list={UnidadProgramaList}
                      create={UnidadProgramaCreate}
                      edit={UnidadProgramaEdit}
                      show={UnidadProgramaShow}
            />

            <Resource name="Provincia"
                      list={ProvinciaList}
                      create={ProvinciaCreate}
                      edit={ProvinciaEdit}
                      show={ProvinciaShow}
            />


            <Resource name="Ciudad"
                      list={CiudadList}
                      create={CiudadCreate}
                      edit={CiudadEdit}
                      show={CiudadShow}
            />

            <Resource name="DocumentoTipo"
                      list={DocumentoTipoList}
                      create={DocumentoTipoCreate}
                      edit={DocumentoTipoEdit}
                      show={DocumentoTipoShow}
            />

            <Resource name="EstadoCivil"
                      list={EstadoCivilList}
                      create={EstadoCivilCreate}
                      edit={EstadoCivilEdit}
                      show={EstadoCivilShow}
            />

            <Resource name="SexoTipo"
                      list={SexoTipoList}
                      create={SexoTipoCreate}
                      edit={SexoTipoEdit}
                      show={SexoTipoShow}
            />

            <Resource name="Rubro"
                      list={RubroList}
                      create={RubroCreate}
                      edit={RubroEdit}
                      show={RubroShow}
            />

            <Resource name="RubroClase"
                      list={RubroClaseList}
                      create={RubroClaseCreate}
                      edit={RubroClaseEdit}
                      show={RubroClaseShow}
            />

            <Resource name="UniMed"
                      list={UniMedList}
                      create={UniMedCreate}
                      edit={UniMedEdit}
                      show={UniMedShow}
            />


            <Resource name="AdjudicacionCriterio"
                      list={AdjudicacionCriterioList}
                      create={AdjudicacionCriterioCreate}
                      edit={AdjudicacionCriterioEdit}
                      show={AdjudicacionCriterioShow}
            />

            <Resource name="ContratacionTipo"
                      list={ContratacionTipoList}
                      create={ContratacionTipoCreate}
                      edit={ContratacionTipoEdit}
                      show={ContratacionTipoShow}
            />

            <Resource name="ContratacionSecuenciaTipo"
                      list={ContratacionSecuenciaTipoList}
                      create={ContratacionSecuenciaTipoCreate}
                      edit={ContratacionSecuenciaTipoEdit}
                      show={ContratacionSecuenciaTipoShow}
            />

            <Resource name="ContratacionEstado"
                      list={ContratacionEstadoList}
                      create={ContratacionEstadoCreate}
                      edit={ContratacionEstadoEdit}
                      show={ContratacionEstadoShow}
            />

            <Resource name="Moneda"
                      list={MonedaList}
                      create={MonedaCreate}
                      edit={MonedaEdit}
                      show={MonedaShow}
            />

            <Resource name="MonedaActivo"
            />

            <Resource name="ProveedorValidar"
            />

            <Resource name="ObjetoAuditoria"
            />

            <Resource name="OrdenCompraDetalle"
            />

            <Resource name="Clase"
            />

            <Resource name="OrdenCompraCabeceraSeleccionar"
            />

            <Resource name="Ano"
            />

            <Resource name="DocumentacionTipo"
                      list={DocumentacionTipoList}
                      create={DocumentacionTipoCreate}
                      edit={DocumentacionTipoEdit}
                      show={DocumentacionTipoShow}
            />

            <Resource name="ContratacionSecuencia"
            />

            <Resource name="ContratacionEncuadre"
                      list={ContratacionEncuadreList}
                      create={ContratacionEncuadreCreate}
                      edit={ContratacionEncuadreEdit}
                      show={ContratacionEncuadreShow}
            />

            <Resource name="ContratacionEncuadreVisita"
                      list={ContratacionEncuadreVisitaList}
            />

            <Resource name="ContratacionEncuadreGerente"
                      list={ContratacionEncuadreGerenteList}
            />


            <Resource name="TableroControlContratacion"
                      list={TableroControlContratacionList}
            />

            <Resource name="TableroControlOrdenCompra"
                      list={TableroControlOrdenCompraList}
            />

            <Resource name="UserUsuCom" {...UserUsuCom}
            />

            <Resource name="UserCopy" {...UserCopy}
            />


            <Resource name="ContratacionEncuadreSecuencia"
            />

            <Resource name="ContratacionEncuadreAdjudicacion"
            />

            <Resource name="Notificacion"
                      list={NotificacionList}
            />

            <Resource name="NotificacionTipo"
                      list={NotificacionTipoList}
            />


            <Resource name="UsuComNotificacionTipo"
                      list={UsuComNotificacionTipoList}
                      create={UsuComNotificacionTipoCreate}
            />

            <Resource name="UsuComArea"
                      list={UsuComAreaList}
                      create={UsuComAreaCreate}
            />

            <Resource name="ParametroSistema"
                      list={ParametroSistemaList}
            />

            <Resource name="Autoridades"
                      list={AutoridadesList}
                      create={AutoridadesCreate}
                      edit={AutoridadesEdit}
                      show={AutoridadesShow}
            />

            <Resource name="CompraCpt"
                      list={CompraCptList}
                      create={CompraCptCreate}
                      edit={CompraCptEdit}
                      show={CompraCptShow}
            />

            <Resource name="RequerimientoConcepto"
                      list={RequerimientoConceptoList}
                      create={RequerimientoConceptoCreate}
                      edit={RequerimientoConceptoEdit}
                      show={RequerimientoConceptoShow}
            />

            <Resource name="IbruTipo"
                      list={IbruTipoList}
                      create={IbruTipoCreate}
                      edit={IbruTipoEdit}
                      show={IbruTipoShow}
            />
            
            <Resource name={"BuscarClasesList"}
            />

            <Resource name="ProveedorTransferencia"
                      list={ProveedorTransferenciaList}
            />

            <Resource name="ParametroPath"
                      list={ParametroPathList}
                      create={ParametroPathCreate}
                      edit={ParametroPathEdit}
                      show={ParametroPathShow}
            />



        </Admin>
    </BuscadorContextProvider>
);

export default App;


