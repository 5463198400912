import React from 'react'
import {
    Datagrid,
    EditButton,
    List,
    TextField,
    TextInput,
    Filter,
    DateField,
    NumberInput,
    DateInput,
    NumberField,
    ReferenceInput, SelectInput, ShowButton, CardActions, Link,
} from 'react-admin';
// import OrdenCompraCabeceraInfo from "./OrdenCompraCabeceraInfo"
// import AuditoriaButton from "../componentes/AuditoriaButton"
import Vertitle from "./Vertitle"
import withStyles from "@material-ui/core/styles/withStyles";
import LinkMostrarButtonNotificacion from "./LinkMostrarButtonNotificacion";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import VisibilityIcon from '@material-ui/icons/Visibility';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import TodayIcon from '@material-ui/icons/Today';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from "@material-ui/icons/Person";


const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

var role_mis_notificacion_todas_permissions_return

const Filtros = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" variant="outlined" source="q" alwaysOn/>
        {role_mis_notificacion_todas_permissions_return === 'ROLE_MIS_NOTIFICACION_TODAS' &&
        <SelectInput
            label={  <div title={'Usuario de la Notificacion'}
                          style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                <PersonIcon style={{color: '#0064c8'}} />
            </div>
            }
            source="username"
            choices={[
                {id: 'TODOS', name: 'Todos'},
                {id: 'DISTINTO', name: 'Distintos al Mio'},
            ]}
            alwaysOn
        />
        }
        {/*<TextInput label="Número" source="orden_compra_nro" alwaysOn />*/}
        {/*<TextInput label="Razón Social" source="razon_social" alwaysOn  />*/}
        {/*/!*<TextInput label="Año" source="orden_compra_ano" alwaysOn  />*!/*/}
        {/*<ReferenceInput label='Año'*/}
        {/*                source="id_orden_compra_ano"*/}
        {/*                reference="Ano"*/}
        {/*                onChange={(event,username,record) => console.log(`New deviceId `)}*/}
        {/*                alwaysOn*/}
        {/*>*/}
        {/*    <SelectInput*/}
        {/*        optionText= "orden_compra_ano_d"*/}
        {/*        onClose={console.log('acatoy')}*/}
        {/*    />*/}
        {/*</ReferenceInput>*/}
        {/*/!*<TextInput label="Unidad" source="unidad_negocio_d" alwaysOn />*!/*/}
        {/*<ReferenceInput label='Unidad'*/}
        {/*                source="id_unidad_negocio"*/}
        {/*                reference="UnidadNegocio"*/}
        {/*                onChange={(event,username,record) => console.log(`New deviceId `)}*/}
        {/*                alwaysOn*/}
        {/*>*/}
        {/*    <SelectInput*/}
        {/*        optionText= "unidad_negocio_d"*/}
        {/*        onClose={console.log('acatoy')}*/}
        {/*    />*/}
        {/*</ReferenceInput>*/}
        {/*/!*<TextInput label="Orden Compra Estado" source="orden_compra_estado" alwaysOn  />*!/*/}

        {/*<ReferenceInput label='Orden Compra Estado'*/}
        {/*                source="id_estado_orden_compra"*/}
        {/*                reference="EstadoOrdenCompra"*/}
        {/*                onChange={(event,username,record) => console.log(`New deviceId `)}*/}
        {/*                alwaysOn*/}
        {/*>*/}
        {/*    <SelectInput*/}
        {/*        optionText= "estado"*/}
        {/*        onClose={console.log('acatoy')}*/}
        {/*    />*/}
        {/*</ReferenceInput>*/}


        {/*<TextInput label="Apl." source="orden_compra_ampliacion_nro" />*/}
        {/*<DateInput label="Fecha - Desde" variant="outlined" source="orden_compra_fecha_desde"/>*/}
        {/*<DateInput label="Fecha - Hasta" variant="outlined" source="orden_compra_fecha_hasta"/>*/}
        {/*<TextInput label="Proveedor" source="proveedor_rta" />*/}
        {/*<TextInput label="IVA Tipo" source="abre_iva_proveedor" />*/}
        {/*<TextInput label="IVA Categoría" source="d_iva_proveedor" />*/}
        {/*<TextInput label="Cuit-Cuil" source="cuit_cuil" />*/}
        {/*<TextInput label="Emisora" source="emisora_d" />*/}
        {/*<TextInput label="Area" source="area_d" />*/}
        {/*<TextInput label="Gerencia" source="gerencia_d" />*/}
        {/*<TextInput label="Sector" source="sector_d" />*/}
        {/*<TextInput label="Programa" source="programa_d" />*/}
        {/*<TextInput label="Tipo" source="contratacion_tipo" />*/}
        {/*<TextInput label="Moneda" source="moneda_abre" />*/}
    </Filter>
);

const role_mis_notificacion_todas_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if(w_permissions_control==null){}else{
        var w_role_mis_notificacion_todas = w_permissions_control.indexOf('ROLE_MIS_NOTIFICACION_TODAS')
        if(w_role_mis_notificacion_todas===-1){}else{
            w_role_control = 'ROLE_MIS_NOTIFICACION_TODAS'
        }
    }
    role_mis_notificacion_todas_permissions_return = w_role_control

    return w_role_control
}

const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
    </CardActions>
);


// Grilla general
export const NotificacionList = withStyles(styles)(({classes, permissions,...props}) =>  {
    return (
    <div>
        <div>
            <Vertitle/>
        </div>
        <div style={{ margin: '1em' }}>
            <List {...props} title="Listado de Mis Notificaciones Históricas"
                  bulkActionButtons={false}
                  actions={<PostActions/>}
                  perPage={10}
                  filters={<Filtros/>}
                  empty={false}
                  sort={{ field: 'usuario_acepto_notificacion_fh', order: 'DESC' }}
            >

                <Datagrid rowStyle={RowStyle}
                          // expand={<OrdenCompraCabeceraInfo/>}
                >
                    {role_mis_notificacion_todas_permissions({permissions}) === 'ROLE_MIS_NOTIFICACION_TODAS' &&
                    <TextField label={  <div title={'Usuario de la Notificacion'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                        <PersonIcon style={{color: '#0064c8'}} />
                                    </div>
                                    }

                                source="username"/>
                    }
                    <TextField label="Notificacion" source="notificacion_d" />
                    <TextField label="Leyenda Notificacion" source="notificacion_leyenda" />
                    <TextField label="Fecha Hora Notificacion" source="notificacion_fh" />
                    {/*<TextField label="Ver Notificacion" source="usuario_acepto_notificacion" />*/}
                    <TextField label={  <div title={'Ver Notificacion Fecha Hora'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                        {/*<VisibilityIcon/>*/}
                                        <NotificationsIcon style={{color: '#ff0000'}}/>
                                        {/*<TodayIcon/>*/}
                                        {/*<WatchLaterIcon/>*/}
                                        </div>
                                     }
                                source="usuario_acepto_notificacion_fh"
                    />
                    <TextField label={  <div title={'Envio Correo Fecha Hora'}
                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                            {/*<MarkunreadMailboxIcon/>*/}
                                            <EmailIcon/>
                                            {/*<TodayIcon/>*/}
                                            {/*<WatchLaterIcon/>*/}
                                        </div>
                                     }
                               source="envio_correo_fh"
                    />
                    <TextField label={  <div title={'Envio Whatsapp Fecha Hora'}
                                            style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center',
                                                color: '#ffffff', backgroundColor: '#00bb2d'}}>
                                            {/*<MarkunreadMailboxIcon/>*/}
                                            <WhatsAppIcon />
                                            {/*<TodayIcon />*/}
                                            {/*<WatchLaterIcon/>*/}
                                        </div>
                                     }
                               source="envio_whatsapp_fh"
                    />


                    <LinkMostrarButtonNotificacion
                        label={
                        <div title={'Mostrar'} style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center',
                            color: '#0064c8', backgroundColor: '#ffffff'}}
                        >
                            <VisibilityIcon style={{fontSize: 20}}/> Mostrar
                        </div>
                        }
                    />

                    {/*<TextField label="Razón Social" source="razon_social" />*/}
                    {/*<TextField label="IVA Tipo" source="abre_iva_proveedor" />*/}
                    {/*<TextField label="IVA Categoría" source="d_iva_proveedor" />*/}
                    {/*<TextField label="Cuit-Cuil" source="cuit_cuil" />*/}
                    {/*<TextField label="Unidad" source="unidad_negocio_d" />*/}
                    {/*<TextField label="Emisora" source="emisora_d" />*/}
                    {/*<TextField label="Area" source="area_d" />*/}
                    {/*<TextField label="Gerencia" source="gerencia_d" />*/}
                    {/*<TextField label="Sector" source="sector_d" />*/}
                    {/*<TextField label="Programa" source="programa_d" />*/}
                    {/*<TextField label="Tipo" source="contratacion_tipo" />*/}
                    {/*<TextField label="Moneda" source="moneda_abre" />*/}
                    {/*<NumberField label="Importe Total" source="importe_total"*/}
                    {/*             style={{textAlign: 'right', margin: '1em' }}*/}
                    {/*             options={{ minimumFractionDigits: 2 }} />*/}
                    {/*<NumberField label="Importe Total Pesos" source="importe_total_pesos"*/}
                    {/*             style={{textAlign: 'right', margin: '1em' }}*/}
                    {/*             options={{ minimumFractionDigits: 2 }} />*/}
                    {/*<TextField label="Es Apl." source="es_ampliacion" />*/}
                    {/*<TextField label="Estado" source="orden_compra_estado" />*/}
                    {/*<ShowButton label="Mostrar"/>*/}
                    {/*<EditButton label="Editar"/>*/}
                    {/*<AuditoriaButton label="Auditoria" />*/}
                </Datagrid>
            </List>
        </div>
    </div>
    )
});


// const RowStyle = (record, index) => ({
//     backgroundColor: record.orden_compra_estado  === undefined ? '' :
//         record.orden_compra_estado.trim() === 'ACTIVADO' ? '#c8ff40' :
//             record.orden_compra_estado.trim() === 'APROBADO' ? '#008000' :
//                 record.orden_compra_estado.trim() === 'CANCELADO' ? '#cdcdcd' :
//                     record.orden_compra_estado.trim() === 'CERRADO' ? '#7bb6b8' :
//                         record.orden_compra_estado.trim() === 'EN-APROBACION' ? '#009aff' :
//                             record.orden_compra_estado.trim() === 'EN-PROGRESO' ? '#ff8000' :
//                                 record.orden_compra_estado.trim() === 'EN-REVISION' ? '#c1004a' :
//                                     record.orden_compra_estado.trim() === 'EN-SUSPENSO' ? '#f2ff00' :
//                                         record.orden_compra_estado.trim() === 'REVISADO' ? '#8fcb3a' : 'white',
// });

const RowStyle = (record, index) => ({
    backgroundColor: record.notificacion_codigo  === undefined || record.estado_color  === undefined ? '' :
        record.estado_color.trim(),
});


export default NotificacionList;
