import React, {useState} from 'react';
import {
    useNotify, useRefresh, useMutation, ReferenceInput, SelectInput, NumberInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import {Drawer} from "@material-ui/core";
import IconCancelar from '@material-ui/icons/Close';
import IconEdit from '@material-ui/icons/Edit';

const useStyles = makeStyles({
    socio_nro_ingreso: {display: 'inline-block', width: 100},
    tipoDocConyuge: {display: 'inline-block', width: 150,},
    nombre: {display: 'inline-block', width: 150,},
    apellido: {display: 'inline-block', width: 150, marginLeft: 28},
    documento_tipo: {display: 'inline-block', width: 80},
    documento_nro: {display: 'inline-block', width: 120, marginLeft: 28},
    cuit_cuil: {display: 'inline-block', width: 150, marginLeft: 28},
    correo_electronico: {display: 'inline-block', width: 350},
    estado_civil: {display: 'inline-block', width: 120},
    socio_activo: {display: 'inline-block', width: 80},

    limite_para_oferta_electronica_apoderado: {display: 'inline-block', width: 80},
    limite_para_oferta_electronica_pesos: {display: 'inline-block', width: 120, marginLeft: 28},
});

const ProveedorApoderadoEditar = ({record}) => {
        const classes = useStyles()
        const [open, setOpen] = React.useState(false);

        const [idApoderado, setIdApoderado] = useState(record.id);
        const [proveedor, setEstadoproveedor] = useState('');

        const [usuario_ingreso_sistema, setUsuario_ingreso_sistema] = useState('');
        const [usuario_ingreso_sistema_clave, setUsuario_ingreso_sistema_clave] = useState('');

        const [nombre_apoderado, setNombre_apoderado] = useState('');
        const [apellido_apoderado, setApellido_apoderado] = useState('');
        const [cuit_cuil_apoderado, setCuit_cuil_apoderado] = useState('');
        const [id_documento_tipo_apoderado, setId_documento_tipo_apoderado] = useState('');
        const [documento_nro_apoderado, setDocumento_nro_apoderado] = useState('');
        const [correo_electronico_apoderado, setCorreo_electronico_apoderado] = useState('');
        const [correo_electronico_alternativo_apoderado, setCorreo_electronico_alternativo_apoderado] = useState('');

        const [limite_para_oferta_electronica_apoderado, setLimite_para_oferta_electronica] = useState('');
        const [limite_para_oferta_electronica_pesos, setLimite_para_oferta_electronica_pesos] = useState(record.apellido_apoderado);
        const [es_administrador_legitimado, setEs_administrador_legitimado] = useState('');
        const [apoderado_activo, setApoderado_activo] = useState('');

        const BtnCancelar = ({record}) => (
            <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
                setOpen(false)
            }}
            >
                <IconCancelar/>
                Volver
            </Button>
        );

        const BotonCrearDetelle = (props) => {
            const notify = useNotify();
            const refresh = useRefresh();

            const [generardetelle, {loading}] = useMutation({
                    type: 'update',
                    resource: 'ProveedorApoderado',
                    payload: {
                        id: idApoderado,
                        data: {
                            proveedor,
                            usuario_ingreso_sistema,
                            usuario_ingreso_sistema_clave,
                            nombre_apoderado,
                            apellido_apoderado,
                            cuit_cuil_apoderado,
                            id_documento_tipo_apoderado,
                            documento_nro_apoderado,
                            correo_electronico_apoderado,
                            correo_electronico_alternativo_apoderado,
                            limite_para_oferta_electronica_apoderado,
                            limite_para_oferta_electronica_pesos,
                            es_administrador_legitimado,
                            apoderado_activo,
                        }
                    }
                },
                {
                    onSuccess: ({data}) => {
                        refresh()
                    },
                    onFailure: error => {
                        notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                    },
                }
            );


            return (
                <Button variant="outlined" color="primary" onClick={generardetelle}
                        disabled={props.pristine}>
                    <div title={''}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                         }}>
                        <SaveIcon style={{color: '#004187'}}/>
                        Guardar
                    </div>
                </Button>
            );
        };

        /// JN Mascara para el input cuit-cuil
        const cuitCuilFormat = value => {
            if (value == null || value === '') return '';

            //normalize string and remove all unnecessary characters
            var valueCuit = value.replace(/[^\d]/g, "");

            //check if number length equals to 11
            if (valueCuit.length == 11) {
                return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
            }

            return value
        };

        const handleClickOpen = () => {
            setIdApoderado(record.id);
            setEstadoproveedor(record.proveedor);
            setUsuario_ingreso_sistema(record.usuario_ingreso_sistema);
            setUsuario_ingreso_sistema_clave(record.usuario_ingreso_sistema_clave);
            setNombre_apoderado(record.nombre);
            setApellido_apoderado(record.apellido);
            setCuit_cuil_apoderado(record.cuit_cuil);
            setId_documento_tipo_apoderado(record.id_documento_tipo);
            setDocumento_nro_apoderado(record.documento_nro);
            setCorreo_electronico_apoderado(record.correo_electronico);
            setCorreo_electronico_alternativo_apoderado(record.correo_electronico_alternativo);
            setLimite_para_oferta_electronica(record.limite_para_oferta_electronica_apoderado);
            setLimite_para_oferta_electronica_pesos(record.limite_para_oferta_electronica_pesos);
            setEs_administrador_legitimado(record.es_administrador_legitimado);
            setApoderado_activo(record.apoderado_activo);

            setOpen(true);
        };
        const formatIdTipoDoc = v => {
            return id_documento_tipo_apoderado

        };

        const formatActivo = v => {
            return apoderado_activo
        };

        const formatAdmLegitimado = v => {
            return es_administrador_legitimado
        };

        const formatLimiteOferta = v => {
            return limite_para_oferta_electronica_apoderado
        };

        return (
            <div>
                <Button variant="text" style={{color: '#123B80'}} size={"small"} onClick={handleClickOpen}>
                    <IconEdit/>
                    Editar
                </Button>

                <Drawer
                    classes={{paper: classes.paper}}
                    variant="persistent"
                    anchor="right"
                    open={open}
                >
                    <div>
                        <h3>Editar Socio</h3>
                    </div>
                    <br/>
                    <div style={{paddingLeft: "10px"}}>

                        <TextField source={"nombre_apoderado"} label={"nombre"} variant={"outlined"}
                                   InputLabelProps={{shrink: true}}
                                   value={nombre_apoderado} onChange={(event) => setNombre_apoderado(event.target.value)}
                                   className={classes.nombre} fullWidth/>

                        <TextField source={"apellido_apoderado"} label={"apellido"} variant={"outlined"}
                                   value={apellido_apoderado}
                                   onChange={(event) => setApellido_apoderado(event.target.value)}
                                   InputLabelProps={{shrink: true}}
                                   className={classes.apellido} fullWidth/><br/> <br/>

                        <ReferenceInput reference="DocumentoTipo" variant="outlined"
                                        label="Tipo Doc." source="id_documento_tipo_apoderado"
                                        value={id_documento_tipo_apoderado}
                                        className={classes.documento_tipo} sort={{field: 'orden', order: 'DESC'}}
                                        inputProps={{
                                            onChange: (e) => {
                                                setId_documento_tipo_apoderado(e.target.value);
                                            }
                                        }}
                                        format={formatIdTipoDoc}
                        >
                            <SelectInput InputLabelProps={{shrink: true}} optionText="documento_tipo_d" fullWidth={true}
                                         className={classes.documento_tipo_d}
                            />
                        </ReferenceInput>

                        <TextField source="documento_nro_apoderado" label={"Nro. Doc."} variant="outlined"
                                   value={documento_nro_apoderado}
                                   InputLabelProps={{shrink: true}}
                                   onChange={(event) => setDocumento_nro_apoderado(event.target.value)}
                                   className={classes.documento_nro} fullWidth={true}/>

                        <TextField source="cuit_cuil_apoderado" label={"CUIT/CUIL"} variant="outlined"
                                   InputLabelProps={{shrink: true}} value={cuit_cuil_apoderado}
                                   className={classes.cuit_cuil} fullWidth={true}
                                   format={cuitCuilFormat}
                                   onChange={(event) => setCuit_cuil_apoderado(event.target.value)}
                        /><br/>

                        <TextField source={"correo_electronico_apoderado"} label={"correo_electronico"} variant={"outlined"}
                                   InputLabelProps={{shrink: true}} className={classes.correo_electronico}
                                   value={correo_electronico_apoderado}
                                   onChange={(event) => setCorreo_electronico_apoderado(event.target.value)}
                                   fullWidth/>
                        <br/> <br/>

                        <TextField source={"correo_electronico_alternativo_apoderado"}
                                   label={"correo_electronico_alternativo"}
                                   variant={"outlined"} InputLabelProps={{shrink: true}}
                                   value={correo_electronico_alternativo_apoderado}
                                   className={classes.correo_electronico}
                                   onChange={(event) => setCorreo_electronico_alternativo_apoderado(event.target.value)}
                                   fullWidth/><br/> <br/>

                        <SelectInput source="limite_para_oferta_electronica_apoderado"
                                     value={limite_para_oferta_electronica_apoderado}
                                     label="¿Tiene límite para oferta electrónica?" InputLabelProps={{shrink: true}}
                                     className={classes.limite_para_oferta_electronica_apoderado} variant="outlined"
                                     inputProps={{
                                         onChange: (e) => {
                                             setLimite_para_oferta_electronica(e.target.value);
                                         }
                                     }}
                                     format={formatLimiteOferta} fullWidth={true}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}
                        />
                        {limite_para_oferta_electronica_apoderado === 'S' &&
                        <NumberInput source={"limite_para_oferta_electronica_pesos"}
                                     label={"Límite en pesos"}
                                     variant={"outlined"} InputLabelProps={{shrink: true}}
                                     value={limite_para_oferta_electronica_pesos}
                                     className={classes.limite_para_oferta_electronica_pesos}
                                     onChange={(event) => setLimite_para_oferta_electronica_pesos(event.target.value)}
                                     fullWidth
                        />
                        }
                        <br/>
                        <SelectInput source="es_administrador_legitimado" value={es_administrador_legitimado}
                                     label="¿Adm. Legitimado?" InputLabelProps={{shrink: true}}
                                     className={classes.socio_activo} variant="outlined"
                                     inputProps={{
                                         onChange: (e) => {
                                             setEs_administrador_legitimado(e.target.value);
                                         }
                                     }}
                                     format={formatAdmLegitimado} fullWidth={true}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}
                        />

                        <br/>
                        <SelectInput source="apoderado_activo" value={apoderado_activo}
                                     label="Activo" InputLabelProps={{shrink: true}}
                                     className={classes.socio_activo} variant="outlined"
                                     inputProps={{
                                         onChange: (e) => {
                                             setApoderado_activo(e.target.value);
                                         }
                                     }}
                                     format={formatActivo} fullWidth={true}
                                     choices={[
                                         {id: 'S', name: 'Si'},
                                         {id: 'N', name: 'No'},
                                     ]}
                        />

                        <div>
                            <BotonCrearDetelle/>

                            <BtnCancelar/>
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }
;

export default ProveedorApoderadoEditar;



