import React, {Fragment, useState, useCallback} from 'react';
import {
    useDataProvider,
    TextInput,
    DateInput,
    useNotify, useRefresh, useMutation
} from 'react-admin';

import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import RequerimientoConceptoBusquedaBtn from "../RequerimientoConcepto/RequerimientoConceptoBusquedaBtn";
import UniMedBusquedaBtn from "../UniMed/UniMedBusquedaBtn";
import IvaTasaTipoBusquedaBtn from "../IvaTasaTipo/IvaTasaTipoBusquedaBtn";
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';

var w_apiurlserver = ApiUrlServer
if (w_apiurlserver == null) {
    w_apiurlserver = ''
}
w_apiurlserver = w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
    item: {display: 'inline-block', width: 80, marginLeft: 15},
    requerimiento_concepto: {display: 'inline-block', width: 250, marginLeft: 32},
    clase: {display: 'inline-block', width: 250, marginLeft: 32},
    renglon: {display: 'inline-block', width: 80},
    renglon_d: {display: 'inline-block', width: 800, marginLeft: 32},
    fec_entrega: {display: 'inline-block', width: 150, marginLeft: 60},
    uni_med: {display: 'inline-block', width: 120},
    cantidad: {display: 'inline-block', width: 80, marginLeft: 32},
    iva: {display: 'inline-block', width: 110, marginLeft: 22},
    btnBusqueda: {
        // marginRight: '1rem',
        width: 10,
        display: 'inline-block',
    },
    moneda: {display: 'inline-block', width: 150, marginLeft: 15},
    cotizacion: {display: 'inline-block', width: 150, marginLeft: 32},
    fecha_cotizacion: {display: 'inline-block', width: 150, marginLeft: 32},
    importe: {display: 'inline-block', width: 150, marginLeft: 32, textAlign: 'right'},
    importe2: {display: 'inline-block', width: 150, marginLeft: 16},
});

const OrdenCompraDetalleButtonEdit = ({record}) => {
    console.log("record edit", record)
    var wr_id = record.id
    var wr_orden_compra_nro = record.orden_compra_nro

    if (wr_orden_compra_nro == null) {
        wr_orden_compra_nro = 0
    }
    // wr_orden_compra_nro=wr_orden_compra_nro.trim()

    var wr_orden_compra_ampliacion_nro = record.orden_compra_ampliacion_nro


    const [EstadoImporteTotal, setEstadoImporteTotal] = useState(0)


    const handleImporteTotal = (event) => {
        const Cambiaimporte = (wp_value) => {
            setEstadoImporteTotal(wp_value)
        };

        var w_value = event.target.value;


        Cambiaimporte(w_value)

    };

    function NumberFormatCustom(props) {
        const {inputRef, onChange, ...other} = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={'.'}
                decimalSeparator={','}
                isNumericString
                prefix=""
            />
        );
    }

    NumberFormatCustom.propTypes = {
        inputRef: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,

    };


    if (wr_orden_compra_ampliacion_nro == null) {
        wr_orden_compra_ampliacion_nro = 0
    }
    // wr_orden_compra_ampliacion_nro_tiene=wr_orden_compra_ampliacion_nro_tiene.trim()
    const dataProviderTableroControl = useDataProvider();
    const [Estadoid, setEstadoid] = useState(0)
    const [Estadoid_orden_compra_cabecera, setEstadoid_orden_compra_cabecera] = useState(0)
    const [Estadoorden_compra_nro, setEstadoorden_compra_nro] = useState(0)
    const [Estadoorden_compra_ampliacion_nro, setEstadoorden_compra_ampliacion_nro] = useState(0)
    const [Estadoitem, setEstadoitem] = useState('')
    const [Estadoconcepto_requerimiento, setEstadoconcepto_requerimiento] = useState('')
    const [Estadoclase, setEstadoclase] = useState('')
    const [Estadorenglon_nro, setEstadorenglon_nro] = useState('')
    const [Estadorenglon_descripcion, setEstadorenglon_descripcion] = useState('')
    const [Estadounidad_medida, setEstadounidad_medida] = useState('')
    const [Estadounidad_medida_abre, setEstadounidad_medida_abre] = useState('')
    const [Estadocantidad, setEstadocantidad] = useState('')
    const [Estadofecha_entrega, setEstadofecha_entrega] = useState('')
    const [Estadomoneda, setEstadomoneda] = useState('')
    const [Estadomoneda_abre, setEstadomoneda_abre] = useState('')
    const [EstadoIdMoneda, setEstadoIdMoneda] = useState(0)
    const [Estadocotizacion, setEstadocotizacion] = useState(record.cotizacion)
    const [EstadocotizacionFecha, setEstadocotizacionFecha] = useState(record.cotizacion_fecha)
    const [Estadoprecio_unitario_sin_iva, setEstadoprecio_unitario_sin_iva] = useState('')
    const [Estadodescuento_porcentaje, setEstadodescuento_porcentaje] = useState('')
    const [Estadoprecio_unitario_sin_iva_con_dto, setEstadoprecio_unitario_sin_iva_con_dto] = useState('')
    const [Estadoiva_porcentaje, setEstadoiva_porcentaje] = useState(0)
    const [Estadoiva_importe, setEstadoiva_importe] = useState('')
    const [Estadototal_item, setEstadototal_item] = useState('')
    const [Estadototal_importe_iva_pesos, setEstadototal_importe_iva_pesos] = useState('')
    const [Estadototal_item_pesos, setEstadototal_item_pesos] = useState('')
    const [Estadofecha_necesidad, setEstadofecha_necesidad] = useState('')

    const [ComboMoneda, setComboMoneda] = useState([]);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const classes = useStyles()
    const [open, setOpen] = React.useState(false);

    // const [EstadoImporteTotal, setEstadoImporteTotal] = useState(0)

    const handleClickOpen = () => {
        var wr_id = record.id;
        var wr_id_orden_compra_cabecera = record.id_orden_compra_cabecera;
        var wr_orden_compra_nro = record.orden_compra_nro;
        var wr_orden_compra_ampliacion_nro = record.orden_compra_ampliacion_nro;
        var wr_item = record.item;
        var wr_concepto_requerimiento = record.concepto_requerimiento;
        var wr_clase = record.clase;
        var wr_renglon_nro = record.renglon_nro;
        var wr_renglon_descripcion = record.renglon_descripcion;
        var wr_unidad_medida = record.unidad_medida;
        var wr_unidad_medida_abre = record.unidad_medida_abre;
        var wr_cantidad = record.cantidad;
        var wr_fecha_entrega = record.fecha_entrega;
        var wr_moneda = record.moneda;
        var wr_id_moneda = record.id_moneda;
        var wr_cotizacion = record.cotizacion;
        var wr_cotizacion_fecha = record.cotizacion_fecha;
        var wr_precio_unitario_sin_iva = record.precio_unitario_sin_iva;
        var wr_descuento_porcentaje = record.descuento_porcentaje;
        var wr_precio_unitario_sin_iva_con_dto = record.precio_unitario_sin_iva_con_dto;
        var wr_iva_porcentaje = record.iva_porcentaje;
        var wr_iva_importe = record.iva_importe;
        var wr_total_item = record.total_item;
        var wr_total_importe_iva_pesos = record.total_importe_iva_pesos;
        var wr_total_item_pesos = record.total_item_pesos;
        var wr_fecha_necesidad = record.fecha_necesidad;

        setEstadoImporteTotal(wr_total_item)
        setEstadoid(wr_id);
        setEstadoid_orden_compra_cabecera(wr_id_orden_compra_cabecera);
        setEstadoorden_compra_nro(wr_orden_compra_nro);
        setEstadoorden_compra_ampliacion_nro(wr_orden_compra_ampliacion_nro);
        setEstadoitem(wr_item);
        setEstadoconcepto_requerimiento(wr_concepto_requerimiento);
        setEstadoclase(wr_clase);
        setEstadorenglon_nro(wr_renglon_nro);
        setEstadorenglon_descripcion(wr_renglon_descripcion);
        setEstadounidad_medida(wr_unidad_medida);
        setEstadounidad_medida_abre(wr_unidad_medida_abre);
        setEstadocantidad(wr_cantidad);
        setEstadofecha_entrega(wr_fecha_entrega);
        setEstadofecha_entrega(wr_fecha_entrega);
        setEstadomoneda(wr_moneda);
        setEstadoIdMoneda(wr_id_moneda);
        setEstadocotizacion(wr_cotizacion);
        setEstadocotizacionFecha(wr_cotizacion_fecha);

        setEstadoprecio_unitario_sin_iva(wr_precio_unitario_sin_iva);
        setEstadodescuento_porcentaje(wr_descuento_porcentaje);
        setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto);
        setEstadoiva_porcentaje(wr_iva_porcentaje);
        setEstadoiva_importe(wr_iva_importe);
        setEstadototal_item(wr_total_item);
        setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos);
        setEstadototal_item_pesos(wr_total_item_pesos);
        setEstadofecha_necesidad(wr_fecha_necesidad);

        dataProviderTableroControl.getList(
            'MonedaActivo',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'S', id: record.id_moneda},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setComboMoneda(data);
                setEstadomoneda(data[0].moneda_d);
            })
            .catch(error => {
                setOpen(false);
            })
        setOpen(true);
    };

    const handleActualizar = () => {
        setOpen(false);
    };

    const handlefecha_entrega = (event) => {
        const Cambiafecha_entrega1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadofecha_entrega(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiafecha_entrega1(w_value)
        event.preventDefault()

    };


    const handlecantidad = (event) => {
        const Cambiacantidad1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadocantidad(wp_value)

            var wh_cantidad = wp_value
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva
            var wh_descuento_porcentaje = Estadodescuento_porcentaje
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva


            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
            }
            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)


            // (a) Cantidad:
            // (b) Precio unitario sin iva:
            // (c) Descuento %:
            // (d) Precio unitario con descuento:   =  round(  b*(1-(c/100)),2)  RO
            // (e) IVA %:
            // (f) IVA Importe:     RO   =  round((a * d) * (e/100),2)

            var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);
            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100;

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiacantidad1(w_value)

    };

    const handlecotizacion = (event) => {
        const Cambiacotizacion1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadocotizacion(wp_value)

            var wh_cantidad = Estadocantidad
            var wh_cotizacion = wp_value
            var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva
            var wh_descuento_porcentaje = Estadodescuento_porcentaje
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
            }
            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiacotizacion1(w_value)

    };

    const handleprecio_unitario_sin_iva = (event) => {
        const Cambiaprecio_unitario_sin_iva1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoprecio_unitario_sin_iva(wp_value)

            var wh_cantidad = Estadocantidad
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = wp_value
            var wh_descuento_porcentaje = Estadodescuento_porcentaje
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
            }
            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            // var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)
            var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiaprecio_unitario_sin_iva1(w_value)

    };

    const handleiva_porcentaje = (w_porcentaje) => {
        var wh_cantidad = Estadocantidad;
        var wh_cotizacion = Estadocotizacion;
        var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva;
        var wh_descuento_porcentaje = Estadodescuento_porcentaje;
        var wh_iva_porcentaje = w_porcentaje;

        var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

        if (wh_descuento_porcentaje === 0) {
        } else {
            wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
        }
        wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

        setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

        // var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)
        var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);

        wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

        setEstadoiva_importe(wr_iva_importe)

        var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

        wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

        setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

        var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

        wr_total_item = Math.round(wr_total_item * 100) / 100

        setEstadototal_item(wr_total_item)

        var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

        wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

        setEstadototal_item_pesos(wr_total_item_pesos)
    };

    const handledescuento_porcentaje = (event) => {
        const Cambiadescuento_porcentaje1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadodescuento_porcentaje(wp_value)

            var wh_cantidad = Estadocantidad
            var wh_cotizacion = Estadocotizacion
            var wh_precio_unitario_sin_iva = Estadoprecio_unitario_sin_iva
            var wh_descuento_porcentaje = wp_value
            var wh_iva_porcentaje = Estadoiva_porcentaje


            console.log('wh_cantidad')
            console.log(wh_cantidad)
            console.log('wh_cotizacion')
            console.log(wh_cotizacion)
            console.log('wh_precio_unitario_sin_iva')
            console.log(wh_precio_unitario_sin_iva)
            console.log('wh_descuento_porcentaje')
            console.log(wh_descuento_porcentaje)
            console.log('wh_iva_porcentaje')
            console.log(wh_iva_porcentaje)

            var wr_precio_unitario_sin_iva_con_dto = wh_precio_unitario_sin_iva

            if (wh_descuento_porcentaje === 0) {
            } else {
                wr_precio_unitario_sin_iva_con_dto = ((wh_precio_unitario_sin_iva * (1 - (wh_descuento_porcentaje) / 100)));
            }
            wr_precio_unitario_sin_iva_con_dto = Math.round(wr_precio_unitario_sin_iva_con_dto * 100) / 100

            console.log('wr_precio_unitario_sin_iva_con_dto')
            console.log(wr_precio_unitario_sin_iva_con_dto)

            setEstadoprecio_unitario_sin_iva_con_dto(wr_precio_unitario_sin_iva_con_dto)

            var wr_iva_importe = (wh_cantidad * wr_precio_unitario_sin_iva_con_dto) * (wh_iva_porcentaje / 100);
            // var wr_iva_importe = ((wr_precio_unitario_sin_iva_con_dto * wh_iva_porcentaje) / 100)

            wr_iva_importe = Math.round(wr_iva_importe * 100) / 100

            console.log('wr_iva_importe')
            console.log(wr_iva_importe)

            setEstadoiva_importe(wr_iva_importe)

            var wr_total_importe_iva_pesos = (wr_iva_importe * wh_cotizacion)

            wr_total_importe_iva_pesos = Math.round(wr_total_importe_iva_pesos * 100) / 100

            console.log('wr_total_importe_iva_pesos')
            console.log(wr_total_importe_iva_pesos)

            setEstadototal_importe_iva_pesos(wr_total_importe_iva_pesos)

            var wr_total_item = (wr_precio_unitario_sin_iva_con_dto * wh_cantidad) + wr_iva_importe;

            wr_total_item = Math.round(wr_total_item * 100) / 100

            console.log('wr_total_item')
            console.log(wr_total_item)

            setEstadototal_item(wr_total_item)

            var wr_total_item_pesos = (wr_total_item * wh_cotizacion)

            wr_total_item_pesos = Math.round(wr_total_item_pesos * 100) / 100

            console.log('wr_total_item_pesos')
            console.log(wr_total_item_pesos)

            setEstadototal_item_pesos(wr_total_item_pesos)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        if (w_value == null) {
            w_value = ''
        }
        w_value = w_value.trim()
        if (w_value === '') {
        } else {
            w_value = Number(w_value)
        }
        Cambiadescuento_porcentaje1(w_value)

    };


    const handleClose = () => {
        setOpen(false);
    };

    const BotonActualizarDetelle = (record) => {

        var w_mensaje = '';

        const notify = useNotify();
        const refresh = useRefresh();

        const [generardetelle, {loading}] = useMutation({
                type: 'update',
                resource: 'OrdenCompraDetalle',
                payload: {
                    id: Estadoid, data: {
                        id_orden_compra_cabecera: Estadoid_orden_compra_cabecera,
                        orden_compra_nro: Estadoorden_compra_nro,
                        orden_compra_ampliacion_nro: Estadoorden_compra_ampliacion_nro,
                        item: Estadoitem,
                        concepto_requerimiento: Estadoconcepto_requerimiento,
                        clase: Estadoclase,
                        renglon_nro: Estadorenglon_nro,
                        renglon_descripcion: Estadorenglon_descripcion,
                        unidad_medida: Estadounidad_medida,
                        unidad_medida_abre: Estadounidad_medida_abre,
                        cantidad: Estadocantidad,
                        // moneda: Estadomoneda,
                        // cotizacion: Estadocotizacion,
                        // cotizacion_fecha: EstadocotizacionFecha,
                        precio_unitario_sin_iva: Estadoprecio_unitario_sin_iva,
                        descuento_porcentaje: Estadodescuento_porcentaje,
                        precio_unitario_sin_iva_con_dto: Estadoprecio_unitario_sin_iva_con_dto,
                        iva_porcentaje: Estadoiva_porcentaje,
                        iva_importe: Estadoiva_importe,
                        total_item: Estadototal_item,
                        total_importe_iva_pesos: Estadototal_importe_iva_pesos,
                        total_item_pesos: Estadototal_item_pesos,
                        fecha_entrega: Estadofecha_entrega,
                        fecha_necesidad: Estadofecha_necesidad
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    refresh()
                    // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------

                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );


        return (
            <Button variant="outlined" color="primary" onClick={generardetelle} disabled={loading}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <SaveIcon style={{color: '#004187'}}/>
                    Actualizar
                </div>
            </Button>
            // <Button label={w_mensaje}
            //         title={w_mensaje}
            //         onClick={generardetelle} disabled={loading}
            //         color="primary"
            // >
            //     <div>
            //         <LineWeightIcon style={{fontSize: 40, backgroundColor: '#ffffff', color: '#004187'}}/>
            //     </div>
            // </Button>
        );

    };

    const handleconcepto_requerimiento = useCallback(async (who) => {
        setEstadoconcepto_requerimiento(who.requerimiento_concepto);
        setEstadoclase(who.clase);
    });

    const formatEstadoconcepto_requerimiento = v => {
        return Estadoconcepto_requerimiento
    };

    const formatEstadoclase = v => {
        return Estadoclase
    };

    const handleclase = (event) => {
        const Cambiaclase1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadoclase(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiaclase1(w_value)

    };

    const handlerenglon_nro = (event) => {
        const Cambiarenglon_nro1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadorenglon_nro(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiarenglon_nro1(w_value)

    };

    const handlerenglon_descripcion = (event) => {
        const Cambiarenglon_descripcion1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadorenglon_descripcion(wp_value)
        };

        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()

        Cambiarenglon_descripcion1(w_value)

    };

    const handleunidad_medida = useCallback(async (who) => {
        setEstadounidad_medida(who.unidad_medida);
        setEstadounidad_medida_abre(who.unidad_medida_abre);
    });

    const handleIvaTasaTipo = useCallback(async (who) => {
        setEstadoiva_porcentaje(who.porcentaje);
        handleiva_porcentaje(who.porcentaje);
        // handleiva_porcentaje(who.porcentaje);
    });

    const formatIvaPorcentaje = v => {
        return Estadoiva_porcentaje;
    };

    const formatEstadounidad_medida_abre = v => {
        return Estadounidad_medida_abre;
    };

    const handlemoneda = (event) => {
        const Cambiamoneda1 = (wp_value) => {
            console.log("valor ", wp_value)

            setEstadomoneda(wp_value)
        };

        var w_value = event.target.value

        if (w_value.trim() == 'PES') {
            setEstadocotizacion(1)
        }

        Cambiamoneda1(w_value)

    };

    return (
        <div>


            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <EditIcon style={{color: '#004187'}}/>
                    Editar
                </div>
            </Button>

            <Dialog open={open}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                // fullScreen={true}
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Detalle de Productos/Servicios</DialogTitle>
                <DialogContent>
                    <form>

                        <div style={{display: 'flex', alignContent: 'baseline', width: '100%'}}>

                            <TextInput source="item"
                                       label={"Item"}
                                       fullWidth={true}
                                       className={classes.item}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                                       value={Estadoitem}
                                // format={formatItem}
                            />

                            <TextInput source="requerimiento_concepto"
                                       label={"Concepto Requerimiento"}
                                       fullWidth={true}
                                       className={classes.requerimiento_concepto}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                                       value={Estadoconcepto_requerimiento}
                                       format={formatEstadoconcepto_requerimiento}
                            />

                            <TextInput source="clase" label={"Clase"}
                                       fullWidth={true}
                                       className={classes.clase}
                                       variant="filled" inputProps={{readOnly: true,}}
                                       InputLabelProps={{shrink: true}}
                                       value={Estadoclase}
                                       format={formatEstadoclase}
                            />

                            <RequerimientoConceptoBusquedaBtn fullWidth={true}
                                                              callbackRequerimientoConcepto={handleconcepto_requerimiento}
                                                              className={classes.btnBusqueda}/>

                        </div>

                        <TextField label="Renglon" value={Estadorenglon_nro}
                                   onChange={handlerenglon_nro} style={{textAlign: 'right', margin: '1em'}}
                                   SelectProps={{native: true,}}
                                   className={classes.renglon}
                                   InputLabelProps={{shrink: true}}
                                   required
                        />

                        <TextField
                            label="Renglon Descripción"
                            value={Estadorenglon_descripcion}
                            onChange={handlerenglon_descripcion}
                            multiline style={{margin: '1em'}}
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            SelectProps={{native: true,}}
                            className={classes.renglon_d}
                            required
                        />
                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput label="Uni.Med."
                                       value={Estadounidad_medida_abre}
                                       format={formatEstadounidad_medida_abre}
                                       style={{margin: '1em'}}
                                       className={classes.uni_med}
                                       inputProps={{readOnly: true,}}
                                       SelectProps={{
                                           native: true,
                                       }}
                            />

                            <UniMedBusquedaBtn
                                callbackUniMed={handleunidad_medida}
                                className={classes.btnBusqueda}/>

                            <NumberFormat
                                label="Cantidad"
                                customInput={TextField} value={Estadocantidad}
                                onChange={handlecantidad} className={classes.cantidad}
                                style={{textAlign: 'right', margin: '1em'}}
                                options={{minimumFractionDigits: 2}}
                            />

                            <DateInput
                                label="Fecha de Entrega"
                                source={"fecha_entrega"}
                                className={classes.fec_entrega} fullWidth
                                value={Estadofecha_entrega}
                                defaultValue={Estadofecha_entrega}
                                onChange={handlefecha_entrega}
                                style={{textAlign: 'right', margin: '1em'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                            />

                        </div>

                        <TextField
                            label="Moneda"
                            value={Estadomoneda}
                            inputProps={{readOnly: true}}
                            variant="filled"
                            className={classes.moneda}
                        />

                        <TextField
                            label="Cotización"
                            value={Estadocotizacion}
                            inputProps={{readOnly: true}}
                            variant="filled"
                            className={classes.cotizacion}
                        />

                        <DateInput
                            label="Fecha Cotización"
                            source={"cotizacion_fecha"}
                            className={classes.fecha_cotizacion} fullWidth
                            defaultValue={EstadocotizacionFecha}
                            inputProps={{readOnly: true}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                        />

                        <br/>

                        <NumberFormat
                            customInput={TextField} label="Precio Unit. Sin IVA"
                            value={Estadoprecio_unitario_sin_iva}
                            onChange={handleprecio_unitario_sin_iva}
                            style={{textAlign: 'right', margin: '1em'}}
                            decimalScale={2}
                            className={classes.importe}
                            options={{minimumFractionDigits: 2}}
                        />

                        <NumberFormat
                            customInput={TextField} label="Porc. Descuento"
                            value={Estadodescuento_porcentaje}
                            onChange={handledescuento_porcentaje}
                            style={{textAlign: 'right', margin: '1em'}}
                            decimalScale={2}
                            className={classes.importe}
                            options={{minimumFractionDigits: 2}}/>

                        <TextField
                            label="Precio Unit. con Dto"
                            value={Estadoprecio_unitario_sin_iva_con_dto}
                            onBlur={handleImporteTotal}
                            name="numberformat"
                            id="importe_presupuesto_pesos_ing"
                            variant="filled"
                            className={classes.importe2}
                            textAlign="right"
                            // style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                        />

                        <br/>

                        <div style={{display: 'inline-flex', width: '100%'}}>

                            <TextInput
                                label="IVA %"
                                value={Estadoiva_porcentaje}
                                format={formatIvaPorcentaje}
                                inputProps={{readOnly: true}}
                                variant="filled"
                                className={classes.iva}
                                onChange={handleiva_porcentaje}
                            />

                            <IvaTasaTipoBusquedaBtn
                                callbackIvaTasaTipo={handleIvaTasaTipo}
                                className={classes.btnBusqueda}/>

                            <TextField
                                label="IVA Importe"
                                value={Estadoiva_importe}
                                name="numberformat"
                                id="importe_presupuesto_pesos_ing"
                                variant="filled"
                                className={classes.importe}
                                style={{textAlign: 'right', margin: '1em'}}
                                InputProps={{
                                    inputComponent: NumberFormatCustom
                                }}
                            />

                            <TextField
                                label="Total Ítem"
                                value={Estadototal_item}
                                className={classes.importe}
                                id="importe_presupuesto_pesos_ing"
                                variant="filled"
                                style={{textAlign: 'right', margin: '1em'}}
                                InputProps={{
                                    inputComponent: NumberFormatCustom
                                }}
                            />

                        </div>

                        <TextField
                            label="Total IVA $"
                            value={Estadototal_importe_iva_pesos}
                            className={classes.importe}
                            id="importe_presupuesto_pesos_ing"
                            variant="filled"
                            style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                        />

                        <TextField
                            label="Total Ítem $"
                            value={Estadototal_item_pesos}
                            name="numberformat"
                            id="importe_presupuesto_pesos_ing"
                            variant="filled"
                            className={classes.importe}
                            style={{textAlign: 'right', margin: '1em'}}
                            InputProps={{
                                inputComponent: NumberFormatCustom
                            }}
                        />

                    </form>
                </DialogContent>
                <DialogActions>

                    {Estadorenglon_nro &&
                    Estadorenglon_descripcion &&
                    Estadocantidad &&
                    Estadounidad_medida &&
                    Estadoprecio_unitario_sin_iva ?
                        <>
                            <BotonActualizarDetelle/>
                        </>
                        :
                        <>
                            <Button disabled
                                    variant="contained"
                                // style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                    className={classes.button}>
                                Actualizar
                            </Button>
                        </>
                    }

                    <Button variant="outlined" color="primary" onClick={handleClose}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <CancelPresentationIcon style={{color: '#004187'}}/>
                            Cancelar
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

};

OrdenCompraDetalleButtonEdit.defaultProps = {
    source: '',
    addLabel: true,
};

export default OrdenCompraDetalleButtonEdit;