import React, {useState, useCallback} from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Create,
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    AutocompleteInput,
    SelectInput,
    DateInput,
    TopToolbar,
    ReferenceInput,
    Toolbar, SaveButton, DeleteButton,
    useGetOne, Loading, NumberInput, TextField, ShowButton
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";



const useStyles = makeStyles((theme) => ({
    secuencia_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    secuencia_tipo_d: {display: 'inline-block', width: 800, marginLeft: 8},
}));

const ContratacionSecuenciaTipoEdit = props => {
    const classes = useStyles();


    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );

    const redirect = (basePath, id, data) => `/ContratacionSecuenciaTipo`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            <DeleteButton undoable={false} redirect={redirect}/>
        </Toolbar>
    );
    const transform = data => {
        return {
            ...data
        }
    };

    const PostTitle = ({record}) => {
        return <span>Contratacion Secuencia Tipo: {record ? `${record.secuencia_tipo}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={<CustomToolbar/>}
            >
                {/*Datos*/}
                <FormTab label="DATOS CONTRATACION SECUENCIA TIPO">

                    <TextInput
                        label="Código Contratacion Secuencia Tipo"
                        source="secuencia_tipo"
                        formClassName={classes.secuencia_tipo}
                        placeholder="Ingrese Código Contratacion Secuencia Tipo"
                        helperText="Código Contratacion Secuencia Tipo"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>

                    <TextInput
                        label="Descripción Contratacion Secuencia Tipo"
                        source="secuencia_tipo_d"
                        formClassName={classes.secuencia_tipo_d}
                        placeholder="Ingrese Descripción Contratacion Secuencia Tipo"
                        helperText="Descripción Contratacion Secuencia Tipo"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                </FormTab>
            </TabbedForm>
        </Edit>
    )
};
export default ContratacionSecuenciaTipoEdit;


