import React from "react";

import {Show, TabbedForm, TextField} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const ProveedorClaseShow = props => {

    return (
        <Show {...props}>
            <TabbedForm>
                <TextField label="Proveedor" source="pro" sortable={false}/>
                <TextField label="Clase" source="clase" sortable={false}/>

            </TabbedForm>
        </Show>
    )
};
export default ProveedorClaseShow;


