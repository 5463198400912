import React, {useState} from 'react'
import {
    TextInput,
    Filter, FormTab, Loading, useQuery, ReferenceInput, AutocompleteInput, SelectInput
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


export const ProveedorPersonaExtranjera = (props) => {

    const useStyles = makeStyles({
        // Proveedor Persona extranjera
        pais: {display: 'inline-block', width: 300},
        identificacion_tributaria_tipo: {display: 'inline-block', width: 300},
        identificacion_tributaria_nro: {display: 'inline-block', width: 300}
    });

    const classes = useStyles();
    const isReadonly = props.readOnly;

    return (
        <div>
            <Typography variant="h6">Persona Extranjera</Typography>

            <ReferenceInput label="Pais Dom. Extranjero" source="pais_codigo_afip_extranjero" reference="pais"
                            fullWidth={true} InputLabelProps={{shrink: true}} variant="outlined" className={classes.pais}>
                <AutocompleteInput  InputLabelProps={{shrink: true}} optionText="pais" inputProps={{readOnly: isReadonly}}/>
            </ReferenceInput>

            {/*<TextInput source="PersonaExtranjera.identificacion_tributaria_pais_origen" label={"País Origen"} variant="outlined"
                       className={classes.identificacion_tributaria_pais_origen} fullWidth={true} InputLabelProps={{shrink: true}}/>*/}
            <br/>
            <TextInput source="PersonaExtranjera.identificacion_tributaria_tipo" label={"Tipo"} variant="outlined"
                       className={classes.identificacion_tributaria_tipo} fullWidth={true}
                       InputLabelProps={{shrink: true}} inputProps={{readOnly: isReadonly}}/>
            <br/>
            <TextInput source="PersonaExtranjera.identificacion_tributaria_nro" label={"Nro."} variant="outlined"
                       className={classes.identificacion_tributaria_nro} fullWidth={true}
                       InputLabelProps={{shrink: true}} inputProps={{readOnly: isReadonly}}/>
        </div>
    );
};

export default ProveedorPersonaExtranjera;



