import React, {useState, useCallback} from "react";

import {
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    DateInput,
    TopToolbar,
    Toolbar,
    SaveButton,
    useGetOne,
    NumberInput,
    Datagrid,
    TextField,
    NumberField,
    ReferenceManyField,
    SelectInput,
    useDataProvider,
    ReferenceInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import ComboMonedaActivo from "../componentes/ComboMonedaActivo";
import OrdenCompraDetalleInfo from "./OrdenCompraDetalleInfo";
import OrdenCompraDetalleButtonShow from "./OrdenCompraDetalleButtonShow";
import OrdenCompraDetalleButtonEdit from "./OrdenCompraDetalleButtonEdit"
import OrdenCompraDetalleButtonDelete from "./OrdenCompraDetalleButtonDelete"
import OrdenCompraDetalleButtonCreate from "./OrdenCompraDetalleButtonCreate"
import OrdenDeCompraProveedor from "./OrdenDeCompraProveedor";
import OrdenDeCompraContratacion from "./OrdenDeCompraContratacion";
import AutoridadesBusquedaBtn from "../Autoridades/AutoridadesBusquedaBtn";
import OrdenDeCompraEstado from "./OrdenDeCompraEstado";
import OrdenDeCompraDetalleProductos from "../OrdenCompraDetalle/OrdenDeCompraDetalleProductos";


const useStyles = makeStyles((theme) => ({

    d_pais: {display: 'inline-block', width: 300},

    combo: {display: 'inline-block', width: 300},
    procedimeinto_referencia_gde: {display: 'inline-block', width: 250, marginLeft: 32},


    title: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },

    es_ampliacion_inicio: {display: 'inline-block', width: 230, marginLeft: 32},


    pro: {display: 'inline-block', width: 200},

    text: {display: 'inline-block', width: 1200},
    btnBusqueda: {
        marginRight: '2rem',
        width: 80,
        display: 'inline-flex',
    },


}));

const OrdenCompraCabeceraEdit = props => {
    // const {form, change} = useForm();
    const [OrdenCompraCabecera, setOrdenCompraCabecera] = useState(props.id);
    const dataProvider = useDataProvider();
    const classes = useStyles();
    const [state, setState] = useState({});
    let w_esAmpliacion, w_OCorigenAmpliacionNro, w_OCorigenNro = '';
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [Moneda2, setMoneda2] = useState('');

    //
    // const updateProveedor = useCallback((who) => {
    //     change('razon_social', 'PEPE');
    // }, [form]);

    const updateProveedor = ''

    const limpiarValores = () => {
        setState(state => ({
            ...state,
            idContratacion: null,
            contratacionD: null,
            unidadNegocio: null,
            unidadAreaD: null,
            contratacionAno: null,
            contratacionNro: null,
            OCid: null,
            OCnro: null,
            OCfecha: null,
            OCano: null,
            OCorigenNro: null,
            OCorigenAmpliacionNro: null,
            OCampliacionNro: null,
            OCexpediente: null,
        }));
    }


    // const updateProveedor = useCallback(async (who) => {
    //     console.log("proveedor: ", who)
    //     setState(state => ({
    //         ...state,
    //         idPro: who.id,
    //         Pro: who.pro,
    //         RazonSocial: who.razon_social,
    //         Razon_social_fantasia: who.razon_social_fantasia,
    //         Documento_tipo: who.documento_tipo_d,
    //         Documento_nro: who.documento_nro,
    //         Cuit_cuil: who.cuit_cuil,
    //         Iva: who.d_iva,
    //         // Iva: who.id_iva,
    //         idPais: who.comercial_pais,
    //         Pais: who.d_pais_comercial ? who.d_pais_comercial.trim() : null,
    //         Provincia: who.provincia_comercial_d,
    //         Ciudad: who.ciudad_comercial_d,
    //         Domicilio_partido: who.comercial_partido,
    //         idCiudad: who.id_comercial_ciudad,
    //         idProvincia: who.id_comercial_ciudad,
    //         Domicilio_calle: who.comercial_calle,
    //         Domicilio_calle_nro: who.comercial_calle_nro,
    //         Domicilio_piso: who.comercial_piso,
    //         Domicilio_departamento: who.comercial_departamento,
    //         Domicilio_codigo_postal: who.comercial_codigo_postal,
    //         Contacto_correo_electronico: who.correo_electronico,
    //         Contacto_correo_electronico_alternativo: who.correo_electronico_alternativo,
    //         Telefono_area: who.telefono_area,
    //         Telefono_nro: who.telefono_nro,
    //         Telefono_alternativo_area: who.telefono_alternativo_area,
    //         Telefono_alternativo_nro: who.telefono_alternativo_nro,
    //         Celular_area: who.celular_area,
    //         Celular_nro: who.celular_nro,
    //         Fax_area: who.fax_area,
    //         Fax_nro: who.fax_nro,
    //         Codigo_pro_rna: who.codigo_pro_rna,
    //         Codigo_pro_tvpublica: who.codigo_pro_rna,
    //         Moneda: who.moneda,
    //         Moneda_cotizacion: who.moneda_cotizacion,
    //         Moneda_cotizacion_fecha: who.moneda_cotizacion_fecha,
    //     }));
    //
    // });


    const updateComercialPais = useCallback(async (who) => {
        setState(state => ({
            ...state,
            Pais: who.pais.trim(),
            idPais: who.pais.id
        }));
    });

    const updateProvinciaComercial = useCallback(async (who) => {
        setState(state => ({
            ...state,
            codProvinciaComercial: who.id,
            dProvinciaComercial: who.provincia.trim(),
        }));
    });

    const updateCiudadComercial = useCallback(async (who) => {
        setState(state => ({
            ...state,
            codCiudadComercial: who.id,
            dCiudadComercial: who.ciudad.trim(),
        }));
    });


    var w_emisora_d, w_gerencia_d, w_programa_d, w_sector_d;
    var w_emisora, w_gerencia, w_programa, w_sector, w_area;
    var w_contratacion_d, w_idContratacion, w_unidadNegocio, w_unidadAreaD, w_contratacionAno, w_contratacionNro;
    /// Búsqueda país
    var w_pais_comercial = '', w_persona_tipo = '';
    var w_d_pais_comercial = '';
    var w_provincia_comercial = '', w_d_provincia_comercial = '';
    var w_ciudad_comercial = '', w_d_ciudad_comercial = '';
    var w_provincia_jurisdiccion = '', w_d_provincia_jurisdiccion = '';
    var w_id_documentoTipo, w_d_documentoTipo = '';
    var w_domicilio_calle, w_domicilio_calle_nro, w_domicilio_piso = '';
    var w_domicilio_partido, w_domicilio_codigo_postal = '';
    var w_contacto_correo_electronico, w_contacto_correo_electronico_alternativo = '';

    var w_domicilio_calle, w_domicilio_calle_nro, w_domicilio_piso, w_domicilio_departamento = '';

    var w_contacto_telefono_area, w_contacto_telefono_nro, w_contacto_telefono_alternativo_area,
        w_contacto_telefono_alternativo_nro = '';
    var w_contacto_celular_area, w_contacto_celular_nro, w_contacto_fax_area, w_contacto_fax_nro = '';
    var w_codigo_pro_tvpublica, w_codigo_pro_rna = '';
    var w_moneda, w_moneda_cotizacion, w_moneda_cotizacion_fecha = '';
    var w_idPro, w_pro, w_razon_social, w_razon_social_fantasia, w_cuit_cuil, w_documento_tipo, w_documento_nro = '';
    var w_id_iva, w_id_pais, w_d_pais, w_id_provincia, w_id_ciudad, w_d_provincia, w_d_ciudad = '';
    var w_IdArea, w_OCexpediente = '';

    const {data: Data} = useGetOne('OrdenCompraCabecera', OrdenCompraCabecera);
    if (Data === undefined) {
    } else {
        console.log("Data: ", Data);
        w_esAmpliacion = Data.es_ampliacion ? Data.es_ampliacion : '';

        w_OCorigenNro = Data.orden_compra_origen_nro ? Data.orden_compra_origen_nro : '';
        w_OCorigenAmpliacionNro = Data.orden_compra_origen_ampliacion_nro;
        w_OCexpediente = Data.procedimeinto_referencia_gde;
        w_idContratacion = Data.id_contratacion_tipo;
        // w_idPro = Data.id_proveedor = Data.id_proveedor ?  Data.id_proveedor  '';
        // w_pais_comercial = Data.domicilio_pais === undefined ? 0 : Data.domicilio_pais;
        // w_provincia_comercial = Data.Provincia ? Data.domicilio_provincia : null;
        // w_ciudad_comercial = Data.CiudadComercial.id ? Data.CiudadComercial.id : null;
        // w_d_pais_comercial = Data.PaisComercial.pais === undefined ? '' : Data.PaisComercial.pais.trim();
        // w_d_provincia_comercial = Data.ProvinciaComercial.provincia ? Data.ProvinciaComercial.provincia.trim() : '';
        // w_d_ciudad_comercial = Data.CiudadComercial.ciudad ? Data.CiudadComercial.ciudad.trim() : '';

        w_domicilio_calle = Data.domicilio_calle ? Data.domicilio_calle : null;
        w_domicilio_calle_nro = Data.domicilio_calle_nro ? Data.domicilio_calle_nro : null;
        w_domicilio_piso = Data.domicilio_piso ? Data.domicilio_piso : null;
        w_domicilio_partido = Data.domicilio_partido ? Data.domicilio_partido : null;
        w_domicilio_departamento = Data.domicilio_departamento ? Data.domicilio_departamento : null;
        w_domicilio_codigo_postal = Data.domicilio_codigo_postal ? Data.domicilio_codigo_postal : null;
        w_contacto_correo_electronico = Data.contacto_correo_electronico ? Data.contacto_correo_electronico : null;
        w_contacto_correo_electronico_alternativo = Data.contacto_correo_electronico_alternativo ? Data.contacto_correo_electronico_alternativo : null;
        w_contacto_telefono_area = Data.contacto_telefono_area ? Data.contacto_telefono_area : null;
        w_contacto_telefono_nro = Data.contacto_telefono_nro ? Data.contacto_telefono_nro : null;
        w_contacto_telefono_alternativo_area = Data.contacto_telefono_alternativo_area ? Data.contacto_telefono_alternativo_area : null;
        w_contacto_telefono_alternativo_nro = Data.contacto_telefono_alternativo_nro ? Data.contacto_telefono_alternativo_nro : null;
        w_contacto_celular_area = Data.contacto_celular_area ? Data.contacto_celular_area : null;
        w_contacto_celular_nro = Data.contacto_celular_nro ? Data.contacto_celular_nro : null;
        w_contacto_fax_area = Data.contacto_fax_area ? Data.contacto_fax_area : null;
        w_contacto_fax_nro = Data.contacto_fax_nro ? Data.contacto_fax_nro : null;

        w_codigo_pro_tvpublica = Data.codigo_pro_tvpublica ? Data.codigo_pro_tvpublica : null;
        w_codigo_pro_rna = Data.codigo_pro_rna ? Data.codigo_pro_rna : null;

        w_moneda = Data.moneda ? Data.moneda : null;
        w_moneda_cotizacion = Data.moneda_cotizacion ? Data.moneda_cotizacion : null;
        w_moneda_cotizacion_fecha = Data.moneda_cotizacion_fecha ? Data.moneda_cotizacion_fecha : null;

        w_IdArea = Data.id_unidad_area ? Data.id_unidad_area : null;

        if (Data.Proveedor === undefined || Data.Proveedor === null) {
        } else {
            console.log("Proveedor", Data.Proveedor)
            // w_idPro = Data.Proveedor.id ? Data.Proveedor.id : null;
            w_pro = Data.Proveedor.pro ? Data.Proveedor.pro : null;
            w_razon_social = Data.Proveedor.razon_social ? Data.Proveedor.razon_social : null;
            w_razon_social_fantasia = Data.Proveedor.razon_social_fantasia ? Data.Proveedor.razon_social_fantasia : null;
            w_cuit_cuil = Data.Proveedor.cuit_cuil ? Data.Proveedor.cuit_cuil : null;
            w_id_iva = Data.Proveedor.id_iva ? Data.Proveedor.id_iva : null;

            w_id_pais = Data.Proveedor.id_pais ? Data.Proveedor.id_pais : null;
            w_d_pais = Data.Proveedor.d_pais_comercial ? Data.Proveedor.d_pais_comercial.trim() : null;

            w_id_provincia = Data.Proveedor.id_provincia ? Data.Proveedor.id_provincia : null;
            w_id_ciudad = Data.Proveedor.id_ciudad ? Data.Proveedor.id_ciudad : null;

            w_documento_tipo = Data.Proveedor.documento_tipo ? Data.Proveedor.documento_tipo : null;
            w_documento_nro = Data.Proveedor.documento_nro ? Data.Proveedor.documento_nro : null;
        }

        /* if (Data.Emisora === undefined || Data.Emisora === null) {
         } else {
             w_emisora_d = Data.Emisora.emisora_d ? Data.Emisora.emisora_d : null;
             w_emisora = Data.Emisora.emisora ? Data.Emisora.emisora : null;
         }

         if (Data.Gerencia === undefined || Data.Gerencia === null) {
         } else {
             w_gerencia_d = Data.Gerencia.gerencia_d ? Data.Gerencia.gerencia_d : null;
             w_gerencia = Data.Gerencia.gerencia ? Data.Gerencia.gerencia : null;
         }

         if (Data.Programa === undefined || Data.Programa === null) {
         } else {
             w_programa_d = Data.Programa.programa_d ? Data.Programa.programa_d : null;
             w_programa = Data.Programa.programa ? Data.Programa.programa : null;
         }

         if (Data.Sector === undefined || Data.Sector === null) {
         } else {
             w_sector_d = Data.Sector.sector_d ? Data.Sector.sector_d : null;
             w_sector = Data.Sector.sector ? Data.Sector.sector : null;
         }*/

        if (Data.ContratacionTipo === undefined || Data.ContratacionTipo === null) {
        } else {
            // console.log("ContratacionTipo ", Data.ContratacionTipo)
            w_contratacion_d = Data.ContratacionTipo.contratacion_d ? Data.ContratacionTipo.contratacion_d : null;
        }

        if (Data.Area === undefined || Data.Area === null) {
        } else {
            w_IdArea = Data.Area.id ? Data.Area.id : null;
            w_unidadAreaD = Data.Area.area_d ? Data.Area.area_d : null;
            w_area = Data.Area.area ? Data.Area.area : null;
        }

        if (Data.ContratacionEncuadre === undefined || Data.ContratacionEncuadre === null) {
        } else {
            // console.log("Encuadre ", Data.ContratacionEncuadre)
            w_idContratacion = Data.ContratacionEncuadre.id ? Data.ContratacionEncuadre.id : null;
            w_contratacionAno = Data.ContratacionEncuadre.contratacion_ano ? Data.ContratacionEncuadre.contratacion_ano : null;
            w_contratacionNro = Data.ContratacionEncuadre.id ? Data.ContratacionEncuadre.id : null;
            w_unidadNegocio = Data.ContratacionEncuadre.unidad_negocio_requiere ? Data.ContratacionEncuadre.unidad_negocio_requiere : null;
            // w_unidadAreaD = Data.ContratacionEncuadre.unidad_area_requiere ? Data.ContratacionEncuadre.unidad_area_requiere : null;
        }


        if (Data.ProvinciaJurisdiccion === undefined || Data.ProvinciaJurisdiccion === null) {
        } else {
            w_provincia_jurisdiccion = Data.ProvinciaJurisdiccion.id ? Data.ProvinciaJurisdiccion.id : null;
            w_d_provincia_jurisdiccion = Data.ProvinciaJurisdiccion.provincia ? Data.ProvinciaJurisdiccion.provincia.trim() : '';
        }

        /*  if (Data.DocumentoTipo === undefined || Data.DocumentoTipo === null) {
          } else {
              w_id_documentoTipo = Data.DocumentoTipo.id ? Data.DocumentoTipo.id : null;
              w_d_documentoTipo = Data.DocumentoTipo.documento_tipo_d ? Data.DocumentoTipo.documento_tipo_d.trim() : '';
          }*/

        // if (Data.Pais === undefined || Data.Pais === null) {
        // } else {
        //     // w_pais = Data.PaisLegal.pais_codigo_afip === undefined ? 0 : Data.PaisLegal.pais_codigo_afip;
        //     w_d_pais = Data.Pais.pais === undefined ? '' : Data.Pais.pais.trim();
        // }

        if (Data.Provincia === undefined || Data.Provincia === null) {
        } else {
            w_d_provincia = Data.Provincia.provincia ? Data.Provincia.provincia.trim() : '';
        }
        if (Data.Ciudad === undefined || Data.Ciudad === null) {
        } else {
            w_d_ciudad = Data.Ciudad.ciudad ? Data.Ciudad.ciudad.trim() : '';
        }
    }

    const {
        /*  emisora_d = w_emisora_d,
          gerencia_d = w_gerencia_d,
          programa_d = w_programa_d,
          sector_d = w_sector_d,

          emisora = w_emisora,
          gerencia = w_gerencia,
          programa = w_programa,
          sector = w_sector,*/
        area = w_area,

        contratacionD = w_contratacion_d,
        idContratacion = w_idContratacion,
        unidadNegocio = w_unidadNegocio,
        unidadAreaD = w_unidadAreaD,
        contratacionAno = w_contratacionAno,
        contratacionNro = w_contratacionNro,
        OCorigenAmpliacionNro = w_OCorigenAmpliacionNro,
        OCorigenNro = w_OCorigenNro,
        OCid, OCnro, OCfecha, OCano, OCampliacionNro,
        OCexpediente = w_OCexpediente,
        idUPrograma, idUGerencia, idUSector, idUEmisora,
        idPro = w_idPro,
        Pro = w_pro,
        RazonSocial = w_razon_social,
        Razon_social_fantasia = w_razon_social_fantasia,
        Cuit_cuil = w_cuit_cuil,
        idDocumentoTipo = w_id_documentoTipo,
        Documento_tipo = w_d_documentoTipo,
        Documento_nro = w_documento_nro,
        Iva = w_id_iva,
        codProvinciaComercial = w_provincia_comercial,
        dProvinciaComercial = w_d_provincia_comercial,
        codCiudadComercial = w_ciudad_comercial,
        dCiudadComercial = w_d_ciudad_comercial,
        Domicilio_partido = w_domicilio_partido,
        Domicilio_calle = w_domicilio_calle,
        Domicilio_calle_nro = w_domicilio_calle_nro,
        Domicilio_piso = w_domicilio_piso,
        Domicilio_departamento = w_domicilio_departamento,
        Domicilio_codigo_postal = w_domicilio_codigo_postal,
        Contacto_correo_electronico = w_contacto_correo_electronico,
        Contacto_correo_electronico_alternativo = w_contacto_correo_electronico_alternativo,
        Telefono_area = w_contacto_telefono_area,
        Telefono_nro = w_contacto_telefono_nro,
        Telefono_alternativo_area = w_contacto_telefono_alternativo_area,
        Telefono_alternativo_nro = w_contacto_telefono_alternativo_nro,
        Celular_area = w_contacto_celular_area,
        Celular_nro = w_contacto_celular_nro,
        Fax_area = w_contacto_fax_area,
        Fax_nro = w_contacto_fax_nro,
        Codigo_pro_rna = w_codigo_pro_rna,
        Codigo_pro_tvpublica = w_codigo_pro_tvpublica,
        Moneda = w_moneda,
        Moneda_cotizacion = w_moneda_cotizacion,
        Moneda_cotizacion_fecha = w_moneda_cotizacion_fecha,
        Pais = w_d_pais,
        idPais = w_id_pais,
        idProvincia = w_id_provincia,
        idCiudad = w_id_ciudad,
        Provincia = w_d_provincia,
        Ciudad = w_d_ciudad,
        idArea = w_IdArea,
    }
        = state;

    const formatProvincia = v => {
        return Provincia
    };

    const formatCiudad = v => {
        return Ciudad
    };


    const formatPais = v => {
        return Pais
    };

    const formatMoneda = v => {
        return Moneda
    };
    const formatMoneda_cotizacion = v => {
        return Moneda_cotizacion
    };
    const formatMoneda_cotizacion_fecha = v => {
        return Moneda_cotizacion_fecha
    };


    const formatidPro = v => {
        return idPro
    };
    const formatPro = v => {
        return Pro
    };
    const formatRazonSocial = v => {
        return RazonSocial
    };
    const formatRazon_social_fantasia = v => {
        return Razon_social_fantasia
    };
    const formatDocumento_tipo = v => {
        return Documento_tipo
    };

    const formatDocumento_nro = v => {
        return Documento_nro
    };
    const formatCuit_cuil = v => {
        return Cuit_cuil
    };
    const formatIva = v => {
        return Iva
    };

    const formatComercial_provincia = v => {
        return dProvinciaComercial
    };

    const formatComercial_ciudad = v => {
        return dCiudadComercial
    };
    const formatDomicilio_partido = v => {
        return Domicilio_partido
    };

    const parseDomicilio_calle = value => {
        //value is a string of "YYYY-MM-DD" format
        const match = value;
        // if (match === null) return;
        // const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
        // if (isNaN(d.getDate())) return;
        return match;
    };


    const formatDomicilio_calle = v => {
        return Domicilio_calle
    };


    const formatDomicilio_calle_nro = v => {
        return Domicilio_calle_nro
    };
    const formatDomicilio_piso = v => {
        return Domicilio_piso
    };
    const formatDomicilio_departamento = v => {
        return Domicilio_departamento
    };
    const formatDomicilio_codigo_postal = v => {
        return Domicilio_codigo_postal
    };
    const formatContacto_correo_electronico = v => {
        return Contacto_correo_electronico
    };
    const formatContacto_correo_electronico_alternativo = v => {
        return Contacto_correo_electronico
    };
    const formatTelefono_area = v => {
        return Telefono_area
    };
    const formatTelefono_nro = v => {
        return Telefono_nro
    };
    const formatTelefono_alternativo_area = v => {
        return Telefono_alternativo_area
    };
    const formatTelefono_alternativo_nro = v => {
        return Telefono_alternativo_nro
    };
    const formatCelular_area = v => {
        return Celular_area
    };
    const formatCelular_nro = v => {
        return Celular_nro
    };
    const formatFax_area = v => {
        return Fax_area
    };
    const formatFax_nro = v => {
        return Fax_nro
    };
    const formatCodigo_pro_rna = v => {
        return Codigo_pro_rna
    };
    const formatCodigo_pro_tvpublica = v => {
        return Codigo_pro_tvpublica
    };

    /*
        const formatEmisora_d = v => {
            return emisora_d
        };
        const formatGerencia_d = v => {
            return gerencia_d
        };
        const formatPrograma_d = v => {
            return programa_d
        };
        const formatSector_d = v => {
            return sector_d
        };
    */

    const formatOCid = v => {
        return OCid
    };
    const formatOCnro = v => {
        return OCnro
    };
    const formatOCfecha = v => {
        return OCfecha
    };
    const formatOCano = v => {
        return OCano
    };
    const formatOCorigenAmpliacionNro = v => {
        return OCorigenAmpliacionNro
    };
    const formatOCampliacionNro = v => {
        return OCampliacionNro
    };

    const formatOCorigenNro = v => {
        return OCorigenNro
    };
    const formatOCexpediente = v => {
        return OCexpediente
    };
    const formatContratacionD = v => {
        return contratacionD
    };
    const formatUnidadNegocio = v => {
        return unidadNegocio
    };
    const formatUnidadAreaD = v => {
        return unidadAreaD
    };
    const formatContratacionAno = v => {
        return contratacionAno
    };
    const formatContratacionNro = v => {
        return contratacionNro
    };

    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>

        </TopToolbar>
    );

    const redirect = (basePath, id, data) => `/OrdenCompraCabecera/`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            {/*<DeleteButton undoable={false} redirect={redirect}/>*/}
        </Toolbar>
    );

    const transform = data => {
        let w_idUPrograma = idUPrograma;
        if (idUPrograma > 0) {
        } else (w_idUPrograma = 0);

        let w_idUGerencia = idUGerencia;
        if (idUGerencia > 0) {
        } else (w_idUGerencia = 0);

        let w_idContratacion = idContratacion;
        if (idContratacion > 0) {
        } else (w_idContratacion = 0);

        let w_idUSector = idUSector;
        if (idUSector > 0) {
        } else (w_idUSector = 0);

        let w_idUEmisora = idUEmisora;
        if (idUEmisora > 0) {
        } else (w_idUEmisora = 0);

        let w_OCid = OCid;
        if (OCid > 0) {
        } else (w_OCid = 0);

        let w_OCnro = OCnro;
        if (OCnro > 0) {
        } else (w_OCnro = 0);

        let w_idPro = idPro;
        if (idPro > 0) {
        } else (w_idPro = null);

        return {
            ...data,
            w_idUPrograma,
            w_idUGerencia,
            w_idUEmisora,
            w_idContratacion,
            w_idUSector,
            w_OCid,
            w_OCnro,
            /*
            area,
            sector,
             programa,
             gerencia,
             emisora,*/
            unidadNegocio,
            unidadAreaD,

            w_idPro,

            idArea, //>> Falta en controlador
            idPais,
            codProvinciaComercial,
            codCiudadComercial,
            Domicilio_calle,
            Domicilio_calle_nro,
            Domicilio_piso,
            Domicilio_departamento,
            Domicilio_codigo_postal,
        }
    };

    const PostTitle = ({record}) => {
        if (record.orden_compra_ampliacion_nro != null)
            return <span>Nro. Orden de Compra: {record ? `${record.orden_compra_nro}` + '/' + `${record.orden_compra_ampliacion_nro}` : ''}</span>;
        else
            return <span>Nro. Orden de Compra: {record ? `${record.orden_compra_nro}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm
                // initialValues={postDefaultValue}
                redirect={redirect}
                toolbar={<CustomToolbar/>}
            >
                {/*Datos de la Cabecera*/}
                <FormTab label="ORDEN DE COMPRA">

                    <OrdenDeCompraContratacion esAmpliacion={w_esAmpliacion}/>

                </FormTab>

                {/*Datos del Proveedor*/}
                <FormTab label="DATOS DEL PROVEEDOR">

                    <OrdenDeCompraProveedor/>

                </FormTab>
                {/* detalle de Productos /Servicios*/}
                <FormTab label="DETALLE DE PRODUCTOS/SERVICIOS">

                    {Data &&
                    <OrdenDeCompraDetalleProductos ParamData={Data} />
                    }

                </FormTab>
                {/*Leyendas*/}
                <FormTab label="LEYENDAS">

                    <TextInput multiline source="leyenda_cabecera" className={classes.text}
                               InputLabelProps={{shrink: true}}
                               label="Leyenda Cabecera" variant="outlined" fullWidth/>

                    <TextInput multiline source="leyenda_detalle" formClassName={classes.text} label="Leyenda Detalle"
                               InputLabelProps={{shrink: true}} variant="outlined"
                               fullWidth/>

                    <TextInput multiline source="leyenda_pie" formClassName={classes.text} label="Leyenda Pie"
                               InputLabelProps={{shrink: true}} variant="outlined"
                               fullWidth/>

                    <TextInput multiline source="leyenda_seguro" formClassName={classes.text} label="Leyenda Seguro"
                               InputLabelProps={{shrink: true}} variant="outlined"
                               fullWidth/>

                    <TextInput multiline source="leyenda_condicion_pago" formClassName={classes.text} fullWidth
                               InputLabelProps={{shrink: true}} variant="outlined"
                               label="Leyenda Condición Pago"/>

                    <TextInput multiline source="leyenda_condicion_pago_moneda_extranjera" variant="outlined"
                               label="Leyenda Condición Pago Moneda Extranjera" formClassName={classes.text}
                               InputLabelProps={{shrink: true}} fullWidth/>

                    <TextInput multiline source="leyenda_lugar_ejecucion" label="Leyenda Lugar Ejecución"
                               InputLabelProps={{shrink: true}} fullWidth variant="outlined"
                               formClassName={classes.text}/>

                    <TextInput multiline source="leyenda_condicion_entrega_colocacion" fullWidth
                               InputLabelProps={{shrink: true}} variant="outlined"
                               label="Leyenda Condición Entrega Colocación" formClassName={classes.text}/>

                </FormTab>
                {/*Estado*/}
                <FormTab label="ESTADO">

                    <OrdenDeCompraEstado/>

                </FormTab>
            </TabbedForm>
        </Edit>
    )
}

export default OrdenCompraCabeceraEdit;


