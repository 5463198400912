import {SelectInput, ReferenceInput, TextInput} from 'react-admin';
import React from "react";

const EstadoProveedorCombo = (props) => {
    const isReadonly = props.readOnly;
    return (
        <ReferenceInput label="Estado Proveedor" source="id_estado_proveedor" reference="estadoProveedor" variant="outlined">
            <SelectInput  InputLabelProps={{shrink: true}} optionText="estado_proveedor_abre"
                          inputProps={{readOnly: isReadonly}}/>
        </ReferenceInput>
    )
};

export default EstadoProveedorCombo;
