import React, {useCallback,  useEffect, useState} from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
    translate,
    Link,
    useVersion,
    useDataProvider,
    useNotify,
    useRedirect, UPDATE, useMutation, useRefresh, Button, useUnselectAll, WithPermissions
} from 'react-admin';
import {useQuery, Loading, Error } from 'react-admin';
import {makeStyles, withStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ReplayIcon from "@material-ui/icons/Replay";
import FilterListIcon from "@material-ui/icons/FilterList";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button2 from '@material-ui/core/Button';
import sliderPrincipal from "../layout/headerRTA.jpg";
import CropPortraitIcon from "@material-ui/icons/CropPortrait";
import CompareIcon from "@material-ui/icons/Compare";
import TabletIcon from "@material-ui/icons/Tablet";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import ShopIcon from '@material-ui/icons/Shop';
import PaymentIcon from "@material-ui/icons/Payment";
import SupervisedUserCircleTwoToneIcon from "@material-ui/icons/SupervisedUserCircleTwoTone";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CommentIcon from "@material-ui/icons/Comment";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import PersonIcon from "@material-ui/icons/Person";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SwitchCameraIcon from "@material-ui/icons/SwitchCamera";
import VignetteIcon from "@material-ui/icons/Vignette";
import VisibilityIcon from "@material-ui/icons/Visibility";
import cartuchonewlargo from "../TableroControl/cartuchonewlargo.png";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import HistoryIcon from "@material-ui/icons/History";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import Avatar from "@material-ui/core/Avatar";
import Imagepvm from "../layout/tvpublica_logo_2021.bmp";
import IconButton from "@material-ui/core/IconButton";
import BackupIcon from "@material-ui/icons/Backup";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import UndoIcon from "@material-ui/icons/Undo";
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Imagerna from "../layout/Radio_Nacional_Logo_2021.jpg";
import PolicyIcon from "@material-ui/icons/Policy";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TableroControlContratacionDetalleAgrupadoButton from "./TableroControlContratacionDetalleAgrupadoButton";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from "@material-ui/core/InputBase";

const mediaUrl = sliderPrincipal;
// const useStyles = makeStyles({
//     icon: {paddingRight: '0.5em'},
//     link: {
//         display: 'inline-flex',
//         alignItems: 'center',
//     },
// });

const styles = {
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    cardleft: {
        overflow: 'inherit',
        textAlign: 'left',
        padding: 16,
        minHeight: 52,
    },
    button1: {
        fontWeight: 'bold',
        // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
        '& svg': {color: 'orange', backgroundColor: 'red'}
    },
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
    flexHighcharts: {height: 400, width: 400},
};

const useStyles = makeStyles((theme) => ({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    root: {
        width: 'fit-content',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        '& svg': {
            margin: theme.spacing(1.5),
        },
        '& hr': {
            margin: theme.spacing(0, 0.5),
        },
    },
    root2: {
        maxWidth: 345,
    },
    media2: {
        height: 140,
    },
    table: {
        minWidth: 10,
    },
    textright:{
        textAlign: 'right',
    },
    textleft:{
        textAlign: 'left',
    },
    textcenter:{
        textAlign: 'center',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
}));

const role_con_tab_ver_graf_uni_neg_inv = ({permissions}) => {
    var w_role_con_tab_ver_graf_uni_neg_inv = ''
    var w_permissions_con_tab_ver_graf_uni_neg_inv = permissions
    if(w_permissions_con_tab_ver_graf_uni_neg_inv==null){}else{
        var w_role_con_tab_ver_graf_uni_neg_inv_aux = w_permissions_con_tab_ver_graf_uni_neg_inv.indexOf('ROLE_CON_TAB_VER_GRAF_UNI_NEG_INV')
        if(w_role_con_tab_ver_graf_uni_neg_inv_aux===-1){}else{
            w_role_con_tab_ver_graf_uni_neg_inv = 'ROLE_CON_TAB_VER_GRAF_UNI_NEG_INV'
        }
    }
    return w_role_con_tab_ver_graf_uni_neg_inv
}

const role_con_tab_ver_graf_uni_area_inv = ({permissions}) => {
    var w_role_con_tab_ver_graf_uni_area_inv = ''
    var w_permissions_con_tab_ver_graf_uni_area_inv = permissions
    if(w_permissions_con_tab_ver_graf_uni_area_inv==null){}else{
        var w_role_con_tab_ver_graf_uni_area_inv_aux = w_permissions_con_tab_ver_graf_uni_area_inv.indexOf('ROLE_CON_TAB_VER_GRAF_UNI_AREA_INV')
        if(w_role_con_tab_ver_graf_uni_area_inv_aux===-1){}else{
            w_role_con_tab_ver_graf_uni_area_inv = 'ROLE_CON_TAB_VER_GRAF_UNI_AREA_INV'
        }
    }
    return w_role_con_tab_ver_graf_uni_area_inv
}

const TableroControlContratacionGraficos = props => {

    const classes = useStyles()
    var w_valor = props.w_valor
    var w_estado = props.w_estado

    //
    // console.log('props', props)
    // console.log('W_VALOR', w_valor)
    // /// w_estado es el nuevo estado del padre ------------


    var InicioProList1

    const [state, setState] = useState({});

    var w_anoactual = new Date().getFullYear()
    let wf_anoactual = w_anoactual.toString();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirectTo = useRedirect();
    const refresh = useRefresh();
    const [EstadoBoton, setEstadoBoton] = useState(1);
    const [errorGrafico, setErrorGrafico] = useState();
    const [loadingGrafico, setLoadingGrafico] = useState(true);
    const [UNGrafico, setUNGrafico] = useState([]);
    const [TEGrafico, setTEGrafico] = useState([]);
    const [EGrafico, setEGrafico] = useState([]);
    const [AGrafico, setAGrafico] = useState([]);
    const [SGrafico, setSGrafico] = useState([]);

    const [cantidad_unidad_negocioGrafico, setcantidad_unidad_negocioGrafico] = useState(0);
    const [cantidad_unidad_areaGrafico, setcantidad_unidad_areaGrafico] = useState(0);
    const [cantidad_contratacion_tipoGrafico, setcantidad_contratacion_tipoGrafico] = useState(0);
    const [cantidad_estadoGrafico, setcantidad_estadoGrafico] = useState(0);


    const [vUNGrafico, setvUNGrafico] = useState('');
    const [vTEGrafico, setvTEGrafico] = useState('');
    const [vEGrafico,  setvEGrafico] = useState('');
    const [vAGrafico,  setvAGrafico] = useState('');
    const [EstadoFecha, setEstadoFecha] = useState('')
    const [IDUNGrafico, setIDUNGrafico] = useState(0);
    const [IDTEGrafico, setIDTEGrafico] = useState(0);
    const [IDEGrafico,  setIDEGrafico]  = useState(0);
    const [IDAGrafico, setIDAGrafico] = useState(0);

    const unselectAll = useUnselectAll();
    const [udntableroTableroControl, setUDNTableroTableroControl] = useState([]);

    useEffect(() => {
        dataProvider.getList(
            'TableroControlContratacionGrafico',
            {
                filter: {
                    tipografico_ing: 'TODOS', ano_ing: '', unidad_negocio_ing2: props.FiltroUN,
                    unidad_negocio_ingv: vUNGrafico, area_ing: vAGrafico,
                    tipos_de_encuadre_ing: vTEGrafico, estado_ing: vEGrafico, fecha_ing: EstadoFecha
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                console.log('data--------setcantidad')
                console.log(data)
                console.log(data[0].cantidad_unidad_negocio);
                console.log(data[0].cantidad_unidad_area);
                console.log(data[0].cantidad_contratacion_tipo);
                console.log(data[0].cantidad_estado);
                setcantidad_unidad_negocioGrafico(data[0].cantidad_unidad_negocio);
                setcantidad_unidad_areaGrafico(data[0].cantidad_unidad_area);
                setcantidad_contratacion_tipoGrafico(data[0].cantidad_contratacion_tipo);
                setcantidad_estadoGrafico(data[0].cantidad_estado);


                setLoadingGrafico(false);
            })
            .catch(error => {
                setErrorGrafico(error);
                setLoadingGrafico(false);

            })
        dataProvider.getList(
            'TableroControlContratacionGrafico',
            {
                filter: {
                    tipografico_ing: 'UN', ano_ing: '', unidad_negocio_ing2: props.FiltroUN,
                    unidad_negocio_ingv: vUNGrafico, area_ing: vAGrafico,
                    tipos_de_encuadre_ing: vTEGrafico, estado_ing: vEGrafico, fecha_ing: EstadoFecha
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setUNGrafico(data);
                setLoadingGrafico(false);
            })
            .catch(error => {
                setErrorGrafico(error);
                setLoadingGrafico(false);

            })

        dataProvider.getList(
            'TableroControlContratacionGrafico',
            {
                filter: {
                    tipografico_ing: 'TE', ano_ing: '', unidad_negocio_ing2: props.FiltroUN,
                    unidad_negocio_ingv: vUNGrafico, area_ing: vAGrafico,
                    tipos_de_encuadre_ing: vTEGrafico, estado_ing: vEGrafico, fecha_ing: EstadoFecha
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setTEGrafico(data);
                setLoadingGrafico(false);
            })
            .catch(error => {
                setErrorGrafico(error);
                setLoadingGrafico(false);
            })
        dataProvider.getList(
            'TableroControlContratacionGrafico',
            {
                filter: {
                    tipografico_ing: 'E', ano_ing: '', unidad_negocio_ing2: props.FiltroUN,
                    unidad_negocio_ingv: vUNGrafico, area_ing: vAGrafico,
                    tipos_de_encuadre_ing: vTEGrafico, estado_ing: vEGrafico, fecha_ing: EstadoFecha
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setEGrafico(data);
                setLoadingGrafico(false);
            })
            .catch(error => {
                setErrorGrafico(error);
                setLoadingGrafico(false);
            })
        dataProvider.getList(
            'TableroControlContratacionGrafico',
            {
                filter: {
                    tipografico_ing: 'A', ano_ing: '', unidad_negocio_ing2: props.FiltroUN,
                    unidad_negocio_ingv: vUNGrafico, area_ing: vAGrafico,
                    tipos_de_encuadre_ing: vTEGrafico, estado_ing: vEGrafico, fecha_ing: EstadoFecha
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setAGrafico(data);
                setLoadingGrafico(false);
            })
            .catch(error => {
                setErrorGrafico(error);
                setLoadingGrafico(false);
            })
        dataProvider.getList(
            'UnidadNegocio',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'F' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setUDNTableroTableroControl(data);
                setLoadingGrafico(false);
            })
            .catch(error => {
                setErrorGrafico(error);
                setLoadingGrafico(false);

            })

    }, [EstadoBoton]);


//----------------- chequea datos estado del componente -----------------------------


// if (loading) return <Loading loadingPrimary=" " loadingSecondary="Actualizando" />      // no cargo todavia
    if (loadingGrafico) return (
        <div>
            <div>
                <CircularProgress/>
            </div>
            <div>
                {' Actualizando.'}
            </div>
        </div>
    )
    if (!UNGrafico) return null   ;           // no cargo todavia


    if (errorGrafico) {
        console.log('error')
        // eslint-disable-next-line no-undef
        console.log(errorGrafico)
        return <Error/>;                           // error
    };

    const cambiarestadoboton = () => {
        setEstadoBoton(EstadoBoton + 1);
    }

    const handleClickBotonUNFiltro = () => {
        // setEstadoFecha('')
        setvUNGrafico('')
        // setvTEGrafico('')
        // setvEGrafico('')
        // setvAGrafico('')
        setIDUNGrafico(0)
        // setIDTEGrafico(0)
        // setIDEGrafico(0)
        // setIDAGrafico(0)
        cambiarestadoboton()
        unselectAll();
        var w_redirectToun = ``
        var w_redirectTote = ``
        var w_redirectToe = ``
        var w_redirectTof = ``
        var w_redirectToa = ``
        var w_IDUNGrafico = IDUNGrafico
        if(w_IDUNGrafico===0){}else{
            w_redirectToun = `%2C"id_unidad_negocio"%3A"${w_IDUNGrafico}"`
        }
        var w_IDAGrafico = IDAGrafico
        if(w_IDAGrafico===0){}else{
            w_redirectToa = `%2C"id_unidad_area"%3A"${w_IDAGrafico}"`
        }
        var w_IDTEGrafico = IDTEGrafico
        if(w_IDTEGrafico===0){}else{
            w_redirectTote = `%2C"id_contratacion_tipo"%3A"${w_IDTEGrafico}"`
        }
        var w_IDEGrafico = IDEGrafico
        if(w_IDEGrafico===0){}else{
            w_redirectToe = `%2C"id_estado_encuadre"%3A"${w_IDEGrafico}"`
        }

        var w_FechaGrafico = EstadoFecha
        if(w_FechaGrafico==='0000'){}else{
            w_redirectTof = `%2C"contratacion_ano"%3A"${w_FechaGrafico}"`
        }

        redirectTo(`TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0${w_redirectToa}${w_redirectToe}${w_redirectTof}${w_redirectToun}${w_redirectTote}%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);

        // redirectTo(`/TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);
        refresh()
        notify('Procesando Borrar Filtros' , 'info', {}, false);
        // redirectTo(`/TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);
        refresh()
        unselectAll();
    };

    const handleClickBotonTodoslosFiltros = () => {
        setEstadoFecha(wf_anoactual)
        setvUNGrafico('')
        setvTEGrafico('')
        setvEGrafico('')
        setvAGrafico('')
        setIDUNGrafico(0)
        setIDTEGrafico(0)
        setIDEGrafico(0)
        setIDAGrafico(0)
        cambiarestadoboton()
        unselectAll();
        redirectTo(`/TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0%2C"contratacion_ano"%3A"${wf_anoactual}%20%20%20%20%20%20"%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);
        refresh()
        notify('Procesando Borrar Filtros' , 'info', {}, false);
        redirectTo(`/TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0%2C"contratacion_ano"%3A"${wf_anoactual}%20%20%20%20%20%20"%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);
        refresh()
        unselectAll();
    };

    //----------------- grafico por unidades de negocio -----------------------------
    const optionsUN = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            options3d: {
                enabled: true,
                alpha: 45
            }
        },
        title: {
            text: 'Unidad<br>de Negocio<br>',
            align: 'center',
            verticalAlign: 'middle',
            //y: 60
        },
        credits: {
            enabled: false
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> $: <b>{point.signo}{point.y:.,2f}</b><br/> <b>{point.percentage:.2f}%</b><br/> <b>Cantidad: {point.cantidad}</b><br/>'
        },
        lang: {
            decimalPoint: ',',
            thousandsSep: '.'
        },
        plotOptions: {
            pie: {
                // showInLegend: true,
                dataLabels: {
                    enabled: true,
                    // distance: -50,
                    format: '<span style="color:{point.color}">\u25CF</span><b>{point.name}</b> en $: {point.percentage:.2f}% ',
                    style: {
                        fontWeight: 'bold',
                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'

                    }
                },
                // startAngle: -90,
                // endAngle: 90,
                // center: ['50%', '75%'],
                // size: '110%',
                events: {
                    click: function (event) {
                        console.log(event)
                        // alert(event.point.name)
                        var w_tipo_sociedad = event.point.name_c
                        if (w_tipo_sociedad == null) {
                            w_tipo_sociedad = ''
                        }
                        w_tipo_sociedad = w_tipo_sociedad.trim()

                        var w_name_id = event.point.name_id

                        var w_d_tipo_sociedad = event.point.name_d
                        if (w_d_tipo_sociedad == null) {
                            w_d_tipo_sociedad = ''
                        }
                        w_d_tipo_sociedad = w_d_tipo_sociedad.trim()

                        var w_tot_sociedad = event.point.data

                        console.log('w_tipo_sociedad')
                        console.log(w_tipo_sociedad)
                        console.log('w_d_tipo_sociedad')
                        console.log(w_d_tipo_sociedad)
                        setvUNGrafico(w_tipo_sociedad)
                        setIDUNGrafico(w_name_id)
                        setEstadoBoton(EstadoBoton + 1);

                        refresh()

                        var w_redirectToun = ``
                        var w_redirectTote = ``
                        var w_redirectToe = ``
                        var w_redirectTof = ``
                        var w_redirectToa = ``
                        var w_IDUNGrafico = w_name_id
                        if(w_IDUNGrafico===0){}else{
                            w_redirectToun = `%2C"id_unidad_negocio"%3A"${w_IDUNGrafico}"`
                        }
                        var w_IDAGrafico = IDAGrafico
                        if(w_IDAGrafico===0){}else{
                            w_redirectToa = `%2C"id_unidad_area"%3A"${w_IDAGrafico}"`
                        }
                        var w_IDTEGrafico = IDTEGrafico
                        if(w_IDTEGrafico===0){}else{
                            w_redirectTote = `%2C"id_contratacion_tipo"%3A"${w_IDTEGrafico}"`
                        }
                        var w_IDEGrafico = IDEGrafico
                        if(w_IDEGrafico===0){}else{
                            w_redirectToe = `%2C"id_estado_encuadre"%3A"${w_IDEGrafico}"`
                        }

                        var w_FechaGrafico = EstadoFecha
                        if(w_FechaGrafico==='0000'){}else{
                            w_redirectTof = `%2C"contratacion_ano"%3A"${w_FechaGrafico}"`
                        }

                        redirectTo(`TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0${w_redirectToa}${w_redirectToe}${w_redirectTof}${w_redirectToun}${w_redirectTote}%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);


                        // redirectTo(`TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0%2C"id_unidad_negocio"%3A"${w_name_id}"%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);

                    }
                }
            }
        },
        series: [{
            type: 'pie',
            name: 'Unidad de Negocio',
            innerSize: '50%',
            data: UNGrafico
        }]
    };

    //----------------- grafico por area -----------------------------
    const optionsA = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            options3d: {
                enabled: true,
                alpha: 45
            }
        },
        title: {
            text: 'Área<br>',
            align: 'center',
            verticalAlign: 'middle',
            //y: 60
        },
        credits: {
            enabled: false
        },
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> $: <b>{point.signo}{point.y:.,2f}</b><br/> <b>{point.percentage:.2f}%</b><br/> <b>Cantidad: {point.cantidad}</b><br/>'
        },
        lang: {
            decimalPoint: ',',
            thousandsSep: '.'
        },
        plotOptions: {
            pie: {
                // showInLegend: true,
                dataLabels: {
                    enabled: true,
                    // distance: -50,
                    format: '<span style="color:{point.color}">\u25CF</span><b>{point.name}</b> en $: {point.percentage:.2f}% ',
                    style: {
                        fontWeight: 'bold',
                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'

                    }
                },
                // startAngle: -90,
                // endAngle: 90,
                // center: ['50%', '75%'],
                // size: '100%',
                events: {
                    click: function (event) {
                        console.log(event)
                        // alert(event.point.name)
                        var w_tipo_sociedad = event.point.name_c
                        if (w_tipo_sociedad == null) {
                            w_tipo_sociedad = ''
                        }
                        w_tipo_sociedad = w_tipo_sociedad.trim()

                        var w_name_id = event.point.name_id

                        var w_d_tipo_sociedad = event.point.name_d
                        if (w_d_tipo_sociedad == null) {
                            w_d_tipo_sociedad = ''
                        }
                        w_d_tipo_sociedad = w_d_tipo_sociedad.trim()

                        var w_tot_sociedad = event.point.data

                        console.log('w_tipo_sociedad')
                        console.log(w_tipo_sociedad)
                        console.log('w_d_tipo_sociedad')
                        console.log(w_d_tipo_sociedad)
                        setvAGrafico(w_tipo_sociedad)
                        setIDAGrafico(w_name_id)
                        setEstadoBoton(EstadoBoton + 1);

                        refresh()

                        var w_redirectToun = ``
                        var w_redirectTote = ``
                        var w_redirectToe = ``
                        var w_redirectTof = ``
                        var w_redirectToa = ``
                        var w_IDUNGrafico = IDUNGrafico
                        if(w_IDUNGrafico===0){}else{
                            w_redirectToun = `%2C"id_unidad_negocio"%3A"${w_IDUNGrafico}"`
                        }
                        var w_IDAGrafico = w_name_id
                        if(w_IDAGrafico===0){}else{
                            w_redirectToa = `%2C"id_unidad_area"%3A"${w_IDAGrafico}"`
                        }
                        var w_IDTEGrafico = IDTEGrafico
                        if(w_IDTEGrafico===0){}else{
                            w_redirectTote = `%2C"id_contratacion_tipo"%3A"${w_IDTEGrafico}"`
                        }
                        var w_IDEGrafico = IDEGrafico
                        if(w_IDEGrafico===0){}else{
                            w_redirectToe = `%2C"id_estado_encuadre"%3A"${w_IDEGrafico}"`
                        }

                        var w_FechaGrafico = EstadoFecha
                        if(w_FechaGrafico==='0000'){}else{
                            w_redirectTof = `%2C"contratacion_ano"%3A"${w_FechaGrafico}"`
                        }

                        redirectTo(`TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0${w_redirectToa}${w_redirectToe}${w_redirectTof}${w_redirectToun}${w_redirectTote}%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);


                        // redirectTo(`TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0%2C"id_unidad_negocio"%3A"${w_name_id}"%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);

                    }
                }
            }
        },
        series: [{
            type: 'pie',
            name: 'Area',
            innerSize: '70%',
            data: AGrafico
        }]
    };
    //----------------- grafico por tipos de encuadre -----------------------------
    const optionsTE = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            options3d: {
                enabled: true,
                alpha: 45
            }
        },
        title: {
            text: 'Encuadre<br>',
            align: 'center',
            verticalAlign: 'middle',
            // x: 25
        },
        credits: {
            enabled: false
        },
        tooltip: {
            pointFormat: '{point.name_d}<br/><span style="color:{point.color}">\u25CF</span> $: <b>{point.signo}{point.y:.,2f}</b><br/> <b>{point.percentage:.2f}%</b><br/> <b>Cantidad: {point.cantidad}</b><br/>'
        },
        lang: {
            decimalPoint: ',',
            thousandsSep: '.'
        },
        plotOptions: {
            pie: {
                // showInLegend: true,
                // dataLabels: {
                //     enabled: true,
                //     distance: -50,
                //     format: '<span style="color:{point.color}">\u25CF</span><b>{point.name}</b> en $: {point.signo}{point.y:.,2f} ',
                //     style: {
                //         fontWeight: 'bold',
                //         color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                //
                //     }
                // },
                dataLabels: {
                    enabled: true,
                    // distance: -50,
                    format: '<span style="color:{point.color}">\u25CF</span><b>{point.name}</b> en $: {point.percentage:.2f}% ',
                    style: {
                        fontWeight: 'bold',
                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'

                    }},
                events: {
                    click: function (event) {
                        console.log(event)
                        // alert(event.point.name)
                        var w_tipo_sociedad = event.point.name_c
                        if (w_tipo_sociedad == null) {
                            w_tipo_sociedad = ''
                        }
                        w_tipo_sociedad = w_tipo_sociedad.trim()

                        var w_name_id = event.point.name_id

                        var w_d_tipo_sociedad = event.point.name_d
                        if (w_d_tipo_sociedad == null) {
                            w_d_tipo_sociedad = ''
                        }
                        w_d_tipo_sociedad = w_d_tipo_sociedad.trim()

                        var w_tot_sociedad = event.point.data

                        console.log('w_tipo_sociedad')
                        console.log(w_tipo_sociedad)
                        console.log('w_d_tipo_sociedad')
                        console.log(w_d_tipo_sociedad)
                        setvTEGrafico(w_tipo_sociedad)
                        setIDTEGrafico(w_name_id)
                        setEstadoBoton(EstadoBoton + 1);
                        refresh()

                        var w_redirectToun = ``
                        var w_redirectTote = ``
                        var w_redirectToe = ``
                        var w_redirectTof = ``
                        var w_redirectToa = ``
                        var w_IDUNGrafico = IDUNGrafico
                        if(w_IDUNGrafico===0){}else{
                            w_redirectToun = `%2C"id_unidad_negocio"%3A"${w_IDUNGrafico}"`
                        }
                        var w_IDAGrafico = IDAGrafico
                        if(w_IDAGrafico===0){}else{
                            w_redirectToa = `%2C"id_unidad_area"%3A"${w_IDAGrafico}"`
                        }
                        var w_IDTEGrafico = w_name_id
                        if(w_IDTEGrafico===0){}else{
                            w_redirectTote = `%2C"id_contratacion_tipo"%3A"${w_IDTEGrafico}"`
                        }
                        var w_IDEGrafico = IDEGrafico
                        if(w_IDEGrafico===0){}else{
                            w_redirectToe = `%2C"id_estado_encuadre"%3A"${w_IDEGrafico}"`
                        }

                        var w_FechaGrafico = EstadoFecha
                        if(w_FechaGrafico==='0000'){}else{
                            w_redirectTof = `%2C"contratacion_ano"%3A"${w_FechaGrafico}"`
                        }

                        redirectTo(`TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0${w_redirectToa}${w_redirectToe}${w_redirectTof}${w_redirectToun}${w_redirectTote}%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);


                        // redirectTo(`TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0%2C"id_contratacion_tipo"%3A"${w_name_id}"%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);

                    }
                }
            }
        },
        series: [{
            type: 'pie',
            name: 'Tipo de Encuadre',
            innerSize: '50%',
            data: TEGrafico
        }]
    };

    //----------------- grafico por estado -----------------------------
    const optionsE = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            options3d: {
                enabled: true,
                alpha: 45
            }
        },
        title: {
            text: 'Estado<br>',
            align: 'center',
            verticalAlign: 'middle',
            x: -10
        },
        credits: {
            enabled: false
        },
        tooltip: {
            pointFormat: '{point.name_d}<br/> <span style="color:{point.color}">\u25CF</span> $: <b>{point.signo}{point.y:.,2f}</b><br/> <b>{point.percentage:.2f}%</b><br/> <b>Cantidad: {point.cantidad}</b><br/>'
        },
        lang: {
            decimalPoint: ',',
            thousandsSep: '.'
        },
        plotOptions: {
            pie: {
                // showInLegend: true,
                dataLabels: {
                    enabled: true,
                    // distance: -50,
                    format: '<span style="color:{point.color}">\u25CF</span><b>{point.name}</b> en $: {point.percentage:.2f}% ',
                    style: {
                        fontWeight: 'bold',
                        color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'

                    }
                },
                // startAngle: -90,
                // endAngle: 90,
                // center: ['50%', '75%'],
                // size: '110%',
                events: {
                    click: function (event) {
                        console.log(event)
                        // alert(event.point.name)
                        var w_tipo_sociedad = event.point.name
                        if (w_tipo_sociedad == null) {
                            w_tipo_sociedad = ''
                        }
                        w_tipo_sociedad = w_tipo_sociedad.trim()

                        var w_name_id = event.point.name_id

                        var w_d_tipo_sociedad = event.point.name_d
                        if (w_d_tipo_sociedad == null) {
                            w_d_tipo_sociedad = ''
                        }
                        w_d_tipo_sociedad = w_d_tipo_sociedad.trim()

                        var w_tot_sociedad = event.point.data

                        console.log('w_tipo_sociedad')
                        console.log(w_tipo_sociedad)
                        console.log('w_d_tipo_sociedad')
                        console.log(w_d_tipo_sociedad)
                        setvEGrafico(w_tipo_sociedad)
                        setIDEGrafico(w_name_id)
                        setEstadoBoton(EstadoBoton + 1);
                        refresh()
                        var w_redirectToun = ``
                        var w_redirectTote = ``
                        var w_redirectToe = ``
                        var w_redirectTof = ``
                        var w_redirectToa = ``
                        var w_IDUNGrafico = IDUNGrafico
                        if(w_IDUNGrafico===0){}else{
                            w_redirectToun = `%2C"id_unidad_negocio"%3A"${w_IDUNGrafico}"`
                        }
                        var w_IDAGrafico = IDAGrafico
                        if(w_IDAGrafico===0){}else{
                            w_redirectToa = `%2C"id_unidad_area"%3A"${w_IDAGrafico}"`
                        }
                        var w_IDTEGrafico = IDTEGrafico
                        if(w_IDTEGrafico===0){}else{
                            w_redirectTote = `%2C"id_contratacion_tipo"%3A"${w_IDTEGrafico}"`
                        }
                        var w_IDEGrafico = w_name_id
                        if(w_IDEGrafico===0){}else{
                            w_redirectToe = `%2C"id_estado_encuadre"%3A"${w_IDEGrafico}"`
                        }

                        var w_FechaGrafico = EstadoFecha
                        if(w_FechaGrafico==='0000'){}else{
                            w_redirectTof = `%2C"contratacion_ano"%3A"${w_FechaGrafico}"`
                        }

                        redirectTo(`TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0${w_redirectToa}${w_redirectToe}${w_redirectTof}${w_redirectToun}${w_redirectTote}%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);

                        // redirectTo(`TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0%2C"id_estado_encuadre"%3A"${w_name_id}"%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);

                    }
                }
            }
        },
        series: [{
            type: 'pie',
            name: 'Estado',
            innerSize: '50%',
            data: EGrafico
        }]
    };

    const cambiaano = (event) => {

//---------------- el cambio de estado refresca el componente y lo usa para pasar parametro ------------------------
// -------------------------------------- Graba la nueva fecha de proceso ------------------------------------------
        const CambiaFecha = (wp_value) => {
            setEstadoFecha(wp_value)
            setEstadoBoton(EstadoBoton + 1);
            refresh()
            var w_redirectToun = ``
            var w_redirectTote = ``
            var w_redirectToe = ``
            var w_redirectTof = ``
            var w_redirectToa = ``
            var w_IDUNGrafico = IDUNGrafico
            if(w_IDUNGrafico===0){}else{
                w_redirectToun = `%2C"id_unidad_negocio"%3A"${w_IDUNGrafico}"`
            }
            var w_IDAGrafico = IDAGrafico
            if(w_IDAGrafico===0){}else{
                w_redirectToa = `%2C"id_unidad_area"%3A"${w_IDAGrafico}"`
            }
            var w_IDTEGrafico = IDTEGrafico
            if(w_IDTEGrafico===0){}else{
                w_redirectTote = `%2C"id_contratacion_tipo"%3A"${w_IDTEGrafico}"`
            }
            var w_IDEGrafico = IDEGrafico
            if(w_IDEGrafico===0){}else{
                w_redirectToe = `%2C"id_estado_encuadre"%3A"${w_IDEGrafico}"`
            }

            var w_FechaGrafico = wp_value
            if(w_FechaGrafico==='0000'){}else{
                w_redirectTof = `%2C"contratacion_ano"%3A"${w_FechaGrafico}"`
            }

            redirectTo(`TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0${w_redirectToa}${w_redirectToe}${w_redirectTof}${w_redirectToun}${w_redirectTote}%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`);

        };


        var w_value = event.target.value
        CambiaFecha(w_value)

    }

    //---------------- muestra grafico---------
    return (
        <div>
            <AppBar position="static" style={{color: '#ffffff', backgroundColor: '#7CC1FF'}}>
                <Toolbar>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="headline" component="h2">
                                        <div style={{color: '#ffffff'}}>
                                            {'Tablero Control Contrataciones'}
                                        </div>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="headline" component="h2">
                                        <div style={{color: '#ffffff'}}>
                                            {`Año`}
                                        </div>
                                        <TextField
                                            id="ano_ing"
                                            select
                                            label=""
                                            size="small"
                                            // value={currency}
                                            value={EstadoFecha}
                                            onChange={cambiaano}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            // helperText="Por favor seleccione su Mes"
                                        >
                                            {udntableroTableroControl.map((option) => (
                                                <option key={option.contratacion_ano} value={option.contratacion_ano}>
                                                    {option.contratacion_ano_d}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <TableroControlContratacionDetalleAgrupadoButton    p_unidad_negocio_ingv={vUNGrafico}
                                                                                        p_area_ing={vAGrafico}
                                                                                        p_tipos_de_encuadre_ing={vTEGrafico}
                                                                                        p_estado_ing={vEGrafico}
                                                                                        p_fecha_ing={EstadoFecha}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button
                                        label="Borrar Todos los Filtros"
                                        title="Borrar Todos los Filtros"
                                        onClick={handleClickBotonTodoslosFiltros}
                                        style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                    >
                                        <div style={{
                                            display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                                        >
                                            <ReplayIcon/>
                                            <FilterListIcon/>
                                        </div>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                </Toolbar>
            </AppBar>
            {/*cantidad_unidad_negocioGrafico*/}
            {/*cantidad_unidad_areaGrafico*/}
            {/*cantidad_contratacion_tipoGrafico*/}
            {/*cantidad_estadoGrafico*/}
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <WithPermissions
                        render={({ permissions }) => (
                            role_con_tab_ver_graf_uni_neg_inv({permissions }) === 'ROLE_CON_TAB_VER_GRAF_UNI_NEG_INV'
                                ?
                                <div style={styles.flex}>
                                    <HighchartsReact highcharts={Highcharts} options={optionsUN}/>
                                </div>
                                :
                                <div style={styles.flex}>
                                    {cantidad_unidad_negocioGrafico === 1 ? '' :
                                        <HighchartsReact highcharts={Highcharts} options={optionsUN}/>
                                    }
                                </div>
                        )}
                    />

                    <div style={styles.flex}>
                        <HighchartsReact highcharts={Highcharts} options={optionsTE}/>
                    </div>
                </div>
                <div style={styles.rightCol}>
                    <WithPermissions
                        render={({ permissions }) => (
                            role_con_tab_ver_graf_uni_area_inv({permissions }) === 'ROLE_CON_TAB_VER_GRAF_UNI_AREA_INV'
                                ?
                                <div style={styles.flex}>
                                    <HighchartsReact highcharts={Highcharts} options={optionsA}/>
                                </div>
                                :
                                <div style={styles.flex}>
                                    {cantidad_unidad_areaGrafico === 1 ? '' :
                                        <HighchartsReact highcharts={Highcharts} options={optionsA}/>
                                    }
                                </div>
                        )}
                    />
                    <div style={styles.flex}>
                        <HighchartsReact highcharts={Highcharts} options={optionsE}/>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default (TableroControlContratacionGraficos);


