import React, {useState} from "react";
import {
    FormTab,
    Show,
    TextInput,
    TabbedForm,
    TopToolbar,
    NumberInput,
    EditButton,
    SelectInput,
    usePermissions
} from 'react-admin';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

    documento_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    documento_tipo_d: {display: 'inline-block', width: 400, marginLeft: 8},
}));

const MonedaShow = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    var w_role_auxiliar_editar = ''
    var w_permissions_admuno_auxiliar_editar = permissions
    if(w_permissions_admuno_auxiliar_editar==null){}else{
        var wa_role_auxiliar_editar = w_permissions_admuno_auxiliar_editar.indexOf('ROLE_AUXILIAR_EDITAR')
        if(wa_role_auxiliar_editar===-1){}else{
            w_role_auxiliar_editar = 'ROLE_AUXILIAR_EDITAR'
        }
    }

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            {w_role_auxiliar_editar === 'ROLE_AUXILIAR_EDITAR' &&
                <EditButton basePath={basePath} record={data}/>
            }
        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        return <span>Moneda: {record ? `${record.moneda}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>

                <FormTab label="DATOS MONEDA">

                    <TextInput
                        label="Moneda"
                        source="moneda"
                        formClassName={classes.moneda}
                        placeholder="Ingrese Moneda"
                        helperText="Moneda"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />

                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Descripción Moneda"
                        source="moneda_d"
                        formClassName={classes.moneda_d}
                        multiline
                        placeholder="Ingrese Descripción Moneda"
                        helperText="Descripción Moneda"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>



                    <TextInput
                        id="standard-full-width"
                        label="Moneda Abre."
                        source="moneda_abre"
                        formClassName={classes.moneda_abre}
                        multiline
                        placeholder="Ingrese Moneda Abre."
                        helperText="Moneda Abre."
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Código AFIP"
                        source="codigo_afip"
                        formClassName={classes.codigo_afip}
                        multiline
                        placeholder="Ingrese Código AFIP"
                        helperText="Código AFIP"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>


                    <TextInput
                        id="standard-full-width"
                        label="Código Gestion"
                        source="codigo_gestion"
                        formClassName={classes.codigo_gestion}
                        multiline
                        placeholder="Ingrese Código Gestion"
                        helperText="Código Gestion"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>

                    {/*<TextInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Activo"*/}
                    {/*    source="activo"*/}
                    {/*    formClassName={classes.activo}*/}
                    {/*    multiline*/}
                    {/*    placeholder="Ingrese Activo"*/}
                    {/*    helperText="Activo"*/}
                    {/*    fullWidth*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}

                    <SelectInput source="activo" label="Activo"
                                 formClassName={classes.activo}
                                 placeholder="Ingrese Activo"
                                 helperText="Activo"
                                 choices={[
                                     { id: 'S', name: 'S' },
                                     { id: 'N', name: 'N' },
                                 ]}
                                 inputProps={{readOnly: true}}
                    />
                    <br/>

                </FormTab>


            </TabbedForm>
        </Show>
    )
};
export default MonedaShow;


