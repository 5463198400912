import React, {useState} from 'react';
import {Drawer, makeStyles} from "@material-ui/core";
import IconBuscar from '@material-ui/icons/Search';
import ProveedorClasesBuscadorArbol from "./ProveedorClasesBuscadorArbol";
import Button from "@mui/material/Button";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '35%'
    }
}));

export const BtnAbrirArbolClaseBuscador = ({label, callbackClase}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" size={"small"} color="primary" onClick={() => setOpen(!open)}>
                <IconBuscar/>
                <h5>{label}</h5>
            </Button>

            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <div>
                    <h3>Seleccionar Clases</h3>
                </div>

                <ProveedorClasesBuscadorArbol
                    callbackClase={callbackClase}
                    callbackCloseDrawer={handleDrawerOpen}/>

            </Drawer>
        </div>
    )
};
export default BtnAbrirArbolClaseBuscador;