import React, {useCallback, useState} from 'react';
import { useFormState } from 'react-final-form';
import {
    SaveButton,
    useUpdate,
    useRedirect,
    useNotify,
    useRefresh, Loading, Button
} from 'react-admin';
import axios from 'axios';
import Compressor from 'compressorjs';
import ApiUrlServer from '../layout/ApiUrlServer'
import PropTypes from 'prop-types';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ReplayIcon from '@material-ui/icons/Replay';

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()



const ProveedorDocumentacionAdjuntoSaveButton = props => {
    const [update] = useUpdate('ProveedorDocumentacionAdjunto');
    const redirectTo = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const { basePath, redirect } = props;
    const [loadingDisabled, setLoadingDisabled] = useState(false);
    // const [loadingDisabledT, setLoadingDisabledT] = useState(0);
    // redirect to the related Author show page
    // const redirect_orgpag = (basePath, id, data) => `/OrdPagEstado`;

    // const redirect_g_remitoentrega = (basePath, id, data) => `/OrdPagMailOperar`;


    const CambiaDisabledTrue = () => {
        // console.log('CostosControlPeriodoDatagrid----CambiaEditarEdit')
        setLoadingDisabled(true);
    };

    const formState = useFormState();

    const redirect_g_remitoentrega = (basePath, id, data) => `/ProveedorDocumentacionAdjuntoShow/${formState.values.id}/show`;

    const handleClick = useCallback(() => {

        // notify('Verificando Remito...', 'info', {
        //     smart_count: 1,
        // });
        // console.log('formState.values')
        // console.log(formState.values)
        //---------------------- en primer lugar sube los archivos con axios --------------------
        //-------------------------------- Controlador y Token ------------------------------
        // let webApiUrl = 'http://localhost:8080/api'
        let webApiUrl = w_apiurlserver+'/api'

        let tokenStr = localStorage.getItem('token');
        // datafile.append('files[]', formState.values.ArchivosAdjuntos)

        console.log('formState')
        console.log(formState)

        console.log('formState.values')
        console.log(formState.values)

        alert('ProveedorDocumentacionAdjuntoSaveButton')

        let w_length = formState.values.ArchivosAdjuntos
        let w_mandar_mail
        let w_cant_length = 0
        let w_cant_for = 0
        if (w_length === undefined){
            notify('Debe Ingresar un Archivo para Adjuntar', 'warning', {
                smart_count: 1,
            });
        }else {
            w_mandar_mail = 'N'
            w_cant_length = formState.values.ArchivosAdjuntos.length
            console.log('w_cant_length')
            console.log(w_cant_length)
            alert(w_cant_length)

            if(w_cant_length===0){
                notify('Debe Ingresar un Archivo para Adjuntar', 'warning', {
                    smart_count: 1,
                });
            }else {
                notify('Actualizando Remito', 'info', {
                    smart_count: 1,
                });
                for (let i = 0, numFiles = formState.values.ArchivosAdjuntos.length; i < numFiles; i++) {

                    const datafile = new FormData()
                    const file = formState.values.ArchivosAdjuntos[i];
                    console.log('file')
                    console.log(file)
                    console.log('file.rawFile')
                    console.log(file.rawFile)
                    console.log('file.rawFile.name')
                    console.log(file.rawFile.name)

                    let type_file = file.rawFile.type
                    if (type_file == null) {
                        type_file = ''
                    }
                    type_file = type_file.trim()

                    console.log('type_file')
                    console.log(type_file)

                    let type_image = type_file.substring(0, 6);

                    console.log('type_image')
                    console.log(type_image)

                    // alert('TI')

                    // const result = file

                    // alert('new Compressor')

                    // compress.attach('file', {
                    //     size: 4,
                    //     quality: .75
                    // }).then((result) => {

                    // compress.compress(file, {
                    //
                    //     size: 4, // the max size in MB, defaults to 2MB
                    //     quality: .75, // the quality of the image, max is 1,
                    //     maxWidth: 1920, // the max width of the output image, defaults to 1920px
                    //     maxHeight: 1920, // the max height of the output image, defaults to 1920px
                    //     resize: true, // defaults to true, set false if you do not want to resize the image width and height
                    // }).then((result) => {


                    if (type_image === 'image/') {

                        new Compressor(file.rawFile, {
                            quality: 0.6,
                            success(result) {

                                console.log('result')
                                console.log(result)

                                // alert('hola-----entre---new Compressor')

                                // datafile.append('file', result);
                                // datafile.append('name', result.name);
                                // datafile.append('description', 'Este archivo es subido por mi');


                                datafile.append('file', result, result.name);
                                datafile.append('id_rem_ent', formState.values.id);
                                // datafile.append('firma', formState.values.firma);

                                console.log('datafile----for')
                                console.log(datafile)
                                console.log({datafile})
                                console.log('datafile----for---FIN')

                                // axios.post(webApiUrl, {datafile}, { headers: {
                                //     'Content-Type': 'application/json',
                                //     "x-auth-token" : `${tokenStr}`,
                                //     // 'content-type': 'multipart/form-data',
                                //     }} )
                                //     .then(res => { // then print response status
                                //         console.log(res.statusText)
                                //     })
                                //     .catch((error) => {
                                //         console.log(error);
                                //     });


                                axios.create({
                                    baseURL: webApiUrl,
                                    timeout: 10000,
                                    headers: {
                                        'Content-Type': 'application/json',
                                        "x-auth-token": `${tokenStr}`

                                    },
                                }).post('/ProveedorDocumentacionAdjunto', datafile).then((response) => {
                                    w_cant_for = w_cant_for + 1
                                    console.log('w_cant_for--post')
                                    console.log(w_cant_for)
                                    if (w_cant_length === w_cant_for) {
                                        w_mandar_mail = 'P'
                                    }
                                    console.log('w_mandar_mail---Final_aux')
                                    console.log(w_mandar_mail)
                                    console.log("File " + file.rawFile.name + " is uploaded");
                                    if (w_mandar_mail === 'P') {
                                        update(
                                            {
                                                payload: {data: {...formState.values}},
                                            },
                                            {
                                                onSuccess: ({data: newRecord}) => {
                                                    notify('Listo Remito Actualizado', 'info', {
                                                        smart_count: 1,
                                                    });
                                                    // refresh()
                                                    redirectTo(redirect_g_remitoentrega, basePath, newRecord.id, newRecord);
                                                },
                                            }
                                        );
                                    }
                                }).catch(function (error) {
                                    console.log(error);
                                    if (error.response) {
                                        //HTTP error happened
                                        console.log("Upload error. HTTP error/status code=", error.response.status);
                                    } else {
                                        //some other error happened
                                        console.log("Upload error. HTTP error/message code=", error.message);
                                    }
                                });
                                // })

                            },
                            error(err) {
                                console.log(err.message);
                                // alert('erro')
                            },
                        });
                    } else {
                        datafile.append('file', file.rawFile);
                        datafile.append('id_rem_ent', formState.values.id);
                        // datafile.append('firma', formState.values.firma);
                        datafile.append('name', file.rawFile.name);
                        datafile.append('description', 'Este archivo es subido por mi');

                        console.log('datafile----for')
                        console.log(datafile)
                        console.log({datafile})
                        console.log('datafile----for---FIN')

                        // axios.post(webApiUrl, {datafile}, { headers: {
                        //     'Content-Type': 'application/json',
                        //     "x-auth-token" : `${tokenStr}`,
                        //     // 'content-type': 'multipart/form-data',
                        //     }} )
                        //     .then(res => { // then print response status
                        //         console.log(res.statusText)
                        //     })
                        //     .catch((error) => {
                        //         console.log(error);
                        //     });


                        axios.create({
                            baseURL: webApiUrl,
                            timeout: 10000,
                            headers: {
                                'Content-Type': 'application/json',
                                "x-auth-token": `${tokenStr}`

                            },
                        }).post('/ProveedorDocumentacionAdjunto', datafile).then((response) => {
                            w_cant_for = w_cant_for + 1
                            console.log('w_cant_for--post')
                            console.log(w_cant_for)
                            if (w_cant_length === w_cant_for) {
                                w_mandar_mail = 'P'
                            }
                            console.log('w_mandar_mail---Final_aux')
                            console.log(w_mandar_mail)
                            console.log("File " + file.rawFile.name + " is uploaded");
                            if (w_mandar_mail === 'P') {
                                update(
                                    {
                                        payload: {data: {...formState.values}},
                                    },
                                    {
                                        onSuccess: ({data: newRecord}) => {
                                            notify('Listo Remito Actualizado', 'info', {
                                                smart_count: 1,
                                            });
                                            // refresh()
                                            redirectTo(redirect_g_remitoentrega, basePath, newRecord.id, newRecord);
                                        },
                                    }
                                );
                            }
                        }).catch(function (error) {
                            console.log(error);
                            if (error.response) {
                                //HTTP error happened
                                console.log("Upload error. HTTP error/status code=", error.response.status);
                            } else {
                                //some other error happened
                                console.log("Upload error. HTTP error/message code=", error.message);
                            }
                        });
                    }
                }
            }
        }


        if (!formState.valid) {
            return;
        }

        // if (w_mandar_mail === 'S') {
        //     let w_generar_remitoentrega
        //     const Valida = new FormData()
        //
        //     var wr_entregado = formState.values.entregadob
        //     var w_entregado
        //     if(wr_entregado===true){
        //         w_entregado = 'S'
        //     }else{
        //         w_entregado = 'N'
        //     }
        //
        //     var wr_entregado_parcial = formState.values.entregado_parcialb
        //     var w_entregado_parcial
        //     if(wr_entregado_parcial===true){
        //         w_entregado_parcial = 'S'
        //     }else{
        //         w_entregado_parcial = 'N'
        //     }
        //
        //     var wr_no_entregado = formState.values.no_entregadob
        //     var w_no_entregado
        //     if(wr_no_entregado===true){
        //         w_no_entregado = 'S'
        //     }else{
        //         w_no_entregado = 'N'
        //     }
        //
        //
        //     var w_observacion = formState.values.observacion
        //     if (w_observacion == null) {
        //         w_observacion = ''
        //     }
        //     w_observacion = w_observacion.trim()
        //
        //
        //     Valida.append('id', formState.values.id);
        //     // Valida.append('firma', formState.values.firma);
        //     Valida.append('entregado', w_entregado);
        //     Valida.append('entregado_parcial', w_entregado_parcial);
        //     Valida.append('no_entregado', w_no_entregado);
        //     Valida.append('observacion', w_observacion);
        //     Valida.append('cant_length', w_cant_length);
        //
        //     console.log('Valida----for')
        //     console.log(Valida)
        //     console.log({Valida})
        //     console.log('Valida----for---FIN')
        //     console.log(w_entregado)
        //
        //
        //     // alert('gola')
        //
        //     axios.create({
        //         baseURL: webApiUrl,
        //         timeout: 10000,
        //         headers: {
        //             'Content-Type': 'application/json',
        //             "x-auth-token": `${tokenStr}`
        //
        //         },
        //     }).post('/ProveedorDocumentacionAdjuntoValida', Valida).then((response) => {
        //         console.log(response)
        //         let w_error = response.data.data.error
        //         let w_mensaje = response.data.data.mensaje
        //         console.log(w_error)
        //         console.log(w_mensaje)
        //
        //         if (w_error === 'N') {
        //             w_generar_remitoentrega = 'P'
        //             CambiaDisabledTrue()
        //         }else{
        //             notify(`Atención: ${w_mensaje}`, 'warning')
        //             // alert(w_mensaje)
        //         }
        //         console.log('w_generar_periodo---Final_aux')
        //         console.log(w_generar_remitoentrega)
        //         if (w_generar_remitoentrega === 'P') {
        //             notify('Actualizando Remito', 'info', {
        //                 smart_count: 1,
        //             });
        //
        //             update(
        //                 {
        //                     payload: {data: {...formState.values}},
        //                 },
        //                 {
        //                     onSuccess: ({data: newRecord}) => {
        //                         notify('Listo Remito Actualizado', 'info', {
        //                             smart_count: 1,
        //                         });
        //                         // refresh()
        //                         redirectTo(redirect_g_remitoentrega, basePath, newRecord.id, newRecord);
        //                     },
        //                 }
        //             );
        //         }
        //     }).catch(function (error) {
        //         console.log(error);
        //         if (error.response) {
        //             //HTTP error happened
        //             console.log("Upload error. HTTP error/status code=", error.response.status);
        //         } else {
        //             //some other error happened
        //             console.log("Upload error. HTTP error/message code=", error.message);
        //         }
        //     });
        // }
    }, [
        formState.valid,
        formState.values,
        update,
        notify,
        redirectTo,
        refresh,
        redirect,
        basePath,
    ]);

    return <SaveButton {...props} disabled={loadingDisabled} handleSubmitWithRedirect={handleClick} redirect={redirect_g_remitoentrega}/>

    // const onClickVolver = () => {
    //     CambiaDisabledFalse()
    // }
    // return (
    //     <div>
    //         {loadingDisabled === true && loadingDisabledT === 1 ?
    //             <SaveButton
    //                 label="Volver a Guardar"
    //                 handleSubmitWithRedirect={onClicVolver}
    //                 icon={<ReplayIcon />}
    //                 style={{backgroundColor: '#0080ff', color: '#ffffff'}}
    //             />
    //             :
    //             <SaveButton {...props}
    //                         disabled={loadingDisabled}
    //             handleSubmitWithRedirect={handleClick}
    //             redirect={redirect_g_remitoentrega}/>
    //         }
    //     </div>
    // )
};

ProveedorDocumentacionAdjuntoSaveButton.propTypes = {
    invalid: PropTypes.bool,
};

export default ProveedorDocumentacionAdjuntoSaveButton