import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    useNotify,
    useRedirect, useMutation, useRefresh, useUnselectAll
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import PolicyIcon from '@material-ui/icons/Policy';
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import HistoryIcon from '@material-ui/icons/History';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import CardContent from "@material-ui/core/CardContent";
import AvatarImage from "../componentes/Avatar/AvatarImage";
import Accordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Badge from "@material-ui/core/Badge";
import Avatar from '@material-ui/core/Avatar';

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
    rotateIcon: {
        animation: "spin 4s linear infinite"
    },
    root: {
        transform: 90,
        borderRadius: 5,
        width: 50,
        height: "100%"
    },
    paper: {
        padding: '6px 16px',
    },
    root2: {
        width: '100%',
    },
    content: {
        justifyContent: "center"
    },
    rounded: {
        color: '#fff',
        backgroundColor: '#02a600',
    },
});

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: -25,
            left: -25,
            width: '300%',
            height: '300%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '4px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const AccordionSummary = withStyles({
    content: {
        flexGrow: 0
    }
})(MuiAccordionSummary);

const SeguimientoCompletoButton = ({record}) => {
    var wr_id = record.id

    var wr_contratacion_tipo_encuadre = record.contratacion_tipo_encuadre

    if (wr_contratacion_tipo_encuadre == null) {
        wr_contratacion_tipo_encuadre = ''
    }
    wr_contratacion_tipo_encuadre = wr_contratacion_tipo_encuadre.trim()

    var wr_expediente_referencia_gde = record.expediente_referencia_gde

    if (wr_expediente_referencia_gde == null) {
        wr_expediente_referencia_gde = ''
    }
    wr_expediente_referencia_gde = wr_expediente_referencia_gde.trim()
    var wr_estado_encuadre = record.estado_encuadre_p_m
    var wr_estado_encuadre_d = record.estado_encuadre_d

    var wr_hay_contratacion_encuadre_visita = record.hay_contratacion_encuadre_visita

    if(wr_hay_contratacion_encuadre_visita==null){
        wr_hay_contratacion_encuadre_visita=0
    }

    var wr_estado_encuadre_fh_ultima_actualizacion = record.estado_encuadre_fh_ultima_actualizacion

    var wr_concepto_descripcion = record.concepto_descripcion

    var wr_importe_contratacion_pesos = record.importe_contratacion_pesos

    var wr_contratacion_d = record.contratacion_d

    // console.log('wr_hay_contratacion_encuadre_visita')
    // console.log(wr_hay_contratacion_encuadre_visita)

    var wrt_hay_contratacion_encuadre_visita = ''
    var wrc_hay_contratacion_encuadre_visita = '#8d8d8d'

    if(wr_hay_contratacion_encuadre_visita===0){
        wrt_hay_contratacion_encuadre_visita = 'Sin Visitar'
        wrc_hay_contratacion_encuadre_visita = '#8d8d8d'
    }else{
        if(wr_hay_contratacion_encuadre_visita > 1){
            wrt_hay_contratacion_encuadre_visita = 'Visitas'
            wrc_hay_contratacion_encuadre_visita = '#02a600'
        }else{
            if(wr_hay_contratacion_encuadre_visita = 1){
                wrt_hay_contratacion_encuadre_visita = 'Visitar'
                wrc_hay_contratacion_encuadre_visita = '#64c8ff'
            }
        }
    }

    const classes = useStyles()
    const [openvisita, setOpenvisita] = React.useState(false);
    const [openSeguimiento, setOpenSeguimiento] = React.useState(false);
    const [ObjetoHistorial, setObjetoHistorial] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();
    const [Estadoid, setEstadoid] = useState(wr_id)
    const [Estadoestado_encuadre, setEstadoestado_encuadre] = useState(wr_estado_encuadre)
    const [Estadoestado_encuadre_d, setEstadoestado_encuadre_d] = useState(wr_estado_encuadre_d)
    const [Estadoexpediente_referencia_gde, setEstadoexpediente_referencia_gde] = useState(wr_expediente_referencia_gde)
    const [Estadocontratacion_tipo_encuadre, setEstadocontratacion_tipo_encuadre] = useState(wr_contratacion_tipo_encuadre)
    const [Estadocontratacion_d, setEstadocontratacion_d] = useState(wr_contratacion_d)
    const [Estadoestado_encuadre_fh_ultima_actualizacion, setEstadoestado_encuadre_fh_ultima_actualizacion] = useState(wr_estado_encuadre_fh_ultima_actualizacion)
    const [Estadoconcepto_descripcion, setEstadoconcepto_descripcion] = useState(wr_concepto_descripcion)
    const [Estadoimporte_contratacion_pesos, setEstadoimporte_contratacion_pesos] = useState(wr_importe_contratacion_pesos)

    const [Estadovisita_leyenda, setEstadovisita_leyenda] = useState('')
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const dataProvider = useDataProvider();
    const redirectTo = useRedirect();
    const unselectAll = useUnselectAll();
    const refresh = useRefresh();

    const handleClickOpenvisita = () => {
        setOpenvisita(true);
    };

    const handleClosevisita = () => {
        setOpenvisita(false);
        // unselectAll();
        // redirectTo(`/ContratacionEncuadreVisita?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=25&sort=id`);
        refresh()
    };

    const handleClickOpenSeguimiento = () => {
        dataProvider.getList(
            'ContratacionEncuadreVisita',
            {
                filter: {uso_historial: 'T',
                    id_contratacion_encuadre: Estadoid,
                    expediente_referencia_gde: Estadoexpediente_referencia_gde,
                    contratacion_tipo: Estadocontratacion_tipo_encuadre},
                sort: {field: 'visita_usuario_fh', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },
        )
            .then(({data}) => {
                setObjetoHistorial(data);
                setLoading(false);
                setOpenSeguimiento(true);
            })
            .catch(error => {
                // setError(error);
                setLoading(false);

            })

        if (loading) return <Loading/>;                // no cargo todavia

        if (error) {
            console.log('error')
            console.log(error)
            return <Error/>;                           // error
        }
        ;

    };

    const handleCloseSeguimiento = () => {
        setOpenSeguimiento(false);
    };

    const BotonCrearDetellevisita = (record) => {
        var w_mensaje = ' '
        const notify = useNotify();

        const [generardetellevisita, {loading}] = useMutation({
                type: 'create',
                resource: 'ContratacionEncuadreVisita',
                payload: {
                    data: {
                        id_contratacion_encuadre: Estadoid,
                        expediente_referencia_gde: Estadoexpediente_referencia_gde,
                        contratacion_tipo: Estadocontratacion_tipo_encuadre,
                        visita_leyenda: Estadovisita_leyenda
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    setOpenvisita(false);
                    // unselectAll();
                    // redirectTo(`/ContratacionEncuadreVisita?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=25&sort=id`);
                    // redirectTo(`/ContratacionEncuadreVisita?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=25&sort=id`);
                    refresh()
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );

        return (
            <Button variant="outlined" color="primary" onClick={generardetellevisita}
                    style={{color: '#ffffff', backgroundColor: '#004187'}}
            >
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <SaveIcon style={{color: '#ffffff', backgroundColor: '#004187'}}/>
                    Guardar
                </div>
            </Button>
        );
    };

    const handlevisita_leyenda = (event) => {
        const Cambiavisita_leyenda1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadovisita_leyenda(wp_value)
        };
        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        Cambiavisita_leyenda1(w_value)
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpenSeguimiento}>
                <div title={'Seguimiento'}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <LinearScaleIcon style={{ transform: "rotate(90deg)", transition: "all 0.2s linear" }}  />
                    {/*<LinearScaleIcon  className={classes.root} />*/}
                    {/*    <LinearScaleIcon className={classes.rotateIcon} />*/}
                    {/*    <style>{`*/}
                    {/*        @keyframes spin {*/}
                    {/*             0% { transform: rotate(360deg); }*/}
                    {/*             100% { transform: rotate(0deg); }*/}
                    {/*        }*/}
                    {/*    `}</style>*/}
                </div>
            </Button>
            <Dialog open={openSeguimiento}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                // maxWidth={'lg'}
                    aria-labelledby="draggable-dialog-title">
                <DialogTitle id="form-dialog-title">
                    <DialogContentText style={{color: '#ffffff', backgroundColor: '#123B80'}}>
                        <Typography variant="headline" component="h4" className={classes.textleft}>
                            {'Seguimiento Contratación'}
                        </Typography>
                    </DialogContentText>
                    <Table size={'small'} style={{color: '#ffffff', backgroundColor: '#7CC1FF'}}>
                        <TableRow>
                            <TableCell style={{width: '10%'}} className={classes.textright}>
                                <Typography variant="headline" component="h6" className={classes.textright}>
                                    {'TIPO:'}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption" component="h6" className={classes.textleft}>
                                    {Estadocontratacion_d}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="headline" component="h6" className={classes.textright}>
                                    {'EXPEDIENTE:'}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption" component="h6" className={classes.textleft}>
                                    {Estadoexpediente_referencia_gde}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{width: '10%'}} className={classes.textright}>
                                <Typography variant="headline" component="h6" className={classes.textright}>
                                    {'ESTADO ACTUAL:'}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption" component="h6" className={classes.textleft}>
                                    {Estadoestado_encuadre}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="headline" component="h6" className={classes.textright}>
                                    {'ÚLTIMA ACTUALIZACIÓN:'}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption" component="h6" className={classes.textleft}>
                                    {Estadoestado_encuadre_fh_ultima_actualizacion}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="headline" component="h6" className={classes.textright}>
                                    {'CONTRATACIÓN $:'}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.textright}>
                                <Typography variant="caption" component="h6">
                                    {Estadoimporte_contratacion_pesos === undefined ? '0,00' :
                                        Estadoimporte_contratacion_pesos.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 16,
                                        })}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </Table>
                    <Table size={'small'} style={{color: '#ffffff', backgroundColor: '#7CC1FF'}}>
                        <TableRow>
                            <TableCell style={{width: '10%'}} className={classes.textright}>
                                <Typography variant="headline" component="h6" className={classes.textright}>
                                    {'CONCEPTO:'}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="caption" component="h6" className={classes.textleft}>
                                    {Estadoconcepto_descripcion}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </Table>
                </DialogTitle>
                <DialogContent>
                    <Timeline align="alternate">
                        {ObjetoHistorial.map(recordseguimiento => {
                            return (
                                <TimelineItem>
                                    <TimelineOppositeContent>
                                        <Typography variant="headline" component="h4">
                                            {recordseguimiento.secuencia_fecha_cumplido}
                                        </Typography>
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineDot
                                            style={{backgroundColor: recordseguimiento.secuencia_cumplido === undefined ||
                                                recordseguimiento.es_ultimo_seguimiento === undefined ||
                                                recordseguimiento.secuencia_cumplido_estado === undefined ? '#f2ff00':
                                                    recordseguimiento.secuencia_cumplido.trim() === 'S' &&
                                                    recordseguimiento.secuencia_cumplido_estado.trim() === 'EN-PLAZO'  ?
                                                        recordseguimiento.es_ultimo_seguimiento === 'S' ? '#02a600': '#02a600':
                                                            recordseguimiento.secuencia_cumplido.trim() === 'S' &&
                                                            recordseguimiento.secuencia_cumplido_estado.trim() === 'DEMORADO' ?
                                                                recordseguimiento.es_ultimo_seguimiento === 'S' ? '#02a600':  '#02a600':
                                                            // '#ff0000':
                                                            '#004187'
                                            }}
                                        >
                                            {recordseguimiento.secuencia_cumplido === undefined ||
                                             recordseguimiento.es_ultimo_seguimiento === undefined ? '' :
                                                recordseguimiento.secuencia_cumplido.trim() === 'S' ?
                                                    recordseguimiento.es_ultimo_seguimiento === 'S' ?
                                                        <StyledBadge
                                                            overlap="circular"
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'right',
                                                            }}
                                                            variant="dot"
                                                        >
                                                            <Avatar variant="rounded" className={classes.rounded}>
                                                                <CheckCircleOutlineIcon
                                                                    style={{backgroundColor: '#02a600'}}
                                                                />
                                                            </Avatar>
                                                        </StyledBadge>
                                                        :
                                                        <CheckCircleOutlineIcon/>
                                                    :
                                                    <RadioButtonUncheckedIcon/>
                                            }

                                        </TimelineDot>
                                        {recordseguimiento.maxid === undefined ? '' :
                                            recordseguimiento.maxid === recordseguimiento.id ? '' :
                                                <TimelineConnector
                                                    style={{backgroundColor: recordseguimiento.estado_color_secuencia_tipo, padding: '0 5px'}}/>
                                        }
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Paper elevation={3} className={classes.paper}>
                                            {/*<Typography variant="headline" component="h6">*/}
                                            {/*    {recordseguimiento.secuencia_fecha_cumplido}*/}
                                            {/*</Typography>*/}
                                            <Typography variant="headline" component="h6">{recordseguimiento.secuencia_d}</Typography>
                                            {/*{recordseguimiento.secuencia_cumplido === undefined ? '' :*/}
                                            {/*    recordseguimiento.secuencia_cumplido.trim() === 'S' ?*/}
                                            {/*        <Typography variant="headline" component="h6">*/}
                                            {/*            {recordseguimiento.secuencia_cumplido_estado}*/}
                                            {/*        </Typography>*/}
                                            {/*        : ''*/}
                                            {/*}*/}
                                            <Typography>
                                            {recordseguimiento.secuencia_observacion === null ?
                                                recordseguimiento.secuencia_cumplido === undefined ? '' :
                                                    recordseguimiento.secuencia_cumplido.trim() === 'S' ?
                                                        <Typography variant="headline" component="h6">
                                                            {recordseguimiento.secuencia_cumplido_estado_mjs}
                                                        </Typography>
                                                        : ''
                                                 :
                                                recordseguimiento.secuencia_observacion === '' ?
                                                    recordseguimiento.secuencia_cumplido === undefined ? '' :
                                                        recordseguimiento.secuencia_cumplido.trim() === 'S' ?
                                                            <Typography variant="headline" component="h6">
                                                                {recordseguimiento.secuencia_cumplido_estado_mjs}
                                                            </Typography>
                                                            : ''
                                                     :
                                                    <div className={classes.root2}>
                                                        <Accordion>
                                                            <AccordionSummary
                                                                classes={{ content: classes.content }}
                                                                expandIcon={<VisibilityIcon style={{color: '#02a600'}}/>}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                {recordseguimiento.secuencia_cumplido === undefined ? '' :
                                                                    recordseguimiento.secuencia_cumplido.trim() === 'S' ?
                                                                    <Typography variant="headline" component="h6">
                                                                        {recordseguimiento.secuencia_cumplido_estado_mjs}
                                                                    </Typography>
                                                                    : ''
                                                                }
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                {/*<TextField*/}
                                                                {/*    label="Observacion"*/}
                                                                {/*    value={recordseguimiento.secuencia_observacion}*/}
                                                                {/*    multiline*/}
                                                                {/*    fullWidth*/}
                                                                {/*    inputProps={{readOnly: true}}*/}
                                                                {/*    SelectProps={{native: true,}}*/}
                                                                {/*/>*/}
                                                                <Typography variant="headline" component="h6" display="block"
                                                                            gutterBottom>
                                                                    {recordseguimiento.secuencia_observacion}
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                            }
                                            </Typography>
                                        </Paper>
                                        {/*<Typography>{recordseguimiento.secuencia_d}</Typography>*/}
                                    </TimelineContent>
                                </TimelineItem>
                            );
                        })}
                        {/*<TimelineItem>*/}
                        {/*    <TimelineSeparator>*/}
                        {/*        <TimelineDot />*/}
                        {/*        <TimelineConnector />*/}
                        {/*    </TimelineSeparator>*/}
                        {/*    <TimelineContent>Eat</TimelineContent>*/}
                        {/*</TimelineItem>*/}
                        {/*<TimelineItem>*/}
                        {/*    <TimelineSeparator>*/}
                        {/*        <TimelineDot color="primary" />*/}
                        {/*        <TimelineConnector />*/}
                        {/*    </TimelineSeparator>*/}
                        {/*    <TimelineContent>Code</TimelineContent>*/}
                        {/*</TimelineItem>*/}
                        {/*<TimelineItem>*/}
                        {/*    <TimelineSeparator>*/}
                        {/*        <TimelineDot color="secondary" />*/}
                        {/*        <TimelineConnector />*/}
                        {/*    </TimelineSeparator>*/}
                        {/*    <TimelineContent>Sleep</TimelineContent>*/}
                        {/*</TimelineItem>*/}
                        {/*<TimelineItem>*/}
                        {/*    <TimelineSeparator>*/}
                        {/*        <TimelineDot />*/}
                        {/*    </TimelineSeparator>*/}
                        {/*    <TimelineContent>Repeat</TimelineContent>*/}
                        {/*</TimelineItem>*/}


                    </Timeline>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleCloseSeguimiento}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <ExitToAppIcon style={{color: '#004187'}}/>
                            Salir
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

SeguimientoCompletoButton.defaultProps = {
    source: '',
    addLabel: true,
};

export default SeguimientoCompletoButton;