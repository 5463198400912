import React, {Component, useState, useEffect, useRef, useCallback} from 'react';
import {
    useVersion,
    useDataProvider,
    useRefresh,
    WithPermissions,
    Responsive,
    Loading,
    Error,
    Button,
    Link,
    FormTab
} from 'react-admin';
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import RefreshIcon from '@material-ui/icons/Refresh';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import cartuchonewlargo from "./cartuchonewlargo.png";
import PantallaMensaje from "../componentes/PantallaMensaje";
import List from "@material-ui/core/List";
import {makeStyles, withStyles, useTheme} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Chip from '@material-ui/core/Chip';
import {styled} from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Imagetv from '../layout/tvpublica_logo_2021.bmp'; // Import using relative path
import Imagerta from '../layout/headerRTA.jpg';
import Imagec12 from '../layout/Canal_12_Trenque_Lauquen.bmp';
import Imagerna from '../layout/Radio_Nacional_Logo_2021.jpg';
import Imagetec from '../layout/logos/logo_tecnopolis.png';
import NumberFormat from 'react-number-format';
import TextField from "@material-ui/core/TextField";
import WebAssetIcon from '@material-ui/icons/WebAsset';
import AvatarImage from '../componentes/Avatar/AvatarImage'
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import {Drawer} from "@material-ui/core";
import TableroControlContratacionGerenteInicioDataGrid from "./TableroControlContratacionGerenteInicioDataGrid";
import IconButton from "@material-ui/core/IconButton";
import WebIcon from "@material-ui/icons/Web";
import CardIcon from "./CardIcon";
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from "@material-ui/core/CardMedia";
import Imageprincipalrta from '../layout/headerRTA.jpg';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PropTypes from "prop-types";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: 300,
    },
    root2: {
        maxWidth: 345,
        backgroundColor: '#FEF3B9'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        // padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
}));

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        right: 150,
        top: 150,
        border: `2px solid ${theme.palette.background.paper}`,
        // padding: '10 40px',
    },
}));


const StyledBadge2 = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const SmallAvatar = withStyles((theme) => ({
    root: {
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
    },
}))(Avatar);

const useStyles2 = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const role_contratacion_consulta_menu = ({permissions}) => {
    var w_role_contratacion_consulta_menu = ''
    var w_permissions_contratacion_consulta_menu = permissions
    if (w_permissions_contratacion_consulta_menu == null) {
    } else {
        var w_role_contratacion_consulta_menu_aux = w_permissions_contratacion_consulta_menu.indexOf('ROLE_CONTRATACION_CONSULTA_MENU')
        if (w_role_contratacion_consulta_menu_aux === -1) {
        } else {
            w_role_contratacion_consulta_menu = 'ROLE_CONTRATACION_CONSULTA_MENU'
        }
    }
    return w_role_contratacion_consulta_menu
}

const TableroControlContratacionGerenteInicio = (props) => {

    const refresh = useRefresh()
    const classes = useStyles();
    const classes2 = useStyles2();
    // const [state, setState] = useState({});
    // const version = useVersion();
    const dataProviderConGerIniEstado = useDataProvider();
    // const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    // const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    var w_anoactual = new Date().getFullYear()
    let wf_anoactual = w_anoactual.toString();

    var w_p_con_ger_ini_estado_currenciesunidadnegocio = props.p_untableroTableroControl

    // console.log('w_p_con_ger_ini_estado_currenciesunidadnegocio')
    // console.log(w_p_con_ger_ini_estado_currenciesunidadnegocio)

    if (w_p_con_ger_ini_estado_currenciesunidadnegocio[0] === undefined || w_p_con_ger_ini_estado_currenciesunidadnegocio === []) {
    } else {
        refresh()
    }

    if (w_p_con_ger_ini_estado_currenciesunidadnegocio[0].unidad_negocio === undefined) {
    } else {
        refresh()
    }

    // var w_p_con_ger_ini_estado_currenciesunidadarea = props.p_uatableroTableroControl
    //
    // console.log('w_p_con_ger_ini_estado_currenciesunidadarea')
    // console.log(w_p_con_ger_ini_estado_currenciesunidadarea)
    //
    // if (w_p_con_ger_ini_estado_currenciesunidadarea[0] === undefined) {
    // } else {
    //     refresh()
    // }
    //
    // if (w_p_con_ger_ini_estado_currenciesunidadarea === []) {
    //     console.log('w_p_con_ger_ini_estado_currenciesunidadarea--- []')
    // } else {
    //     console.log('w_p_con_ger_ini_estado_currenciesunidadarea--- []---refresh')
    //     refresh()
    // }
    //
    // if (w_p_con_ger_ini_estado_currenciesunidadarea[0].unidad_area === undefined) {
    // } else {
    //     refresh()
    // }

    var w_p_con_ger_ini_estado_unidad_negocio_usu = props.p_untableroTableroControl[0].unidad_negocio

    // /var w_p_con_ger_ini_estado_unidad_area_usu = props.p_uatableroTableroControl[0].unidad_area

    var w_p_con_ger_ini_estado_currenciesano = props.p_udntableroTableroControl

    var w_p_con_ger_ini_estado_currenciesano_contratacion_ano = ''

    if (w_p_con_ger_ini_estado_currenciesano[0] === undefined) {
    } else {
        w_p_con_ger_ini_estado_currenciesano_contratacion_ano = w_p_con_ger_ini_estado_currenciesano[0].contratacion_ano
    }


    var w_p_con_ger_ini_estado_role_admuno_unidad_negocio_todas = ''

    // alert(w_p_con_ger_ini_estado_role_admuno_unidad_negocio_todas)

    var w_p_con_ger_ini_estado_role_admuno_unidad_area_todas = ''

    // alert(w_p_con_ger_ini_estado_role_admuno_unidad_area_todas)

    const [EstadoFecha, setEstadoFecha] = useState(wf_anoactual)
    const [EstadoDetalleConGerIniEstado, setEstadoDetalleConGerIniEstado] = useState(1);
    const [EstadoAreaConGerIniEstado, setEstadoAreaConGerIniEstado] = useState(1);
    const [untableroConGerIniEstado, setUNTableroConGerIniEstado] = useState([]);
    const [loadingConGerIniEstado, setLoadingConGerIniEstado] = useState(true);
    const [errorConGerIniEstado, setErrorConGerIniEstado] = useState();


    const [ActualizarCircularProgressConGerIniEstado, setActualizarCircularProgressConGerIniEstado] = useState(false);

    const [ActualizarConGerIniEstado, setActualizarConGerIniEstado] = useState(false);
    const [ConGerInicurrenciesunidadnegocio, setConGerInicurrenciesunidadnegocio] = useState(w_p_con_ger_ini_estado_currenciesunidadnegocio);
    const [ConGerInicurrenciesunidadarea, setConGerInicurrenciesunidadarea] = useState([]);
    const [ConGerInicurrenciesano, setConGerInicurrenciesano] = useState(w_p_con_ger_ini_estado_currenciesano);

    const [ConGerIniUnidadNegocio, setConGerIniUnidadNegocio] = useState(w_p_con_ger_ini_estado_unidad_negocio_usu);
    const [ConGerIniUnidadArea, setConGerIniUnidadArea] = useState('');
    const [ConGerIniOrdenCompraAno, setConGerIniOrdenCompraAno] = useState('');

    const [ConGerIniroleadmunounidadnegociotodas, setConGerIniroleadmunounidadnegociotodas] = useState(w_p_con_ger_ini_estado_role_admuno_unidad_negocio_todas)
    const [ConGerIniroleadmunounidadareatodas, setConGerIniroleadmunounidadareatodas] = useState(w_p_con_ger_ini_estado_role_admuno_unidad_area_todas)

    const [valueBottomNavigation, setValueBottomNavigation] = React.useState(null);
    const [valueOpenBottomNavigation, setValueOpenBottomNavigation] = React.useState('');

    const [valueOpenBottomNavigationpage, setValueOpenBottomNavigationPage] = useState(1);
    const [valueOpenBottomNavigationperPage, setValueOpenBottomNavigationPerPage] = useState(10);

    const [openBottomNavigation, setOpenBottomNavigation] = useState(false);

    const handleDrawerOpenBottomNavigation = (recordbn) => {
        setActualizarCircularProgressConGerIniEstado(true);
        setOpenBottomNavigation(false);
        setValueOpenBottomNavigationPage(1);
        setValueOpenBottomNavigationPerPage(10);
        // console.log(recordbn)
        setTimeout(() => {
            setValueOpenBottomNavigation(recordbn)
            setOpenBottomNavigation(true);
            setActualizarCircularProgressConGerIniEstado(false);
        }, 60 * 0.01 * 1000);//Digamos que quieres esperar al menos 30 segundo

    };

    const handleDrawercloseBottomNavigation = () => {
        setOpenBottomNavigation(false);
        setValueBottomNavigation(null);
        setValueOpenBottomNavigationPage(1);
        setValueOpenBottomNavigationPerPage(10);
    };

    const handleClickActualizarCircularProgressConGerIniEstado = () => {
        setActualizarCircularProgressConGerIniEstado(true);
        CambiaEstadoConGerIniEstado()
    };

    const handleDrawerCloseActualizarCircularProgressConGerIniEstado = () => {
        if (ActualizarCircularProgressConGerIniEstado === true) {
            setActualizarCircularProgressConGerIniEstado(false);
        } else {
            setActualizarCircularProgressConGerIniEstado(true);
        }
    };

    const CambiaEstadoConGerIniEstado = () => {
        setEstadoDetalleConGerIniEstado(EstadoDetalleConGerIniEstado + 1);
    };

    setTimeout(() => {
        CambiaEstadoConGerIniEstado()
    }, 60 * 2 * 1000);//Digamos que quieres esperar al menos 2 minutos

    useEffect(() => {

        // fetchOrders_pesos_uni_neg_TableroControl()

        dataProviderConGerIniEstado.getList(
            'TableroControlContratacionEncuadreEstado',
            {
                filter: {
                    filtros: 'N', ingreso_por: 'G',
                    unidad_negocio_ing: ConGerIniUnidadNegocio,
                    unidad_area_ing: ConGerIniUnidadArea,
                    contratacion_ano_ing: EstadoFecha
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setUNTableroConGerIniEstado(data);
                setLoadingConGerIniEstado(false);
                setActualizarCircularProgressConGerIniEstado(false);
            })
            .catch(error => {
                setErrorConGerIniEstado(error);
                setLoadingConGerIniEstado(false);
                setActualizarCircularProgressConGerIniEstado(false);

            })

    }, [EstadoDetalleConGerIniEstado]);

    useEffect(() => {
        dataProviderConGerIniEstado.getList(
            'UnidadArea',
            {
                filter: {
                    filtros: 'N', ingreso_por: 'Z', usar_todos: 'S',
                    unidad_negocio_ing: ConGerIniUnidadNegocio
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                // console.log('UnidadArea----data')
                // console.log(data)
                setConGerInicurrenciesunidadarea(data);
                setLoadingConGerIniEstado(false);
            })
            .catch(error => {
                setErrorConGerIniEstado(error);
                setLoadingConGerIniEstado(false);
                setActualizarCircularProgressConGerIniEstado(false);

            })
    }, [EstadoAreaConGerIniEstado]);


    if (loadingConGerIniEstado) return <Loading/>;                // no cargo todavia

    if (errorConGerIniEstado) {
        return <Error/>;                           // error
    }
    ;

    const Cambiaunidadnegocio = (wp_value) => {
        setConGerIniUnidadNegocio(wp_value)
        setConGerIniUnidadArea('')
        setEstadoAreaConGerIniEstado(EstadoAreaConGerIniEstado + 1)
    };


    const cambiaunidadnegocio = (event) => {

//---------------- el cambio de estado refresca el componente y lo usa para pasar parametro ------------------------
// -------------------------------------- Graba la nueva fecha de proceso ------------------------------------------


        var w_value = event.target.value
        Cambiaunidadnegocio(w_value)
        CambiaEstadoConGerIniEstado()
        if (openBottomNavigation === true) {
            setActualizarCircularProgressConGerIniEstado(true);
            setOpenBottomNavigation(false);
            setValueOpenBottomNavigationPage(1);
            setValueOpenBottomNavigationPerPage(10);
            setTimeout(() => {
                setOpenBottomNavigation(true);
                setActualizarCircularProgressConGerIniEstado(false);
            }, 60 * 0.01 * 1000);//Digamos que quieres esperar al menos 30 segundo
        }
    }

    const Cambiaunidadarea = (wp_value) => {
        setConGerIniUnidadArea(wp_value)
    };


    const cambiaunidadarea = (event) => {

//---------------- el cambio de estado refresca el componente y lo usa para pasar parametro ------------------------
// -------------------------------------- Graba la nueva fecha de proceso ------------------------------------------


        var w_value = event.target.value
        Cambiaunidadarea(w_value)
        CambiaEstadoConGerIniEstado()
        if (openBottomNavigation === true) {
            setActualizarCircularProgressConGerIniEstado(true);
            setOpenBottomNavigation(false);
            setValueOpenBottomNavigationPage(1);
            setValueOpenBottomNavigationPerPage(10);
            setTimeout(() => {
                setOpenBottomNavigation(true);
                setActualizarCircularProgressConGerIniEstado(false);
            }, 60 * 0.01 * 1000);//Digamos que quieres esperar al menos 30 segundo
        }
    }

    const cambiaano = (event) => {

//---------------- el cambio de estado refresca el componente y lo usa para pasar parametro ------------------------
// -------------------------------------- Graba la nueva fecha de proceso ------------------------------------------
        const CambiaFecha = (wp_value) => {
            setEstadoFecha(wp_value)
        };


        var w_value = event.target.value
        CambiaFecha(w_value)
        CambiaEstadoConGerIniEstado()
        if (openBottomNavigation === true) {
            setActualizarCircularProgressConGerIniEstado(true);
            setOpenBottomNavigation(false);
            setValueOpenBottomNavigationPage(1);
            setValueOpenBottomNavigationPerPage(10);
            setTimeout(() => {
                setOpenBottomNavigation(true);
                setActualizarCircularProgressConGerIniEstado(false);
            }, 60 * 0.01 * 1000);//Digamos que quieres esperar al menos 30 segundo
        }
    }


    const cambiaAno = (event) => {

//---------------- el cambio de estado refresca el componente y lo usa para pasar parametro ------------------------
// -------------------------------------- Graba la nueva fecha de proceso ------------------------------------------

        const CambiaFecha = (wp_value) => {
            setEstadoFecha(wp_value)
        };

        var w_value = event.target.value

        if (w_value >= 1753 && w_value <= 9999) {
            CambiaFecha(w_value)
            CambiaEstadoConGerIniEstado()
        }
    }

    //----------- si ya tiene los datos cargados en forma asincrónica actualiza el DOM --------------

    var today = new Date();
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";
    var seconds = today.getSeconds() + "";

    day = checkZero(day);
    month = checkZero(month);
    year = checkZero(year);
    hour = checkZero(hour);
    minutes = checkZero(minutes);
    seconds = checkZero(seconds);

    function checkZero(data) {
        if (data.length == 1) {
            data = "0" + data;
        }
        return data;
    }

    var fecha_hora = day + "/" + month + "/" + year + " " + hour + ":" + minutes + ":" + seconds
    var cant_todas = 0
    var cant_en_ejecucion = 0
    var cant_en_finalizadas = 0
    var cant_dadas_de_baja = 0
    var importe_en_ejecucion = 0
    var importe_en_finalizadas = 0
    var importe_dadas_de_baja = 0
    if (untableroConGerIniEstado.length > 0) {
        var untableroListConGerIniEstado = untableroConGerIniEstado

        const aggregationsFacturasProveedores = untableroConGerIniEstado
            .reduce(
                (stats, order) => {
                    stats.fecha_hora = order.fecha_hora
                    stats.cant_en_ejecucion = order.cant_en_ejecucion
                    stats.cant_en_finalizadas = order.cant_en_finalizadas
                    stats.cant_dadas_de_baja = order.cant_dadas_de_baja
                    stats.importe_en_ejecucion = order.importe_en_ejecucion
                    stats.importe_en_finalizadas = order.importe_en_finalizadas
                    stats.importe_dadas_de_baja = order.importe_dadas_de_baja


                    // console.log(order)
                    return stats;
                },
                {
                    fecha_hora: '',
                    cant_en_ejecucion: 0,
                    cant_en_finalizadas: 0,
                    cant_dadas_de_baja: 0,
                    importe_en_ejecucion: '$0.00',
                    importe_en_finalizadas: '$0.00',
                    importe_dadas_de_baja: '$0.00'
                }
            );


        fecha_hora = aggregationsFacturasProveedores.fecha_hora
        cant_en_ejecucion = aggregationsFacturasProveedores.cant_en_ejecucion
        cant_en_finalizadas = aggregationsFacturasProveedores.cant_en_finalizadas
        cant_dadas_de_baja = aggregationsFacturasProveedores.cant_dadas_de_baja

        importe_en_ejecucion = aggregationsFacturasProveedores.importe_en_ejecucion
        importe_en_finalizadas = aggregationsFacturasProveedores.importe_en_finalizadas
        importe_dadas_de_baja = aggregationsFacturasProveedores.importe_dadas_de_baja

        cant_todas = cant_en_ejecucion + cant_en_finalizadas + cant_dadas_de_baja

        return (
            <div>
                <div>
                    <Card>
                        {/*Encabezado de la pantalla*/}
                        <CardContent style={{paddingBottom: '0px'}}>
                            {/*<Table style={{ background: `url(${cartuchonewlargo}) no-repeat`}}>*/}
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{backgroundColor: '#FEF3B9', borderTopRightRadius: '48%'}}>
                                            <Typography variant="headline" component="h3">
                                                <Card className={classes.root2}>
                                                    <CardHeader
                                                        avatar={
                                                            <WebAssetIcon
                                                                // src={`${Imagetvp}?size=${40}x${40}`}
                                                                size={40}
                                                                style={{width: 40, height: 40, color: '#7CC1FF'}}
                                                            />
                                                        }
                                                        action={
                                                            <div>
                                                                {/*<CardMedia*/}
                                                                {/*    style={{*/}
                                                                {/*        width: "auto",*/}
                                                                {/*        maxHeight: "40px"*/}
                                                                {/*    }}*/}
                                                                {/*    component="img"*/}
                                                                {/*    image={Imageprincipalrta}*/}
                                                                {/*    title="RTA"*/}
                                                                {/*/>*/}
                                                            </div>
                                                        }
                                                        title="Contrataciones"
                                                        subheader={EstadoFecha === '0000' ? 'Todos los Años' : 'Año: ' + EstadoFecha}
                                                    />
                                                </Card>

                                                {/*<AvatarImage medio={'PRTA'}/>*/}
                                                {/*{'Contrataciones'}*/}
                                                {/*</Typography>*/}
                                                {/*<Typography variant="headline" component="h2">*/}
                                                {/*{<WebAssetIcon*/}
                                                {/*    // src={`${Imagetvp}?size=${40}x${40}`}*/}
                                                {/*    size={40}*/}
                                                {/*    style={{width: 40, height: 40, color: '#7CC1FF'}}*/}
                                                {/*/>}*/}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {/*<Typography variant="headline" component="h3">*/}

                                            {/*</Typography>*/}
                                            <AvatarImage medio={ConGerIniUnidadNegocio}/>
                                        </TableCell>
                                        <TableCell>
                                            <div>{`Año`}</div>
                                            <TextField
                                                id="ano_ing"
                                                select
                                                label=""
                                                size="small"
                                                // value={currency}
                                                value={EstadoFecha}
                                                onChange={cambiaano}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                // helperText="Por favor seleccione su Mes"
                                            >
                                                {w_p_con_ger_ini_estado_currenciesano.map((option) => (
                                                    <option key={option.contratacion_ano}
                                                            value={option.contratacion_ano}>
                                                        {option.contratacion_ano_d}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                        <TableCell>
                                            {ConGerInicurrenciesunidadnegocio.length === 1 ? '' :
                                                <div>
                                                    <div>{`Unidad Negocio`}</div>
                                                    <TextField
                                                        id="ord_pag_unidad_negocio_ing"
                                                        select
                                                        label=""
                                                        size="small"
                                                        // value={currency}
                                                        value={ConGerIniUnidadNegocio}
                                                        onChange={cambiaunidadnegocio}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        // helperText="Por favor seleccione su Mes"
                                                    >
                                                        {ConGerInicurrenciesunidadnegocio.map((option) => (
                                                            <option key={option.unidad_negocio}
                                                                    value={option.unidad_negocio}>
                                                                {option.unidad_negocio_d}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                </div>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {ConGerInicurrenciesunidadarea.length === 1 ? '' :
                                                <div>
                                                    <div>{`Unidad Area`}</div>
                                                    <TextField
                                                        id="unidad_area_ing"
                                                        select
                                                        label=""
                                                        size="small"
                                                        // value={currency}
                                                        value={ConGerIniUnidadArea}
                                                        onChange={cambiaunidadarea}
                                                        SelectProps={{
                                                            native: true,
                                                        }}
                                                        // helperText="Por favor seleccione su Mes"
                                                    >
                                                        {ConGerInicurrenciesunidadarea.map((option) => (
                                                            <option key={option.unidad_area} value={option.unidad_area}>
                                                                {option.unidad_area_d}
                                                            </option>
                                                        ))}
                                                    </TextField>
                                                </div>
                                            }
                                        </TableCell>
                                        {/*<TableCell size="small">*/}

                                        {/*</TableCell>*/}
                                        <TableCell>
                                            <div>{`Última Actualización:`}</div>
                                            <div><Chip style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                                       label={`${fecha_hora}`}
                                            />
                                            </div>
                                            <div>
                                                <Button label={'Actualizar'}
                                                        title={'Actualizar'}
                                                        onClick={handleClickActualizarCircularProgressConGerIniEstado}
                                                        style={{color: '#123B80'}}
                                                >
                                                    <RefreshIcon/>
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            {ActualizarCircularProgressConGerIniEstado === true ?
                                <PantallaMensaje
                                    p_imagen={'CircularProgress'}
                                    p_mensajePrimario={'Cargando Contratacion'}
                                    p_mensajeSecondario={'La página se está cargando, espere un momento por favor.'}
                                />
                                : ''}
                        </CardContent>

                        {/*Burbujas de estado*/}
                        <CardContent style={{padding: '0'}}>
                            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>

                                <BottomNavigation
                                    value={valueBottomNavigation}
                                    onChange={(event, newValue) => {
                                        setValueBottomNavigation(newValue);
                                    }}
                                    showLabels
                                    className={classes.root}
                                >

                                    <BottomNavigationAction label={importe_en_ejecucion}
                                                            style={{padding: '0'}}
                                                            onClick={() => {
                                                                handleDrawerOpenBottomNavigation('en_ejecucion')
                                                            }
                                                            }
                                                            icon={
                                                                <StyledBadge
                                                                    badgeContent={"EN EJECUCIÓN: " + cant_en_ejecucion}
                                                                    max={999999999999999999999999}
                                                                    color="secondary">
                                                                    <FiberManualRecordIcon
                                                                        style={{
                                                                            backgroundColor: '#ffffff',
                                                                            color: '#00b050',
                                                                            fontSize: 300
                                                                        }}
                                                                    />
                                                                </StyledBadge>
                                                            }
                                    />
                                </BottomNavigation>

                                <BottomNavigation
                                    value={valueBottomNavigation}
                                    onChange={(event, newValue) => {
                                        setValueBottomNavigation(newValue);
                                    }}
                                    showLabels
                                    className={classes.root}
                                >


                                    <BottomNavigationAction label={importe_en_finalizadas}
                                                            onClick={() => handleDrawerOpenBottomNavigation('en_finalizadas')}
                                                            icon={
                                                                <StyledBadge
                                                                    badgeContent={"FINALIZADAS: " + cant_en_finalizadas}
                                                                    max={99999999999999999999999999999999}
                                                                    color="secondary">
                                                                    <FiberManualRecordIcon
                                                                        style={{
                                                                            backgroundColor: '#ffffff',
                                                                            color: '#f9cf1c',
                                                                            fontSize: 300
                                                                        }}
                                                                    />
                                                                </StyledBadge>
                                                            }
                                    />
                                </BottomNavigation>


                                <BottomNavigation
                                    value={valueBottomNavigation}
                                    onChange={(event, newValue) => {
                                        setValueBottomNavigation(newValue);
                                    }}
                                    showLabels
                                    className={classes.root}
                                >


                                    <BottomNavigationAction label={importe_dadas_de_baja}
                                                            onClick={() => handleDrawerOpenBottomNavigation('dadas_de_baja')}
                                                            icon={
                                                                <StyledBadge
                                                                    badgeContent={"DADAS DE BAJA: " + cant_dadas_de_baja}
                                                                    max={99999999999999999999999999999999}
                                                                    color="secondary">
                                                                    <FiberManualRecordIcon
                                                                        style={{
                                                                            backgroundColor: '#ffffff',
                                                                            color: '#c00000', fontSize: 300
                                                                        }}
                                                                    />
                                                                </StyledBadge>
                                                            }
                                    />

                                </BottomNavigation>

                            </div>
                        </CardContent>

                        {/*Grilla de estado*/}
                        <CardContent autoFocus>
                            <div>
                                {openBottomNavigation === true ?
                                    <TableroControlContratacionGerenteInicioDataGrid
                                        congeriniunidadnegocio={ConGerIniUnidadNegocio}
                                        congeriniunidadarea={ConGerIniUnidadArea}
                                        congerinicurrenciesunidadnegocio={ConGerInicurrenciesunidadnegocio}
                                        congerinicurrenciesunidadarea={ConGerInicurrenciesunidadarea}
                                        estadofecha={EstadoFecha}
                                        valuebottomnavigation={valueOpenBottomNavigation}
                                        valuebottomnavigationpage={valueOpenBottomNavigationpage}
                                        valuebottomnavigationperpage={valueOpenBottomNavigationperPage}
                                        callbackCloseDrawer={handleDrawercloseBottomNavigation}
                                    />
                                    : ' '}
                            </div>
                        </CardContent>

                        <CardHeader
                            avatar={
                                <WebAssetIcon
                                    // src={`${Imagetvp}?size=${40}x${40}`}
                                    size={40}
                                    style={{width: 40, height: 40, color: '#7CC1FF'}}
                                />
                            }
                            action={<> </>}
                            title=" "
                            subheader={' '}
                        />
                    </Card>
                </div>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={styles.modal}
                    open={ActualizarConGerIniEstado}
                    onClose={handleDrawerCloseActualizarCircularProgressConGerIniEstado}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={ActualizarConGerIniEstado}>
                        <div>
                            <Table style={{
                                background: `url(${cartuchonewlargo}) no-repeat`,
                                color: '#ffffff',
                                backgroundColor: '#ffffff'
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell size="small">
                                            <Typography variant="headline" component="h2">
                                                {'Actualizando Contrataciones...'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <PantallaMensaje
                                p_imagen={'CircularProgressModalabajo'}
                                p_mensajePrimario={'Cargando Contratacion'}
                                p_mensajeSecondario={'La página se está cargando, espere un momento por favor.'}
                            />
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    } else {
        return (
            <div>
                <Table style={{background: `url(${cartuchonewlargo}) no-repeat`}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="headline" component="h2">
                                    {'Contrataciones'}
                                    {<WebAssetIcon
                                        // src={`${Imagetvp}?size=${40}x${40}`}
                                        size={40}
                                        style={{width: 40, height: 40, color: '#7CC1FF'}}
                                    />}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="headline" component="h2">
                                    {'Estado'}
                                </Typography>
                                <AvatarImage medio={ConGerIniUnidadNegocio.trim()}/>
                            </TableCell>
                            <TableCell>
                                <div>{`Año`}</div>
                                <TextField
                                    id="ano_ing"
                                    select
                                    label=""
                                    size="small"
                                    // value={currency}
                                    value={EstadoFecha}
                                    onChange={cambiaano}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    // helperText="Por favor seleccione su Mes"
                                >
                                    {w_p_con_ger_ini_estado_currenciesano.map((option) => (
                                        <option key={option.contratacion_ano} value={option.contratacion_ano}>
                                            {option.contratacion_ano_d}
                                        </option>
                                    ))}
                                </TextField>

                                <div>{`Unidad Negocio`}</div>

                                <TextField
                                    id="ord_pag_unidad_negocio_ing"
                                    select
                                    label=""
                                    size="small"
                                    // value={currency}
                                    value={ConGerIniUnidadNegocio}
                                    onChange={cambiaunidadnegocio}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    // helperText="Por favor seleccione su Mes"
                                >
                                    {ConGerInicurrenciesunidadnegocio.map((option) => (
                                        <option key={option.unidad_negocio} value={option.unidad_negocio}>
                                            {option.unidad_negocio_d}
                                        </option>
                                    ))}
                                </TextField>
                                <div>{`Unidad Area`}</div>
                                <TextField
                                    id="unidad_area_ing"
                                    select
                                    label=""
                                    size="small"
                                    // value={currency}
                                    value={ConGerIniUnidadArea}
                                    onChange={cambiaunidadarea}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    // helperText="Por favor seleccione su Mes"
                                >
                                    {ConGerInicurrenciesunidadarea.map((option) => (
                                        <option key={option.unidad_area} value={option.unidad_area}>
                                            {option.unidad_area_d}
                                        </option>
                                    ))}
                                </TextField>
                            </TableCell>

                            <TableCell>
                                <div>{`Última Actualización:`}</div>
                                <div><Chip style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                           label={`${fecha_hora}`}
                                />
                                </div>
                                <div>
                                    <Button label={'Actualizar'}
                                            title={'Actualizar'}
                                            onClick={handleClickActualizarCircularProgressConGerIniEstado}
                                            style={{color: '#123B80'}}
                                    >
                                        <RefreshIcon/>
                                    </Button>
                                </div>
                            </TableCell>
                            <TableCell>
                                {ActualizarCircularProgressConGerIniEstado === true ?
                                    <PantallaMensaje
                                        p_imagen={'CircularProgress'}
                                        p_mensajePrimario={'Cargando Contratacion'}
                                        p_mensajeSecondario={'La página se está cargando, espere un momento por favor.'}
                                    />
                                    : ''}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </div>
        );
    }
};
export default TableroControlContratacionGerenteInicio;



