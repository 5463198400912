import React, {useState} from 'react'
import {
    Loading, useQuery, Datagrid, TextField, Pagination, Button, useDelete, useRefresh
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import IconEliminar from '@material-ui/icons/Delete';
import BtnEditarSocio from './BtnEditarSocio'
import {makeStyles} from "@material-ui/core";
import ProveedorSocioEdit from './ProveedorSocioEdit'

export const ProveedorSocioList = (props) => {
        const [page, setPage] = useState(1);
        const [perPage, setPerPage] = useState(25);
        const [sort, setSort] = useState({field: 'id', order: 'ASC'})

        const useStyles = makeStyles((theme) => ({
            paper: {
                width: '28%'
            }
        }));
        const classes = useStyles();

        const {data, total, loading, error} = useQuery({
            type: 'getList',
            resource: 'ProveedorSocio',
            payload: {
                pagination: {page, perPage},
                sort,
                filter: props.pro,
            }
        });

        if (loading) {
            return <Loading/>
        }
        if (error) {
            return <p>ERROR: {error}</p>
        }

        const EditarSocio = ({record}) => {
            return (
                <>
                    <BtnEditarSocio pro={record.pro} nroSocio={record.socio_nro_ingreso}/>
                </>
            )
        };


        // Eliminar Fila
        const BtnEliminar = ({record}) => {
            const refresh = useRefresh();

            const [deleteOne, {isLoading, error}] = useDelete(
                'proveedorSocio',
                record.id
            );
            const handleClick = () => {
                deleteOne();
                refresh();
            };
            if (error) {
                return <p>ERROR</p>;
            }
            return <Button variant="text" size={"small"} color="primary"
                           onClick={handleClick}
                           disabled={loading}
                           label={'Eliminar'}
            >
                <IconEliminar style={{color: 'red'}}/>
            </Button>
        };

        return (
            <div>
                <Datagrid
                    data={keyBy(data, 'id')}
                    ids={data.map(({id}) => id)}
                    currentSort={sort}
                    setSort={(field, order) => setSort({field, order})}
                >
                    <TextField source="socio_nro_ingreso" label={"Nro Ingreso"}/>
                    <TextField source="nombre" label={"Nombre"}/>
                    <TextField source="apellido" label={"Apellido"}/>
                    <TextField source="cuit_cuil" label={"Cuit-Cuil"}/>
                    <TextField source="documento_tipo_d" label={"Tipo Doc."}/>
                    <TextField source="documento_nro" label={"Nro. Doc."}/>
                    <TextField source="correo_electronico" label={"Email"}/>
                    <TextField source="correo_electronico_alternativo" label={"Email alt."}/>
                    <TextField source="estado_civil_casado" label={"Estado Civil"}/>
                    <TextField source="socio_activo" label={"Activo S/N"}/>
                    <ProveedorSocioEdit/>
                    <BtnEliminar/>

                </Datagrid>
                <Pagination
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    total={total}
                />

            </div>
        );
    }
;

export default ProveedorSocioList;



