import React, {useState} from 'react';
import {Drawer, makeStyles} from "@material-ui/core";
import Icon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Button from '@mui/material/Button';
import ProveedorDocumentacionAgregar from './ProveedorDocumentacionAgregar'
import ProveedorDocumentacionVariasAgregar from './ProveedorDocumentacionVariasAgregar'
import IconCancelar from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '28%'
    },
    paper2: {
        width: '88%'
    }
}));

export const BtnAgregarDocumentacion = ({pro}) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openAVD, setAVDOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(false);
    };

    const handleDrawerAVDOpen = () => {
        setAVDOpen(false);
    };

    const BtnCancelar = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" marginLeft="40px" onClick={() => {
            handleDrawerAVDOpen(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );


    return (
        <div>
            {/*<div style={{display: 'inline-block', width: 400, marginLeft: 8}}>*/}
            {/*<Button variant="contained" size={"small"} style={{color: '#ffffff', backgroundColor: '#123B80'}}*/}
            {/*        onClick={() => setOpen(!open)}>*/}
            {/*    <Icon/>*/}
            {/*    Agregar Documentacion*/}
            {/*</Button>*/}
            {/*<Drawer*/}
            {/*    classes={{paper: classes.paper}}*/}
            {/*    variant="persistent"*/}
            {/*    anchor="right"*/}
            {/*    open={open}*/}
            {/*>*/}
            {/*    <div>*/}
            {/*        <h3>Agregar Documentacion</h3>*/}
            {/*    </div>*/}
            {/*    <ProveedorDocumentacionAgregar*/}
            {/*        pro={pro}*/}
            {/*        callbackCloseDrawer={handleDrawerOpen}*/}
            {/*        // callback={props.callbackArt}*/}
            {/*    />*/}

            {/*</Drawer>*/}
            {/*</div>*/}
            <div style={{display: 'inline-block', width: 600, marginLeft: 8}}>
                <Button variant="contained" size={"small"} style={{color: '#ffffff', backgroundColor: '#123B80'}}
                        onClick={() => setAVDOpen(!openAVD)}>
                    <PlaylistAddIcon/>
                    Agregar Documentación
                </Button>
                <Drawer
                    classes={{paper: classes.paper2}}
                    variant="persistent"
                    anchor="right"
                    open={openAVD}
                >
                    <div>
                        <h3>Agregar Documentación</h3>
                    </div>
                    <div style={{display: 'inline-block', width: 400, marginLeft: 8}}>
                        <BtnCancelar/>
                    </div>
                    {openAVD===true ?
                        <ProveedorDocumentacionVariasAgregar
                            pro={pro}
                            callbackCloseAVDDrawer={handleDrawerAVDOpen}
                            // callback={props.callbackArt}
                        />
                    : '' }
                </Drawer>
            </div>
        </div>
    )
};
export default BtnAgregarDocumentacion;