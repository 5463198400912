import React, {useState, useCallback} from "react";
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Create,
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    AutocompleteInput,
    SelectInput,
    DateInput,
    TopToolbar,
    ReferenceInput,
    Toolbar, SaveButton, DeleteButton,
    useGetOne, Loading, NumberInput, ShowButton
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import RubroBusquedaBtn from "../Rubro/RubroBusquedaBtn";
import TextField from '@material-ui/core/TextField';



const useStyles = makeStyles((theme) => ({
    btnBusqueda: {display: 'inline-block', width: 40, marginLeft: 48},
    clase: {display: 'inline-block', width: 200, marginLeft: 8},
    clase_d: {display: 'inline-block', width: 600, marginLeft: 108},
    rubro: {display: 'inline-block', width: 200, marginLeft: 8},
    rubro_d: {display: 'inline-block', width: 600, marginLeft: 22},
}));

const RubroClaseEdit = props => {
    const classes = useStyles();
    const [state, setState] = useState({});

    /// Búsqueda Rubro
    var w_rubro = '', w_rubro_d = '';

    const {data: Data, loading: Loading} = useGetOne('RubroClase', props.id);

    if (Data === undefined) {
    } else {
        w_rubro = Data.rubro;
        if (Data.Rubro === undefined || Data.Rubro === null) {
        } else {
            w_rubro_d = Data.rubro ? Data.Rubro.rubro_d.trim() : '';
        }

    }

    const {
        Rubro = w_rubro, RubroD = w_rubro_d,
    } = state;


    const formatRubro = v => {
        return Rubro
    };

    const formatRubroD = v => {
        return RubroD
    };

    const updateRubro = useCallback(async (who) => {
        setState(state => ({
            ...state,
            Rubro: who.rubro,
            RubroD: who.rubro_d.trim(),
        }));
    })


    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );

    const redirect = (basePath, id, data) => `/RubroClase`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            <DeleteButton undoable={false} redirect={redirect}/>
        </Toolbar>
    );
    const transform = data => {
        let wt_rubro = Rubro;


        return {
            ...data,
            wp_rubro: wt_rubro,
        }
    };

    const PostTitle = ({record}) => {
        return <span>Rubro Clase: {record ? `${record.clase_d}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={<CustomToolbar/>}
            >
                {/*Datos*/}
                <FormTab label="DATOS RUBRO CLASE">
                    <TextInput
                        label="Código Rubro"
                        source="rubro"
                        formClassName={classes.rubro}
                        placeholder="Ingrese Rubro"
                        helperText="Código Rubro"
                        margin="normal"
                        format={formatRubro}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <RubroBusquedaBtn callbackRubro={updateRubro} rubro={Rubro} formClassName={classes.btnBusqueda}/>
                    <TextField
                        id="rubro_d-read-only-input"
                        value={RubroD}
                        InputProps={{
                            readOnly: true,
                        }}
                        formClassName={classes.rubro_d}
                        variant="filled"
                        label="Descripción Rubro"
                        placeholder="Ingrese Descripción Rubro"
                        helperText="Descripción Rubro"
                        margin="normal"
                        format={formatRubroD}
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>
                    <TextInput
                        label="Código Clase"
                        source="clase"
                        formClassName={classes.clase}
                        placeholder="Ingrese Código Clase"
                        helperText="Código Clase"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <TextInput
                        label="Descripción Clase"
                        source="clase_d"
                        formClassName={classes.clase_d}
                        placeholder="Ingrese Descripción Clase"
                        helperText="Descripción Clase"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};
export default RubroClaseEdit;


