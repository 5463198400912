import React from "react";
import * as XLSX from "xlsx";
import {useDataProvider, useListContext, Button, TextField, DateField, NumberField, Datagrid} from 'react-admin';
import DownloadIcon from '@mui/icons-material/Download';

const ExportExcelButton = ({total, fileName, sheetName}) => {
    const {filterValues} = useListContext();
    const dataProvider = useDataProvider();

    let w_fileName, w_sheetName;
    fileName ? w_fileName = fileName + ".xlsx" : w_fileName = 'OrdenCompraCabecera.xlsx';
    sheetName ? w_sheetName = sheetName : w_sheetName = 'Hoja 1';

    const handleClick = () => {
        // Data para exportar
        dataProvider.getList(
            'OrdenCompraCabecera',
            {
                filter: {
                    orden_compra_nro: filterValues.orden_compra_nro,
                    razon_social: filterValues.razon_social,
                    orden_compra_ano_d: filterValues.orden_compra_ano_d,
                    unidad_negocio_d: filterValues.unidad_negocio_d,
                    id_estado_orden_compra: filterValues.id_estado_orden_compra,
                    orden_compra_ampliacion_nro: filterValues.orden_compra_ampliacion_nro,
                    orden_compra_fecha_desde: filterValues.orden_compra_fecha_desde,
                    orden_compra_fecha_hasta: filterValues.orden_compra_fecha_hasta,
                    proveedor: filterValues.proveedor,
                    abre_iva_proveedor: filterValues.abre_iva_proveedor,
                    d_iva_proveedor: filterValues.d_iva_proveedor,
                    cuit_cuil: filterValues.cuit_cuil,
                    emisora_d: filterValues.emisora_d,
                    area_d: filterValues.area_d,
                    gerencia_d: filterValues.gerencia_d,
                    sector_d: filterValues.sector_d,
                    programa_d: filterValues.programa_d,
                    contratacion_tipo: filterValues.contratacion_tipo,
                    moneda_abre: filterValues.moneda_abre,
                    q: filterValues.q
                },
                sort: {field: 'id', order: 'ASC'},
                pagination: {page: 1, perPage: total},
            },)
            .then(({data}) => {
                downloadExcel(data);
            })
            .catch(error => {
                console.log(error)
            });
    };

    // JN Creo un nuevo JSON sólo con los campos que quiero mostrar en el excel
    // Ajusta el ancho de las columnas automaticamente segun el contenido
    const fitToColumn = data_export => {
        const columnWidths = [];
        for (const property in data_export[0]) {
            columnWidths.push({wch: Math.max(property ? property.toString().length : 0, ...data_export.map(obj => obj[property] ? obj[property].toString().length : 0))});
        }
        return columnWidths;
    };

    const downloadExcel = (data) => {
        let data_export = data.map(({
                                        orden_compra_nro,
                                        orden_compra_ampliacion_nro,
                                        orden_compra_fecha,
                                        orden_compra_ano,
                                        proveedor_rta,
                                        razon_social,
                                        abre_iva_proveedor,
                                        d_iva_proveedor,
                                        cuit_cuil,
                                        unidad_negocio_d,
                                        emisora_d,
                                        area_d,
                                        gerencia_d,
                                        sector_d,
                                        programa_d,
                                        contratacion_tipo,
                                        moneda_abre,
                                        importe_total,
                                        es_ampliacion,
                                        orden_compra_estado
                                    }) => ({
            orden_compra_nro,
            orden_compra_ampliacion_nro,
            orden_compra_fecha,
            orden_compra_ano,
            proveedor_rta,
            razon_social,
            abre_iva_proveedor,
            d_iva_proveedor,
            cuit_cuil,
            unidad_negocio_d,
            emisora_d,
            area_d,
            gerencia_d,
            sector_d,
            programa_d,
            contratacion_tipo,
            moneda_abre,
            importe_total,
            es_ampliacion,
            orden_compra_estado
        }));

        const worksheet = XLSX.utils.json_to_sheet(data_export);
        worksheet['!cols'] = fitToColumn(data_export);

        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, w_sheetName);
        XLSX.utils.sheet_add_aoa(worksheet, [["OC Número", "Nro Ampliación", "Fecha OC", "Año", "Proveedor", "Razón Social", "IVA Tipo", "IVA Categoría", "Cuit-Cuil", "Unidad", "Emisora", "Area", "Gerencia", "Sector", "Programa", "Tipo Contratacion", "Moneda", "Importe Total", "Es Ampliación?", "Estado"]], {origin: "A1"});

        XLSX.writeFile(workbook, w_fileName);
    };
    return (
        <Button
            onClick={() => handleClick()}
            label="excel"
        >
            <DownloadIcon/>
        </Button>
    )
};

export default ExportExcelButton;
