import React from 'react';
import Grid from '@material-ui/core/Grid';
import VerInfo from './VerInfo'

// Método que carga más datos de cada registro. Haciendo Click en el registro se expande hacia abajo.
const OrdenCompraCabeceraInfo = ({record, ...rest}) => (
    <Grid container spacing={12}>
        <Grid container spacing={12}>
            <Grid container spacing={12}>
                <Grid item xs={12}>
                    <VerInfo
                        ver_orden_compra_estado={record.orden_compra_estado}
                        ver_estado_d={record.estado_d}
                        ver_orden_compra_estado_usuario={record.orden_compra_estado_usuario}
                        ver_orden_compra_estado_usuario_fh={record.orden_compra_estado_usuario_fh}
                        ver_orden_compra_origen_nro={record.orden_compra_origen_nro}
                        ver_orden_compra_origen_ampliacion_nro={record.orden_compra_origen_ampliacion_nro}
                        ver_contratacion_d={record.contratacion_d}
                        ver_contratacion_nro={record.contratacion_nro}
                        ver_contratacion_ano={record.contratacion_ano}
                        ver_procedimeinto_referencia_gde={record.procedimeinto_referencia_gde}
                        ver_informe_adjudicacion_referencia_gde={record.informe_adjudicacion_referencia_gde}
                        ver_codigo_pro_tvpublica={record.codigo_pro_tvpublica}
                        ver_codigo_pro_rna={record.codigo_pro_rna}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>

);
export default OrdenCompraCabeceraInfo;
