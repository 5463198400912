import React from 'react';
import Grid from '@material-ui/core/Grid';
import VerInfoDetalle from './VerInfoDetalle'

// Método que carga más datos de cada registro. Haciendo Click en el registro se expande hacia abajo.
const OrdenCompraDetalleInfo = ({record, ...rest}) => (

    <Grid container spacing={12}>
        <Grid container spacing={12}>
            <Grid container spacing={12}>
                <Grid item xs={12}>
                    <VerInfoDetalle
                        ver_item={record.item}
                        ver_concepto_requerimiento={record.concepto_requerimiento}
                        ver_clase={record.clase}
                        ver_renglon_nro={record.renglon_nro}
                        ver_renglon_descripcion={record.renglon_descripcion}
                        ver_unidad_medida={record.unidad_medida}
                        ver_unidad_medida_abre={record.unidad_medida_abre}
                        ver_cantidad={record.cantidad}
                        ver_fecha_entrega={record.fecha_entrega}
                        ver_moneda={record.moneda_detalle}
                        ver_cotizacion={record.cotizacion_detalle}
                        ver_precio_unitario_sin_iva={record.precio_unitario_sin_iva}
                        ver_descuento_porcentaje={record.descuento_porcentaje}
                        ver_precio_unitario_sin_iva_con_dto={record.precio_unitario_sin_iva_con_dto}
                        ver_iva_porcentaje={record.iva_porcentaje}
                        ver_iva_importe={record.iva_importe}
                        ver_total_item={record.total_item}
                        ver_total_importe_iva_pesos={record.total_importe_iva_pesos}
                        ver_total_item_pesos={record.total_item_pesos}
                    />
                </Grid>
            </Grid>
        </Grid>
    </Grid>

);
export default OrdenCompraDetalleInfo;
