import React from 'react';
import {Link} from 'react-admin';
import VisibilityIcon from '@material-ui/icons/Visibility';

const LinkMostrarButton = ({record}) => {
    return (
        <Link to={`/OrdenCompraCabecera/${record.id}/show`}>
            <div title={''}
                 style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
            >
                <VisibilityIcon style={{fontSize: 20}}/> Mostrar
            </div>
        </Link>
    );
}

LinkMostrarButton.defaultProps = {
    source: '',
    addLabel: true,
};

export default LinkMostrarButton;