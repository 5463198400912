import React, {Component, useState, useEffect, useRef, useCallback} from 'react';
import {
    useVersion,
    useDataProvider,
    useRefresh,
    WithPermissions,
    Responsive,
    Loading,
    Error,
    Button,
    Link,
    FormTab
} from 'react-admin';
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import RefreshIcon from '@material-ui/icons/Refresh';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import cartuchonewlargo from "./cartuchonewlargo.png";
import PantallaMensaje from "../componentes/PantallaMensaje";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Chip from '@material-ui/core/Chip';
import {styled} from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Imagetv from '../layout/tvpublica_logo_2021.bmp'; // Import using relative path
import Imagerta from '../layout/headerRTA.jpg';
import Imagec12 from '../layout/Canal_12_Trenque_Lauquen.bmp';
import Imagerna from '../layout/Radio_Nacional_Logo_2021.jpg';
import Imagetec from '../layout/logos/logo_tecnopolis.png';
import NumberFormat from 'react-number-format';
import TextField from "@material-ui/core/TextField";
import WebAssetIcon from '@material-ui/icons/WebAsset';
import AvatarImage from '../componentes/Avatar/AvatarImage'

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    title: {
        flexGrow: 1,
    },
    hide: {
        display: 'none',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
}));

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const role_contratacion_consulta_menu = ({permissions}) => {
    var w_role_contratacion_consulta_menu = ''
    var w_permissions_contratacion_consulta_menu = permissions
    if(w_permissions_contratacion_consulta_menu==null){}else{
        var w_role_contratacion_consulta_menu_aux = w_permissions_contratacion_consulta_menu.indexOf('ROLE_CONTRATACION_CONSULTA_MENU')
        if(w_role_contratacion_consulta_menu_aux===-1){}else{
            w_role_contratacion_consulta_menu = 'ROLE_CONTRATACION_CONSULTA_MENU'
        }
    }
    return w_role_contratacion_consulta_menu
}


const TableroControlContratacionEncuadreEstado = (props) => {

    const classes = useStyles();
    // const [state, setState] = useState({});
    // const version = useVersion();
    const dataProviderOrdComEstado = useDataProvider();
    // const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    // const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    var w_anoactual = new Date().getFullYear()
    let wf_anoactual = w_anoactual.toString();
    var w_p_ord_com_estado_unidad_negocio_usu = ''
    var w_p_ord_com_estado_currenciesunidadnegocio = props.p_untableroTableroControl
    var w_p_ord_com_estado_currenciesano = props.p_udntableroTableroControl

    var w_p_ord_com_estado_currenciesano_contratacion_ano = ''

    if (w_p_ord_com_estado_currenciesano[0] === undefined) {
    } else {
        w_p_ord_com_estado_currenciesano_contratacion_ano = w_p_ord_com_estado_currenciesano[0].contratacion_ano
    }


    var w_p_ord_com_estado_role_admuno_unidad_negocio_todas = ''

    // alert(w_p_ord_com_estado_role_admuno_unidad_negocio_todas)
    const [EstadoFecha, setEstadoFecha] = useState(wf_anoactual)
    const [EstadoDetalleOrdComEstado, setEstadoDetalleOrdComEstado] = useState(1);
    const [untableroOrdComEstado, setUNTableroOrdComEstado] = useState([]);
    const [loadingOrdComEstado, setLoadingOrdComEstado] = useState(true);
    const [errorOrdComEstado, setErrorOrdComEstado] = useState();

    const [ActualizarOrdComEstado, setActualizarOrdComEstado] = useState(false);
    const [OrdComcurrenciesunidadnegocio, setOrdComcurrenciesunidadnegocio] = useState(w_p_ord_com_estado_currenciesunidadnegocio);
    const [OrdComcurrenciesano, setOrdComcurrenciesano] = useState(w_p_ord_com_estado_currenciesano);

    const [OrdComUnidadNegocio, setOrdComUnidadNegocio] = useState(w_p_ord_com_estado_unidad_negocio_usu);
    const [OrdComOrdenCompraAno, setOrdComOrdenCompraAno] = useState('');

    const [OrdComroleadmunounidadnegociotodas, setOrdComroleadmunounidadnegociotodas] = useState(w_p_ord_com_estado_role_admuno_unidad_negocio_todas)

    const handleClickActualizarOrdComEstado = () => {
        setActualizarOrdComEstado(true);
        CambiaEstadoOrdComEstado()
    };

    const handleDrawerCloseActualizarOrdComEstado = () => {
        if (ActualizarOrdComEstado === true) {
            setActualizarOrdComEstado(false);
        } else {
            setActualizarOrdComEstado(true);
        }
    };

    const CambiaEstadoOrdComEstado = () => {
        setEstadoDetalleOrdComEstado(EstadoDetalleOrdComEstado + 1);
    };

    setTimeout(() => {
        CambiaEstadoOrdComEstado()
    }, 60 * 2 * 1000);//Digamos que quieres esperar al menos 2 minutos

    useEffect(() => {

        // fetchOrders_pesos_uni_neg_TableroControl()

        dataProviderOrdComEstado.getList(
            'TableroControlContratacionEncuadreEstado',
            {
                filter: {
                    filtros: 'N', ingreso_por: 'Z',
                    unidad_negocio_ing: OrdComUnidadNegocio,
                    contratacion_ano_ing: EstadoFecha
                },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setUNTableroOrdComEstado(data);
                setLoadingOrdComEstado(false);
                setActualizarOrdComEstado(false);
            })
            .catch(error => {
                setErrorOrdComEstado(error);
                setLoadingOrdComEstado(false);
                setActualizarOrdComEstado(false);

            })

    }, [EstadoDetalleOrdComEstado]);


    if (loadingOrdComEstado) return <Loading/>;                // no cargo todavia

    if (errorOrdComEstado) {
        return <Error/>;                           // error
    }
    ;

    const Cambiaunidadnegocio = (wp_value) => {
        setOrdComUnidadNegocio(wp_value)
    };


    const cambiaunidadnegocio = (event) => {

//---------------- el cambio de estado refresca el componente y lo usa para pasar parametro ------------------------
// -------------------------------------- Graba la nueva fecha de proceso ------------------------------------------


        var w_value = event.target.value
        Cambiaunidadnegocio(w_value)
        CambiaEstadoOrdComEstado()

    }

    const cambiaano = (event) => {

//---------------- el cambio de estado refresca el componente y lo usa para pasar parametro ------------------------
// -------------------------------------- Graba la nueva fecha de proceso ------------------------------------------
        const CambiaFecha = (wp_value) => {
            setEstadoFecha(wp_value)
        };


        var w_value = event.target.value
        CambiaFecha(w_value)
        CambiaEstadoOrdComEstado()

    }


    const cambiaAno = (event) => {

//---------------- el cambio de estado refresca el componente y lo usa para pasar parametro ------------------------
// -------------------------------------- Graba la nueva fecha de proceso ------------------------------------------

        const CambiaFecha = (wp_value) => {
            setEstadoFecha(wp_value)
        };

        var w_value = event.target.value

        if (w_value >= 1753 && w_value <= 9999) {
            CambiaFecha(w_value)
            CambiaEstadoOrdComEstado()
        }
    }

    //----------- si ya tiene los datos cargados en forma asincrónica actualiza el DOM --------------

    var today = new Date();
    var day = today.getDate() + "";
    var month = (today.getMonth() + 1) + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";
    var seconds = today.getSeconds() + "";

    day = checkZero(day);
    month = checkZero(month);
    year = checkZero(year);
    hour = checkZero(hour);
    minutes = checkZero(minutes);
    seconds = checkZero(seconds);

    function checkZero(data) {
        if (data.length == 1) {
            data = "0" + data;
        }
        return data;
    }

    var fecha_hora = day + "/" + month + "/" + year + " " + hour + ":" + minutes + ":" + seconds
    if (untableroOrdComEstado.length > 0) {
        var untableroListOrdComEstado = untableroOrdComEstado

        const aggregationsFacturasProveedores = untableroOrdComEstado
            .reduce(
                (stats, order) => {
                    stats.fecha_hora = order.fecha_hora
                    // console.log(order)
                    return stats;
                },
                {
                    fecha_hora: '',
                }
            );


        fecha_hora = aggregationsFacturasProveedores.fecha_hora


        return (
            <div>
                <div>
                    <Card>
                        <CardContent>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{backgroundColor: '#FEF3B9', borderTopRightRadius: '50%'}}>
                                            <Typography variant="headline" component="h3">
                                                {'Contrataciones'}
                                                {/*</Typography>*/}
                                                {/*<Typography variant="headline" component="h2">*/}
                                                {<WebAssetIcon
                                                    // src={`${Imagetvp}?size=${40}x${40}`}
                                                    size={40}
                                                    style={{width: 30, height: 30}}
                                                />}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="headline" component="h3">
                                                {'Estado'}
                                            </Typography>
                                            <AvatarImage medio={OrdComUnidadNegocio}/>
                                        </TableCell>
                                        <TableCell>
                                            <div>{`Año`}</div>
                                            <TextField
                                                id="ano_ing"
                                                select
                                                label=""
                                                size="small"
                                                // value={currency}
                                                value={EstadoFecha}
                                                onChange={cambiaano}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                // helperText="Por favor seleccione su Mes"
                                            >
                                                {w_p_ord_com_estado_currenciesano.map((option) => (
                                                    <option key={option.contratacion_ano}
                                                            value={option.contratacion_ano}>
                                                        {option.contratacion_ano_d}
                                                    </option>
                                                ))}
                                            </TextField>
                                            {/*<NumberFormat*/}
                                            {/*    customInput={TextField}*/}
                                            {/*    id="ano_ing"*/}
                                            {/*    label=""*/}
                                            {/*    size="small"*/}
                                            {/*    value={EstadoFecha}*/}
                                            {/*    onChange={cambiaAno}*/}
                                            {/*    format="####"*/}
                                            {/*/>*/}
                                            <div>{`Unidad Negocio`}</div>

                                            <TextField
                                                id="ord_pag_unidad_negocio_ing"
                                                select
                                                label=""
                                                size="small"
                                                // value={currency}
                                                value={OrdComUnidadNegocio}
                                                onChange={cambiaunidadnegocio}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                // helperText="Por favor seleccione su Mes"
                                            >
                                                {OrdComcurrenciesunidadnegocio.map((option) => (
                                                    <option key={option.unidad_negocio} value={option.unidad_negocio}>
                                                        {option.unidad_negocio_d}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </TableCell>
                                        {/*<TableCell size="small">*/}

                                        {/*</TableCell>*/}
                                        <TableCell>
                                            <div>{`Última Actualización:`}</div>
                                            <div><Chip style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                                       label={`${fecha_hora}`}
                                            />
                                            </div>
                                            <div>
                                                <Button label={'Actualizar'}
                                                        title={'Actualizar'}
                                                        onClick={handleClickActualizarOrdComEstado}
                                                        style={{color: '#123B80'}}
                                                >
                                                    <RefreshIcon/>
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <div>
                                <div>
                                    <List>
                                        <Table size="small" aria-label="a dense table">
                                            {untableroListOrdComEstado.map(record => {
                                                return (
                                                    <TableRow
                                                        style={{
                                                            textAlign: 'left',
                                                            backgroundColor: record.estado_encuadre === undefined || record.estado_color === undefined ? '' :
                                                                record.estado_color.trim()
                                                        }}
                                                    >
                                                        <TableCell size="small" className={classes.textcenter}>
                                                            <Typography size="small" title={''}
                                                                        style={{textAlign: 'center'}}>
                                                                {OrdComUnidadNegocio === undefined ? '' : OrdComUnidadNegocio.trim() === '' ?
                                                                        <WithPermissions
                                                                            render={({ permissions }) => (
                                                                                role_contratacion_consulta_menu({permissions }) === 'ROLE_CONTRATACION_CONSULTA_MENU'
                                                                                    ?
                                                                                        <Link
                                                                                            to={`/ContratacionEncuadre?displayedFilters=%7B%7D&filter=%7B"contratacion_ano"%3A${record.contratacion_ano}%2C"id_unidad_negocio"%3A"${record.id_unidad_negocio}"%2C"id_estado_encuadre"%3A"${record.id_estado_encuadre}"%7D&order=ASC&page=1&perPage=25&sort=id`}>
                                                                                            <StyledBadge badgeContent={record.cantidad}
                                                                                                         max={9999999999}
                                                                                                         color="secondary">
                                                                                                <WebAssetIcon/>
                                                                                            </StyledBadge>
                                                                                        </Link>
                                                                                    :
                                                                                        <StyledBadge badgeContent={record.cantidad}
                                                                                                       max={9999999999}
                                                                                                       color="secondary">
                                                                                            <WebAssetIcon/>
                                                                                        </StyledBadge>
                                                                            )}
                                                                        />
                                                                    :
                                                                        <WithPermissions
                                                                            render={({ permissions }) => (
                                                                                role_contratacion_consulta_menu({permissions }) === 'ROLE_CONTRATACION_CONSULTA_MENU'
                                                                                    ?
                                                                                        <Link
                                                                                            to={`/ContratacionEncuadre?displayedFilters=%7B%7D&filter=%7B"contratacion_ano"%3A${record.contratacion_ano}%2C"id_unidad_negocio"%3A"${record.id_unidad_negocio}"%2C"id_estado_encuadre"%3A"${record.id_estado_encuadre}"%7D&order=ASC&page=1&perPage=25&sort=id`}>
                                                                                            <StyledBadge badgeContent={record.cantidad}
                                                                                                         max={9999999999}
                                                                                                         color="secondary">
                                                                                                <WebAssetIcon/>
                                                                                            </StyledBadge>
                                                                                        </Link>
                                                                                    :
                                                                                        <StyledBadge badgeContent={record.cantidad}
                                                                                                               max={9999999999}
                                                                                                               color="secondary">
                                                                                            <WebAssetIcon/>
                                                                                        </StyledBadge>
                                                                            )}
                                                                        />
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                        {EstadoFecha === undefined ? '' : EstadoFecha.trim() === '0000' ?
                                                            <TableCell size="small" className={classes.textleft}>
                                                                <Typography size="small" title={''}
                                                                            style={{textAlign: 'left'}}>
                                                                    {record.contratacion_ano}
                                                                </Typography>
                                                            </TableCell>
                                                            : ''
                                                        }
                                                        {OrdComUnidadNegocio === undefined ? '' : OrdComUnidadNegocio.trim() === '' ?
                                                            <TableCell size="small" className={classes.textleft}>
                                                                <Typography size="small" title={''}
                                                                            style={{textAlign: 'left'}}>
                                                                    <AvatarImage medio={record.unidad_negocio.trim()}/>

                                                                </Typography>
                                                            </TableCell>
                                                            : ''
                                                        }
                                                        <TableCell size="small" className={classes.textleft}>
                                                            <Typography size="small" title={''}
                                                                        style={{textAlign: 'left'}}>
                                                                {record.estado_encuadre}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell size="small" className={classes.textleft}>
                                                            <Typography size="small" title={''}
                                                                        style={{textAlign: 'left'}}>
                                                                {record.estado_encuadre_d}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </Table>
                                    </List>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={styles.modal}
                    open={ActualizarOrdComEstado}
                    onClose={handleDrawerCloseActualizarOrdComEstado}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={ActualizarOrdComEstado}>
                        <div>
                            <Table style={{
                                background: `url(${cartuchonewlargo}) no-repeat`,
                                color: '#ffffff',
                                backgroundColor: '#ffffff'
                            }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell size="small">
                                            <Typography variant="headline" component="h2">
                                                {'Actualizando Contrataciones...'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <PantallaMensaje
                                p_imagen={'CircularProgressModalabajo'}
                                p_mensajePrimario={'Cargando'}
                                p_mensajeSecondario={'La página se está cargando, espere un momento por favor.'}
                            />
                        </div>
                    </Fade>
                </Modal>
            </div>
        )
    } else {
        return (
            <div>
                <Table style={{background: `url(${cartuchonewlargo}) no-repeat`}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography variant="headline" component="h2">
                                    {'Contrataciones'}
                                    {/*</Typography>*/}
                                    {/*<Typography variant="headline" component="h2">*/}
                                    {<WebAssetIcon
                                        // src={`${Imagetvp}?size=${40}x${40}`}
                                        size={40}
                                        style={{width: 40, height: 40}}
                                    />}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="headline" component="h2">
                                    {'Estado'}
                                </Typography>
                                <AvatarImage medio={OrdComUnidadNegocio.trim()}/>
                            </TableCell>
                            <TableCell>
                                <div>{`Año`}</div>
                                <TextField
                                    id="ano_ing"
                                    select
                                    label=""
                                    size="small"
                                    // value={currency}
                                    value={EstadoFecha}
                                    onChange={cambiaano}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    // helperText="Por favor seleccione su Mes"
                                >
                                    {w_p_ord_com_estado_currenciesano.map((option) => (
                                        <option key={option.contratacion_ano} value={option.contratacion_ano}>
                                            {option.contratacion_ano_d}
                                        </option>
                                    ))}
                                </TextField>
                                {/*<NumberFormat*/}
                                {/*    customInput={TextField}*/}
                                {/*    id="ano_ing"*/}
                                {/*    label=""*/}
                                {/*    size="small"*/}
                                {/*    value={EstadoFecha}*/}
                                {/*    onChange={cambiaAno}*/}
                                {/*    format="####"*/}
                                {/*/>*/}
                                <div>{`Unidad Negocio`}</div>

                                <TextField
                                    id="ord_pag_unidad_negocio_ing"
                                    select
                                    label=""
                                    size="small"
                                    // value={currency}
                                    value={OrdComUnidadNegocio}
                                    onChange={cambiaunidadnegocio}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    // helperText="Por favor seleccione su Mes"
                                >
                                    {OrdComcurrenciesunidadnegocio.map((option) => (
                                        <option key={option.unidad_negocio} value={option.unidad_negocio}>
                                            {option.unidad_negocio_d}
                                        </option>
                                    ))}
                                </TextField>
                            </TableCell>
                            {/*<TableCell size="small">*/}

                            {/*</TableCell>*/}
                            <TableCell>
                                <div>{`Última Actualización:`}</div>
                                <div><Chip style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                           label={`${fecha_hora}`}
                                />
                                </div>
                                <div>
                                    <Button label={'Actualizar'}
                                            title={'Actualizar'}
                                            onClick={handleClickActualizarOrdComEstado}
                                            style={{color: '#123B80'}}
                                    >
                                        <RefreshIcon/>
                                    </Button>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </div>
        );
    }
}
export default TableroControlContratacionEncuadreEstado;



