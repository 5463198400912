import React, {useCallback, useState} from 'react';
import { useFormState } from 'react-final-form';
import {
    SaveButton,
    useUpdate,
    useRedirect,
    useNotify,
    useRefresh, Loading, Button, useDataProvider
} from 'react-admin';
import axios from 'axios';
import Compressor from 'compressorjs';
import ApiUrlServer from '../layout/ApiUrlServer'
import PropTypes from 'prop-types';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ReplayIcon from '@material-ui/icons/Replay';

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()



const ButtonSave = props => {
    const [update] = useUpdate('ProveedorDocumentacionAdjunto');
    const redirectTo = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const { basePath, redirect } = props;
    const [loadingDisabled, setLoadingDisabled] = useState(false);
    const dataProvider = useDataProvider();
    // const [loadingDisabledT, setLoadingDisabledT] = useState(0);
    // redirect to the related Author show page
    // const redirect_orgpag = (basePath, id, data) => `/OrdPagEstado`;

    // const redirect_g_remitoentrega = (basePath, id, data) => `/OrdPagMailOperar`;


    const CambiaDisabledTrue = () => {
        // console.log('CostosControlPeriodoDatagrid----CambiaEditarEdit')
        setLoadingDisabled(true);
    };

    const formState = useFormState();

    const redirect_g_remitoentrega = (basePath, id, data) => `/ProveedorDocumentacionAdjuntoShow/${formState.values.id}/show`;

    const handleClick = useCallback(() => {
        let w_length = formState.values.ArchivosAdjuntos
        let w_mandar_mail
        let w_cant_length = 0
        let w_cant_for = 0
        if (w_length === undefined){
            notify('Debe Ingresar un Archivo para Adjuntar', 'warning', {
                smart_count: 1,
            });
        }else {
            w_mandar_mail = 'N'
            w_cant_length = formState.values.ArchivosAdjuntos.length
            console.log('w_cant_length')
            console.log(w_cant_length)
            alert(w_cant_length)

            if (w_cant_length === 0) {
                notify('Debe Ingresar un Archivo para Adjuntar', 'warning', {
                    smart_count: 1,
                });
            } else {
                notify('Actualizando Archivo', 'info', {
                    smart_count: 1,
                });
                for (let i = 0, numFiles = formState.values.ArchivosAdjuntos.length; i < numFiles; i++) {

                    const datafile = new FormData()
                    const file = formState.values.ArchivosAdjuntos[i];
                    // datafile.append('file', file.rawFile);
                    // datafile.append('id', formState.values.id);
                    // datafile.append('name', file.rawFile.name);
                    // datafile.append('description', 'Este archivo es subido por mi');


                    const data = {
                        data: {
                            myFile: file.rawFile,
                            id: formState.values.id,
                            description: 'Este archivo es subido por mi',
                            name: file.rawFile.name
                        },
                    };

                    const response = dataProvider.create('ProveedorDocumentacionAdjunto', data);



                }
            }
        }

    }, [
        formState.valid,
        formState.values,
        update,
        notify,
        redirectTo,
        refresh,
        redirect,
        basePath,
    ]);

    return <SaveButton {...props} disabled={loadingDisabled} handleSubmitWithRedirect={handleClick} redirect={redirect_g_remitoentrega}/>

};

ButtonSave.propTypes = {
    invalid: PropTypes.bool,
};

export default ButtonSave