import {TextInput, useDataProvider} from 'react-admin';
import React, {useState} from "react";
import VerifiedIcon from '@mui/icons-material/Verified';
import ReportIcon from '@mui/icons-material/Report';
import {makeStyles} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
    cuit_cuil: {display: 'inline-block', width: 270},
}));


{/*
PROBAR ESTO
import { isValidCuit } from 'revalidate';

function validateCuit(value) {
  if (!isValidCuit(value)) {
    return 'El CUIT es inválido';
  }
  return null;
}

<TextInput
  label="CUIT"
  source="cuit"
  validate={validateCuit}
/>*/
}


// JN Al seleccionar valor en Combo persona Tipo, filtra el valor de Persona Forma.
/// Paso opcionalmente el PRO, para que en el editar no tome en cuenta el pro en cuestión al momento de validar si existe en BD. Lo uso en modo EDITAR

const CuitCuil = ({personaTipo, isReadonly, validarExistenciaBD, pro, props}) => {
    const [cuitValido, setCuitValido] = useState(false);
    const [alertCuitUtilizado, setAlertCuitUtilizado] = useState(false);
    const [alertCuitUtilizadoBaja, setAlertCuitUtilizadoBaja] = useState(false);

    const dataProvider = useDataProvider();
    const classes = useStyles();
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})

    /// JN Validar CUIT
    const validarCuit = (value, existeBD) => {

        if (value == null || value === '') {
            setCuitValido(false);
            setAlertCuitUtilizado(false);
            setAlertCuitUtilizadoBaja(false);
            return '';
        }
        //
        // if (/^-?\d*\.?\d*$/.test(value) === false) {
        //     return value.slice(0, -1);
        // }

        //Elimino guiones para dejar el número entero
        let valueCuit = value.replace(/[^\d]/g, "");

        if (valueCuit.length > 11 || valueCuit.length < 11) {
            setCuitValido(false);
            setAlertCuitUtilizado(false);
            return "Debe ingresar 11 dígitos";
        }

        if (valueCuit.length === 11) {
            let esValido = validarDigitoVerificador(valueCuit);

            if (validarExistenciaBD && esValido) {
                return ValidarExisteCuitEnBD(valueCuit);
            } else {
                setCuitValido(false);
                setAlertCuitUtilizado(false);
                return 'CUIT invalido'
            }
        }
    };

    const validarDigitoVerificador = valueCuit => {
        var acumulado, digitos, digito, verif;
        acumulado = 0;
        digitos = valueCuit.split('');
        digito = parseInt(digitos.pop());

        for (let i = 0; i < digitos.length; i++) {
            acumulado += digitos[9 - i] * (2 + (i % 6));
        }
        verif = 11 - (acumulado % 11);

        if (verif === 11) {
            verif = 0;
        } else if (verif === 10) {
            verif = 9;
        }

        // Controlo dígito verificador
        if (digito === verif)
            return true;
        else
            return false;
    };

    const ValidarExisteCuitEnBD = async (valueCuit) => {
        let mensaje;

        await dataProvider.getList(
            'ProveedorValidar',
            {
                filter: {
                    cuit_cuil: valueCuit,
                    pro: pro
                },
                sort,
                pagination: {page: 1, perPage: 1},
            },)
            .then(({data}) => {
                /// Si encuentra el CUIT en un proveedor registrado mostrar mensaje de alerta. Igual permite avanzar
                if (data.length > 0) {
                    console.log("DATA CUIT", data)
                    if (data[0].estado_proveedor == 4) // Estado 4 = Baja
                    {
                        setAlertCuitUtilizadoBaja(true);
                        setAlertCuitUtilizado(false);
                    } else {
                        setAlertCuitUtilizado(true);
                        setAlertCuitUtilizadoBaja(false);
                    }
                } else {
                    // Significa que es válido y no hay otro proveedor que lo utilice
                    {
                        setCuitValido(true);
                        setAlertCuitUtilizado(false);
                        setAlertCuitUtilizadoBaja(false);
                    }
                }
                mensaje = undefined;
            })
            .catch(error => {
                console.log(error)
            });

        return mensaje;
    };

    const formatCuit = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length === 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };


    return (
        <div style={{display: "flex", alignItems: "baseline"}}>
            <TextInput source="cuit_cuil" label={"CUIT/CUIL"} variant="outlined"
                       InputLabelProps={{shrink: true}}
                       className={classes.cuit_cuil} fullWidth={true}
                       validate={validarCuit}
                       format={formatCuit}
                       inputProps={{readOnly: isReadonly}}
                       helperText="Ingrese sólo valores numéricos"
            />

            {cuitValido &&
            <VerifiedIcon color="success" style={{margin: 10}}/>
            }

            {alertCuitUtilizado &&
            <>
                <ReportIcon color="info" style={{margin: 10}}/>
                <h6>El CUIT ingresado se encuentra utilizado en uno o más proveedores</h6>
            </>
            }

            {alertCuitUtilizadoBaja &&
            <>
                <ReportIcon color="info" style={{margin: 10}}/>
                <h6>El CUIT existe en un proveedor con estado = "Baja"</h6>
            </>
            }

        </div>
    );

};

export default CuitCuil;
