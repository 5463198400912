import React, {useState, useCallback} from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Create,
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    AutocompleteInput,
    SelectInput,
    DateInput,
    TopToolbar,
    ReferenceInput,
    Toolbar, SaveButton, DeleteButton,
    useGetOne, Loading, NumberInput, TextField, ShowButton
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import DomicilioPaisBusquedaBtn from "../pais/PaisBusquedaBtn";



const useStyles = makeStyles((theme) => ({
    unidad_negocio: {display: 'inline-block', width: 200, marginLeft: 32},
    unidad_negocio_d: {display: 'inline-block', width: 400, marginLeft: 32},
    domicilio_pais: {display: 'inline-block', width: 350, marginLeft: 32},
    domicilio_provincia: {display: 'inline-block', width: 350, marginLeft: 160},
    domicilio_partido: {display: 'inline-block', width: 510, marginLeft: 32},
    domicilio_ciudad: {display: 'inline-block', width: 510, marginLeft: 32},
    domicilio_calle: {display: 'inline-block', width: 410, marginLeft: 32},
    domicilio_calle_nro: {display: 'inline-block', width: 90, marginLeft: 32},
    domicilio_piso: {display: 'inline-block', width: 50, marginLeft: 32},
    domicilio_departamento: {display: 'inline-block', width: 50, marginLeft: 20},
    domicilio_codigo_postal: {display: 'inline-block', width: 110, marginLeft: 20},
    contacto_correo_electronico: {display: 'inline-block', width: 510, marginLeft: 32},
    contacto_telefono_area: {display: 'inline-block', width: 110, marginLeft: 32},
    contacto_telefono_nro: {display: 'inline-block', width: 200, marginLeft: 20},
    contacto_fax_area: {display: 'inline-block', width: 110, marginLeft: 20},
    contacto_fax_nro: {display: 'inline-block', width: 200, marginLeft: 20},
    cuit: {display: 'inline-block', width: 200, marginLeft: 32},
    iva_responsable: {display: 'inline-block', width: 310, marginLeft: 20},
    ibru_responsable: {display: 'inline-block', width: 310, marginLeft: 20},
}));

const UnidadNegocioEdit = props => {
    const classes = useStyles();
    const [state, setState] = useState({});

    /// Búsqueda país
    var w_domicilio_pais = '';
    var w_d_domicilio_pais = '';

    const {data: Data, loading, error} = useGetOne('UnidadNegocio', props.id);

    if (Data === undefined) {
    } else {

        if (Data.DomicilioPais === undefined || Data.DomicilioPais === null) {

        } else {
            w_domicilio_pais = Data.DomicilioPais.pais_codigo_afip === undefined ? 0 : Data.DomicilioPais.pais_codigo_afip;
            w_d_domicilio_pais = Data.DomicilioPais.pais === undefined ? '' : Data.DomicilioPais.pais.trim();
        }
    }


    const {
        codDomicilioPais = w_domicilio_pais,
        dDomicilioPais = w_d_domicilio_pais,

    } = state;

    const formatDomicilioPais = v => {
        return dDomicilioPais
    };



    const updateDomicilioPais = useCallback(async (who) => {
        setState(state => ({
            ...state,
            codDomicilioPais: who.pais_codigo_afip,
            dDomicilioPais: who.pais.trim(),
        }));
    })


    /// JN Mascara para el input cuit-cuil
    const CuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };


    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );

    const redirect = (basePath, id, data) => `/UnidadNegocio`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            <DeleteButton undoable={false} redirect={redirect}/>
        </Toolbar>
    );
    const transform = data => {

        let w_cod_domicilio_pais = codDomicilioPais;
        if (codDomicilioPais > 0) {
        } else (w_cod_domicilio_pais = 0);

        return {
            ...data,
            wp_domicilio_pais: w_cod_domicilio_pais,
        }
    };

    const PostTitle = ({record}) => {
        return <span>Unidad Negocio: {record ? `${record.unidad_negocio}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={<CustomToolbar/>}
            >
                {/*Datos*/}
                <FormTab label="DATOS UNIDAD NEGOCIO">
                    <TextInput
                        label="Código Unidad Negocio"
                        source="unidad_negocio"
                        formClassName={classes.unidad_negocio}
                        placeholder="Ingrese Código Unidad Negocio"
                        helperText="Código"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />

                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Descripción Unidad Negocio"
                        source="unidad_negocio_d"
                        formClassName={classes.unidad_negocio_d}
                        multiline
                        placeholder="Ingrese Descripcion Unidad Negocio"
                        helperText="Descripcion"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>
                    <DomicilioPaisBusquedaBtn callbackPais={updateDomicilioPais} formClassName={classes.btnBusqueda}/>

                    <TextInput source="domicilio_pais" label={"Pais"}
                               fullWidth={true}
                               formClassName={classes.domicilio_pais}
                               variant="filled" inputProps={{readOnly: true,}}
                               value={dDomicilioPais}
                               format={formatDomicilioPais}
                               InputLabelProps={{shrink: true}}
                    />



                    <TextInput source="domicilio_provincia" label={"Provincia"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_provincia} fullWidth={true}/>
                    <br/>
                    <TextInput source="domicilio_partido" label={"Partido"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_partido} fullWidth={true}/>

                    <TextInput source="domicilio_ciudad" label={"Ciudad"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_ciudad} fullWidth={true}/>
                    <br/>
                    <TextInput source="domicilio_calle" label={"Calle"} variant="outlined" InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_calle} fullWidth={true}/>

                    <TextInput source="domicilio_calle_nro" label={"Nro."} variant="outlined" InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_calle_nro} fullWidth={true}/>

                    <TextInput source="domicilio_piso" label={"Piso"} variant="outlined" InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_piso} fullWidth={true}/>

                    <TextInput source="domicilio_departamento" label={"Depto"} variant="outlined" InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_departamento} fullWidth={true}/>

                    <TextInput source="domicilio_codigo_postal" label={"Código Postal"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_codigo_postal} fullWidth={true}/>
                    <br/>


                    <TextInput source="contacto_correo_electronico" label={"Contacto Correo Electronico"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_correo_electronico} fullWidth={true}/>
                    <br/>

                    <TextInput source="contacto_telefono_area" label={"Contacto Telefono Area"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_telefono_area} fullWidth={true}/>


                    <TextInput source="contacto_telefono_nro" label={"Contacto Telefono Nro"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_telefono_nro} fullWidth={true}/>


                    <TextInput source="contacto_fax_area" label={"Contacto Fax Area"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_fax_area} fullWidth={true}/>


                    <TextInput source="contacto_fax_nro" label={"Contacto Fax Nro"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_fax_nro} fullWidth={true}/>
                    <br/>


                    <TextInput source="cuit" label={"CUIT"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.cuit} fullWidth={true}
                               format={CuilFormat}
                    />
                    <TextInput source="iva_responsable" label={"IVA Responsable"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.iva_responsable} fullWidth={true}/>


                    <TextInput source="ibru_responsable" label={"IBRU Responsable"} variant="outlined"
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.ibru_responsable} fullWidth={true}/>
                    <br/>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};
export default UnidadNegocioEdit;


