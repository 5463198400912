import React, {useCallback, useState} from "react";
import {FormTab, Show, TextInput, TabbedForm, TopToolbar, NumberInput, EditButton, usePermissions} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import PaisLegalBusquedaBtn from "../pais/PaisBusquedaBtn";

const useStyles = makeStyles((theme) => ({
    unidad_negocio: {display: 'inline-block', width: 200, marginLeft: 32},
    unidad_negocio_d: {display: 'inline-block', width: 400, marginLeft: 32},
    domicilio_pais: {display: 'inline-block', width: 350, marginLeft: 32},
    domicilio_provincia: {display: 'inline-block', width: 350, marginLeft: 160},
    domicilio_partido: {display: 'inline-block', width: 510, marginLeft: 32},
    domicilio_ciudad: {display: 'inline-block', width: 510, marginLeft: 32},
    domicilio_calle: {display: 'inline-block', width: 410, marginLeft: 32},
    domicilio_calle_nro: {display: 'inline-block', width: 90, marginLeft: 32},
    domicilio_piso: {display: 'inline-block', width: 50, marginLeft: 32},
    domicilio_departamento: {display: 'inline-block', width: 50, marginLeft: 20},
    domicilio_codigo_postal: {display: 'inline-block', width: 110, marginLeft: 20},
    contacto_correo_electronico: {display: 'inline-block', width: 510, marginLeft: 32},
    contacto_telefono_area: {display: 'inline-block', width: 110, marginLeft: 32},
    contacto_telefono_nro: {display: 'inline-block', width: 200, marginLeft: 20},
    contacto_fax_area: {display: 'inline-block', width: 110, marginLeft: 20},
    contacto_fax_nro: {display: 'inline-block', width: 200, marginLeft: 20},
    cuit: {display: 'inline-block', width: 200, marginLeft: 32},
    iva_responsable: {display: 'inline-block', width: 310, marginLeft: 20},
    ibru_responsable: {display: 'inline-block', width: 310, marginLeft: 20},
}));

const UnidadNegocioShow = props => {
    const classes = useStyles();
    const { permissions } = usePermissions();
    var w_role_auxiliar_editar = ''
    var w_permissions_admuno_auxiliar_editar = permissions
    if(w_permissions_admuno_auxiliar_editar==null){}else{
        var wa_role_auxiliar_editar = w_permissions_admuno_auxiliar_editar.indexOf('ROLE_AUXILIAR_EDITAR')
        if(wa_role_auxiliar_editar===-1){}else{
            w_role_auxiliar_editar = 'ROLE_AUXILIAR_EDITAR'
        }
    }
    const [state, setState] = useState({});
    const [UnidadNegocio, setUnidadNegocio] = useState('');
    const [PersonaTipo, setPersonaTipo] = useState('');
    // const [UnidadNegocioSocioEstadoCivilCasado, setUnidadNegocioSocioEstadoCivilCasado] = useState('');

    const establecerUnidadNegocio = value => {
        if (value == null || value === '') return '';
        setUnidadNegocio(value.trim());
        return value;
    };

    const establecerFecha = value => {
        if (value == null || value === '') return '';
        return value;
    };

    // Obtengo tipo de persona para cargar solapa correspondiente
    const establecerPersonaTipo = value => {
        if (value == null || value === '') return '';
        setPersonaTipo(value.trim());
        return value;
    };


    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    const {
        dPais = '',
    } = state;

    const formatPais = v => {
        return dPais
    };

    const updatePais = useCallback(async (who) => {
        setState(state => ({
            ...state,
            domicilio_pais: who.pais_codigo_afip,
            dPais: who.pais.trim(),
        }));
    })

    /// JN Mascara para el input cuit-cuil
    const CuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };


    // const establecerUnidadNegocioSocioEstadoCivilCasado = value => {
    //     if (value == null || value === '') return '';
    //     setUnidadNegocioSocioEstadoCivilCasado(value.trim());
    //     return value;
    // };

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            {w_role_auxiliar_editar === 'ROLE_AUXILIAR_EDITAR' &&
                <EditButton basePath={basePath} record={data}/>
            }
        </TopToolbar>
    );

    const PostTitle = ({record}) => {
        return <span>Unidad Negocio: {record ? `${record.unidad_negocio}` : ''}</span>;
    };

    return (
        <Show title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm toolbar={false}>
                <FormTab label="DATOS UNIDAD NEGOCIO">
                    <TextInput
                        label="Código Unidad Negocio"
                        source="unidad_negocio"
                        formClassName={classes.unidad_negocio}
                        placeholder="Ingrese Código Unidad Negocio"
                        helperText="Código"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />

                    <br/>

                    <TextInput
                        id="standard-full-width"
                        label="Descripción Unidad Negocio"
                        source="unidad_negocio_d"
                        formClassName={classes.unidad_negocio_d}
                        multiline
                        placeholder="Ingrese Descripcion Unidad Negocio"
                        helperText="Descripcion"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{readOnly: true}}
                    />
                    <br/>

                    <TextInput source="domicilio_pais" label={"Pais"}
                               fullWidth={true}
                               formClassName={classes.domicilio_pais}
                               variant="filled" inputProps={{readOnly: true,}}
                               value={dPais}
                               format={formatPais}
                               InputLabelProps={{shrink: true}}
                    />



                    <TextInput source="domicilio_provincia" label={"Provincia"}
                               InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               formClassName={classes.domicilio_provincia} fullWidth={true}/>
                    <br/>
                    <TextInput source="domicilio_partido" label={"Partido"}
                               InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               formClassName={classes.domicilio_partido} fullWidth={true}/>

                    <TextInput source="domicilio_ciudad" label={"Ciudad"}
                               InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               formClassName={classes.domicilio_ciudad} fullWidth={true}/>
                    <br/>
                    <TextInput source="domicilio_calle" label={"Calle"}  InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               formClassName={classes.domicilio_calle} fullWidth={true}/>

                    <TextInput source="domicilio_calle_nro" label={"Nro."}  InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               formClassName={classes.domicilio_calle_nro} fullWidth={true}/>

                    <TextInput source="domicilio_piso" label={"Piso"}  InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               formClassName={classes.domicilio_piso} fullWidth={true}/>

                    <TextInput source="domicilio_departamento" label={"Depto"}  InputLabelProps={{shrink: true}}
                               inputProps={{readOnly: true}}
                               formClassName={classes.domicilio_departamento} fullWidth={true}/>

                    <TextInput source="domicilio_codigo_postal" label={"Código Postal"}
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.domicilio_codigo_postal} fullWidth={true}/>
                    <br/>


                    <TextInput source="contacto_correo_electronico" label={"Contacto Correo Electronico"}
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_correo_electronico} fullWidth={true}/>
                    <br/>

                    <TextInput source="contacto_telefono_area" label={"Contacto Telefono Area"}
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_telefono_area} fullWidth={true}/>


                    <TextInput source="contacto_telefono_nro" label={"Contacto Telefono Nro"}
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_telefono_nro} fullWidth={true}/>


                    <TextInput source="contacto_fax_area" label={"Contacto Fax Area"}
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_fax_area} fullWidth={true}/>


                    <TextInput source="contacto_fax_nro" label={"Contacto Fax Nro"}
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.contacto_fax_nro} fullWidth={true}/>
                    <br/>


                    <TextInput source="cuit" label={"CUIT"}
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.cuit} fullWidth={true}
                               format={CuilFormat}
                    />
                    <TextInput source="iva_responsable" label={"IVA Responsable"}
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.iva_responsable} fullWidth={true}/>


                    <TextInput source="ibru_responsable" label={"IBRU Responsable"}
                               inputProps={{readOnly: true}}
                               InputLabelProps={{shrink: true}}
                               formClassName={classes.ibru_responsable} fullWidth={true}/>
                    <br/>
                </FormTab>
            </TabbedForm>
        </Show>
    )
};
export default UnidadNegocioShow;


