import React, {useState} from 'react';
import UnidadSectorBusquedaDatagrid from "./UnidadSectorBusquedaDatagrid"
import {Drawer} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';

export const UnidadSectorBusquedaBtn = (props) => {
    const useStyles = makeStyles({
        paper: {
            width: '50%'
        },
        button: {
            justifyContent: 'left',
            display: 'inline-flex',
            fontWeight: 'bold',
            margin: 0,
            marginBottom: 0
        }
    });

    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton color="primary" onClick={() => setOpen(!open)}>
                <Icon/>
            </IconButton>

            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <UnidadSectorBusquedaDatagrid
                    callbackUnidadSector={props.callbackUnidadSector}
                    callbackCloseDrawer={handleDrawerOpen}
                    unidadNegocio={props.unidadNegocio}
                />

            </Drawer>
        </div>
    )
};
export default UnidadSectorBusquedaBtn;