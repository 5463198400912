import React from 'react'
import {
    CardActions, CreateButton,
    Datagrid, ExportButton,
    List,
    TextField, WithPermissions,
} from 'react-admin';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '1em'},
    rightCol: {flex: 1, marginLeft: '1em'},
    singleCol: {marginTop: '2em', marginBottom: '2em'},
};

const role_auxiliar_crear_permissions = ({permissions}) => {
    var w_role_control = ''
    var w_permissions_control = permissions
    if(w_permissions_control==null){}else{
        var w_role_auxiliar_crear = w_permissions_control.indexOf('ROLE_AUXILIAR_CREAR')
        if(w_role_auxiliar_crear===-1){}else{
            w_role_control = 'ROLE_AUXILIAR_CREAR'
        }
    }

    return w_role_control
}


const role_exportar_csv = ({permissions}) => {
    var w_role_control = '';
    var w_permissions_control = permissions;
    if (w_permissions_control == null) {
    } else {
        var w_role_exportar_csv = w_permissions_control.indexOf('ROLE_EXPORTAR_CSV');
        if (w_role_exportar_csv === -1) {
        } else {
            w_role_control = 'ROLE_EXPORTAR_CSV'
        }
    }
    return w_role_control
};

const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        <WithPermissions
            render={({ permissions }) => (
                role_auxiliar_crear_permissions({permissions }) === 'ROLE_AUXILIAR_CREAR'
                    ?
                    <CreateButton/>
                    : null
            )}
        />

        <WithPermissions
            render={({permissions}) => (
                role_exportar_csv({permissions}) === 'ROLE_EXPORTAR_CSV'
                    ?
                    <ExportButton/>
                    : null

            )}
        />
    </CardActions>
);

export const PersonasTipoList = withStyles(styles)(({classes, permissions, ...props}) => {
    return (
    <List {...props} title="Listado de Tipo de Personas"
          bulkActionButtons={false}
          perPage={25}
          actions={<PostActions/>}
    >

        <Datagrid>
            <TextField label="Proveedor" source="persona_tipo" sortable={false}/>
            <TextField label="Descripcion" source="persona_tipo_d" sortable={false}/>
        </Datagrid>
    </List>
    )
});


export default PersonasTipoList;
