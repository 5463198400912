import React, {useState} from 'react';
import UnidadAreaBusquedaDataGrid from "./UnidadAreaBusquedaDatagrid"
import {Drawer} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';

export const UnidadAreaBusquedaBtn = (props) => {
    const useStyles = makeStyles({
        paper: {
            width: '45%'
        },
        button: {
            justifyContent: 'left',
            display: 'inline-flex',
            fontWeight: 'bold',
            margin: 0,
            marginBottom: 0
        }
    });
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(false);
    };

    return (
        <div>
            {/*{'Buscar Unidad Area'}*/}
            <IconButton style={{color: '#123B80'}} onClick={() => setOpen(!open)}>
                <Icon/>
            </IconButton>

            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <UnidadAreaBusquedaDataGrid
                    callbackUnidadArea={props.callbackUnidadArea}
                    area={props.area}
                    unidad_negocio={props.unidad_negocio}
                    usar_todos={props.usar_todos}
                    callbackCloseDrawer={handleDrawerOpen}
                />

            </Drawer>
        </div>
    )
};
export default UnidadAreaBusquedaBtn;