// import React from 'react'
// import {
//     Create,
//     TextInput,
// } from 'react-admin';
//
// export const PersonasTipoCreate = props => {
//     return (
//         <Create {...props}>
//             <TextInput label="Código" source="persona_tipo" sortable={false}/>
//             <TextInput label="Descripción Persona Tipo" source="persona_tipo_d" sortable={false}/>
//         </Create>
//     )
// };
//
// export default PersonasTipoCreate;

import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput, Filter, SelectInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';


const useStyles = makeStyles((theme) => ({
    documento_tipo: {display: 'inline-block', width: 200, marginLeft: 8},
    documento_tipo_d: {display: 'inline-block', width: 400, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_documento_tipo = values.documento_tipo

    if (w_documento_tipo===undefined) {
        errors.documento_tipo = ['El campo Código es requerido'];
    }else {
        if (w_documento_tipo === null) {
            errors.documento_tipo = ['El campo Código es requerido'];
        } else {
            w_documento_tipo = w_documento_tipo.trim()
            if (w_documento_tipo === '') {
                errors.documento_tipo = ['El campo Código es requerido'];
            }
        }
    }


    var w_documento_tipo_d = values.documento_tipo_d

    if (w_documento_tipo_d===undefined) {
        errors.documento_tipo_d = ['El campo Descripcion es requerido'];
    }else {
        if (w_documento_tipo_d === null) {
            errors.documento_tipo_d = ['El campo Descripcion es requerido'];
        } else {
            w_documento_tipo_d = w_documento_tipo_d.trim()
            if (w_documento_tipo_d === '') {
                errors.documento_tipo_d = ['El campo Descripcion es requerido'];
            }
        }
    }

    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/PersonasTipo`;

const PersonasTipoCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Personas Tipo</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS Personas Tipo">
                    <TextInput label="Código" source="persona_tipo"/>
                    <TextInput label="Descripción Persona Tipo" source="persona_tipo_d" />

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default PersonasTipoCreate;
