import {SelectInput, ReferenceInput, TextInput} from 'react-admin';
import React from "react";

const ComboContratacionTipo = (props) => {
    const isReadonly = props.readOnly;
    const islabel = props.label;
    const isvariant = props.variant;
    return (
        <ReferenceInput label={islabel===undefined || islabel===null ? 'Contratación Tipo' :
                                    islabel.trim()==='' ? 'Contratación Tipo' : islabel}
                        source="id_contratacion_tipo" reference="ContratacionTipo"
                        // variant="outlined"
                        variant={isvariant===undefined || isvariant===null ? 'outlined' : isvariant.trim()}
        >
            <SelectInput optionText="contratacion_d" InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: isReadonly}} />
        </ReferenceInput>
    )
};

export default ComboContratacionTipo;
