import React, {useState, useEffect, Component} from 'react';
import {GET_LIST, Responsive, withDataProvider, useDataProvider, Loading, Error, WithPermissions} from 'react-admin';
import Bienvenido from './Bienvenido';
import TableroControlOrdenCompraEstado from "./TableroControlOrdenCompraEstado";
import UnidadDeNEgociosTableroControl from "./UnidadDeNEgociosTableroControl"
import UnidadDeNEgociosContratacionEncuadreTableroControl from "./UnidadDeNEgociosContratacionEncuadreTableroControl"
import TableroControlContratacionEncuadreEstado from "./TableroControlContratacionEncuadreEstado";
import TableroControlContratacionGerenteInicio from "./TableroControlContratacionGerenteInicio"

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

const role_bienvenido = ({permissions}) => {
    var w_role_menu_bienvenido = ''
    var w_permissions_bienvenido_menu = permissions
    if(w_permissions_bienvenido_menu==null){}else{
        var w_role_menu_bienvenido_aux = w_permissions_bienvenido_menu.indexOf('ROLE_BIENVENIDO')
        if(w_role_menu_bienvenido_aux===-1){}else{
            w_role_menu_bienvenido = 'ROLE_BIENVENIDO'
        }
    }
    return w_role_menu_bienvenido
}

const role_contratacion_gerente_inicio = ({permissions}) => {
    var w_role_contratacion_gerente_inicio = ''
    var w_permissions_contratacion_gerente_menu = permissions
    if(w_permissions_contratacion_gerente_menu==null){}else{
        var w_role_contratacion_gerente_inicio_aux = w_permissions_contratacion_gerente_menu.indexOf('ROLE_CONTRATACION_GERENTE_INICIO')
        if(w_role_contratacion_gerente_inicio_aux===-1){}else{
            w_role_contratacion_gerente_inicio = 'ROLE_CONTRATACION_GERENTE_INICIO'
        }
    }
    return w_role_contratacion_gerente_inicio
}

const role_contratacion_inicio = ({permissions}) => {
    var w_role_contratacion_inicio = ''
    var w_permissions_contratacion_menu = permissions
    if(w_permissions_contratacion_menu==null){}else{
        var w_role_contratacion_inicio_aux = w_permissions_contratacion_menu.indexOf('ROLE_CONTRATACION_INICIO')
        if(w_role_contratacion_inicio_aux===-1){}else{
            w_role_contratacion_inicio = 'ROLE_CONTRATACION_INICIO'
        }
    }
    return w_role_contratacion_inicio
}

const role_orden_compra_inicio = ({permissions}) => {
    var w_role_orden_compra_inicio = ''
    var w_permissions_orden_compra_menu = permissions
    if(w_permissions_orden_compra_menu==null){}else{
        var w_role_orden_compra_inicio_aux = w_permissions_orden_compra_menu.indexOf('ROLE_COMPRA_INICIO')
        if(w_role_orden_compra_inicio_aux===-1){}else{
            w_role_orden_compra_inicio = 'ROLE_COMPRA_INICIO'
        }
    }
    return w_role_orden_compra_inicio
}


const TableroControl = () => {
    const dataProviderTableroControl = useDataProvider();
    const [EstadoDetalleTableroControl, setEstadoDetalleTableroControl] = useState(1);
    const [untableroTableroControl, setUNTableroTableroControl] = useState([]);
    const [udntableroTableroControl, setUDNTableroTableroControl] = useState([]);
    const [etableroTableroControl, setETableroTableroControl] = useState([]);
    const [loadingTableroControl, setLoadingTableroControl] = useState(true);
    const [errorTableroControl, setErrorTableroControl] = useState();

    useEffect(() => {

        // fetchOrders_pesos_uni_neg_TableroControl()

        dataProviderTableroControl.getList(
            'UnidadNegocio',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'S' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setUNTableroTableroControl(data);
                setLoadingTableroControl(false);
            })
            .catch(error => {
                setErrorTableroControl(error);
                setLoadingTableroControl(false);

            })

        dataProviderTableroControl.getList(
            'UnidadNegocio',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'C' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setUDNTableroTableroControl(data);
                setLoadingTableroControl(false);
            })
            .catch(error => {
                setErrorTableroControl(error);
                setLoadingTableroControl(false);

            })
        dataProviderTableroControl.getList(
            'UnidadNegocio',
            {
                filter: {filtros: 'N', ingreso_por: 'Z', usar_todos: 'E' },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setETableroTableroControl(data);
                setLoadingTableroControl(false);
            })
            .catch(error => {
                setErrorTableroControl(error);
                setLoadingTableroControl(false);

            })
    }, [EstadoDetalleTableroControl]);

    if (loadingTableroControl) return <Loading/>;                // no cargo todavia

    if (errorTableroControl) {
        return <Error/>;                           // error
    }



    return (
       <Responsive
           xsmall={
               <div>
                   <div style={styles.flexColumn}>
                       <div style={{ marginBottom: '2em' }}>
                           <WithPermissions
                               render={({ permissions }) => (
                                   role_bienvenido({permissions }) === 'ROLE_BIENVENIDO'
                                       ?
                                        <Bienvenido />
                                       : null
                               )}
                           />
                       </div>
                       <div style={styles.flex}>
                           <WithPermissions
                               render={({ permissions }) => (
                                   role_contratacion_gerente_inicio({permissions }) === 'ROLE_CONTRATACION_GERENTE_INICIO'
                                       ?
                                       <TableroControlContratacionGerenteInicio
                                           p_untableroTableroControl={untableroTableroControl}
                                           p_udntableroTableroControl={etableroTableroControl}
                                       />
                                       : null
                               )}
                           />
                           <WithPermissions
                               render={({ permissions }) => (
                                   role_orden_compra_inicio({permissions }) === 'ROLE_COMPRA_INICIO'
                                       ?
                                           <TableroControlOrdenCompraEstado
                                               p_untableroTableroControl={untableroTableroControl}
                                               p_udntableroTableroControl={udntableroTableroControl}
                                           />
                                       : null
                               )}
                           />
                           <WithPermissions
                               render={({ permissions }) => (
                                   role_contratacion_inicio({permissions }) === 'ROLE_CONTRATACION_INICIO'
                                       ?
                                           <TableroControlContratacionEncuadreEstado
                                               p_untableroTableroControl={untableroTableroControl}
                                               p_udntableroTableroControl={etableroTableroControl}
                                           />
                                       : null
                               )}
                           />
                       </div>
                       <div style={styles.singleCol}>
                            <WithPermissions
                                render={({ permissions }) => (
                                    role_orden_compra_inicio({permissions }) === 'ROLE_COMPRA_INICIO'
                                        ?
                                            <UnidadDeNEgociosTableroControl/>
                                       : null
                                )}
                            />
                       </div>
                       <div style={styles.singleCol}>
                           <WithPermissions
                               render={({ permissions }) => (
                                   role_contratacion_inicio({permissions }) === 'ROLE_CONTRATACION_INICIO'
                                       ?
                                            <UnidadDeNEgociosContratacionEncuadreTableroControl/>
                                       : null
                               )}
                           />
                       </div>
                   </div>
               </div>
           }
           small={
               <div>
                   <div style={styles.flexColumn}>
                       <div style={styles.singleCol}>
                           <WithPermissions
                               render={({ permissions }) => (
                                   role_bienvenido({permissions }) === 'ROLE_BIENVENIDO'
                                       ?
                                       <Bienvenido />
                                       : null
                               )}
                           />
                       </div>
                       <div style={styles.flex}>
                           <WithPermissions
                               render={({ permissions }) => (
                                   role_contratacion_gerente_inicio({permissions }) === 'ROLE_CONTRATACION_GERENTE_INICIO'
                                       ?
                                       <TableroControlContratacionGerenteInicio
                                           p_untableroTableroControl={untableroTableroControl}
                                           p_udntableroTableroControl={etableroTableroControl}
                                       />
                                       : null
                               )}
                           />
                           <WithPermissions
                               render={({ permissions }) => (
                                   role_orden_compra_inicio({permissions }) === 'ROLE_COMPRA_INICIO'
                                       ?
                                           <TableroControlOrdenCompraEstado
                                               p_untableroTableroControl={untableroTableroControl}
                                               p_udntableroTableroControl={udntableroTableroControl}
                                           />
                                       : null
                               )}
                           />
                           <WithPermissions
                               render={({ permissions }) => (
                                   role_contratacion_inicio({permissions }) === 'ROLE_CONTRATACION_INICIO'
                                       ?
                                           <TableroControlContratacionEncuadreEstado
                                               p_untableroTableroControl={untableroTableroControl}
                                               p_udntableroTableroControl={etableroTableroControl}
                                           />
                                       : null
                               )}
                           />
                       </div>
                       <div style={styles.singleCol}>
                            <WithPermissions
                                render={({ permissions }) => (
                                    role_orden_compra_inicio({permissions }) === 'ROLE_COMPRA_INICIO'
                                    ?
                                        <UnidadDeNEgociosTableroControl/>
                                    : null
                                )}
                            />
                       </div>
                       <div style={styles.singleCol}>
                           <WithPermissions
                               render={({ permissions }) => (
                                   role_contratacion_inicio({permissions }) === 'ROLE_CONTRATACION_INICIO'
                                       ?
                                        <UnidadDeNEgociosContratacionEncuadreTableroControl/>
                                       : null
                               )}
                           />
                       </div>
                   </div>
               </div>
           }
           medium={
               <div>
                   <WithPermissions
                       render={({ permissions }) => (
                           role_contratacion_gerente_inicio({permissions }) === 'ROLE_CONTRATACION_GERENTE_INICIO'
                               ?
                               <TableroControlContratacionGerenteInicio
                                   p_untableroTableroControl={untableroTableroControl}
                                   p_udntableroTableroControl={etableroTableroControl}
                               />
                               : null
                       )}
                   />
                   <div style={styles.flex}>
                       <div style={styles.leftCol}>
                            <div style={styles.flex}>

                            </div>
                            <div style={styles.singleCol}>
                                <WithPermissions
                                    render={({ permissions }) => (
                                        role_bienvenido({permissions }) === 'ROLE_BIENVENIDO'
                                            ?
                                            <Bienvenido />
                                            : null
                                    )}
                                />
                            </div>
                            <div style={styles.singleCol}>
                                <WithPermissions
                                    render={({ permissions }) => (
                                        role_orden_compra_inicio({permissions }) === 'ROLE_COMPRA_INICIO'
                                            ?
                                                <UnidadDeNEgociosTableroControl/>
                                           : null
                                    )}
                                />
                            </div>
                            <div style={styles.singleCol}>
                                <WithPermissions
                                    render={({ permissions }) => (
                                        role_contratacion_inicio({permissions }) === 'ROLE_CONTRATACION_INICIO'
                                            ?
                                                <UnidadDeNEgociosContratacionEncuadreTableroControl/>
                                            : null
                                    )}
                                />
                            </div>
                       </div>
                       <div style={styles.rightCol}>
                           <div style={styles.flex}>

                           </div>
                           <div style={styles.flex}>
                                <WithPermissions
                                    render={({ permissions }) => (
                                        role_orden_compra_inicio({permissions }) === 'ROLE_COMPRA_INICIO'
                                            ?
                                               <TableroControlOrdenCompraEstado
                                                   p_untableroTableroControl={untableroTableroControl}
                                                   p_udntableroTableroControl={udntableroTableroControl}
                                               />
                                            : null
                                   )}
                                />
                           </div>
                           <br/>
                           <div style={styles.flex}>
                               <WithPermissions
                                   render={({ permissions }) => (
                                       role_contratacion_inicio({permissions }) === 'ROLE_CONTRATACION_INICIO'
                                           ?
                                               <TableroControlContratacionEncuadreEstado
                                                   p_untableroTableroControl={untableroTableroControl}
                                                   p_udntableroTableroControl={etableroTableroControl}
                                               />
                                           : null
                                   )}
                               />
                           </div>
                       </div>
                   </div>
               </div>
           }
       />
   );

}

export default TableroControl;
