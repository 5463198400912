import {SelectInput, ReferenceInput, FormTab} from 'react-admin';
import React from "react";

const ComboIvaResponsable = (props) => {
    const isReadonly = props.readOnly;
    return (
        <ReferenceInput label="IVA Responsable" source="id_iva" reference="iva" variant="outlined">
            <SelectInput optionText="d_iva" InputLabelProps={{shrink: true}}
                         inputProps={{readOnly: isReadonly}} />
        </ReferenceInput>
    )
};

export default ComboIvaResponsable;
