import React from 'react'
import {
    CardActions, CreateButton,
    Datagrid, ExportButton,
    List,
    TextField, WithPermissions
} from 'react-admin';

const role_exportar_csv = ({permissions}) => {
    var w_role_control = '';
    var w_permissions_control = permissions;
    if (w_permissions_control == null) {
    } else {
        var w_role_exportar_csv = w_permissions_control.indexOf('ROLE_EXPORTAR_CSV');
        if (w_role_exportar_csv === -1) {
        } else {
            w_role_control = 'ROLE_EXPORTAR_CSV'
        }
    }
    return w_role_control
};


const PostActions = ({resource, filters, displayedFilters, filterValues, basePath, showFilter}) => (
    <CardActions>
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}

        <WithPermissions
            render={({permissions}) => (
                role_exportar_csv({permissions}) === 'ROLE_EXPORTAR_CSV'
                    ?
                    <ExportButton/>
                    : null
            )}
        />
    </CardActions>
);

export const IvaLista = (props) => (
    <List {...props} title="Iva"
          bulkActionButtons={false}
          perPage={25}
          actions={<PostActions/>}
    >

        <Datagrid>
            <TextField label="Cod." source="iva" sortable={false}/>
            <TextField label="Descripción" source="d_iva" sortable={false}/>
        </Datagrid>
    </List>
);


export default IvaLista;
