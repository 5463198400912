import React, {useState, useCallback} from "react";

import {
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    TopToolbar,
    Toolbar, SaveButton, DeleteButton,
    useGetOne, TextField, ShowButton, maxLength, required
} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import PersonaTipoBusquedaBtn from "../personaTipo/PersonaTipoBusquedaBtn";

const useStyles = makeStyles((theme) => ({
        codigo: {width: 80},
        persona_forma_d: {width: 300},
        // persona_tipo: {display: 'inline-block', width: 200},
        persona_tipo_d: {display: 'inline-block', width: 200},
        btnBusqueda: {display: 'inline-block', width: 40}

    }))
;

const validatepersona_forma = [required(), maxLength(10)];
const validatepersona_forma_d = [required(), maxLength(60)];

const PersonaFormaEdit = props => {
    const classes = useStyles();
    const [state, setState] = useState({});

    /// Búsqueda PersonaTipo
    var w_persona_tipo, w_persona_tipo_d = '';

    const {data: Data, loading: Loading} = useGetOne('PersonaForma', props.id);

    if (Data === undefined) {
    } else {
        w_persona_tipo = Data.persona_tipo;
        if (Data.PersonaTipo === undefined || Data.PersonaTipo === null) {
        } else {
            w_persona_tipo_d = Data.persona_tipo ? Data.PersonaTipo.persona_tipo_d.trim() : '';
        }
    }

    const {
        PersonaTipo = w_persona_tipo, PersonaTipoD = w_persona_tipo_d,
    } = state;

    const formatPersonaTipo = v => {
        return PersonaTipo
    };

    const formatPersonaTipoD = v => {
        return PersonaTipoD
    };

    const updatePersonaTipo = useCallback(async (who) => {
        setState(state => ({
            ...state,
            PersonaTipo: who.persona_tipo,
            PersonaTipoD: who.persona_tipo_d.trim(),
        }));
    });

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );

    const redirect = (basePath, id, data) => `/PersonaForma`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            <DeleteButton undoable={false} redirect={redirect}/>
        </Toolbar>
    );
    const transform = data => {
        let wt_persona_tipo = PersonaTipo;

        return {
            ...data,
            wp_persona_tipo: wt_persona_tipo,
        }
    };


    const PostTitle = ({record}) => {
        return <span>Persona Forma: {record ? `${record.persona_forma}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={<CustomToolbar/>}
            >
                <FormTab label="PERSONA FORMA">
                    <TextInput
                        label="Código" fullWidth
                        source="persona_forma"
                        formClassName={classes.codigo}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validatepersona_forma}
                    />

                    <TextInput
                        label="Descripción"
                        source="persona_forma_d"
                        formClassName={classes.persona_forma_d}
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validatepersona_forma_d}
                    />

                    <TextInput source="persona_tipo_d" label={"Tipo de Persona"}
                               formClassName={classes.persona_tipo_d}
                               variant="filled" inputProps={{readOnly: true,}}
                               value={PersonaTipoD} fullWidth
                               format={formatPersonaTipoD}
                               InputLabelProps={{shrink: true}}
                    />

                    <PersonaTipoBusquedaBtn callbackPersonaTipo={updatePersonaTipo} fullWidth
                                            formClassName={classes.btnBusqueda}/>

                </FormTab>
            </TabbedForm>
        </Edit>
    )
};
export default PersonaFormaEdit;


