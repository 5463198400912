import React, {useState} from 'react';
import {
    useQuery,
    Datagrid,
    TextField,
    Pagination,
    Loading,
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import IconBuscar from '@material-ui/icons/Search';
import IconSeleccionar from '@material-ui/icons/Done';
import {makeStyles} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {TextField as TextFieldMUI} from '@material-ui/core';
import IconCancelar from '@material-ui/icons/Close';
import ReplayIcon from "@material-ui/icons/Replay";
import FilterListIcon from "@material-ui/icons/FilterList";

export const PersonaTipoBusquedaDatagrid = (props) => {


    var wr_props_persona_tipo_ing = props.persona_tipo

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(12);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})
    const [filter, setFilter] = useState('');
    const [busqueda, setBusqueda] = useState('');

    const useStyles = makeStyles({
        button: {},
    });
    const classes = useStyles();

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'PersonaTipo',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: {q: filter, persona_tipo_ing: wr_props_persona_tipo_ing},
        }
    });

    if (loading) {
        return <Loading/>
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    /*Botón seleccionar registro*/
    const BtnConfirmar = ({record}) => (
        <IconButton style={{fontSize: 15, padding: 2}}
                    onClick={() => {
                        props.callbackPersonaTipo(record);
                        props.callbackCloseDrawer(false)
                    }}
        >

            <IconSeleccionar style={{fontSize: 20}}/>
        </IconButton>
    );

    /*Botón de búsqueda*/
    const BtnBuscar = ({event}) => (
        <Button
            onClick={() => {
                setFilter(busqueda);
                setPage(1);
                setPerPage(10);
            }}
            style={{color: '#ffffff', backgroundColor: '#64c8ff'}}
            size={'small'}
        >
            <IconBuscar style={{fontSize: 20}}/> <h4>{' buscar'}</h4>
        </Button>
    );

    /*Botón de borrar*/
    const BtnBorrar = ({event}) => (
        <Button
            onClick={() => {
                setFilter('');
                setBusqueda('');
                setPage(1);
                setPerPage(10);
            }}
            style={{color: '#ffffff', backgroundColor: '#123B80'}}
            size={'small'}
        >
            <ReplayIcon/>
            <FilterListIcon style={{fontSize: 20}}/>
            <h4>{' B. F.'}</h4>
        </Button>
    );

    const BtnVolver = ({record}) => (
        <Button variant="contained" size={"small"} color="primary" onClick={() => {
            props.callbackCloseDrawer(false)
        }}
        >
            <IconCancelar/>
            Volver
        </Button>
    );

    const Btntitulo = ({event}) => (
        <h4>{'Persona Forma'}</h4>
    );


    return (
        <div>
            <div style={{margin: '3em'}}>
                <Btntitulo/>
                <TextFieldMUI label="Buscar"
                              onChange={(v) => setBusqueda(v.target.value)}
                              defaultValue={filter}
                />

                <BtnBuscar/>

                {filter === '' ? '' :
                    <BtnBorrar/>
                }

                <Datagrid rowStyle={RowStyle}
                    data={keyBy(data, 'id')}
                    ids={data.map(({id}) => id)}
                    currentSort={sort}
                    setSort={(field, order) => setSort({field, order})}
                >
                    <BtnConfirmar/>
                    <TextField label="Código Persona Tipo" source="persona_tipo" />
                    <TextField label="Descripción Persona Tipo" source="persona_tipo_d" />
                </Datagrid>
                <Pagination
                    rowsPerPageOptions={[]}
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    total={total}
                />
                <BtnVolver/>
            </div>
        </div>
    )
};

const RowStyle = (record, index) => ({
    backgroundColor: record.persona_tipo  === undefined || record.persona_tipo_ing === undefined ? '' :
        record.persona_tipo.trim() === record.persona_tipo_ing.trim() ? '#64c8ff' : '',
});

export default PersonaTipoBusquedaDatagrid;