import React, {useState, useCallback} from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Create,
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    AutocompleteInput,
    SelectInput,
    DateInput,
    TopToolbar,
    ReferenceInput,
    Toolbar, SaveButton, DeleteButton,
    useGetOne, Loading, NumberInput, TextField, ShowButton, maxLength, required
} from 'react-admin';
import {makeStyles} from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
    adjudicacion_criterio: {display: 'inline-block', width: 200, marginLeft: 8},
    adjudicacion_criterio_d: {display: 'inline-block', width: 600, marginLeft: 8},
}));

const validateadjudicacion_criterio = [required(), maxLength(10)];
const validateadjudicacion_criterio_d = [required(), maxLength(60)];

const AdjudicacionCriterioEdit = props => {
    const classes = useStyles();
    const [state, setState] = useState({});
    const [AdjudicacionCriterio, setAdjudicacionCriterio] = useState('');
    const [PersonaTipo, setPersonaTipo] = useState('');

    /// Búsqueda país
    var w_pais_legal = '', w_pais_comercial = '';

    const {data: Data, loading: Loading} = useGetOne('AdjudicacionCriterio', props.id);

    if (Data === undefined) {
    } else {
        w_pais_legal = Data.legal_pais;
        w_pais_comercial = Data.PaisComercial ? Data.PaisComercial.pais.trim() : '';
    }

    const {
        PaisLegal = w_pais_legal, PaisComercial = w_pais_comercial,
    } = state;

    const formatPaisLegal = v => {
        return PaisLegal
    };

    const formatPaisComercial = v => {
        return PaisComercial
    };

    const updatePaisLegal = useCallback(async (who) => {
        setState(state => ({
            ...state,
            pais_codigo_afip_legal: who.pais_codigo_afip,
            PaisLegal: who.pais.trim(),
        }));
    })


    const updatePaisComercial = useCallback(async (who) => {
        setState(state => ({
            ...state,
            pais_codigo_afip_comercial: who.pais_codigo_afip,
            PaisComecial: who.pais.trim(),
        }));
    })

    const establecerAdjudicacionCriterio = value => {
        if (value == null || value === '') return '';
        setAdjudicacionCriterio(value.trim());
        return value;
    };

    const establecerFecha = value => {
        if (value == null || value === '') return '';
        return value;
    };

    // Obtengo tipo de persona para cargar solapa correspondiente
    const establecerPersonaTipo = value => {
        if (value == null || value === '') return '';
        setPersonaTipo(value.trim());
        return value;
    };

    const cuitCuilFormat = value => {
        if (value == null || value === '') return '';

        //normalize string and remove all unnecessary characters
        var valueCuit = value.replace(/[^\d]/g, "");

        //check if number length equals to 11
        if (valueCuit.length == 11) {
            return value.replace(/(\d{2})(\d{8})(\d{1})/, "$1-$2-$3");
        }

        return value
    };

    const PostEditActions = ({basePath, data, resource}) => (
        <TopToolbar>
            <ShowButton basePath={basePath} record={data}/>
        </TopToolbar>
    );

    const redirect = (basePath, id, data) => `/AdjudicacionCriterio`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            <DeleteButton undoable={false} redirect={redirect}/>
        </Toolbar>
    );
    const transform = data => {
        return {
            ...data
        }
    };

    const PostTitle = ({record}) => {
        return <span>Adjudicacion Criterio: {record ? `${record.adjudicacion_criterio}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} actions={<PostEditActions/>} {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={<CustomToolbar/>}
            >
                {/*Datos*/}
                <FormTab label="DATOS ADJUDICACION CRITERIO">
                    <TextInput
                        label="Código Adjudicacion Criterio"
                        source="adjudicacion_criterio"
                        formClassName={classes.adjudicacion_criterio}
                        placeholder="Ingrese Código Adjudicacion Criterio"
                        helperText="Código Adjudicacion Criterio"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validateadjudicacion_criterio}
                    />

                    <br/>

                    <TextInput
                        label="Descripción Adjudicacion Criterio"
                        source="adjudicacion_criterio_d"
                        formClassName={classes.adjudicacion_criterio_d}
                        placeholder="Ingrese Descripción Adjudicacion Criterio"
                        helperText="Descripción Adjudicacion Criterio"
                        margin="normal"
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        validate={validateadjudicacion_criterio_d}
                    />


                </FormTab>
            </TabbedForm>
        </Edit>
    )
};
export default AdjudicacionCriterioEdit;


