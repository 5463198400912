import React, {useState} from "react";
import {
    Create,
    FormTab,
    TextInput,
    TabbedForm,
    Toolbar,
    ReferenceInput,
    NumberInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";


const useStyles = makeStyles((theme) => ({
    parametro: {display: 'inline-block', width: 800, marginLeft: 8},
    parametro_path: {display: 'inline-block', width: 800, marginLeft: 8},
    parametro_descripcion: {display: 'inline-block', width: 800, marginLeft: 8},
}));

const PostCreateToolbar = props => {
    return (
        <Toolbar {...props} >

        </Toolbar>
    )

};

const validateCreate = (values) => {
    const errors = {};

    var w_parametro = values.parametro

    if (w_parametro===undefined) {
        errors.parametro = ['El campo Parametro es requerido'];
    }else {
        if (w_parametro === null) {
            errors.parametro = ['El campo Parametro es requerido'];
        } else {
            w_parametro = w_parametro.trim()
            if (w_parametro === '') {
                errors.parametro = ['El campo Parametro es requerido'];
            }
        }
    }


    var w_parametro_path = values.parametro_path

    if (w_parametro_path===undefined) {
        errors.parametro_path = ['El campo Parametro Path es requerido'];
    }else {
        if (w_parametro_path === null) {
            errors.parametro_path = ['El campo Parametro Path es requerido'];
        } else {
            w_parametro_path = w_parametro_path.trim()
            if (w_parametro_path === '') {
                errors.parametro_path = ['El campo Parametro Path es requerido'];
            }
        }
    }

    var w_parametro_descripcion = values.parametro_descripcion

    if (w_parametro_descripcion===undefined) {
        errors.parametro_descripcion = ['El campo Parametro Descripcion es requerido'];
    }else {
        if (w_parametro_descripcion === null) {
            errors.parametro_descripcion = ['El campo Parametro Descripcion es requerido'];
        } else {
            w_parametro_descripcion = w_parametro_descripcion.trim()
            if (w_parametro_descripcion === '') {
                errors.parametro_descripcion = ['El campo Parametro Descripcion es requerido'];
            }
        }
    }


    return errors
};

// redirect to the related Author show page
const redirect = (basePath, id, data) => `/ParametroPath`;

const ParametroPathCreate = props => {
    const classes = useStyles();

    const PostTitle = ({record}) => {
        return <span>Alta de Parametros del Path</span>;
    };

    return (
        <Create title={<PostTitle/>} {...props}>
            <TabbedForm
                // toolbar={<PostCreateToolbar />} validate={validateCreate} redirect={redirect}
            >
                {/*Datos*/}
                <FormTab label="DATOS PARAMETRO PATH">

                    <TextInput
                        label="Parametro"
                        source="parametro"
                        formClassName={classes.parametro}
                        placeholder="Ingrese Parametro"
                        helperText="Parametro"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    <TextInput
                        label="Parametro Path"
                        source="parametro_path"
                        formClassName={classes.parametro_path}
                        placeholder="Ingrese Parametro Path"
                        helperText="Parametro Path"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                    <TextInput
                        label="Parametro Descripcion"
                        source="parametro_descripcion"
                        formClassName={classes.parametro_descripcion}
                        placeholder="Ingrese Parametro Descripcion"
                        helperText="Parametro Descripcion"
                        margin="normal"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                    />
                    <br/>

                </FormTab>
            </TabbedForm>
        </Create>
    )
};
export default ParametroPathCreate;


