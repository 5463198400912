import React from 'react';
import {Datagrid, Link, TextField, useRefresh} from 'react-admin';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


const LinkEditButton = ({record}) => {
    const classes = useStyles();
    const [openAprobado, setAprobadoOpen] = React.useState(false);

    const handleAprobadoOpen = () => {
        setAprobadoOpen(true);
    };

    const handleAprobadoClose = () => {
        setAprobadoOpen(false);
    };
    var w_orden_compra_estado = record.orden_compra_estado
    if(w_orden_compra_estado==null){
        w_orden_compra_estado=''
    }
    w_orden_compra_estado=w_orden_compra_estado.trim()
    var w_orden_compra_nro = record.orden_compra_nro
    var w_orden_compra_ampliacion_nro = record.orden_compra_ampliacion_nro


    if(w_orden_compra_estado==='APROBADO') {
        return (
            <div>
                <Button
                    onClick={handleAprobadoOpen}
                    size={"small"}
                    startIcon={<EditIcon/>}
                    // style={{color: '#bdbdbd'}}
                    disabled
                >
                    <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}
                         title={"Para Editar Orden de Compra, el estado no debe ser APROBADO N°: "+w_orden_compra_nro+" Apl.: "+w_orden_compra_ampliacion_nro+"."}
                    >
                        Editar
                    </div>
                </Button>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openAprobado}
                    onClose={handleAprobadoClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openAprobado}>
                        <div className={classes.paper}>
                            <h2 id="transition-modal-title">Editar</h2>
                            <p id="transition-modal-description">Para Editar Orden de Compra, el estado no debe ser APROBADO.</p>
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }else{
        return (
            <Button
                component={Link}
                to={`/OrdenCompraCabecera/${record.id}`}
                color="primary"
                size={"small"}
                startIcon={<EditIcon/>}
                title={"Editar Orden de Compra N°: "+w_orden_compra_nro+" Apl.: "+w_orden_compra_ampliacion_nro+"."}
            >
                Editar
            </Button>

        );
    }
}

LinkEditButton.defaultProps = {
    source: '',
    addLabel: true,
};

export default LinkEditButton;