// import React from 'react';
//
// var w_apiurlserver
//
//
//
//  w_apiurlserver = 'http://10.10.0.59:4620'; // DB002 SERVIDOR OFICINA
//
//
// //w_apiurlserver = 'https://opi.radionacional.ar:4443/'; // SERVIDOR RNA
//
// const apiurlserver = w_apiurlserver
//
// export default apiurlserver;

// import React from 'react';
// import {Link} from "react-admin";

console.log('window.location')

var w_location_hostname = window.location.hostname

console.log(w_location_hostname)

var w_location_href = window.location.href

console.log(w_location_href)

var w_location_protocol = window.location.protocol
if(w_location_hostname==null){
 w_location_hostname=''
}
w_location_hostname=w_location_hostname.trim()

console.log(w_location_protocol)

var w_apiurlserver

switch (w_location_hostname) {
  case 'opi.radionacional.ar':
    w_apiurlserver = 'https://opi.radionacional.ar:4443/'
    break
  case 'proveedores.radionacional.ar':
    w_apiurlserver = 'https://archivprov.radionacional.ar/'
    break
  case '192.168.0.32':
    w_apiurlserver = 'http://192.168.0.32:4620'
    break
  default:
    w_apiurlserver = 'http://'+w_location_hostname+':4620'
    break
}

// w_apiurlserver = 'http://10.10.0.59:8080'
// w_apiurlserver = 'http://10.10.0.60:8080'
// w_apiurlserver = 'http://10.10.0.59:8080'

const apiurlserver = w_apiurlserver

export default apiurlserver;
