import React, {useState} from 'react';
import PersonaTipoBusquedaDataGrid from "./PersonaTipoBusquedaDatagrid"
import {Drawer} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/icons/Search';
import {makeStyles} from '@material-ui/core/styles';

export const PersonaTipoBusquedaBtn = (props) => {
    const useStyles = makeStyles({
        paper: {
            width: '25%'
        },
        button: {
            justifyContent: 'left',
            display: 'inline-flex',
            fontWeight: 'bold',
            margin: 0,
            marginBottom: 0
        }
    });
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton color="primary" onClick={() => setOpen(!open)}>
                <Icon/>
            </IconButton>

            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <PersonaTipoBusquedaDataGrid
                    callbackPersonaTipo={props.callbackPersonaTipo}
                    persona_tipo={props.persona_tipo}
                    callbackCloseDrawer={handleDrawerOpen}
                />

            </Drawer>
        </div>
    )
};
export default PersonaTipoBusquedaBtn;