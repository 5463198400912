import React, {useEffect, useState} from 'react';
import {useDataProvider, Loading, useNotify, useMutation, Button, SelectInput, TextInput, Link} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List"
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import TabletIcon from '@material-ui/icons/Tablet';
import CompareIcon from '@material-ui/icons/Compare';
import VignetteIcon from '@material-ui/icons/Vignette';
import PersonIcon from '@material-ui/icons/Person';
import ShopIcon from '@material-ui/icons/Shop';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PaymentIcon from '@material-ui/icons/Payment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WarningIcon from '@material-ui/icons/Warning';
import BackupIcon from "@material-ui/icons/Backup";
import Avatar from '@material-ui/core/Avatar';
import Icon from "@material-ui/icons/Add";
import Imagepvm from '../layout/tvpublica_logo_2021.bmp'; // Import using relative path
import Imagecpm from '../layout/headerRTA.jpg';
import HistoryIcon from '@material-ui/icons/History';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import UndoIcon from '@material-ui/icons/Undo';
import SwitchCameraIcon from '@material-ui/icons/SwitchCamera';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import IconButton from "@material-ui/core/IconButton";
import SupervisedUserCircleTwoToneIcon from '@material-ui/icons/SupervisedUserCircleTwoTone';
import CommentIcon from '@material-ui/icons/Comment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import cartuchonewlargo from "../TableroControl/cartuchonewlargo.png";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import AddBoxIcon from "@material-ui/icons/AddBox";
import WebIcon from "@material-ui/icons/Web";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SearchIcon from "@material-ui/icons/Search";
import AssistantIcon from "@material-ui/icons/Assistant";
import FilterFramesIcon from "@material-ui/icons/FilterFrames";
import ShopTwoIcon from "@material-ui/icons/ShopTwo";
import BusinessIcon from "@material-ui/icons/Business";
import TableChartIcon from "@material-ui/icons/TableChart";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PolicyIcon from "@material-ui/icons/Policy";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import CardMedia from "@material-ui/core/CardMedia";
import Imageprincipalrta from '../layout/headerRTA.jpg';
import PieChartIcon from '@material-ui/icons/PieChart';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import TextField from "@material-ui/core/TextField";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import OCIcon from '@mui/icons-material/Feed';
import NuevaOCIcon from '@mui/icons-material/PostAdd';
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import UpdateIcon from '@material-ui/icons/Update';

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
    table: {
        minWidth: 650,
    },
});

//--------------------  recibe como parametro la fecha de proceso ------------------

const UserUsuComDatagrid = props => {

//        console.log('w_valor parametro ',props)

    var w_valor = props

    console.log('UserUsuComDatagrid----w_valor')
    console.log(w_valor.record.id)

    var visitors = []

    const classes = useStyles()

    const dataProvider = useDataProvider();
    const [EstadoDetalle, setEstadoDetalle] = useState(1);
    const [pvmadjuntos, setPvmAdjuntos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const CambiaEstadoAdjuntos = () => {
        console.log('UserUsuComDatagrid----CambiaEstadoAdjuntos')
        setEstadoDetalle(EstadoDetalle + 1);
    };

//--- el efect se ejecuta por primera vez y cuando cambia el estado del boton detall o cambia la fecha de proceso -------

    useEffect(() => {

        dataProvider.getList(
            'UserUsuComDetalle',
            {
                filter: {id: w_valor.record.id},
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },)
            .then(({data}) => {
                setPvmAdjuntos(data);
                setLoading(false);
            })
            .catch(error => {
                CambiaEstadoAdjuntos()
                // setError(error);
                // setLoading(false);

            })
    }, [EstadoDetalle, w_valor.record.id]);

    //----------------- chequea datos estado del componente -----------------------------

    if (loading) return <Loading loadingPrimary="  " loadingSecondary="Actualizando" />      // no cargo todavia

    if (error) {
        console.log('error')
        console.log(error)
        // return <Error/>;                           // error
    };

    //----------- si ya tiene los datos cargados en forma asincrónica actualiza el DOM --------------

    if (pvmadjuntos.length > 0) {

        visitors = pvmadjuntos

        // console.log('visitors')
        // console.log(visitors)
        // alert('Hola')

//---------------------- botones detalle / sin detalle --------------------------

        const BotonEliminarDetelle = (record) => {

            var w_id = record.id

            console.log('w_id---BotonEliminar')
            console.log(w_id)

            var w_role = record.role


            if(w_role==null){
                w_role=''
            }
            w_role=w_role.trim()

            console.log('w_role')
            console.log(w_role)

            var w_activo = record.activo
            if(w_activo==null){
                w_activo=''
            }
            w_activo=w_activo.trim()

            console.log('w_activo')
            console.log(w_activo)

            console.log('record---BotonEliminar')
            console.log(record)

            var w_usu_role_id_ing = record.usu_role_id_ing
            var w_usu_com_id_ing = record.usu_com_id_ing

            const CambiaEstado = () => {
                setEstadoDetalle(EstadoDetalle + 1);
            };

            const notify = useNotify();

            const [eliminardetelle, {loading}] = useMutation({
                    type: 'update',
                    resource: 'UserUsuComDetalle',
                    payload: {id: w_id, data: {role: w_role, status: 'B', usu_role_id_ing: w_usu_role_id_ing,
                            usu_com_id_ing: w_usu_com_id_ing}}
                },
                {
                    onSuccess: ({data}) => {
                        // notify('('+w_role+') Seleccionada aprobado para Eliminar', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------
                        CambiaEstado()
                    },
                    onFailure: error => {
                        notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                    },
                }
            );
            return (
                <Button title={"Desmarcar"}
                    // label="Eliminar"
                        onClick={eliminardetelle} disabled={loading}
                        style={{color: w_activo === undefined ? '' : w_activo.trim() === 'S' ? '#32c832':
                                w_activo.trim() === 'V' ? '#e6e6e6' : '#a1a1a1'}}
                >
                    <ToggleOnIcon   style={{fontSize: 30}}/>
                </Button>
            );
        };


        const BotonGenerarDetelle = (record) => {

            var w_id = record.id

            console.log('w_id---BotonEliminar')
            console.log(w_id)

            var w_role = record.role

            if(w_role==null){
                w_role=''
            }
            w_role=w_role.trim()

            console.log('w_role')
            console.log(w_role)

            var w_activo = record.activo
            if(w_activo==null){
                w_activo=''
            }
            w_activo=w_activo.trim()

            console.log('w_activo')
            console.log(w_activo)

            var w_usu_role_id_ing = record.usu_role_id_ing
            var w_usu_com_id_ing = record.usu_com_id_ing

            console.log('record---BotonEliminar')
            console.log(record)


            const CambiaEstado = () => {
                setEstadoDetalle(EstadoDetalle + 1);
            };

            const notify = useNotify();

            const [generardetelle, {loading}] = useMutation({
                    type: 'update',
                    resource: 'UserUsuComDetalle',
                    payload: {id: w_id, data: {role: w_role, status: 'A', usu_role_id_ing: w_usu_role_id_ing,
                            usu_com_id_ing: w_usu_com_id_ing}}
                },
                {
                    onSuccess: ({data}) => {
                        // notify('('+w_role+') Seleccionada aprobado para Generar Detelle', 'info', {}, true);
//----------------------- cambia el estado del componente para vover a renderizar ----------------
                        CambiaEstado()
                    },
                    onFailure: error => {
                        notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                    },
                }
            );
            return (
                <Button title={"Marcar"}
                    // label="Eliminar"
                        onClick={generardetelle} disabled={loading}
                        style={{color: w_activo === undefined ? '' : w_activo.trim() === 'S' ? '#32c832' :
                                w_activo.trim() === 'V' ? '#ffbe00': '#4766ff'}}
                >
                    <ToggleOffIcon style={{fontSize: 30}}/>
                </Button>
            );
        };

        return (

            <div>
                <div>
                    <List>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>
                                            {'Detalle'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                            {'ROLE'}
                                        </Typography>
                                    </TableCell>
                                    {/*<TableCell>*/}
                                    {/*    <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>*/}
                                    {/*        {'Version'}*/}
                                    {/*    </Typography>*/}
                                    {/*</TableCell>*/}
                                    {/*<TableCell>*/}
                                    {/*    <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>*/}
                                    {/*        {'Activo'}*/}
                                    {/*    </Typography>*/}
                                    {/*</TableCell>*/}
                                    {/*<TableCell>*/}
                                    {/*    <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>*/}
                                    {/*        {'Marcar'}*/}
                                    {/*    </Typography>*/}
                                    {/*</TableCell>*/}
                                    {/*<TableCell>*/}
                                    {/*    <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>*/}
                                    {/*        {'Desmarcar'}*/}
                                    {/*    </Typography>*/}
                                    {/*</TableCell>*/}
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                            {''}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                            {'Descripcion'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {visitors.map(record => {
                                return (
                                    <TableRow>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.textleft}>
                                                                <Typography style={{color: record.ordenManual === undefined
                                                                        ? '' : record.ordenManual === 1
                                                                            ? '#32c832' :'#000000'}}>
                                                                    {record.menu==='Inicio' ? <DashboardIcon/> : ''}
                                                                    {record.menu==='Contrataciones' ? <WebAssetIcon/> : ''}
                                                                    {record.menu==='Ordenes de Compra' ? <OCIcon/> : ''}
                                                                    {record.menu==='Proveedores' ? <PersonIcon/> : ''}
                                                                    {record.menu==='Tablas Auxiliares' ? <AssistantIcon/> : ''}
                                                                    {record.menu==='Mis Notificaciones Hist.' ? <NotificationsIcon/> : ''}

                                                                    {record.menu==='Saldo ML' ? <SupervisedUserCircleTwoToneIcon style={{ color: '#0085ff' }}/> : ''}
                                                                    {record.menu==='Nota ML' ? <LocalOfferIcon/> : ''}
                                                                    {record.menu==='PreMensaje ML' ? <CommentIcon/> : ''}
                                                                    {record.menu==='Proveedor Grupo' ? <ContactMailIcon/> : ''}
                                                                    {record.menu==='Cliente Grupo' ? <PersonIcon/> : ''}
                                                                    {record.menu==='Constancia AFIP' ? <AccountBoxIcon/> : ''}
                                                                    {record.menu==='Parametros' ? <SwitchCameraIcon/> : ''}
                                                                    {/*{record.menu==='Parametros Pasar A' ? <SwitchCameraIcon/> : ''}*/}
                                                                    {record.menu==='Familia' ? <VignetteIcon/> : ''}
                                                                    {record.menu==='Cotizador Menu' ? <VisibilityIcon style={{color: '#aa0000', backgroundColor: '#ffffff'}}/> : ''}
                                                                    {/*{record.menu==='Usuarios del Sistema' ? <WarningIcon style={{color: '#fffd1e', backgroundColor: '#000000'}}/> : ''}*/}
                                                                    {record.menu==='Usuarios del Sistema' ? <AssignmentIndIcon style={{color: '#dcaadc'}} /> : ''}
                                                                    {record.menu==='Parametros del Sistema' ? <AssignmentLateIcon style={{ color: '#a7c5f5'}} /> : ''}
                                                                    {record.menu==='Parametros del Path' ? <AssignmentLateIcon style={{ color: '#00c8ff'}} /> : ''}
                                                                    {record.menu==='xxl' ? <ShopIcon style={{ background: `url(${cartuchonewlargo}) no-repeat`}} /> : ''}
                                                                    {record.menu}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={classes.textleft}>
                                                                <Typography style={{color: '#000000'}}>
                                                                    {record.submenu==='Bienvenido' ? <CardMedia
                                                                        style={{
                                                                            width: "auto",
                                                                            maxHeight: "40px"
                                                                        }}
                                                                        component="img"
                                                                        image={Imageprincipalrta}
                                                                        title="RTA"
                                                                    /> : ''}
                                                                    {record.submenu==='Contrataciones' ? <WebAssetIcon/> : ''}
                                                                    {record.submenu==='Consulta Ordenes de Compra' ? <OCIcon/> : ''}
                                                                    {record.submenu==='Nueva Orden de Compra' ? <NuevaOCIcon/> : ''}
                                                                    {record.submenu==='Nueva Contratación' ? <AddBoxIcon/> : ''}
                                                                    {record.submenu==='Consulta Contrataciones' ? <WebAssetIcon/> : ''}
                                                                    {record.submenu==='Consulta Contrataciones (G)' ? <WebAssetIcon style={{color: '#64b4ff'}}/> : ''}
                                                                    {record.submenu==='Vista Contrataciones' ? <VisibilityIcon/> : ''}
                                                                    {record.submenu==='Tablero Control' ? <TabletIcon/> : ''}
                                                                    {record.submenu==='Listado de Proveedores' ? <PersonOutlineIcon/> : ''}
                                                                    {record.submenu==='Nuevo Proveedor' ? <PersonAddIcon/> : ''}
                                                                    {record.submenu==='Buscador por Clase' ? <SearchIcon/> : ''}
                                                                    {record.submenu==='Transf. de Proveedores' ? <TransferWithinAStationIcon/> : ''}


                                                                    {record.submenu==='Contratación' ? <FilterFramesIcon/> : ''}
                                                                    {record.submenu==='Compras' ? <ShopTwoIcon/> : ''}
                                                                    {record.submenu==='Estructura Unidades' ? <BusinessIcon/> : ''}
                                                                    {record.submenu==='Tablas AFIP' ? <AccountBalanceIcon/> : ''}
                                                                    {record.submenu==='Otras Tablas' ? <TableChartIcon/> : ''}
                                                                    {record.submenu==='Notificacion' ? <NotificationsIcon/> : ''}
                                                                    {record.submenu}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={classes.textleft}>
                                                                <Typography style={{color: '#000000'}}>
                                                                    {record.boton==='Todos los Articulos ML' ? <Avatar
                                                                        src={`${Imagepvm}?size=${30}x${30}`}
                                                                        size={30}
                                                                        style={{ width: 30, height: 30 }}
                                                                    /> : ''}
                                                                    {record.boton==='Todos los Articulos ML' ? <Avatar
                                                                        src={`${Imagecpm}?size=${30}x${30}`}
                                                                        size={30}
                                                                        style={{ width: 30, height: 30 }}
                                                                    /> : ''}
                                                                    {record.boton==='Articulos ML Provemet' ? <Avatar
                                                                        src={`${Imagepvm}?size=${30}x${30}`}
                                                                        size={30}
                                                                        style={{ width: 30, height: 30 }}
                                                                    /> : ''}
                                                                    {record.boton==='Articulos ML Complemet' ? <Avatar
                                                                        src={`${Imagecpm}?size=${30}x${30}`}
                                                                        size={30}
                                                                        style={{ width: 30, height: 30 }}
                                                                    /> : ''}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={classes.textleft}>
                                                                <Typography style={{color: '#5abebe'}}>
                                                                    {record.boton==='Auditoria' ?
                                                                        <Button variant="outlined" color="primary">
                                                                            <div title={'Auditoria Interna'}
                                                                                 style={{
                                                                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                                <PolicyIcon style={{color: '#ff8040'}}/>
                                                                            </div>
                                                                        </Button>
                                                                        : ''}
                                                                    {record.boton==='Mis Notificaciones Hist. Todas' ?
                                                                        <SelectInput  disabled={true}
                                                                                      label={  <div title={'Usuario de la Notificacion'}
                                                                                          style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                                <PersonIcon style={{color: '#0064c8'}} />
                                                                            </div>
                                                                            }
                                                                            source="username"
                                                                            choices={[
                                                                                {id: 'TODOS', name: 'Todos'},
                                                                                {id: 'DISTINTO', name: 'Distintos al Mio'},
                                                                            ]}
                                                                        />
                                                                        : ''}
                                                                    {record.boton==='Tablas Auxiliares Crear' ?
                                                                        <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                            <Button
                                                                                label="Crear"
                                                                            >
                                                                                <AddIcon/>
                                                                            </Button>
                                                                        </div>
                                                                        : ''}
                                                                    {record.boton==='Tablas Auxiliares Editar' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <EditIcon style={{fontSize: 20}}/> Editar
                                                                        </div>
                                                                        : ''}
                                                                    {record.boton==='Listado de Proveedores Mostrar' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <VisibilityIcon style={{fontSize: 20}}/> Mostrar
                                                                        </div>
                                                                        : ''}
                                                                    {record.boton==='Listado de Proveedores Editar' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <EditIcon style={{fontSize: 20}}/> Editar
                                                                        </div>
                                                                        : ''}

                                                                    {record.boton==='Consulta Contrataciones Mostrar' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <VisibilityIcon style={{fontSize: 20}}/> Mostrar
                                                                        </div>
                                                                        : ''}
                                                                    {record.boton==='Listado de Proveedores Documentacion' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <div>
                                                                                <AttachFileIcon style={{fontSize: 20}}/> Documentación
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {record.boton==='Contrataciones Seguimiento Completo' ?
                                                                        <FormatListNumberedIcon/>
                                                                        : ''}
                                                                    {record.boton==='Contrataciones Seguimiento Limitado' ?
                                                                        <FormatListBulletedIcon/>
                                                                        : ''}
                                                                    {record.boton==='Estados Contrataciones' ?
                                                                        <ListAltIcon/>
                                                                        : ''}
                                                                    {record.boton==='Vista Contrataciones Mostrar' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <VisibilityIcon style={{fontSize: 20}}/> Mostrar
                                                                        </div>
                                                                        : ''}
                                                                    {record.boton==='Vista Contrataciones Editar' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <EditIcon style={{fontSize: 20}}/> Editar
                                                                        </div>
                                                                        : ''}
                                                                    {record.boton==='Vista Contrataciones Visitar' ?
                                                                        <Button variant="outlined" color="primary">
                                                                            <div title={''}
                                                                                 style={{
                                                                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                                <VisibilityIcon/>
                                                                            </div>
                                                                        </Button>
                                                                        : ''}
                                                                    {record.boton==='Ver Grafico de la Unidad de Negocio Invariablemente' ?
                                                                        <PieChartIcon style={{fontSize: 20}}/>
                                                                        : ''}
                                                                    {record.boton==='Ver Grafico de la Unidad de Area Invariablemente' ?
                                                                        <PieChartIcon style={{fontSize: 20}}/>
                                                                        : ''}
                                                                    {record.boton==='Tablero Control Mostrar' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <VisibilityIcon style={{fontSize: 20}}/> Mostrar
                                                                        </div>
                                                                        : ''}

                                                                    {record.boton==='Vista Contrataciones Visitas Historicas' ?
                                                                        <Button variant="outlined" color="primary">
                                                                            <div title={''}
                                                                                 style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                            >
                                                                                <HistoryIcon/>
                                                                            </div>
                                                                        </Button>
                                                                        : ''}


                                                                    {record.boton==='Consulta Ordenes de Compra Mostrar' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <VisibilityIcon style={{fontSize: 20}}/> Mostrar
                                                                        </div>
                                                                        : ''}
                                                                    {record.boton==='Consulta Ordenes de Compra Editar' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <EditIcon style={{fontSize: 20}}/> Editar
                                                                        </div>
                                                                        : ''}

                                                                    {record.boton==='Consulta Ordenes de Compra Actualizar Estado' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <UpdateIcon style={{fontSize: 20}}/>
                                                                        </div>
                                                                        : ''}
                                                                    {record.boton==='Consulta Ordenes de Compra PDF Generado' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <PictureAsPdfIcon style={{fontSize: 20}}/>
                                                                        </div>
                                                                        : ''}

                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={classes.textleft}>
                                                                <Typography style={{color: '#5abebe'}}>
                                                                    {record.boton}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={classes.textleft}>
                                                                <Typography style={{color: '#5abebe'}}>
                                                                    {record.subboton==='Vista Contrataciones Editar la Fecha de Alta' ?
                                                                        <TextField
                                                                            label="Fecha"
                                                                            type="date"
                                                                            InputLabelProps={{shrink: true,}}
                                                                            inputProps={{readOnly: true}}
                                                                            variant="filled"
                                                                        />
                                                                        : ''}
                                                                    {record.subboton==='Vista Contrataciones Editar el Tipo de Encuadre' ?
                                                                        <div title={''}
                                                                             style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}
                                                                        >
                                                                            <EditIcon style={{fontSize: 20}}/> Editar Encuadre
                                                                        </div>
                                                                        : ''}
                                                                    {record.subboton==='Agregar Documentación' ?
                                                                        <div title={'Agregar Documentación'}
                                                                             style={{
                                                                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center',
                                                                                 color: '#0064c8'}}>
                                                                            <PlaylistAddIcon style={{fontSize: 20}}/>
                                                                        </div>
                                                                        : ''}
                                                                    {record.subboton==='Eliminar Documentación' ?
                                                                        <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                                                        >
                                                                            <div title={'Eliminar Documentación'}
                                                                                 style={{
                                                                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                                <DeleteForeverIcon style={{color: '#c00000', backgroundColor: '#ffffff', fontSize: 20}}/>
                                                                            </div>
                                                                        </Button>
                                                                        : ''}
                                                                    {record.subboton==='Agregar Archivo de la Documentación' ?
                                                                        <div title={'Agregar Archivo de la Documentación'}
                                                                             style={{
                                                                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center', color: '#0064c8'}}>
                                                                            <Icon style={{fontSize: 20}}/>
                                                                            <AttachFileIcon style={{fontSize: 20}}/>
                                                                        </div>
                                                                        : ''}
                                                                    {record.subboton==='Eliminar Archivo de la Documentación' ?
                                                                        <Button variant="outlined" style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                                                        >
                                                                            <div title={'Eliminar Archivo de la Documentación'}
                                                                                 style={{
                                                                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                                                                                <DeleteForeverIcon style={{color: '#c00000', backgroundColor: '#ffffff', fontSize: 20}}/>
                                                                            </div>
                                                                        </Button>
                                                                        : ''}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className={classes.textleft}>
                                                                <Typography style={{color: '#c895c8'}}>
                                                                    {record.subboton}
                                                                </Typography>
                                                            </TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography>
                                                {record.role}
                                            </Typography>
                                        </TableCell>
                                        {/*<TableCell className={classes.textcenter}>*/}
                                        {/*    <Typography>*/}
                                        {/*        {record.version}*/}
                                        {/*    </Typography>*/}
                                        {/*</TableCell>*/}
                                        {/*<TableCell className={classes.textcenter}>*/}
                                        {/*    <Typography>*/}
                                        {/*        {record.activo}*/}
                                        {/*    </Typography>*/}
                                        {/*</TableCell>*/}
                                        {/*<TableCell className={classes.textcenter}>*/}
                                        {/*    <Typography>*/}
                                        {/*        <BotonGenerarDetelle  id = {record.role_id}*/}
                                        {/*                              role = {record.role}*/}
                                        {/*                              activo = {record.activo}*/}
                                        {/*                              usu_role_id_ing = {record.usu_role_id_ing}*/}
                                        {/*                              usu_com_id_ing = {record.usu_com_id_ing}*/}
                                        {/*        />*/}
                                        {/*    </Typography>*/}
                                        {/*</TableCell>*/}
                                        {/*<TableCell className={classes.textcenter}>*/}
                                        {/*    <Typography>*/}
                                        {/*        <BotonEliminarDetelle id = {record.role_id}*/}
                                        {/*                              role = {record.role}*/}
                                        {/*                              activo = {record.activo}*/}
                                        {/*                              usu_role_id_ing = {record.usu_role_id_ing}*/}
                                        {/*                              usu_com_id_ing = {record.usu_com_id_ing}*/}
                                        {/*        />*/}
                                        {/*    </Typography>*/}
                                        {/*</TableCell>*/}

                                        <TableCell className={classes.textcenter}>
                                            <Typography  style={{backgroundColor: '#ffffff'}} >
                                                {record.activo.trim() === 'N' ?
                                                <BotonGenerarDetelle  id = {record.role_id}
                                                                      role = {record.role}
                                                                      activo = {record.activo}
                                                                      usu_role_id_ing = {record.usu_role_id_ing}
                                                                      usu_com_id_ing = {record.usu_com_id_ing}
                                                />
                                                :
                                                <BotonEliminarDetelle id = {record.role_id}
                                                                      role = {record.role}
                                                                      activo = {record.activo}
                                                                      usu_role_id_ing = {record.usu_role_id_ing}
                                                                      usu_com_id_ing = {record.usu_com_id_ing}
                                                />}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.textleft}>
                                            <Typography style={{color: '#000000'}}>
                                                {record.descripcion}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </Table>
                    </List>
                </div>
            </div>
        )
    }else{
        return (
            <div>
                <Typography>
                    {'Sin Datos'}
                </Typography>
            </div>
        );
    }
}


export default UserUsuComDatagrid;