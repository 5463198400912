import React, {useState, useEffect} from 'react'
import {DataGrid, GridToolbarContainer, GridToolbarQuickFilter} from '@mui/x-data-grid';
import {useDataProvider, useRedirect, useCreate} from 'react-admin';
import {useBuscadorContext} from "../Context/BuscadorProveedorClaseContextProvider";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core";

const columns = [
    {field: 'rubro_d', headerName: 'Rubro', width: 400},
    {field: 'clase_d', headerName: 'Clase', width: 400},
    {field: 'cantidad', headerName: 'Proveedores', width: 250}
];

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1)
    }
}));

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter/>
        </GridToolbarContainer>
    );
}

const localizedTextsMap = {
    noRowsLabel: 'Sin filas',
    noResultsOverlayLabel: 'No se han encontrado resultados.',
    errorOverlayDefaultLabel: 'Ocurrió un error.',

    // Density selector toolbar button text
    toolbarDensity: 'Densidad',
    toolbarDensityLabel: 'Densidad',
    toolbarDensityCompact: 'Compacto',
    toolbarDensityStandard: 'Estándar',
    toolbarDensityComfortable: 'Cómodo',

    // Columns selector toolbar button text
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Seleccionar columnas',

    // Filters toolbar button text
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarFiltersTooltipHide: 'Ocultar filtros',
    toolbarFiltersTooltipShow: 'Mostrar filtros',
    toolbarFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Búsqueda…',
    toolbarQuickFilterLabel: 'Búsqueda',
    toolbarQuickFilterDeleteIconLabel: 'Despejado',

    // Export selector toolbar button text
    toolbarExport: 'Exportar',
    toolbarExportLabel: 'Exportar',
    toolbarExportCSV: 'Descargar como CSV',
    toolbarExportPrint: 'Impresión',
    toolbarExportExcel: 'Descargar como Excel',

    // Columns panel text
    columnsPanelTextFieldLabel: 'Buscar columna',
    columnsPanelTextFieldPlaceholder: 'Título de la columna',
    columnsPanelDragIconLabel: 'Reordenar columna',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',

    // Filter panel text
    filterPanelAddFilter: 'Añadir filtro',
    filterPanelDeleteIconLabel: 'Borrar',
    filterPanelLinkOperator: 'Operador lógico',
    filterPanelOperators: 'Operador', // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor del filtro',

    // Filter operators text
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'es igual',
    filterOperatorStartsWith: 'comienza con',
    filterOperatorEndsWith: 'termina con',
    filterOperatorIs: 'es',
    filterOperatorNot: 'no es',
    filterOperatorAfter: 'es despues',
    filterOperatorOnOrAfter: 'está en o después',
    filterOperatorBefore: 'es antes',
    filterOperatorOnOrBefore: 'está en o antes',
    filterOperatorIsEmpty: 'Está vacío',
    filterOperatorIsNotEmpty: 'No esta vacío',
    filterOperatorIsAnyOf: 'es cualquiera de',

    // Filter values text
    filterValueAny: 'ningún',
    filterValueTrue: 'verdadero',
    filterValueFalse: 'falso',

    // Column menu text
    columnMenuLabel: 'Menú',
    columnMenuShowColumns: 'Mostrar columnas',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Ocultar',
    columnMenuUnsort: 'Desclasificar',
    columnMenuSortAsc: 'Ordenar por ASC',
    columnMenuSortDesc: 'Ordenar por DESC',

    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
        count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,
    columnHeaderFiltersLabel: 'Mostrar filtros',
    columnHeaderSortIconLabel: 'Clasificar',

    // Rows selected footer text
    footerRowSelected: (count) =>
        count !== 1
            ? `${count.toLocaleString()} filas seleccionadas`
            : `${count.toLocaleString()} fila seleccionada`,

    // Total row amount footer text
    footerTotalRows: 'Filas Totales:',

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
        `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: 'Selección de casilla de verificación',
    checkboxSelectionSelectAllRows: 'Seleccionar todas las filas',
    checkboxSelectionUnselectAllRows: 'Deseleccionar todas las filas',
    checkboxSelectionSelectRow: 'Seleccionar fila',
    checkboxSelectionUnselectRow: 'Deseleccionar fila',

    // Texto de celda booleano
    booleanCellTrueLabel: 'sí',
    booleanCellFalseLabel: 'no',

    // Celda de acciones más texto
    actionsCellMore: 'más',

    // Texto de anclaje de columna
    pinToLeft: 'Alfiler a la izquierda',
    pinToRight: 'Alfiler a derecha',
    unpin: 'Desprender',

    // Tree Data
    treeDataGroupingHeaderName: 'Grupo',
    treeDataExpand: 'ver niños',
    treeDataCollapse: 'ocultar niños',

    // Grouping columns
    groupingColumnHeaderName: 'Grupo',
    groupColumn: (name) => `Agrupar por ${name}`,
    unGroupColumn: (name) => `Deja de agrupar por ${name}`,

    // Master/detail
    detailPanelToggle: 'Alternar panel de detalles',
    expandDetailPanel: 'Expandir',
    collapseDetailPanel: 'Colapsar',

    // Claves de traducción de componentes principales usadas
    MuiTablePagination: {
        labelRowsPerPage: 'Filas por página:',
        labelDisplayedRows: ({ from, to, count }) =>
            `${from} - ${to} de mas de ${count}`,
    },

    // Row reordering text
    rowReorderingHeaderName: 'Reordenación de filas',

    // Aggregation
    aggregationMenuItemHeader: 'Agregación',
    aggregationFunctionLabelSum: 'suma',
    aggregationFunctionLabelAvg: 'promedio',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'Talla',
};

export const BuscarClasesList = ({setActiveStep}) => {
    const classes = useStyles();
    const {addClases, rubrosList, limpiarClases, limpiarRubros} = useBuscadorContext();
    const redirectTo = useRedirect();
    const redirect = (basePath, id, data) => `/proveedorClaseBuscador/?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=10&sort=id`;

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sort, setSort] = useState({field: 'id', order: 'ASC'})
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedClases, setSelectedClases] = useState([]);
    const [data, setData] = useState([]);
    const [create, {isLoading, error}] = useCreate();

    const dataProvider = useDataProvider();
    useEffect(() => {
        setLoading(true);
        dataProvider.getList(
            'ProveedorBuscadorClase',
            {
                filter: {
                    rubros: rubrosList
                },
                sort,
                pagination: {page: page, perPage: perPage},
            },)
            .then(({data}) => {
                setData(data);
                setTotal(data.length);
            })
            .catch(error => {
                console.log(error)
            })
            .finally(
                setLoading(false)
            )
    }, [rubrosList]);


    // useEffect(() => {
    //     console.log(rubrosList)
    // }, [rubrosList]);

    const handleActualizar = (async () => {
        // await create('proveedorClaseBuscador', {clases: selectedClases});
        if (selectedClases.length > 0) {
            await dataProvider.create('proveedorClaseBuscador', {data: {clases: selectedClases}});
            redirectTo(redirect);
            // setActiveStep(2);
        }


    });

    const handleVolver = () => {
        setActiveStep(0);
        limpiarClases();
        limpiarRubros();
    };
    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '700px', width: '100%'}}>
            <div style={{flexDirection: 'row', alignItems: 'center', marginBottom: '5px'}}>

                <Button onClick={handleVolver}
                        className={classes.button}
                        variant="contained"
                        style={{color: '#2497FF'}}
                >VOLVER</Button>

                {selectedClases.length > 0 ?
                    <>
                        <Button onClick={handleActualizar}
                                variant="contained"
                                style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                className={classes.button}>
                            Buscar Proveedores
                        </Button>
                    </>
                    :
                    <>
                        <Button disabled
                                variant="contained"
                            // style={{color: '#ffffff', backgroundColor: '#123B80'}}
                                className={classes.button}>
                            Buscar Proveedores
                        </Button>
                    </>
                }

            </div>
            <DataGrid
                disableVirtualization
                keepNonExistentRowsSelected
                pagination
                checkboxSelection
                NoRowsOverlay
                selectionModel={selectedClases}
                onSelectionModelChange={(e) => {
                    setSelectedClases(e);
                    addClases(e)
                }}
                rows={data}
                columns={columns}
                components={{Toolbar: CustomToolbar}}
                loading={loading}
                autoPageSize
                localeText={localizedTextsMap}
            />
        </div>
    )
};

export default BuscarClasesList