import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import AssistantIcon from '@material-ui/icons/Assistant';
import SubMenu from './SubMenu';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {withRouter} from 'react-router-dom';
import {MenuItemLink, Responsive, translate, WithPermissions} from 'react-admin';
import SearchIcon from '@material-ui/icons/Search';
import ShopTwoIcon from '@material-ui/icons/ShopTwo';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BusinessIcon from '@material-ui/icons/Business';
import TableChartIcon from '@material-ui/icons/TableChart';
import PublicIcon from '@material-ui/icons/Public';
import MapIcon from '@material-ui/icons/Map';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import DescriptionIcon from '@material-ui/icons/Description';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import WcIcon from '@material-ui/icons/Wc';
import PeopleIcon from '@material-ui/icons/People';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import MoneyIcon from '@material-ui/icons/Money';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import LineStyleIcon from '@material-ui/icons/LineStyle';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import BallotIcon from '@material-ui/icons/Ballot';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import ClassIcon from '@material-ui/icons/Class';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import DnsIcon from '@material-ui/icons/Dns';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import TocIcon from '@material-ui/icons/Toc';
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FilterFramesIcon from '@material-ui/icons/FilterFrames';
import DvrIcon from '@material-ui/icons/Dvr';
import GradientIcon from '@material-ui/icons/Gradient';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import WebIcon from '@material-ui/icons/Web';
import TabletIcon from '@material-ui/icons/Tablet';
import VisibilityIcon from '@material-ui/icons/Visibility';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import AddBoxIcon from '@material-ui/icons/AddBox';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';

import OCIcon from '@mui/icons-material/Feed';
import NuevaOCIcon from '@mui/icons-material/PostAdd';

const role_contratacion_menu = ({permissions}) => {
    var w_role_contratacion_menu = ''
    var w_permissions_contratacion_menu = permissions
    if (w_permissions_contratacion_menu == null) {
    } else {
        var w_role_contratacion_menu_aux = w_permissions_contratacion_menu.indexOf('ROLE_CONTRATACION_MENU')
        if (w_role_contratacion_menu_aux === -1) {
        } else {
            w_role_contratacion_menu = 'ROLE_CONTRATACION_MENU'
        }
    }
    return w_role_contratacion_menu
}

const role_contratacion_nuevo_menu = ({permissions}) => {
    var w_role_contratacion_nuevo_menu = ''
    var w_permissions_contratacion_nuevo_menu = permissions
    if (w_permissions_contratacion_nuevo_menu == null) {
    } else {
        var w_role_contratacion_nuevo_menu_aux = w_permissions_contratacion_nuevo_menu.indexOf('ROLE_CONTRATACION_NUEVA_MENU')
        if (w_role_contratacion_nuevo_menu_aux === -1) {
        } else {
            w_role_contratacion_nuevo_menu = 'ROLE_CONTRATACION_NUEVA_MENU'
        }
    }
    return w_role_contratacion_nuevo_menu
}

const role_contratacion_consulta_menu = ({permissions}) => {
    var w_role_contratacion_consulta_menu = ''
    var w_permissions_contratacion_consulta_menu = permissions
    if (w_permissions_contratacion_consulta_menu == null) {
    } else {
        var w_role_contratacion_consulta_menu_aux = w_permissions_contratacion_consulta_menu.indexOf('ROLE_CONTRATACION_CONSULTA_MENU')
        if (w_role_contratacion_consulta_menu_aux === -1) {
        } else {
            w_role_contratacion_consulta_menu = 'ROLE_CONTRATACION_CONSULTA_MENU'
        }
    }
    return w_role_contratacion_consulta_menu
}

const role_contratacion_gerente_consulta_menu = ({permissions}) => {
    var w_role_contratacion_gerente_consulta_menu = ''
    var w_permissions_contratacion_gerente_menu = permissions
    if (w_permissions_contratacion_gerente_menu == null) {
    } else {
        var w_role_contratacion_gerente_consulta_menu_aux = w_permissions_contratacion_gerente_menu.indexOf('ROLE_CONTRATACION_GERENTE_CONSULTA_MENU')
        if (w_role_contratacion_gerente_consulta_menu_aux === -1) {
        } else {
            w_role_contratacion_gerente_consulta_menu = 'ROLE_CONTRATACION_GERENTE_CONSULTA_MENU'
        }
    }
    return w_role_contratacion_gerente_consulta_menu
}

const role_contratacion_visita_menu = ({permissions}) => {
    var w_role_contratacion_visita_menu = ''
    var w_permissions_contratacion_visita_menu = permissions
    if (w_permissions_contratacion_visita_menu == null) {
    } else {
        var w_role_contratacion_visita_menu_aux = w_permissions_contratacion_visita_menu.indexOf('ROLE_CONTRATACION_VISITA_MENU')
        if (w_role_contratacion_visita_menu_aux === -1) {
        } else {
            w_role_contratacion_visita_menu = 'ROLE_CONTRATACION_VISITA_MENU'
        }
    }
    return w_role_contratacion_visita_menu
}

const role_contratacion_tablero_menu = ({permissions}) => {
    var w_role_contratacion_tablero_menu = ''
    var w_permissions_contratacion_tablero_menu = permissions
    if (w_permissions_contratacion_tablero_menu == null) {
    } else {
        var w_role_contratacion_tablero_menu_aux = w_permissions_contratacion_tablero_menu.indexOf('ROLE_CONTRATACION_TABLERO_MENU')
        if (w_role_contratacion_tablero_menu_aux === -1) {
        } else {
            w_role_contratacion_tablero_menu = 'ROLE_CONTRATACION_TABLERO_MENU'
        }
    }
    return w_role_contratacion_tablero_menu
}

const role_orden_compra_menu = ({permissions}) => {
    var w_role_orden_compra_menu = ''
    var w_permissions_orden_compra_menu = permissions
    if (w_permissions_orden_compra_menu == null) {
    } else {
        var w_role_orden_compra_menu_aux = w_permissions_orden_compra_menu.indexOf('ROLE_ORDEN_COMPRA_MENU')
        if (w_role_orden_compra_menu_aux === -1) {
        } else {
            w_role_orden_compra_menu = 'ROLE_ORDEN_COMPRA_MENU'
        }
    }
    return w_role_orden_compra_menu
}

const role_orden_compra_consulta_menu = ({permissions}) => {
    var w_role_orden_compra_consulta_menu = ''
    var w_permissions_orden_compra_consulta_menu = permissions
    if (w_permissions_orden_compra_consulta_menu == null) {
    } else {
        var w_role_orden_compra_consulta_menu_aux = w_permissions_orden_compra_consulta_menu.indexOf('ROLE_ORDEN_COMPRA_CONSULTA_MENU')
        if (w_role_orden_compra_consulta_menu_aux === -1) {
        } else {
            w_role_orden_compra_consulta_menu = 'ROLE_ORDEN_COMPRA_CONSULTA_MENU'
        }
    }
    return w_role_orden_compra_consulta_menu
}

const role_orden_compra_nueva_menu = ({permissions}) => {
    var w_role_orden_compra_nueva_menu = ''
    var w_permissions_orden_compra_nueva_menu = permissions
    if (w_permissions_orden_compra_nueva_menu == null) {
    } else {
        var w_role_orden_compra_nueva_menu_aux = w_permissions_orden_compra_nueva_menu.indexOf('ROLE_ORDEN_COMPRA_NUEVA_MENU')
        if (w_role_orden_compra_nueva_menu_aux === -1) {
        } else {
            w_role_orden_compra_nueva_menu = 'ROLE_ORDEN_COMPRA_NUEVA_MENU'
        }
    }
    return w_role_orden_compra_nueva_menu
}



const role_orden_compra_tablero_menu = ({permissions}) => {
    var w_role_orden_compra_tablero_menu = ''
    var w_permissions_orden_compra_tablero_menu = permissions
    if (w_permissions_orden_compra_tablero_menu == null) {
    } else {
        var w_role_orden_compra_tablero_menu_aux = w_permissions_orden_compra_tablero_menu.indexOf('ROLE_ORDEN_COMPRA_TABLERO_MENU')
        if (w_role_orden_compra_tablero_menu_aux === -1) {
        } else {
            w_role_orden_compra_tablero_menu = 'ROLE_ORDEN_COMPRA_TABLERO_MENU'
        }
    }
    return w_role_orden_compra_tablero_menu
}

const role_proveedor_menu = ({permissions}) => {
    var w_role_proveedor_menu = ''
    var w_permissions_proveedor_menu = permissions
    if (w_permissions_proveedor_menu == null) {
    } else {
        var w_role_proveedor_menu_aux = w_permissions_proveedor_menu.indexOf('ROLE_PROVEEDOR_MENU')
        if (w_role_proveedor_menu_aux === -1) {
        } else {
            w_role_proveedor_menu = 'ROLE_PROVEEDOR_MENU'
        }
    }
    return w_role_proveedor_menu
}

const role_proveedor_nuevo_menu = ({permissions}) => {
    var w_role_proveedor_nuevo_menu = ''
    var w_permissions_proveedor_nuevo_menu = permissions
    if (w_permissions_proveedor_nuevo_menu == null) {
    } else {
        var w_role_proveedor_nuevo_menu_aux = w_permissions_proveedor_nuevo_menu.indexOf('ROLE_PROVEEDOR_NUEVO_MENU')
        if (w_role_proveedor_nuevo_menu_aux === -1) {
        } else {
            w_role_proveedor_nuevo_menu = 'ROLE_PROVEEDOR_NUEVO_MENU'
        }
    }
    return w_role_proveedor_nuevo_menu
}

const role_proveedor_transferencia_menu = ({permissions}) => {
    var w_role_proveedor_transferencia_menu = ''
    var w_permissions_proveedor_transferencia_menu = permissions
    if (w_permissions_proveedor_transferencia_menu == null) {
    } else {
        var w_role_proveedor_transferencia_menu_aux = w_permissions_proveedor_transferencia_menu.indexOf('ROLE_PROVEEDOR_TRANSFERENCIA_MENU')
        if (w_role_proveedor_transferencia_menu_aux === -1) {
        } else {
            w_role_proveedor_transferencia_menu = 'ROLE_PROVEEDOR_TRANSFERENCIA_MENU'
        }
    }
    return w_role_proveedor_transferencia_menu
}

const role_proveedor_consulta_menu = ({permissions}) => {
    var w_role_proveedor_consulta_menu = ''
    var w_permissions_proveedor_consulta_menu = permissions
    if (w_permissions_proveedor_consulta_menu == null) {
    } else {
        var w_role_proveedor_consulta_menu_aux = w_permissions_proveedor_consulta_menu.indexOf('ROLE_PROVEEDOR_CONSULTA_MENU')
        if (w_role_proveedor_consulta_menu_aux === -1) {
        } else {
            w_role_proveedor_consulta_menu = 'ROLE_PROVEEDOR_CONSULTA_MENU'
        }
    }
    return w_role_proveedor_consulta_menu
}

const role_proveedor_buscar_menu = ({permissions}) => {
    var w_role_proveedor_buscar_menu = ''
    var w_permissions_proveedor_buscar_menu = permissions
    if (w_permissions_proveedor_buscar_menu == null) {
    } else {
        var w_role_proveedor_buscar_menu_aux = w_permissions_proveedor_buscar_menu.indexOf('ROLE_PROVEEDOR_BUSCAR_MENU')
        if (w_role_proveedor_buscar_menu_aux === -1) {
        } else {
            w_role_proveedor_buscar_menu = 'ROLE_PROVEEDOR_BUSCAR_MENU'
        }
    }
    return w_role_proveedor_buscar_menu
}

const role_auxiliar_menu = ({permissions}) => {
    var w_role_auxiliar_menu = ''
    var w_permissions_auxiliar_menu = permissions
    if (w_permissions_auxiliar_menu == null) {
    } else {
        var w_role_auxiliar_menu_aux = w_permissions_auxiliar_menu.indexOf('ROLE_AUXILIAR_MENU')
        if (w_role_auxiliar_menu_aux === -1) {
        } else {
            w_role_auxiliar_menu = 'ROLE_AUXILIAR_MENU'
        }
    }
    return w_role_auxiliar_menu
}

const role_auxiliar_contratacion_menu = ({permissions}) => {
    var w_role_auxiliar_contratacion_menu = ''
    var w_permissions_auxiliar_contratacion_menu = permissions
    if (w_permissions_auxiliar_contratacion_menu == null) {
    } else {
        var w_role_auxiliar_contratacio_menu_aux = w_permissions_auxiliar_contratacion_menu.indexOf('ROLE_AUXILIAR_CONTRATACION_MENU')
        if (w_role_auxiliar_contratacio_menu_aux === -1) {
        } else {
            w_role_auxiliar_contratacion_menu = 'ROLE_AUXILIAR_CONTRATACION_MENU'
        }
    }
    return w_role_auxiliar_contratacion_menu
}

const role_auxiliar_compra_menu = ({permissions}) => {
    var w_role_auxiliar_compra_menu = ''
    var w_permissions_auxiliar_compra_menu = permissions
    if (w_permissions_auxiliar_compra_menu == null) {
    } else {
        var w_role_auxiliar_compra_menu_aux = w_permissions_auxiliar_compra_menu.indexOf('ROLE_AUXILIAR_COMPRA_MENU')
        if (w_role_auxiliar_compra_menu_aux === -1) {
        } else {
            w_role_auxiliar_compra_menu = 'ROLE_AUXILIAR_COMPRA_MENU'
        }
    }
    return w_role_auxiliar_compra_menu
}

const role_auxiliar_estructura_menu = ({permissions}) => {
    var w_role_auxiliar_estructura_menu = ''
    var w_permissions_auxiliar_estructura_menu = permissions
    if (w_permissions_auxiliar_estructura_menu == null) {
    } else {
        var w_role_auxiliar_estructura_menu_aux = w_permissions_auxiliar_estructura_menu.indexOf('ROLE_AUXILIAR_ESTRUCTURA_MENU')
        if (w_role_auxiliar_estructura_menu_aux === -1) {
        } else {
            w_role_auxiliar_estructura_menu = 'ROLE_AUXILIAR_ESTRUCTURA_MENU'
        }
    }
    return w_role_auxiliar_estructura_menu
}

const role_auxiliar_afip_menu = ({permissions}) => {
    var w_role_auxiliar_afip_menu = ''
    var w_permissions_auxiliar_afip_menu = permissions
    if (w_permissions_auxiliar_afip_menu == null) {
    } else {
        var w_role_auxiliar_afip_menu_aux = w_permissions_auxiliar_afip_menu.indexOf('ROLE_AUXILIAR_AFIP_MENU')
        if (w_role_auxiliar_afip_menu_aux === -1) {
        } else {
            w_role_auxiliar_afip_menu = 'ROLE_AUXILIAR_AFIP_MENU'
        }
    }
    return w_role_auxiliar_afip_menu
}

const role_auxiliar_otro_menu = ({permissions}) => {
    var w_role_auxiliar_otro_menu = ''
    var w_permissions_auxiliar_otro_menu = permissions
    if (w_permissions_auxiliar_otro_menu == null) {
    } else {
        var w_role_auxiliar_otro_menu_aux = w_permissions_auxiliar_otro_menu.indexOf('ROLE_AUXILIAR_OTRO_MENU')
        if (w_role_auxiliar_otro_menu_aux === -1) {
        } else {
            w_role_auxiliar_otro_menu = 'ROLE_AUXILIAR_OTRO_MENU'
        }
    }
    return w_role_auxiliar_otro_menu
}

const role_auxiliar_notificacion_menu = ({permissions}) => {
    var w_role_auxiliar_notificacion_menu = ''
    var w_permissions_auxiliar_notificacion_menu = permissions
    if (w_permissions_auxiliar_notificacion_menu == null) {
    } else {
        var w_role_notificacion_menu_aux = w_permissions_auxiliar_notificacion_menu.indexOf('ROLE_AUXILIAR_NOTIFICACION_MENU')
        if (w_role_notificacion_menu_aux === -1) {
        } else {
            w_role_auxiliar_notificacion_menu = 'ROLE_AUXILIAR_NOTIFICACION_MENU'
        }
    }
    return w_role_auxiliar_notificacion_menu
}

const role_mis_notificacion_menu = ({permissions}) => {
    var w_role_mis_notificacion_menu = ''
    var w_permissions_mis_notificacion_menu = permissions
    if (w_permissions_mis_notificacion_menu == null) {
    } else {
        var w_role_notificacion_menu_aux = w_permissions_mis_notificacion_menu.indexOf('ROLE_MIS_NOTIFICACION_MENU')
        if (w_role_notificacion_menu_aux === -1) {
        } else {
            w_role_mis_notificacion_menu = 'ROLE_MIS_NOTIFICACION_MENU'
        }
    }
    return w_role_mis_notificacion_menu
}

class Menu extends Component {
    state = {
        Menutablas: false,
        Menuaxuliares: false,
        MenuOC: false
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({[menu]: !state[menu]}));
    };

    render() {
        var w_anoactual = new Date().getFullYear()
        let wf_anoactual = w_anoactual.toString();
        const {onMenuClick, open, logout, translate} = this.props;
        return (
            <div>
                {' '}
                <MenuItemLink
                    to={`/TableroControl`}
                    primaryText={'Inicio'}
                    leftIcon={<DashboardIcon/>}
                    onClick={onMenuClick}
                />
                {/*-------Contrataciones    */}
                <WithPermissions
                    render={({permissions}) => (
                        role_contratacion_menu({permissions}) === 'ROLE_CONTRATACION_MENU'
                            ?
                            <SubMenu
                                handleToggle={() => this.handleToggle('MenuContrataciones')}
                                isOpen={this.state.MenuContrataciones}
                                sidebarIsOpen={open}
                                name="Contrataciones"
                                icon={<WebAssetIcon/>}
                                primaryText={(`Contrataciones`, {
                                    smart_count: 2,
                                })}
                            >
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_contratacion_nuevo_menu({permissions}) === 'ROLE_CONTRATACION_NUEVA_MENU'
                                            ?
                                            <MenuItemLink
                                                to={`/ContratacionEncuadre/create`}
                                                primaryText={translate('Nueva Contratación', {
                                                    smart_count: 2,
                                                })}
                                                leftIcon={<AddBoxIcon/>}
                                                onClick={onMenuClick}
                                            />
                                            : null
                                    )}
                                />
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_contratacion_consulta_menu({permissions}) === 'ROLE_CONTRATACION_CONSULTA_MENU'
                                            ?
                                            <MenuItemLink
                                                to={`/ContratacionEncuadre`}
                                                primaryText={'Consulta Contrataciones'}
                                                leftIcon={<WebAssetIcon/>}
                                                onClick={onMenuClick}
                                            />
                                            : null
                                    )}
                                />
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_contratacion_gerente_consulta_menu({permissions}) === 'ROLE_CONTRATACION_GERENTE_CONSULTA_MENU'
                                            ?
                                            <MenuItemLink
                                                to={`/ContratacionEncuadreGerente`}
                                                primaryText={'Consulta Contrataciones'}
                                                leftIcon={<WebAssetIcon style={{color: '#7CC1FF'}}/>}
                                                onClick={onMenuClick}
                                            />
                                            : null
                                    )}
                                />
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_contratacion_visita_menu({permissions}) === 'ROLE_CONTRATACION_VISITA_MENU'
                                            ?
                                            <MenuItemLink
                                                to={`/ContratacionEncuadreVisita`}
                                                primaryText={'Vista Contrataciones'}
                                                leftIcon={<VisibilityIcon/>}
                                                onClick={onMenuClick}
                                            />
                                            : null
                                    )}
                                />
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_contratacion_tablero_menu({permissions}) === 'ROLE_CONTRATACION_TABLERO_MENU'
                                            ?
                                            <MenuItemLink
                                                to={`/TableroControlContratacion?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0%2C"contratacion_ano"%3A"${wf_anoactual}%20%20%20%20%20%20"%7D&order=DESC&page=1&perPage=10&sort=fecha_encuadre`}
                                                primaryText={'Tablero Control'}
                                                leftIcon={<TabletIcon/>}
                                                onClick={onMenuClick}
                                            />
                                            : null
                                    )}
                                />
                            </SubMenu>
                            : null
                    )}
                />



                {/*<WithPermissions*/}
                {/*    render={({permissions}) => (*/}
                {/*        role_orden_compra_menu({permissions}) === 'ROLE_ORDEN_COMPRA_MENU'*/}
                {/*            ?*/}
                {/*            <MenuItemLink*/}
                {/*                to={`/OrdenCompraCabecera`}*/}
                {/*                primaryText={'Ordenes de Compra'}*/}
                {/*                leftIcon={<WebIcon/>}*/}
                {/*                onClick={onMenuClick}*/}
                {/*            />*/}
                {/*            : null*/}
                {/*    )}*/}
                {/*/>*/}

                <WithPermissions
                    render={({permissions}) => (
                        role_orden_compra_menu({permissions}) === 'ROLE_ORDEN_COMPRA_MENU'
                            ?
                            <SubMenu
                                handleToggle={() => this.handleToggle('MenuOC')}
                                isOpen={this.state.MenuOC}
                                sidebarIsOpen={open}
                                name="Órdenes de Compra"
                                icon={<OCIcon/>}
                                primaryText={(`Tablas`, {
                                    smart_count: 2,
                                })}
                            >
                                {/*<WithPermissions*/}
                                {/*    render={({permissions}) => (*/}
                                {/*        role_orden_compra_consulta_menu({permissions}) === 'ROLE_ORDEN_COMPRA_CONSULTA_MENU'*/}
                                {/*            ?*/}
                                {/*            <MenuItemLink*/}
                                {/*                to={`/OrdenCompraCabecera`}*/}
                                {/*                primaryText={'Ordenes de Compra'}*/}
                                {/*                leftIcon={<OCIcon/>}*/}
                                {/*                onClick={onMenuClick}*/}
                                {/*            />*/}
                                {/*            : null*/}
                                {/*    )}*/}
                                {/*/>*/}
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_orden_compra_consulta_menu({permissions}) === 'ROLE_ORDEN_COMPRA_CONSULTA_MENU'
                                            ?
                                            <>
                                                <MenuItemLink
                                                    to={`/OrdenCompraCabecera/`}
                                                    primaryText={translate('Consulta Órdenes de Compra', {
                                                        smart_count: 3,
                                                    })}
                                                    leftIcon={<OCIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                            </>
                                            : null
                                    )}
                                />
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_orden_compra_nueva_menu({permissions}) === 'ROLE_ORDEN_COMPRA_NUEVA_MENU'
                                            ?
                                            <MenuItemLink
                                                to={`/OrdenCompraCabecera/create`}
                                                primaryText={translate('Nueva Orden de Compra', {
                                                    smart_count: 2,
                                                })}
                                                leftIcon={<NuevaOCIcon/>}
                                                onClick={onMenuClick}
                                            />
                                            : null
                                    )}
                                />
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_orden_compra_tablero_menu({permissions}) === 'ROLE_ORDEN_COMPRA_TABLERO_MENU'
                                            ?
                                            <MenuItemLink
                                                to={`/TableroControlOrdenCompra?displayedFilters=%7B%7D&filter=%7B"fecha_desde"%3A0%2C"orden_compra_ano"%3A"${wf_anoactual}%20%20%20%20%20%20"%7D&order=DESC&page=1&perPage=10&sort=orden_compra_fecha`}
                                                primaryText={'Tablero Control'}
                                                leftIcon={<TabletIcon/>}
                                                onClick={onMenuClick}
                                            />
                                            : null
                                    )}
                                />
                            </SubMenu>
                            : null
                    )}
                />

                {/*Proveedor*/}
                <WithPermissions
                    render={({permissions}) => (
                        role_proveedor_menu({permissions}) === 'ROLE_PROVEEDOR_MENU'
                            ?
                            <SubMenu
                                handleToggle={() => this.handleToggle('Menutablas')}
                                isOpen={this.state.Menutablas}
                                sidebarIsOpen={open}
                                name="Proveedores"
                                icon={<PersonIcon/>}
                                primaryText={(`Tablas`, {
                                    smart_count: 2,
                                })}
                            >
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_proveedor_consulta_menu({permissions}) === 'ROLE_PROVEEDOR_CONSULTA_MENU'
                                            ?
                                            <MenuItemLink
                                                to={`/proveedor/`}
                                                primaryText={translate('Listado de Proveedores', {
                                                    smart_count: 2,
                                                })}
                                                leftIcon={<PersonOutlineIcon/>}
                                                onClick={onMenuClick}
                                            />
                                            : null
                                    )}
                                />
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_proveedor_nuevo_menu({permissions}) === 'ROLE_PROVEEDOR_NUEVO_MENU'
                                            ?
                                            <MenuItemLink
                                                to={`/proveedor/create`}
                                                primaryText={translate('Nuevo Proveedor', {
                                                    smart_count: 2,
                                                })}
                                                leftIcon={<PersonAddIcon/>}
                                                onClick={onMenuClick}
                                            />
                                            : null
                                    )}
                                />
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_proveedor_buscar_menu({permissions}) === 'ROLE_PROVEEDOR_BUSCAR_MENU'
                                            ?
                                            <>
                                                <MenuItemLink
                                                    to={`/ProveedorClaseBuscar/`}
                                                    primaryText={translate('Buscador por Clase', {
                                                        smart_count: 3,
                                                    })}
                                                    leftIcon={<SearchIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                            </>
                                            : null
                                    )}
                                />
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_proveedor_transferencia_menu({permissions}) === 'ROLE_PROVEEDOR_TRANSFERENCIA_MENU'
                                            ?
                                            <MenuItemLink
                                                to={`/ProveedorTransferencia/`}
                                                primaryText={translate('Transf. de Proveedores', {
                                                    smart_count: 2,
                                                })}
                                                leftIcon={<TransferWithinAStationIcon/>}
                                                onClick={onMenuClick}
                                            />
                                            : null
                                    )}
                                />
                            </SubMenu>
                            : null
                    )}
                />
                {/*----"Tablas Auxiliares"*/}

                <WithPermissions
                    render={({permissions}) => (
                        role_auxiliar_menu({permissions}) === 'ROLE_AUXILIAR_MENU'
                            ?
                            <SubMenu
                                handleToggle={() => this.handleToggle('MenuTablasAuxiliares')}
                                isOpen={this.state.MenuTablasAuxiliares}
                                sidebarIsOpen={open}
                                name="Tablas Auxiliares"
                                icon={<AssistantIcon/>}
                                primaryText={(`Tablas Auxiliares`, {
                                    smart_count: 2,
                                })}
                            >
                                {/*-------Encuadre    */}
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_auxiliar_contratacion_menu({permissions}) === 'ROLE_AUXILIAR_CONTRATACION_MENU'
                                            ?
                                            <SubMenu
                                                handleToggle={() => this.handleToggle('MenuEncuadre')}
                                                isOpen={this.state.MenuEncuadre}
                                                sidebarIsOpen={open}
                                                name="Contratación"
                                                icon={<FilterFramesIcon/>}
                                                primaryText={(`Contratación`, {
                                                    smart_count: 2,
                                                })}
                                            >
                                                <MenuItemLink
                                                    to={`/ContratacionTipo`}
                                                    primaryText={'Contrataciones Tipo'}
                                                    leftIcon={<ChromeReaderModeIcon/>}
                                                    onClick={onMenuClick}
                                                />

                                                <MenuItemLink
                                                    to={`/ContratacionSecuenciaTipo`}
                                                    primaryText={'Secuencia Contratación Tipo'}
                                                    leftIcon={<DvrIcon/>}
                                                    onClick={onMenuClick}
                                                />

                                                <MenuItemLink
                                                    to={`/ContratacionEstado`}
                                                    primaryText={'Estados Contratación'}
                                                    leftIcon={<GradientIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                            </SubMenu>
                                            : null
                                    )}
                                />
                                {/*-------Gestión Compras    */}
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_auxiliar_compra_menu({permissions}) === 'ROLE_AUXILIAR_COMPRA_MENU'
                                            ?
                                            <SubMenu
                                                handleToggle={() => this.handleToggle('MenuGestionCompras')}
                                                isOpen={this.state.MenuGestionCompras}
                                                sidebarIsOpen={open}
                                                name="Compras"
                                                icon={<ShopTwoIcon/>}
                                                primaryText={(`Compras`, {
                                                    smart_count: 2,
                                                })}
                                            >
                                                <MenuItemLink
                                                    to={`/Autoridades`}
                                                    primaryText={'Autorid. Gestión C'}
                                                    leftIcon={<PeopleOutlineIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/CompraCpt`}
                                                    primaryText={'Comprob. Gestión C'}
                                                    leftIcon={<BallotIcon/>}
                                                    onClick={onMenuClick}
                                                />

                                                <MenuItemLink
                                                    to={`/DocumentacionTipo`}
                                                    primaryText={'Docum. Requerida'}
                                                    leftIcon={<ClassIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/AdjudicacionCriterio`}
                                                    primaryText={'Crit. de Adjudicación'}
                                                    leftIcon={<DeveloperBoardIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/RequerimientoConcepto`}
                                                    primaryText={'Concep. Requerim.'}
                                                    leftIcon={<DnsIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/UniMed`}
                                                    primaryText={'Uni. Med. Compras'}
                                                    leftIcon={<AcUnitIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/EstadoProveedor`}
                                                    primaryText={'Estados del Prove.'}
                                                    leftIcon={<BookmarkIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/EstadoOrdenCompra`}
                                                    primaryText={'Estados Ordenes C'}
                                                    leftIcon={<BookmarkBorderIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/Rubro`}
                                                    primaryText={'Rubros C'}
                                                    leftIcon={<FeaturedPlayListIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/RubroClase`}
                                                    primaryText={'Clases de Compras'}
                                                    leftIcon={<TocIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                            </SubMenu>
                                            : null
                                    )}
                                />
                                {/*-------Estructura Unidades*/}
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_auxiliar_estructura_menu({permissions}) === 'ROLE_AUXILIAR_ESTRUCTURA_MENU'
                                            ?
                                            <SubMenu
                                                handleToggle={() => this.handleToggle('MenuEstructuraUnidades')}
                                                isOpen={this.state.MenuEstructuraUnidades}
                                                sidebarIsOpen={open}
                                                name="Estructura Unidades"
                                                icon={<BusinessIcon/>}
                                                primaryText={(`Estructura Unidades`, {
                                                    smart_count: 2,
                                                })}
                                            >
                                                <MenuItemLink
                                                    to={`/UnidadNegocio`}
                                                    primaryText={'Unidad Negocio'}
                                                    leftIcon={<ApartmentIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/UnidadEmisora`}
                                                    primaryText={'Emisoras'}
                                                    leftIcon={<EqualizerIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/UnidadArea`}
                                                    primaryText={'Areas'}
                                                    leftIcon={<FontDownloadIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/UnidadGerencia`}
                                                    primaryText={'Gerencias'}
                                                    leftIcon={<SupervisorAccountIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/UnidadSector`}
                                                    primaryText={'Sectores'}
                                                    leftIcon={<LineStyleIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/UnidadPrograma`}
                                                    primaryText={'Programas'}
                                                    leftIcon={<InsertEmoticonIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                            </SubMenu>
                                            : null
                                    )}
                                />
                                {/*-------Tablas AFIP*/}
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_auxiliar_afip_menu({permissions}) === 'ROLE_AUXILIAR_AFIP_MENU'
                                            ?
                                            <SubMenu
                                                handleToggle={() => this.handleToggle('MenuTablasAFIP')}
                                                isOpen={this.state.MenuTablasAFIP}
                                                sidebarIsOpen={open}
                                                name="Tablas AFIP"
                                                icon={<AccountBalanceIcon/>}
                                                primaryText={(`Tablas AFIP`, {
                                                    smart_count: 2,
                                                })}
                                            >
                                                <MenuItemLink
                                                    to={`/IbruTipo`}
                                                    primaryText={'Ingresos Brutos'}
                                                    leftIcon={<AttachMoneyIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/Iva`}
                                                    primaryText={'Responsables IVA'}
                                                    leftIcon={<MonetizationOnIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/Moneda`}
                                                    primaryText={'Monedas'}
                                                    leftIcon={<MoneyIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/PersonaForma`}
                                                    primaryText={'Forma de Personas'}
                                                    leftIcon={<SupervisedUserCircleIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/PersonaTipo`}
                                                    primaryText={'Tipo de Personas'}
                                                    leftIcon={<PeopleIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                            </SubMenu>
                                            : null
                                    )}
                                />
                                {/*-------Otras Tablas*/}
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_auxiliar_otro_menu({permissions}) === 'ROLE_AUXILIAR_OTRO_MENU'
                                            ?
                                            <SubMenu
                                                handleToggle={() => this.handleToggle('MenuOtrasTablas')}
                                                isOpen={this.state.MenuOtrasTablas}
                                                sidebarIsOpen={open}
                                                name="Otras Tablas"
                                                icon={<TableChartIcon/>}
                                                primaryText={(`Otras Tablas`, {
                                                    smart_count: 2,
                                                })}
                                            >
                                                <MenuItemLink
                                                    to={`/Pais`}
                                                    primaryText={'Paises'}
                                                    leftIcon={<PublicIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/Provincia`}
                                                    primaryText={'Provincias'}
                                                    leftIcon={<MapIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/Ciudad`}
                                                    primaryText={'Ciudades'}
                                                    leftIcon={<LocationCityIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/DocumentoTipo`}
                                                    primaryText={'Tipos de Docum.'}
                                                    leftIcon={<DescriptionIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/EstadoCivil`}
                                                    primaryText={'Estado Civil'}
                                                    leftIcon={<EmojiPeopleIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/SexoTipo`}
                                                    primaryText={'Tipos de Sexo'}
                                                    leftIcon={<WcIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                            </SubMenu>
                                            : null
                                    )}
                                />
                                {/*-------Notificacion    */}
                                <WithPermissions
                                    render={({permissions}) => (
                                        role_auxiliar_notificacion_menu({permissions}) === 'ROLE_AUXILIAR_NOTIFICACION_MENU'
                                            ?
                                            <SubMenu
                                                handleToggle={() => this.handleToggle('MenuNotificacion')}
                                                isOpen={this.state.MenuNotificacion}
                                                sidebarIsOpen={open}
                                                name="Notificacion"
                                                icon={<NotificationsIcon/>}
                                                primaryText={(`Notificacion`, {
                                                    smart_count: 2,
                                                })}
                                            >
                                                <MenuItemLink
                                                    to={`/NotificacionTipo`}
                                                    primaryText={'Notificacion Tipo'}
                                                    leftIcon={<AddAlertIcon/>}
                                                    onClick={onMenuClick}
                                                />
                                                <MenuItemLink
                                                    to={`/UsuComNotificacionTipo`}
                                                    primaryText={'Usuarios Notificaciones'}
                                                    leftIcon={<NotificationsActiveIcon/>}
                                                    onClick={onMenuClick}
                                                />

                                            </SubMenu>
                                            : null
                                    )}
                                />
                            </SubMenu>
                            : null
                    )}
                />

                <WithPermissions
                    render={({permissions}) => (
                        role_mis_notificacion_menu({permissions}) === 'ROLE_MIS_NOTIFICACION_MENU'
                            ?
                            <MenuItemLink
                                to={`/Notificacion`}
                                primaryText={'Mis Notificaciones Hist.'}
                                leftIcon={<NotificationsIcon/>}
                                onClick={onMenuClick}
                            />
                            : null
                    )}
                />

                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }

}


const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    // locale: state.i18n.es,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(Menu);
