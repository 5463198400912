import React, {useCallback, useState} from 'react';
import { useFormState } from 'react-final-form';
import {
    SaveButton,
    Toolbar,
    useUpdate,
    useRedirect,
    useNotify,
    withDataProvider,
    Loading,
    Error,
    useRefresh
} from 'react-admin';
import axios from 'axios';
import Compressor from 'compressorjs';
import ApiUrlServer from '../layout/ApiUrlServer'

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

// const Compress = require('compress.js')

// Initialization
// const compress = new Compress()

const EstadoOrdenCompraSaveButtonCreate = props => {
    const [update] = useUpdate('EstadoOrdenCompra');
    const redirectTo = useRedirect();
    const refresh = useRefresh();
    const notify = useNotify();
    const { basePath, redirect } = props;

    // redirect to the related Author show page
    // const redirect_orgpag = (basePath, id, data) => `/OrdPagEstado`;

    // const redirectsavebutton = (basePath, id, data) => `/OrdPagMailOperar`;


    const formState = useFormState();

    // const redirectsavebutton = (basePath, id, data) => `/MLNota/${formState.values.id}/show`;
    const redirectsavebutton = (basePath, id, data) => `/EstadoOrdenCompra`;

    const handleClick = useCallback(() => {

        // console.log('formState.values')
        // console.log(formState.values)
        //---------------------- en primer lugar sube los archivos con axios --------------------
        //-------------------------------- Controlador y Token ------------------------------
        // let webApiUrl = 'http://localhost:8080/api'
        let webApiUrl = w_apiurlserver+'/api'

        let tokenStr = localStorage.getItem('token');
        // datafile.append('files[]', formState.values.ArchivosAdjuntos)

        let w_length = formState.values.ArchivosAdjuntos
        let w_mandar_mail
        let w_cant_length = 0
        let w_cant_for = 0

        if (w_length === undefined){
            w_mandar_mail = 'S'

            if (w_mandar_mail === 'S') {

            }
            let w_generar_articulosml
            const Valida = new FormData()



            var wr_estado = formState.values.estado

            var wr_estado_d = formState.values.estado_d

            var wr_secuencia = formState.values.secuencia


            Valida.append('estado', wr_estado);
            Valida.append('estado_d', wr_estado_d);
            Valida.append('secuencia', wr_secuencia);
            Valida.append('cant_length', w_cant_length);

            axios.create({
                baseURL: webApiUrl,
                timeout: 10000,
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": `${tokenStr}`

                },
            }).post('/EstadoOrdenCompraVerificar', Valida).then((response) => {
                let w_error = response.data.data.error
                let w_mensaje = response.data.data.mensaje

                if (w_error === 'N') {
                    w_generar_articulosml = 'P'
                }else{
                    notify(`Atención: ${w_mensaje}`, 'warning')
                    // alert(w_mensaje)
                }
                if (w_generar_articulosml === 'P') {
                    notify('Generando Estado Orden Compra', 'info', {
                        smart_count: 1,
                    });
                    update(
                        {
                            payload: {data: {...formState.values}},
                        },
                        {
                            onSuccess: ({data: newRecord}) => {
                                notify('Estado Orden Compra Generado', 'info', {
                                    smart_count: 1,
                                });
                                // refresh()
                                redirectTo(redirectsavebutton, basePath, newRecord.id, newRecord);
                            },
                        }
                    );
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    //HTTP error happened
                    console.log("Upload error. HTTP error/status code=", error.response.status);
                } else {
                    //some other error happened
                    console.log("Upload error. HTTP error/message code=", error.message);
                }
            });
        }else {
            w_mandar_mail = 'N'

            console.log('w_mandar_mail')
            console.log(w_mandar_mail)

            w_cant_length = formState.values.ArchivosAdjuntos.length
            let w_generar_articulosml
            const Valida = new FormData()


            var wr_estado = formState.values.estado

            var wr_estado_d = formState.values.estado_d

            var wr_secuencia = formState.values.secuencia


            Valida.append('estado', wr_estado);
            Valida.append('estado_d', wr_estado_d);
            Valida.append('secuencia', wr_secuencia);
            Valida.append('cant_length', w_cant_length);

            console.log('Valida----for')
            console.log(Valida)
            console.log({Valida})
            console.log('Valida----for---FIN')



            // alert('gola')


            axios.create({
                baseURL: webApiUrl,
                timeout: 10000,
                headers: {
                    'Content-Type': 'application/json',
                    "x-auth-token": `${tokenStr}`

                },
            }).post('/EstadoOrdenCompraVerificar', Valida).then((response) => {
                console.log(response)
                let w_error = response.data.data.error
                let w_mensaje = response.data.data.mensaje
                console.log(w_error)
                console.log(w_mensaje)

                if (w_error === 'N') {
                    w_generar_articulosml = 'P'
                }else{
                    notify(`Atención: ${w_mensaje}`, 'warning')
                    // alert(w_mensaje)
                }
                console.log('w_generar_periodo---Final_aux')
                console.log(w_generar_articulosml)
                if (w_generar_articulosml === 'P') {

                    for (let i = 0, numFiles = formState.values.ArchivosAdjuntos.length; i < numFiles; i++) {

                        const datafile = new FormData()
                        const file = formState.values.ArchivosAdjuntos[i];
                        console.log('file')
                        console.log(file)
                        console.log('file.rawFile')
                        console.log(file.rawFile)
                        console.log('file.rawFile.name')
                        console.log(file.rawFile.name)

                        let type_file = file.rawFile.type
                        if (type_file == null) {
                            type_file = ''
                        }
                        type_file = type_file.trim()

                        console.log('type_file')
                        console.log(type_file)

                        let type_image = type_file.substring(0, 6);

                        console.log('type_image')
                        console.log(type_image)

                        // alert('TI')

                        // const result = file

                        // alert('new Compressor')

                        // compress.attach('file', {
                        //     size: 4,
                        //     quality: .75
                        // }).then((result) => {

                        // compress.compress(file, {
                        //
                        //     size: 4, // the max size in MB, defaults to 2MB
                        //     quality: .75, // the quality of the image, max is 1,
                        //     maxWidth: 1920, // the max width of the output image, defaults to 1920px
                        //     maxHeight: 1920, // the max height of the output image, defaults to 1920px
                        //     resize: true, // defaults to true, set false if you do not want to resize the image width and height
                        // }).then((result) => {


                        if (type_image === 'image/') {

                            new Compressor(file.rawFile, {
                                quality: 0.6,
                                success(result) {

                                    console.log('result')
                                    console.log(result)

                                    // alert('hola-----entre---new Compressor')

                                    // datafile.append('file', result);
                                    // datafile.append('name', result.name);
                                    // datafile.append('description', 'Este archivo es subido por mi');


                                    datafile.append('file', result, result.name);

                                    console.log('datafile----for')
                                    console.log(datafile)
                                    console.log({datafile})
                                    console.log('datafile----for---FIN')

                                    // axios.post(webApiUrl, {datafile}, { headers: {
                                    //     'Content-Type': 'application/json',
                                    //     "x-auth-token" : `${tokenStr}`,
                                    //     // 'content-type': 'multipart/form-data',
                                    //     }} )
                                    //     .then(res => { // then print response status
                                    //         console.log(res.statusText)
                                    //     })
                                    //     .catch((error) => {
                                    //         console.log(error);
                                    //     });


                                    axios.create({
                                        baseURL: webApiUrl,
                                        timeout: 10000,
                                        headers: {
                                            'Content-Type': 'application/json',
                                            "x-auth-token": `${tokenStr}`

                                        },
                                    }).post('/AdjuntarArchivoSubirMLNota', datafile).then((response) => {
                                        w_cant_for = w_cant_for + 1
                                        console.log('w_cant_for--post')
                                        console.log(w_cant_for)
                                        if (w_cant_length === w_cant_for) {
                                            w_mandar_mail = 'P'
                                        }
                                        console.log('w_mandar_mail---Final_aux')
                                        console.log(w_mandar_mail)
                                        console.log("File " + file.rawFile.name + " is uploaded");
                                        if (w_mandar_mail === 'P') {
                                            notify('Generando Estado Orden Compra', 'info', {
                                                smart_count: 1,
                                            });
                                            update(
                                                {
                                                    payload: {data: {...formState.values}},
                                                },
                                                {
                                                    onSuccess: ({data: newRecord}) => {
                                                        notify('Estado Orden Compra Generado', 'info', {
                                                            smart_count: 1,
                                                        });
                                                        // refresh()
                                                        redirectTo(redirectsavebutton, basePath, newRecord.id, newRecord);
                                                    },
                                                }
                                            );
                                        }
                                    }).catch(function (error) {
                                        console.log(error);
                                        if (error.response) {
                                            //HTTP error happened
                                            console.log("Upload error. HTTP error/status code=", error.response.status);
                                        } else {
                                            //some other error happened
                                            console.log("Upload error. HTTP error/message code=", error.message);
                                        }
                                    });
                                    // })

                                },
                                error(err) {
                                    console.log(err.message);
                                    // alert('erro')
                                },
                            });
                        } else {
                            datafile.append('file', file.rawFile);
                            datafile.append('name', file.rawFile.name);
                            datafile.append('description', 'Este archivo es subido por mi');

                            console.log('datafile----for')
                            console.log(datafile)
                            console.log({datafile})
                            console.log('datafile----for---FIN')

                            // axios.post(webApiUrl, {datafile}, { headers: {
                            //     'Content-Type': 'application/json',
                            //     "x-auth-token" : `${tokenStr}`,
                            //     // 'content-type': 'multipart/form-data',
                            //     }} )
                            //     .then(res => { // then print response status
                            //         console.log(res.statusText)
                            //     })
                            //     .catch((error) => {
                            //         console.log(error);
                            //     });


                            axios.create({
                                baseURL: webApiUrl,
                                timeout: 10000,
                                headers: {
                                    'Content-Type': 'application/json',
                                    "x-auth-token": `${tokenStr}`

                                },
                            }).post('/AdjuntarArchivoSubirMLNota', datafile).then((response) => {
                                w_cant_for = w_cant_for + 1
                                console.log('w_cant_for--post')
                                console.log(w_cant_for)
                                if (w_cant_length === w_cant_for) {
                                    w_mandar_mail = 'P'
                                }
                                console.log('w_mandar_mail---Final_aux')
                                console.log(w_mandar_mail)
                                console.log("File " + file.rawFile.name + " is uploaded");
                                if (w_mandar_mail === 'P') {
                                    notify('Generando Estado Orden Compra', 'info', {
                                        smart_count: 1,
                                    });
                                    update(
                                        {
                                            payload: {data: {...formState.values}},
                                        },
                                        {
                                            onSuccess: ({data: newRecord}) => {
                                                notify('Estado Orden Compra Generado', 'info', {
                                                    smart_count: 1,
                                                });
                                                // refresh()
                                                redirectTo(redirectsavebutton, basePath, newRecord.id, newRecord);
                                            },
                                        }
                                    );
                                }
                            }).catch(function (error) {
                                console.log(error);
                                if (error.response) {
                                    //HTTP error happened
                                    console.log("Upload error. HTTP error/status code=", error.response.status);
                                } else {
                                    //some other error happened
                                    console.log("Upload error. HTTP error/message code=", error.message);
                                }
                            });
                        }

                    }
                }
            }).catch(function (error) {
                console.log(error);
                if (error.response) {
                    //HTTP error happened
                    console.log("Upload error. HTTP error/status code=", error.response.status);
                } else {
                    //some other error happened
                    console.log("Upload error. HTTP error/message code=", error.message);
                }
            });
        }


        if (!formState.valid) {
            return;
        }
    }, [
        formState.valid,
        formState.values,
        update,
        notify,
        redirectTo,
        refresh,
        redirect,
        basePath,
    ]);

    return <SaveButton {...props} handleSubmitWithRedirect={handleClick} redirect={redirectsavebutton}/>;
};

export default EstadoOrdenCompraSaveButtonCreate