import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    useNotify,
    useRedirect, useMutation, useRefresh, useUnselectAll
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import PolicyIcon from '@material-ui/icons/Policy';
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import HistoryIcon from '@material-ui/icons/History';
import PersonIcon from "@material-ui/icons/Person";
import DescriptionIcon from '@material-ui/icons/Description';

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const ObservacionesButton = ({record}) => {
    var wr_id = record.id

    var wr_contratacion_tipo_encuadre = record.contratacion_tipo_encuadre

    if (wr_contratacion_tipo_encuadre == null) {
        wr_contratacion_tipo_encuadre = ''
    }
    wr_contratacion_tipo_encuadre = wr_contratacion_tipo_encuadre.trim()

    var wr_expediente_referencia_gde = record.expediente_referencia_gde

    if (wr_expediente_referencia_gde == null) {
        wr_expediente_referencia_gde = ''
    }
    wr_expediente_referencia_gde = wr_expediente_referencia_gde.trim()
    var wr_estado_encuadre = record.estado_encuadre
    var wr_estado_encuadre_d = record.estado_encuadre_d

    var wr_hay_observaciones = record.hay_observaciones

    if(wr_hay_observaciones==null){
        wr_hay_observaciones=0
    }

    var wr_estado_encuadre_fh_ultima_actualizacion = record.estado_encuadre_fh_ultima_actualizacion

    var wr_concepto_descripcion = record.concepto_descripcion

    var wr_importe_contratacion_pesos = record.importe_contratacion_pesos

    var wr_contratacion_d = record.contratacion_d

    var wr_en_anulado_motivo =  record.en_anulado_motivo


    // console.log('wr_hay_observaciones')
    // console.log(wr_hay_observaciones)

    var wrt_hay_observaciones = ''
    var wrc_hay_observaciones = '#8d8d8d'

    if(wr_hay_observaciones===0){
        wrt_hay_observaciones = 'Sin Observacion'
        wrc_hay_observaciones = '#8d8d8d'
    }else{
        if(wr_hay_observaciones > 1){
            wrt_hay_observaciones = 'Observaciones'
            wrc_hay_observaciones = '#02a600'
        }else{
            if(wr_hay_observaciones === 1){
                wrt_hay_observaciones = 'Observacion'
                wrc_hay_observaciones = '#64c8ff'
            }
        }
    }

    const classes = useStyles()
    const [openObservaciones, setOpenObservaciones] = React.useState(false);
    const [ObjetoObservaciones, setObjetoObservaciones] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();
    const [Estadoid, setEstadoid] = useState(wr_id)
    const [Estadoestado_encuadre, setEstadoestado_encuadre] = useState(wr_estado_encuadre)
    const [Estadoestado_encuadre_d, setEstadoestado_encuadre_d] = useState(wr_estado_encuadre_d)
    const [Estadoexpediente_referencia_gde, setEstadoexpediente_referencia_gde] = useState(wr_expediente_referencia_gde)
    const [Estadocontratacion_tipo_encuadre, setEstadocontratacion_tipo_encuadre] = useState(wr_contratacion_tipo_encuadre)
    const [Estadocontratacion_d, setEstadocontratacion_d] = useState(wr_contratacion_d)
    const [Estadoestado_encuadre_fh_ultima_actualizacion, setEstadoestado_encuadre_fh_ultima_actualizacion] = useState(wr_estado_encuadre_fh_ultima_actualizacion)
    const [Estadoconcepto_descripcion, setEstadoconcepto_descripcion] = useState(wr_concepto_descripcion)
    const [Estadoimporte_contratacion_pesos, setEstadoimporte_contratacion_pesos] = useState(wr_importe_contratacion_pesos)
    const [Estadoen_anulado_motivo, setEstadoen_anulado_motivo] = useState(wr_en_anulado_motivo)
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const dataProvider = useDataProvider();
    const refresh = useRefresh();



    const handleClickOpenObservaciones = () => {
        dataProvider.getList(
            'TableroControlContratacionEncuadreEstado',
            {
                filter: {ingreso_por: 'O',
                    id_contratacion_encuadre: Estadoid,
                    expediente_referencia_gde: Estadoexpediente_referencia_gde,
                    contratacion_tipo: Estadocontratacion_tipo_encuadre},
                sort: {field: 'secuencia_item', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },
        )
            .then(({data}) => {
                setObjetoObservaciones(data);
                setLoading(false);
                setOpenObservaciones(true);
            })
            .catch(error => {
                // setError(error);
                setLoading(false);

            })

        if (loading) return <Loading/>;                // no cargo todavia

        if (error) {
            console.log('error')
            console.log(error)
            return <Error/>;                           // error
        }
        ;

    };

    const handleCloseObservaciones = () => {
        refresh()
        setOpenObservaciones(false);
    };


    if(wr_hay_observaciones===0){
        return (
            <div>
                <Button variant="outlined" color="primary">
                    <div title={'Observaciones'}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                         }}>
                        <DescriptionIcon style={{color: wrc_hay_observaciones}}/>
                    </div>
                </Button>
            </div>
        )
    }else {
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpenObservaciones}>
                    <div title={'Observaciones'}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                         }}>
                        <DescriptionIcon style={{color: wrc_hay_observaciones}}/>
                    </div>
                </Button>
                <Dialog open={openObservaciones}
                        fullWidth={fullWidth}
                        maxWidth={maxWidth}
                    // maxWidth={'lg'}
                        aria-labelledby="draggable-dialog-title">
                    <DialogTitle id="form-dialog-title">{'Observaciones'}</DialogTitle>
                    <DialogContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {'Tipo de Contratacion:'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {Estadocontratacion_d}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {'Expediente:'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {Estadoexpediente_referencia_gde}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {'Estado Actual:'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {Estadoestado_encuadre}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {'Última Actualización:'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {Estadoestado_encuadre_fh_ultima_actualizacion}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {'Concepto:'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {Estadoconcepto_descripcion}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {'Contratación $:'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.textright}>
                                        <Typography variant="headline" component="h3">
                                            {Estadoimporte_contratacion_pesos === undefined ? '0,00' :
                                                Estadoimporte_contratacion_pesos.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 16,
                                                })}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {'Observaciones:'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="headline" component="h3">
                                            {Estadoen_anulado_motivo}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>

                        {/*<DialogContentText>*/}
                        {/*    <Typography variant="headline" component="h3">*/}
                        {/*        {'Observaciones:'}*/}
                        {/*    </Typography>*/}
                        {/*</DialogContentText>*/}
                        {/*<Table size="small" aria-label="a dense table">*/}
                        {/*    <TableHead>*/}
                        {/*        <TableRow>*/}
                        {/*            <TableCell size="small">*/}
                        {/*                <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>*/}
                        {/*                    {'Observacion'}*/}
                        {/*                </Typography>*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell size="small">*/}
                        {/*                <Typography variant="body2" style={{textAlign: 'left', color: '#a1a1a1'}}>*/}
                        {/*                    {'Razon Social'}*/}
                        {/*                </Typography>*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell size="small">*/}
                        {/*                <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>*/}
                        {/*                    {'Codigo Pro TvPublica'}*/}
                        {/*                </Typography>*/}
                        {/*            </TableCell>*/}
                        {/*            <TableCell size="small">*/}
                        {/*                <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>*/}
                        {/*                    {'Codigo Pro RNA'}*/}
                        {/*                </Typography>*/}
                        {/*            </TableCell>*/}
                        {/*        </TableRow>*/}
                        {/*    </TableHead>*/}
                        {/*    {ObjetoObservaciones.map(recordco => {*/}
                        {/*        return (*/}
                        {/*            <TableRow>*/}
                        {/*                <TableCell size="small" className={classes.textcenter}>*/}
                        {/*                    <Typography size="small" title={''} style={{textAlign: 'center'}}>*/}
                        {/*                        {recordco.secuencia_observacion}*/}
                        {/*                    </Typography>*/}
                        {/*                </TableCell>*/}
                        {/*                <TableCell size="small" className={classes.textleft}>*/}
                        {/*                    <Typography size="small" title={''} style={{textAlign: 'left'}}>*/}
                        {/*                        {recordpa.razon_social}*/}
                        {/*                    </Typography>*/}
                        {/*                </TableCell>*/}
                        {/*                <TableCell size="small" className={classes.textcenter}>*/}
                        {/*                    <Typography title={''} style={{textAlign: 'center'}}>*/}
                        {/*                        {recordpa.codigo_pro_tvpublica}*/}
                        {/*                    </Typography>*/}
                        {/*                </TableCell>*/}
                        {/*                <TableCell size="small" className={classes.textcenter}>*/}
                        {/*                    <Typography title={''} style={{textAlign: 'center'}}>*/}
                        {/*                        {recordpa.codigo_pro_rna}*/}
                        {/*                    </Typography>*/}
                        {/*                </TableCell>*/}
                        {/*            </TableRow>*/}
                        {/*        );*/}
                        {/*    })}*/}
                        {/*</Table>*/}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="primary" onClick={handleCloseObservaciones}>
                            <div title={''}
                                 style={{
                                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                                 }}>
                                <ExitToAppIcon style={{color: '#004187'}}/>
                                Salir
                            </div>
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

ObservacionesButton.defaultProps = {
    source: '',
    addLabel: true,
};

export default ObservacionesButton;