import React, {useState} from 'react';
import {Drawer, makeStyles} from "@material-ui/core";
import Icon from '@material-ui/icons/Add';
import ProveedorClaseArbol from "./ProveedorClasesArbol";
import Button from "@mui/material/Button";
import './BtnAbrirArbolClases.css';
import {SelectInput} from "ra-ui-materialui/esm";

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '60%'
    }
}));

export const BtnAbrirArbolClases = ({pro}) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" size={"small"} style={{color: '#ffffff', backgroundColor: '#123B80'}}
                    onClick={() => setOpen(!open)}>
                <Icon/>
                Agregar
            </Button>
            <Drawer
                classes={{paper: classes.paper}}
                variant="persistent"
                anchor="right"
                open={open}
            >
                <div>
                    <h3>Seleccionar Clases</h3>
                </div>
                <ProveedorClaseArbol
                    pro={pro}
                    callbackCloseDrawer={handleDrawerOpen}
                    // callback={props.callbackArt}
                />

            </Drawer>
        </div>
    )
};
export default BtnAbrirArbolClases;