import React, {useEffect, Fragment, useState} from 'react';
import {
    useDataProvider,
    Loading,
    Error,
    Datagrid,
    Link,
    TextInput,
    useUpdate,
    useNotify,
    useRedirect, useMutation, useRefresh, useUnselectAll
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import PolicyIcon from '@material-ui/icons/Policy';
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import HistoryIcon from '@material-ui/icons/History';

// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const VisitarButton = ({record}) => {
    var wr_id = record.id

    var wr_contratacion_tipo_encuadre = record.contratacion_tipo_encuadre

    if (wr_contratacion_tipo_encuadre == null) {
        wr_contratacion_tipo_encuadre = ''
    }
    wr_contratacion_tipo_encuadre = wr_contratacion_tipo_encuadre.trim()

    var wr_expediente_referencia_gde = record.expediente_referencia_gde

    if (wr_expediente_referencia_gde == null) {
        wr_expediente_referencia_gde = ''
    }
    wr_expediente_referencia_gde = wr_expediente_referencia_gde.trim()
    var wr_estado_encuadre = record.estado_encuadre
    var wr_estado_encuadre_d = record.estado_encuadre_d

    var wr_hay_contratacion_encuadre_visita = record.hay_contratacion_encuadre_visita

    if(wr_hay_contratacion_encuadre_visita==null){
        wr_hay_contratacion_encuadre_visita=0
    }

    var w_visita_hoy = record.visita_hoy
    if(w_visita_hoy==null){
        w_visita_hoy=''
    }
    w_visita_hoy=w_visita_hoy.trim()

    // console.log('wr_hay_contratacion_encuadre_visita')
    // console.log(wr_hay_contratacion_encuadre_visita)

    var wrt_hay_contratacion_encuadre_visita = ''
    var wrc_hay_contratacion_encuadre_visita = '#8d8d8d'

    if(wr_hay_contratacion_encuadre_visita===0){
        wrt_hay_contratacion_encuadre_visita = 'Sin Visitar'
        wrc_hay_contratacion_encuadre_visita = '#8d8d8d'
    }else{
        if(wr_hay_contratacion_encuadre_visita > 1){
            wrt_hay_contratacion_encuadre_visita = 'Visitas'
            wrc_hay_contratacion_encuadre_visita = '#02a600'
        }else{
            if(wr_hay_contratacion_encuadre_visita = 1){
                wrt_hay_contratacion_encuadre_visita = 'Visitar'
                wrc_hay_contratacion_encuadre_visita = '#64c8ff'
            }
        }
    }

    const classes = useStyles()
    const [openvisita, setOpenvisita] = React.useState(false);
    const [openHistorialvisita, setOpenHistorialvisita] = React.useState(false);
    const [ObjetoHistorial, setObjetoHistorial] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();
    const [Estadoid, setEstadoid] = useState(wr_id)
    const [Estadovisita_hoy, setEstadovisita_hoy] = useState(w_visita_hoy)
    const [Estadoestado_encuadre, setEstadoestado_encuadre] = useState(wr_estado_encuadre)
    const [Estadoestado_encuadre_d, setEstadoestado_encuadre_d] = useState(wr_estado_encuadre_d)
    const [Estadoexpediente_referencia_gde, setEstadoexpediente_referencia_gde] = useState(wr_expediente_referencia_gde)
    const [Estadocontratacion_tipo_encuadre, setEstadocontratacion_tipo_encuadre] = useState(wr_contratacion_tipo_encuadre)
    const [Estadovisita_leyenda, setEstadovisita_leyenda] = useState('')
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('lg');

    const dataProvider = useDataProvider();
    const redirectTo = useRedirect();
    const unselectAll = useUnselectAll();
    const refresh = useRefresh();

    const handleClickOpenvisita = () => {
        setOpenvisita(true);
    };

    const handleClosevisita = () => {
        setOpenvisita(false);
        // unselectAll();
        // redirectTo(`/ContratacionEncuadreVisita?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=25&sort=id`);
        refresh()
    };

    const handleClickOpenHistorialvisita = () => {
        dataProvider.getList(
            'ContratacionEncuadreVisita',
            {
                filter: {uso_historial: 'S',
                    id_contratacion_encuadre: Estadoid,
                    expediente_referencia_gde: Estadoexpediente_referencia_gde,
                    contratacion_tipo: Estadocontratacion_tipo_encuadre},
                sort: {field: 'visita_usuario_fh', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },
        )
            .then(({data}) => {
                setObjetoHistorial(data);
                setLoading(false);
                setOpenHistorialvisita(true);
            })
            .catch(error => {
                // setError(error);
                setLoading(false);

            })

        if (loading) return <Loading/>;                // no cargo todavia

        if (error) {
            console.log('error')
            console.log(error)
            return <Error/>;                           // error
        }
        ;

    };

    const handleCloseHistorialvisita = () => {
        setOpenHistorialvisita(false);
    };

    const BotonCrearDetellevisita = (record) => {
        var w_mensaje = ' '
        const notify = useNotify();

        const [generardetellevisita, {loading}] = useMutation({
                type: 'create',
                resource: 'ContratacionEncuadreVisita',
                payload: {
                    data: {
                        id_contratacion_encuadre: Estadoid,
                        expediente_referencia_gde: Estadoexpediente_referencia_gde,
                        contratacion_tipo: Estadocontratacion_tipo_encuadre,
                        visita_leyenda: Estadovisita_leyenda
                    }
                }
            },
            {
                onSuccess: ({data}) => {
                    setOpenvisita(false);
                    // unselectAll();
                    // redirectTo(`/ContratacionEncuadreVisita?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=25&sort=id`);
                    // redirectTo(`/ContratacionEncuadreVisita?displayedFilters=%7B%7D&filter=%7B%7D&order=ASC&page=1&perPage=25&sort=id`);
                    refresh()
                },
                onFailure: error => {
                    notify(`Error durante la Actualizacion: ${error.message}`, "warning");
                },
            }
        );

        return (
            <Button variant="outlined" color="primary" onClick={generardetellevisita}
                    style={{color: '#ffffff', backgroundColor: '#004187'}}
            >
                <div title={''}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                     }}>
                    <SaveIcon style={{color: '#ffffff', backgroundColor: '#004187'}}/>
                    Guardar
                </div>
            </Button>
        );
    };

    const handlevisita_leyenda = (event) => {
        const Cambiavisita_leyenda1 = (wp_value) => {
            console.log('wp_value')
            console.log(wp_value)
            setEstadovisita_leyenda(wp_value)
        };
        var w_value = event.target.value
        console.log('w_value')
        console.log(w_value)
        // if(w_value==null){
        //     w_value=''
        // }
        // w_value=w_value.trim()
        Cambiavisita_leyenda1(w_value)
    };

    return (
        <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpenvisita}>
                <div title={'Agregar Visita'}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <VisibilityIcon style={{color: Estadovisita_hoy==='S' ? '#004187' : '#808080'}}/>
                </div>
            </Button>
            <Dialog open={openvisita}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    // maxWidth={'lg'}
                    aria-labelledby="draggable-dialog-title">
                <DialogTitle id="form-dialog-title">{'Agregar Visita'}
                    {/*<Button variant="outlined" color="primary" onClick={handleClickOpenHistorialvisita}>*/}
                    {/*    <div title={'Historial de Visitas'}*/}
                    {/*         style={{*/}
                    {/*             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>*/}
                    {/*        <HistoryIcon style={{color: '#004187'}}/>*/}
                    {/*    </div>*/}
                    {/*</Button>*/}
                    {/*<Dialog open={openHistorialvisita}*/}
                    {/*        fullWidth={fullWidth}*/}
                    {/*        maxWidth={maxWidth}*/}
                    {/*    // maxWidth={'lg'}*/}
                    {/*        aria-labelledby="draggable-dialog-title">*/}
                    {/*    <DialogTitle id="form-dialog-title">{'Historial de Visitas'}</DialogTitle>*/}
                    {/*    <DialogContent>*/}
                    {/*        <DialogContentText>*/}
                    {/*            {'Estado Actual: '+Estadoestado_encuadre}*/}
                    {/*        </DialogContentText>*/}
                    {/*        <DialogContentText>*/}
                    {/*            {Estadoestado_encuadre_d}*/}
                    {/*        </DialogContentText>*/}
                    {/*        <DialogContentText>*/}
                    {/*            {'Expediente: '+Estadoexpediente_referencia_gde}*/}
                    {/*        </DialogContentText>*/}
                    {/*            <Table size="small" aria-label="a dense table">*/}
                    {/*                <TableHead>*/}
                    {/*                    <TableRow>*/}
                    {/*                        <TableCell size="small">*/}
                    {/*                            <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>*/}
                    {/*                                {'Usuario'}*/}
                    {/*                            </Typography>*/}
                    {/*                        </TableCell>*/}
                    {/*                        <TableCell size="small">*/}
                    {/*                            <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>*/}
                    {/*                                {'Fecha Hora'}*/}
                    {/*                            </Typography>*/}
                    {/*                        </TableCell>*/}
                    {/*                        <TableCell size="small">*/}
                    {/*                            <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>*/}
                    {/*                                {'Visita Leyenda'}*/}
                    {/*                            </Typography>*/}
                    {/*                        </TableCell>*/}
                    {/*                    </TableRow>*/}
                    {/*                </TableHead>*/}
                    {/*                {ObjetoHistorial.map(recorde => {*/}
                    {/*                    return (*/}
                    {/*                        <TableRow>*/}
                    {/*                            <TableCell size="small" className={classes.textcenter}>*/}
                    {/*                                <Typography size="small" title={''} style={{textAlign: 'center'}}>*/}
                    {/*                                    {recorde.vvisita_usuario}*/}
                    {/*                                </Typography>*/}
                    {/*                            </TableCell>*/}
                    {/*                            <TableCell size="small" className={classes.textcenter}>*/}
                    {/*                                <Typography size="small" title={''} style={{textAlign: 'center'}}>*/}
                    {/*                                    {recorde.vvisita_usuario_fh}*/}
                    {/*                                </Typography>*/}
                    {/*                            </TableCell>*/}
                    {/*                            <TableCell size="small" className={classes.textcenter}>*/}
                    {/*                                <Typography title={''} style={{textAlign: 'center'}}>*/}
                    {/*                                    {recorde.vvisita_leyenda}*/}
                    {/*                                </Typography>*/}
                    {/*                            </TableCell>*/}
                    {/*                        </TableRow>*/}
                    {/*                    );*/}
                    {/*                })}*/}
                    {/*            </Table>*/}
                    {/*    </DialogContent>*/}
                    {/*    <DialogActions>*/}
                    {/*        <Button variant="outlined" color="primary" onClick={handleCloseHistorialvisita}>*/}
                    {/*            <div title={''}*/}
                    {/*                 style={{*/}
                    {/*                     display: 'flex', flexWrap: 'nowrap', alignItems: 'center'*/}
                    {/*                 }}>*/}
                    {/*                <ExitToAppIcon style={{color: '#004187'}}/>*/}
                    {/*                Salir*/}
                    {/*            </div>*/}
                    {/*        </Button>*/}
                    {/*    </DialogActions>*/}
                    {/*</Dialog>*/}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {'Estado Actual: '+Estadoestado_encuadre}
                    </DialogContentText>
                    <DialogContentText>
                       {Estadoestado_encuadre_d}
                    </DialogContentText>
                    <DialogContentText>
                        {'Expediente: '+Estadoexpediente_referencia_gde}
                    </DialogContentText>
                    <label>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <TextField label={"Visita Leyenda"}
                                                   variant="outlined"
                                                   multiline
                                                   value={Estadovisita_leyenda}
                                                   onChange={handlevisita_leyenda}
                                                   InputLabelProps={{shrink: true}}
                                                   fullWidth={true}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    </label>
                </DialogContent>
                <DialogActions>
                    <BotonCrearDetellevisita/>
                    <Button variant="outlined" color="primary" onClick={handleClosevisita}>
                        <div title={''}
                             style={{
                                 display: 'flex', flexWrap: 'nowrap', alignItems: 'center'
                             }}>
                            <CancelPresentationIcon style={{color: '#004187'}}/>
                            Cancelar
                        </div>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

VisitarButton.defaultProps = {
    source: '',
    addLabel: true,
};

export default VisitarButton;