import React, {useCallback, useEffect, useState} from "react";
import {NumberField, ListContextProvider} from 'react-admin';
import {makeStyles} from "@material-ui/core";
import {Grid} from '@mui/material';
import TextField from '@material-ui/core/TextField';
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    // Uso interno
    moneda_cotizacion: {display: 'inline-block', width: 120, marginLeft: 32},
    moneda_cotizacion_fecha: {display: 'inline-block', width: 160, marginLeft: 32},

    importe0: {display: 'inline-block', width: 250},
    importe: {display: 'inline-block', width: 250, marginLeft: 32},

    precio_incluye_iva: {display: 'inline-block', width: 100},
    importe_subtotal: {display: 'inline-block', width: 250},
    importe_iva: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_total: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_subtotal_pesos: {display: 'inline-block', width: 250},
    importe_iva_pesos: {display: 'inline-block', width: 250, marginLeft: 32},
    importe_total_pesos: {display: 'inline-block', width: 250, marginLeft: 32},
}));

export const OCDetalleTotales = ({data, hayCambios}) => {
    const classes = useStyles();

    function NumberFormatCustom(props) {
        const {inputRef, onChange, ...other} = props;

        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator={'.'}
                decimalSeparator={','}
                isNumericString
                prefix=""
            />
        );
    }

    NumberFormatCustom.propTypes = {
        inputRef: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        onBlur: PropTypes.func.isRequired,

    };

    const [importeSubtotal, setImporteSubtotal] = useState(null);
    const [importeIva, setImporteIva] = useState(null);
    const [importeTotal, setImporteTotal] = useState(null);
    const [importeSubtotalPesos, setImporteSubtotalPesos] = useState(null);
    const [importeIvaPesos, setImporteIvaPesos] = useState(null);
    const [importeTotalPesos, setImporteTotalPesos] = useState(null);

    useEffect(() => {
        setImporteSubtotal(data[0].importe_subtotal);
        setImporteIva(data[0].importe_iva);
        setImporteTotal(data[0].importe_total);
        setImporteSubtotalPesos(data[0].importe_subtotal_pesos);
        setImporteIvaPesos(data[0].importe_iva_pesos);
        setImporteTotalPesos(data[0].importe_total_pesos);
    }, [hayCambios, data]);

    return (
        <ListContextProvider value={{data}}>

            <TextField
                label="Importe Subtotal"
                value={importeSubtotal}
                variant="filled"
                className={classes.importe0}
                InputProps={{
                    inputComponent: NumberFormatCustom
                }}
                InputLabelProps={{shrink: true}}
                name={"SubtotalPesos"}
            />

            <TextField
                label="Importe del IVA"
                value={importeIva}
                variant="filled"
                className={classes.importe}
                InputProps={{
                    inputComponent: NumberFormatCustom
                }}
                InputLabelProps={{shrink: true}}
                name={"SubtotalPesos"}
            />


            <TextField
                label="Total del Ítem"
                value={importeTotal}
                variant="filled"
                className={classes.importe}
                InputProps={{
                    inputComponent: NumberFormatCustom
                }}
                InputLabelProps={{shrink: true}}
                name={"SubtotalPesos"}
            />
            <br/> <br/>
            <TextField
                label="Subtotal Pesos"
                value={importeSubtotalPesos}
                variant="filled"
                className={classes.importe0}
                InputProps={{
                    inputComponent: NumberFormatCustom
                }}
                InputLabelProps={{shrink: true}}
                name={"SubtotalPesos"}
            />


            <TextField
                label="IVA Pesos"
                value={importeIvaPesos}
                variant="filled"
                className={classes.importe}
                InputProps={{
                    inputComponent: NumberFormatCustom
                }}
                InputLabelProps={{shrink: true}}
                name={"importeIvaPesos"}
            />


            <TextField
                label="Total ítem Pesos"
                value={importeTotalPesos}
                id="importe_presupuesto_pesos_ing"
                variant="filled"
                className={classes.importe}
                InputProps={{
                    inputComponent: NumberFormatCustom
                }}
                InputLabelProps={{shrink: true}}
                name={"total_iva_pesos"}
            />

            <br/> <br/>
        </ListContextProvider>
    )
};


export default OCDetalleTotales;