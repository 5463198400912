import React, {useEffect, Fragment} from 'react';
import {useDataProvider, Loading, Error, Datagrid, Link, TextInput, useUpdate} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import ApiUrlServer from '../layout/ApiUrlServer'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import PolicyIcon from '@material-ui/icons/Policy';


// console.log('ApiUrlServer-AppBar')
// console.log(ApiUrlServer)

var w_apiurlserver = ApiUrlServer
if(w_apiurlserver==null){
    w_apiurlserver=''
}
w_apiurlserver=w_apiurlserver.trim()

const useStyles = makeStyles({
    icon: {paddingRight: '0.5em'},
    link: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    textright: {
        textAlign: 'right',
    },
    textleft: {
        textAlign: 'left',
    },
    textcenter: {
        textAlign: 'center',
    },
});

const AuditoriaButton = ({record}) => {
    var wr_id = record.id

    var wr_tabla = record.tabla

    if(wr_tabla==null){
        wr_tabla=''
    }
    wr_tabla=wr_tabla.trim()

    var wr_tabla_madre_clave1 = record.tabla_madre_clave1

    if(wr_tabla_madre_clave1==null){
        wr_tabla_madre_clave1=0
    }
    // wr_tabla_madre_clave1=wr_tabla_madre_clave1.trim()

    var wr_tabla_madre_clave2= record.tabla_madre_clave2

    if(wr_tabla_madre_clave2==null){
        wr_tabla_madre_clave2=0
    }
    // wr_tabla_madre_clave2_tiene=wr_tabla_madre_clave2_tiene.trim()

    const classes = useStyles()
    const [open, setOpen] = React.useState(false);
    const [ObjetoAuditoria, setObjetoAuditoria] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState();

    const dataProvider = useDataProvider();

    const handleClickOpen = () => {
        dataProvider.getList(
            'ObjetoAuditoria',
            {
                filter: {id: wr_id,
                        tabla: wr_tabla,
                        tabla_madre_clave1: wr_tabla_madre_clave1,
                        tabla_madre_clave2: wr_tabla_madre_clave2 },
                sort: {field: 'id', order: 'DESC'},
                pagination: {page: 1, perPage: 100},
            },
        )
        .then(({data}) => {
            setObjetoAuditoria(data);
            setLoading(false);
            setOpen(true);
        })
        .catch(error => {
            // setError(error);
            setLoading(false);

        })

        if (loading) return <Loading/>;                // no cargo todavia

        if (error) {
            console.log('error')
            console.log(error)
            return <Error/>;                           // error
        }
        ;
    };

    const handleClose = () => {
        setOpen(false);
    };

    var wr_hay_objeto_auditoria = record.hay_objeto_auditoria

    if(wr_hay_objeto_auditoria==null){
        wr_hay_objeto_auditoria=0
    }

    if (wr_hay_objeto_auditoria > 0){
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    <div title={wr_hay_objeto_auditoria > 1 ? 'Auditorias Interna': 'Auditoria Interna'}
                         style={{
                             display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                        <PolicyIcon style={{color: wr_hay_objeto_auditoria > 1 ? '#008000': '#ff8040'}}/>
                    </div>
                </Button>
                <Dialog open={open} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{wr_hay_objeto_auditoria > 1 ? 'Auditorias Interna': 'Auditoria Interna'}</DialogTitle>
                    <DialogContent>
                        <List>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell size="small">
                                            <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                {'Usuario'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell size="small">
                                            <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                {'Fecha Hora'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell size="small">
                                            <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                {'Novedad Tipo'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell size="small">
                                            <Typography variant="body2" style={{textAlign: 'center', color: '#a1a1a1'}}>
                                                {'Novedad Estado'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {ObjetoAuditoria.map(record => {
                                    return (
                                        <TableRow>
                                            <TableCell size="small" className={classes.textcenter}>
                                                <Typography size="small" title={''} style={{textAlign: 'center'}}>
                                                    {record.novedad_usuario}
                                                </Typography>
                                            </TableCell>
                                            <TableCell size="small" className={classes.textcenter}>
                                                <Typography size="small" title={''} style={{textAlign: 'center'}}>
                                                    {record.novedad_fh}
                                                </Typography>
                                            </TableCell>
                                            <TableCell size="small" className={classes.textcenter}>
                                                <Typography title={''} style={{textAlign: 'center'}}>
                                                    {record.novedad_tipo}
                                                </Typography>
                                            </TableCell>
                                            <TableCell size="small" className={classes.textcenter}>
                                                <Typography title={''} style={{textAlign: 'center'}}>
                                                    {record.novedad_estado}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </Table>
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Salir
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }else{
        return (
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <div title={'No tiene Auditoria Interna'}
                     style={{
                         display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}>
                    <PolicyIcon style={{color: '#a1a1a1'}}/>
                </div>
            </Button>
        );
    }
}

AuditoriaButton.defaultProps = {
    source: '',
    addLabel: true,
};

export default AuditoriaButton;