import React, {useState, useCallback} from "react";

import withStyles from '@material-ui/core/styles/withStyles';
import {
    Create,
    FormTab,
    Edit,
    TextInput,
    TabbedForm,
    AutocompleteInput,
    SelectInput,
    DateInput,
    TopToolbar,
    ReferenceInput,
    Toolbar,
    SaveButton,
    DeleteButton,
    DateField,
    useGetOne,
    Loading,
    NumberInput,
    TextField,
    ShowButton,
    Datagrid,
    NumberField,
    ReferenceManyField,
    WithPermissions,
    usePermissions, MenuItemLink
} from 'react-admin';
import TextFieldMui from '@material-ui/core/TextField';
import {makeStyles} from "@material-ui/core";
import ComboUnidadNegocio from "../componentes/ComboUnidadNegocio";
import ComboContratacionTipo from "../componentes/ComboContratacionTipo";
import ComboArea from "../componentes/ComboArea";
import ComboActivoMoneda from "../componentes/ComboMonedaActivo";
import OrdenCompraDetalleInfo from "../OrdenCompraCabecera/OrdenCompraDetalleInfo";
import ComboContratacionEstado from "../componentes/ComboContratacionEstado";
import RichTextInput from "ra-input-rich-text";
import Typography from "@material-ui/core/Typography";
import ContratacionEncuadreSecuenciaButtonEdit from "./ContratacionEncuadreSecuenciaButtonEdit";
import ContratacionEncuadreSecuenciaInfo from "./ContratacionEncuadreSecuenciaInfo"
import ContratacionSecuenciaButtonCreate from "../ContratacionTipo/ContratacionSecuenciaButtonCreate";
import EstadoContratacionButtonEdit from "./EstadoContratacionButtonEdit"
import EncuadreContratacionTipoButtonEdit from "./EncuadreContratacionTipoButtonEdit"

import ContratacionEncuadreAdjudicacionButtonCreate from "./ContratacionEncuadreAdjudicacionButtonCreate";
import ContratacionEncuadreAdjudicacionButtonEdit from "./ContratacionEncuadreAdjudicacionButtonEdit";
import ContratacionEncuadreAdjudicacionButtonShow from "./ContratacionEncuadreAdjudicacionButtonShow";
import ContratacionEncuadreAdjudicacionButtonDelete from "./ContratacionEncuadreAdjudicacionButtonDelete";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import UnidadAreaBusquedaBtn from "../UnidadArea/UnidadAreaBusquedaBtn";
import ContratacionEncuadreButtonEdit from "./ContratacionEncuadreButtonEdit"

import ContratacionEncuadreInputEdit from "./ContratacionEncuadreInputEdit";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const useStyles = makeStyles((theme) => ({
    combo: {display: 'inline-block', width: 250},

    id: {display: 'inline-block', width: 120},
    expediente_referencia_gde2: {display: 'inline-block', width: 400, marginLeft: 32},
    estado_encuadre2: {display: 'inline-block', width: 300, marginLeft: 32},
    combo_contratacion: {display: 'inline-block', width: 250, marginLeft: 32},
    encuadre_contratacion_tipo_button_edit: {display: 'inline-block', width: 200, marginLeft: 190},

    fecha_encuadre: {display: 'inline-block', width: 200},
    expediente_referencia_gde: {display: 'inline-block', width: 800},
    concepto_descripcion: {display: 'inline-block', width: 600},

    ContratacionEncuadreButtonEdit2: {display: 'inline-block', width: 1200},
    contratacion_ano: {display: 'inline-block', width: 200},
    moneda_cotizacion: {display: 'inline-block', width: 200},
    moneda_cotizacion_fecha: {display: 'inline-block', width: 200},
    importe_presupuesto: {display: 'inline-block', width: 200},
    importe_presupuesto_pesos: {display: 'inline-block', width: 200},
    importe_adjudicado_pesos: {display: 'inline-block', width: 200},
    estado_encuadre: {display: 'inline-block', width: 200},


    estado: {display: 'inline-block', width: 40},
    estado_motivo: {display: 'inline-block', width: 800, marginLeft: 160},

}));




const role_contratacion_seguimiento_completo = ({permissions}) => {
    var w_role_contratacion_seguimiento_completo = ''
    var w_permissions_admuno_contratacion_seguimiento_completo = permissions
    if (w_permissions_admuno_contratacion_seguimiento_completo == null) {
    } else {
        var wa_role_contratacion_seguimiento_completo = w_permissions_admuno_contratacion_seguimiento_completo.indexOf('ROLE_CONTRATACION_SEGUIMIENTO_COMPLETO')
        if (wa_role_contratacion_seguimiento_completo === -1) {
        } else {
            w_role_contratacion_seguimiento_completo = 'ROLE_CONTRATACION_SEGUIMIENTO_COMPLETO'
        }
    }
    return w_role_contratacion_seguimiento_completo
}

const role_contratacion_seguimiento_limitado = ({permissions}) => {
    var w_role_contratacion_seguimiento_limitado = ''
    var w_permissions_admuno_contratacion_seguimiento_limitado = permissions
    if (w_permissions_admuno_contratacion_seguimiento_limitado == null) {
    } else {
        var wa_role_contratacion_seguimiento_limitado = w_permissions_admuno_contratacion_seguimiento_limitado.indexOf('ROLE_CONTRATACION_SEGUIMIENTO_LIMITADO')
        if (wa_role_contratacion_seguimiento_limitado === -1) {
        } else {
            w_role_contratacion_seguimiento_limitado = 'ROLE_CONTRATACION_SEGUIMIENTO_LIMITADO'
        }
    }
    return w_role_contratacion_seguimiento_limitado
}

function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
            isNumericString
            prefix=""
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const ContratacionEncuadreEdit = props => {
    const classes = useStyles();
    const {permissions} = usePermissions();
    var w_role_contratacion_estado = ''
    var w_permissions_admuno_contratacion_estado = permissions
    if (w_permissions_admuno_contratacion_estado == null) {
    } else {
        var wa_role_contratacion_estado = w_permissions_admuno_contratacion_estado.indexOf('ROLE_CONTRATACION_ESTADO')
        if (wa_role_contratacion_estado === -1) {
        } else {
            w_role_contratacion_estado = 'ROLE_CONTRATACION_ESTADO'
        }
    }

    const redirect = (basePath, id, data) => `/ContratacionEncuadre/${id}/1`;
    const redirect2 = (basePath, id, data) => `/ContratacionEncuadre/`;

    const CustomToolbar = props => (

        <Toolbar {...props}>
            <SaveButton
                label="Actualizar"
                transform={transform}
                submitOnEnter={false}
                disabled={props.pristine}
            />
            {/*<DeleteButton undoable={false} redirect={redirect2}/>*/}
        </Toolbar>
    );
    const transform = data => {
        return {
            ...data
        }
    };

    const PostTitle = ({record}) => {
        return <span>Encuadre Contrataciones: {record ? `${record.expediente_referencia_gde}` : ''}</span>;
    };

    return (
        <Edit title={<PostTitle/>} {...props}>
            <TabbedForm
                redirect={redirect}
                toolbar={false}
            >
                {/*Datos*/}
                <FormTab label="DATOS CONTRATACION">

                    {/*<ContratacionEncuadreButtonEdit formClassName={classes.ContratacionEncuadreButtonEdit2}/>*/}
                    {/*<ContratacionEncuadreInputEdit formClassName={classes.ContratacionEncuadreButtonEdit2}/>*/}
                    <ContratacionEncuadreInputEdit/>


                    {/*<NumberInput label="ID Contratacion" source="id"*/}
                    {/*             inputProps={{readOnly: true}}*/}
                    {/*             formClassName={classes.id}*/}
                    {/*             options={{ minimumFractionDigits: 0 }} />*/}

                    {/*<TextInput source="expediente_referencia_gde" label={"Expediente"}*/}
                    {/*           variant="filled"*/}
                    {/*           InputLabelProps={{shrink: true}}*/}
                    {/*           formClassName={classes.expediente_referencia_gde2}*/}
                    {/*           fullWidth={true}*/}
                    {/*           inputProps={{readOnly: true}}*/}
                    {/*/>*/}
                    {/*<TextInput*/}
                    {/*    label="Estado Actual"*/}
                    {/*    source="estado_encuadre"*/}
                    {/*    formClassName={classes.estado_encuadre2}*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="filled"*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}
                    {/*<ComboContratacionTipo label={"Encuadre"} readOnly={true} variant="filled" formClassName={classes.combo_contratacion}/>*/}
                    {/*<br/>*/}
                    {/*<DateInput  source="fecha_encuadre" label={"Fecha"}*/}
                    {/*            variant="filled"*/}
                    {/*            inputProps={{readOnly: true}}*/}
                    {/*    // InputLabelProps={{shrink: false}}*/}
                    {/*            formClassName={classes.fecha_encuadre}*/}
                    {/*/>*/}
                    {/*<NumberInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Año"*/}
                    {/*    source="contratacion_ano"*/}
                    {/*    formClassName={classes.contratacion_ano}*/}
                    {/*    placeholder="Ingrese Año"*/}
                    {/*    // helperText="Año"*/}
                    {/*    // margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="filled"*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}
                    {/*<br/>*/}

                    {/*/!*<br/>*!/*/}

                    {/*/!*<br/>*!/*/}
                    {/*<ComboUnidadNegocio readOnly={true} variant="filled" formClassName={classes.combo}/>*/}
                    {/*<br/>*/}
                    {/*<ComboArea readOnly={true} variant="filled" formClassName={classes.combo}/>*/}
                    {/*<br/>*/}
                    {/*<ComboActivoMoneda readOnly={true} variant="filled" formClassName={classes.combo}/>*/}

                    {/*<NumberInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Cotización"*/}
                    {/*    source="moneda_cotizacion"*/}
                    {/*    formClassName={classes.moneda_cotizacion}*/}
                    {/*    placeholder="Ingrese Cotización"*/}
                    {/*    // helperText="Cotización"*/}
                    {/*    // margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="filled"*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}

                    {/*<DateInput source="moneda_cotizacion_fecha" label={"Fecha Ctz."}*/}
                    {/*    // variant="filled"*/}
                    {/*           inputProps={{readOnly: true}}*/}
                    {/*    // InputLabelProps={{shrink: false}}*/}
                    {/*            formClassName={classes.moneda_cotizacion_fecha}*/}
                    {/*/>*/}
                    {/*<br/>*/}

                    {/*<NumberInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Importe Presupuesto"*/}
                    {/*    source="importe_presupuesto"*/}
                    {/*    formClassName={classes.importe_presupuesto}*/}
                    {/*    placeholder="Ingrese Importe Presupuesto"*/}
                    {/*    helperText="Importe Presupuesto"*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="outlined"*/}
                    {/*    // inputProps={{readOnly: true}}*/}
                    {/*/>*/}


                    {/*<NumberInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Importe Presupuesto $"*/}
                    {/*    source="importe_presupuesto_pesos"*/}
                    {/*    formClassName={classes.importe_presupuesto_pesos}*/}
                    {/*    // placeholder="Ingrese Importe Presupuesto $"*/}
                    {/*    helperText="Importe Presupuesto $"*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="filled"*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}


                    {/*<NumberInput*/}
                    {/*    id="standard-full-width"*/}
                    {/*    label="Importe Adjudicado Pesos"*/}
                    {/*    source="importe_adjudicado_pesos"*/}
                    {/*    formClassName={classes.importe_adjudicado_pesos}*/}
                    {/*    // placeholder="Ingrese Importe Adjudicado Pesos"*/}
                    {/*    helperText="Importe Adjudicado Pesos"*/}
                    {/*    margin="normal"*/}
                    {/*    InputLabelProps={{*/}
                    {/*        shrink: true,*/}
                    {/*    }}*/}
                    {/*    variant="filled"*/}
                    {/*    inputProps={{readOnly: true}}*/}
                    {/*/>*/}
                    {/*<br/>*/}
                    {/*<TextInput source="concepto_descripcion" label={"Concepto"}*/}
                    {/*           multiline*/}
                    {/*           variant="outlined"*/}
                    {/*           InputLabelProps={{shrink: true}} inputProps={{readOnly: false}}*/}
                    {/*           formClassName={classes.concepto_descripcion} fullWidth={true}/>*/}
                </FormTab>

                {/* Secuencia de Cumplimiento*/}
                <FormTab label="SECUENCIA DE CUMPLIMIENTO">

                    <TextInput source="id" label={"ID Contratacion"}
                               fullWidth={true}
                               formClassName={classes.id}
                               variant="filled" inputProps={{readOnly: true,}}
                               InputLabelProps={{shrink: true}}
                    />
                    <TextInput source="expediente_referencia_gde" label={"Expediente"}
                               fullWidth={true}
                               formClassName={classes.expediente_referencia_gde2}
                               variant="filled" inputProps={{readOnly: true,}}
                               InputLabelProps={{shrink: true}}
                    />

                    <TextInput source="estado_encuadre" label={"Estado Actual"}
                               fullWidth={true}
                               formClassName={classes.estado_encuadre2}
                               variant="filled" inputProps={{readOnly: true,}}
                               InputLabelProps={{shrink: true}}
                    />

                    <ComboContratacionTipo label={"Encuadre"} readOnly={true} variant="filled"
                                           formClassName={classes.combo_contratacion}/>


                    <EncuadreContratacionTipoButtonEdit formClassName={classes.encuadre_contratacion_tipo_button_edit}/>
                    
                    <br/>

                    <WithPermissions
                        render={({permissions}) => (
                            role_contratacion_seguimiento_completo({permissions}) === 'ROLE_CONTRATACION_SEGUIMIENTO_COMPLETO'
                                ?
                                <ReferenceManyField
                                    sort={{field: 'secuencia_item', order: 'ASC'}}
                                    perPage={500}
                                    reference="ContratacionEncuadreSecuencia"
                                    target="id"
                                    addLabel={false}
                                >
                                    <Datagrid
                                        rowStyle={RowStyle}
                                        expand={<ContratacionEncuadreSecuenciaInfo/>}
                                    >
                                        {/*FALTA EDITARs*/}
                                        <ContratacionEncuadreSecuenciaButtonEdit label="Editar"/>
                                        <NumberField label="Item" source="secuencia_item"
                                                     style={{textAlign: 'right', margin: '1em'}}
                                                     options={{minimumFractionDigits: 0}} sortable={false}/>
                                        {/*<TextField   label="Tipo" source="secuencia_tipo" />*/}
                                        <NumberField label="Dias" source="secuencia_dias"
                                                     style={{textAlign: 'right', margin: '1em'}}
                                                     options={{minimumFractionDigits: 0}} sortable={false}/>
                                        <TextField label="Descripción" source="secuencia_d" sortable={false}/>
                                        <DateField label="Fecha Tope" source="secuencia_fecha_tope" sortable={false}/>
                                        <DateField label="Fecha Cumplido" source="secuencia_fecha_cumplido"
                                                   sortable={false}/>
                                        <TextField label="Cumplido" source="secuencia_cumplido" sortable={false}/>
                                        <TextField label="Cumplido Estado" source="secuencia_cumplido_estado"
                                                   sortable={false}/>
                                        <DateField label="Fecha GDE" source="secuencia_fecha_gde" sortable={false}/>
                                        <TextField label="Usuario GDE" source="secuencia_usuario_gde" sortable={false}/>
                                        {/*<TextField label="Observacion" source="secuencia_observacion"  sortable={false}/>*/}


                                    </Datagrid>
                                </ReferenceManyField>
                                : null
                        )}
                    />
                    <WithPermissions
                        render={({permissions}) => (
                            role_contratacion_seguimiento_limitado({permissions}) === 'ROLE_CONTRATACION_SEGUIMIENTO_LIMITADO'
                                ?
                                <ReferenceManyField
                                    sort={{field: 'secuencia_item', order: 'ASC'}}
                                    perPage={500}
                                    reference="ContratacionEncuadreSecuencia"
                                    target="id"
                                    addLabel={false}
                                >
                                    <Datagrid
                                        rowStyle={RowStyle}
                                        // expand={<ContratacionEncuadreSecuenciaInfo/>}
                                    >
                                        {/*FALTA EDITARs*/}
                                        {/*<ContratacionEncuadreSecuenciaButtonEdit label="Editar" />*/}
                                        {/*<NumberField label="Item" source="secuencia_item"*/}
                                        {/*             style={{textAlign: 'right', margin: '1em' }}*/}
                                        {/*             options={{ minimumFractionDigits: 0 }} sortable={false}  />*/}
                                        {/*<TextField   label="Tipo" source="secuencia_tipo" />*/}
                                        {/*<NumberField label="Dias" source="secuencia_dias"*/}
                                        {/*             style={{textAlign: 'right', margin: '1em' }}*/}
                                        {/*             options={{ minimumFractionDigits: 0 }}  sortable={false} />*/}
                                        <TextField label="Descripción" source="secuencia_d" sortable={false}/>
                                        {/*<DateField label="Fecha Tope" source="secuencia_fecha_tope"  sortable={false}/>*/}
                                        <DateField label="Fecha Cumplido" source="secuencia_fecha_cumplido"
                                                   sortable={false}/>
                                        <TextField label="Cumplido" source="secuencia_cumplido" sortable={false}/>
                                        <TextField label="Cumplido Estado" source="secuencia_cumplido_estado"
                                                   sortable={false}/>
                                        {/*<DateField label="Fecha GDE" source="secuencia_fecha_gde" sortable={false} />*/}
                                        {/*<TextField label="Usuario GDE" source="secuencia_usuario_gde" sortable={false} />*/}
                                        {/*<TextField label="Observacion" source="secuencia_observacion"  sortable={false}/>*/}

                                    </Datagrid>
                                </ReferenceManyField>
                                : null
                        )}
                    />
                </FormTab>

                {/* Ordenes de Compra*/}
                <FormTab label="ORDENES DE COMPRA">

                    <TextInput source="id" label={"ID Contratacion"}
                               fullWidth={true}
                               formClassName={classes.id}
                               variant="filled" inputProps={{readOnly: true,}}
                               InputLabelProps={{shrink: true}}
                    />
                    <TextInput source="expediente_referencia_gde" label={"Expediente"}
                               fullWidth={true}
                               formClassName={classes.expediente_referencia_gde2}
                               variant="filled" inputProps={{readOnly: true,}}
                               InputLabelProps={{shrink: true}}
                    />

                    <TextInput source="estado_encuadre" label={"Estado Actual"}
                               fullWidth={true}
                               formClassName={classes.estado_encuadre2}
                               variant="filled" inputProps={{readOnly: true,}}
                               InputLabelProps={{shrink: true}}
                    />

                    <ComboContratacionTipo label={"Encuadre"} readOnly={true} variant="filled"
                                           formClassName={classes.combo_contratacion}/>
                    <br/>
                    <NumberInput
                        id="standard-full-width"
                        label="Importe Adjudicado Pesos"
                        source="importe_adjudicado_pesos"
                        formClassName={classes.importe_adjudicado_pesos}
                        // placeholder="Ingrese Importe Adjudicado Pesos"
                        helperText="Importe Adjudicado Pesos"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        inputProps={{readOnly: true}}
                    />
                    <br/>

                    <ContratacionEncuadreAdjudicacionButtonCreate/>

                    <ReferenceManyField
                        sort={{field: 'id', order: 'ASC'}}
                        perPage={500}
                        reference="ContratacionEncuadreAdjudicacion"
                        target="id"
                        addLabel={false}
                    >
                        <Datagrid
                            // expand={<OrdenCompraDetalleInfo/>}
                        >
                            <TextField label="Renglon" source="renglon_nro"/>
                            <TextField label="Renglon Descripción" source="renglon_descripcion"
                                       cellClassName={classes.title}/>
                            <NumberField label="Importe Adjudicado Pesos" source="importe_adjudicado_pesos"
                                         style={{textAlign: 'right', margin: '1em'}}
                                         options={{minimumFractionDigits: 2}}/>

                            <TextField label="Orden Compra Nro" source="orden_compra_nro"
                                       style={{textAlign: 'right', margin: '1em'}}/>

                            <TextField label="Orden Compra Ampliacion Nro" source="orden_compra_ampliacion_nro"
                                       style={{textAlign: 'right', margin: '1em'}}/>

                            <TextField label="Proveedor RTA" source="proveedor_rta"/>
                            <TextField label="Razon Social" source="razon_social"/>
                            <TextField label="Codigo Pro TvPublica" source="codigo_pro_tvpublica"/>
                            <TextField label="Codigo Pro RNA" source="codigo_pro_rna"/>

                            <ContratacionEncuadreAdjudicacionButtonShow label="Mostrar"/>
                            <ContratacionEncuadreAdjudicacionButtonEdit label="Editar"/>
                            <ContratacionEncuadreAdjudicacionButtonDelete label="Eliminar"/>
                        </Datagrid>
                    </ReferenceManyField>
                </FormTab>

                {w_role_contratacion_estado === 'ROLE_CONTRATACION_ESTADO' &&
                <FormTab label="ESTADOS CONTRATACION">
                    <TextInput source="id" label={"ID Contratacion"}
                               fullWidth={true}
                               formClassName={classes.id}
                               variant="filled" inputProps={{readOnly: true,}}
                               InputLabelProps={{shrink: true}}
                    />
                    <TextInput source="expediente_referencia_gde" label={"Expediente"}
                               fullWidth={true}
                               formClassName={classes.expediente_referencia_gde2}
                               variant="filled" inputProps={{readOnly: true,}}
                               InputLabelProps={{shrink: true}}
                    />

                    <TextInput source="estado_encuadre" label={"Estado Actual"}
                               fullWidth={true}
                               formClassName={classes.estado_encuadre2}
                               variant="filled" inputProps={{readOnly: true,}}
                               InputLabelProps={{shrink: true}}
                    />

                    <ComboContratacionTipo label={"Encuadre"} readOnly={true} variant="filled"
                                           formClassName={classes.combo_contratacion}/>

                    <br/>

                    <ComboContratacionEstado label={"Estado Actual"} readOnly={true} variant="filled"
                                             className={classes.combo} fullWidth/>

                    <br/>

                    <TextInput source="concepto_descripcion" label={"Concepto"}
                               multiline
                               variant="filled"
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: true}}
                               className={classes.concepto_descripcion} fullWidth={true}/>
                    <br/>

                    <Typography variant="h6">Estados</Typography>

                    {/*En Progreso	[en_progreso] [char](1) NULL,       -S/N*/}
                    {/*[en_progreso_motivo] [text] NULL,   -es un campo memo   idem que las leyendas de la orden de compras*/}

                    {/*En Impugnación	[en_impugnacion] [char](10) NULL, -S/N*/}
                    {/*[en_impugnacion_motivo] [text] NULL,*/}

                    {/*En Suspenso	[en_suspenso] [char](1) NULL, -S/N*/}
                    {/*[en_suspenso_motivo] [text] NULL,*/}

                    {/*En Revisión	[en_revision] [char](1) NULL,  -S/N*/}
                    {/*[en_revision_motivo] [text] NULL,*/}

                    {/*Anulado		[en_anulado] [char](1) NULL,  -S/N*/}
                    {/*[en_anulado_motivo] [text] NULL,*/}

                    {/*Finalizado	[en_finalizado] [char](1) NULL,  -S/N*/}
                    {/*[en_finalizado_motivo] [text] NULL,*/}

                    <EstadoContratacionButtonEdit/>

                    <br/>


                    <SelectInput source="en_progreso" label={"En Progreso"}
                                 formClassName={classes.estado}
                                 inputProps={{readOnly: true}} InputLabelProps={{shrink: true}}
                                 fullWidth
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}
                    />

                    <TextInput source="en_progreso_motivo" label={"En Progreso Motivo"}
                               fullWidth
                               formClassName={classes.estado_motivo}
                               variant="filled"
                               inputProps={{readOnly: true}} InputLabelProps={{shrink: true}}
                    />

                    <br/>
                    {/*<TextInput source="en_progreso_motivo" label={""}*/}
                    {/*           multiline*/}
                    {/*           InputLabelProps={{shrink: true}} inputProps={{readOnly: false}}*/}
                    {/*           className={classes.estado_motivo} fullWidth={true}*/}
                    {/*/>*/}


                    <SelectInput source="en_impugnacion" label={"En Impugnacion"}
                                 formClassName={classes.estado} fullWidth
                                 inputProps={{readOnly: true}}
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}
                    />

                    <TextInput source="en_impugnacion_motivo" label={"En Impugnacion Motivo"}
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: false}}
                               formClassName={classes.estado_motivo} fullWidth={true}
                    />

                    <br/>

                    <SelectInput source="en_suspenso" label={"En Suspenso"}
                                 formClassName={classes.estado} fullWidth
                                 inputProps={{readOnly: true}}
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}/>

                    <TextInput source="en_suspenso_motivo" label={"En Suspenso Motivo"}
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: false}}
                               formClassName={classes.estado_motivo} fullWidth={true}
                    />

                    <br/>

                    <SelectInput source="en_revision" label={"En Revision"}
                                 formClassName={classes.estado} fullWidth
                                 inputProps={{readOnly: true}}
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}/>

                    <TextInput source="en_revision_motivo" label={"En Revision Motivo"}
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: false}}
                               formClassName={classes.estado_motivo} fullWidth={true}
                    />

                    <br/>

                    <SelectInput source="en_anulado" label={"En Anulado"}
                                 formClassName={classes.estado} fullWidth
                                 inputProps={{readOnly: true}}
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}/>

                    <TextInput source="en_anulado_motivo" label={"En Anulado Motivo"}
                               formClassName={classes.estado_motivo} fullWidth={true}
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: false}}
                    />

                    <br/>

                    <SelectInput source="en_finalizado" label={"En Finalizado"}
                                 formClassName={classes.estado} fullWidth
                                 inputProps={{readOnly: true}}
                                 choices={[
                                     {id: 'S', name: 'Si'},
                                     {id: 'N', name: 'No'},
                                 ]}/>

                    <TextInput source="en_finalizado_motivo" label={"En Finalizado Motivo"}
                               multiline
                               InputLabelProps={{shrink: true}} inputProps={{readOnly: false}}
                               formClassName={classes.estado_motivo} fullWidth={true}
                    />

                </FormTab>
                }
            </TabbedForm>
        </Edit>
    )
};

const RowStyle = (record, index) => ({
    backgroundColor: record.secuencia_tipo === undefined || record.estado_color === undefined ? '' :
        record.estado_color.trim(),
});


export default ContratacionEncuadreEdit;


