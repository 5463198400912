import React, {useState} from 'react';
import {
    useQuery,
    Datagrid,
    TextField,
    Pagination,
    Loading, NumberField, ListContextProvider
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import IconBuscar from '@material-ui/icons/Search';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import IconSeleccionar from '@material-ui/icons/Done';
import {makeStyles} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import ButtonMui from '@material-ui/core/Button';
import {TextField as TextFieldMUI} from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import IconCancelar from '@material-ui/icons/Close';
import SeguimientoCompletoButton from "../TableroControlContratacion/SeguimientoCompletoButton";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ReplayIcon from "@material-ui/icons/Replay";
import FilterListIcon from "@material-ui/icons/FilterList";
import TableroControlContratacionGerenteInicioInfo from "./TableroControlContratacionGerenteInicioInfo";
import ProveedorAdjudicadoButton from "./ProveedorAdjudicadoButton"
import ContratacionObservacionesButton from "./ContratacionObservacionesButton"
import LinearProgresoCumplidoDiasPorcentajeCompleto
    from "../TableroControlContratacion/LinearProgresoCumplidoDiasPorcentajeCompleto";
import Divider from '@material-ui/core/Divider';
import PantallaMensaje from "../componentes/PantallaMensaje";

export const TableroControlContratacionGerenteInicioDataGrid = (props) => {
    const [page, setPage] = useState(props.valuebottomnavigationpage);
    const [perPage, setPerPage] = useState(props.valuebottomnavigationperpage);
    const [sort, setSort] = useState({field: 'id', order: 'DESC'})
    const [filter, setFilter] = useState('');
    const [busqueda, setBusqueda] = useState('');

    const useStyles = makeStyles({
        // button: {},
        table: {
            backgroundColor: 'Lavender',
        },
        headerCell: {
            backgroundColor: props.valuebottomnavigation === undefined ? 'MistyRose' :
                props.valuebottomnavigation === 'en_ejecucion' ? '#00b050' :
                    props.valuebottomnavigation === 'en_finalizadas' ? '#ffff00' :
                        props.valuebottomnavigation === 'dadas_de_baja' ? '#c00000' : 'MistyRose',
        },
    });
    const classes = useStyles();

    const {data, total, loading, error} = useQuery({
        type: 'getList',
        resource: 'TableroControlContratacionEncuadreEstado',
        payload: {
            pagination: {page, perPage},
            sort,
            filter: {
                q: filter, ingreso_por: 'D',
                unidad_negocio_ing: props.congeriniunidadnegocio,
                unidad_area_ing: props.congeriniunidadarea,
                contratacion_ano_ing: props.estadofecha,
                valuebottomnavigation_ing: props.valuebottomnavigation
            },
        }
    });

    if (loading) {
        // return <Loading/>
        return <PantallaMensaje
            p_imagen={'CircularProgress'}
            p_mensajePrimario={'Cargando la Lista de Contratacion'}
            p_mensajeSecondario={'La página se está cargando, espere un momento por favor.'}
        />
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }

    /*Botón de búsqueda*/
    const BtnBuscar = ({event}) => (
        <ButtonMui
            variant="contained"
            onClick={() => {
                setFilter(busqueda);
                setPage(props.valuebottomnavigationpage);
                setPerPage(props.valuebottomnavigationperpage);
            }}
            style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
            size={"small"}
            startIcon={<IconBuscar/>}
        >
            Buscar
        </ButtonMui>
    );

    /*Botón de borrar*/
    const BtnBorrar = ({event}) => (
        <ButtonMui
            variant="contained"
            onClick={() => {
                setFilter('');
                setBusqueda('');
                setPage(props.valuebottomnavigationpage);
                setPerPage(props.valuebottomnavigationperpage);
            }}
            style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
            size={'small'}
            startIcon={<YoutubeSearchedForIcon/>}
        >
            Borrar
        </ButtonMui>
    );

    const Btntitulo = ({event}) => (
        <div>
            <div>
                <div>{''}</div>
            </div>
        </div>
    );

    const BtnVolver = ({record}) => (
        <ButtonMui variant="contained" size={"small"} color="primary"
                   onClick={() => {
                       props.callbackCloseDrawer(false)
                       setPage(props.valuebottomnavigationpage);
                       setPerPage(props.valuebottomnavigationperpage);
                   }}
                   startIcon={<IconCancelar/>}
                   style={{color: '#ffffff', backgroundColor: '#123B80', marginLeft: '32px'}}
        >
            Volver
        </ButtonMui>
    );

    return (
        <div>
            <div style={{display: 'flex', justifyContent: "flex", alignContent: 'baseline', margin: '15px'}}>

                <Btntitulo/>
                <TextFieldMUI label="Buscar"
                              onChange={(v) => setBusqueda(v.target.value)}
                              defaultValue={filter}
                              style={{marginLeft: '2px', width: '150px', height: '40px'}}
                />

                <BtnBuscar size={'small'}/>
                {filter === '' ? '' :
                    <BtnBorrar/>
                }
            </div>
            {/*<ListContextProvider value={{*/}
            {/*    data: keyBy(data, 'id'),*/}
            {/*    ids: data.map(({ id }) => id),*/}
            {/*    total,*/}
            {/*    page,*/}
            {/*    perPage,*/}
            {/*    setPage,*/}
            {/*    currentSort: { sort },*/}
            {/*    basePath: "/posts",*/}
            {/*    resource: 'posts',*/}
            {/*    selectedIds: []*/}
            {/*}}>*/}
            <Datagrid classes={classes}
                      rowStyle={RowStyle}
                // expand={<TableroControlContratacionGerenteInicioInfo/>}
                      data={keyBy(data, 'id')}
                      ids={data.map(({id}) => id)}
                      currentSort={sort}
                      setSort={(field, order) => setSort({field, order})}
            >
                {/*<BtnConfirmar/>*/}
                <TextField label="ID" source="id" sortable={false}/>
                <TextField label="Expediente" source="expediente_referencia_gde" sortable={false}/>
                {/*<TextField label="Tipo" source="contratacion_tipo_encuadre"  sortable={false}/>*/}
                <TextField label="Tipo" source="contratacion_d" sortable={false}/>
                <TextField label="Concepto" source="concepto_descripcion" sortable={false}/>

                {
                    props.congerinicurrenciesunidadnegocio.length === 1 ? '' :
                        <TextField label="Unidad Negocio" source="unidad_negocio"/>
                }
                {
                    props.congerinicurrenciesunidadarea.length === 1 ? '' :
                        <TextField label="Unidad Area" source="unidad_area_d"/>
                }

                <NumberField label="Importe $" source="importe_contratacion_pesos"
                             style={{textAlign: 'right', margin: '1em'}}
                             options={{minimumFractionDigits: 2}} sortable={false}/>
                {
                    props.valuebottomnavigation === undefined ?
                        <TextField label="Estado Actual" source="estado_encuadre" sortable={false}/> :
                        props.valuebottomnavigation === 'en_ejecucion' ?
                            <TextField label="Estado Actual" source="estado_encuadre" sortable={false}/> :
                            props.valuebottomnavigation === 'en_finalizadas' ?
                                <ProveedorAdjudicadoButton label="Proveedor Adjudicado"/> :
                                props.valuebottomnavigation === 'dadas_de_baja' ?
                                    // <TextField label="Observacion" source="en_anulado_motivo"  sortable={false}/>
                                    <ContratacionObservacionesButton label="Observacion"/>
                                    : <TextField label="Estado Actual" source="estado_encuadre" sortable={false}/>
                }
                <SeguimientoCompletoButton label="Seguimiento"/>
                {
                    props.valuebottomnavigation === undefined ?
                        <LinearProgresoCumplidoDiasPorcentajeCompleto label="Progreso Días"/> :
                        props.valuebottomnavigation === 'en_ejecucion' ?
                            <LinearProgresoCumplidoDiasPorcentajeCompleto label="Progreso Días"/> :
                            props.valuebottomnavigation === 'en_finalizadas' ?
                                <LinearProgresoCumplidoDiasPorcentajeCompleto label="Progreso Días"/> :
                                props.valuebottomnavigation === 'dadas_de_baja' ? '' :
                                    <LinearProgresoCumplidoDiasPorcentajeCompleto label="Progreso Días"/>
                }
                {
                    props.valuebottomnavigation === undefined ?
                        <TextField label="Etapas Cumplidas" source="cumplido_total_pasos" sortable={false}/> :
                        props.valuebottomnavigation === 'en_ejecucion' ?
                            <TextField label="Etapas Cumplidas" source="cumplido_total_pasos" sortable={false}/> :
                            props.valuebottomnavigation === 'en_finalizadas' ?
                                <TextField label="Etapas Cumplidas" source="cumplido_total_pasos" sortable={false}/> :
                                props.valuebottomnavigation === 'dadas_de_baja' ? '' :
                                    <TextField label="Etapas Cumplidas" source="cumplido_total_pasos"
                                               sortable={false}/>
                }
            </Datagrid>
            <Pagination
                rowsPerPageOptions={[]}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                total={total}
            />
            {/*</ListContextProvider >*/}
            <BtnVolver/>
        </div>
    )
};

const RowStyle = (record, index) => ({
    backgroundColor: record.estado_encuadre === undefined || record.estado_color === undefined ? '' :
        record.estado_color.trim(),
});


export default TableroControlContratacionGerenteInicioDataGrid;